export const isSupportRequestLt = (attendeeList) => {
  const host = attendeeList.find((item) => item.isHost);
  return Boolean(host.bCanPinMultiVideo);
};

export const getFullTranscriptionHeight = ({
  isTranscriptionWindowOpen,
  windowSizeHeight,
  hasFooter,
}) => {
  const windowHeight = isTranscriptionWindowOpen ? 500 : windowSizeHeight;
  const headerHeight = 37;
  const windowHeaderHeight = 36;
  const searchBoxHeight = 56;
  const poweredBy = 20;
  const paddingBottom = 8;
  let fullTranscriptionHeight = 0;
  const footerReqHeight = 40;
  if (isTranscriptionWindowOpen) {
    fullTranscriptionHeight =
      windowHeight -
      windowHeaderHeight -
      searchBoxHeight -
      poweredBy -
      paddingBottom;
  } else {
    fullTranscriptionHeight =
      windowHeight - headerHeight - searchBoxHeight - poweredBy - paddingBottom;
  }
  fullTranscriptionHeight -= hasFooter ? footerReqHeight : 0;
  return fullTranscriptionHeight;
};

export const getFullTranscriptionWidth = ({
  isTranscriptionWindowOpen,
  rightContainerWidth,
}) => {
  const windowWidth = isTranscriptionWindowOpen ? 398 : rightContainerWidth;
  const listPadding = 48;
  return windowWidth - listPadding;
};

function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  // Time in microseconds since page-load or 0 if unsupported
  let d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const combineMessage = ({
  ccType,
  decryptedMessage,
  destNodeID,
  srcMsgID,
  isFinished = false,
  previousDisplayName,
  previousAvatar,
}) => ({
  ccType, // CC_TYPE
  message: decryptedMessage,
  userId: destNodeID, // same in attendeesList
  srcMsgID: srcMsgID || generateUUID(),
  isFinished,
  previousDisplayName,
  previousAvatar,
});
