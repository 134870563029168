import { infoLog } from '../global/web-client-logger';

export const externalController = ((rootCtx) => {
  const vendors = {};
  const addVendor = (vendorId, adaptor) => {
    if (!(vendorId in vendors)) {
      vendors[vendorId] = adaptor;
    }
  };

  return {
    init: (envParam) => {
      const req = require.context('./vendors', true, /index\.js$/);
      const confKeys = req.keys();
      confKeys.forEach((filename) => {
        const adaptor = req(filename).default(envParam, rootCtx);
        if (
          !adaptor ||
          typeof adaptor.shouldLoad !== 'function' ||
          !adaptor.shouldLoad()
        ) {
          infoLog(`Adaptor config ${filename} is not loaded`);
        } else {
          adaptor.init();
          addVendor(adaptor.getVendorId(), adaptor);
          infoLog(`Adaptor config ${filename} is loaded`);
        }
      });
    },
    isControlledMode(vendorId) {
      if (vendorId) {
        return vendors && vendorId in vendors;
      }
      return Object.keys(vendors).length > 0;
    },

    notifyConnectorZoomMsg(msgType, msgEvt, payload) {
      Object.keys(vendors).forEach((key) => {
        const adaptor = vendors[key];
        if (adaptor) {
          adaptor.handleZoomMsg(msgType, msgEvt, payload);
        }
      });
    },
  };
})(window);
