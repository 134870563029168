import { batch } from 'react-redux';
import {
  CONTROL_MODE_COMMON_ERROR_TYPE,
  JOIN_FLOW_ERROR_CODE,
} from '../../enum';
import { isSimulive } from '../../../global/service';
import {
  startCaptureVideo,
  stopCaptureVideo,
} from '../../../features/video/redux/thunks/start-stop-capture-video-thunk';
import {
  setIsNeedNotifyStartVideoFail,
  setClientDisableVideo,
  setIsTeslaParked,
} from '../../../features/video/redux/video-action';
import {
  unmuteAudio,
  muteAudio,
} from '../../../features/audio/redux/audio-thunk-action';
import { setSelfMuteOrUnmute } from '../../../features/audio/redux/audio-action';
import {
  coOrHostSelector,
  showWaitingRoomListSelector,
  waitingParticipantsSelector,
} from '../../../global/redux';
import { isVideoEncodeReadySelector } from '../../../features/video/redux/selectors/video-status-selector';
import { NetworkType } from './enum';
import {
  SET_NETWORK_CHANGE_COUNTER,
  SET_NETWORK_TYPE,
} from '../../../constants/MeetingActionTypes';
import {
  expelParticipant,
  showNotAllowUnmuteDialog,
} from '../../../features/dialog/redux/dialog-thunk-action';
import {
  admit,
  admitAll,
} from '../../../features/participants/redux/participants-thunk-action';
import { setShowWaitingRoomNotificationVisible } from '../../../reducers/MeetingUIReducer';
import { isWebinar } from '../../../global/service/meeting-types';
import { checkRole, isViewOnly } from '../../../global/service/user-types';
import {
  endMeetingThunkAction,
  leaveMeetingThunkAction,
} from '../../../global/redux/thunk-action/end-meeting-request';
import { noAvailableCameraSelector } from '../../../features/video/redux/selectors/camera-control-selector';

const teslaSocketUrl = 'ws://localhost:9998';
const teslaHeartbeatSeconds = 5;

class TeslaSocket {
  socket = null;

  shouldAutoReconnectWhenClose = true;

  options = {};

  constructor(options) {
    this.options = options;
    this.openSocket();
  }

  openSocket = () => {
    if (this.socket) return;
    this.socket = new WebSocket(teslaSocketUrl);

    this.socket.onopen = (evt) => {
      this.startHeartbeat();
      this.reConnectTimes = 0;
      this.shouldAutoReconnectWhenClose = true;
      if (this.options?.handleOnOpen) {
        this.options.handleOnOpen(evt);
      }
      this.deQueueMessage();
    };
    this.socket.onclose = (evt) => {
      this.stopHeartbeat();
      this.socket = null;
      if (this.options?.handleOnClose) {
        this.options.handleOnClose(evt);
      }
      this.reconnect();
    };
    this.socket.onerror = (evt) => {
      if (this.options?.handleOnError) {
        this.options.handleOnError(evt);
      }
    };
    this.socket.onmessage = (evt) => {
      if (this.options?.handleOnMessage) {
        const { method, params } = JSON.parse(evt.data || '{}');
        this.options.handleOnMessage({
          method,
          params,
        });
      }
    };
  };

  reConnectTimes = 0;

  maxReConnectTimes = 5;

  reconnect = (force) => {
    if (force) {
      this.shouldAutoReconnectWhenClose = true;
      this.reConnectTimes = 0;
    }
    if (!this.shouldAutoReconnectWhenClose) return;
    const delaySeconds = 2 ** this.reConnectTimes - 1;

    // eslint-disable-next-line no-plusplus
    this.reConnectTimes++;

    if (this.reConnectTimes > this.maxReConnectTimes) return;
    setTimeout(() => {
      if (!this.shouldAutoReconnectWhenClose) return;
      this.openSocket();
    }, delaySeconds * 1000);
  };

  heartbeatTimer = null;

  startHeartbeat = () => {
    if (this.heartbeatTimer) return;
    this.heartbeatTimer = setInterval(() => {
      this.sendMessage(
        {
          method: 'call_heartbeat',
        },
        false,
      );
    }, teslaHeartbeatSeconds * 1000);
  };

  stopHeartbeat = () => {
    if (this.heartbeatTimer) {
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = null;
    }
  };

  messageQueue = [];

  addQueueMessage = (message) => {
    this.messageQueue.push(message);
  };

  deQueueMessage = () => {
    if (this.messageQueue.length > 0) {
      const AllSuccess = this.messageQueue.every((message, idx) => {
        if (this.sendMessage(message, true)) return true;
        if (idx > 0) {
          this.messageQueue.splice(0, idx - 1);
        }
        return false;
      });
      if (AllSuccess) {
        this.clearQueueMessage();
      }
    }
  };

  clearQueueMessage = () => {
    this.messageQueue = [];
  };

  sendMessage = (message, shouldWait = true) => {
    if (this.socket?.readyState === 1) {
      this.socket.send(JSON.stringify(message));
      return true;
    }
    if (shouldWait) {
      this.addQueueMessage(message);
    }
    return false;
  };

  closeWebSocket = () => {
    this.shouldAutoReconnectWhenClose = false;
    if (this.socket) {
      this.socket.close();
    }
  };
}

const websocketIns = (() => {
  let store = null;
  let adaptor = null;
  return {
    registerEvtHandler(_store, _adaptor) {
      store = _store;
      adaptor = _adaptor;
    },
    createSocket() {
      if (!window.teslaSocket) {
        window.teslaSocket = new TeslaSocket({
          handleOnMessage: ({ method, params }) => {
            const { getState, dispatch } = store;
            const state = getState();
            const {
              meeting: {
                currentUser,
                bCanUnmute,
                isHost,
                admitAllSilentUsersStarted,
              },
              video: {
                UI: { loading },
                isCameraCaptureLoading,
                isCameraForbidden,
                isNeedNotifyStartVideoFail,
              },
              audio: { isMicrophoneForbidden },
              meeting: {
                loginUserUid,
                bCanUnmuteVideo,
                currentUser: {
                  bVideoMute: isCurrentUserVideoMuted,
                  isAllowTalk: isCurrentUserAllowTalk,
                  bVideoOn: isCurrentUserVideoOn,
                },
              },
              settings: {
                vbSettings: { enableVB, enableForceUseVB },
              },
            } = state;
            const isVideoEncodeReady = isVideoEncodeReadySelector(state);

            const coOrHost = coOrHostSelector(state);
            const isWb = isWebinar();
            const isViewOnlyUser = isViewOnly(currentUser.userRole);
            const noAvailableCamera = noAvailableCameraSelector(state);
            /**
             * @openapi
             *
             * components:
             *   messages:
             *     mute_audio:
             *       summary: notify zoom to mute self audio
             *       payload:
             *         type: object
             *         properties:
             *           method:
             *             const: mute_audio
             * channels:
             *   mute_audio:
             *     publish:
             *       message:
             *         $ref: '#/components/messages/mute_audio'
             */
            switch (method) {
              case 'mute_audio':
                if (isMicrophoneForbidden) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.MUTE_AUDIO,
                  });

                  return false;
                }
                if (isWb && isViewOnlyUser && !isCurrentUserAllowTalk) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
                  });
                  return false;
                }
                dispatch(setSelfMuteOrUnmute(true));
                dispatch(muteAudio(currentUser.userId));
                break;
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     unmute_audio:
               *       summary: notify zoom to unmute self audio
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: unmute_audio
               * channels:
               *   unmute_audio:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/unmute_audio'
               */
              case 'unmute_audio': {
                if (isMicrophoneForbidden) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
                  });
                  return false;
                }
                if (isWb && isViewOnlyUser && !isCurrentUserAllowTalk) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
                  });
                  dispatch(showNotAllowUnmuteDialog());
                  return false;
                }
                if (!bCanUnmute && currentUser.muted && !coOrHost) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
                  });
                  dispatch(showNotAllowUnmuteDialog());
                  return false;
                }
                dispatch(setSelfMuteOrUnmute(true));
                dispatch(unmuteAudio(currentUser.userId));
                break;
              }
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     camera_off:
               *       summary: notify zoom to open turn off self camera
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: camera_off
               * channels:
               *   camera_off:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/camera_off'
               */
              case 'camera_off': {
                if (isWb && isViewOnlyUser) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
                  });
                  return false;
                }
                if (isCameraCaptureLoading || isCameraForbidden) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.STOP_VIDEO,
                  });
                  return false;
                }
                dispatch(stopCaptureVideo());
                break;
              }
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     camera_on:
               *       summary: notify zoom to turn on self camera
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: camera_on
               * channels:
               *   camera_on:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/camera_on'
               */
              case 'camera_on': {
                if (isWb && isViewOnlyUser) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
                  });
                }

                if (!isVideoEncodeReady || loading) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
                  });
                }
                if (noAvailableCamera) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
                  });
                }
                if (
                  (isCurrentUserVideoMuted && !coOrHost) ||
                  (!bCanUnmuteVideo && !coOrHost)
                ) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
                  });
                }

                if (loginUserUid && enableForceUseVB && !enableVB) {
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
                  });
                }

                if (isNeedNotifyStartVideoFail) {
                  dispatch(setIsNeedNotifyStartVideoFail(false));
                  adaptor.notifyControllerActionFailure({
                    type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
                  });
                }

                dispatch(startCaptureVideo());
                break;
              }
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     stop_incoming_video:
               *       summary: notify zoom to stop subscribe incoming video
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: stop_incoming_video
               * channels:
               *   stop_incoming_video:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/stop_incoming_video'
               */
              case 'stop_incoming_video': {
                dispatch(setClientDisableVideo(true));
                break;
              }
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     start_incoming_video:
               *       summary: notify zoom to re-subscribe incoming video, if has been stopped before
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: start_incoming_video
               * channels:
               *   start_incoming_video:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/start_incoming_video'
               */
              case 'start_incoming_video': {
                dispatch(setClientDisableVideo(false));
                break;
              }
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     network_interface_changed:
               *       summary: notify zoom tesla buck network type has changed
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: network_interface_changed
               *           params:
               *             type: object
               *             required:
               *               - interface
               *             properties:
               *               interface:
               *                 description: network type
               *                 enum:
               *                   - wifi
               *                   - cellular
               *                   - none
               * channels:
               *   network_interface_changed:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/network_interface_changed'
               */
              case 'network_interface_changed': {
                const { interface: network } = params || {};
                if (
                  network === NetworkType.WIFI ||
                  network === NetworkType.LTE
                ) {
                  dispatch({
                    type: SET_NETWORK_CHANGE_COUNTER,
                  });
                }
                dispatch({
                  type: SET_NETWORK_TYPE,
                  payload: network,
                });
                break;
              }
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     drive_state_changed:
               *       summary: notify zoom tesla drive state has changed
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: drive_state_changed
               *           params:
               *             type: object
               *             required:
               *               - parked
               *             properties:
               *               parked:
               *                 description: car is parking or driving
               *                 type: boolean
               * channels:
               *   drive_state_changed:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/drive_state_changed'
               */
              case 'drive_state_changed': {
                const { parked: isParked } = params || {};

                // only batch together video feed toggles and allow UI layout to change seperately
                if (isParked) {
                  dispatch(setClientDisableVideo(false));
                  dispatch(setIsTeslaParked(true));
                } else {
                  batch(() => {
                    if (isCurrentUserVideoOn) {
                      dispatch(stopCaptureVideo());
                    }
                    dispatch(setClientDisableVideo(true));
                  });
                  dispatch(setIsTeslaParked(false));
                }
                break;
              }
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     end_call:
               *       summary: notify zoom to leave the meeting
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: end_call
               * channels:
               *   end_call(deprecated):
               *     publish:
               *       message:
               *         summary: use 'leave_meeting' instead
               *         $ref: '#/components/messages/end_call'
               */
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     leave_meeting:
               *       summary: notify zoom to leave the meeting
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: leave_meeting
               * channels:
               *   leave_meeting:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/leave_meeting'
               */
              case 'end_call':
              case 'leave_meeting': {
                /* eslint-disable-next-line no-console */
                console.log(
                  'received leave request from controller, zoom is leaving now..',
                );
                adaptor.notifyControllerWarningInfo(
                  JOIN_FLOW_ERROR_CODE.CONTROLLER_QUIT,
                );
                dispatch(leaveMeetingThunkAction());
                break;
              }
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     end_call_for_all:
               *       summary: notify zoom to end the meeting(Only for host)
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: end_call_for_all
               * channels:
               *   end_call_for_all:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/end_call_for_all'
               */
              case 'end_call_for_all': {
                if (!isHost || isSimulive()) {
                  return;
                }
                dispatch(endMeetingThunkAction());
                break;
              }
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     admit_participant_from_wr:
               *       summary: notify zoom to admit target user_id participant from waiting room(Only for host or cohost)
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: admit_participant_from_wr
               *           params:
               *             type: object
               *             properties:
               *               user_id:
               *                 $ref: '#/components/schemas/user_id'
               * channels:
               *   admit_participant_from_wr:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/admit_participant_from_wr'
               */
              case 'admit_participant_from_wr': {
                const showWaitingRoomList = showWaitingRoomListSelector(state);
                const { user_id } = params || {};
                if (
                  showWaitingRoomList &&
                  !admitAllSilentUsersStarted &&
                  user_id
                ) {
                  dispatch(admit(user_id));
                }
                break;
              }
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     admit_all_participants_from_wr:
               *       summary: notify zoom to admit all participants from waiting room(Only for host or cohost)
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: admit_all_participants_from_wr
               * channels:
               *   admit_all_participants_from_wr:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/admit_all_participants_from_wr'
               */
              case 'admit_all_participants_from_wr': {
                const showWaitingRoomList = showWaitingRoomListSelector(state);
                if (showWaitingRoomList) {
                  dispatch(admitAll());
                }
                break;
              }
              /**
               * @openapi
               *
               * components:
               *   messages:
               *     deny_participant_from_wr:
               *       summary: notify zoom to deny target user_id participant from waiting room(Only for host or cohost)
               *       payload:
               *         type: object
               *         properties:
               *           method:
               *             const: deny_participant_from_wr
               *           params:
               *             type: object
               *             properties:
               *               user_id:
               *                 $ref: '#/components/schemas/user_id'
               * channels:
               *   deny_participant_from_wr:
               *     publish:
               *       message:
               *         $ref: '#/components/messages/deny_participant_from_wr'
               */
              case 'deny_participant_from_wr': {
                const showWaitingRoomList = showWaitingRoomListSelector(state);
                if (showWaitingRoomList) {
                  const { user_id } = params || {};
                  const waitingParticipants =
                    waitingParticipantsSelector(state);
                  const participant = waitingParticipants.find(
                    (item) => item.userId === user_id,
                  );
                  if (participant) {
                    const { isWebinarAttendee } = checkRole(participant);
                    const data = {
                      userId: isWebinarAttendee
                        ? participant.jid
                        : participant.userId,
                      userName: participant.displayName || participant.name,
                      isAttendee: isWebinarAttendee,
                    };
                    dispatch(expelParticipant(data));
                    dispatch(setShowWaitingRoomNotificationVisible(false));
                  }
                }
                break;
              }
              default:
                break;
            }
          },
        });
      }
    },
  };
})();

export function externalEvtHandler(store, adaptor) {
  websocketIns.registerEvtHandler(store, adaptor);
  websocketIns.createSocket();
}
