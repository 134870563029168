/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';

const nextItem = function (list, item) {
  if (list === item) {
    return list.firstChild;
  }
  if (item && item.nextElementSibling) {
    return item.nextElementSibling;
  }
  return list.firstChild;
};

const previousItem = function (list, item) {
  if (list === item) {
    return list.lastChild;
  }
  if (item && item.previousElementSibling) {
    return item.previousElementSibling;
  }
  return list.lastChild;
};

const isValidItem = function (item) {
  const disabled =
    item.disabled || item.getAttribute('aria-disabled') === 'true';
  return !disabled && item.hasAttribute('tabindex');
};

const moveFocus = function (list, currentFocus, travasalFunction) {
  let wrappedOnce = false;
  let nextFocus = travasalFunction(list, currentFocus);
  if (!nextFocus) {
    return;
  }
  while (!isValidItem(nextFocus)) {
    //prevent infinite loop;
    if (nextFocus === list.firstChild) {
      if (wrappedOnce) {
        return;
      }
      wrappedOnce = true;
    }
    nextFocus = travasalFunction(list, nextFocus);
  }
  nextFocus.focus();
};

const Menu = React.forwardRef((props, ref) => {
  const { className, children, onKeyDown, autoFocus, ...rest } = props;
  const originFocusRef = useRef(null);

  useEffect(() => {
    if (autoFocus && ref.current) {
      originFocusRef.current = document.activeElement;
      ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus]);

  useEffect(() => {
    return () => {
      if (originFocusRef.current) {
        originFocusRef.current.focus();
      }
    };
  }, []);

  const handleKeyDown = (event) => {
    const key = event.key;
    const currentFocus = document.activeElement;
    if (key === 'ArrowDown') {
      moveFocus(ref.current, currentFocus, nextItem);
    } else if (key === 'ArrowUp') {
      moveFocus(ref.current, currentFocus, previousItem);
    } else if (key === 'Enter' || key === ' ') {
      event.target.click();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };
  return (
    <ul
      className={className}
      role="menu"
      onKeyDown={handleKeyDown}
      tabIndex={autoFocus ? 0 : -1}
      ref={ref}
      {...rest}
    >
      {children}
    </ul>
  );
});

Menu.displayName = 'Menu';
Menu.defaultProps = {
  autoFocus: true,
};

export default Menu;
