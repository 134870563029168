import meetingConfig from 'meetingConfig';
import {
  MEMORYSTORAGE_KEYS,
  isExternalControlledMode,
  isSupportMultiView,
} from '../../../../global';
import AliveToast from '../../../../global/containers/notification-manager/alive-toast';
import { easyStore, storeType } from '../../../../global/easy-store';
import {
  isEnableMultiSpeaker,
  isUseUnifiedRender,
} from '../../../../global/op-feature-option';
import { isWebinar } from '../../../../global/service/meeting-types';
import { multiSpeakerActiveConditionTip } from '../../resource';
import { isShowMultiSpeakerActiveSelector } from '../selectors/video-layout-selector';
import {
  multiSpeakerActiveVideoSelector,
  multiSpeakerMainVideoSelector,
} from '../selectors/video-list-selector';
import {
  setActiveSpeakerList,
  setIsEnableMultiSpeakerView,
  setMultiSpeakerActiveList,
} from '../video-action';

export const handleMultiSpeakerActiveList = (obj) => (dispatch, getState) => {
  if (!isSupportMultiSpeakerFromWebclient()) return;
  const {
    video: { isEnableMultiSpeakerView },
  } = getState();
  if (!isEnableMultiSpeakerView) {
    dispatch(setIsEnableMultiSpeakerView(true));
  }
  const list = _.isObject(obj) ? Object.values(obj) : [];
  dispatch(setMultiSpeakerActiveList(list));
  dispatch(refreshActiveSpeakerList());
};

export const refreshActiveSpeakerList = () => (dispatch, getState) => {
  const state = getState();
  const {
    video: {
      isEnableMultiSpeakerView,
      multiSpeakerActiveList,
      multiSpeakerConfig,
      activeSpeakerList,
    },
  } = state;
  if (!(isEnableMultiSpeakerView && isSupportMultiSpeakerFromWebclient())) {
    return;
  }

  if (multiSpeakerActiveList.length === 0) {
    dispatch(setActiveSpeakerList([]));
    return;
  }
  const activeAreaList = multiSpeakerActiveVideoSelector(state);
  const mainAreaList = multiSpeakerMainVideoSelector(state);
  const maxShowActiveSpeakerNum = getMaxShowActiveSpeakerNum(
    multiSpeakerConfig,
    activeAreaList.length + mainAreaList.length,
  );
  const currentActiveSpeakerList = multiSpeakerActiveList.slice(
    0,
    maxShowActiveSpeakerNum,
  );
  const updateActiveSpeakerList = compareActiveSpeakerList(
    activeSpeakerList,
    currentActiveSpeakerList,
  );
  dispatch(setActiveSpeakerList(updateActiveSpeakerList));
};

const getMaxShowActiveSpeakerNum = (multiSpeakerConfig, participantCount) => {
  let count = 0;
  multiSpeakerConfig.forEach((item) => {
    if (participantCount >= item[1] && participantCount <= item[2]) {
      count = item[0];
    }
  });
  return count;
};

const compareActiveSpeakerList = (
  oldActiveSpeakerList,
  currentActiveSpeakerList,
) => {
  const updateActiveSpeakerList = [];
  oldActiveSpeakerList.forEach((item) => {
    if (currentActiveSpeakerList.includes(item)) {
      updateActiveSpeakerList.push(item);
    }
  });
  currentActiveSpeakerList.forEach((item) => {
    if (!oldActiveSpeakerList.includes(item)) {
      updateActiveSpeakerList.push(item);
    }
  });
  return updateActiveSpeakerList;
};

export const isSupportMultiSpeakerFromWebclient = () => {
  return (
    isEnableMultiSpeaker() &&
    !meetingConfig.meetingOptions.disableDynamicLayout &&
    !isExternalControlledMode() &&
    !isWebinar() &&
    isSupportMultiView() &&
    isUseUnifiedRender()
  );
};

export const popupMultiSpeakerTips = () => (dispatch, getState) => {
  const state = getState();
  const {
    video: { multiSpeakerConfig },
  } = state;
  const isShowMultiSpeakerActive = isShowMultiSpeakerActiveSelector(state);
  if (!isShowMultiSpeakerActive) {
    easyStore.easySet(
      MEMORYSTORAGE_KEYS.showedMultiSpeakerActiveTips,
      true,
      storeType.memory,
    );
    AliveToast.toast({
      message: multiSpeakerActiveConditionTip(multiSpeakerConfig[0][2]),
      aliveTime: 5000,
    });
  }
};
