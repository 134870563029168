import { MeetingEntity } from '@zoom/zdt_events/dist/entities/meetings/Meeting';
import meetingConfig from 'meetingConfig';
import {
  currentHostSelector,
  participantsSizeSelector,
} from '../../redux/selector';
import { getMeetingId } from '../telemetry-utils';
import { isWebinar } from '../../service/meeting-types';

const meetingEntity = new MeetingEntity();

const getScheduleType = () => {
  return 'schedule';
};

const getMeetingType = () => {
  if (isWebinar()) {
    return 'webinar';
  } else if (meetingConfig.isPmi) {
    return 'pmi';
  } else {
    return 'normal';
  }
};

const isSupportWaitingRoom = () => {
  return meetingConfig?.meetingOptions?.enableWaitingRoom;
};

const isSupportsJbh = () => {
  return false;
};

const isEnableTranslation = () =>
  meetingConfig.liveTranscription?.isEnableTranslatedCaptions;
export const makeMeetingEntity = (state) => {
  meetingEntity.currentHostUserId = currentHostSelector(state)?.userGUID;
  // meetingEntity.originalHostUserId = originalHostSelector(state).userGUID;
  meetingEntity.meeting_id = getMeetingId();
  meetingEntity.scheduleType = getScheduleType();
  meetingEntity.meetingType = getMeetingType();
  meetingEntity.supportsWaitRoom = isSupportWaitingRoom();
  meetingEntity.supportsJbh = isSupportsJbh();
  meetingEntity.numAttendees = participantsSizeSelector(state);
  meetingEntity.supportsTranslation = isEnableTranslation();
  return meetingConfig;
};
