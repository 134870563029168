import meetingConfig from 'meetingConfig';
import { SHARER_STATUS_ENUM } from '../../../sharing/enum';
import { isSupportMultiView } from '../../../../global/util';

import {
  setAttendeeLayout,
  setAttendeeVideoControlMode,
  setAttendeeVideoLayoutFlag,
  setIsSwapVideoShared,
} from '../video-action';
import {
  VIDEO_LAYOUT_GALLERY_VIEW,
  VIDEO_LAYOUT_SPEAK_VIEW,
  ATTENDEE_VIDEO_MODE,
  VIDEO_LAYOUT,
  SIDE_BY_SIDE_MODE,
  VIDEO_LAYOUT_NO_SHARE,
  VIDEO_LAYOUT_SIDE_BY_SIDE,
  VIDEO_LAYOUT_SHARE,
  ATTENDEE_FLAG,
  VIDEO_LAYOUT_STANDARD_VIEW,
  ATTENDEE_SEND_FLAG,
} from '../../enum';
import {
  setIsSwapVideoSharedThunk,
  setVideoLayoutThunk,
} from './video-layout-change-thunk';
import {
  attendeeViewFlagThunk,
  attendeeViewThunk,
  followHostOrderThunk,
} from './follow-host-base-message-thunk';
import { isWebinar } from '../../../../global/service/meeting-types';
import { isHost, isViewOnly } from '../../../../global/service/user-types';

export function receiveFollowHostViewThunk(
  attendeeVideoControlMode,
  attendeeVideoControlModeEx,
) {
  return (dispatch, getState) => {
    const {
      video: {
        UI: { attendeeLayout },
        attendeeVideoLayoutFlag,
      },
      sharing: { isReceiveSharing },
      meeting: {
        currentUser: { userRole },
      },
    } = getState();
    const isHostUser = isHost(userRole);

    if (!isWebinar() || !isSupportMultiView()) {
      return;
    }

    dispatch(
      setAttendeeVideoControlMode({
        attendeeVideoControlMode,
        attendeeVideoControlModeEx,
      }),
    );

    // open follow order
    if (isHostUser && isWebinar()) {
      dispatch(
        followHostOrderThunk(
          meetingConfig.meetingOptions.isEnableGalleryViewReordering,
        ),
      );
    }

    if (isViewOnly(userRole)) {
      if (isReceiveSharing) {
        if (attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.FOLLOW) {
          if (attendeeVideoLayoutFlag === ATTENDEE_FLAG.FOLLOW_STANDARD) {
            dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SHARE[attendeeLayout]));
          }

          if (attendeeVideoLayoutFlag === ATTENDEE_FLAG.FOLLOW_STANDARD_SWAP) {
            dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SHARE[attendeeLayout]));
            dispatch(setIsSwapVideoSharedThunk(true));
          }

          if (attendeeVideoLayoutFlag === ATTENDEE_FLAG.FOLLOW_SIDE_BY_SIDE) {
            dispatch(
              setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE[attendeeLayout]),
            );
          }
          return;
        }

        if (attendeeVideoControlModeEx === SIDE_BY_SIDE_MODE.CLOSE) {
          if (
            attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.ACTIVE ||
            attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.GALLERY
          ) {
            dispatch(
              setVideoLayoutThunk(VIDEO_LAYOUT_SHARE[attendeeVideoControlMode]),
            );
          }
          return;
        }

        if (attendeeVideoControlModeEx === SIDE_BY_SIDE_MODE.OPEN) {
          if (
            attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.ACTIVE ||
            attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.GALLERY
          ) {
            dispatch(
              setVideoLayoutThunk(
                VIDEO_LAYOUT_SIDE_BY_SIDE[attendeeVideoControlMode],
              ),
            );
          }
        }
        return;
      }

      if (
        attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.ACTIVE ||
        attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.GALLERY
      ) {
        dispatch(
          setVideoLayoutThunk(VIDEO_LAYOUT_NO_SHARE[attendeeVideoControlMode]),
        );
      }

      if (attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.FOLLOW) {
        dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_NO_SHARE[attendeeLayout]));
      }
    }
  };
}

export function updateFollowHostViewThunk() {
  return (dispatch, getState) => {
    const {
      video: {
        UI: { attendeeLayout, videoLayout, isSwapVideoShared },
        attendeeVideoControlMode,
        attendeeVideoControlModeEx,
        attendeeVideoLayoutFlag,
      },
      meeting: {
        currentUser: { userRole },
      },
      sharing: { sharerStatus, isReceiveSharing },
    } = getState();
    const isHostUser = isHost(userRole);
    const isSendShare = sharerStatus !== SHARER_STATUS_ENUM.ED;

    if (!isSupportMultiView()) {
      return;
    }

    if (isHostUser) {
      if (isWebinar()) {
        dispatch(
          followHostOrderThunk(
            meetingConfig.meetingOptions.isEnableGalleryViewReordering,
          ),
        );
      }

      if (attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.FOLLOW) {
        // for send share host, speaker view => sidebyside:speaker  gallery view => sidebyside:gallery
        if (isSendShare) {
          if (
            videoLayout === VIDEO_LAYOUT_SPEAK_VIEW &&
            attendeeLayout !== ATTENDEE_VIDEO_MODE.ACTIVE
          ) {
            dispatch(
              attendeeViewFlagThunk(ATTENDEE_SEND_FLAG.FOLLOW_SIDE_BY_SIDE),
            );
            // The following two thunks are called to reset the field, preventing standard and side-by-side:speaker from being indistinguishable
            dispatch(attendeeViewThunk(ATTENDEE_VIDEO_MODE.GALLERY));
            dispatch(attendeeViewThunk(ATTENDEE_VIDEO_MODE.ACTIVE));
          } else if (
            videoLayout === VIDEO_LAYOUT_GALLERY_VIEW &&
            attendeeLayout !== ATTENDEE_VIDEO_MODE.GALLERY
          ) {
            dispatch(
              attendeeViewFlagThunk(ATTENDEE_SEND_FLAG.FOLLOW_SIDE_BY_SIDE),
            );
            //The following two thunks are called to reset the field, preventing standard and side-by-side:speaker from being indistinguishable
            dispatch(attendeeViewThunk(ATTENDEE_VIDEO_MODE.ACTIVE));
            dispatch(attendeeViewThunk(ATTENDEE_VIDEO_MODE.GALLERY));
          }
        } else {
          dispatch(attendeeViewThunk(VIDEO_LAYOUT[videoLayout]));
        }

        // standard flag use AttendeeVideoLayoutFlag, if host view is standard, need to send this flag
        if (videoLayout === VIDEO_LAYOUT_STANDARD_VIEW) {
          dispatch(setIsSwapVideoSharedThunk(isSwapVideoShared));
        }
      } else {
        dispatch(attendeeViewThunk(attendeeLayout));
      }
    }

    if (isWebinar() && isViewOnly(userRole)) {
      if (isReceiveSharing) {
        if (attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.FOLLOW) {
          if (
            attendeeVideoLayoutFlag === ATTENDEE_FLAG.FOLLOW_STANDARD &&
            videoLayout !== VIDEO_LAYOUT_SHARE[attendeeLayout]
          ) {
            dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SHARE[attendeeLayout]));
          }

          if (attendeeVideoLayoutFlag === ATTENDEE_FLAG.FOLLOW_STANDARD_SWAP) {
            dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SHARE[attendeeLayout]));
            dispatch(setIsSwapVideoSharedThunk(true));
          }

          if (
            attendeeVideoLayoutFlag === ATTENDEE_FLAG.FOLLOW_SIDE_BY_SIDE &&
            videoLayout !== VIDEO_LAYOUT_SIDE_BY_SIDE[attendeeLayout]
          ) {
            dispatch(
              setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE[attendeeLayout]),
            );
          }
          return;
        }

        if (attendeeVideoControlModeEx === SIDE_BY_SIDE_MODE.CLOSE) {
          if (
            (attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.ACTIVE ||
              attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.GALLERY) &&
            videoLayout !== VIDEO_LAYOUT_SHARE[attendeeVideoControlMode]
          ) {
            dispatch(
              setVideoLayoutThunk(VIDEO_LAYOUT_SHARE[attendeeVideoControlMode]),
            );
          }
          return;
        }

        if (attendeeVideoControlModeEx === SIDE_BY_SIDE_MODE.OPEN) {
          if (
            (attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.ACTIVE ||
              attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.GALLERY) &&
            videoLayout !== VIDEO_LAYOUT_SIDE_BY_SIDE[attendeeVideoControlMode]
          ) {
            dispatch(
              setVideoLayoutThunk(
                VIDEO_LAYOUT_SIDE_BY_SIDE[attendeeVideoControlMode],
              ),
            );
          }
        }
        return;
      }

      if (
        (attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.ACTIVE ||
          attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.GALLERY) &&
        videoLayout !== VIDEO_LAYOUT_NO_SHARE[attendeeVideoControlMode]
      ) {
        dispatch(
          setVideoLayoutThunk(VIDEO_LAYOUT_NO_SHARE[attendeeVideoControlMode]),
        );
      }

      if (
        attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.FOLLOW &&
        videoLayout !== VIDEO_LAYOUT_NO_SHARE[attendeeLayout]
      ) {
        dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_NO_SHARE[attendeeLayout]));
      }
    }
  };
}

export function receiveLayoutFlagThunk(attendeeVideoLayoutFlag) {
  return (dispatch, getState) => {
    const {
      video: {
        attendeeVideoControlMode,
        UI: { attendeeLayout },
      },
      meeting: {
        currentUser: { userRole },
      },
    } = getState();

    if (!isWebinar() || !isSupportMultiView()) {
      return;
    }
    dispatch(setAttendeeVideoLayoutFlag(attendeeVideoLayoutFlag));
    if (
      isViewOnly(userRole) &&
      attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.FOLLOW
    ) {
      if (attendeeVideoLayoutFlag === ATTENDEE_FLAG.FOLLOW_STANDARD) {
        dispatch(setIsSwapVideoShared(false));
        dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SHARE[0]));
      } else if (
        attendeeVideoLayoutFlag === ATTENDEE_FLAG.FOLLOW_STANDARD_SWAP
      ) {
        dispatch(setIsSwapVideoShared(true));
        dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SHARE[0]));
      } else if (
        attendeeVideoLayoutFlag === ATTENDEE_FLAG.CANCEL_STANDARD_SWAP
      ) {
        dispatch(setIsSwapVideoShared(false));
        dispatch(
          setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE[attendeeLayout]),
        );
      }
    }
  };
}

export function receiveLayoutThunk(layout) {
  return (dispatch, getState) => {
    const {
      video: {
        attendeeVideoControlMode,
        attendeeVideoLayoutFlag,
        UI: { videoLayout },
      },
      meeting: {
        currentUser: { userRole },
      },
      sharing: { isReceiveSharing },
    } = getState();

    if (!isWebinar() || !isSupportMultiView()) {
      return;
    }
    dispatch(setAttendeeLayout(layout));

    if (
      isViewOnly(userRole) &&
      attendeeVideoControlMode === ATTENDEE_VIDEO_MODE.FOLLOW
    ) {
      if (isReceiveSharing) {
        if (
          attendeeVideoLayoutFlag === ATTENDEE_FLAG.FOLLOW_SIDE_BY_SIDE &&
          videoLayout !== VIDEO_LAYOUT_SIDE_BY_SIDE[layout]
        ) {
          dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE[layout]));
        }
        return;
      }

      dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_NO_SHARE[layout]));
    }
  };
}
