import meetingConfig from 'meetingConfig';
import { SESSIONSTORAGE_KEYS } from '../../global/constant';
import { isExternalControlledMode } from '../../global/util';
import { IS_FAILOVER_TO_SOCKET_CLOSE } from '../../constants/MeetingActionTypes';
import { forYourGiftTypes } from './enum';
import { setForYourGiftDialogVisible } from './redux/dialog-action';
import { LOADING_TEXT_NOTIFICATION_TYPES } from '../../global/containers/notification-manager/loading-text-notification';

export const isFirstFreeMeeting = () => {
  return (
    meetingConfig.giftOptions && meetingConfig.giftOptions.meetingCount === 0
  );
};
export const isSecondFreeMeeting = () => {
  return (
    meetingConfig.giftOptions && meetingConfig.giftOptions.meetingCount === 1
  );
};
export const isNoMoreFreeMeeting = () => {
  return (
    meetingConfig.giftOptions && meetingConfig.giftOptions.meetingCount >= 2
  );
};

export function freeMeetingEndHandler(dispatch, isOriginhost) {
  let isLeaveMeetingRightNow = true;
  if (!isOriginhost || isExternalControlledMode()) {
    return isLeaveMeetingRightNow;
  }
  const isPaidMeeting = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_freeMeetingHasAutoUpgraded,
  );
  if (isPaidMeeting) {
    if (isFirstFreeMeeting()) {
      dispatch({ type: IS_FAILOVER_TO_SOCKET_CLOSE, data: false });
      dispatch(
        setForYourGiftDialogVisible({
          visible: true,
          type: forYourGiftTypes.FIRST_END,
          isModal: true,
        }),
      );
      isLeaveMeetingRightNow = false;
    } else if (isSecondFreeMeeting()) {
      dispatch({ type: IS_FAILOVER_TO_SOCKET_CLOSE, data: false });
      dispatch(
        setForYourGiftDialogVisible({
          visible: true,
          type: forYourGiftTypes.SECOND_END,
          isModal: true,
        }),
      );
      isLeaveMeetingRightNow = false;
    }
  }
  return isLeaveMeetingRightNow;
}

export function isStartRecordLoadingTextVisiable(loadingNotificationValues) {
  return (
    loadingNotificationValues.findIndex(
      (value) =>
        value === LOADING_TEXT_NOTIFICATION_TYPES.START_RECORDING.value,
    ) > -1
  );
}
