import zmitt from '@zoom/zmitt';
import { successLog, infoLog } from '../web-client-logger';

const EventQueueProvider = (function () {
  let _subscriptionList = {};
  const addSubscription = function (type, persistentEvtList) {
    if (!_subscriptionList[type]) {
      _subscriptionList[type] = {
        type,
        evtBus: zmitt(persistentEvtList),
      };
    } else if (persistentEvtList) {
      _subscriptionList[type].evtBus.addWhitelist(persistentEvtList);
    }
    return _subscriptionList[type];
  };

  return {
    inject(envParam, config, confName) {
      successLog(`EventQueue config ${confName} is loaded`);
      Object.getOwnPropertySymbols(config).forEach((evt) => {
        const _registerInstance = this.getSubscription(evt);
        Object.keys(config[evt]).forEach((type) => {
          _registerInstance.watch(type, config[evt][type].bind(null, envParam));
        });
      });
    },
    autoInject(envParam, meetingConfig) {
      const req = require.context('../../features', true, /evt-conf\.js$/);
      req.keys().forEach((filename) => {
        const _config = req(filename).default;
        if (
          !_config ||
          typeof _config.shouldLoad !== 'function' ||
          !_config.shouldLoad(meetingConfig)
        ) {
          infoLog(`EventQueue config ${filename} is not loaded`);
          return;
        }
        successLog(`EventQueue config ${filename} is loaded`);
        Object.getOwnPropertySymbols(_config).forEach((evt) => {
          const _registerInstance = this.getSubscription(evt);
          Object.keys(_config[evt]).forEach((type) => {
            _registerInstance.watch(
              type,
              _config[evt][type].bind(null, envParam),
            );
          });
        });
      });
    },
    getRegister(type, persistentEvtList) {
      const _subscription = addSubscription(type, persistentEvtList);

      const bus = _subscription.evtBus;
      return {
        emit: bus.emit.bind(bus),
      };
    },
    getSubscription(type) {
      const _subscription = addSubscription(type);

      const bus = _subscription.evtBus;
      return {
        unwatch(type, handler) {
          bus.off.call(bus, type, handler);
        },
        watch(type, handler) {
          bus.on.call(bus, type, handler);
        },
      };
    },
    dispose() {
      _subscriptionList = {};
    },
  };
})();

export { EventQueueProvider };
