import { sendSocketMessage } from '../../../../actions/SocketActions';
import * as socketEventTypes from '../../../../constants/ZoomSocketEventTypes';

export function attendeeViewFlagThunk(flag) {
  return (dispatch) => {
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_ATTENDEE_VIDEO_LAYOUT_MODE_CHANGE_REQ,
        body: {
          AttendeeVideoLayoutFlag: flag,
        },
      }),
    );
  };
}

export function attendeeViewThunk(layout) {
  return (dispatch) => {
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_ATTENDEE_VIDEO_LAYOUT_MODE_CHANGE_REQ,
        body: {
          AttendeeVideoLayoutMode: layout,
        },
      }),
    );
  };
}

export function followHostViewThunk(data) {
  return (dispatch) => {
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_ATTENDEE_VIDEO_CONTROL_MODE_CHANGE_REQ,
        body: data,
      }),
    );
  };
}

export function followHostOrderThunk(flag) {
  return (dispatch) => {
    const data = {
      evt: socketEventTypes.WS_CONF_FOLLOW_HOST_REQ,
      body: {
        bFollowHostVideo: flag,
      },
    };
    dispatch(sendSocketMessage(data));
  };
}
