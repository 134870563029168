import { CONTROL_MODE_READY_FACTOR } from '../../enum';

export const localReadyStatesObserver = (() => {
  let targetCap = 0x0;
  let flags = 0x0;

  return {
    setTargetCap(val) {
      // Google meet device don't support share
      targetCap = val & ~CONTROL_MODE_READY_FACTOR.SHARE_ENCODE_INIT_SUCCESS;
    },
    complete(newFlag) {
      flags |= newFlag;
      if (flags === targetCap) {
        if (typeof this.readyHandler === 'function') this.readyHandler();
      }
    },
    onReady(handler) {
      this.readyHandler = handler;
    },
    reset() {
      targetCap = 0x0;
      flags = 0x0;
    },
    isTargetCapSet() {
      return targetCap !== 0x0;
    },
  };
})();
