import { createSelector } from 'reselect';
import {
  coOrHostSelector,
  isQnASettingEnabledSelector,
} from '../../../global/redux/selector';
import meetingConfig from 'meetingConfig';
import { isInBOSelector } from '../../breakout-room/redux/bo-room-selector';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';
import { currentUserRoleSelector } from '../../video/redux/selectors/video-list-selector';
import { liveAnswerDone } from '../service/q-a-normal-service';
import { setBitFlagAt } from '../../../global/big-int';
export const qaAttendeeSelector = createSelector(
  [currentUserRoleSelector, coOrHostSelector],
  (userRole, coOrHost) => {
    return isWebinar() ? isViewOnly(userRole) : !coOrHost;
  },
);

export const unreadMeetingQANumberSelector = createSelector(
  [
    ({ QAMeeting: { questionList } }) => questionList,
    ({ QAMeeting: { unreadQAListForAttendee } }) => unreadQAListForAttendee,
    qaAttendeeSelector,
  ],
  (questionList, unreadQAListForAttendee, qaAttendee) => {
    if (qaAttendee) {
      return Object.keys(unreadQAListForAttendee).length;
    }
    return questionList.filter(
      (q) => !q.isAnswered && !liveAnswerDone(q.liveStatus) && !q.isDismissed,
    ).length;
  },
);
const isMMRSupportQA = (mmrFeatureEx) => {
  return mmrFeatureEx && !!(BigInt(mmrFeatureEx) & setBitFlagAt(11));
};
export const qaIsInMMR = (state) => {
  const {
    video: { mmrSaveOrderFlag },
  } = state;
  return (
    isMMRSupportQA(mmrSaveOrderFlag) &&
    (meetingConfig.meetingOptions?.isSupportNewQA || !isWebinar())
  );
};

export const isQAButtonShow = (state) => {
  return (
    !isInBOSelector(state) &&
    isQnASettingEnabledSelector(state) &&
    state.QAMeeting.qaEnabled
  );
};
