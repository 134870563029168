/* eslint-disable react/prop-types */
import React from 'react';
import { errorLog } from '../../../web-client-logger';

class LazySVGIconErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    errorLog(error.message);
    errorLog(info.componentStack);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return '❌';
    }

    return children;
  }
}

export default LazySVGIconErrorBoundary;
