import { createActions } from 'redux-actions';

const SET_INTERPRETATION_DROPDOWN_VISIBLE =
  'SET_INTERPRETATION_DROPDOWN_VISIBLE';
const SET_INTERPRETATION_TOOLTIP_VISIBLE = 'SET_INTERPRETATION_TOOLTIP_VISIBLE';
const SET_INTERPRETATION_LANG_TYPE = 'SET_INTERPRETATION_LANG_TYPE';
const SET_INTERPRETATION_START = 'SET_INTERPRETATION_START';
const SET_INTERPRETATION_LIST = 'SET_INTERPRETATION_LIST';
const SET_INTERPRETATION_MUTE = 'SET_INTERPRETATION_MUTE';
const INTERPRETATION_WINDOW_CLOSED = 'INTERPRETATION_WINDOW_CLOSED';
const SIGN_LANGUAGE_INTERPRETATION_LANG_SELECTED =
  'SIGN_LANGUAGE_INTERPRETATION_LANG_SELECTED';
const INTERPRETER_WINDOW_SHOW_TYPE_CHANGED =
  'INTERPRETER_WINDOW_SHOW_TYPE_CHANGED';
const SIGN_LANGUAGES_UPDATED = 'SIGN_LANGUAGES_UPDATED';
const SIGN_LANGUAGE_INTERPRETATION_STARTED =
  'SIGN_LANGUAGE_INTERPRETATION_STARTED';

const SIGN_LANGUAGE_INTERPRETATION_ALLOW_TO_TALK_CHANGED =
  'SIGN_LANGUAGE_INTERPRETATION_ALLOW_TO_TALK_CHANGED';

const SET_INTERPRETATION_WINDOW_VISIBLE = 'SET_INTERPRETATION_WINDOW_VISIBLE';
const SET_INTERPRETER_LIST = 'SET_INTERPRETER_LIST';
const SET_IS_INTERPRETER_LIST_INIT = 'SET_IS_INTERPRETER_LIST_INIT';
const INTERPRETATION_RESET = 'INTERPRETATION_RESET';
export const {
  setInterpretationDropdownVisible,
  setInterpretationTooltipVisible,
  setInterpretationWindowVisible,
  setInterpretationLangType,
  setInterpretationStart,
  setInterpretationList,
  setInterpretationMute,
  interpretationWindowClosed,
  signLanguageInterpretationLangSelected,
  interpreterWindowShowTypeChanged,
  signLanguagesUpdated,
  signLanguageInterpretationStarted,
  setInterpreterList,
  setIsInterpreterListInit,
  interpretationReset,
  signLanguageInterpretationAllowToTalkChanged,
} = createActions({
  [SET_INTERPRETATION_DROPDOWN_VISIBLE]: (payload) => payload,
  [SET_INTERPRETATION_TOOLTIP_VISIBLE]: (payload) => payload,
  [SET_INTERPRETATION_WINDOW_VISIBLE]: (payload) => payload,
  [SET_INTERPRETATION_LANG_TYPE]: (payload) => payload,
  [SET_INTERPRETATION_START]: (payload) => payload,
  [SET_INTERPRETATION_LIST]: (payload) => payload,
  [SET_INTERPRETATION_MUTE]: (payload) => payload,
  [INTERPRETATION_WINDOW_CLOSED]: (payload) => payload,
  [SIGN_LANGUAGE_INTERPRETATION_LANG_SELECTED]: (payload) => payload,
  [INTERPRETER_WINDOW_SHOW_TYPE_CHANGED]: (payload) => payload,
  [SIGN_LANGUAGES_UPDATED]: (payload) => payload,
  [SIGN_LANGUAGE_INTERPRETATION_STARTED]: (payload) => payload,
  [SET_INTERPRETER_LIST]: (payload) => payload,
  [SET_IS_INTERPRETER_LIST_INIT]: (payload) => payload,
  [INTERPRETATION_RESET]: (payload) => payload,
  [SIGN_LANGUAGE_INTERPRETATION_ALLOW_TO_TALK_CHANGED]: (payload) => payload,
});
