/* eslint-disable react/prop-types,react/display-name */
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import SmartCaptcha from './SmartCaptcha.js';
import { MediaType, CaptchaTypes } from './type.js';
import meetingConfig from 'meetingConfig';

const grecaptchaLoadedCallbackKey = 'grecaptchaLoadedCallback';
const gRecaptchaId = 'GReCaptchaEle';
let widgetId;

const GRecaptcha = React.forwardRef(
  ({ siteKey, isVisible, setCaptchaType }, ref) => {
    useImperativeHandle(ref, () => ({
      getToken() {
        return window?.grecaptcha?.enterprise?.getResponse(widgetId);
      },
    }));

    useEffect(() => {
      if (!widgetId) {
        let params = {
          sitekey: siteKey,
          ['error-callback']: () => {
            if (setCaptchaType) {
              setCaptchaType(CaptchaTypes.SmartCaptcha);
              delete window.grecaptcha;
            }
          },
        };
        if (!isVisible) {
          params = Object.assign(params, {
            size: 'invisible',
          });
        }
        widgetId = window?.grecaptcha?.enterprise?.render(gRecaptchaId, params);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteKey, isVisible]);

    return <div id={gRecaptchaId}></div>;
  },
);

/*
recaptcha__en.js, not recaptcha/enterprise.js will load 2 times for document and document inner captcha iframe.
This is by design.
https://stackoverflow.com/questions/41617645/google-recaptcha-javascript-loading-multiple-times#:~:text=This%20is%20intended%20behaviour%2C%20apparently.
*/
let recaptchaScriptInserted = false;
const insertRecaptchaScriptManually = (
  captchaSiteKey,
  iframeNonce,
  captchaType,
  setCaptchaType,
) => {
  if (recaptchaScriptInserted) {
    return;
  }
  const isVisible = captchaType === CaptchaTypes.VisibleRecaptcha;
  const isInVisible = captchaType === CaptchaTypes.InvisibleRecaptcha;
  let recaptchaSource = 'https://www.recaptcha.net/recaptcha/enterprise.js';
  // let recaptchaSource = 'https://www.google.com/recaptcha/enterprise.js'; // back up domain

  let scriptLists =
    [].slice.call(document.getElementsByTagName('script')) || [];

  if (
    scriptLists.every(
      (o) => (o.src ?? '').indexOf('recaptcha/enterprise.js') === -1,
    )
  ) {
    const script = document.createElement('script');
    if (iframeNonce) {
      script.setAttribute('nonce', iframeNonce);
    }
    let src = `${recaptchaSource}?render=${captchaSiteKey}`;
    if (isVisible) {
      src = `${recaptchaSource}?onload=${grecaptchaLoadedCallbackKey}&render=explicit`;
    } else if (isInVisible) {
      src = `${recaptchaSource}?onload=${grecaptchaLoadedCallbackKey}&render=${captchaSiteKey}`;
    }
    script.setAttribute('src', src);
    script.onerror = (event) => {
      if (
        event?.target &&
        event?.target?.src &&
        event?.target?.src.includes('/recaptcha/enterprise.js')
      ) {
        // If gReCaptcha load failed, switch to SmartCaptcha
        setCaptchaType(CaptchaTypes.SmartCaptcha);
      }
    };
    document.head.append(script);
    recaptchaScriptInserted = true;
  }
};

const CaptchaContainer = React.forwardRef(
  (
    {
      className,
      captchaType,
      iframeNonce,
      captchaSiteKey,
      onSmartCaptchaInputChange,
      setCaptchaType,
    },
    ref,
  ) => {
    const [smartCaptchaInfo, setSmartCaptchaInfo] = useState({
      mediaType: MediaType.Image,
      code: '',
    });
    const smartCaptchaRef = useRef();
    const VisibleReCaptchaRef = useRef();

    const isVisibleReCaptcha = captchaType === CaptchaTypes.VisibleRecaptcha;
    const isInvisibleRecaptcha =
      captchaType === CaptchaTypes.InvisibleRecaptcha;

    useEffect(() => {
      if (
        !recaptchaScriptInserted &&
        (isInvisibleRecaptcha || isVisibleReCaptcha)
      ) {
        insertRecaptchaScriptManually(
          captchaSiteKey,
          iframeNonce,
          captchaType,
          setCaptchaType,
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [captchaType]);

    const [recaptchaLoaded, setLoad] = useState(false);
    useEffect(() => {
      if (typeof window !== 'undefined') {
        window[grecaptchaLoadedCallbackKey] = () => {
          setLoad(true);
        };
      }
    }, []);

    const resetCaptcha = () => {
      let grecaptcha = window.grecaptcha?.enterprise;
      if (isVisibleReCaptcha || isInvisibleRecaptcha) {
        grecaptcha?.reset(widgetId);
      } else {
        grecaptcha?.reset();
      }
    };

    useImperativeHandle(ref, () => ({
      getCaptchaCode: (cb) => {
        if (isInvisibleRecaptcha) {
          getTokenFromRecaptcha(cb);
        } else if (captchaType === CaptchaTypes.SmartCaptcha) {
          cb({ ...smartCaptchaInfo, success: true });
        } else if (isVisibleReCaptcha) {
          cb({
            code: VisibleReCaptchaRef.current?.getToken(),
          });
        }
      },
      refreshSmartCaptcha: () => {
        // used for smart captcha
        if (captchaType === CaptchaTypes.SmartCaptcha) {
          smartCaptchaRef?.current?.refresh();
        }
      },
      resetCaptcha: resetCaptcha,
    }));

    const excuteCaptcha = async (grecaptcha, onGetTokenInfo, action) => {
      let token = '';
      try {
        token = await grecaptcha.execute(captchaSiteKey, { action });
        return token
          ? onGetTokenInfo({
              success: true,
              code: token,
              mediaType: MediaType.Invisible,
            })
          : onGetTokenInfo({
              success: false,
              code: 'captcha token falsy',
              mediaType: MediaType.Invisible,
            });
      } catch (err) {
        // eslint-disable-next-line no-console
        return onGetTokenInfo({
          success: false,
          code: 'captcha error',
          mediaType: MediaType.Invisible,
        });
      }
    };

    const getTokenFromRecaptcha = (onGetTokenInfo) => {
      /*
      window.grecaptcha come from Google Recaptcha SDK JS appened to HTML
      by above code `let recaptchaSource = 'https://www.recaptcha.net/recaptcha/enterprise.js';`
      */
      let grecaptcha = window.grecaptcha?.enterprise;

      // TODO After backend support send Visible type
      // grecaptcha.render('grecaptchaContainer', {
      //   sitekey: captchaSiteKey,
      // });
      if (grecaptcha?.ready) {
        let grecaptchaReady = false;
        grecaptcha.ready(async () => {
          grecaptchaReady = true;
          excuteCaptcha(grecaptcha, onGetTokenInfo, meetingConfig.dataAction);
        });
        setTimeout(() => {
          // if after 5 seconds, grecaptcha is not ready,
          // it may suggests recaptcha_en.js load failed
          // we still excuteCaptcha(), code: 'captcha error' will be return,
          if (!grecaptchaReady) {
            excuteCaptcha(grecaptcha, onGetTokenInfo, meetingConfig.dataAction);
          }
        }, 5000);
      } else {
        // if script from google (recaptcha/enterprise.js) load failed,
        // we still excuteCaptcha(), code: 'captcha error' will be return,
        // make sure frontend logic continue, eg: feedback click "Great" btn.
        excuteCaptcha(grecaptcha, onGetTokenInfo, meetingConfig.dataAction);
      }
    };

    const onInputChange = (mediaType, code) => {
      setSmartCaptchaInfo({ mediaType, code });
      onSmartCaptchaInputChange?.(code);
    };

    return (
      <div className={clsx('CaptchaContainer', className)}>
        {(isVisibleReCaptcha || isInvisibleRecaptcha) && recaptchaLoaded && (
          <GRecaptcha
            ref={VisibleReCaptchaRef}
            siteKey={captchaSiteKey}
            isVisible={isVisibleReCaptcha}
            setCaptchaType={setCaptchaType}
          />
        )}
        {captchaType === CaptchaTypes.SmartCaptcha && (
          <SmartCaptcha onInputChange={onInputChange} ref={smartCaptchaRef} />
        )}
      </div>
    );
  },
);

export default CaptchaContainer;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/features/preview/component/Captcha/index.scss';
