import emojiUnicode from 'emoji-unicode';
import {
  SELECTED_EMOJI_REACTION_SET,
  NONVERBAL_DATA,
  EMOJI_LINE_HEIGHT,
  CATEGORY_LABEL_HEIGHT,
  REACTION_CATEGORY_MAP,
  SELECTED_EMOJI_REACTION_TYPE,
  FREQUENTLY_USED_EMOJIS_CATEGORY,
  NONVERBAL_TYPE,
  WEBINAR_REACTION_DISPLAY_MODE,
  CLIP_MAX_SIZE,
  defaultResourcePath,
  categorySprite2xSizeMap,
} from './constant';
import { SKIN_TONE_TYPE } from '../settings/constant';
import { deepFreeze, shuffleArray } from '../../global/util';
import { infoLog } from '../../global/web-client-logger';
import meetingConfig from 'meetingConfig';
window.emojiUnicode = emojiUnicode;
export const convertEmojiToUnicode = (emoji) => {
  if (typeof emoji !== 'string') {
    return '';
  }
  const unicode = emojiUnicode(emoji)
    .split('fe0f') // No variation selector in our file name
    .map((str) => {
      let newStr = str
        .trim()
        .split(' ') // emojiUnicode use space to split two codes, but our filename use dash
        .join('-');
      for (; newStr.length && newStr.length < 4; ) {
        newStr = `0${newStr}`;
      }
      return newStr;
    })
    .filter((str) => !!str)
    .join('-');
  return unicode;
};

export const convertUnicodeToEmoji = (unicode) => {
  return unicode
    .split('-')
    .map((code) => String.fromCodePoint(parseInt(code, 16)))
    .join('');
};

/**
 * convert unicode as follow
 * 1. "output": "1f46e-200d-2642-fe0f",
 *    input: "1f46e-1f3fc-2642"
 *    result: '1f46e-1f3fc-200d-2642-fe0f'
 *
 * 2. "output": "1f468-200d-2764-fe0f-200d-1f48b-200d-1f468"
 *    input:"1f468-1f3fe-2764-1f48b-1f468-1f3fe"
      result "1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe"
  3.  "output": "2764-fe0f"  input: 2764, result 2764-fe0f
 */
export function getStandardOutputUnicode({ input, output }) {
  if (input === output) return input;
  const inputArr = input.split('-');
  const outputArr = output.split('-');
  const resultArr = [];
  let i = 0;
  let j = 0;
  while (j < outputArr.length && i < inputArr.length) {
    // console.log(i, j, resultArr);
    if (inputArr[i] === outputArr[j]) {
      resultArr.push(inputArr[i]);
      j += 1;
      i += 1;
    } else if (inputArr[i].match(ModifierReg)) {
      resultArr.push(inputArr[i]);
      i += 1;
    } else if (outputArr[j] === JOINER) {
      resultArr.push(outputArr[j]);
      j += 1;
    } else if (outputArr[j].match(VariationSelectorReg)) {
      resultArr.push(outputArr[j]);
      j += 1;
    }
  }

  if (j < outputArr.length) {
    resultArr.push(...outputArr.slice(j, outputArr.length));
  }
  if (i < inputArr.length) {
    resultArr.push(...inputArr.slice(i, inputArr.length));
  }
  return resultArr.join('-');
}
const VariationSelectorReg = /fe0[0-9a-f]/;
const ModifierReg = /1f3f[b-f]/;
const JOINER = '200d';
export const convertUnicodeToEmojiWithJoiner = (unicode, modifier) => {
  const codeArray = unicode.split('-');
  const resultArr = [];
  for (let index = 0; index < codeArray.length; index++) {
    const element = codeArray[index];
    if (element === modifier) resultArr.pop();
    resultArr.push(element);
    resultArr.push(JOINER);
  }
  if (resultArr[resultArr.length - 1] === JOINER) {
    resultArr.pop(); // joiner should not be at last
  }
  return resultArr
    .map((code) => String.fromCodePoint(parseInt(code, 16)))
    .join('');
};

export const removeUnicodeFromSkinTone = (unicode) =>
  unicode
    ?.split('-')
    .filter((codePoint) => !SKIN_TONE_TYPE[codePoint])
    .join('-');

export const checkIsSelectedEmoji = (input, isUnicode) => {
  if (isUnicode) {
    return !!SELECTED_EMOJI_REACTION_SET[input.split('-')[0]];
  }
  const filename = convertEmojiToUnicode(input);
  return !!SELECTED_EMOJI_REACTION_SET[filename];
};

export const getReactionFilename = (unicode, isSelectedEmoji) => {
  if (isSelectedEmoji) {
    return `reactions/${unicode}`;
  }
  return `${unicode}`;
};

export const getSelectedEmojiMeta = (input, isUnicode) => {
  let propName = '';
  if (isUnicode) {
    propName = SELECTED_EMOJI_REACTION_SET[input.split('-')[0]];
  } else {
    const filename = convertEmojiToUnicode(input);
    propName = SELECTED_EMOJI_REACTION_SET[filename.split('-')[0]];
  }
  return SELECTED_EMOJI_REACTION_TYPE[propName];
};

export const getNonVerbalFeedbackFilename = (feedbackType, isPListIcon) => {
  if (feedbackType === NONVERBAL_TYPE.NONVERBAL_COFFEE) {
    if (isPListIcon) {
      return NONVERBAL_DATA[NONVERBAL_TYPE.NONVERBAL_COFFEE].alter.circle
        .filename;
    }
    return NONVERBAL_DATA[NONVERBAL_TYPE.NONVERBAL_COFFEE].filename;
  }
  return NONVERBAL_DATA[feedbackType]?.filename;
};

export const getNonVerbalFeedbackAriaLabel = (
  feedbackType,
  currentUserFeedback,
  isPicker,
) => {
  if (feedbackType === NONVERBAL_TYPE.NONVERBAL_COFFEE) {
    if (currentUserFeedback === NONVERBAL_TYPE.NONVERBAL_COFFEE && isPicker) {
      return NONVERBAL_DATA[NONVERBAL_TYPE.NONVERBAL_COFFEE].alter.back
        .ariaLabel;
    }
    return NONVERBAL_DATA[NONVERBAL_TYPE.NONVERBAL_COFFEE].ariaLabel;
  }
  return NONVERBAL_DATA[feedbackType]?.ariaLabel;
};

export const checkIsValidNonVerbalFeedback = (feedbackType) =>
  !!getNonVerbalFeedbackFilename(feedbackType);

export const getRaiseHandFilename = (nSkinTone) => {
  const skinToneCode = SKIN_TONE_TYPE[nSkinTone]?.code;
  if (skinToneCode) {
    return `nvf/270b-${skinToneCode}`;
  }
  return 'nvf/270b';
};

export const parseAllEmojiSetResource = (allEmojiSetResource) => {
  const allEmojiSetSource = allEmojiSetResource.find(
    (resource) => resource.path === 'reaction/Emojis/svg',
  );
  const allEmojiMetaSource = allEmojiSetResource.find(
    (resource) => resource.path === 'reaction/emoji.json',
  );

  const reactionsRestrictionSource = allEmojiSetResource.find(
    (resource) => resource.path === 'reaction/reactions-restriction.json',
  );

  const allEmojiSet = deepFreeze(
    JSON.parse((allEmojiSetSource && allEmojiSetSource.data) || '{}'),
  );
  const allEmojiMeta = deepFreeze(
    JSON.parse((allEmojiMetaSource && allEmojiMetaSource.data) || '{}'),
  );
  const allEmojiCategories = Object.values(deepFreeze(REACTION_CATEGORY_MAP));

  const reactionsRestriction = deepFreeze(
    JSON.parse(
      (reactionsRestrictionSource && reactionsRestrictionSource.data) || '{}',
    ),
  );
  return {
    allEmojiSet,
    allEmojiMeta,
    allEmojiCategories,
    reactionsRestriction,
  };
};

export const getEmojiCodePointBase = (unicode) =>
  `${unicode}`
    .split('-')
    .filter((code) => !SKIN_TONE_TYPE[code])
    .join('-');

export const checkIsSkinToneValidForEmoji = (skinTone, meta) => {
  return !!(
    skinTone?.code &&
    meta?.diversities?.find((diver) => diver.indexOf(skinTone.code) !== -1)
  );
};

export const getEmojiWithSkinTone = (skinTone, meta, allEmojiSet) => {
  const isValidSkinTone = checkIsSkinToneValidForEmoji(skinTone, meta);

  const baseCode = meta?.code_points?.base;
  // const codeWithSkinTone = `${baseCode}-${skinTone?.code}`;
  // if (isValidSkinTone && allEmojiSet[codeWithSkinTone]) {
  //   return allEmojiSet[codeWithSkinTone];
  // }
  if (isValidSkinTone) {
    const codeWithSkinTone = addSkinToneIntoUnicode(meta, skinTone?.code);
    return allEmojiSet[codeWithSkinTone];
  }
  return allEmojiSet[baseCode];
};

export const splitAllEmojiListByLine = (allEmojiList) => {
  if (!allEmojiList) {
    return [[], []];
  }
  const categories = [];
  const lines = [];
  let nextLine = [];
  let lineCursor = 0;
  let offsetTop = 0;
  allEmojiList.forEach((emoji, index, arr) => {
    if (emoji.category.category !== arr[index - 1]?.category?.category) {
      if (nextLine.length) {
        offsetTop += EMOJI_LINE_HEIGHT;
        lines.push(nextLine);
      }
      categories.push({
        ...emoji.category,
        lineIndex: lines.length,
        offsetTop,
      });
      offsetTop += CATEGORY_LABEL_HEIGHT;
      lines.push(emoji.category);
      nextLine = [];
      lineCursor = 0;
    }
    if (lineCursor === 8) {
      offsetTop += EMOJI_LINE_HEIGHT;
      lines.push(nextLine);
      nextLine = [];
      lineCursor = 0;
    }

    nextLine.push(emoji);
    lineCursor += 1;
  });
  if (nextLine.length) {
    offsetTop += EMOJI_LINE_HEIGHT;
    lines.push(nextLine);
  }
  return [lines, categories];
};

export const splitFilteredAllEmojiListByLine = (allEmojiList) => {
  if (!allEmojiList) {
    return [];
  }
  const lines = [FREQUENTLY_USED_EMOJIS_CATEGORY];
  let nextLine = [];
  let lineCursor = 0;
  allEmojiList.forEach((emoji) => {
    if (lineCursor === 8) {
      lines.push(nextLine);
      nextLine = [];
      lineCursor = 0;
    }

    nextLine.push(emoji);
    lineCursor += 1;
  });
  if (nextLine.length) {
    lines.push(nextLine);
  }
  return lines;
};

const calcKVTotal = (sum, kv) => sum + kv[1];
export const makeClipFromBulletsPool = (
  bulletsPool, // something like {'emojiCode': countNum}
  displayMode = WEBINAR_REACTION_DISPLAY_MODE.FULL,
) => {
  const clipMaxSize =
    displayMode === WEBINAR_REACTION_DISPLAY_MODE.FULL
      ? CLIP_MAX_SIZE.FULL_MODE
      : displayMode === WEBINAR_REACTION_DISPLAY_MODE.MEDIUM
      ? CLIP_MAX_SIZE.COMPACT_MODE
      : 0;

  const bulletsKV = Object.entries(bulletsPool)
    .filter((bulletKV) => Number.isInteger && Number.isInteger(bulletKV[1]))
    .map(([code, count]) => [
      getReactionFilename(convertEmojiToUnicode(code), true),
      count,
    ]);
  const totalBulletsCount = bulletsKV.reduce(calcKVTotal, 0);

  let compressedBulletsKV = bulletsKV;
  let clip = [];
  if (totalBulletsCount > clipMaxSize) {
    const ratio = clipMaxSize / totalBulletsCount;
    compressedBulletsKV = bulletsKV.map(([filename, count]) => [
      filename,
      Math.ceil(count * ratio),
    ]);
  }
  clip = new Array(compressedBulletsKV.reduce(calcKVTotal, 0));
  let clipPointer = 0;
  compressedBulletsKV.forEach(([filename, count]) => {
    clip.fill(filename, clipPointer, clipPointer + count);
    clipPointer += count;
  });

  shuffleArray(clip);

  return clip;
};

export const isSupportedTimeZone = () => {
  if (!('Intl' in window)) {
    return false;
  }
  const timeZoneStr = new Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone?.toLocaleLowerCase(); //value of timezone property may return undefined if nothing was provided representing runtime's default time zone.

  const UNSUPPORTED_REGION = [
    'asia/beijing',
    'asia/shanghai',
    'asia/chongqing',
    'asia/urumqi',
    'asia/hong_kong',
    'asia/macau',
  ];
  if (UNSUPPORTED_REGION.indexOf(timeZoneStr) > -1) {
    return false;
  }
  return true;
};
// display tw flag in picker
export const isSupportTWFlagEmoji = () => {
  return (
    meetingConfig.meetingOptions.enableTWEmojiLibrary === true &&
    isSupportedTimeZone()
  );
};

export const shouldFilterTWEmojiOnMsg = () => {
  return (
    meetingConfig.meetingOptions.enableFilterTWEmoji === true &&
    !isSupportedTimeZone()
  );
};
export function addSkinToneIntoUnicode(emojiMeta, skinToneCode) {
  let baseCodeWithSkin = '';
  const baseCode = emojiMeta.code_points.base;
  const baseCodeArray = baseCode?.split('-');
  const diverList = emojiMeta.diversities;
  if (!diverList || diverList.length === 0) {
    infoLog(`can not add skintone `);
    return emojiMeta.code_points?.output;
  }
  const gapLenArray = [];

  for (let i = 0; i < diverList.length; i++) {
    gapLenArray.push(diverList[i].split('-').length - baseCodeArray.length);
  }

  const gapLen = Math.min(...gapLenArray);
  for (let i = 0; i < gapLen; i++) {
    baseCodeArray.push(skinToneCode);
  }

  for (let index = 0; index < diverList.length; index++) {
    // const element = array[index];
    const tempDiver = diverList[index].split('-').sort().join('-');
    if (tempDiver === baseCodeArray.sort().join('-')) {
      baseCodeWithSkin = diverList[index];
      return baseCodeWithSkin;
    }
  }
}

export function getResourcePath() {
  return `${meetingConfig.feStaticPath || defaultResourcePath}fe-emoji/0.0.1.7`;
}

export function addSprite2xStyle(categories) {
  const style = document.createElement('style');
  document.head.appendChild(style);
  style.id = 'sprite-2x-style';
  let cssRule = '';
  categories.forEach(({ category }) => {
    cssRule += `@media (-webkit-min-device-pixel-ratio: 1), (min-resolution: 96dpi), (min-resolution: 1dppx)
    {
        .joypixels-24-${category} {
            background-image: url(${getResourcePath()}/sprites/joypixels-sprite-24-${category}@2x.png);
            background-size: ${categorySprite2xSizeMap[category]};
        }
    } \n`;
    style.innerHTML = cssRule;
  });
}
