// ONLY START:2 STOP:4 are available now.
export const LIVE_TRANSCRIPTION_REQ_OPERATION = {
  GW_LIVE_CC_NONE: 0, // no ubr required, can't be set by client
  GW_LIVE_CC_INIT: 1, // inviting MMR, can't be set by client
  GW_LIVE_CC_START: 2, // client request to start gw live cc
  GW_LIVE_CC_PAUSE: 3, // client request to pause gw live cc
  GW_LIVE_CC_STOP: 4, // client request to stop gw live cc
};

export const LIVE_TRANSCRIPTION_STATUS = {
  CMM_LIVE_CC_STATUS_STOP: 0, // not start
  CMM_LIVE_CC_STATUS_START: 1, // start
  CMM_LIVE_CC_STATUS_CONNECTING: 10, // connecting
};

// NOT DETERMINED YET
export const LIVE_TRANSCRIPTION_OPERATION = {
  LIVE_CC_NONE: 0,
  LIVE_CC_ADD: 1, // a new statement of live cc
  LIVE_CC_REPLACE: 2, // replace some words
};

export const CC_TYPE = {
  UTIL: 1,
  REAL_TIME: 2,
  LIVE_TRANSCRIPTION: 3,
};

export const POWERED_BY = {
  unknown: 0,
  zoomKites: 1,
  AISense: 2,
};
