import { createSelector } from 'reselect';
import {
  isJoinByCallMe,
  isJoinByPhone,
  isJoinVoIP,
  isSimulive,
} from '../../../global/service';
import { restrictFeaturesSelector } from '../../../global/redux/restrict-features-selector';
import { isCurrentUserSignLanguageInterpreterAllowedToTalkOrMuteSelector } from '../../interpretation/redux/interpretation-selector';
import {
  thirdPartyAudioEnabled,
  voipEnabled,
  phoneCallEnabled,
  callMeEnabled,
} from '../service';
import { JOIN_MEETING_POLICY } from '../../../global/constant';
import { currentUserRoleSelector } from '../../../global/selectors/meeting-selector';
import { currentUserSelector } from '../../../global/redux/selector';
import { CallMeStatus } from '../enum';
import { isAudioBridge } from '../../../global/op-feature-option';
import { isViewOnly } from '../../../global/service/user-types';
import { globalVariable } from '../../../global/global-variable';

const audioSelector = (state) => state.audio ?? {};
const isMicrophoneForbiddenSelector = (state) =>
  state.audio.isMicrophoneForbidden;

export const hasMicrophoneDevicePermissonsSelector = createSelector(
  [
    isMicrophoneForbiddenSelector,
    currentUserSelector,
    (state) =>
      isCurrentUserSignLanguageInterpreterAllowedToTalkOrMuteSelector(state),
  ],
  (
    isMicrophoneForbidden,
    { userRole, isAllowTalk },
    isCurrentUserSignLanguageInterpreterAllowedToTalkOrMute,
  ) => {
    return (
      !isMicrophoneForbidden &&
      !(isViewOnly(userRole) && !isAllowTalk) &&
      !isSimulive() &&
      isCurrentUserSignLanguageInterpreterAllowedToTalkOrMute
    );
  },
);

export const hasAudioOptionsSelector = createSelector(
  [restrictFeaturesSelector, audioSelector, currentUserRoleSelector],
  (
    restrictFeatures,
    {
      isSupportAttendeeCallOut,
      isServerSupportAttendeeTelephony,
      isSupportAttendeeTollFreeCallIn,
    },
    currentUserRole,
  ) => {
    if (isSimulive() || thirdPartyAudioEnabled()) return true;
    const isViewOnlyAttendee = isViewOnly(currentUserRole);
    if (
      phoneCallEnabled(
        isViewOnlyAttendee,
        isServerSupportAttendeeTelephony,
        isSupportAttendeeTollFreeCallIn,
      )
    ) {
      return true;
    }
    if (callMeEnabled(isViewOnlyAttendee, isSupportAttendeeCallOut)) {
      return true;
    }
    const isVoipEnabled = voipEnabled(
      isViewOnlyAttendee,
      restrictFeatures[JOIN_MEETING_POLICY.DISABLE_COMPUTER_AUDIO],
    );
    return isVoipEnabled;
  },
);

export const isVoipEnabledSelector = createSelector(
  [restrictFeaturesSelector, currentUserRoleSelector],
  (restrictFeatures, currentUserRole) => {
    const bViewOnly = isViewOnly(currentUserRole);
    const hasRestrictComputerAudio =
      restrictFeatures[JOIN_MEETING_POLICY.DISABLE_COMPUTER_AUDIO];
    return voipEnabled(bViewOnly, hasRestrictComputerAudio);
  },
);

export const isJoinVoIPSelector = createSelector(
  [currentUserSelector],
  (currentUser) => {
    return isJoinVoIP(currentUser);
  },
);

export const isJoinByPhoneSelector = createSelector(
  [currentUserSelector],
  (currentUser) => {
    return isJoinByPhone(currentUser);
  },
);

export const gettyConfigSelector = (state) =>
  state.meeting.gettyConfig?.canAVEnable;

export const audioJoinedSelector = createSelector(
  [isJoinVoIPSelector, isJoinByPhoneSelector],
  (a, b) => {
    return a || b;
  },
);
export const audioJoinedBeforeAVEnabledSelector = createSelector(
  [isJoinVoIPSelector, isJoinByPhoneSelector, gettyConfigSelector],
  (a, b, c) => {
    return (a || b) && !c;
  },
);
export const isDialOutSucessfulSelector = (state) =>
  state.audio.dialOut.returnCode === CallMeStatus.success;

export const isJoinByCallMeSelector = createSelector(
  [currentUserSelector, isDialOutSucessfulSelector],
  (currentUser, isDialOutSucessful) => {
    return isJoinByCallMe(currentUser, isDialOutSucessful);
  },
);
export const initAudioEncodeStatusSelector = (state) =>
  state.socketStatus.initAudioEncodeStatus;

export const initAudioDecodeStatusSelector = (state) =>
  state.socketStatus.initAuidoDecodeStatus;

export const audioSsrcSelector = (state) => state.audio.audioSsrc;

export const isAudioSsrcReadySelector = (state) => {
  const { conID, svcUrl } = state.meeting;
  const { reportDomain } = state.security;
  const { audioSsrc } = state.audio;

  const canStartAudioBridge = conID && svcUrl && reportDomain;
  return isAudioBridge() ? canStartAudioBridge : !!audioSsrc;
};
export const isJoinByPhoneEnabledSelector = (state) => {
  const {
    meeting: { currentUser },
    audio: {
      isServerSupportAttendeeTelephony,
      isSupportAttendeeTollFreeCallIn,
    },
  } = state;
  const isViewOnlyAttendee = isViewOnly(currentUser.userRole);
  return phoneCallEnabled(
    isViewOnlyAttendee,
    isServerSupportAttendeeTelephony,
    isSupportAttendeeTollFreeCallIn,
  );
};

export const audioProfileSelector = (state) => {
  const {
    audio: {
      audioProfileType,
      denoiseType,
      isStereoEnabled,
      isHighFidelityEnabled,
    },
  } = state;
  return {
    backgroundNoiseSuppression: denoiseType,
    originalSound: {
      highfidelity: isHighFidelityEnabled,
      stereo: isStereoEnabled,
    },
    currentSelect: audioProfileType,
  };
};

export const noAvailableMicrophoneSelector = (state) => {
  const {
    audio: { microphoneDevicesList = [] },
  } = state;

  return (
    microphoneDevicesList.filter((mic) => mic.deviceId !== 'default').length ==
    0
  );
};

export const activeMicrophoneLabelSelector = (state) => {
  const {
    audio: { activeMicrophone, microphoneDevicesList },
  } = state;
  const device = microphoneDevicesList.find(
    (device) => device.deviceId === activeMicrophone,
  );
  return device ? device.label : '';
};

export const isPeerConnectionReadySelector = (state) => {
  const { isPeerConnectionSendReady, isPeerConnectionRecvReady } = state.audio;
  return (
    (isPeerConnectionRecvReady && isPeerConnectionSendReady) ||
    (globalVariable.avSocket?.isPeerConnectionSendReady?.() &&
      globalVariable.avSocket?.isPeerConnectionRecvReady?.())
  );
};
