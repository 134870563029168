import { ProductBaseEvent } from '@zoom/zdt_events/dist/base_events/base_events/Product';
import { currentVersion } from '../../../../version';
import meetingConfig from 'meetingConfig';

const productEntity = new ProductBaseEvent();

const getClusterName = () => {
  const string = meetingConfig.tid ?? '';
  const match = string.match(/clid=([^;]+)/);
  return match ? match[1] : 'unknown';
};
export const makeProductEntity = () => {
  productEntity.clientType = 'pwa';
  productEntity.client_version = currentVersion.version;
  productEntity.clusterDbName = getClusterName();
  return productEntity;
};
