import { iText } from '../../global/util';

export const NEW_LT_STATUS = {
  LTT_TEXT_STATUS_READY: 0,
  LTT_TEXT_STATUS_USER_SUB: 1,
  LTT_TEXT_STATUS_MANUAL_CAPTION_ENABLED: 2,
  LTT_TEXT_STATUS_MANUAL_CAPTION_DISABLED: 3,
};

export const NEW_LT_LANGUAGE = {
  LTTLanguage_ID_Unspecified: -1,
  LTTLanguage_ID_English: 0,
  LTTLanguage_ID_Chinese: 1,
  LTTLanguage_ID_Japanese: 2,
  LTTLanguage_ID_German: 3,
  LTTLanguage_ID_French: 4,
  LTTLanguage_ID_Russian: 5,
  LTTLanguage_ID_Portuguese: 6,
  LTTLanguage_ID_Spanish: 7,
  LTTLanguage_ID_Korean: 8,
  LTTLanguage_ID_Italian: 9,
  LTTLanguage_ID_Reserved: 10, // will be considered as EN.
  LTTLanguage_ID_Vietnamese: 11,
  LTTLanguage_ID_NoTranslation: 400, // no translation channel.
  LTTLanguage_ID_DefaultManualInput: 401, // the default channel for manual closed caption.
  LTTLanguage_ID_Customized: 500,
};

export const TEXT_MESSAGE_TYPE = {
  TextMessage_Unknown: 0,
  TextMessage_Close: 1,
  TextMessage_New: 2,
  TextMessage_Insert: 3,
  TextMessage_Delete: 4,
  TextMessage_Reset: 5,
  TextMessage_Audiodesc: 6,
  TextMessage_Speaker: 7,
  TextMessage_Error: 8,
};

export const NEW_LT_MSG_ERROR_TYPE = {
  TRANSLATE_NOT_SUPPORT: 1,
};

export const NEW_LT_LANGUAGE_CODE_MAP = {
  en: 0,
  zh: 1,
  ja: 2,
  de: 3,
  fr: 4,
  ru: 5,
  pt: 6,
  es: 7,
  ko: 8,
  it: 9,
  en1: 10,
  vi: 11,
  nl: 12,
  uk: 13,
  ar: 14,
  bn: 15,
  'zh-hant': 16,
  cs: 17,
  et: 18,
  fi: 19,
  el: 20,
  he: 21,
  hi: 22,
  hu: 23,
  id: 24,
  ms: 25,
  fa: 26,
  pl: 27,
  ro: 28,
  sv: 29,
  ta: 30,
  te: 31,
  tl: 32,
  tr: 33,
  'fr-ca': 34,
  da: 35,
  no: 36,
  th: 37,
  cy: 38,
  'es-mx': 39,
  'pt-br': 40,
  'en-nz': 41,
  'en-au': 42,
  'en-gb': 43,
  'zh-yue': 44,
  so: 45,
  ur: 46,
};

export const LANGUAGE_STR_MAP = {
  0: iText('English', 'apac.transcription.English'),
  1: iText('Chinese (Simplified)', 'apac.transcription.Chinese'),
  2: iText('Japanese', 'apac.transcription.Japanese'),
  3: iText('German', 'apac.transcription.German'),
  4: iText('French', 'apac.transcription.French'),
  5: iText('Russian', 'apac.transcription.Russian'),
  6: iText('Portuguese', 'apac.transcription.Portuguese'),
  7: iText('Spanish', 'apac.transcription.Spanish'),
  8: iText('Korean', 'apac.transcription.Korean'),
  9: iText('Italian', 'apac.transcription.Italian'),
  10: iText('Reserved', 'apac.transcription.Reserved'),
  11: iText('Vietnamese', 'apac.transcription.Vietnamese'),
  12: iText('Dutch', 'apac.transcription.Dutch'),
  13: iText('Ukrainian', 'apac.transcription.Ukrainian'),
  14: iText('Arabic', 'apac.transcription.Arabic'),
  15: iText('Bengali', 'apac.transcription.Bengali'),
  16: iText('Chinese (Traditional)', 'apac.transcription.Chinese_Traditional'),
  17: iText('Czech', 'apac.transcription.Czech'),
  18: iText('Estonian', 'apac.transcription.Estonian'),
  19: iText('Finnish', 'apac.transcription.Finnish'),
  20: iText('Greek', 'apac.transcription.Greek'),
  21: iText('Hebrew', 'apac.transcription.Hebrew'),
  22: iText('Hindi', 'apac.transcription.Hindi'),
  23: iText('Hungarian', 'apac.transcription.Hungarian'),
  24: iText('Indonesian', 'apac.transcription.Indonesian'),
  25: iText('Malay', 'apac.transcription.Malay'),
  26: iText('Persian', 'apac.transcription.Persian'),
  27: iText('Polish', 'apac.transcription.Polish'),
  28: iText('Romanian', 'apac.transcription.Romanian'),
  29: iText('Swedish', 'apac.transcription.Swedish'),
  30: iText('Tamil', 'apac.transcription.Tamil'),
  31: iText('Telugu', 'apac.transcription.Telugu'),
  32: iText('Tagalog', 'apac.transcription.Tagalog'),
  33: iText('Turkish', 'apac.transcription.Turkish'),
  34: iText('French (Canada)', 'apac.transcription.French_Canada'),
  35: iText('Danish', 'apac.transcription.Danish'),
  36: iText('Norwegian', 'apac.transcription.Norwegian'),
  37: iText('Thai', 'apac.transcription.Thai'),
  38: iText('Welsh', 'apac.transcription.Welsh'),
  39: iText('Spanish (Mexico)', 'apac.transcription.Spanish_Mexico'),
  40: iText('Portuguese (Brazil)', 'apac.transcription.Portuguese_Brazil'),
  41: iText('English (New Zealand)', 'apac.transcription.English_NewZealand'),
  42: iText('English (Australia)', 'apac.transcription.English_Australia'),
  43: iText(
    'English (United Kingdom)',
    'apac.transcription.English_UnitedKingdom',
  ),
  44: iText('Cantonese', 'apac.transcription.Cantonese'),
  45: iText('Somali', 'apac.transcription.Somali'),
  46: iText('Urdu', 'apac.transcription.Urdu'),
};

export const NEW_LT_MESSAGE_SOURCE = {
  LTTSource_Unspecified: 0,
  LTTSource_InMeetingManual: 1, //	user type caption in Zoom meeting
  LTTSource_ExternalCaptioner: 2, //	rest API poster: BROWSER --> CCS --> RWG --> MMR
  LTTSource_ASR: 4, //	automatic speech recognition: ASR --> ARGW --> MMR
};

export const CaptionOption = {
  selectTranslate: 1,
  showTranscript: 2,
  offTranslate: 3,
  disableCaption: 4,
  lockSpeakingLanguage: 5,
  changeSpeakingLanguage: 6,
  changeSimuliveLanguage: 7,
};

export const NEW_LT_SUB_TIPS_SHOW_TYPE = {
  mainControl: 'mainControl',
  transcriptionLanguageControl: 'transcriptionLanguageControl',
  translateLanguageControl: 'translateLanguageControl',
  hostManualCCControl: 'hostManualCCControl',
};
export const languageChangeActionType = {
  activeTranscription: 'activeTranscription',
  activeTranslation: 'activeTranslation',
  manualCaptionOnly: 'manualCaptionOnly',
  passiveTranscriptionWhenTranslationOn:
    'passiveTranscriptionWhenTranslationOn',
};
export const languageList = Object.entries(LANGUAGE_STR_MAP).reduce((s, v) => {
  const [languageCode, languageText] = v;
  return s.concat({ label: languageText, value: languageCode });
}, []);

export const findLanguageItem = (languageCode) =>
  languageList.find((v) => Number(v.value) === languageCode);
