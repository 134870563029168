import { createSelector } from 'reselect';
import {
  coOrHostSelector,
  isReceiveSharingSelector,
} from '../../../../global/redux/selector';
import {
  isSimulive,
  isSupportVB,
  isShowBackground,
} from '../../../../global/service';
import { isSelfPreviewRenderWithVideo } from '../../../../global/util';
import {
  MINIMIZED_VIDEO,
  VIDEO_CANVAS,
  WATERMARK_VISIBLE_ON,
} from '../../enum';
import { isMeInRoomSelector } from '../../../breakout-room/redux/bo-room-selector';
import {
  videoBasicAllAttendeeListSelector,
  videoBasicAllAttendeeListSizeSelector,
  galleryViewListSelector,
} from './video-list-selector';
import { spotlightVideoListSelector } from './video-common-selector';
import { isWebinar } from '../../../../global/service/meeting-types';
import { isViewOnly } from '../../../../global/service/user-types';

const currentUserIdSelector = ({
  meeting: {
    currentUser: { userId },
  },
}) => userId;

const currentUserRoleSelector = ({
  meeting: {
    currentUser: { userRole },
  },
}) => userRole;
const currentUserSelector = ({ meeting: { currentUser } }) => currentUser;
const selfVideoShowTypeSelector = ({
  video: {
    UI: { selfVideoShowType },
  },
}) => selfVideoShowType;

const watermarkEnableTurnOnOffSelector = ({
  video: { watermarkEnableTurnOnOff },
}) => watermarkEnableTurnOnOff;
const isWatermarkTurnOnSelector = ({ video: { isWatermarkTurnOn } }) =>
  isWatermarkTurnOn;
const watermarkVisibleOnSelector = ({ video: { watermarkVisibleOn } }) =>
  watermarkVisibleOn;
const isEnableMeetingWatermarkSelector = ({
  meeting: {
    meetingOptions: { isEnableMeetingWatermark },
  },
}) => isEnableMeetingWatermark;

const initVideoEncodeStatusSelector = ({
  socketStatus: { initVideoEncodeStatus },
}) => initVideoEncodeStatus;

const isVbPreReadySelector = ({
  settings: {
    vbBackground: { isVbPreReady },
  },
}) => isVbPreReady;

const backgroundIdxSelector = ({
  settings: {
    backgroundImage: { backgroundIdx },
  },
}) => backgroundIdx;
const loginUserUidSelector = ({ meeting: { loginUserUid } }) => loginUserUid;
const enableForceUseVBSelector = ({
  settings: {
    vbSettings: { enableForceUseVB },
  },
}) => enableForceUseVB;

//------------------------------------------------------------------------------

export const isVideoEncodeReadySelector = createSelector(
  [initVideoEncodeStatusSelector, isVbPreReadySelector, backgroundIdxSelector],
  (initVideoEncodeStatus, isVbPreReady, backgroundIdx) => {
    // supportVB
    if (isSupportVB()) {
      //
      if (initVideoEncodeStatus === 'success') {
        if (isVbPreReady) {
          return true;
        }

        if (backgroundIdx === -1) {
          return true;
        }

        return false;
      }
    }

    // not support VB encode success
    if (initVideoEncodeStatus === 'success') {
      return true;
    }

    return false;
  },
);

export const selfVideoOnSelector = createSelector(
  [
    videoBasicAllAttendeeListSelector,
    currentUserIdSelector,
    selfVideoShowTypeSelector,
    isReceiveSharingSelector,
    spotlightVideoListSelector,
  ],
  (
    videoBasicAllAttendeeList,
    currentUserId,
    selfVideoShowType,
    isReceiveSharing,
    spotlightVideoList,
  ) => {
    if (selfVideoShowType === MINIMIZED_VIDEO) {
      return null;
    }

    if (!isReceiveSharing) {
      return null;
    }

    const videoList = videoBasicAllAttendeeList.filter(
      (item) => item.userId === currentUserId,
    );

    if (!videoList.length || !videoList[0].displayVideoOn) {
      return null;
    }

    if (
      spotlightVideoList.length > 0 &&
      spotlightVideoList[0] === currentUserId
    ) {
      return null;
    }

    return videoList[0];
  },
);

export const isSingleMainRenderWithVideoSelector = createSelector(
  [
    videoBasicAllAttendeeListSizeSelector,
    currentUserIdSelector,
    currentUserRoleSelector,
    isReceiveSharingSelector,
    spotlightVideoListSelector,
    galleryViewListSelector,
    initVideoEncodeStatusSelector,
  ],
  (
    videoBasicAllAttendeeListSize,
    currentUserId,
    currentUserRole,
    isReceiveSharing,
    spotlightVideoList,
    galleryViewList,
    initVideoEncodeStatus,
  ) => {
    return (
      isSelfPreviewRenderWithVideo(initVideoEncodeStatus) &&
      !isReceiveSharing &&
      !(isWebinar() && isViewOnly(currentUserRole)) &&
      (videoBasicAllAttendeeListSize === 1 ||
        galleryViewList.length === 1 ||
        (spotlightVideoList.length > 0 &&
          spotlightVideoList[spotlightVideoList.length - 1] === currentUserId))
    );
  },
);

export const selfVideoRenderTagSelector = createSelector(
  [
    isSingleMainRenderWithVideoSelector,
    selfVideoOnSelector,
    initVideoEncodeStatusSelector,
  ],
  (isSingleMainRenderWithVideo, selfVideoOn, initVideoEncodeStatus) => {
    let videoTag = '';
    if (isSelfPreviewRenderWithVideo(initVideoEncodeStatus)) {
      if (isSingleMainRenderWithVideo) {
        videoTag = VIDEO_CANVAS.MAIN_SELF;
      } else if (selfVideoOn) {
        videoTag = VIDEO_CANVAS.SUSPENSION_VIDEO_SELF_SMALL;
      } else {
        videoTag = VIDEO_CANVAS.SUSPENSION_VIDEO_SELF;
      }
    }
    return videoTag;
  },
);

export const canTurnOnOffWatermarkSelector = createSelector(
  [coOrHostSelector, watermarkEnableTurnOnOffSelector, isMeInRoomSelector],
  (coOrHost, watermarkEnableTurnOnOff, isMeInRoom) => {
    return coOrHost && watermarkEnableTurnOnOff && !isMeInRoom && !isSimulive();
  },
);

export const isShowBlurMyBackgroundButtonSelector = createSelector(
  [
    isVbPreReadySelector,
    loginUserUidSelector,
    currentUserSelector,
    enableForceUseVBSelector,
  ],
  (isVbPreReady, loginUserUid, currentUser, enableForceUseVB) => {
    if (!isShowBackground(currentUser)) {
      return false;
    }

    if (!isSupportVB() || !isVbPreReady) {
      return false;
    }

    if (loginUserUid) {
      if (enableForceUseVB) {
        return false;
      }
    }

    return true;
  },
);

export const isAllMeetingUserVideoOffSelector = createSelector(
  [videoBasicAllAttendeeListSelector],
  (videoBasicAllAttendeeList) => {
    const videoOnList = videoBasicAllAttendeeList.filter(
      (item) => item.displayVideoOn,
    );

    if (videoOnList.length === 0) {
      return true;
    }
    return false;
  },
);

export const isEnableVideoWaterMaskSelector = createSelector(
  [
    watermarkEnableTurnOnOffSelector,
    isWatermarkTurnOnSelector,
    watermarkVisibleOnSelector,
    isEnableMeetingWatermarkSelector,
  ],
  (
    watermarkEnableTurnOnOff,
    isWatermarkTurnOn,
    watermarkVisibleOn,
    isEnableMeetingWatermark,
  ) => {
    return watermarkEnableTurnOnOff
      ? isWatermarkTurnOn &&
          (watermarkVisibleOn === WATERMARK_VISIBLE_ON.VIDEO_FEEDS ||
            watermarkVisibleOn === WATERMARK_VISIBLE_ON.BOTH)
      : isEnableMeetingWatermark;
  },
);
