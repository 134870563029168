import {
  WS_CONF_ATTRIBUTE_INDICATION,
  WS_CONF_HOST_CHANGE_INDICATION,
} from '../../constants/ZoomSocketEventTypes';
import {
  clearQaData,
  setBAllowAnonymousQuestion,
  setBAllowAttendeeViewAllQuestion,
  setBAllowAttendeeUpvoteQuestion,
  setBAllowAttendeeCommentQuestion,
  setEnableQuestion,
} from './redux/q-a-action';
import { SOCKET_NAME_ENUM, isTeslaMode } from '../../global';
import { updateQuestionThunk } from './redux/q-a-thunk-action';

export default {
  shouldLoad: (option) => {
    return !isTeslaMode() && option.isWebinar === 1;
  },
  [SOCKET_NAME_ENUM.XMPP]: {
    WebinarQA: (store, data) => {
      const { dispatch } = store;
      dispatch(
        updateQuestionThunk({
          ...data,
        }),
      );
    },
  },
  [SOCKET_NAME_ENUM.COMMAND]: {
    [WS_CONF_HOST_CHANGE_INDICATION]: (store) => {
      const { dispatch } = store;
      dispatch(clearQaData());
    },
    [WS_CONF_ATTRIBUTE_INDICATION]: (
      store,
      {
        body: {
          bAllowAnonymousQuestion,
          bAllowAttendeeViewAllQuestion,
          bAllowAttendeeUpvoteQuestion,
          bAllowAttendeeCommentQuestion,
          QaQuestionEnabled,
        },
      },
    ) => {
      const { dispatch } = store;
      if (bAllowAnonymousQuestion != null) {
        dispatch(setBAllowAnonymousQuestion(bAllowAnonymousQuestion));
      }
      if (bAllowAttendeeViewAllQuestion != null) {
        dispatch(
          setBAllowAttendeeViewAllQuestion(bAllowAttendeeViewAllQuestion),
        );
      }
      if (bAllowAttendeeUpvoteQuestion != null) {
        dispatch(setBAllowAttendeeUpvoteQuestion(bAllowAttendeeUpvoteQuestion));
      }
      if (bAllowAttendeeCommentQuestion != null) {
        dispatch(
          setBAllowAttendeeCommentQuestion(bAllowAttendeeCommentQuestion),
        );
      }
      if (QaQuestionEnabled != null) {
        dispatch(setEnableQuestion(QaQuestionEnabled));
      }
    },
  },
};
