import {
  setCameraControlleeGroup,
  setCameraControllerUserId,
  setVideoCameraPtzCapabilities,
  setPtz,
  setStartCameraControlGroup,
  setIsShowCameraControlNotification,
} from '../../redux/video-action';
import { manuallyChangeCameraThunk } from './change-camera-thunk';
import { removePinVideoListThunk } from './update-pin-thunk';
import {
  FAR_END_CAMERA_CONTROL_CMD,
  FAR_END_CAMERA_BUTTON_ID,
  FAR_END_CAMERA_CONTROL_DECLINED_REASON,
  PTZCameraStep,
  CAMERA_CONTROL_GROUP_ACTION_TYPE,
} from '../../enum';
import {
  controllerCanCameraControl,
  controlleeDeclineCameraControl,
  stoppedCameraControl,
  SELF_STOPPED_CAMERA_CONTROL,
  addedToAutoAnswerGroup,
  removedFromAutoAnswerGroup,
  addedFailedToAutoAnswerGroup,
  removeFailedFromAutoAnswerGroup,
} from '../../resource';
import * as socketEventTypes from '../../../../constants/ZoomSocketEventTypes';
import { sendSocketMessage } from '../../../../actions/SocketActions';
import {
  setCameraControlGroupDialogVisible,
  setFarEndCameraControlDialogVisible,
} from '../../../dialog/redux/dialog-action';
import AliveToast from '../../../../global/containers/notification-manager/alive-toast';
import { isSameUser } from '../../../../global/util';
import { globalVariable } from '../../../../global/global-variable';
import { MOVE_PTZ_CAMERA } from '../../../../constants/AVNotifyMediaSDKTypes';
import {
  getCurrentPtzConstraint,
  transformPtzConstraint,
  transformPtzRange,
} from '../../utils';
import { pinVideo } from '../../../dialog/redux/dialog-thunk-action';
import { SET_IS_ADDED_TO_CAMERA_CONTROL_GROUP } from '../../../../constants/MeetingActionTypes';
import {
  PWAMeetingEvent,
  sendMsgToPWA,
} from '../../../../global/pwa-integration';

export const sendFarEndCameraControl = (cmd, userId, buttonId) =>
  sendSocketMessage({
    evt: socketEventTypes.WS_CONF_FAR_END_CAMERA_CONTROL_REQ,
    body: {
      cmd,
      userID: userId,
      buttonID: buttonId,
    },
  });

export const requestFarEndCameraControl =
  (userId, userName, isStartCameraControl) => (dispatch, getState) => {
    const {
      video: {
        UI: { videoLayout },
      },
    } = getState();
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_FAR_END_CAMERA_CONTROL_REQ,
        body: {
          cmd: FAR_END_CAMERA_CONTROL_CMD.REQUEST,
          userID: userId,
          userInfo: isStartCameraControl ? 1 : 0,
        },
      }),
    );
    dispatch(
      pinVideo({
        type: 'add',
        userId,
        userName,
        videoLayout,
      }),
    );
  };

export const approveFarEndCameraControl = (userId) => (dispatch, getState) => {
  const {
    attendeesList: { attendeesList },
  } = getState();
  dispatch(sendFarEndCameraControl(FAR_END_CAMERA_CONTROL_CMD.APPROVE, userId));
  const userInfo = attendeesList.find((item) =>
    isSameUser(item.userId, userId),
  );
  if (userInfo) {
    dispatch(setCameraControllerUserId(userInfo.userId));
  }
};

export const declineFarEndCameraControl = (userId) => (dispatch) => {
  dispatch(sendFarEndCameraControl(FAR_END_CAMERA_CONTROL_CMD.DECLINE, userId));
  dispatch(setCameraControllerUserId());
  AliveToast.toast({ message: SELF_STOPPED_CAMERA_CONTROL });
};

export const giveUpFarEndCameraControl = (userId) => (dispatch) => {
  dispatch(sendFarEndCameraControl(FAR_END_CAMERA_CONTROL_CMD.GIVE_UP, userId));
  dispatch(stopFECCUI(userId));
  AliveToast.toast({ message: SELF_STOPPED_CAMERA_CONTROL });
};

const stopFECCUI = (userId) => (dispatch) => {
  dispatch(removePinVideoListThunk(userId));
  dispatch(setCameraControlleeGroup({ type: 'remove', userId }));
};

export const controlFarEndCamera = (option) => (dispatch) => {
  const { userId, cmd } = option;
  if (cmd === FAR_END_CAMERA_BUTTON_ID.SWITCH_CAMERA) {
    dispatch(
      sendFarEndCameraControl(
        FAR_END_CAMERA_CONTROL_CMD.SWITCH_CAMERA,
        userId,
        FAR_END_CAMERA_BUTTON_ID.SWITCH_CAMERA,
      ),
    );
  } else {
    dispatch(
      sendFarEndCameraControl(
        FAR_END_CAMERA_CONTROL_CMD.START_CONTROL,
        userId,
        cmd,
      ),
    );
    dispatch(
      sendFarEndCameraControl(
        FAR_END_CAMERA_CONTROL_CMD.CONTINUE_CONTROL,
        userId,
        cmd,
      ),
    );
    setTimeout(() => {
      dispatch(
        sendFarEndCameraControl(
          FAR_END_CAMERA_CONTROL_CMD.STOP_CONTROL,
          userId,
          cmd,
        ),
      );
    }, 200);
  }
};

export const sendCameraAblity = (ptz) => (dispatch) => {
  dispatch(
    sendSocketMessage({
      evt: socketEventTypes.WS_CONF_FAR_END_CAMERA_CONTROL_CAP_REQ,
      body: {
        ...ptz,
        focus: false,
      },
    }),
  );
};

export const handleCameraPTZ = (data) => (dispatch, getState) => {
  const {
    video: { activeCameraDevice },
  } = getState();
  if (activeCameraDevice === 'default') {
    dispatch(
      sendCameraAblity({
        pan: false,
        tilt: false,
        zoom: false,
      }),
    );
    return;
  }
  const { currentDeviceID, PTZRange } = data;
  if (currentDeviceID && PTZRange) {
    dispatch(
      setVideoCameraPtzCapabilities({
        deviceId: currentDeviceID,
        ptzRange: PTZRange,
      }),
    );
  }
  dispatch(
    sendCameraAblity({
      pan: !!PTZRange.pan,
      tilt: !!PTZRange.tilt,
      zoom: !!PTZRange.zoom,
    }),
  );
};

export const handleFarEndCameraLeft = (message) => (dispatch, getState) => {
  const {
    video: { cameraControlleeGroup, cameraControllerUserId },
  } = getState();
  if (cameraControlleeGroup.length > 0) {
    message.body.remove?.forEach((user) => {
      const userId = cameraControlleeGroup.find((item) =>
        isSameUser(item, user.id),
      );
      if (userId) {
        dispatch(stopFECCUI(userId));
      }
    });
  }
  if (cameraControllerUserId) {
    message.body.remove?.forEach((user) => {
      if (isSameUser(cameraControllerUserId, user.id)) {
        dispatch(setCameraControllerUserId());
      }
    });
  }
};

export const handleFarEndCameraControl = (data) => (dispatch, getState) => {
  const state = getState();
  const {
    attendeesList: { attendeesList },
    video: { cameraDevicesList, activeCameraDevice },
  } = state;
  const { nCmd, nUserID, nReason, nButtonID, nUserInfo } = data || {};
  const userInfo = attendeesList.find((item) =>
    isSameUser(item.userId, nUserID),
  );

  if (nCmd === FAR_END_CAMERA_CONTROL_CMD.REQUEST) {
    if (nUserInfo) {
      dispatch(approveFarEndCameraControl(nUserID));
    } else {
      dispatch(
        setFarEndCameraControlDialogVisible({
          visible: true,
          requester: { ...userInfo },
        }),
      );
    }
  } else if (nCmd === FAR_END_CAMERA_CONTROL_CMD.APPROVE) {
    AliveToast.toast({
      message: controllerCanCameraControl(userInfo.displayName),
    });
    dispatch(
      setCameraControlleeGroup({ type: 'add', userId: userInfo.userId }),
    );
  } else if (nCmd === FAR_END_CAMERA_CONTROL_CMD.DECLINE) {
    // controllee decline/stop -> controller -> UI change
    switch (nReason) {
      case FAR_END_CAMERA_CONTROL_DECLINED_REASON.NONE:
        AliveToast.toast({
          message: controlleeDeclineCameraControl(userInfo.displayName),
        });
        break;
      case FAR_END_CAMERA_CONTROL_DECLINED_REASON.APPROVE_ANOTHER:
      case FAR_END_CAMERA_CONTROL_DECLINED_REASON.STOP:
        dispatch(stopFECCUI(userInfo.userId));
        AliveToast.toast({
          message: stoppedCameraControl(userInfo.displayName),
        });
        break;
      default:
        break;
    }
  } else if (nCmd === FAR_END_CAMERA_CONTROL_CMD.GIVE_UP) {
    // controller stop -> controllee -> UI change
    dispatch(setCameraControllerUserId());
    AliveToast.toast({
      message: stoppedCameraControl(userInfo.displayName),
    });
  } else if (nCmd === FAR_END_CAMERA_CONTROL_CMD.START_CONTROL) {
    dispatch(controlPTZCamera(nButtonID));
  } else if (nCmd === FAR_END_CAMERA_CONTROL_CMD.SWITCH_CAMERA) {
    const index = cameraDevicesList.findIndex(
      (c) => c.deviceId === activeCameraDevice,
    );
    const total = cameraDevicesList.length;
    const nextIndex = index + 1 === total ? 0 : index + 1;
    if (nextIndex !== index) {
      const deviceId = cameraDevicesList[nextIndex].deviceId;
      dispatch(manuallyChangeCameraThunk(deviceId));
    }
  }
};

export const controlPTZCamera = (type) => (dispatch, getState) => {
  const {
    video: { ptz, activeCameraDevice },
  } = getState();
  const cameraPTZ = ptz[activeCameraDevice];
  const constraints = getCurrentPtzConstraint(cameraPTZ) || {};
  const { key, range: nRange } = transformPtzConstraint(type, PTZCameraStep);
  if (cameraPTZ[key]) {
    Object.assign(constraints, {
      [key]: transformPtzRange(
        cameraPTZ[key].capability,
        cameraPTZ[key].value,
        nRange,
      ),
    });
    movePTZCamera(constraints).then(() => {
      dispatch(
        setPtz({
          deviceId: activeCameraDevice,
          constraints,
        }),
      );
    });
  }
};

export function movePTZCamera(ptz) {
  return JsMediaSDK_Instance.util?.queryPTZPermisson?.().then((granted) => {
    if (granted) {
      globalVariable.avSocket.sendSocket(MOVE_PTZ_CAMERA, ptz);
      return Promise.resolve('Move camera successfully');
    } else {
      return Promise.reject('Cannot move camera without permission granted');
    }
  });
}

export const handleUserIsInCameraControlGroup =
  (message) => (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();
    const jids = message.body.add
      ?.filter((user) => user.id !== currentUser?.userId && user.strConfUserID)
      .map((user) => user.strConfUserID.toLowerCase());
    if (jids && Array.isArray(jids) && jids.length > 0) {
      sendMsgToPWA(PWAMeetingEvent.FECC_GROUP_REQUEST, jids);
    }
  };

export const handleReportUserInCameraControlGroup =
  (jids) => (dispatch, getState) => {
    const {
      attendeesList: { attendeesList },
    } = getState();
    jids.forEach((jid) => {
      const attendee = attendeesList.find(
        (item) => item.strConfUserID.toLowerCase() === jid,
      );
      if (attendee) {
        dispatch({
          type: SET_IS_ADDED_TO_CAMERA_CONTROL_GROUP,
          data: attendee.userId,
        });
        dispatch(
          sendSocketMessage({
            evt: socketEventTypes.WS_CONF_FAR_END_CAMERA_CONTROL_GROUP_REQ,
            body: {
              type: CAMERA_CONTROL_GROUP_ACTION_TYPE.EXISITED,
              id: attendee.userId,
            },
          }),
        );
      }
    });
  };

export const handleStartCameraControlGroup =
  ({ type, id }) =>
  (dispatch, getState) => {
    const {
      attendeesList: { attendeesList },
      dialog: {
        cameraControlGroup: { visible },
      },
      video: {
        isShowCameraControlNotification: { isShow },
      },
    } = getState();
    const attendee = attendeesList.find((item) => item.userId === id);
    if (attendee) {
      switch (type) {
        case CAMERA_CONTROL_GROUP_ACTION_TYPE.EXISITED:
          dispatch(
            setStartCameraControlGroup({
              action: 'add',
              type,
              strConfUserID: attendee.strConfUserID,
            }),
          );
          if (!visible && attendee.bVideoOn) {
            dispatch(
              setCameraControlGroupDialogVisible({
                visible: true,
                type: 'start',
                requester: attendee,
              }),
            );
          }
          break;
        case CAMERA_CONTROL_GROUP_ACTION_TYPE.NEW:
          dispatch(
            setStartCameraControlGroup({
              action: 'add',
              type,
              strConfUserID: attendee.strConfUserID,
            }),
          );
          if (!isShow && attendee.bVideoOn) {
            dispatch(
              setIsShowCameraControlNotification({
                isShow: true,
                userInfo: attendee,
              }),
            );
          }
          break;
        case CAMERA_CONTROL_GROUP_ACTION_TYPE.REMOVE:
          dispatch(
            setStartCameraControlGroup({
              action: 'remove',
              type,
              strConfUserID: attendee.strConfUserID,
            }),
          );
          break;
        default:
          break;
      }
    }
  };

export const addToAutoAnswerGroup = (requester) => () => {
  sendMsgToPWA(PWAMeetingEvent.FECC_GROUP_ADD, [
    requester.strConfUserID.toLowerCase(),
  ]);
};

export const addToAutoAnswerGroupResponse = (data) => (dispatch, getState) => {
  const { jid, isSuccess, isActionFromPwa } = data;
  const {
    attendeesList: { attendeesList },
  } = getState();
  const attendee = attendeesList.find(
    (item) => item.strConfUserID.toLowerCase() === jid,
  );
  if (attendee) {
    if (isSuccess) {
      dispatch({
        type: SET_IS_ADDED_TO_CAMERA_CONTROL_GROUP,
        data: attendee.userId,
      });
      dispatch(
        sendSocketMessage({
          evt: socketEventTypes.WS_CONF_FAR_END_CAMERA_CONTROL_GROUP_REQ,
          body: {
            type: CAMERA_CONTROL_GROUP_ACTION_TYPE.NEW,
            id: attendee.userId,
          },
        }),
      );
      if (!isActionFromPwa) {
        AliveToast.toast({
          message: addedToAutoAnswerGroup(attendee.displayName),
        });
      }
    } else {
      AliveToast.toast({
        message: addedFailedToAutoAnswerGroup(attendee.displayName),
      });
    }
  }
};

export const removeFromAutoAnswerGroup = (requester) => () => {
  sendMsgToPWA(PWAMeetingEvent.FECC_GROUP_REMOVE, [
    requester.strConfUserID.toLowerCase(),
  ]);
};

export const removeFromAutoAnswerGroupResponse =
  (data) => (dispatch, getState) => {
    const { jid, isSuccess, isActionFromPwa } = data;
    const {
      attendeesList: { attendeesList },
    } = getState();
    const attendee = attendeesList.find(
      (item) => item.strConfUserID.toLowerCase() === jid,
    );
    if (attendee) {
      if (isSuccess) {
        dispatch({
          type: SET_IS_ADDED_TO_CAMERA_CONTROL_GROUP,
          data: attendee.userId,
        });
        dispatch(
          sendSocketMessage({
            evt: socketEventTypes.WS_CONF_FAR_END_CAMERA_CONTROL_GROUP_REQ,
            body: {
              type: CAMERA_CONTROL_GROUP_ACTION_TYPE.REMOVE,
              id: attendee.userId,
            },
          }),
        );
        if (!isActionFromPwa) {
          AliveToast.toast({
            message: removedFromAutoAnswerGroup(attendee.displayName),
          });
        }
      } else {
        AliveToast.toast({
          message: removeFailedFromAutoAnswerGroup(attendee.displayName),
        });
      }
    }
  };
