import { sendSocketMessage } from '../../../actions/SocketActions';
import {
  // WS_CONF_CHAT_CMD_REQ,
  WS_CONF_CHAT_REQ,
} from '../../../constants/ZoomSocketEventTypes';
import { beginEncrypt, ivType } from '../../../global/crypto/main';
import { attendeesListSelector } from '../../../global/redux/selector';
import {
  // encodeBase64,
  getLocalTime,
  getSafeBase64,
} from '../../../global/util';
import { getXmppMsgFromToSenderNameMid, longestCommonSubString } from '../help';
import { AllTxt } from '../resource';
import { buildInvisibleAtEventXML } from '../transform/buildSendXmppMsgData';
import { findChatItemIndex, generateMentions } from './buildListMeetingChat';
import { updateNewMeetingChat, updateOneChatMessage } from './new-chat-action';
import { produce, applyPatches } from 'immer';
import meetingConfig from 'meetingConfig';

/**
 * @key messageId
 * @value {changes = [], inverseChanges = []}
 */
const changesMap = new Map();

export function updateEditedChatMessage(
  { text, xmppMsgId, timeStamp, rtbItems, atUsers },
  { foundedIndex, isGetPatches = false } = {},
) {
  return (dispatch, getState) => {
    const {
      newChat: { meetingChat },
      meeting: { currentUser },
    } = getState();
    let index;
    if (_.isUndefined(foundedIndex)) {
      const { hasFound, index: mainIndex } = findChatItemIndex(
        xmppMsgId,
        meetingChat,
      );
      index = mainIndex;
      if (!hasFound) return;
    } else {
      index = foundedIndex;
    }
    let newMeetingChat = meetingChat;
    const { mention, isAtAll, isAtMe } = generateMentions(
      atUsers,
      currentUser.strConfUserID,
    );
    newMeetingChat = produce(
      meetingChat,
      (draft) => {
        draft[index].content.text = text;
        draft[index].content.rtbItems = {
          ...rtbItems,
          mention: [...mention, ...(rtbItems.mention || [])],
        };
        draft[index].content.timeStamp = getLocalTime(timeStamp);
        draft[index].t = timeStamp || Date.now();
        draft[index].isEdited = true;

        draft[index].isAtAll = isAtAll;
        draft[index].isAtMe = isAtMe;

        return draft;
      },
      (patches, inversePatches) => {
        if (!isGetPatches) return;
        let patch = changesMap.get(xmppMsgId);
        if (!patch) {
          patch = {
            changes: [],
            inverseChanges: [],
          };
        }
        changesMap.set(xmppMsgId, {
          changes: [...patch.changes, ...patches],
          inverseChanges: [...patch.inverseChanges, ...inversePatches],
        });
      },
    );
    if (isGetPatches) return;
    dispatch(updateNewMeetingChat({ newMeetingChat }));
    // dispatch(updateEditFlag());
  };
}

export function onApplySelfChanges(msgId, isRevert) {
  return (dispatch, getState) => {
    const patch = changesMap.get(msgId);

    if (!patch) return;
    const {
      newChat: { meetingChat },
    } = getState();
    const newMeetingChat = applyPatches(
      meetingChat,
      !isRevert ? patch.changes : patch.inverseChanges,
    );
    changesMap.delete(msgId);
    dispatch(updateNewMeetingChat({ newMeetingChat }));
    // dispatch(updateEditFlag());
  };
}

export function onReverseSelfChanges(msgId) {
  return (dispatch, getState) => {
    const patch = changesMap.get(msgId);

    const {
      newChat: { meetingChat },
    } = getState();
    const newMeetingChat = applyPatches(meetingChat, patch.inverseChanges);
    changesMap.delete(msgId);
    dispatch(updateNewMeetingChat({ newMeetingChat }));
    // dispatch(updateEditFlag());
  };
}
/* @deprecated */
export function updateOneChatMessageThunk({ atId, events }) {
  return (dispatch, getState) => {
    const state = getState();
    const attendeesList = attendeesListSelector(state);
    const {
      newChat: { meetingChat },
    } = state;
    const targetIndex = _.findLastIndex(meetingChat, (n) => {
      return n.xmppMsgId === atId;
    });

    const mentionedChatItem = meetingChat[targetIndex];
    const mentionItems = events.flatMap(({ atJid }) => {
      // MeetingConfig.mid is not urlsafe base64

      const isAtAll =
        atJid.indexOf(meetingConfig.meetingOptions.channelId) !== -1 ||
        atJid.indexOf(getSafeBase64(meetingConfig.mid)) !== -1 ||
        atJid.endsWith('atall');
      const atUserJidInMeeting = atJid.toLowerCase().split('@')[0];
      const attendee =
        !isAtAll &&
        attendeesList.find(
          (u) => u.strConfUserID.toLowerCase() === atUserJidInMeeting,
        );

      const atName = isAtAll ? `@${AllTxt}` : `@${attendee.displayName}`;
      const text = mentionedChatItem.content.text;
      const { result, indexes } = longestCommonSubString(text, atName);
      // const matchedStr = atName.substring(0, result);
      // const startPos = text.indexOf(matchedStr);

      return indexes.map((i) => {
        return {
          type: 1,
          jid: atJid,
          start: i - result,
          end: i,
        };
      });
    });

    const newChatItem = produce(mentionedChatItem, (draft) => {
      if (draft.content.rtbItems.mention) {
        draft.content.rtbItems.mention.push(...mentionItems);
      } else {
        draft.content.rtbItems.mention = mentionItems;
      }
    });

    dispatch(updateOneChatMessage({ item: newChatItem, index: targetIndex }));
  };
}

export function sendMentionMessage({
  mention,
  localXmppMsgId,
  atMsgId,
  atMsgT,
  mainMsgId,
  mainMsgTime,
  destNodeID,
  attendeeNodeID,
  sn,
  ct,
  currentUser,
}) {
  return (dispatch) => {
    buildInvisibleAtEventXML({
      id: localXmppMsgId,
      atMsgT,
      atMsgId,
      mention,
      ct,
      mainMsgId,
      mainMsgTime,
      ...getXmppMsgFromToSenderNameMid(
        currentUser.strConfUserID,
        currentUser.displayName,
      ),
    })
      .then((xml) => {
        return beginEncrypt({
          text: xml,
          type: ivType.RWG_CHAT,
        });
      })
      .then((encryptedXML) => {
        dispatch(
          sendSocketMessage({
            evt: WS_CONF_CHAT_REQ,
            body: {
              text: '', //encodeBase64('[This is an encrypted message]'),
              xmppMsgData: encryptedXML,
              destNodeID,
              attendeeNodeID,
              sn,
              msgID: localXmppMsgId,
            },
          }),
        );
      });
  };
}
