import meetingConfig from 'meetingConfig';
import {
  SHARER_STATUS_ENUM,
  SHARER_PERMISSION_CODE,
  POP_UP_BOX_TYPE_ENUM,
} from '../enum';
import { GLOBAL_POPOVER_PATTERN_ENUM } from '../../../global/enum';
import {
  isHideMutedWhileSharingTips,
  isHideMutedWhileSharingTips2,
} from '../service';

const {
  meetingOptions: {
    isShareOptionLocked,
    isScreenShareEnabled,
    isShareScreenHostOnly,
    isMeetingScreenSharingGrabAll,
  },
  isSameAccount = true,
} = meetingConfig;
let sharerPermissionCode = SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_LOCK_SHARE;
if (!isShareScreenHostOnly) {
  if (isMeetingScreenSharingGrabAll) {
    sharerPermissionCode = SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_ANYONE_GRAB;
  } else {
    sharerPermissionCode = SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_HOST_GRAB;
  }
}
const sharingShape = {
  UI: {
    sharerShowPermissionSetting: false,
    popUpBox: {
      show: false,
      type: POP_UP_BOX_TYPE_ENUM.POP_UP_BOX_START_SHARE,
    },
    popOver: {
      show: false,
      type: GLOBAL_POPOVER_PATTERN_ENUM.DEFAULT,
    },
    sharerClientReceivedRectDimension: {
      width: 0,
      height: 0,
    },
    isSharingContentOriginalSize: false,
    shareeOriginalDimension: {
      width: 1920,
      height: 1080,
      logicWidth: 1920,
      logicHeight: 1080,
    },
    shareeContianerRect: {
      width: 0,
      height: 0,
    },
    isCopyButtonVisible: false,
    isNewSharerToastVisible: false,
    sharerIndicationVisible: false,
    forceStopSharingInBoNotificationVisible: false,
    shareSelfClientPreviewVisible: false,
  },
  sharerExtensionInstalled: false,
  sharerSsrc: 0,
  sharerEnabled: isScreenShareEnabled,
  sameAccount: isSameAccount,
  isLimitSharing: false,
  hasLimitSharingConfirmed: false,
  sharerStatus: SHARER_STATUS_ENUM.ED,
  sharerSharingCount: 0,
  sharerPermissionCode,
  sharerEncodeSdkInitialized: false,
  sharerIsShareOptionLocked: isShareOptionLocked,
  shareeDecodeSdkInitialized: false,
  shareeCurrentActiveNodeId: 0,
  shareeCurrentSSrc: 0,
  /** fps 0: 1fps; 1: 5fps; 2: 24fps */
  shareeCurrentReceivedShareFps: 1,
  /** rosolution 0: 90P; 1: 180P; 2: 360P; 3: 720P */
  shareeCurrentReceivedShareResolution: 1,
  isReceiveSharing: false,
  isRequestingRemoteControl: false,
  isRemoteControl: false,
  isGrabRemoteControl: false,
  hasNotSameAccountUser: false,
  /* During the meeting, when enable remote control multiple times,
  it will reuse the same socket channel,
  this option identify whether the SDK has already create the socket channel
  */
  isSDKGrabControl: false,
  isRemoteControlEnbaled: meetingConfig.meetingOptions.isRemoteControlEnbaled,
  shareeRemoteControlUserList: [],
  shareeRemoteControlClipboard: null,
  isWillShareToBO: false,
  isOptimizeVideoClip: false,
  isVideoShare: false,
  isSharingToBO: false,
  isReceiveSharingFromMainSession: false,
  hasShareBarDragged: false,
  canShareAudioDuringThisSharing: false,
  isSharingAudio: false,
  // localShareeAudioMutedStatus should be like {[userId]: true/false};
  // True means we should locally mute it when SWITCH TO this user
  // because it has been explicit by current user before. Vice versa
  localShareeAudioMutedStatus: {},
  isHideMutedWhileSharingTips: isHideMutedWhileSharingTips(),
  isHideMutedWhileSharingTips2: isHideMutedWhileSharingTips2(),
  remoteControlSendId: 0,
  // current controlling users's ssrc
  remoteControllingUserSsrc: 0,
  /** auto delete rc app or not when remote control ended */
  isEnableAutoDeleteRCApp: false,
  joinRemoteControlTimer: null,
  /** the userid list of the be controlled users */
  remoteControlledUserList: [],
  receiveSharingChannels: [],
  isLeaveSharingAudio: false,
  showSharingPerformantNotification: false,
  videoIsOnBeforeSharing: false,
  // The op flag
  isDisableShareAudioIntoBo:
    meetingConfig.meetingOptions.isDisableShareAudioIntoBo,
  hasEverClosedOldVersionCannotReceiveSharedSoundInBoNotification: false,
};

export { sharingShape };
