import { createActions } from 'redux-actions';

// dialog and window
const SET_CREATE_BO_ROOMS_WINDOW_VISIBLE = 'SET_CREATE_BO_ROOMS_WINDOW_VISIBLE';
const SET_MANAGE_BO_ROOMS_WINDOW_VISIBLE = 'SET_MANAGE_BO_ROOMS_WINDOW_VISIBLE';
const SET_REQUEST_JOIN_BO_ROOM_DIALOG_VISIBLE =
  'SET_REQUEST_JOIN_BO_ROOM_DIALOG_VISIBLE';
const SET_INVITE_JOIN_BO_ROOM_DIALOG_VISIBLE =
  'SET_INVITE_JOIN_BO_ROOM_DIALOG_VISIBLE';
const SET_ASK_FOR_HELP_DIALOG_VISIBLE = 'SET_ASK_FOR_HELP_DIALOG_VISIBLE';
const SET_LEAVE_ROOM_DIALOG_VISIBLE = 'SET_LEAVE_ROOM_DIALOG_VISIBLE';
const SET_NEED_HELP_DIALOG_VISIBLE = 'SET_NEED_HELP_DIALOG_VISIBLE';
const SET_TIME_UP_NOTIFY_DIALOG_VISIBLE = 'SET_TIME_UP_NOTIFY_DIALOG_VISIBLE';
const SET_ROOM_WILL_CLOSE_DIALOG_VISIBLE = 'SET_ROOM_WILL_CLOSE_DIALOG_VISIBLE';
const SET_RETURN_TO_MAIN_SESSION_DIALOG_VISIBLE =
  'SET_RETURN_TO_MAIN_SESSION_DIALOG_VISIBLE';
const SET_ROOM_HAS_STARTED_NOTIFY_VISIBLE =
  'SET_ROOM_HAS_STARTED_NOTIFY_VISIBLE';
const SET_WAITING_ROOM_TIP_VISIBLE = 'SET_WAITING_ROOM_TIP_VISIBLE';
const SET_SAVE_BO_DIALOG_VISIBLE = 'SET_SAVE_BO_DIALOG_VISIBLE';
const SET_SAVE_BO_SUCCESS_VISIBLE = 'SET_SAVE_BO_SUCCESS_VISIBLE';

const SET_IS_SCROLLING_ROOM_LIST_SCROLLBAR =
  'SET_IS_SCROLLING_ROOM_LIST_SCROLLBAR';
const SET_BO_ROOMS_DISTRIBUTION_PATTERN = 'SET_BO_ROOMS_DISTRIBUTION_PATTERN';
const SET_BO_ROOMS_HAS_CREATED = 'SET_BO_ROOMS_HAS_CREATED';
const SET_CONTROL_STATUS = 'SET_CONTROL_STATUS';
const ADD_ROOM = 'ADD_ROOM';
const ADD_ROOMS = 'ADD_ROOMS';
const DELETE_ROOM = 'DELETE_ROOM';
const RENAME_ROOM = 'RENAME_ROOM';
const UPDATE_ROOMS = 'UPDATE_ROOMS';
const UPDATE_TARGET_ROOM_TOKEN = 'UPDATE_TARGET_ROOM_TOKEN';

const SET_ROOM_LOCAL_ID = 'SET_ROOM_LOCAL_ID';
const INCREASE_ROOM_LOCAL_ID = 'INCREASE_ROOM_LOCAL_ID';
const RESET_ROOM_LOCAL_ID = 'RESET_ROOM_LOCAL_ID';
const ASSIGN_ATTENDEE_INTO_BO_ROOM = 'ASSIGN_ATTENDEE_INTO_BO_ROOM';
const RESET_ALL_ROOMS = 'RESET_ALL_ROOMS';
const MOVETO_ANOTHER_ROOM = 'MOVETO_ANOTHER_ROOM';
const EXCHANGE_ATTENDEE = 'EXCHANGE_ATTENDEE';

const BE_INVITED_TO_JOIN_ROOM = 'BE_INVITED_TO_JOIN_ROOM';
const SET_ATTENDEE_ROOM_STATUS = 'SET_ATTENDEE_ROOM_STATUS';
const SET_WHERE_JOIN_ROOM_POPOVER_TIP_VISIBLE =
  'SET_WHERE_JOIN_ROOM_POPOVER_TIP_VISIBLE';
const SET_WHERE_UNASSIGNED_ATTENDEES_POPOVER_TIP_VISIBLE =
  'SET_WHERE_UNASSIGNED_ATTENDEES_POPOVER_TIP_VISIBLE';

// options
const SET_OPTIONS_VALUE_AUTOJOIN = 'SET_OPTIONS_VALUE_AUTOJOIN';
const SET_OPTIONS_VALUE_BACKTOMAINSESSION =
  'SET_OPTIONS_VALUE_BACKTOMAINSESSION';
const SET_OPTIONS_VALUE_PARTICIPANTS_CHOOSE_ROOM =
  'SET_OPTIONS_VALUE_PARTICIPANTS_CHOOSE_ROOM';
const SET_OPTIONS_VALUE_AUTOCLOSETIMER = 'SET_OPTIONS_VALUE_AUTOCLOSETIMER';
const SET_OPTIONS_VALUE_TIMERDURATION = 'SET_OPTIONS_VALUE_TIMERDURATION';
const SET_OPTIONS_VALUE_TIMERAUTOEND = 'SET_OPTIONS_VALUE_TIMERAUTOEND';
const SET_OPTIONS_VALUE_NEEDCOUNTDOWN = 'SET_OPTIONS_VALUE_NEEDCOUNTDOWN';
const SET_OPTIONS_VALUE_WAITSECONDS = 'SET_OPTIONS_VALUE_WAITSECONDS';

const SET_ATTENDEE_ROOM_INFO = 'SET_ATTENDEE_ROOM_INFO';
const BO_ROOM_RESET = 'BO_ROOM_RESET';
const SET_AUTO_LEAVE_BO_MEETING_TIMER = 'SET_AUTO_LEAVE_BO_MEETING_TIMER';
const SET_BROADCAST_MESSAGE = 'SET_BROADCAST_MESSAGE';
const SET_ROOM_STARTED_TIME = 'SET_ROOM_STARTED_TIME';
const SET_BO_FACTOR_MEETING_START_TIME = 'SET_BO_FACTOR_MEETING_START_TIME';
const SET_IS_HOST_BEFORE_JOIN_BO = 'SET_IS_HOST_BEFORE_JOIN_BO';
const SET_JOIN_BO_CMD = 'SET_JOIN_BO_CMD';
const SET_HAS_NOTIFIED_HOST_TIME_UP = 'SET_HAS_NOTIFIED_HOST_TIME_UP';
const SET_WHEN_CLOSE_BO_STARTTIME = 'SET_WHEN_CLOSE_BO_STARTTIME';
const SET_SECOND_WS_CHANNEL_READY = 'SET_SECOND_WS_CHANNEL_READY';

const ADD_MAIN_SESSION_ATTENDEE_LIST = 'ADD_MAIN_SESSION_ATTENDEE_LIST';
const UPDATE_MAIN_SESSION_ATTENDEE_LIST = 'UPDATE_MAIN_SESSION_ATTENDEE_LIST';
const REMOVE_MAIN_SESSION_ATTENDEE_LIST = 'REMOVE_MAIN_SESSION_ATTENDEE_LIST';

const UPDATE_IS_MOVING_ATTENDEE = 'UPDATE_IS_MOVING_ATTENDEE';
const UPDATE_ROOM_SIZE_LIST = 'UPDATE_ROOM_SIZE_LIST';
const BATCH_UPDATE_ROOM_SIZE_LIST = 'BATCH_UPDATE_ROOM_SIZE_LIST';
const CLEAR_ROOM_SIZE_LIST = 'CLEAR_ROOM_SIZE_LIST';

const SET_JOIN_BO_CONF_ID = 'SET_JOIN_BO_CONF_ID';
const SET_HAS_RECEIVE_BO_CLOSING_RES = 'SET_HAS_RECEIVE_BO_CLOSING_RES';

const REMOVE_BO_ASSIGNED_USER_GUID = 'REMOVE_BO_ASSIGNED_USER_GUID';

const SET_IS_SUPPORT_HUGE_BO = 'SET_IS_SUPPORT_HUGE_BO';

const SET_PRE_ROOM_SIZE = 'SET_PRE_ROOM_SIZE';
const SET_BO_LOADING = 'SET_BO_LOADING';
const SET_PRE_ASSIGN_BO_LIST = 'SET_PRE_ASSIGN_BO_LIST';
const SET_SHOW_BO_BROADCAST_MESSAGE_PANEL =
  'SET_SHOW_BO_BROADCAST_MESSAGE_PANEL';
const SET_SHOW_BO_BROADCAST_VOICE_PANEL = 'SET_SHOW_BO_BROADCAST_VOICE_PANEL';
const SET_IS_BROADCAST_VOICE = 'SET_IS_BROADCAST_VOICE';
const SET_IS_RECV_BROADCAST_VOICE = 'SET_IS_RECV_BROADCAST_VOICE';
const SET_SHOW_BO_BROADCAST_VOICE_CONTROLLS =
  'SET_SHOW_BO_BROADCAST_VOICE_CONTROLLS';
const SET_SELF_SELECT_HAS_STARTED_POPOVER_VISIBLE =
  'SET_SELF_SELECT_HAS_STARTED_POPOVER_VISIBLE';
const SET_PRE_ASSIGNMENT_ENABLED = 'SET_PRE_ASSIGNMENT_ENABLED';
const ADD_PRE_ASSIGNMENT_TO_ASSIGN_DICT = 'ADD_PRE_ASSIGNMENT_TO_ASSIGN_DICT';
const DELETE_FROM_PRE_ASSIGNMENT_TO_ASSIGN_DICT =
  'DELETE_FROM_PRE_ASSIGNMENT_TO_ASSIGN_DICT';
const SET_HAS_RECEIVED_PRE_ASSIGN_RES = 'SET_HAS_RECEIVED_PRE_ASSIGN_RES';
const INC_PROTO_COUNT = 'INC_PROTO_COUNT';
const SET_LATEST_INVITER = 'SET_LATEST_INVITER';

const SET_WHO_START = 'SET_WHO_START';
const SET_WHO_STOP = 'SET_WHO_STOP';
const SET_JOIN_REASON = 'SET_JOIN_REASON';
const SET_HAS_CHECKED_FAILOVER_ATTENDEE_BO_STATUS =
  'SET_HAS_CHECKED_FAILOVER_ATTENDEE_BO_STATUS';
const SET_CLOSING_COUNT_DOWN_TIMER = 'SET_CLOSING_COUNT_DOWN_TIMER';

const SET_SHOW_STOP_SHARING_BEFORE_JOIN_BO_DIALOG_VISIBLE =
  'SET_SHOW_STOP_SHARING_BEFORE_JOIN_BO_DIALOG_VISIBLE';
const SET_MAIN_SESSION_CONF_ID = 'SET_MAIN_SESSION_CONF_ID';

const SET_IS_ADD_ROOM_WAITING_BO_ATTRIBUTE_INDICATION =
  'SET_IS_ADD_ROOM_WAITING_BO_ATTRIBUTE_INDICATION';

const SET_IS_INCLUDE_COHOST_CHECKED = 'SET_IS_INCLUDE_COHOST_CHECKED';

const SET_BO_ROOM_ACTIVE_KEYS = 'SET_BO_ROOM_ACTIVE_KEYS';
const SET_HAS_EVER_BEEN_PRE_ASSIGNED_DICT =
  'SET_HAS_EVER_BEEN_PRE_ASSIGNED_DICT';

const UPDATE_BREAKOUT_ROOM_ACTIVITY_STATUS_BY_USER =
  'UPDATE_BREAKOUT_ROOM_ACTIVITY_STATUS_BY_USER';
const AGREE_HOST_CAN_VIEW_USER_ACTIVITY_STATUS =
  'AGREE_HOST_CAN_VIEW_USER_ACTIVITY_STATUS';
const SET_MAIN_SESSION_AUDIO_ENCRYPT_INFO =
  'SET_MAIN_SESSION_AUDIO_ENCRYPT_INFO';
const SET_MAIN_SESSION_SHARE_ENCRYPT_INFO =
  'SET_MAIN_SESSION_SHARE_ENCRYPT_INFO';

const SET_OPTIONS_VALUE_AUTOMOVETOMAINSESSION =
  'SET_OPTIONS_VALUE_AUTOMOVETOMAINSESSION';

export const {
  setCreateBoRoomsWindowVisible,
  setManageBoRoomsWindowVisible,
  setRequestJoinBoRoomDialogVisible,
  setInviteJoinBoRoomDialogVisible,
  setLeaveRoomDialogVisible,
  setAskForHelpDialogVisible,
  setNeedHelpDialogVisible,
  setTimeUpNotifyDialogVisible,
  setRoomWillCloseDialogVisible,
  setReturnToMainSessionDialogVisible,
  setRoomHasStartedNotifyVisible,
  setWaitingRoomTipVisible,
  setIsScrollingRoomListScrollbar,
  setBoRoomsDistributionPattern,
  setBoRoomsHasCreated,
  setRoomLocalId,
  increaseRoomLocalId,
  resetRoomLocalId,
  addRoom,
  deleteRoom,
  renameRoom,
  updateRooms,
  updateTargetRoomToken,
  assignAttendeeIntoBoRoom,
  resetAllRooms,
  movetoAnotherRoom,
  setControlStatus,
  exchangeAttendee,
  beInvitedToJoinRoom,
  setAttendeeRoomStatus,
  setWhereJoinRoomPopoverTipVisible,
  setWhereUnassignedAttendeesPopoverTipVisible,
  setShowStopSharingBeforeJoinBoDialogVisible,
  setOptionsValueAutojoin,
  setOptionsValueBacktomainsession,
  setOptionsValueParticipantsChooseRoom,
  setOptionsValueAutoclosetimer,
  setOptionsValueTimerduration,
  setOptionsValueTimerautoend,
  setOptionsValueNeedcountdown,
  setOptionsValueWaitseconds,
  setAttendeeRoomInfo,
  boRoomReset,
  setAutoLeaveBoMeetingTimer,
  setBroadcastMessage,
  setRoomStartedTime,
  setBoFactorMeetingStartTime,
  addMainSessionAttendeeList,
  updateMainSessionAttendeeList,
  removeMainSessionAttendeeList,
  setIsHostBeforeJoinBo,
  setJoinBoCmd,
  setHasNotifiedHostTimeUp,
  setWhenCloseBoStarttime,
  setSecondWsChannelReady,
  updateIsMovingAttendee,
  updateRoomSizeList,
  batchUpdateRoomSizeList,
  clearRoomSizeList,
  setJoinBoConfId,
  setHasReceiveBoClosingRes,
  removeBoAssignedUserGuid,
  setIsSupportHugeBo,
  setPreRoomSize,
  addRooms,
  setBoLoading,
  setPreAssignBoList,
  setShowBoBroadcastMessagePanel,
  setShowBoBroadcastVoicePanel,
  setIsBroadcastVoice,
  setIsRecvBroadcastVoice,
  setShowBoBroadcastVoiceControlls,
  setSelfSelectHasStartedPopoverVisible,
  setPreAssignmentEnabled,
  addPreAssignmentToAssignDict,
  deleteFromPreAssignmentToAssignDict,
  setHasReceivedPreAssignRes,
  incProtoCount,
  setLatestInviter,
  setWhoStart,
  setWhoStop,
  setJoinReason,
  setHasCheckedFailoverAttendeeBoStatus,
  setClosingCountDownTimer,
  setMainSessionConfId,
  setIsAddRoomWaitingBoAttributeIndication,
  setIsIncludeCohostChecked,
  setBoRoomActiveKeys,
  setSaveBoDialogVisible,
  setSaveBoSuccessVisible,
  setHasEverBeenPreAssignedDict,
  updateBreakoutRoomActivityStatusByUser,
  agreeHostCanViewUserActivityStatus,
  setMainSessionAudioEncryptInfo,
  setMainSessionShareEncryptInfo,
  setOptionsValueAutomovetomainsession,
} = createActions({
  [SET_CREATE_BO_ROOMS_WINDOW_VISIBLE]: (payload) => payload,
  [SET_MANAGE_BO_ROOMS_WINDOW_VISIBLE]: (payload) => payload,
  [SET_REQUEST_JOIN_BO_ROOM_DIALOG_VISIBLE]: (payload) => payload,
  [SET_INVITE_JOIN_BO_ROOM_DIALOG_VISIBLE]: (payload) => payload,
  [SET_ASK_FOR_HELP_DIALOG_VISIBLE]: (payload) => payload,
  [SET_LEAVE_ROOM_DIALOG_VISIBLE]: (payload) => payload,
  [SET_NEED_HELP_DIALOG_VISIBLE]: (payload) => payload,
  [SET_TIME_UP_NOTIFY_DIALOG_VISIBLE]: (payload) => payload,
  [SET_ROOM_WILL_CLOSE_DIALOG_VISIBLE]: (payload) => payload,
  [SET_SHOW_STOP_SHARING_BEFORE_JOIN_BO_DIALOG_VISIBLE]: (payload) => payload,
  [SET_RETURN_TO_MAIN_SESSION_DIALOG_VISIBLE]: (payload) => payload,
  [SET_ROOM_HAS_STARTED_NOTIFY_VISIBLE]: (payload) => payload,
  [SET_WAITING_ROOM_TIP_VISIBLE]: (payload) => payload,
  [SET_IS_SCROLLING_ROOM_LIST_SCROLLBAR]: (payload) => payload,
  [SET_BO_ROOMS_DISTRIBUTION_PATTERN]: (payload) => payload,
  [SET_BO_ROOMS_HAS_CREATED]: (payload) => payload,
  [SET_ROOM_LOCAL_ID]: (payload) => payload,
  [INCREASE_ROOM_LOCAL_ID]: (payload) => payload,
  [RESET_ROOM_LOCAL_ID]: (payload) => payload,
  [ADD_ROOM]: (payload) => payload,
  [DELETE_ROOM]: (boId) => ({ boId }),
  [RENAME_ROOM]: ({ boId, newName }) => ({ boId, newName }),
  [UPDATE_ROOMS]: (payload) => payload,
  [UPDATE_TARGET_ROOM_TOKEN]: (payload) => payload,
  [ASSIGN_ATTENDEE_INTO_BO_ROOM]: ({ boId, assignedAttendeeIds }) => ({
    boId,
    assignedAttendeeIds,
  }),
  [RESET_ALL_ROOMS]: (payload) => payload,
  [MOVETO_ANOTHER_ROOM]: ({ attendeeId, curRoomId, targetRoomId }) => ({
    attendeeId,
    curRoomId,
    targetRoomId,
  }),
  [SET_CONTROL_STATUS]: (payload) => payload,
  [EXCHANGE_ATTENDEE]: ({
    srcRoomId,
    srcAttendeeId,
    desRoomId,
    desAttendeeId,
  }) => ({
    srcRoomId,
    srcAttendeeId,
    desRoomId,
    desAttendeeId,
  }),
  [BE_INVITED_TO_JOIN_ROOM]: ({ boId, name }) => ({ boId, name }),
  [SET_ATTENDEE_ROOM_STATUS]: (payload) => payload,
  [SET_WHERE_JOIN_ROOM_POPOVER_TIP_VISIBLE]: (payload) => payload,
  [SET_WHERE_UNASSIGNED_ATTENDEES_POPOVER_TIP_VISIBLE]: (payload) => payload,
  [SET_OPTIONS_VALUE_AUTOJOIN]: (payload) => payload,
  [SET_OPTIONS_VALUE_BACKTOMAINSESSION]: (payload) => payload,
  [SET_OPTIONS_VALUE_PARTICIPANTS_CHOOSE_ROOM]: (payload) => payload,
  [SET_OPTIONS_VALUE_AUTOCLOSETIMER]: (payload) => payload,
  [SET_OPTIONS_VALUE_TIMERAUTOEND]: (payload) => payload,
  [SET_OPTIONS_VALUE_TIMERDURATION]: (payload) => payload,
  [SET_OPTIONS_VALUE_NEEDCOUNTDOWN]: (payload) => payload,
  [SET_OPTIONS_VALUE_WAITSECONDS]: (payload) => payload,
  [SET_ATTENDEE_ROOM_INFO]: (payload) => payload,
  [BO_ROOM_RESET]: (payload) => payload,
  [SET_AUTO_LEAVE_BO_MEETING_TIMER]: (payload) => payload,
  [SET_BROADCAST_MESSAGE]: (payload) => payload,
  [SET_ROOM_STARTED_TIME]: (payload) => payload,
  [SET_BO_FACTOR_MEETING_START_TIME]: (payload) => payload,
  [ADD_MAIN_SESSION_ATTENDEE_LIST]: (payload) => payload,
  [UPDATE_MAIN_SESSION_ATTENDEE_LIST]: (payload) => payload,
  [REMOVE_MAIN_SESSION_ATTENDEE_LIST]: (payload) => payload,
  [SET_IS_HOST_BEFORE_JOIN_BO]: (payload) => payload,
  [SET_JOIN_BO_CMD]: (payload) => payload,
  [SET_HAS_NOTIFIED_HOST_TIME_UP]: (payload) => payload,
  [SET_WHEN_CLOSE_BO_STARTTIME]: (payload) => payload,
  [SET_SECOND_WS_CHANNEL_READY]: (payload) => payload,
  [UPDATE_IS_MOVING_ATTENDEE]: (payload) => payload,
  [UPDATE_ROOM_SIZE_LIST]: ({ boId, size }) => ({ boId, size }),
  [BATCH_UPDATE_ROOM_SIZE_LIST]: (payload) => payload,
  [CLEAR_ROOM_SIZE_LIST]: (payload) => payload,
  [SET_JOIN_BO_CONF_ID]: (payload) => payload,
  [SET_HAS_RECEIVE_BO_CLOSING_RES]: (payload) => payload,
  [REMOVE_BO_ASSIGNED_USER_GUID]: (payload) => payload,
  [SET_IS_SUPPORT_HUGE_BO]: (payload) => payload,
  [SET_PRE_ROOM_SIZE]: (payload) => payload,
  [ADD_ROOMS]: (payload) => payload,
  [SET_BO_LOADING]: (payload) => payload,
  [SET_PRE_ASSIGN_BO_LIST]: (payload) => payload,
  [SET_SHOW_BO_BROADCAST_MESSAGE_PANEL]: (payload) => payload,
  [SET_SHOW_BO_BROADCAST_VOICE_PANEL]: (payload) => payload,
  [SET_IS_BROADCAST_VOICE]: (payload) => payload,
  [SET_IS_RECV_BROADCAST_VOICE]: (payload) => payload,
  [SET_SHOW_BO_BROADCAST_VOICE_CONTROLLS]: (payload) => payload,
  [SET_SELF_SELECT_HAS_STARTED_POPOVER_VISIBLE]: (payload) => payload,
  [SET_PRE_ASSIGNMENT_ENABLED]: (payload) => payload,
  [ADD_PRE_ASSIGNMENT_TO_ASSIGN_DICT]: (payload) => payload,
  [DELETE_FROM_PRE_ASSIGNMENT_TO_ASSIGN_DICT]: (payload) => payload,
  [SET_HAS_RECEIVED_PRE_ASSIGN_RES]: (payload) => payload,
  [INC_PROTO_COUNT]: (payload) => payload,
  [SET_LATEST_INVITER]: (payload) => payload,
  [SET_WHO_START]: (payload) => payload,
  [SET_WHO_STOP]: (payload) => payload,
  [SET_JOIN_REASON]: (payload) => payload,
  [SET_HAS_CHECKED_FAILOVER_ATTENDEE_BO_STATUS]: (payload) => payload,
  [SET_CLOSING_COUNT_DOWN_TIMER]: (payload) => payload,
  [SET_MAIN_SESSION_CONF_ID]: (payload) => payload,
  [SET_IS_ADD_ROOM_WAITING_BO_ATTRIBUTE_INDICATION]: (payload) => payload,
  [SET_IS_INCLUDE_COHOST_CHECKED]: (payload) => payload,
  [SET_BO_ROOM_ACTIVE_KEYS]: (payload) => payload,
  [SET_SAVE_BO_DIALOG_VISIBLE]: (payload) => payload,
  [SET_SAVE_BO_SUCCESS_VISIBLE]: (payload) => payload,
  [SET_HAS_EVER_BEEN_PRE_ASSIGNED_DICT]: (payload) => payload,
  [UPDATE_BREAKOUT_ROOM_ACTIVITY_STATUS_BY_USER]: (payload) => payload,
  [AGREE_HOST_CAN_VIEW_USER_ACTIVITY_STATUS]: (payload) => payload,
  [SET_MAIN_SESSION_AUDIO_ENCRYPT_INFO]: (payload) => payload,
  [SET_MAIN_SESSION_SHARE_ENCRYPT_INFO]: (payload) => payload,
  [SET_OPTIONS_VALUE_AUTOMOVETOMAINSESSION]: (payload) => payload,
});
