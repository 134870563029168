/* eslint-disable @babel/new-cap */
import { sendSocketMessage } from '../../../actions/SocketActions';
import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import {
  addLiveStreamTokenReqId,
  setRequestingChannel,
  setLiveStreamStatus,
  setRequestPermissionInfo,
  setLiveStreamTip,
} from './live-stream-action';
import {
  LIVE_STREAM_STATUS,
  LIVE_STREAM_ACTION,
  LIVE_STREAM_BROADCAST_TOKEN_VALIDATION_MODE,
} from '../constant';
import {
  canStartLiveStreamSelector,
  canStopLiveStreamSelector,
} from './live-stream-selector';
import { TOKEN_TYPE } from '../../../global/constant';
import { isSDKSupportLocalLiveStream } from '../../../global';
import { setLlsPermissionDialogVisible } from '../../dialog/redux/dialog-action';
import { CUSTOM_STREAMING_SERVICE_TEXT } from '../resource';
import { easyStore, storeType } from '../../../global/easy-store';

const openLiveStreamPage = (mode, payload) => (dispatch, getState) => {
  const state = getState();
  const {
    meeting: { baseUrl, meetingNumber },
    liveStream: { broadcastToken, requestingChannel },
  } = state;

  switch (mode) {
    case LIVE_STREAM_BROADCAST_TOKEN_VALIDATION_MODE.LEGACY: {
      const { channel } = payload;
      const url = `${baseUrl}/broadcast/${meetingNumber}?c=${channel}&token=${broadcastToken}&from=webclient`;
      window.open(url, '_blank');
      break;
    }
    case LIVE_STREAM_BROADCAST_TOKEN_VALIDATION_MODE.UTK: {
      const { utk } = payload;
      const url = `${baseUrl}/broadcast/${meetingNumber}?c=${requestingChannel}&utk=${utk}&from=webclient`;
      window.open(url, '_blank');
      break;
    }
    default:
      break;
  }
};

const requestLiveStreamToken = () => (dispatch, getState) => {
  dispatch(addLiveStreamTokenReqId()); // Add the count before getState(). To make sure it will send the new req id.

  const state = getState();
  const {
    liveStream: { liveStreamTokenReqId },
  } = state;

  const data = {
    evt: socketEventTypes.WS_CONF_REQUEST_TOKEN,
    body: {
      type: TOKEN_TYPE.TOKEN_TYPE_USER_INFO,
      reqId: liveStreamTokenReqId,
    },
  };
  dispatch(sendSocketMessage(data));
};

let startLiveStreamTimeourHandler = null;
export const startLiveStream = (channel) => (dispatch, getState) => {
  const state = getState();
  const {
    liveStream: {
      requestingChannel,
      isEnableBroadCastValidatePermissionByUserToken,
    },
  } = state;
  const canStartLiveStream = canStartLiveStreamSelector(state);
  if (!canStartLiveStream) {
    return;
  }
  if (requestingChannel) {
    return;
  }
  easyStore.easySet('doIEnabledTheLiveStream', true, storeType.memory);

  if (!isEnableBroadCastValidatePermissionByUserToken) {
    dispatch(
      openLiveStreamPage(LIVE_STREAM_BROADCAST_TOKEN_VALIDATION_MODE.LEGACY, {
        channel,
      }),
    );
    return;
  }

  dispatch(setRequestingChannel(channel));
  dispatch(requestLiveStreamToken());

  startLiveStreamTimeourHandler = setTimeout(() => {
    dispatch(setRequestingChannel(null));
    dispatch(
      openLiveStreamPage(LIVE_STREAM_BROADCAST_TOKEN_VALIDATION_MODE.LEGACY, {
        channel,
      }),
    );
  }, 30000);
};

export const handleLiveStreamTokenResponse =
  (messageBody) => (dispatch, getState) => {
    const { utk, type, id } = messageBody;
    const state = getState();
    const {
      liveStream: { liveStreamTokenReqId, requestingChannel },
    } = state;

    if (
      type === TOKEN_TYPE.TOKEN_TYPE_USER_INFO &&
      id === liveStreamTokenReqId &&
      requestingChannel
    ) {
      dispatch(
        openLiveStreamPage(LIVE_STREAM_BROADCAST_TOKEN_VALIDATION_MODE.UTK, {
          utk,
        }),
      );
      dispatch(setRequestingChannel(null));
      if (startLiveStreamTimeourHandler) {
        clearTimeout(startLiveStreamTimeourHandler);
      }
    }
  };

export const stopLiveStream = () => (dispatch, getState) => {
  const state = getState();
  const canStopLiveStream = canStopLiveStreamSelector(state);
  if (!canStopLiveStream) {
    return;
  }
  const data = {
    evt: socketEventTypes.WS_CONF_LIVE_STREAM_ACTION,
    body: {
      action: LIVE_STREAM_ACTION.STOP_LIVE_STREAM,
    },
  };
  dispatch(sendSocketMessage(data));
  dispatch(
    setLiveStreamStatus({
      isLiveStreamOn: false,
      status: LIVE_STREAM_STATUS.OFF,
    }),
  );
};

export const handleRequestPermissionInfo =
  (messageBody) => (dispatch, getState) => {
    // userID reqID reqName reqURL
    const { userID } = messageBody;
    const {
      attendeesList: { attendeesList },
      dialog: {
        llsPermission: { visible },
      },
    } = getState();
    if (userID) {
      const participant = attendeesList.find(
        (attendee) => attendee.userId === userID,
      );
      if (
        participant &&
        !participant.bLocalLivestreamPermission &&
        !participant.bTokenSdkLivestreamPermission &&
        isSDKSupportLocalLiveStream(participant.caps)
      ) {
        messageBody.displayName = participant.displayName;
        dispatch(
          setRequestPermissionInfo({ action: 'add', data: messageBody }),
        );
        if (!visible) {
          dispatch(
            setLlsPermissionDialogVisible({
              visible: true,
              requester: messageBody,
            }),
          );
        }
      }
    }
  };

export const responseLocalLiveStreamPermission = (requester) => (dispatch) => {
  const data = {
    evt: socketEventTypes.WS_CONF_CONSENT_LOCAL_LIVE_STREAM_PERMISSION_REQ,
    body: requester,
  };
  dispatch(sendSocketMessage(data));
};

export const removeLocalLiveStreamPermission = (participant) => (dispatch) => {
  if (
    !participant.bLocalLivestreamPermission ||
    participant.bTokenSdkLivestreamPermission
  ) {
    return;
  }
  const data = {
    evt: socketEventTypes.WS_CONF_HOST_REMOVE_LOCAL_LIVE_STREAM_PERMISSION_REQ,
    body: {
      userID: participant.userId,
    },
  };
  dispatch(sendSocketMessage(data));
};

export const stopLocalLiveStream = (participant) => (dispatch) => {
  const data = {
    evt: socketEventTypes.WS_CONF_CHANGE_LOCAL_LIVE_STREAM_INFO_REQ,
    body: {
      ...participant,
      living: !participant.living,
    },
  };
  dispatch(sendSocketMessage(data));
};

export const handleLlsInfoUpdate = (message) => {
  return (dispatch, getState) => {
    const {
      liveStream: { isNewLocalLiveStreamOn: preIsNewLocalLiveStreamOn },
    } = getState();
    const isNewLocalLiveStreamOn = []
      .concat(message.body.add)
      .concat(message.body.update)
      .some((item) => item?.llsInfo?.living);
    if (preIsNewLocalLiveStreamOn !== isNewLocalLiveStreamOn) {
      dispatch(
        setLiveStreamStatus({
          isNewLocalLiveStreamOn,
        }),
      );
    }
    if (isNewLocalLiveStreamOn) {
      dispatch(setLiveStreamTip(CUSTOM_STREAMING_SERVICE_TEXT));
    }
  };
};
