/* eslint-disable react/prop-types */
import React from 'react';
import PreviewVideo from '../component/PreviewVideo';
import usePreviewVideo from '../hooks/usePreviewVideo';

const PreviewVideoContainer = (props) => {
  const {
    className,
    isAudioInitSuccess,
    isVideoInitSuccess,
    cache,
    canAudioUnmute,
    canVideoUnmute,
    displayName,
    size,
    isAudioEnabled = true,
    resizable,
    sessionBranding,
    avatar,
    needDefaultAvatar,
  } = props;
  const {
    audioStatus,
    videoStatus,
    nextAudioStatus,
    nextVideoStatus,
    willRenderVideo,
    error,
    setError,
    handleAudioClick,
    handleVideoClick,
  } = usePreviewVideo({
    isAudioInitSuccess: isAudioInitSuccess,
    isVideoInitSuccess: isVideoInitSuccess,
    cache: cache,
    canAudioUnmute: canAudioUnmute,
    canVideoUnmute: canVideoUnmute,
  });

  return (
    <PreviewVideo
      className={className}
      displayName={displayName}
      audioStatus={audioStatus}
      videoStatus={videoStatus}
      nextAudioStatus={nextAudioStatus}
      nextVideoStatus={nextVideoStatus}
      willRenderVideo={willRenderVideo}
      error={error}
      setError={setError}
      onAudioClick={handleAudioClick}
      onVideoClick={handleVideoClick}
      size={size}
      isAudioEnabled={isAudioEnabled}
      avatar={avatar}
      resizable={resizable}
      sessionBranding={sessionBranding}
      needDefaultAvatar={needDefaultAvatar}
    />
  );
};

export default PreviewVideoContainer;
