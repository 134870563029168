export const defaultOkButtonProps = {
  type: 'primary',
  size: 'default',
  className: 'zm-btn-legacy',
};
export const defaultCancelButtonProps = {
  type: 'default',
  size: 'default',
  className: 'zm-btn-legacy',
};
