import { AV_STRATEGY } from './enum';

const keys = ['isWebinar', 'isAudioBridge', 'useWBVideo'];

const hasValidateObjectKeys = (obj) => {
  for (let i = 0; i < keys.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(obj, keys[i])) return false;
  }

  return true;
};

/**
 * @param { isWebinar, isAudioBridge, useWBVideo } meetingDetails
 * @returns string
 */
export const getMeetingDetailsBinary = (meetingDetails) => {
  if (!meetingDetails) return '';

  if (
    typeof meetingDetails !== 'object' ||
    !hasValidateObjectKeys(meetingDetails)
  )
    return '';

  const meetingOrWebinar = meetingDetails?.isWebinar
    ? AV_STRATEGY.WEBINAR_BIT_VAL
    : AV_STRATEGY.MEETING_BIT_VAL;

  const audioBit = meetingDetails?.isAudioBridge
    ? AV_STRATEGY.WEBRTC_AUDIO_BIT_VAL
    : AV_STRATEGY.WASM_AUDIO_BIT_VAL;

  const videoBit = meetingDetails?.useWBVideo
    ? AV_STRATEGY.WEBRTC_VIDEO_BIT_VAL
    : AV_STRATEGY.WASM_VIDEO_BIT_VAL;

  const result = `${meetingOrWebinar}${audioBit}${videoBit}`;

  return result;
};
