import { easyStore, storeType } from '../easy-store';
import { isAudioBridge } from '../op-feature-option';

export const audioBridgeCheck = () => {
  const previousAudioBridge = easyStore.easyGet('previousAudioBridge');
  const currentAudioBridge = isAudioBridge();
  easyStore.easySet(
    'previousAudioBridge',
    currentAudioBridge,
    storeType.memory,
  );
  const audioBridgeToWasm = !!previousAudioBridge && !currentAudioBridge;
  return { currentAudioBridge, audioBridgeToWasm };
};
