export const WB_ROLE = {
  OWNER: 0,
  COOWNER: 1,
  EDITOR: 2,
  COMMENTER: 3,
  REVIEWER: 4,
};

export const WHITEBOARD_PERMISSION_CODE = {
  CMM_WB_SETTING_LOCK_SHARE: 0, //Only host can share, the same as "lock share"
  CMM_WB_SETTING_HOST_GRAB: 1, //Anyone can share, but one sharing only at one moment, and only host can grab other's sharing
  CMM_WB_SETTING_LOCK_SHARE_SECURITY: 2, //Same as lock share
  CMM_WB_SETTING_ANYONE_GRAB: 3, //Anyone can share, but one sharing only at one moment, and anyone can grab other's sharing
};

export const WHITEBOARD_STATUS = {
  OFF: 0,
  LOADING: 1,
  ON: 2,
};

export const FULL_SCREEN_HEADER_SPACE = 44;

export const WHITEBOARD_INIT_ROLE = {
  HOST_ONLY: 0,
  INTERNAL_USER: 1,
  ALL_PARTICIPANTS: 2,
};

export const WHITEBOARD_DASHBOARD = 'WHITEBOARD_DASHBOARD';
export const PWA_WB_DASHBOARD = 'PWA_WB_DASHBOARD';
