import meetingConfig from 'meetingConfig';
import { produce } from 'immer';
import axios from 'axios';
import _ from 'lodash';
import {
  updateChatReceiver,
  updateWebinarAttendeeReceiver,
  updateMeetingChat,
  setUploadCancelTokens,
  removeUploadCancelToken,
  updateChatReceiverUniqueId,
  set3rdPartyFileService,
  set3rdPartyFileWindowVisible,
  set3rdPartyFileCnonce,
} from './chat-action';
import {
  CHAT_PRIVILEDGE_ALL,
  CHAT_PRIVILEDGE_ALL_PANELIST,
  CHAT_PRIVILEDGE_HOST,
  CHAT_PRIVILEDGE_NOONE,
  CHAT_PRIVILEDGE_EVERYONE_PUBLICLY,
  PANELIST_CHAT_PRIVILEGE,
} from '../../../constants/Constants';
import {
  attendeesListSelector,
  meetingCoOrHostSelector,
  coOrHostSelector,
} from '../../../global/redux/selector';
import { isWebinarClientSupportCMDChat } from '../../../global/service';
import { beginDecrypt, beginEncrypt, ivType } from '../../../global/crypto';
import { readFileAsChunk } from '../../settings/service';
import {
  hostAndPanelistsText,
  everyoneText,
  meText,
  waitingRoomParticipantsText,
  hostsText,
} from '../resource';
import {
  CHAT_MSG_TYPE_TO_ALL,
  CHAT_MSG_TYPE_TO_PANELIST,
  CHAT_MSG_TYPE_TO_SILENT_MODE_USERS,
  CHAT_MSG_TYPE_TO_INDIVIDUAL,
  CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
  CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS,
  CHAT_FILE_UPLOAD_STATUS,
  CHAT_FILE_DOWNLOAD_STATUS,
  CHAT_FILE_CHUNK_SIZE,
  CHAT_FILE_ENCODE_BLOCK_SIZE,
  CHAT_FILE_DECODE_BLOCK_SIZE,
  CHAT_FILE_READER_CHUNK_SIZE,
  CHAT_FILE_UPLOAD_ERROR_TYPE,
  CHAT_FILE_TYPE,
  CHAT_MSG_TYPE_TO_INDIVIDUAL_SILENT_MODE_USER,
} from '../constants';
import {
  WS_CONF_CHAT_FILE_TRANSFER_REQ,
  WS_CONF_CHAT_REQ,
} from '../../../constants/ZoomSocketEventTypes';
import { xmppServer } from '../../../components/XMPPSocket';
import {
  getCorrect3rdPartyFileUrl,
  getFileDownloadUrl,
  getMimeTypeByName,
  isBuildInChatReceiver,
} from '../utils';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { CHAT_CONSTANTS, JOIN_MEETING_POLICY } from '../../../global/constant';
import { getLocalTime, encodeBase64, decodeBase64 } from '../../../global/util';
import { getIsFileLoading } from '../../interpretation/util';
import { isSupportedTimeZone } from '../../reaction/utils';

import { easyStore } from '../../../global/easy-store';
import {
  changePanelViewState,
  PanelName,
} from '../../../global/redux/set-panel-action';
import { doubleConfirmBeforeDownloadFile, resolveFileInfo } from '../service';
import { displayPMCUISelector } from './chat-selector';
import { addPmcHeader } from '../../new-chat/redux/new-chat-action';
import { PMCSyncTip } from '../../new-chat/resource';
import { generateUUID } from '../../../global/service/generate-uuid';
import { isWebinar } from '../../../global/service/meeting-types';
import { isPanelist, isViewOnly } from '../../../global/service/user-types';
import { makeLogger } from '../../../global/logger';
const CancelToken = axios.CancelToken;

const logger = makeLogger(['Old Chat File Transfer']);

const PMC_HEADER_ROW = {
  cat: CHAT_CONSTANTS.PMC_HEADER,
  msgId: 'msgId-placeholder',
  text: PMCSyncTip,
};

function checkIfAddPMCHeader() {
  return (dispatch, getState) => {
    const state = getState();
    const isPMCMeeting = displayPMCUISelector(state);

    if (!isPMCMeeting) return;
    const {
      newChat: { meetingChat },
      meetingUI: { panelViewResult },
    } = state;
    const hasPMCHeader = meetingChat[0]?.cat === CHAT_CONSTANTS.PMC_HEADER;
    if (isPMCMeeting && hasPMCHeader) return;
    if (isPMCMeeting && !panelViewResult[PanelName.chat] && !hasPMCHeader) {
      dispatch(addPmcHeader(PMC_HEADER_ROW));
    }
  };
}
export function toggleChatThunkAction(justOpen) {
  return (dispatch) => {
    if (!meetingConfig.meetingOptions.isChatEnabled) return;
    dispatch(checkIfAddPMCHeader(justOpen));
    dispatch(changePanelViewState(PanelName.chat, justOpen));
  };
}

function checkReceiverIsCoOrHost(meetingCoOrHost, data) {
  const foundAttendee = meetingCoOrHost.find(
    (item) => item.userId === data.receiverId,
  );
  return foundAttendee && (foundAttendee.isHost || foundAttendee.bCoHost);
}

function tryUpdateWebinarAttendeeReceiver(receiverId) {
  return (dispatch, getState) => {
    const {
      chat: { webinarAttendeeReceiver },
      meeting: { xmppUserList },
    } = getState();
    if (isWebinar()) {
      if (webinarAttendeeReceiver.indexOf(receiverId) === -1) {
        const foundAttendee = xmppUserList.attendees.find(
          (attendee) => attendee.node === receiverId,
        );
        if (foundAttendee) {
          const newReceivers = [...webinarAttendeeReceiver, receiverId];
          dispatch(updateWebinarAttendeeReceiver(newReceivers));
        }
      }
    }
  };
}

export function updateReceiverOnAttendeeFailover(attendee) {
  return (dispatch, getState) => {
    const {
      chat: { receiverUniqueId },
    } = getState();
    if (receiverUniqueId && receiverUniqueId === attendee.jid) {
      dispatch(
        updateChatReceiver({
          receiver: attendee.name,
          receiverId: attendee.node,
        }),
      );
    }
  };
}

export function updateReceiverOnUserFailover(addItem) {
  return (dispatch) => {
    dispatch(
      tryUpdateChatReceiver({
        receiver: decodeBase64(addItem.dn2, true),
        receiverId: addItem.id,
      }),
    );
  };
}

export function updateChatReceiverUniqueIdOnReceiverChange(receiverId) {
  return (dispatch, getState) => {
    if (isBuildInChatReceiver(receiverId)) {
      dispatch(updateChatReceiverUniqueId({ receiverUniqueId: '' }));
    } else {
      const state = getState();
      const attendeesList = attendeesListSelector(state);
      const targetUser = attendeesList.find(
        (user) => user.userId === receiverId,
      );
      if (targetUser) {
        dispatch(
          updateChatReceiverUniqueId({
            receiverUniqueId: targetUser.zoomID,
          }),
        );
      } else {
        dispatch(updateChatReceiverUniqueId({ receiverUniqueId: '' }));
      }
    }
  };
}

export function tryUpdateChatReceiver(data) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: { currentUser },
      attendeesList: { attendeesList },
    } = state;
    const chatPriviledge = state.meeting.chatPriviledge;
    const userRole = currentUser.userRole;
    const coOrHost = coOrHostSelector(state);
    const meetingCoOrHost = meetingCoOrHostSelector(state);
    const currentUserId = currentUser.userId;

    if (currentUserId === data.receiverId) {
      return;
    }
    if (!coOrHost) {
      if (isWebinar() && isViewOnly(userRole)) {
        if (data.receiverId !== 0 && !data.receiverId) {
          return;
        }
        if (chatPriviledge === CHAT_PRIVILEDGE_NOONE) {
          return;
        }
        if (
          chatPriviledge === CHAT_PRIVILEDGE_ALL_PANELIST &&
          data.receiverId !== CHAT_MSG_TYPE_TO_PANELIST
        ) {
          return;
        }
        if (chatPriviledge === CHAT_PRIVILEDGE_ALL_PANELIST) {
          dispatch(updateChatReceiver(data));
          return;
        }
        if (chatPriviledge === CHAT_PRIVILEDGE_ALL) {
          if (data.receiverId > 1) {
            return;
          }
        }
      }
      if (
        chatPriviledge === CHAT_PRIVILEDGE_NOONE &&
        (!isWebinar() || !isPanelist(userRole))
      ) {
        return;
      }
      if (chatPriviledge === CHAT_PRIVILEDGE_HOST) {
        if (
          data.receiverId === CHAT_MSG_TYPE_TO_ALL ||
          !checkReceiverIsCoOrHost(meetingCoOrHost, data)
        ) {
          return;
        }
      }
      if (chatPriviledge === CHAT_PRIVILEDGE_EVERYONE_PUBLICLY) {
        if (
          data.receiverId !== CHAT_MSG_TYPE_TO_ALL &&
          !checkReceiverIsCoOrHost(meetingCoOrHost, data)
        ) {
          return;
        }
      }
    }

    if (data.receiverId === CHAT_MSG_TYPE_TO_INDIVIDUAL_SILENT_MODE_USER) {
      if (!coOrHost) return;
      const target = attendeesList.find(
        (user) => user.zoomID === data.receiverSN,
      );
      if (target) {
        return dispatch(
          updateChatReceiver({
            receiverId: target.userId,
            receiver: target.displayName,
          }),
        );
      }
    }

    dispatch(tryUpdateWebinarAttendeeReceiver(data.receiverId));
    dispatch(updateChatReceiver(data));
  };
}
function buildMeetingChat(
  meetingChat,
  attendees,
  attendeesList,
  currentUserId,
  currentUserRole,
  defaultAvatar,
  {
    text,
    receiverId,
    receiver: messageReceiver,
    receiverJid,
    senderId,
    sender,
    isSenderMe,
    isConveyByXmpp,
    type,
    msgId,
    isChatPinBottom,
    isSilentModeUser = false,
    file,
  },
) {
  const lastChatMessage = _.last(meetingChat);
  const isFile = !_.isEmpty(file);
  let isAppendToLastMessage = false;
  let newMeetingChat = meetingChat;
  // In order to gaurantee the correctness of avatar, search the right avarar every time.
  // Should be improved in terms of performance.
  let chatSender;
  let chatReceiver;
  if (attendeesList && attendeesList.length > 0) {
    chatSender = attendeesList.find((user) => user.userId === senderId);
    chatReceiver = attendeesList.find((user) => user.userId === receiverId);
  }
  if (isWebinar() && attendees && attendees.length > 0) {
    if (!chatSender) {
      chatSender = attendees.find((user) => user.node === senderId);
    }
    if (!chatReceiver) {
      chatReceiver = attendees.find((user) => user.node === receiverId);
    }
  }

  if (meetingChat.length > 0) {
    const {
      senderId: lastSenderId,
      receiverId: lastReceiverId,
      receiverJid: lastReceiverJid,
      sender: lastSender,
      isSilentModeUser: lastIsSilentModeUser,
    } = lastChatMessage;
    if (senderId === lastSenderId && sender === lastSender) {
      if (isConveyByXmpp) {
        isAppendToLastMessage = lastReceiverJid === receiverJid;
      } else {
        isAppendToLastMessage =
          lastReceiverId === receiverId &&
          isSilentModeUser === lastIsSilentModeUser &&
          !isFile;
      }
    }
  }
  if (isAppendToLastMessage) {
    newMeetingChat = produce(meetingChat, (draft) => {
      const lastChat = _.last(draft);
      const { chatMsgs } = lastChat;
      chatMsgs.push({
        text,
        msgId,
        read: senderId === currentUserId || isChatPinBottom,
        file,
      });
    });
  } else {
    let receiver = null;
    let isShowPrivately = false;
    let isCCAllPanelists = false;
    let isSilentModeUser = false;
    let isReceiverMe = false;
    if (receiverId === CHAT_MSG_TYPE_TO_ALL) {
      receiver = everyoneText;
      chatReceiver = null;
    } else if (receiverId === CHAT_MSG_TYPE_TO_PANELIST) {
      receiver = hostAndPanelistsText;
      chatReceiver = null;
    } else if (receiverId === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS) {
      /* host chat to all in WR */
      receiver = waitingRoomParticipantsText;
      chatReceiver = null;
    } else if (receiverId === CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS) {
      /* WR chat to hosts group */
      receiver = hostsText;
      chatReceiver = null;
    } else if (receiverId === currentUserId) {
      receiver = meText;
      isReceiverMe = true;
      const currentUser = attendeesList?.find(
        (user) => user.userId === currentUserId,
      );
      chatReceiver = currentUser;
      if (isViewOnly(currentUserRole)) {
        if (type === CHAT_MSG_TYPE_TO_INDIVIDUAL) {
          isShowPrivately = true;
        } else {
          isCCAllPanelists = true;
        }
      } else if (currentUser.bHold) {
        isSilentModeUser = true;
      } else {
        isShowPrivately = true;
      }
    } else if (isConveyByXmpp) {
      receiver = messageReceiver;
      isCCAllPanelists = true;
    } else if (
      isWebinar() &&
      attendees.findIndex((user) => user.node === receiverId) > -1
    ) {
      const chatUser = attendees.find((user) => user.node === receiverId);
      if (chatUser) {
        receiver = chatUser.name;
      }
      isCCAllPanelists = true;
    } else {
      if (chatReceiver) {
        receiver = chatReceiver.displayName;
        isShowPrivately = !chatReceiver.bHold;
        isSilentModeUser = chatReceiver.bHold;
      }
    }
    if (receiver !== null) {
      const newChatMessage = {
        chatMsgs: [
          {
            text,
            msgId,
            read: senderId === currentUserId || isChatPinBottom,
            file,
          },
        ],
        chatSender: { ...chatSender },
        chatReceiver: { ...chatReceiver },
        sender,
        senderId,
        isSenderMe,
        receiver,
        receiverId,
        isReceiverMe,
        receiverJid,
        timeStamp: getLocalTime(),
        isShowPrivately,
        isCCAllPanelists,
        isSilentModeUser,
      };
      newMeetingChat = produce(meetingChat, (draft) => {
        draft.push(newChatMessage);
      });
    }
  }
  return newMeetingChat;
}

export function updateSenderChatMessage(chatMessage, isSendByXmpp = false) {
  return (dispatch, getState) => {
    const {
      chat: { meetingChat, isChatPinBottom },
      meeting: {
        currentUser: { userId: currentUserId, userRole: currentUserRole },
        xmppUserList: { attendees },
        defaultAvatar,
      },
      attendeesList: { attendeesList },
    } = getState();
    const { text, receiverId, receiverJid, receiver, isSilentModeUser, file } =
      chatMessage;
    const newMeetingChat = buildMeetingChat(
      meetingChat,
      attendees,
      attendeesList,
      currentUserId,
      currentUserRole,
      defaultAvatar,
      {
        text,
        receiverId,
        receiver,
        receiverJid,
        senderId: currentUserId,
        sender: meText,
        isSenderMe: true,
        isConveyByXmpp: isSendByXmpp,
        isChatPinBottom,
        isSilentModeUser,
        file,
      },
    );
    dispatch(updateMeetingChat(newMeetingChat));
  };
}

export function tryUpdateChatReceiverName(message) {
  return (dispatch, getState) => {
    if (message.body.update) {
      const {
        chat: { receiverId },
      } = getState();
      const targetUser = message.body.update.find(
        (user) => user.id === receiverId,
      );
      if (targetUser && targetUser.dn2 !== undefined) {
        const displayName = decodeBase64(targetUser.dn2);
        dispatch(
          updateChatReceiver({
            receiverId,
            receiver: displayName,
          }),
        );
      }
    }
  };
}

export function tryUpdateAttendeeReceiverName() {
  return (dispatch, getState) => {
    const {
      meeting: {
        xmppUserList: { attendees },
      },
      chat: { receiverId },
    } = getState();
    const xmppReceiver = attendees.find((user) => user.node === receiverId);
    if (xmppReceiver) {
      dispatch(
        updateChatReceiver({
          receiver: xmppReceiver.name,
          receiverId,
        }),
      );
    }
  };
}

export function onReceiveChatMessage(message) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: {
        bIbDisableChat,
        xmppUserList: { attendees },
        currentUser: { userId: currentUserId, userRole: currentUserRole },
        defaultAvatar,
        restrictFeatures,
        fileServerDomain,
      },
      attendeesList: { attendeesList },
      chat: { meetingChat, isChatPinBottom },
      meetingUI: { isOnHold },
    } = state;
    if (restrictFeatures[JOIN_MEETING_POLICY.CHAT]) return;
    const coOrHost = coOrHostSelector(state);
    /**
     * when panelist using native client sends chat messages to attendees,
     * they received two identical messages from RWG Socket Channel and XMPP Socket Channel.
     * That is duplicate.
     */
    const { attendeeNodeID, msgID } = message.body || {};
    const isDuplicateChatMessage =
      isViewOnly(currentUserRole) && attendeeNodeID === currentUserId && !msgID;
    if (
      typeof message.body !== 'undefined' &&
      !bIbDisableChat &&
      !isDuplicateChatMessage
    ) {
      const latestChatMessage = message.body;
      let senderSN = latestChatMessage.sn;
      let targetUser;
      if (!senderSN) {
        targetUser = attendeesList.find(
          (user) => user.userId === latestChatMessage.destNodeID,
        );
        if (targetUser) {
          senderSN = targetUser.zoomID;
        }
      }
      /**
       * waiting room messages are unencrypted
       */
      const receiveUser = attendeesList.find(
        (user) => user.userId === latestChatMessage.attendeeNodeID,
      );
      let fileInfo;
      if (
        !_.isNil(latestChatMessage.fileType) &&
        easyStore.easyGet('gcmEnabled')
      ) {
        fileInfo = _.pick(latestChatMessage, [
          'fileID',
          'fileType',
          'fileName',
          'fileSize',
          'fileObj',
          'senderName',
          'shareType',
          'previewUrl',
          'previewPath',
          'downloadUrl',
          'destNodeID',
          'bEncrypted',
        ]);
        fileInfo.senderSN = senderSN;
        fileInfo.fileUrl = getFileDownloadUrl(fileInfo, fileServerDomain);
        fileInfo.uploadBaseInfo = { fileMsgIndex: meetingChat.length };
        fileInfo.correctPreviewUrl = getCorrect3rdPartyFileUrl(fileInfo);
      }
      if (
        latestChatMessage.attendeeNodeID ===
          CHAT_MSG_TYPE_TO_SILENT_MODE_USERS ||
        latestChatMessage.attendeeNodeID ===
          CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS ||
        isOnHold ||
        (coOrHost && receiveUser?.bHold)
      ) {
        const text = decodeBase64(latestChatMessage.text || '');
        let senderText = hostsText;
        if (isOnHold) {
          senderText = hostsText;
        } else if (targetUser) {
          senderText = targetUser.displayName;
        }
        const newMeetingChat = buildMeetingChat(
          meetingChat,
          attendees,
          attendeesList,
          currentUserId,
          currentUserRole,
          defaultAvatar,
          {
            text,
            receiverId: latestChatMessage.attendeeNodeID,
            receiver: null,
            senderId: latestChatMessage.destNodeID,
            sender: senderText,
            isConveyByXmpp: false,
            msgId: latestChatMessage.msgID,
            isChatPinBottom,
            isSilentModeUser: Boolean(receiveUser),
            file: fileInfo,
          },
        );
        dispatch(updateMeetingChat(newMeetingChat));
      } else {
        const sender = attendeesList.find(
          (user) => user.userId === latestChatMessage.destNodeID,
        );
        /* eslint-disable-next-line no-prototype-builtins */
        const senderName = latestChatMessage.hasOwnProperty('senderName')
          ? decodeBase64(latestChatMessage.senderName)
          : sender.displayName;
        if (fileInfo) {
          const updateMeetingChatWithFile = (fileInfo) => {
            return (dispatch, getState) => {
              const {
                chat: { meetingChat: meetingChat2 },
              } = getState();
              const newMeetingChat = buildMeetingChat(
                meetingChat2,
                attendees,
                attendeesList,
                currentUserId,
                currentUserRole,
                defaultAvatar,
                {
                  receiverId: latestChatMessage.attendeeNodeID,
                  receiver: null,
                  senderId: latestChatMessage.destNodeID,
                  sender: senderName,
                  isConveyByXmpp: false,
                  msgId: latestChatMessage.msgID,
                  isChatPinBottom,
                  file: fileInfo,
                },
              );
              dispatch(updateMeetingChat(newMeetingChat));
              if (!isViewOnly(currentUserRole)) {
                dispatch(
                  tryUpdateWebinarAttendeeReceiver(
                    latestChatMessage.destNodeID,
                  ),
                );
              }
            };
          };
          dispatch(
            resolveFileInfo(fileInfo, senderSN, updateMeetingChatWithFile),
          );
        } else {
          beginDecrypt({
            decryptedText: latestChatMessage.text,
            userId: senderSN,
            type: ivType.RWG_CHAT,
          }).then(({ message: _text }) => {
            const {
              chat: { meetingChat: meetingChat2 },
            } = getState();
            let text = _text;
            if (
              _text.includes('🇹🇼') &&
              meetingConfig.meetingOptions.enableFilterTWEmoji === true &&
              !isSupportedTimeZone()
            ) {
              text = _text.replaceAll('🇹🇼', 'TW');
            }
            if (text.includes('\r')) text = text.replace(/\r/g, '\r\n');
            const newMeetingChat = buildMeetingChat(
              meetingChat2,
              attendees,
              attendeesList,
              currentUserId,
              currentUserRole,
              defaultAvatar,
              {
                text,
                receiverId: latestChatMessage.attendeeNodeID,
                receiver: null,
                senderId: latestChatMessage.destNodeID,
                sender: senderName,
                isConveyByXmpp: false,
                msgId: latestChatMessage.msgID,
                isChatPinBottom,
              },
            );
            dispatch(updateMeetingChat(newMeetingChat));
            if (!isViewOnly(currentUserRole)) {
              dispatch(
                tryUpdateWebinarAttendeeReceiver(latestChatMessage.destNodeID),
              );
            }
          });
        }
      }
    }
  };
}
/**
 * only for chat messages which sended from panelists to attendee themselves
 * and attendee send message to panelist
 */
export function onReceiveXmppChatMessage(message) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userRole: currentUserRole, userId: currentUserId },
        jid,
        xmppUserList: { host, panelists, attendees },
        defaultAvatar,
        restrictFeatures,
      },
      chat: { meetingChat, isChatPinBottom },
    } = getState();
    if (restrictFeatures[JOIN_MEETING_POLICY.CHAT]) return;
    const { sn, senderName, senderJid, receiver, text, type } = message;

    beginDecrypt({
      decryptedText: text,
      type: ivType.XMPP_CHAT,
      userId: sn,
    }).then(({ message: text }) => {
      let sender = null;
      let receiverId = null;
      if (isViewOnly(currentUserRole) && receiver === jid) {
        sender = host.concat(panelists).find((user) => user.jid === senderJid);
        receiverId = currentUserId;
      } else {
        sender = attendees.find((user) => user.jid === senderJid);
        receiverId = CHAT_MSG_TYPE_TO_PANELIST;
      }
      const newMeetingChat = buildMeetingChat(
        meetingChat,
        null,
        null,
        currentUserId,
        currentUserRole,
        defaultAvatar,
        {
          text,
          receiverId,
          receiver: null,
          receiverJid: receiver,
          senderId: sender && sender.node,
          sender: senderName,
          isConveyByXmpp: true,
          type,
          isChatPinBottom,
        },
      );
      dispatch(updateMeetingChat(newMeetingChat));
    });
  };
}

export function updateReceiverOnChatPriviledgeChange(chatPriviledge) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      attendeesList: { attendeesList },
      chat: { receiverId },
      meeting: {
        currentUser: { userRole: currentUserRole },
      },
    } = state;
    const coOrHost = coOrHostSelector(state);
    const meetingCoOrHost = meetingCoOrHostSelector(state);
    if (!coOrHost) {
      const receiverUser = attendeesList.find(
        (user) => user.userId === receiverId,
      );
      const isReceiverHost =
        receiverUser && (receiverUser.isHost || receiverUser.bCoHost);
      if (chatPriviledge === CHAT_PRIVILEDGE_HOST) {
        if (
          !isReceiverHost &&
          meetingCoOrHost.length &&
          !meetingConfig.isOriginhost
        ) {
          dispatch(
            updateChatReceiver({
              receiver: meetingCoOrHost[0].displayName,
              receiverId: meetingCoOrHost[0].userId,
            }),
          );
        }
      } else if (chatPriviledge === CHAT_PRIVILEDGE_EVERYONE_PUBLICLY) {
        if (!isReceiverHost && receiverId !== CHAT_MSG_TYPE_TO_ALL) {
          dispatch(
            updateChatReceiver({
              receiver: everyoneText,
              receiverId: CHAT_MSG_TYPE_TO_ALL,
            }),
          );
        }
      } else if (
        chatPriviledge === CHAT_PRIVILEDGE_ALL_PANELIST &&
        isViewOnly(currentUserRole)
      ) {
        dispatch(
          updateChatReceiver({
            receiver: hostAndPanelistsText,
            receiverId: CHAT_MSG_TYPE_TO_PANELIST,
          }),
        );
      }
    }
  };
}

export function updateReceiverOnPanelistChatPriviledgeChange(
  panelistChatPriviledge,
) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      chat: { receiverId, receiver },
      attendeesList: { attendeesList },
    } = state;
    let receiverObj = {
      receiverId,
      receiver,
    };
    if (attendeesList.find((user) => user.userId === receiverId)) return;
    switch (panelistChatPriviledge) {
      case PANELIST_CHAT_PRIVILEGE.ALL_PANELIST:
        receiverObj = {
          receiver: hostAndPanelistsText,
          receiverId: CHAT_MSG_TYPE_TO_PANELIST,
        };
        break;
      default:
        return;
    }
    dispatch(updateChatReceiver(receiverObj));
  };
}

export function sendChatThunk(text, destNode) {
  return (dispatch, getState) => {
    const {
      meeting: { zoomId },
    } = getState();
    const sendRwgChat = (destNodeID, attendeeNodeID = 0) => {
      return beginEncrypt({ text, type: ivType.RWG_CHAT }).then(
        (encodeText) => {
          dispatch(
            sendSocketMessage({
              evt: WS_CONF_CHAT_REQ,
              body: {
                text: encodeText,
                destNodeID,
                attendeeNodeID,
                sn: zoomId,
              },
            }),
          );
          return {
            text,
            receiverId: attendeeNodeID || destNodeID,
            type: 'rwg',
          };
        },
      );
    };

    const sendXmppChat = () => {
      return beginEncrypt({ text, type: ivType.XMPP_CHAT }).then(
        (encodeText) => {
          xmppServer.sendWebinarMsg(
            encodeText,
            destNode.jid,
            CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
            easyStore.easyGet('gcmEnabled'),
          );
          return {
            text,
            receiver: destNode.name,
            receiverId: destNode.node,
            receiverJid: destNode.jid,
            type: 'xmpp',
          };
        },
      );
    };

    return Promise.resolve(0)
      .then(() => {
        const promiseCollection = [];
        if (
          destNode === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS ||
          destNode === CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS
        ) {
          dispatch(
            sendSocketMessage({
              evt: WS_CONF_CHAT_REQ,
              body: {
                text: encodeBase64(text),
                destNodeID: destNode,
              },
            }),
          );
          promiseCollection.push(
            Promise.resolve({
              text,
              receiverId: destNode,
              type: 'waiting_room',
            }),
          );
        } else if (Array.isArray(destNode)) {
          dispatch(
            sendSocketMessage({
              evt: WS_CONF_CHAT_REQ,
              body: {
                text: encodeBase64(text),
                destNodeID: destNode[1],
                attendeeNodeID: destNode[0],
              },
            }),
          );
          promiseCollection.push(
            Promise.resolve({
              text,
              receiverId: destNode[0],
              type: 'waiting_room',
              isSilentModeUser: true,
            }),
          );
        } else if (typeof destNode === 'object') {
          if (isWebinarClientSupportCMDChat(destNode.clientCap)) {
            promiseCollection.push(
              sendRwgChat(
                CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
                destNode.node,
              ),
            );
          } else {
            promiseCollection.push(
              sendRwgChat(CHAT_MSG_TYPE_TO_PANELIST, destNode.node),
            );
            promiseCollection.push(sendXmppChat());
          }
        } else {
          promiseCollection.push(sendRwgChat(destNode));
        }
        return Promise.all(promiseCollection);
      })
      .then((results) => {
        dispatch(
          updateSenderChatMessage(
            results.find((item) => item.type === 'xmpp') || results[0],
          ),
        );
      })
      .catch(console.error); // eslint-disable-line no-console
  };
}

export const updateChatFilePropertys =
  (values, index) => (dispatch, getState) => {
    const {
      chat: { meetingChat },
    } = getState();

    if (!values || index < 0) {
      return;
    }

    const newMeetingChat = produce(meetingChat, (draft) => {
      const chatMsgs = draft[index]?.chatMsgs;
      if (chatMsgs && chatMsgs[0]) {
        // file message is always the first one
        const file = chatMsgs[0]['file'];
        chatMsgs[0].file = _.assign({}, file, values);
      }
    });
    dispatch(updateMeetingChat(newMeetingChat));
  };

const calcFileHashHex = (file) => {
  // resolve large size file
  const chunkNum = Math.ceil(file.size / CHAT_FILE_READER_CHUNK_SIZE);
  const promiseCollection = [];
  for (let i = 0; i < chunkNum; i++) {
    promiseCollection.push(
      new Promise((resolve, reject) => {
        readFileAsChunk(
          file,
          i * CHAT_FILE_READER_CHUNK_SIZE,
          (i + 1) * CHAT_FILE_READER_CHUNK_SIZE,
        )
          .then((e) => {
            window.crypto.subtle
              .digest('SHA-256', e.target.result)
              .then((hashArray) => {
                resolve(bufferToHex(hashArray));
              });
          })
          .catch((e) => reject(e));
      }),
    );
  }
  return new Promise((resolve, reject) => {
    Promise.all(promiseCollection)
      .then((results) => {
        resolve(results.join(''));
      })
      .catch((e) => reject(e));
  });
};

export const bufferToHex = (arraybuffer) => {
  return [...new Uint8Array(arraybuffer)]
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('');
};

export const combineArrayBuffer = (results) => {
  let totalLen = 0;
  for (const arr of results) {
    totalLen += arr.byteLength;
  }
  const combinedBuffer = new Uint8Array(totalLen);
  let offset = 0;
  for (const arr of results) {
    combinedBuffer.set(new Uint8Array(arr), offset);
    offset += arr.byteLength;
  }
  return combinedBuffer.buffer;
};

const updatePartUploadProgress = _.throttle(
  (p, fileMsgIndex, partNumber, fileSize, dispatch) => {
    const percent = _.parseInt(
      100 * (((partNumber - 1) * CHAT_FILE_CHUNK_SIZE + p.loaded) / fileSize),
    );
    dispatch(updateChatFilePropertys({ uploadPercent: percent }, fileMsgIndex));
  },
  1000,
);

const onUploadFail = (
  error,
  fileMsgIndex,
  errorType = CHAT_FILE_UPLOAD_ERROR_TYPE.NORMAL,
) => {
  return (dispatch) => {
    if (error?.toString() === 'Cancel') {
      dispatch(
        updateChatFilePropertys(
          {
            uploadCanceled: true,
            uploadPercent: 0,
          },
          fileMsgIndex,
        ),
      );
    } else {
      logger.error(
        `Old chat file transfer error: ${error.message}, file: ${error.fileName}, line: ${error.lineNumber}:${error.columnNumber}, stack: ${error.stack}`,
      );
      dispatch(
        updateChatFilePropertys(
          {
            uploadError: true,
            uploadPercent: 0,
            uploadErrorType: errorType,
          },
          fileMsgIndex,
        ),
      );
    }
  };
};

const onUploadFileSuccess =
  (res, file, uploadBaseInfo) => (dispatch, getState) => {
    try {
      const {
        meeting: { fileServerDomain },
      } = getState();
      const { fileMsgIndex, destNode, requestUUID } = uploadBaseInfo;
      const headers = res.headers;
      const fileID = headers['zoom-file-id'];
      const fileObj = headers['zoom-file-obj'];
      const fileUrl = getFileDownloadUrl({ fileObj }, fileServerDomain);
      dispatch(
        updateChatFilePropertys(
          {
            fileUrl,
            fileObj,
            fileID,
            uploadPercent: 100,
            uploadStatus: CHAT_FILE_UPLOAD_STATUS.SUCCESS,
          },
          fileMsgIndex,
        ),
      );
      if (meetingConfig.fileTransfer.isEnableFileTransferEncrypted) {
        const promiseCollection = [];
        promiseCollection.push(
          beginEncrypt({
            text: file.name,
            type: ivType.RWG_CHAT,
          }),
        );
        promiseCollection.push(
          beginEncrypt({
            text: file.size,
            type: ivType.RWG_CHAT,
          }),
        );
        Promise.all(promiseCollection)
          .then(([fileName, fileSize]) => {
            dispatch(
              sendSocketMessage({
                evt: WS_CONF_CHAT_FILE_TRANSFER_REQ,
                body: {
                  fileID,
                  fileSize,
                  fileName,
                  fileType: CHAT_FILE_TYPE.LOCAL,
                  fileObj,
                  type: file.type,
                  destNodeID: destNode.destNodeID,
                  attendeeNodeID: destNode.attendeeNodeID,
                  receiverType: 0,
                },
              }),
            );
            dispatch(removeUploadCancelToken(`${requestUUID}-complete`));
          })
          .catch((error) => {
            logger.error(
              `Old chat file transfer error when success: ${error.message}, file: ${error.fileName}, line: ${error.lineNumber}:${error.columnNumber}, stack: ${error.stack}`,
            );
          });
      } else {
        dispatch(
          sendSocketMessage({
            evt: WS_CONF_CHAT_FILE_TRANSFER_REQ,
            body: {
              fileID,
              fileSize: encodeBase64(`${file.size}`),
              fileName: encodeBase64(file.name),
              fileType: CHAT_FILE_TYPE.LOCAL,
              fileObj,
              type: file.type,
              destNodeID: destNode.destNodeID,
              attendeeNodeID: destNode.attendeeNodeID,
              receiverType: 0,
            },
          }),
        );
        dispatch(removeUploadCancelToken(`${requestUUID}-complete`));
      }
    } catch (error) {
      logger.error(
        `Old chat file transfer error when success: ${error.message}, file: ${error.fileName}, line: ${error.lineNumber}:${error.columnNumber}, stack: ${error.stack}`,
      );
    }
  };

const uploadFileInit = (file, destNode, fileMsgIndex, requestUUID) => {
  return (dispatch, getState) => {
    const {
      meeting: { svcUrl, conID, zmk, meetingId },
      security: { reportDomain },
    } = getState();
    if (!file) {
      dispatch(
        onUploadFail(
          null,
          fileMsgIndex,
          CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
        ),
      );
    }
    const uploadInitUrl = `https://${reportDomain}/wc/multiupload/init?filename=${encodeURIComponent(
      file.name,
    )}&&filesize=${file.size}&&rwg=${svcUrl}&&cid=${conID}`;
    dispatch(
      updateChatFilePropertys(
        {
          uploadStatus: CHAT_FILE_UPLOAD_STATUS.INIT,
          uploadPercent: 0,
        },
        fileMsgIndex,
      ),
    );
    calcFileHashHex(file)
      .then((digest) => {
        axios
          .post(
            uploadInitUrl,
            {
              fileName: file.name,
              length: file.size,
              channelType: 4,
              digest,
              shareJid: meetingId,
            },
            {
              headers: {
                zmk,
                ['Zoom-File-Origin']: 'redirect=support_auth',
              },
              cancelToken: new CancelToken((cancel) => {
                dispatch(
                  setUploadCancelTokens({ [`${requestUUID}-init`]: cancel }),
                );
              }),
            },
          )
          .then((res) => {
            const trackingId = res.headers['x-zm-trackingid'];
            const { uploadid, path, metadata } = res.data;
            if (!uploadid) {
              uploadFileInit(file, destNode, fileMsgIndex, requestUUID);
              return;
            }
            const uploadCompleteInfo = {
              metadata,
              etags: {},
            };
            const uploadBaseInfo = {
              uploadid,
              path,
              trackingId,
              fileMsgIndex,
              destNode,
              requestUUID,
            };
            dispatch(
              updateChatFilePropertys(
                {
                  uploadBaseInfo,
                  uploadChunkInfo: {
                    partNumber: 0,
                    uploadCompleteInfo,
                  },
                  uploadStatus: CHAT_FILE_UPLOAD_STATUS.PENDING,
                },
                fileMsgIndex,
              ),
            );
            dispatch(
              uploadFilePart(file, 1, 0, uploadBaseInfo, uploadCompleteInfo),
            );
            dispatch(removeUploadCancelToken(`${requestUUID}-init`));
          })
          .catch((e) => {
            dispatch(onUploadFail(e, fileMsgIndex));
          });
      })
      .catch((e) =>
        dispatch(
          onUploadFail(
            e,
            fileMsgIndex,
            CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
          ),
        ),
      );
  };
};

const uploadFilePart = (
  file,
  partNumber,
  loaded,
  uploadBaseInfo,
  uploadCompleteInfo,
) => {
  return (dispatch, getState) => {
    const {
      meeting: { zmk, fileServerDomain },
    } = getState();
    const { uploadid, path, trackingId, fileMsgIndex, requestUUID } =
      uploadBaseInfo;
    if (loaded >= file.size) {
      dispatch(uploadFileComplete(file, uploadBaseInfo, uploadCompleteInfo));
      return;
    }

    const fileChunkNums = Math.ceil(file.size / CHAT_FILE_CHUNK_SIZE);
    const isTheLastChunk = partNumber === fileChunkNums;
    const restSize = !isTheLastChunk
      ? CHAT_FILE_CHUNK_SIZE
      : file.size - loaded;
    const encodeBlockNum = Math.ceil(restSize / CHAT_FILE_ENCODE_BLOCK_SIZE);
    const encodePromiseCollection = [];
    let i = 0;
    while (i < encodeBlockNum) {
      const start = loaded + i * CHAT_FILE_ENCODE_BLOCK_SIZE;
      // encode the last 2 blocks together
      const isTheLast2EncodeBlock = isTheLastChunk && i == encodeBlockNum - 2;
      const end = isTheLast2EncodeBlock
        ? loaded + (i + 2) * CHAT_FILE_ENCODE_BLOCK_SIZE
        : loaded + (i + 1) * CHAT_FILE_ENCODE_BLOCK_SIZE;
      encodePromiseCollection.push(
        new Promise((resolve, reject) => {
          readFileAsChunk(file, start, end)
            .then((e) => {
              beginEncrypt({
                text: new Uint8Array(e.target.result),
                type: ivType.CHAT_FILE,
              }).then((fileData) => {
                resolve(fileData);
              });
            })
            .catch((e) => {
              reject(e);
            });
        }),
      );
      i = isTheLast2EncodeBlock ? i + 2 : i + 1;
    }
    Promise.all(encodePromiseCollection)
      .then((results) => {
        return combineArrayBuffer(results);
      })
      .then((arraybuffer) => {
        const uploadPartUrl = `https://${fileServerDomain}/upload/parts?uploadid=${uploadid}&&partNumber=${partNumber}`;
        const formData = new FormData();
        const chunkFile = new File([arraybuffer], file.name);
        formData.append('file', new File([arraybuffer], file.name));
        axios
          .post(uploadPartUrl, formData, {
            headers: {
              ['Zoom-File-Size']: chunkFile.size,
              ['Zoom-File-Path']: path,
              ['x-zm-trackingid']: trackingId,
              ['Zoom-File-Origin']: 'redirect=support_auth',
              zmk,
            },
            onUploadProgress: (p) =>
              updatePartUploadProgress(
                p,
                fileMsgIndex,
                partNumber,
                file.size,
                dispatch,
              ),
            cancelToken: new CancelToken((cancel) => {
              dispatch(
                setUploadCancelTokens({
                  [`${requestUUID}-part-${partNumber}`]: cancel,
                }),
              );
            }),
          })
          .then((res) => {
            const etag = res.headers['x-zm-etag'];
            const newCompleteInfo = _.cloneDeep(uploadCompleteInfo);
            newCompleteInfo['etags'][partNumber] = etag;
            dispatch(
              updateChatFilePropertys(
                {
                  uploadChunkInfo: {
                    partNumber,
                    uploadCompleteInfo: newCompleteInfo,
                  },
                },
                fileMsgIndex,
              ),
            );
            dispatch(
              uploadFilePart(
                file,
                partNumber + 1,
                loaded + CHAT_FILE_CHUNK_SIZE,
                uploadBaseInfo,
                newCompleteInfo,
              ),
            );
            dispatch(
              removeUploadCancelToken(`${requestUUID}-part-${partNumber}`),
            );
          })
          .catch((e) => {
            dispatch(onUploadFail(e, fileMsgIndex));
          });
      })
      .catch((e) =>
        dispatch(
          onUploadFail(
            e,
            fileMsgIndex,
            CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
          ),
        ),
      );
  };
};

const uploadFileComplete = (file, uploadBaseInfo, uploadCompleteInfo) => {
  return (dispatch, getState) => {
    const {
      meeting: { zmk, fileServerDomain },
    } = getState();
    const { uploadid, trackingId, fileMsgIndex, requestUUID } = uploadBaseInfo;
    const uploadCompleteUrl = `https://${fileServerDomain}/upload/complete?uploadid=${uploadid}`;
    dispatch(
      updateChatFilePropertys(
        {
          uploadStatus: CHAT_FILE_UPLOAD_STATUS.COMPLETE,
          uploadPercent: 100,
        },
        fileMsgIndex,
      ),
    );
    axios
      .post(uploadCompleteUrl, uploadCompleteInfo, {
        headers: {
          ['x-zm-trackingid']: trackingId,
          zmk,
          ['Zoom-File-Origin']: 'redirect=support_auth',
        },
        cancelToken: new CancelToken((cancel) => {
          dispatch(
            setUploadCancelTokens({ [`${requestUUID}-complete`]: cancel }),
          );
        }),
      })
      .then((res) => {
        dispatch(onUploadFileSuccess(res, file, uploadBaseInfo));
      })
      .catch((e) => {
        dispatch(
          onUploadFail(
            e,
            fileMsgIndex,
            CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
          ),
        );
      });
  };
};

const updateFullUploadProgress = _.throttle((p, fileMsgIndex, dispatch) => {
  const percent = _.parseInt(100 * (p.loaded / p.total));
  dispatch(updateChatFilePropertys({ uploadPercent: percent }, fileMsgIndex));
}, 1000);

const uploadFullFile = (file, destNode, fileMsgIndex, requestUUID) => {
  return (dispatch, getState) => {
    const {
      meeting: { svcUrl, conID, zmk },
      security: { reportDomain },
    } = getState();
    if (!file) {
      dispatch(
        onUploadFail(
          null,
          fileMsgIndex,
          CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
        ),
      );
    }
    const uploadUrl = `https://${reportDomain}/wc/fileupload?filename=${encodeURIComponent(
      file.name,
    )}&&filesize=${file.size}&&rwg=${svcUrl}&&cid=${conID}`;
    dispatch(
      updateChatFilePropertys(
        {
          uploadStatus: CHAT_FILE_UPLOAD_STATUS.PENDING,
          uploadPercent: 0,
        },
        fileMsgIndex,
      ),
    );
    Promise.resolve().then(() => {
      const encodeBlockNum = Math.ceil(file.size / CHAT_FILE_ENCODE_BLOCK_SIZE);
      const encodePromiseCollection = [];
      for (let i = 0; i <= encodeBlockNum - 1; i++) {
        const start = i * CHAT_FILE_ENCODE_BLOCK_SIZE;
        // encode the last 2 blocks together
        const end =
          i === encodeBlockNum - 2
            ? (i + 2) * CHAT_FILE_ENCODE_BLOCK_SIZE
            : (i + 1) * CHAT_FILE_ENCODE_BLOCK_SIZE;
        encodePromiseCollection.push(
          new Promise((resolve, reject) => {
            readFileAsChunk(file, start, end)
              .then((e) => {
                beginEncrypt({
                  text: new Uint8Array(e.target.result),
                  type: ivType.CHAT_FILE,
                }).then((fileData) => {
                  resolve(fileData);
                });
              })
              .catch((e) => {
                reject(e);
              });
          }),
        );
        if (i === encodeBlockNum - 2) break;
      }
      Promise.all(encodePromiseCollection)
        .then((results) => {
          return combineArrayBuffer(results);
        })
        .then((arraybuffer) => {
          const formData = new FormData();
          formData.append('file', new File([arraybuffer], file.name));
          axios
            .post(uploadUrl, formData, {
              onUploadProgress: (p) =>
                updateFullUploadProgress(p, fileMsgIndex, dispatch),
              cancelToken: new CancelToken((cancel) => {
                dispatch(
                  setUploadCancelTokens({ [`${requestUUID}-full`]: cancel }),
                );
              }),
              headers: {
                zmk,
                ['Zoom-File-Origin']: 'redirect=support_auth',
              },
            })
            .then((res) => {
              dispatch(
                onUploadFileSuccess(res, file, {
                  fileMsgIndex,
                  requestUUID,
                  destNode,
                }),
              );
            })
            .catch((e) => {
              dispatch(onUploadFail(e, fileMsgIndex));
            });
        })
        .catch((e) =>
          dispatch(
            onUploadFail(
              e,
              fileMsgIndex,
              CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
            ),
          ),
        );
    });
  };
};

export const uploadChatFile = (file, destNode) => (dispatch, getState) => {
  const {
    chat: { meetingChat },
  } = getState();
  if (_.isNil(destNode?.destNodeID) || !file) {
    return;
  }
  const requestUUID = generateUUID();
  const fileMsgIndex = meetingChat.length;
  const uploadFile = {
    fileName: file.name,
    fileSize: file.size,
    senderSN: easyStore.getCurrentUserId(),
    fileType: CHAT_FILE_TYPE.LOCAL,
    type: file.type,
    uploadPercent: 0,
    originFile: file,
    uploadBaseInfo: {
      requestUUID,
      destNode,
      fileMsgIndex,
    },
    uploadChunkInfo: {
      partNumber: 0,
    },
  };
  const receiverId =
    destNode.destNodeID === CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST
      ? destNode.attendeeNodeID
      : destNode.destNodeID;
  const chatMessage = {
    receiverId,
    isSilentModeUser: false,
    file: uploadFile,
  };
  dispatch(updateSenderChatMessage(chatMessage));
  if (file.size > CHAT_FILE_CHUNK_SIZE) {
    dispatch(uploadFileInit(file, destNode, fileMsgIndex, requestUUID));
  } else {
    dispatch(uploadFullFile(file, destNode, fileMsgIndex, requestUUID));
  }
};

export const resendFile = (file, uploadFile) => {
  return (dispatch) => {
    if (!file) {
      return;
    }

    const { uploadChunkInfo, uploadBaseInfo, uploadStatus } = uploadFile;
    const { destNode, fileMsgIndex, requestUUID } = uploadBaseInfo;
    const { partNumber, uploadCompleteInfo } = uploadChunkInfo;
    if (file.size <= CHAT_FILE_CHUNK_SIZE) {
      dispatch(uploadFullFile(file, destNode, fileMsgIndex, requestUUID));
    } else {
      if (uploadStatus === CHAT_FILE_UPLOAD_STATUS.INIT) {
        dispatch(uploadFileInit(file, destNode, fileMsgIndex, requestUUID));
      } else if (uploadStatus === CHAT_FILE_UPLOAD_STATUS.COMPLETE) {
        dispatch(uploadFileComplete(file, uploadBaseInfo, uploadCompleteInfo));
      } else {
        dispatch(
          uploadFilePart(
            file,
            partNumber + 1,
            partNumber * CHAT_FILE_CHUNK_SIZE,
            uploadBaseInfo,
            uploadCompleteInfo,
          ),
        );
        dispatch(
          updateChatFilePropertys(
            {
              uploadStatus: CHAT_FILE_UPLOAD_STATUS.PENDING,
            },
            fileMsgIndex,
          ),
        );
      }
    }
    dispatch(
      updateChatFilePropertys(
        {
          uploadError: false,
          uploadErrorType: undefined,
          uploadCanceled: false,
        },
        fileMsgIndex,
      ),
    );
  };
};

export const stopUploadRequest = (uploadFile) => (dispatch, getState) => {
  const {
    chat: { uploadCancelTokens },
  } = getState();
  const { uploadBaseInfo, uploadChunkInfo, uploadStatus, fileSize } =
    uploadFile;
  const { requestUUID } = uploadBaseInfo;
  let requestId;
  if (fileSize <= CHAT_FILE_CHUNK_SIZE) {
    requestId = `${requestUUID}-full`;
  } else {
    if (uploadStatus === CHAT_FILE_UPLOAD_STATUS.INIT) {
      requestId = `${requestUUID}-init`;
    } else if (uploadStatus === CHAT_FILE_UPLOAD_STATUS.PENDING) {
      const { partNumber } = uploadChunkInfo;
      requestId = `${requestUUID}-part-${partNumber + 1}`;
    } else {
      requestId = `${requestUUID}-complete`;
    }
  }
  const cancelFunc = uploadCancelTokens[requestId];
  if (cancelFunc) {
    cancelFunc();
    dispatch(removeUploadCancelToken(requestId));
  }
};

const updateDownloadProgress = _.throttle((p, fileMsgIndex, dispatch) => {
  const percent = _.parseInt(100 * (p.loaded / p.total));
  dispatch(updateChatFilePropertys({ downloadPercent: percent }, fileMsgIndex));
}, 1000);

export const downloadChatFile = (file) => {
  return (dispatch, getState) => {
    const {
      meeting: { zmk },
    } = getState();
    const { fileUrl, uploadBaseInfo, senderSN, fileName } = file;
    const { fileMsgIndex } = uploadBaseInfo;
    dispatch(
      updateChatFilePropertys(
        {
          downloadStatus: CHAT_FILE_DOWNLOAD_STATUS.DOWNLOADING,
          downloadPercent: 0,
        },
        fileMsgIndex,
      ),
    );
    axios
      .get(fileUrl, {
        headers: {
          zmk,
          ['Zoom-File-Origin']: 'redirect=support_auth',
        },
        responseType: 'arraybuffer',
        onDownloadProgress: (p) =>
          updateDownloadProgress(p, fileMsgIndex, dispatch),
        cancelToken: new CancelToken((cancel) => {
          dispatch(
            updateChatFilePropertys(
              {
                downloadCancelToken: cancel,
              },
              fileMsgIndex,
            ),
          );
        }),
      })
      .then((res) => {
        let loaded = 0;
        const decodePromiseCollection = [];
        while (loaded < res.data.byteLength) {
          // decode the last 2 blocks together
          const blockSize =
            res.data.byteLength - loaded < CHAT_FILE_DECODE_BLOCK_SIZE * 2
              ? CHAT_FILE_DECODE_BLOCK_SIZE * 2
              : CHAT_FILE_DECODE_BLOCK_SIZE;
          decodePromiseCollection.push(
            beginDecrypt({
              decryptedText: res.data.slice(loaded, loaded + blockSize),
              userId: senderSN,
              type: ivType.CHAT_FILE,
            }).then(({ message }) => {
              return message;
            }),
          );
          loaded += blockSize;
        }
        Promise.all(decodePromiseCollection).then((results) => {
          const blob = new Blob(results, { type: getMimeTypeByName(fileName) });
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          dispatch(
            updateChatFilePropertys(
              {
                downloadStatus: CHAT_FILE_DOWNLOAD_STATUS.SUCCESS,
              },
              fileMsgIndex,
            ),
          );
        });
      })
      .catch((e) => {
        if (e.toString() !== 'Cancel') {
          dispatch(
            updateChatFilePropertys(
              {
                downloadStatus: CHAT_FILE_DOWNLOAD_STATUS.ERROR,
              },
              fileMsgIndex,
            ),
          );
        } else {
          dispatch(
            updateChatFilePropertys(
              {
                downloadStatus: CHAT_FILE_DOWNLOAD_STATUS.CANCELED,
              },
              fileMsgIndex,
            ),
          );
        }
      })
      .finally(() => {
        dispatch(
          updateChatFilePropertys(
            {
              downloadPercent: 0,
            },
            fileMsgIndex,
          ),
        );
      });
  };
};

export const clearAllFileMessage = () => {
  return (dispatch, getState) => {
    const {
      chat: { meetingChat, uploadCancelTokens },
    } = getState();

    // clear all request
    Object.keys(uploadCancelTokens).forEach((key) => {
      const cancel = uploadCancelTokens[key];
      if (cancel) {
        cancel();
        removeUploadCancelToken(key);
      }
    });

    const newMeetingChat = produce(meetingChat, (draft) => {
      let index = 0;
      while (index <= draft.length) {
        const msgBody = draft[index];
        const chatMsgs = msgBody?.chatMsgs;
        if (chatMsgs?.[0]?.file) {
          const file = chatMsgs[0]?.file;
          if (file.downloadCancelToken) {
            // clear download request
            file.downloadCancelToken();
          }
          const newChatMsgs = chatMsgs.slice(1);
          msgBody.chatMsgs = newChatMsgs;
          // combine chat message
          if (index > 0 && msgBody.senderId === draft[index - 1]?.senderId) {
            draft[index - 1].chatMsgs = draft[index - 1].chatMsgs.concat(
              newChatMsgs || [],
            );
            draft.splice(index, 1);
            continue;
          }
        }
        index += 1;
      }
    });
    dispatch(updateMeetingChat(newMeetingChat));
  };
};

export const clear3rdPartyInfo = () => (dispatch) => {
  dispatch(set3rdPartyFileService(null));
  dispatch(set3rdPartyFileWindowVisible({ value: false }));
  dispatch(set3rdPartyFileCnonce(undefined));
};

export const handleClickFile = (file) => (dispatch, getState) => {
  const {
    fileType,
    uploadErrorType,
    originFile,
    uploadError,
    uploadCanceled,
    correctPreviewUrl,
  } = file;
  const { fileDownloadPromptIgnoreList } = getState().chat;
  const isLoading = getIsFileLoading(file);
  if (fileType === CHAT_FILE_TYPE.THIRD_PARTY) {
    if (correctPreviewUrl) {
      window.open(correctPreviewUrl, '_blank');
    }
    return;
  }
  if (
    isLoading ||
    uploadErrorType === CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED
  ) {
    return;
  }

  if (uploadError || uploadCanceled) {
    dispatch(resendFile(originFile, file));
    return;
  }

  doubleConfirmBeforeDownloadFile({
    file,
    downloadFileFunc: () => {
      dispatch(downloadChatFile(file));
    },
    fileDownloadPromptIgnoreList,
  });
};
