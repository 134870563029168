/* eslint-disable react/prop-types */
import React from 'react';
import { MODAL_BANNER_TIMEOUT } from '../../../constants/Constants';
import { DOM_KEY_CODE } from '../../enum';
import { isExternalControlledMode, isTeslaMode } from '../../util';

const NotificationMessage = ({
  isOpen,
  showClose,
  btnComponent,
  children,
  onClose,
  wrapRef,
  className,
  key,
  style,
}) => {
  const hanldeCloseBtnKeydown = (e) => {
    if (e.keyCode === DOM_KEY_CODE.ENTER && onClose) {
      onClose();
    }
  };

  return (
    isOpen && (
      <div
        ref={wrapRef}
        className={`notification-message-wrap ${className}`}
        key={key}
        style={style}
      >
        <div className="notification-message-wrap__layer">
          <div
            className="notification-message-wrap__txt-container"
            tabIndex={0}
            role="alert"
          >
            <span className="notification-message-wrap__txt">{children}</span>
          </div>
          {btnComponent && (
            <div className="notification-message-wrap__btns">
              {btnComponent}
            </div>
          )}
          {showClose && (
            <i
              role="button"
              tabIndex={0}
              className="notification-message-wrap__close close-jd ax-outline"
              onKeyDown={hanldeCloseBtnKeydown}
              onClick={
                isExternalControlledMode() && !isTeslaMode()
                  ? setTimeout(() => {
                      onClose();
                    }, MODAL_BANNER_TIMEOUT)
                  : onClose
              }
              aria-label="close"
            />
          )}
        </div>
      </div>
    )
  );
};

export default NotificationMessage;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/containers/notification-manager/notification-message.scss';
