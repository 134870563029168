import { localReadyStatesObserver } from './local-ready-states-observer';
import {
  CONTROL_MODE_READY_FACTOR,
  CONTROL_MODE_COMMON_ERROR_TYPE,
} from '../../enum';
import {
  INIT_SUCCESS_VIDEO,
  INIT_SUCCESS_VIDEO_ENCODE,
  INIT_SUCCESS_VIDEO_DECODE,
  INIT_SUCCESS_AUDIO,
  INIT_SUCCESS_AUDIO_ENCODE,
  INIT_SUCCESS_AUDIO_DECODE,
  INIT_SUCCESS_SHARING,
  INIT_SUCCESS_SHARING_DECODE,
  USER_GRANT_AUDIO_CAPTURE,
  USER_FORBIDDED_CAPTURE_VIDEO,
  USER_FORBIDDED_CAPTURE_AUDIO,
  DESKTOP_SHARING_ERROR,
  USER_STOP_DESKTOP_SHARING,
} from '../../../constants/AVNotifyAPPTypes';
import { INIT_VIDEO_ENCODE_SUCCESS } from '../../../constants/SocketActionTypes';
import { coOrHostSelector } from '../../../global/redux/selector';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';
import {
  CONTENT_SHARE_STATE,
  GOOGLE_MEET_ERROR_CODE_PREFIX,
  GOOGLE_MEET_ERROR_CODE_SUFFIX,
} from './enum';
import { DESKTOP_SHARING_CAPTURE_SUCCESS } from '../../../features/sharing/enum';
import { noAvailableCameraSelector } from '../../../features/video/redux/selectors/camera-control-selector';
import { stopCaptureVideo } from '../../../features/video/redux/thunks/start-stop-capture-video-thunk';

const updateInitState = (evt, payload, result) => {
  /* eslint-disable-next-line no-console */
  console.error(
    `Ready State Check: ${evt}  ${payload}: map result : ${result}`,
  );
  localReadyStatesObserver.complete(result);
};

const callbackMap = {
  [INIT_SUCCESS_AUDIO + INIT_SUCCESS_AUDIO_ENCODE]: (evt, payload) => {
    updateInitState(
      evt,
      payload,
      CONTROL_MODE_READY_FACTOR.AUDIO_ENCODE_INIT_SUCCESS,
    );
  },
  [INIT_SUCCESS_AUDIO + INIT_SUCCESS_AUDIO_DECODE]: (evt, payload) => {
    updateInitState(
      evt,
      payload,
      CONTROL_MODE_READY_FACTOR.AUDIO_DECODE_INIT_SUCCESS,
    );
  },
  [INIT_SUCCESS_VIDEO + INIT_SUCCESS_VIDEO_ENCODE]: (
    evt,
    payload,
    store,
    adaptor,
  ) => {
    updateInitState(
      evt,
      payload,
      CONTROL_MODE_READY_FACTOR.VIDEO_ENCODE_INIT_SUCCESS,
    );
    const { getState, dispatch } = store;
    const state = getState();
    /**
     * In partner flow, save success redux state ealier becuase it will be immediately used after this line
     * It would too late if we depended on public logic to save state in the avsocket.js
     */
    dispatch({ type: INIT_VIDEO_ENCODE_SUCCESS });
    const {
      meeting: { currentUser, bCanUnmuteVideo },
      meetingUI,
    } = state;
    const coOrHost = coOrHostSelector(state);
    const isWb = isWebinar();
    const isViewOnlyUser = isViewOnly(currentUser.userRole);
    const noAvailableCamera = noAvailableCameraSelector(state);

    if (meetingUI.isOnHold) {
      adaptor.notifyControllerActionFailure({
        type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
        errorCode:
          GOOGLE_MEET_ERROR_CODE_PREFIX.APPLICATION_ERROR +
          GOOGLE_MEET_ERROR_CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
      });
      return false;
    }

    if (isWb && isViewOnlyUser) {
      adaptor.notifyControllerActionFailure({
        type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
        errorCode:
          GOOGLE_MEET_ERROR_CODE_PREFIX.APPLICATION_ERROR +
          GOOGLE_MEET_ERROR_CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
      });
      return false;
    }

    if (noAvailableCamera) {
      adaptor.notifyControllerActionFailure({
        type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
        errorCode:
          GOOGLE_MEET_ERROR_CODE_PREFIX.APPLICATION_ERROR +
          GOOGLE_MEET_ERROR_CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
      });
      return false;
    }
    if (
      (currentUser.bVideoMute && !coOrHost) ||
      (!bCanUnmuteVideo && !coOrHost)
    ) {
      adaptor.notifyControllerActionFailure({
        type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
        errorCode:
          GOOGLE_MEET_ERROR_CODE_PREFIX.APPLICATION_ERROR +
          GOOGLE_MEET_ERROR_CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
      });
      return false;
    }
  },
  [INIT_SUCCESS_VIDEO + INIT_SUCCESS_VIDEO_DECODE]: (evt, payload) => {
    updateInitState(
      evt,
      payload,
      CONTROL_MODE_READY_FACTOR.VIDEO_DECODE_INIT_SUCCESS,
    );
  },
  [INIT_SUCCESS_SHARING + INIT_SUCCESS_SHARING_DECODE]: (evt, payload) => {
    updateInitState(
      evt,
      payload,
      CONTROL_MODE_READY_FACTOR.SHARE_DECODE_INIT_SUCCESS,
    );
  },
};

export const mediaSDKMsgHandler = (evt, payload, store, adaptor) => {
  const { getState, dispatch } = store;

  const state = getState();
  const {
    meeting: {
      currentUser: { isAllowTalk: isCurrentUserAllowTalk, userRole, muted },
      bCanUnmute,
    },
    audio: { isMicrophoneForbidden },
  } = state;

  const coOrHost = coOrHostSelector(state);
  const cb = callbackMap[evt + payload];

  if (typeof cb === 'function') {
    cb(evt, payload, store, adaptor);
  } else {
    switch (evt) {
      case USER_GRANT_AUDIO_CAPTURE: {
        if (isMicrophoneForbidden) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
            errorCode:
              GOOGLE_MEET_ERROR_CODE_PREFIX.APPLICATION_ERROR +
              GOOGLE_MEET_ERROR_CODE_SUFFIX.UNMUTE_AUDIO_FAILED,
          });
          return false;
        }
        if (isWebinar() && isViewOnly(userRole) && !isCurrentUserAllowTalk) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
            errorCode:
              GOOGLE_MEET_ERROR_CODE_PREFIX.APPLICATION_ERROR +
              GOOGLE_MEET_ERROR_CODE_SUFFIX.UNMUTE_AUDIO_FAILED,
          });
          return false;
        }
        if (!bCanUnmute && muted && !coOrHost) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
            errorCode:
              GOOGLE_MEET_ERROR_CODE_PREFIX.APPLICATION_ERROR +
              GOOGLE_MEET_ERROR_CODE_SUFFIX.UNMUTE_AUDIO_FAILED,
          });
          return false;
        }

        break;
      }

      case USER_FORBIDDED_CAPTURE_VIDEO: {
        adaptor.notifyControllerActionFailure({
          type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
          errorCode:
            GOOGLE_MEET_ERROR_CODE_PREFIX.FORBIDDEN +
            GOOGLE_MEET_ERROR_CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
        });
        break;
      }
      case USER_FORBIDDED_CAPTURE_AUDIO: {
        adaptor.notifyControllerActionFailure({
          type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
          errorCode:
            GOOGLE_MEET_ERROR_CODE_PREFIX.FORBIDDEN +
            GOOGLE_MEET_ERROR_CODE_SUFFIX.UNMUTE_AUDIO_FAILED,
        });

        break;
      }

      case DESKTOP_SHARING_ERROR: {
        adaptor.notifyControllerActionFailure({
          type: CONTROL_MODE_COMMON_ERROR_TYPE.CONTENT_SHARE_FAILED_TO_OPEN_MEDIA_STREAM,
          errorCode:
            GOOGLE_MEET_ERROR_CODE_PREFIX.APPLICATION_ERROR +
            GOOGLE_MEET_ERROR_CODE_SUFFIX.CONTENT_SHARE_FAILED_TO_OPEN_MEDIA_STREAM,
        });
        break;
      }

      case USER_STOP_DESKTOP_SHARING: {
        adaptor.notifyControllerActionFailure({
          type: CONTROL_MODE_COMMON_ERROR_TYPE.CONTENT_SHARE_MEDIA_STREAM_TRACK_ENDED,
          errorCode:
            GOOGLE_MEET_ERROR_CODE_PREFIX.APPLICATION_ERROR +
            GOOGLE_MEET_ERROR_CODE_SUFFIX.CONTENT_SHARE_MEDIA_STREAM_TRACK_ENDED,
        });
        dispatch(stopCaptureVideo());
        break;
      }

      case DESKTOP_SHARING_CAPTURE_SUCCESS: {
        adaptor.notifyControllerMeetingUpdate({
          contentShare: { state: CONTENT_SHARE_STATE.ACTIVE },
        });
        break;
      }

      default:
        break;
    }
  }
};
