import React from 'react';
import PropTypes from 'prop-types';
import { CompositeCheckbox } from './composite-checkbox';

function CompositeCheckboxGroup({ groupName, children }) {
  return (
    <div className="composite-check-box-group">
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { groupName }, null),
      )}
    </div>
  );
}

CompositeCheckboxGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    CompositeCheckbox,
    PropTypes.arrayOf(CompositeCheckbox),
  ]),
};

export { CompositeCheckboxGroup };

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/components/widget/composite-checkbox-group.scss';
