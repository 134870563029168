import { promptA11yInfo } from '../../../global/util';

let addSuccessRoomQueue = [];
const interval = 500;
let addRoomTimer = null;
function addRoomDebounce(roomName) {
  addSuccessRoomQueue.push(roomName);
  if (addRoomTimer) {
    clearTimeout(addRoomTimer);
  }
  addRoomTimer = setTimeout(() => {
    const len = addSuccessRoomQueue.length;
    if (len === 1) {
      promptA11yInfo(`${addSuccessRoomQueue[0]} has been added`);
    } else {
      promptA11yInfo(
        `${len} Breakout Rooms (${addSuccessRoomQueue[0]} to ${
          addSuccessRoomQueue[len - 1]
        }) have been added`,
      );
    }
    addSuccessRoomQueue = [];
  }, interval);
}

export { addRoomDebounce };
