/* eslint-disable react/prop-types */
import React, { useImperativeHandle, forwardRef, useEffect } from 'react';
import { isExternalControlledMode } from '../../../util';
import Modal from './modal';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { globalVariable } from '../../../global-variable';

const queues = {};

let ConfirmModal = (props, ref) => {
  const { reduxStore } = globalVariable.wcCTX();
  const {
    content,
    close,
    onOk,
    onCancel,
    timeout,
    afterTimeout,
    isSetInnerHtml,
    onTimeout: _onTimeout,
    ...rest
  } = props;

  useEffect(() => {
    let clear;
    if (timeout > 0 && rest.visible) {
      clear = setTimeout(() => {
        close();
        if (afterTimeout) {
          afterTimeout();
        }
      }, timeout);
    }
    return () => {
      if (clear) {
        clearTimeout(clear);
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [timeout]);

  useImperativeHandle(ref, () => ({
    close,
  }));
  const handleOnOk = (e, checked) => {
    if (onOk) {
      const ret = onOk(e, checked);
      if (!ret) {
        close(e, checked);
      } else if (ret.then) {
        ret.then(() => {
          close(e, checked);
        });
      }
    } else {
      close(e, checked);
    }
  };
  const handleOnCancel = (e, checked) => {
    if (onCancel) {
      const ret = onCancel(e, checked);
      if (!ret) {
        close(e, checked);
      } else if (ret.then) {
        ret.then(() => {
          close(e, checked);
        });
      }
    } else {
      close(e, checked);
    }
  };

  const onTimeout = (e, checked) => {
    if (_onTimeout) {
      const ret = _onTimeout(e, checked);
      if (!ret) {
        close(e, checked);
      } else if (ret.then) {
        ret.then(() => {
          close(e, checked);
        });
      }
    } else {
      close(e, checked);
    }
  };
  return (
    <Provider store={reduxStore}>
      <Modal
        onOk={handleOnOk}
        onCancel={handleOnCancel}
        onClose={close}
        isAutoTimeout={isExternalControlledMode()}
        onTimeout={onTimeout}
        rModalProps={{
          role: 'dialog',
        }}
        {...rest}
      >
        {content &&
          (isSetInnerHtml ? (
            <div
              className="confirm-modal-content"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          ) : (
            <div className="confirm-modal-content">{content}</div>
          ))}
      </Modal>
    </Provider>
  );
};
ConfirmModal = forwardRef(ConfirmModal);
let modalId = 0;

const modalRootList = {};
const createModalDiv = (_id) => {
  let div;
  let root = modalRootList[_id] ?? null;
  // eslint-disable-next-line no-plusplus
  const id = _id ?? `zm-modalId-${modalId++}`;
  div = document.getElementById(id);
  if (!div) {
    div = document.createElement('div');
    div.setAttribute('id', id);
    document.body.appendChild(div);
    root = createRoot(div);
    if (_id) {
      modalRootList[_id] = root;
    }
  }
  return { div, root };
};

function confirm(config) {
  const { name, isSingleton, id, ref } = config || {};
  if (isSingleton && name && queues[name]) {
    return;
  }
  const { div, root } = createModalDiv(id, ref);

  function render(props) {
    const { ref, name, isSingleton, ...restProps } = props;

    root.render(<ConfirmModal ref={ref} {...restProps} />);
    if (isSingleton && name) {
      queues[name] = name;
    }
  }

  let currentConfig = { ...config, close, visible: true };
  function close() {
    currentConfig = {
      ...currentConfig,
      visible: false,
    };
    if (currentConfig.onAfterClose) {
      currentConfig.onAfterClose();
    }
    render(currentConfig);
    const { name, isSingleton } = config || {};
    if (isSingleton && name && queues[name]) {
      delete queues[name];
    }
    setTimeout(() => {
      root.unmount();
      if (div.parentNode) {
        div.parentNode.removeChild(div);
      }
    }, 0);
  }

  render(currentConfig);
}

Modal.confirm = confirm;
export default Modal;
