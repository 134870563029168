import meetingConfig from 'meetingConfig';

export const srSettingUrl = () =>
  meetingConfig.meetingOptions?.smartRecodingEnableReminder?.content?.linkUrl;
export const goToSRSetting = () => {
  window.open(srSettingUrl(), '_blank');
};

export const onlyCanEnableSRByWeb = (state) => {
  const { srEnabled, srLocked } = state.recording.smartRecording;
  return (
    !srEnabled &&
    (srLocked ||
      meetingConfig.meetingOptions?.smartRecodingEnableReminder?.content
        ?.linkText)
  );
};
