import mitt from './mitt';
import { Content, MessageResExt } from './@types/type';
type sendPayload = {
  content: Content;
  isExternal: boolean;
};

type Events = {
  sendMessage: sendPayload;
  updateMessage: MessageResExt;
  sendCloudFileMessage: sendPayload;
  dangerousChange: { msgId: string; type: string; rest?: unknown };
  cancelDownload: string;
  deleteUnsentFailedMsg:
    | string
    | {
        mainMsgId: string;
        msgId: string;
      };
  sendMixAsSingleMessage: unknown;
  // recoverMessage: any;
  // [key: string]: string;
};

export const bridgeEmitter = mitt<Events>();
// @ts-ignore
// window.bridgeEmitter = bridgeEmitter;
