import meetingConfig from 'meetingConfig';
import {
  CHAT_FILE_TYPE,
  CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS,
  CHAT_MSG_TYPE_TO_ALL,
  CHAT_MSG_TYPE_TO_SILENT_MODE_USERS,
  CHAT_RECEIVER_TEXT_MAP,
} from '../../../features/chat/constants';
import {
  extractMsgFromIM,
  extractMsgFromXml,
} from '../../../features/new-chat/transform/cover';
import { CHAT_MSG_FROM, IQ_FROM_IM, JOIN_MEETING_POLICY } from '../../constant';
import { beginDecrypt, ivType } from '../../crypto';
import { coOrHostSelector } from '../../redux';
import { decodeBase64, getSafeBase64 } from '../../util';
import { errorLog, infoLog } from '../../web-client-logger';
import { isSupportedTimeZone } from '../../../features/reaction/utils';
import {
  convertXml2Json,
  getCorrect3rdPartyFileUrl,
  isBuildInChatReceiver,
} from '../../../features/chat/utils';
import { emitter } from '../emitter';
import {
  add,
  addComment,
  addUnread,
  deleteUnread,
  emojiUpdate,
  markMsgRead,
  resetUnRead,
  revoke,
  update,
  updateDownloadFile,
} from './action';
import {
  EmojiVoteResponse,
  GiphyItem,
  MarkMsgReadParams,
  MessageResExt,
  MessageResponse,
  RevokeMessage,
  UnreadChannelMap,
  emojiVoteMap,
  emojiVoteUser,
} from '../@types/type';
import {
  MentionMeAction,
  messageType,
  operaType,
  thirdPartyTypeNumToStrMap,
} from '../enums';
import { getToChannel, getFileMessageTypeByName } from '../util';
import { generateMentions } from '../../../features/new-chat/redux/buildListMeetingChat';
import {
  getTargetChatItem,
  search2,
  searchChatItem,
  searchMessageByTime,
} from './search2';
import { produce, type Draft } from 'immer';
import _ from 'lodash';
import {
  EmojiPayload,
  MeetingFilePayload,
  MeetingUser,
} from '../@types/meetingTypes';
import { trackMessageType } from '../../../features/new-chat/transform/enum';
// import { easyStore } from '../../easy-store';
// import { bridgeEmitter } from '../bridgeEmitter';

export type RWGMessage<T> = {
  body: T;
  evt: number;
  seq: number;
};
export type RWGChatBody = {
  attendeeNodeID: number;
  destNodeID: number;
  msgID: string;
  senderName: string;
  text: string;
  xmppMsgData?: string;
  sn?: string;
  chatFrom?: string;
  realSenderID?: string;
};

export interface ChatCMDBody {
  cmd: number;
  deletedOrModifiedBy: number;
  editContent: string;
  msgID: string;
  senderID: number;
  sn: string;
  xmppMsgData: string;
}

export function finallyAddToStore(savedItem: MessageResExt) {
  return (dispatch) => {
    if (savedItem.replyId) {
      dispatch(addCommentsEmitIfMainMsgForbidden(savedItem));
    } else {
      dispatch(add(savedItem));
      emitter.emit('message', [savedItem]);
    }
  };
}

export function triggerUnreadEvent() {
  return (_, getState) => {
    const {
      oneChat: { unreadChannelMap },
    } = getState();

    emitter.emit('unreadChannelMap', cloneUnreadChannelMap(unreadChannelMap));
  };
}

export function onReceiveChatMessageSaveOne(message: RWGMessage<RWGChatBody>) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: {
        bIbDisableChat,
        // defaultAvatar,
        restrictFeatures,
        // fileServerDomain,
        currentUser,
      },
      attendeesList: { attendeesList },
      // newChat: { meetingChat },
      meetingUI: { isOnHold },
    } = state;
    if (restrictFeatures[JOIN_MEETING_POLICY.CHAT]) return;
    const coOrHost = coOrHostSelector(state);

    const { xmppMsgData } = message.body || {};
    const isNewXmppChat = !!xmppMsgData;
    // const hasOldKVChat = !!text;

    if (typeof message.body !== 'undefined' && !bIbDisableChat) {
      const latestChatMessage = message.body;
      let senderSN = latestChatMessage.sn;

      const sender = attendeesList.find(
        (user) => user.userId === latestChatMessage.destNodeID,
      );
      if (!senderSN) {
        if (sender) {
          senderSN = sender.zoomID;
        }
      }
      const { isBuiltIn, receiverName, receiveUser } = getChatExtInfo(
        latestChatMessage,
        attendeesList,
        currentUser,
      );

      const meetingChatExt = {
        isBuiltIn,
        receiverName,
        receiverId: latestChatMessage.attendeeNodeID,
        receiverSN: receiveUser?.zoomID,
        isPrivately: !!receiveUser,
        isSilentMode: false,
        isMyMessage: false,
        senderSN,
        senderId: latestChatMessage.destNodeID,
        isToMyMessage:
          !!receiveUser && receiveUser?.userId === currentUser.userId,
        bid: currentUser.bid,
      };
      if (message.body.chatFrom === CHAT_MSG_FROM.CHAT_FROM_PMC_IM) {
        meetingChatExt.senderId = Number(latestChatMessage.realSenderID);
        meetingChatExt.receiverId = CHAT_MSG_TYPE_TO_ALL;
        meetingChatExt.receiverSN = '';
        meetingChatExt.isBuiltIn = true;
        meetingChatExt.isPrivately = false;
        meetingChatExt.isMyMessage = false;
        meetingChatExt.isToMyMessage = false;
        meetingChatExt.isSilentMode = false;
        meetingChatExt.receiverName =
          CHAT_RECEIVER_TEXT_MAP[CHAT_MSG_TYPE_TO_ALL];
      }
      /**
       * waiting room messages are unencrypted
       */
      // waiting room chat
      if (
        latestChatMessage.attendeeNodeID ===
          CHAT_MSG_TYPE_TO_SILENT_MODE_USERS ||
        latestChatMessage.attendeeNodeID ===
          CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS ||
        isOnHold ||
        (coOrHost && receiveUser?.bHold)
      ) {
        meetingChatExt.isSilentMode = true;

        Promise.resolve(
          isNewXmppChat
            ? extractMsgFromXml(decodeBase64(latestChatMessage.xmppMsgData))
            : {
                text: decodeBase64(latestChatMessage.text || ''),
              },
        ).then(({ text, timeStamp, ...rest }) => {
          const savedItem = {
            message: text,
            emojiVotes: {},
            time: Number(timeStamp || Date.now()),
            id: rest.xmppMsgId,
            senderName: sender.displayName,
            senderJid: senderSN,
            senderAvatar: sender?.avatar,
            messageXmlStr: '',
            fontStyte: rest.rtbItems?.item,
            messageType: messageType.text,
            operType: operaType.new,
            channelJid: getToChannel(),
            receiverJid: '',
            meetingChatExt,
          } as MessageResExt;
          // save waiting room message
          dispatch(add(savedItem));
          dispatch(addUnreadThunk(savedItem));
          emitter.emit('message', [savedItem]);
        });
      } else {
        // meeting chat

        /* eslint-disable-next-line no-prototype-builtins */
        const senderName = latestChatMessage.hasOwnProperty('senderName')
          ? decodeBase64(latestChatMessage.senderName)
          : sender.displayName;

        beginDecrypt({
          decryptedText: isNewXmppChat
            ? latestChatMessage.xmppMsgData
            : latestChatMessage.text,
          userId: senderSN,
          type: ivType.RWG_CHAT,
        })
          // receive text
          .then(({ message }) => {
            // console.log(other);
            // console.log(message, oldMessage, 222);
            // newMessage = message;
            if (isNewXmppChat) {
              if (
                latestChatMessage.chatFrom === CHAT_MSG_FROM.CHAT_FROM_PMC_IM
              ) {
                // pmc message from channel
                return extractMsgFromIM(message);
              } else {
                // meeting message
                return extractMsgFromXml(message);
              }
            } else {
              // old version chat return directly
              return Promise.resolve({
                text: message,
                msgId: latestChatMessage.msgID,
                xmppMsgId: latestChatMessage.msgID,
                isOldChat: true,
                reply: {},
                toChannel: getToChannel(),
                timeStamp: Date.now(),
              });
            }
          })
          .then(
            ({
              text: _text,
              timeStamp,
              // isEdit,
              fileID,
              isUnsupportedFeature,
              ...rest
            }) => {
              infoLog(
                'isXmpp ' +
                  isNewXmppChat +
                  'isUnsupportedFeature ' +
                  isUnsupportedFeature,
              );
              infoLog('decrypt txt: ' + _text);
              infoLog(`msg rest: ${JSON.stringify(rest)}`);

              if (rest.isAtEvent) {
                infoLog('received atEvent');
                return;
                // return dispatch(updateOneChatMessageThunk(rest));
              }
              if (rest.isVote) {
                let senderObj = sender;
                let storedSN = senderSN;

                if (rest.type === IQ_FROM_IM) {
                  // stored iq message used sn(zoomID) as key
                  // so if message from IM, use the in meeting user's sn
                  const realSenderId = Number(latestChatMessage.realSenderID);
                  const inMeetingAttendee = attendeesList.find(
                    (item) => item.userId === realSenderId,
                  );
                  senderObj = inMeetingAttendee || {
                    displayName: rest.senderName,
                    userId: latestChatMessage.realSenderID,
                  };
                  storedSN = inMeetingAttendee?.zoomID || senderSN;
                }
                const data = {
                  ...rest,
                  senderSN: storedSN,
                  senderObj,
                  senderId: senderObj.userId,
                };
                // transitEmitter.emit()
                const emojiStr = decodeBase64(data.id).replace(
                  /[\u200B-\u200D\uFEFF]/g,
                  '',
                );
                // dispatch(emojiUpdate({ ...data, emojiStr }));
                return dispatch(updateEmoji({ ...data, emojiStr })); //transitEmitter.emit('emojiVote', { ...data, emojiStr });
              }
              // if (rest.atUsers) {
              //   console.log(rest.atUsers);
              // }

              // const msgInfo = {
              //   senderId: latestChatMessage.destNodeID,
              //   receiverId: latestChatMessage.attendeeNodeID,
              // };

              // // reset msg from IM, because IM message was forwarded by gateway
              // if (
              //   latestChatMessage.chatFrom === CHAT_MSG_FROM.CHAT_FROM_PMC_IM
              // ) {
              //   msgInfo.senderId = Number(latestChatMessage.realSenderID);
              //   msgInfo.receiverId = CHAT_MSG_TYPE_TO_ALL;
              // }
              // TODO
              if (isUnsupportedFeature) {
                const savedItem = {
                  message: '',
                  emojiVotes: {},
                  time: Number(timeStamp),
                  id: rest.xmppMsgId,
                  replyId: rest.reply.mainMsgId,
                  replyOwnerJid: rest.reply.owner,
                  replyThreadTime: rest.reply.mainMsgTime
                    ? Number(rest.reply.mainMsgTime)
                    : null,
                  senderName,
                  senderJid: senderSN,
                  senderAvatar: sender?.avatar,
                  //! however giphy need this
                  messageXmlStr: '',
                  messageType: messageType.unknown,
                  operType: operaType.new,
                  channelJid: getSafeBase64(rest.toChannel) || getToChannel(),
                  meetingChatExt,
                  bid: currentUser.bid,
                } as MessageResExt;
                return dispatch(finallyAddToStore(savedItem));
              }
              if (_text !== null && !fileID) {
                let text = _text;
                if (
                  text &&
                  text.includes('🇹🇼') &&
                  meetingConfig.meetingOptions.enableFilterTWEmoji === true &&
                  !isSupportedTimeZone()
                ) {
                  text = text.replaceAll('🇹🇼', 'TW');
                  // if receive TW flag direct return;
                }
                const { mention, isAtMe } = generateMentions(
                  rest.atUsers,
                  currentUser.strConfUserID,
                );

                const finalMessageType =
                  rest.msgType == trackMessageType.giphy
                    ? messageType.giphy
                    : rest.allFiles
                    ? messageType.mix
                    : messageType.text;

                const giphyInfo = rest.giphyInfo as GiphyItem;
                const savedItem = {
                  message: text,
                  emojiVotes: {},
                  time: Number(timeStamp),
                  id: rest.xmppMsgId,
                  replyId: rest.reply.mainMsgId,
                  replyOwnerJid: rest.reply.owner,
                  replyThreadTime: rest.reply.mainMsgTime
                    ? Number(rest.reply.mainMsgTime)
                    : null,
                  senderName,
                  senderJid: senderSN,
                  senderAvatar: sender?.avatar,
                  //! however giphy need this
                  messageXmlStr: giphyInfo
                    ? giphyInfo.images.pcPicInfo.url ||
                      giphyInfo.images.bigPicInfo.url ||
                      giphyInfo.images.mobilePicInfo.url
                    : '',
                  fontStyte: rest.rtbItems?.item,
                  messageType: finalMessageType,
                  operType: operaType.new,
                  channelJid: getSafeBase64(rest.toChannel) || getToChannel(),
                  mention: mention,
                  receiverJid: '', //latestChatMessage.destNodeID + '', // TODO
                  mentionMeType: isAtMe && MentionMeAction.add,
                  meetingChatExt,
                  isUnreadMsg: true,
                  ...(rest.allFiles ? { allFiles: rest.allFiles } : {}),
                  failureBody: rest.failureBody,
                  giphyInfo: giphyInfo,
                  isEncrypted: rest.fr !== 'im',
                  bid: currentUser.bid,
                } as MessageResExt;
                dispatch(finallyAddToStore(savedItem));
                dispatch(addUnreadThunk(savedItem));
              } else {
                // receive file msg from IM
                const fileInfo = { ...rest, fileID } as MeetingFilePayload;
                const { fileType } = rest;
                // // if (_.isUndefined(fileType)) return;
                // // const is3rdFileShareFromIM
                const is3rdFileShareFromIM =
                  fileType === CHAT_FILE_TYPE.THIRD_PARTY;
                if (is3rdFileShareFromIM) {
                  fileInfo.correctPreviewUrl =
                    getCorrect3rdPartyFileUrl(fileInfo);
                }
                fileInfo.senderName = latestChatMessage.senderName;
                fileInfo.senderSN = senderSN!;
                fileInfo.timeStamp = timeStamp || Date.now();
                dispatch(
                  addFileMessage(fileInfo, message, {
                    displayName: atob(fileInfo.senderName),
                    zoomID: senderSN,
                  }),
                );
              }
            },
          );
      }
    }
  };
}

function addCommentsEmitIfMainMsgForbidden(savedItem: MessageResExt) {
  return (dispatch, getState) => {
    dispatch(addComment(savedItem));
    const lastMaybeForbidden = _.last(getState().oneChat.list) as MessageResExt;
    if (
      lastMaybeForbidden.id === savedItem.replyId &&
      lastMaybeForbidden.operType === operaType.forbidden &&
      !meetingConfig.meetingOptions.enableMeetingChatViewHistory
    ) {
      emitter.emit('message', [lastMaybeForbidden]);
    } else {
      emitter.emit('message', [savedItem]);
    }
  };
}

function getChatExtInfo(
  latestChatMessage: RWGChatBody,
  attendeesList: MeetingUser[],
  currentUser: MeetingUser,
): {
  isBuiltIn: boolean;
  receiverName: string;
  receiveUser: MeetingUser | null | undefined;
} {
  const isBuiltIn = isBuildInChatReceiver(latestChatMessage.attendeeNodeID);

  const receiveUser = isBuiltIn
    ? null
    : attendeesList.find(
        (user) => user.userId === latestChatMessage.attendeeNodeID,
      );

  let receiverName = isBuiltIn
    ? CHAT_RECEIVER_TEXT_MAP[latestChatMessage.attendeeNodeID]
    : receiveUser!.displayName;

  if (currentUser.userId === receiveUser?.userId) {
    receiverName = CHAT_RECEIVER_TEXT_MAP.me;
  }
  return { isBuiltIn, receiverName, receiveUser };
}

export function onEditSavedChatMessage(message: RWGMessage<ChatCMDBody>) {
  return (dispatch, getState) => {
    const { msgID, xmppMsgData, sn: senderSN } = message.body;

    const {
      meeting: { currentUser },
      oneChat: { list: chatList },
    } = getState();

    const searchRes = search2({ list: chatList }, msgID);
    if (!searchRes) return;

    const target =
      searchRes.length > 1
        ? (chatList[searchRes[0]].comments[searchRes[1]] as MessageResponse)
        : (chatList[searchRes[0]] as MessageResponse);
    if (!target) return;
    if (target.id !== msgID) return;
    // if (target.senderJid !== senderSN) return; // TODO check
    // if () {} waiting room // TODO

    beginDecrypt({
      decryptedText: xmppMsgData,
      type: ivType.RWG_CHAT,
      userId: senderSN,
    })
      .then(({ message }) => extractMsgFromXml(message))
      .then(
        ({
          text,
          rtbItems,
          atUsers /* targetXmppMsgId, timeStamp */,
          allFiles,
          failureBody,
        }) => {
          const { mention, isAtMe: afterMentionMe } = generateMentions(
            atUsers,
            currentUser.strConfUserID,
          );
          const beforeMentionMe = target.mentionMeType === MentionMeAction.add;
          const updatedItem = produce(target, (draft) => {
            draft.message = text;
            // draft.time = Number(timeStamp);
            draft.operType = operaType.edit;
            draft.fontStyte = rtbItems?.item;
            draft.mention = mention;
            if (draft.messageType === messageType.mix) {
              draft.allFiles = allFiles || [];
              draft.failureBody = failureBody || 0;
            }

            if (beforeMentionMe !== afterMentionMe) {
              draft.mentionMeType = afterMentionMe
                ? MentionMeAction.add
                : MentionMeAction.remove;
            } else if (beforeMentionMe && beforeMentionMe === afterMentionMe) {
              draft.mentionMeType = MentionMeAction.update;
            }
          });

          dispatch(update({ updatedItem, indexes: searchRes }));

          emitter.emit('editMessage', updatedItem);
          if (beforeMentionMe !== afterMentionMe) {
            emitter.emit('mentionMeMessage', updatedItem);
          } else if (beforeMentionMe && beforeMentionMe === afterMentionMe) {
            emitter.emit('mentionMeMessage', updatedItem);
          }
        },
      );
  };
}

export function onSelfRevokeMessageResponse(
  message: RWGMessage<{
    bSuccess: boolean;
    cmd: number;
    msgID: string;
  }>,
) {
  return (dispatch, getState) => {
    if (!message.body.bSuccess) {
      // TODO
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { searchRes, target } = searchChatItem(
        getState().oneChat,
        message.body.msgID,
      );
      // console.log(result);
      // setTimeout(() => {
      //   bridgeEmitter.emit('recoverMessage', message.body.msgID);
      //   emitter.emit('message', [target!]);
      // }, 1000);
    }
  };
}

export function onRevokeSavedChatMessage(message: RWGMessage<ChatCMDBody>) {
  return (dispatch, getState) => {
    const { sn, xmppMsgData, msgID } = message.body;
    const {
      // meeting: { currentUser },
      oneChat: { list: chatList },
    } = getState();

    if (xmppMsgData && sn) {
      beginDecrypt({
        decryptedText: xmppMsgData,
        userId: sn,
        type: ivType.RWG_CHAT,
      })
        .then(({ message }) => convertXml2Json(message))
        .then((json) => {
          const {
            _has_comment: hasComment,
            _thread: mainMsgId,
            _thread_t: mainMsgTimestamp,
            _id: msgId,
            _t: msgTimestamp,
            _mention_me: mentionMeFlag,
          } = _.get(json, 'message.revoke', {});

          const isDeleteMainMessage = mainMsgId === msgId;
          if (isDeleteMainMessage) {
            return dispatch(revokeExactMsgById(msgId));
          }

          // delete replies may not exist in store
          const [mainMessageIndex] = searchMessageByTime(
            chatList,
            Number(mainMsgTimestamp),
          );
          if (mainMessageIndex === -1) return;
          const targetItemIndex = chatList[mainMessageIndex].comments.findIndex(
            (c) => c.id === msgId,
          );
          const targetChatItem =
            chatList[mainMessageIndex].comments[targetItemIndex];
          const revokeEvent = {
            hasComment: Boolean(Number(hasComment)),
            mainMsgId,
            mainMsgTimestamp: Number(mainMsgTimestamp),
            msgId,
            msgTimestamp: Number(msgTimestamp),
            mentionMeType:
              mentionMeFlag || targetChatItem?.mentionMeType
                ? 'remove'
                : undefined,
          };
          dispatch(
            revoke({ msgID, searchRes: [mainMessageIndex, targetItemIndex] }),
          );
          emitter.emit('revokeMessage', revokeEvent as RevokeMessage);
          if (targetChatItem) {
            dispatch(
              deleteUnread({
                time: msgTimestamp,
                threadTime: mainMsgTimestamp,
                sessionId: targetChatItem.channelJid,
              }),
            );
          }
          dispatch(tryRevokeSavedFile(targetChatItem));
        });
    } else {
      dispatch(revokeExactMsgById(msgID));
    }
  };
}

function revokeExactMsgById(msgID: string) {
  return (dispatch, getState) => {
    const {
      // meeting: { currentUser },
      oneChat: { list: chatList },
    } = getState();
    const searchRes = search2({ list: chatList }, msgID);
    if (!searchRes) return;
    const targetChatItem = getTargetChatItem(searchRes, chatList);

    if (!targetChatItem) return;
    const revokeEvent = {
      hasComment: Boolean(targetChatItem.commentTotal),
      mainMsgId: targetChatItem.replyId,
      mainMsgTimestamp: Number(targetChatItem.replyThreadTime),
      msgId: msgID,
      msgTimestamp: targetChatItem.time,
      mentionMeType: targetChatItem?.mentionMeType ? 'remove' : undefined,
    };
    dispatch(revoke({ msgID, searchRes }));
    dispatch(
      deleteUnread({
        time: revokeEvent.msgTimestamp,
        threadTime: revokeEvent.mainMsgTimestamp,
        sessionId: targetChatItem.channelJid,
      }),
    );
    emitter.emit('revokeMessage', revokeEvent as RevokeMessage);
    dispatch(tryRevokeSavedFile(targetChatItem));

    dispatch(triggerUnreadEvent());
  };
}

export function tryRevokeSavedFile(targetChatItem: MessageResExt) {
  return (dispatch, getState) => {
    const {
      // meeting: { currentUser },
      oneChat: { downloadedMap },
    } = getState();
    try {
      if (targetChatItem.messageType === messageType.file) {
        if (targetChatItem.file && downloadedMap[targetChatItem.file.fileId]) {
          window.URL.revokeObjectURL(
            downloadedMap[targetChatItem.file.fileId].fileUrl,
          );
          dispatch(
            updateDownloadFile({
              fileId: targetChatItem.file.fileId,
              res: null,
            }),
          );
        }
      }
      if (targetChatItem.messageType === messageType.mix) {
        targetChatItem.allFiles?.forEach((f) => {
          if (f.fileUrl && f.fileUrl.search('blob:') !== -1) {
            window.URL.revokeObjectURL(downloadedMap[f.fileId].fileUrl);
          }
          if (downloadedMap[f.fileId]) {
            dispatch(
              updateDownloadFile({
                fileId: f.fileId,
                res: null,
              }),
            );
            window.URL.revokeObjectURL(downloadedMap[f.fileId].fileUrl);
          }
        });
      }
    } catch (error) {
      errorLog(`revoke blob url error: ${targetChatItem.id}`);
    }
  };
}

export function updateEmoji(data: EmojiPayload, isSelf = false) {
  return (dispatch, getState) => {
    const {
      oneChat,
      meeting: { currentUser, zoomId },
    } = getState();

    const isMySelf =
      zoomId === data.senderSN || currentUser.strConfUserID === data.senderJid;
    const searchRes = search2(oneChat, data.parentMsgId);
    if (!searchRes) return;
    const target = getTargetChatItem(searchRes, oneChat.list);
    const oldData = target?.emojiVotes ?? {};
    const emoji = data.emojiStr;

    const user: emojiVoteUser = {
      emoji,
      msgId: data.id,
      timestamp: Number(data.t),
      userJid: data.senderJid,
      username: data.senderObj.displayName,
    };
    let isDuplicatedEmojiVote;
    const newData = produce(oldData, (draft: Draft<emojiVoteMap>) => {
      const voteData = draft[emoji];
      if (data.action === 'add') {
        if (!voteData) {
          draft[emoji] = {
            count: 1,
            firstEmojiTime: Number(data.t),
            haveIEmojied: isMySelf,
            // users: {
            //   [`${user.userJid}`]: user,
            // },
            users: [user],
          };
        } else if (voteData.count === 0) {
          voteData.firstEmojiTime = Number(data.t);
          voteData.users = [user];
          voteData.haveIEmojied = isMySelf;
        } else {
          isDuplicatedEmojiVote =
            voteData.users.findIndex(
              ({ userJid }) => userJid === user.userJid,
            ) >= 0;

          if (isDuplicatedEmojiVote) {
            return;
          }
          voteData.count += 1;
          voteData.haveIEmojied = isMySelf;
          voteData.users.push(user);
        }
        // draft.users!;
      } else if (data.action === 'remove') {
        if (!voteData) return;
        if (voteData.count === 1) {
          delete draft[emoji];
        } else {
          voteData.count -= 1;
          voteData.haveIEmojied = !(voteData.haveIEmojied && isMySelf);
          const removedIndex = voteData.users.findIndex(
            (u) => u.userJid === user.userJid,
          );
          if (removedIndex !== -1) {
            voteData.users.splice(removedIndex, 1);
            // delete voteData.users![`${user.userJid}`];
          }
        }
      }
    });
    dispatch(emojiUpdate({ searchRes, emoji, update: newData }));

    return (
      !isDuplicatedEmojiVote &&
      !isSelf &&
      emitter.emit('emojiVote', {
        senderJid: data.senderJid,
        time: Number(data.t),
        msgId: data.parentMsgId,
        msgTimestamp: Number(data.t),
        emoji: data.emojiStr,
        action: data.action,
      } as EmojiVoteResponse)
    );
  };
}

/*
{
"735ff98cd7104af7af0060cfa4ea8fa1@conference.xmppdev.zoom.us": {
    "sessionId": "735ff98cd7104af7af0060cfa4ea8fa1@conference.xmppdev.zoom.us",
    "unreadCount": 5,
    "lastReadTime": 1695275371672,
    "threadMap": {
        "1695181351758": {
            "threadTime": 1695181351758,
            "lastReadTime": 1695188645743,
            "unreadCount": 3,
            "unreadMsgTimestamps": new Set([1695181351758])
        },
        "1695224682909": {
            "threadTime": 1695224682909,
            "lastReadTime": 1695225097876,
            "unreadCount": 1,
            "unreadMsgTimestamps":  new Set([1695181351758])
        }
    },
    "unreadMsgTimestamps":  new Set([1695181351758])
}
}
*/
export function markMsgReadThunk(p?: MarkMsgReadParams) {
  return (dispatch) => {
    // const {
    //   oneChat: { list },
    // } = getState();
    // const { time, threadTime } = p;

    // // mark read reply
    // if (threadTime && time) {
    //   const result = binarySearchMessageByTime(list, threadTime);
    //   if (result[0] === -1) return;
    //   const main = list[result[0]];
    //   const index = binarySearchMessageByTime(main.comments, time);
    //   return dispatch(markMsgRead({ indexes: [result[0], index[0]] }));
    // }

    // // mark read main
    // if (time) {
    //   const result = binarySearchMessageByTime(list, time);
    //   const index = result[0];
    //   if (index === -1) return;
    //   return dispatch(markMsgRead({ indexes: [index] }));
    // }

    // if (!time) {
    // }
    // setTimeout(() => {
    if (!p) {
      dispatch(resetUnRead());
    } else {
      dispatch(markMsgRead(p));
    }

    dispatch(triggerUnreadEvent());
    // }, 300);
  };
}

function cloneUnreadChannelMap(unreadChannelMap: UnreadChannelMap) {
  const onlyKey = Object.keys(unreadChannelMap)[0];
  if (!onlyKey) return unreadChannelMap;
  const { threadMap, unreadMsgTimestamps, ...rest } = unreadChannelMap[onlyKey];
  const newSet = new Set(Array.from(unreadMsgTimestamps));

  const clonedThreadMap = {};
  Object.keys(threadMap).forEach((key) => {
    const { unreadMsgTimestamps: unreadReplyMsgTimestamps, ...rest } =
      threadMap[key];

    const newSet = new Set(Array.from(unreadReplyMsgTimestamps));
    clonedThreadMap[key] = { ...rest, unreadMsgTimestamps: newSet };
  });
  const clonedObj = {
    ...rest,
    unreadMsgTimestamps: newSet,
    threadMap: clonedThreadMap,
  };
  return {
    [onlyKey]: clonedObj,
  };
}

export function addUnreadThunk(params: MessageResExt) {
  return (dispatch, getState) => {
    // setTimeout(() => {
    dispatch(addUnread(params));
    const {
      oneChat: { unreadChannelMap },
    } = getState();

    emitter.emit('unreadChannelMap', cloneUnreadChannelMap(unreadChannelMap));
    // }, 300);
  };
}

export function addFileMessage(
  fileInfo: MeetingFilePayload,
  latestChatMessage: RWGMessage<Omit<RWGChatBody, 'text'>>,
  senderUser,
) {
  return (dispatch, getState) => {
    const state = getState();

    const {
      meeting: { currentUser },
      attendeesList: { attendeesList },
    } = state;

    const isBuiltIn = isBuildInChatReceiver(
      latestChatMessage.body.attendeeNodeID,
    );

    const receiveUser = !isBuiltIn
      ? attendeesList.find(
          (user) => user.userId === latestChatMessage.body.attendeeNodeID,
        )
      : null;

    const avatar =
      !isBuiltIn &&
      attendeesList.find(
        (user) => user.userId === latestChatMessage.body.destNodeID,
      )?.avatar;

    let receiverName = isBuiltIn
      ? CHAT_RECEIVER_TEXT_MAP[latestChatMessage.body.attendeeNodeID]
      : receiveUser?.displayName;

    if (currentUser.userId === receiveUser?.userId) {
      receiverName = CHAT_RECEIVER_TEXT_MAP.me;
    }

    const meetingChatExt = {
      isBuiltIn: isBuildInChatReceiver(latestChatMessage.body.attendeeNodeID),
      receiverName,
      receiverId: latestChatMessage.body.attendeeNodeID,
      receiverSN: receiveUser?.zoomId,
      isPrivately: !!receiveUser,
      isSilentMode: false,
      isMyMessage: false,
      senderSN: senderUser.zoomID,
      senderId: latestChatMessage.body.destNodeID,
      isToMyMessage: !!receiveUser && receiveUser.userId === currentUser.userId,
      bid: currentUser.bid,
      // @ts-ignore
      // sessionKey: window.easyStore.getRawSessionKey(),
    };
    if (latestChatMessage.body.chatFrom === CHAT_MSG_FROM.CHAT_FROM_PMC_IM) {
      meetingChatExt.senderId = Number(latestChatMessage.body.realSenderID);
      meetingChatExt.receiverId = CHAT_MSG_TYPE_TO_ALL;
      meetingChatExt.receiverSN = '';
      meetingChatExt.isBuiltIn = true;
      meetingChatExt.isPrivately = false;
      meetingChatExt.isMyMessage = false;
      meetingChatExt.isToMyMessage = false;
      meetingChatExt.isSilentMode = false;
      meetingChatExt.receiverName =
        CHAT_RECEIVER_TEXT_MAP[CHAT_MSG_TYPE_TO_ALL];
    }
    // eslint-disable-next-line prefer-const
    let { type, fileExt } = getFileMessageTypeByName(fileInfo.fileName);
    let thirdStorageInfo;
    let thirdPartyType;
    if (fileInfo.previewPath) {
      type = messageType.file;
      thirdStorageInfo = {
        id: fileInfo.fileID,
        fileName: fileInfo.fileName,
        fileSize: Number(fileInfo.fileSize),
        extFile: fileExt,
        previewLink: fileInfo.previewUrl!,
        previewPath: fileInfo.previewPath!,
      };

      thirdPartyType = thirdPartyTypeNumToStrMap[fileInfo._t!];
    }

    const savedItem: MessageResExt = {
      message: '',
      emojiVotes: {},
      time: Number(fileInfo.timeStamp),
      id: fileInfo.xmppMsgId,
      replyId: fileInfo.reply.mainMsgId,
      replyOwnerJid: fileInfo.reply.owner,
      replyThreadTime: fileInfo.reply.mainMsgTime
        ? Number(fileInfo.reply.mainMsgTime)
        : null,
      senderName: senderUser.displayName,
      senderJid: senderUser.zoomID,
      senderAvatar: senderUser?.avatar || avatar || '', // make zod happy
      messageXmlStr: '',
      // fontStyte: [],
      messageType: type,
      operType: operaType.new,
      channelJid: getToChannel(),
      // mention: null,
      receiverJid: '',

      // mentionMeType: isAtMe && MentionMeAction.add,
      meetingChatExt,
      thirdStorageInfo,
      thirdPartyType,
      file: {
        fileId: fileInfo.fileID,
        fileName: fileInfo.fileName,
        size: Number(fileInfo.fileSize),
        fileObj: fileInfo.fileObj,
        senderSN: fileInfo.senderSN,
        isEncrypted: fileInfo.fr !== 'im',
      },
      isEncrypted: fileInfo.fr !== 'im',
      bid: currentUser.bid,
    };
    emitter.emit('message', [savedItem]);
    dispatch(finallyAddToStore(savedItem));
    dispatch(addUnreadThunk(savedItem));
  };
}
