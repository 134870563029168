import { iText } from '../../../../global/util';

export const MediaType = {
  Image: 'image',
  Audio: 'audio',
  Invisible: 'invisible',
  Visible: 'visible',
};
export const CaptchaTypes = {
  VisibleRecaptcha: 'VisibleRecaptcha',
  InvisibleRecaptcha: 'InvisibleRecaptcha',
  SmartCaptcha: 'smart',
  NoCaptcha: 'nocaptcha',
};

export const SmartCaptchaType = {
  Image: 0,
  Audio: 1,
};

export const PlayStatus = {
  Play: 0,
  Ended: 1,
};

export const CAPTCHA_NO_PERMISSION_ERROR_CODE = 200;
export const CAPTCHA_ERROR_CODE = 9505;
export const ERROR_WEBCLIENT_POST_INVISIBLE_CAPTCHA_ERROR = 9515;
export const ERROR_WEBCLIENT_BLOCK_BOT_ERROR = 9516;

export const CAPTCHAT_TIP = {
  wrong: iText(
    'Captcha wrong, please try again',
    'apac.wc_meeting.captcha_wrong_try_again',
  ),
  show: iText(
    'Please input captcha and retry',
    'apac.wc_meeting.input_captcha',
  ),
};
