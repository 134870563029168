/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@zoom/zoom-react-ui';
import { useClickOutSide2 } from '../../../hooks/use-click-outside-2';
import { Paragraph } from '../../../../components/utils/paragraph';
import { promptA11yInfo } from '../../../util';

export const Disclaimer = ({
  buttonText,
  tipText,
  tipPosition,
  type,
  contentNode,
  className,
}) => {
  const buttonRef = useRef();
  const [isOpen, changeOpen] = useState(false);
  useClickOutSide2(buttonRef, '.zmu-tooltip .disclaimer', () =>
    changeOpen(false),
  );

  useEffect(() => {
    changeOpen(false);
  }, [buttonText, tipText]);

  useEffect(() => {
    if (isOpen) {
      promptA11yInfo(tipText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const textParts = [].concat(tipText);
  return (
    <Tooltip
      position={tipPosition}
      trigger="controlled"
      contentNode={contentNode || <Paragraph textParts={textParts} />}
      paperClassName={classNames('disclaimer', `disclaimer--${type}`)}
      isOpen={isOpen}
    >
      <button
        type="button"
        onClick={() => changeOpen(!isOpen)}
        tabIndex={0}
        ref={buttonRef}
        className={classNames('disclaimer__button-wrap', className)}
      >
        <i className="disclaimer__icon" />
        <span className="disclaimer__button-text">{buttonText}</span>
      </button>
    </Tooltip>
  );
};

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/components/widget/disclaimer/disclaimer.scss';
