import * as socketEventTypes from '../../constants/ZoomSocketEventTypes';
import {
  showStartRecordingTimeoutDialog,
  showAutoRecordingTimeoutDialog,
} from '../../features/dialog/redux/dialog-thunk-action';
import { setStartRecordingNotificationVisible } from '../../actions/MeetingUIActions';
import { resetRecordingStatus } from '../../actions/MeetingActions';
import { startRecordingTimeoutValidator } from './timeout-response-validator';

export const defaultTimeout = 10000;

export const timeoutCommandSocketEvents = {
  [socketEventTypes.WS_CONF_RECORD_REQ]: {
    responseEventType: [
      socketEventTypes.WS_CONF_ATTRIBUTE_INDICATION,
      socketEventTypes.WS_CONF_CHECK_CMR_PRIVILEGE_RES,
    ],
    actions: [
      {
        name: 'START-RECORDING',
        reqValidator: (payload) => payload.bRecord && !payload.bPause,
        resValidator: startRecordingTimeoutValidator,
        timeoutExecutor: (dispatch) => {
          dispatch(showStartRecordingTimeoutDialog());
          dispatch(setStartRecordingNotificationVisible(false));
          dispatch(resetRecordingStatus());
        },
      },
      {
        name: 'AUTO-RECORDING',
        reqValidator: (payload) => payload.isAutoRecording,
        resValidator: startRecordingTimeoutValidator,
        timeoutExecutor: (dispatch) => {
          dispatch(showAutoRecordingTimeoutDialog());
          dispatch(resetRecordingStatus());
        },
      },
    ],
    timeout: defaultTimeout,
  },
};

export const isShouldTrackCommandSocketEvent = ({ evt, body }) => {
  const keys = Object.keys(timeoutCommandSocketEvents);
  const targetEventKey = keys.find((key) => key === `${evt}`);
  if (targetEventKey) {
    return timeoutCommandSocketEvents[targetEventKey].actions.some((action) =>
      action.reqValidator(body),
    );
  }

  return false;
};

export const isShouldHandleCommandSocketEvent = (eventType) => {
  const values = Object.values(timeoutCommandSocketEvents);
  return !!values.find(({ responseEventType }) =>
    Array.isArray(responseEventType)
      ? responseEventType.find((type) => type === eventType)
      : responseEventType === eventType,
  );
};
