import { createSelector } from 'reselect';
import {
  raiseHandParticipantSelector,
  isReceiveSharingSelector,
} from '../../../../global/redux/selector';
import { spotlightVideoListSelector } from './video-common-selector';

const pinVideoListSelector = ({ video: { pinVideoList } }) => pinVideoList;

const hasChangedVideoOrderSelector = ({ video: { hasChangedVideoOrder } }) =>
  hasChangedVideoOrder;

const currentUserSelector = ({ meeting: { currentUser } }) => currentUser;

//------------------------------------------------------------------------------

export const isNeedSpecialOrderSelector = createSelector(
  [
    raiseHandParticipantSelector,
    hasChangedVideoOrderSelector,
    spotlightVideoListSelector,
    pinVideoListSelector,
    currentUserSelector,
  ],
  (
    raiseHandParticipant,
    hasChangedVideoOrder,
    spotlightVideoList,
    pinVideoList,
    currentUser,
  ) => {
    if (hasChangedVideoOrder) {
      return false;
    }

    if (
      raiseHandParticipant.length > 0 ||
      spotlightVideoList.length > 0 ||
      pinVideoList.length > 0
    ) {
      const raiseHandParticipantIds = (raiseHandParticipant || []).map(
        (item) => item.userId,
      );

      const totalSpecialList = [
        ...raiseHandParticipantIds,
        ...spotlightVideoList,
        ...pinVideoList,
      ];
      const uniqTotalList = _.uniq(totalSpecialList);
      const isCurrentUserInSpecialList = uniqTotalList.some(
        (item) => item === currentUser.userId,
      );
      if (isCurrentUserInSpecialList) {
        return false;
      }
      return true;
    }

    return false;
  },
);

export const isNeedSpeakerSpecialOrderSelector = createSelector(
  [
    isNeedSpecialOrderSelector,
    isReceiveSharingSelector,
    raiseHandParticipantSelector,
  ],
  (isNeedSpecialOrder, isReceiveSharing, raiseHandParticipant) => {
    if (!isNeedSpecialOrder) {
      return false;
    }

    if (raiseHandParticipant.length) {
      return true;
    }

    if (isReceiveSharing) {
      return true;
    }

    return false;
  },
);

export const specialOrderUserSizeSelector = createSelector(
  [
    isNeedSpecialOrderSelector,
    raiseHandParticipantSelector,
    spotlightVideoListSelector,
    pinVideoListSelector,
  ],
  (
    isNeedSpecialOrder,
    raiseHandParticipant,
    spotlightVideoList,
    pinVideoList,
  ) => {
    if (!isNeedSpecialOrder) {
      return 0;
    }

    const raiseHandParticipantIds = (raiseHandParticipant || []).map(
      (item) => item.userId,
    );

    const totalSpecialList = [
      ...raiseHandParticipantIds,
      ...spotlightVideoList,
      ...pinVideoList,
    ];
    const uniqTotalList = _.uniq(totalSpecialList);

    return uniqTotalList.length;
  },
);

export const speakerSpecialOrderUserSizeSelector = createSelector(
  [
    isNeedSpeakerSpecialOrderSelector,
    raiseHandParticipantSelector,
    spotlightVideoListSelector,
    pinVideoListSelector,
    isReceiveSharingSelector,
  ],
  (
    isNeedSpeakerSpecialOrder,
    raiseHandParticipant,
    spotlightVideoList,
    pinVideoList,
    isReceiveSharing,
  ) => {
    if (!isNeedSpeakerSpecialOrder) {
      return 0;
    }

    const raiseHandParticipantIds = (raiseHandParticipant || []).map(
      (item) => item.userId,
    );

    let totalSpecialList = raiseHandParticipantIds;
    if (isReceiveSharing) {
      totalSpecialList = [
        ...raiseHandParticipantIds,
        ...spotlightVideoList,
        ...pinVideoList,
      ];
    }

    const uniqTotalList = _.uniq(totalSpecialList);

    return uniqTotalList.length;
  },
);
