import { createActions } from 'redux-actions';

export const {
  updateNewMeetingChat,
  updateNewMeetingChatFile,
  updateNewChatIndexes,
  deleteNewChat,
  editMeetingChat,
  changeReadNewChatState,
  cleanUnreadNewChat,
  setSelfNewChatId,
  resetNewChat,
  hideChatPersistentTip,
  setValidChannels,
  setChatPMCTipShow,
  pushSysInfo,
  addPmcHeader,
  toggleThread,
  updateThreadDraft,
  removeThreadInput,
  addThreadInput,
  clearNewChat,
  setLockTime,
  setIsPinBottom,
  addVoteEmoji,
  removeVoteEmoji,
  updateEditFlag,
  saveRtfDraft,
  toggleBottomFormat,
  saveLatestMessage,
  updateOneChatMessage,
  setQuoteText,
  setSelectedFiles,
} = createActions(
  'setSelectedFiles',
  'updateOneChatMessage',
  'setQuoteText',
  'saveRtfDraft',
  'toggleBottomFormat',
  'SET_IS_PIN_BOTTOM',
  'SET_LOCK_TIME',
  'CLEAR_NEW_CHAT',
  'UPDATE_NEW_CHAT_INDEXES',
  'UPDATE_NEW_MEETING_CHAT',
  'UPDATE_NEW_MEETING_CHAT_FILE',
  'DELETE_NEW_CHAT',
  'EDIT_MEETING_CHAT',
  'CLEAN_UNREAD_NEW_CHAT',
  'CHANGE_READ_NEW_CHAT_STATE',
  'SET_SELF_NEW_CHAT_ID',
  'RESET_NEW_CHAT',
  'HIDE_CHAT_PERSISTENT_TIP',
  'SET_VALID_CHANNELS',
  'SET_CHAT_P_M_C_TIP_SHOW',
  'PUSH_SYS_INFO',
  'TOGGLE_THREAD',
  'UPDATE_THREAD_DRAFT',
  'REMOVE_THREAD_INPUT',
  'ADD_THREAD_INPUT',
  'ADD_VOTE_EMOJI',
  'REMOVE_VOTE_EMOJI',
  'UPDATE_EDIT_FLAG',
  'SAVE_LATEST_MESSAGE',
  'ADD_PMC_HEADER',
);
