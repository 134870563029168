import { isInRoom } from '../../../features/breakout-room/utils';
import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import { sendSocketMessage } from '../../../actions/SocketActions';
import AliveToast from '../../containers/notification-manager/alive-toast';
import { removedSuccessTipText } from '../../../features/dialog/resource';

export const assignHostThunkAction = (userId) => (dispatch, getState) => {
  let targetId = userId;
  const {
    meeting: { isHost },
    attendeesList: { attendeesList },
    breakoutRoom: {
      attendee: { status },
      mainSessionAttendeeList,
    },
  } = getState();
  if (!isHost) {
    return;
  }
  if (isInRoom(status)) {
    const targetUserInBo = attendeesList.find(
      (attendee) => attendee.userId === userId,
    );
    if (targetUserInBo) {
      const meInMeeting = mainSessionAttendeeList.find(
        (attendee) => attendee.zoomID === targetUserInBo.zoomID,
      );
      if (meInMeeting) {
        targetId = meInMeeting.userId;
      }
    }
  }
  const data = {
    evt: socketEventTypes.WS_CONF_ASSIGN_HOST_REQ,
    body: {
      id: targetId,
      bCoHost: false,
    },
  };
  dispatch(sendSocketMessage(data));
};
export const removeUserThunkAction =
  (userId, userName) => (dispatch, getState) => {
    const {
      breakoutRoom: {
        attendee: { status },
        mainSessionAttendeeList,
      },
      attendeesList: { attendeesList },
      meeting: {
        currentUser: { bCoHost },
        isHost,
      },
    } = getState();
    if (!isHost && !bCoHost) {
      return;
    }
    let id = userId;
    if (isInRoom(status)) {
      const targetUserInBo = attendeesList.find(
        (attendee) => attendee.userId === userId,
      );
      if (targetUserInBo) {
        const meInMeeting = mainSessionAttendeeList.find(
          (attendee) => attendee.zoomID === targetUserInBo.zoomID,
        );
        if (meInMeeting) {
          id = meInMeeting.userId;
        }
      }
    }
    const data = {
      evt: socketEventTypes.WS_CONF_EXPEL_REQ,
      body: {
        id,
      },
    };
    dispatch(sendSocketMessage(data));
    AliveToast.toast({
      message: removedSuccessTipText(userName),
      showClose: true,
    });
  };
