import { createActions } from 'redux-actions';

export const {
  showPollDialog,
  setPollData,
  setPollingState,
  setPollingId,
  answerQuestion,
  setLocalElapsedTime,
  pollReset,
  resetPollLoadingStatus,
  setPollingWebLinks,
  setIsPollingRequestSent,
  setIsRwgEnablePolling,
  setHasPollingInMeeting,
  recheckCanSubmit,
  setQuizShareOption,
  setOneQuestionAtATimeIndex,
  setWaitingRwgPollStateLoading,
  setPollingDocMaxSize,
  setPollingDocSize,
  setPollingUrlType,
  setEditingPollingId,
  setHighlightPollingIds,
  setModuleFrom,
  setFilterPollType,
  setSavedPollName,
  setPollListLoading,
  setPollingDocLibraryMaxSize,
  setPollingDocLibrarySize,
  setIframeLoadStatus,
  setShowNewPollLibraryBanner,
  setListActiveTab,
} = createActions(
  'SHOW_POLL_DIALOG',
  'SET_POLL_DATA',
  'SET_POLLING_STATE',
  'SET_POLLING_ID',
  'ANSWER_QUESTION',
  'SET_LOCAL_ELAPSED_TIME',
  'POLL_RESET',
  'RESET_POLL_LOADING_STATUS',
  'SET_POLLING_WEB_LINKS',
  'SET_IS_POLLING_REQUEST_SENT',
  'SET_IS_RWG_ENABLE_POLLING',
  'SET_HAS_POLLING_IN_MEETING',
  'RECHECK_CAN_SUBMIT',
  'SET_QUIZ_SHARE_OPTION',
  'SET_ONE_QUESTION_AT_A_TIME_INDEX',
  'SET_WAITING_RWG_POLL_STATE_LOADING',
  'SET_POLLING_DOC_MAX_SIZE',
  'SET_POLLING_DOC_SIZE',
  'SET_POLLING_URL_TYPE',
  'SET_EDITING_POLLING_ID',
  'SET_HIGHLIGHT_POLLING_IDS',
  'SET_MODULE_FROM',
  'SET_FILTER_POLL_TYPE',
  'SET_SAVED_POLL_NAME',
  'SET_POLL_LIST_LOADING',
  'SET_POLLING_DOC_LIBRARY_MAX_SIZE',
  'SET_POLLING_DOC_LIBRARY_SIZE',
  'SET_IFRAME_LOAD_STATUS',
  'SET_SHOW_NEW_POLL_LIBRARY_BANNER',
  'SET_LIST_ACTIVE_TAB',
);
