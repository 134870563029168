export const feedbackOptions = {
  NO_FEEDBACK: 0,
  FEEDBACK_RANDOMLY: 1,
  FEEDBACK_EVERYONE: 2,
};

export const AV_STRATEGY = {
  MEETING_BIT_VAL: 0,
  WEBINAR_BIT_VAL: 1,
  WEBRTC_VIDEO_BIT_VAL: 1,
  WASM_VIDEO_BIT_VAL: 0,
  WEBRTC_AUDIO_BIT_VAL: 1,
  WASM_AUDIO_BIT_VAL: 0,
};
