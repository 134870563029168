import { iText } from '../../global/util';

export const BREAKOUTROOMS = iText(
  'Breakout Rooms',
  'apac.wc_bo.breakout_rooms',
);
export const CHOOSE_BREAKOUT_ROOM = iText(
  'Choose Breakout Room',
  'apac.wc_bo.choose_breakout_room',
);
export const BREAKOUTROOM_CREATEROOM_WINDOW_TITLE = iText(
  'Create Breakout Rooms',
  'apac.wc_bo.create_room_window_title',
);

export const assignRoomsText = ([participantsSize, placeholder]) =>
  iText(
    `Assign ${participantsSize}
participants into${placeholder}Rooms.`,
    'apac.wc_bo.assign_rooms',
    [participantsSize, placeholder],
  );
export const assignRoomsText2 = (placeholder) =>
  iText(
    `Create ${placeholder} breakout rooms`,
    'apac.wc_bo.assign_rooms_2',
    placeholder,
  );

export const assignRoomsText3 = (placeholder) =>
  iText(
    `Recreate ${placeholder} breakout rooms`,
    'apac.wc_bo.assign_rooms_3',
    placeholder,
  );

export const howmanyPerRoom = (sizePerRoom) =>
  iText(
    `${sizePerRoom} participants per room`,
    'apac.wc_bo.perroom_size',
    sizePerRoom,
  );

export const BREAKOUTROOM_DISTRIBUTION = iText(
  'Distribution',
  'apac.wc_bo.distrubution',
);
export const BREAKOUTROOM_DISTRIBUTION_AUTO_ASSIGN = iText(
  'Assign automatically',
  'apac.wc_bo.distrubution_auto_assign',
);
export const BREAKOUTROOM_DISTRIBUTION_MANUALLY_ASSIGN = iText(
  'Assign manually',
  'apac.wc_bo.distrubution_manually_assign',
);
export const BREAKOUTROOM_DISTRIBUTION_SELF_SELECT = iText(
  'Let participants choose room',
  'apac.wc_bo.distrubution_self_select',
);

export const BREAKOUTROOM_OPEN_ALL_ROOMS = iText(
  'Open All Rooms',
  'apac.wc_bo.open_all_rooms',
);
export const BREAKOUTROOM_ADD_ROOM = iText('Add Room', 'apac.wc_bo.addroom2');
export const BREAKOUTROOM_RECREATE_BUTTON = iText(
  'Recreate',
  'apac.wc_bo.recreate',
);
export const BREAKOUTROOM_RECREATE_ALL_ROOMS = iText(
  'Recreate All Rooms',
  'apac.wc_bo.recreate_all_rooms',
);
export const BREAKOUTROOM_OPTIONS = iText('Options', 'apac.wc_bo.options');
export const BREAKOUTROOM_RECREATE_TIPS = iText(
  'All existing rooms will be replaced.',
  'apac.wc_bo.recreate_tips',
);
export const BREAKOUTROOM_OPTIONS_AUTOMOVE = iText(
  'Automatically move all assigned participants into breakout rooms',
  'apac.wc_bo.options_automove',
);
export const BREAKOUTROOM_OPTIONS_AUTORETURN = iText(
  'Automatically move all selected participants in breakout rooms to main meeting',
  'apac.wc_bo.options_autoreturn',
);
export const BREAKOUTROOM_OPTIONS_ALLOWRETURN = iText(
  'Allow participants to return to the main session at any time',
  'apac.wc_bo.options_alllowreturn',
);
export const BREAKOUTROOM_OPTIONS_ALLOW_PARTICIPANTS_CHOOSE_ROOM = iText(
  'Allow participants to choose room',
  'apac.wc_bo.options_allow_participants_choose_room',
);
export const BREAKOUTROOM_OPTIONS_TIMEUPNOTIFY = iText(
  'Notify me when the time is up',
  'apac.wc_bo.options_timeupnotify',
);
export const BREAKOUTROOM_OPTIONS_NEEDCOUNTDOWN = iText(
  'Countdown after closing breakout room',
  'apac.wc_bo.options_needcountdown',
);
export const closeRoomAutoText = (placeholder) =>
  iText(
    `Auto close breakout rooms after ${placeholder} minutes`,
    'apac.wc_bo.options_closeroom_auto',
    placeholder,
  );
export const BREAKOUTROOM_COUNTDOWN_TIMER = iText(
  'Set Countdown timer',
  'apac.wc_bo.options_countdowntime2',
);

export const countDownTimeText = (time) =>
  iText(`${time} seconds`, 'apac.wc_bo.options_countdowntime3', time);

export const BREAKOUTROOM_ASSIGN = iText('Assign', 'apac.wc_bo.assign');
export const BREAKOUTROOM_DEFAULT_ROOM_NAME = iText(
  'Room',
  'apac.wc_bo.default_room_name2',
);
export const BREAKOUTROOMS_NO_UNASSIGNED_TIP = iText(
  'All participants have been assigned to Breakout Rooms.',
  'apac.wc_bo.no_unassigned_tip',
);
export const deleteBoRoomConfirmTip = (roomName) =>
  iText(`Delete ${roomName}?`, 'apac.wc_bo.delete_room_confirm_tip', roomName);
export const joinBoRoomConfirmTip = (roomName) =>
  iText(`Join ${roomName}?`, 'apac.wc_bo.join_room__confirm_tip', roomName);
export const leaveBoRoomConfirmTip = (roomName) =>
  iText(`Leave ${roomName}?`, 'apac.wc_bo.leave_room_confirm_tip', roomName);

export const BREAKOUTROOM_JOINED = iText('Joined', 'apac.wc_bo.joined');
export const BREAKOUTROOM_NOT_JOINED = iText(
  'Not Joined',
  'apac.wc_bo.not_joined',
);
export const BREAKOUTROOM_MOVETO = iText('Move To', 'apac.wc_bo.moveto');
export const BREAKOUTROOM_ASSIGN_TO = iText(
  'Assign To',
  'apac.wc_bo.assign_to',
);
export const BREAKOUTROOM_EXCHANGE = iText('Exchange', 'apac.wc_bo.exchange');
export const BREAKOUTROOM_IN_PROGRESS = iText(
  'In Progress',
  'apac.wc_bo.in_progress',
);
export const BREAKOUTROOM_NOT_STARTED = iText(
  'Not Started',
  'apac.wc_bo.not_started',
);
export const BREAKOUTROOM_JOIN = iText('Join', 'apac.wc_bo.join');
export const BREAKOUTROOM_LEAVE = iText('Leave', 'apac.wc_bo.leave');

export const BREAKOUTROOMS_CLOSE_ALL_ROOMS = iText(
  'Close All Rooms',
  'apac.wc_bo.close_all_rooms',
);
export const BREAKOUTROOMS_BROADCASE = iText(
  'Broadcast',
  'apac.wc_bo.broadcast_to_all',
);
export const BREAKOUTROOMS_BROADCASE_MESSAGES = iText(
  'Broadcast Message',
  'apac.wc_bo.broadcast_to_all_message',
);
export const BREAKOUTROOMS_BROADCASE_VOICES = iText(
  'Broadcast Voice',
  'apac.wc_bo.broadcast_to_all_voice',
);
export const BREAKOUTROOMS_BROADCASE_VOICES_STOP = iText(
  'Stop Broadcast Voice',
  'apac.wc_bo.broadcast_to_all_voice_stop',
);
export const BREAKOUTROOMS_BROADCASE_VOICES_TIP = iText(
  'Broadcast your voice to all rooms',
  'apac.wc_bo.broadcast_to_all_voice_tip',
);
export const BREAKOUTROOMS_BROADCASE_VOICES_HELP_TIP = iText(
  'You can also press and hold "B" to broadcast your voice',
  'apac.wc_bo.broadcast_to_all_voice_help_tip',
);
export const BREAKOUTROOMS_BROADCASE_VOICES_INDICATION_HOST = iText(
  'Currently broadcasting to all rooms',
  'apac.wc_bo.broadcast_to_all_voice_indication_host',
);
export const broadcastVoiceParticipantIndicationText = (hostName) =>
  iText(
    `${hostName} (Host) is broadcasting`,
    'apac.wc_bo.broadcast_to_all_voice_indication_participant',
    [hostName],
  );

export const broadcastVoiceHostToastText = (hotKey) =>
  iText(
    `Hold <a>${hotKey}</a> to broadcast your voice to breakout rooms`,
    'apac.wc_bo.broadcast_to_all_voice_host_toast_typo_fix',
    [hotKey],
  );
export const BREAKOUTROOMS_BROADCASE_VOICES_VIEW_CONTROLLS_TIP = iText(
  "You're broadcasting your voice to all rooms.",
  'apac.wc_bo.broadcast_to_all_voice_view_controlls_tip',
);
export const BREAKOUTROOMS_BROADCASE_VOICES_VIEW_CONTROLLS_BTN = iText(
  'View Controls',
  'apac.wc_bo.broadcast_to_all_voice_view_controlls_btn',
);

export const BREAKOUTROOMS_BROADCASE_VOICES_JOIN_AUDIO_TIPS = iText(
  '<a>Join audio</a> to broadcast your voice to all rooms',
  'apac.wc_bo.broadcast_to_all_voice_join_audio_tips',
);

export const BREAKOUTROOMS_PLEASE_INPUT = iText(
  'Please input',
  'apac.wc_bo.please_input',
);
export const BREAKOUTROOMS_MESSAGE_ALL_ROOMS = iText(
  'Message all rooms...',
  'apac.wc_bo.message_all_rooms',
);
export const BREAKOUTROOMS_BROADCAST = iText(
  'Broadcast',
  'apac.wc_bo.broadcast',
);
export const BREAKOUTROOMS_BROADCAST_TIPS = iText(
  'Broadcast will be sent to everyone.',
  'apac.wc_bo.broadcast_tips',
);
export const BREAKOUTROOMS_UNASSIGNED = iText(
  'Unassigned',
  'apac.wc_bo.unassigned',
);

export const BREAKOUTROOM_LATER = iText('Later', 'apac.wc_bo.later');
export const BREAKOUTROOM_NOT_NOW = iText('Not Now', 'apac.wc_bo.not_now');
export const BREAKOUTROOM_JOIN_ROOM = iText(
  'Join Breakout Room',
  'apac.wc_bo.join_room',
);
export const BREAKOUTROOM_JOIN_A_ROOM = iText(
  'Join a breakout room',
  'apac.wc_bo.join_a_room',
);
export const BREAKOUTROOM_JOIN_ROOM_TIP_CONTENT = iText(
  'You have been assigned to',
  'apac.wc_bo.join_room_tip_content2',
);
export const BREAKOUTROOM_INVITE_JOIN_ROOM_TIP_DEFAULT_HOST = iText(
  'The host',
  'apac.wc_bo.invite_join_room_tip_default_host',
);
export const BREAKOUTROOM_INVITE_JOIN_ROOM_TIP_CONTENT = iText(
  'is inviting you to join',
  'apac.wc_bo.invite_join_room_tip_content2',
);
export const BREAKOUTROOMS_WHERE_JOIN_ROOM_TIP = iText(
  'You can join Breakout Rooms from here.',
  'apac.wc_bo.where_join_room_tip',
);
export const BREAKOUTROOMS_ASK_FOR_HELP = iText(
  'Ask for Help',
  'apac.wc_bo.ask_for_help',
);
export const BREAKOUTROOM_CHOOSE_ROOM_TIP = iText(
  'Choose a different breakout room',
  'apac.wc_bo.choose_room_tip',
);
export const BREAKOUTROOMS_ASK_FOR_HELP_CONTENT = iText(
  'You can invite the host to this Breakout Room for assistance',
  'apac.wc_bo.ask_for_help_content',
);
export const BREAKOUTROOMS_INVITE_HOST = iText(
  'Invite host',
  'apac.wc_bo.invite_host',
);
export const BREAKOUTROOMS_INVITE_HOST_SUCCESS_TIP = iText(
  'The host has been invited.',
  'apac.wc_bo.invite_host_success_tip',
);
export const BREAKOUTROOMS_INVITE_HOST_FAILED_TIP = iText(
  'The host is currently helping others.Please try again later.',
  'apac.wc_bo.invite_host_failed_tip',
);
export const BREAKOUTROOMS_INVITE_HOST_SUCCESS_TIP2 = iText(
  'The host is currently in this room.',
  'apac.wc_bo.invite_host_success_tip2',
);
export const BREAKOUTROOMS_LEAVE_ROOM_TIPS = iText(
  'Do you want to leave this Breakout Room and return to the main session?',
  'apac.wc_bo.leave_room_tips',
);
export const BREAKOUTROOMS_LEAVE_ROOM_TIPS2 = iText(
  'Do you want to leave this Breakout Room? If you leave,you cannot return to the main meeting.',
  'apac.wc_bo.leave_room_tips2',
);
export const BREAKOUTROOM_RETURN_TO_MAIN_SESSION = iText(
  'Return to Main Session',
  'apac.wc_bo.return_to_main_sessoin',
);
export const BREAKOUTROOM_MAIN_SESSION = iText(
  'Main Session',
  'apac.wc_bo.main_sessoin',
);
export const BREAKOUTROOM_JOINING_ROOMS = (roomName) =>
  iText(`Joining ${roomName}...`, 'apac.wc_bo.joining_rooms2', roomName);
export const BREAKOUTROOM_MOVING_INTO_ROOMS = iText(
  'The host has moved you to',
  'apac.wc_bo.moving_into_rooms',
);
export const BREAKOUTROOM_RETURNING_FROM_ROOMS = iText(
  'Returning to Main Session...',
  'apac.wc_bo.returning_to_main_session',
);
export const BREAKOUTROOM_TAKE_FEW_MOMENTS = iText(
  'It may take a few moments.',
  'apac.wc_bo.take_few_moments',
);
export const BREAKOUTROOM_HOST_MOVE_YOU = iText(
  'The host has moved you to',
  'apac.wc_bo.host_move_you',
);

export const BREAKOUTROOM_ALL_ROOM_WILL_CLOSE_TIPS = iText(
  'You will be returned to the main session automatically.',
  'apac.wc_bo.all_room_will_close_tips',
);
export const askHostForHelpTipText = ([attendeeName, roomName]) =>
  iText(
    `${attendeeName} in ${roomName} asked for help.`,
    'apac.wc_bo.ask_host_for_help_tip',
    [attendeeName, roomName],
  );
export const allRoomWillCloseText = (seconds) =>
  iText(
    `All Breakout Rooms will close in ${seconds} seconds.`,
    'apac.wc_bo.all_room_will_close',
    seconds,
  );
export const currentRoomWillCloseText = (seconds) =>
  iText(
    `Breakout Rooms will close in ${seconds} seconds.`,
    'apac.wc_bo.current_room_will_close',
    seconds,
  );

export const BREAKOUTROOMS_CLOSE_BREAKOUT_ROOM = iText(
  'Close breakout room',
  'apac.wc_bo.close_breakout_room',
);
export const BREAKOUTROOMS_CLOSE_NOW = iText(
  'Close now',
  'apac.wc_bo.close_now',
);
export const BREAKOUTROOMS_KEEP_ROOM_OPEN = iText(
  'Keep breakout rooms open',
  'apac.wc_bo.keep_bo_open',
);
export const closeRoomTipWhenTimeUp = (minutes) =>
  iText(
    `Time is up for the ${minutes} minutes
  Breakout Room.Do you want to close all Breakout Rooms now?`,
    'apac.wc_bo.close_room_tip_when_time_up',
    minutes,
  );
export const BREAKOUTROOMS_BE_ASSIGNED_TO_ROOM_TIP = iText(
  'You have been assigned to a Breakout Room',
  'apac.wc_bo.be_assigned_to_room_tip',
);
export const BREAKOUTROOMS_ROOM_HAS_STARTED_NOTIFY = iText(
  'The host has opened Breakout Rooms. Please wait to be assigned.',
  'apac.wc_bo.room_has_started_notify',
);
export const BREAKOUTROOMS_REMAINING = iText(
  'Remaining:',
  'apac.wc_bo.remaining',
);
export const BREAKOUTROOMS_HOST_JOIN_BO_TIP = iText(
  'You are now in a Breakout Room',
  'apac.wc_bo.host_join_bo_tip',
);
export const BREAKOUTROOMS_RETURN_MAIN_SESSION_TIP = iText(
  'You are now in the main session',
  'apac.wc_bo.return_main_session_tip',
);
export const BREAKOUTROOMS_HOST_LEAVE_MEETING_BTN = iText(
  'Keep Meeting Running for Others',
  'apac.wc_bo.host_leave_meeting_in_bo',
);

export const hostInBoWaitingRoomText = (count) =>
  iText(
    `${count} people have joined the waiting room for this meeting. You can return to
main session to admin.`,
    'apac.wc_bo.host_in_bo_waitingroom_tip',
    count,
  );

export const BREAKOUTROOMS_RESET_PREASSIGN = iText(
  'Reset to pre-assigned rooms',
  'apac.wc_bo.reset_preassign',
);
export const BREAKOUTROOMS_RECOVER_PREASSIGN = iText(
  'Recover to pre-assigned rooms',
  'apac.wc_bo.recover_preassign',
);
export const BREAKOUTROOMS_RECOVER_CONFIRM_TIP = iText(
  'Do you want to recover to the pre-assigned rooms? All existing rooms will be replaced.',
  'apac.wc_bo.recover_confirm_tip',
);
export const BREAKOUTROOMS_LOADING = iText(
  'Loading rooms...',
  'apac.wc_bo.loading',
);
export const returnToMainSessionDialogText = (inviter) =>
  iText(
    `${inviter} is inviting you to return to main session.`,
    'apac.wc_bo.return_to_main_session_dialog',
    inviter,
  );

export const broadcastMessageSenderText = iText(
  'to Everyone',
  'apac.wc_bo.broadcast_toeveryone',
);

export const STOPSHARING_BEFORE_JOIN_BO_TEXT = iText(
  'Joining a room will stop your screen sharing',
  'apac.wc_bo.stopsharing_before_joinbo',
);
export const STOPSHARING_BEFORE_JOIN_BO_TEXT2 = iText(
  '. Joining a room will stop your screen sharing.',
  'apac.wc_bo.stopsharing_before_joinbo2',
);
export const STOP_AND_JOIN_BTN_TEXT = iText(
  'Stop & Join',
  'apac.wc_bo.stop_join_btn',
);

export const INCLUDE_COHOST_ASSIGN_TOBO_TEXT = iText(
  'Include co-host(s) to breakout rooms',
  'apac.wc_bo.include_cohosts',
);

export const CLOSE_BO_CONFIRM_TITLE = iText(
  'Close all breakout rooms?',
  'apac.wc_bo.close_confirm_title',
);
export const CLOSE_BO_CONFIRM_CONTENT1 = iText(
  'All participants will immediately be returned to the main session',
  'apac.wc_bo.close_confirm_content1',
);
export const CLOSE_BO_CONFIRM_CONTENT2 = (value) =>
  iText(
    `All participants will be returned to the main session in ${value} seconds`,
    'apac.wc_bo.close_confirm_content2',
    value,
  );

export const BO_COLLAPSE_ALL_TEXT = iText(
  'Collapse All',
  'apac.wc_bo.collapse_all',
);
export const BO_EXPAND_ALL_TEXT = iText('Expand All', 'apac.wc_bo.expand_all');
export const SAVE_BO_BTN_TEXT = iText(
  'Save Breakout Rooms',
  'apac.wc_bo.save_bo',
);
export const SAVE_BO_TIPS = iText(
  'Saving these breakout rooms will also save the existing configuration you created (e.g. the participants in Room 1 will be saved).',
  'apac.wc_bo.save_bo_tips',
);
export const BO_NAME_TEXT = iText(
  'Breakout rooms name',
  'apac.wc_bo.bo_name_text',
);
export const UNABLE_SAVE_BO_TEXT = iText(
  'Unable to save these breakout rooms',
  'apac.wc_bo.unable_save_bo',
);
export const UNABLE_SAVE_BO_ERROR1 = iText(
  'Your account has reached the maximum set of saved breakout rooms.',
  'apac.wc_bo.unable_save_bo_error1',
);
export const UNABLE_SAVE_BO_ERROR2 = iText(
  'The Breakout Rooms Name has already exist.',
  'apac.wc_bo.unable_save_bo_error2',
);
export const UNABLE_SAVE_BO_ERROR_TIP_PLACEHOLDER = iText(
  'Schedule a Meeting',
  'apac.wc_bo.schedule_meeting',
);
export const UNABLE_SAVE_BO_ERROR_TIP = (placeholder) =>
  iText(
    `  To manage existing saved rooms, go to ${placeholder} > Recurring Meeting > Breakout Room pre-assign.`,
    'apac.wc_security.unable_save_bo_error_tip',
    placeholder,
  );
export const UNABLE_SAVE_BO_COMMON_ERROR = (errorCode) =>
  iText(
    `Breakout rooms could not be saved. Please try again later. Error Code: ${errorCode}`,
    'apac.wc_security.unable_save_bo_error_common',
    errorCode,
  );
export const SAVE_BO_SUCCESS_TIP = iText(
  'Breakout rooms saved',
  'apac.wc_bo.save_bo_success',
);

export const BO_ROOM_AGREE_HOST_VIEW_ACTIVITY_STATUS_DIALOG_TITLE = iText(
  'Your activity status is being shared with the host',
  'apac.wc_bo.agree_host_view_activity_status_dialog_title',
);
export const BO_ROOM_AGREE_HOST_VIEW_ACTIVITY_STATUS_DIALOG_CONTENT = iText(
  'The hosts and co-hosts can view the audio, video, and screen sharing status and reactions of participants in the breakout rooms created in this meeting. They will not be able to view participants, view shared screens, or listen to participants unless they join the breakout room.',
  'apac.wc_bo.agree_host_view_activity_status_dialog_content',
);
export const BO_ROOM_AGREE_HOST_VIEW_ACTIVITY_STATUS_DIALOG_OK = iText(
  'Got It',
  'apac.wc_bo.agree_host_view_activity_status_dialog_ok',
);

export const ATTACH_TO_ALL_MEETINGS = iText(
  'Attach these breakout rooms to this Meeting ID for future use',
  'apac.wc_bo.attach_to_all_meetings',
);

export const BO_TAB_NAME_ROOMS = iText('Rooms', 'apac.wc_bo.tab_name_rooms');
export const BO_TAB_NAME_PARTICIPANTS = iText(
  'Participants',
  'apac.wc_bo.tab_name_participants',
);
export const BO_RENAME_TIPS_FOR_PRE_ASSIGN = iText(
  'Pre-assigned participants who join the meeting after you change the name will not be put in a breakout room. You can manually re-assign them.',
  'apac.wc_bo.rename_tips_for_preassign',
);

export const SAVE_BO_DUPLICATE_BO_NAME_ERROR_DIALOG_TEXT = iText(
  'Breakout room names must be unique',
  'apac.wc_bo.save_bo_duplicate_bo_name_dialog_error_text',
);
