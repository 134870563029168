import { createActions } from 'redux-actions';

export const namespace = '@one-chat';

export enum ActionTypes {
  add = 'add',
  revoke = 'revoke',
  update = 'update',
  addComment = 'addComment',
  addComments = 'addComments',
  revokeComment = 'revokeComment',
  emojiUpdate = 'emojiUpdate',
  mentionUpdate = 'mentionUpdate',
  updateComments = 'updateComments',
  updateDownloadFile = 'updateDownloadFile',
  saveSending = 'saveSending',
  removeSending = 'removeSending',
  updateSending = 'updateSending',
  addUnread = 'addUnread',
  deleteUnread = 'deleteUnread',
  markMsgRead = 'markMsgRead',
  resetUnRead = 'resetUnRead',
  saveLatest = 'saveLatest',
  prepend = 'prepend',
  restructure = 'restructure',
}

const actionCreators = createActions(
  ActionTypes.add,
  ActionTypes.revoke,
  ActionTypes.update,
  ActionTypes.addComment,
  ActionTypes.addComments,
  ActionTypes.revokeComment,
  ActionTypes.emojiUpdate,
  ActionTypes.mentionUpdate,
  ActionTypes.updateComments,
  ActionTypes.updateDownloadFile,
  ActionTypes.saveSending,
  ActionTypes.removeSending,
  ActionTypes.updateSending,
  ActionTypes.addUnread,
  ActionTypes.deleteUnread,
  ActionTypes.markMsgRead,
  ActionTypes.resetUnRead,
  ActionTypes.saveLatest,
  ActionTypes.prepend,
  ActionTypes.restructure,
  {
    prefix: namespace,
  },
);

export { actionCreators as ChatSDKActionCreators };
export const {
  add,
  revoke,
  update,
  addComment,
  addComments,
  revokeComment,
  emojiUpdate,
  mentionUpdate,
  updateComments,
  updateDownloadFile,
  removeSending,
  saveSending,
  updateSending,
  addUnread,
  deleteUnread,
  markMsgRead,
  resetUnRead,
  saveLatest,
  prepend,
  restructure,
} = actionCreators;
