import { globalVariable } from '../../global/global-variable';
import {
  isNewEdge,
  isFirefox,
  isChrome,
  isMobileDevice,
  isOpera,
  getChromeVersion,
  isSupportAV,
  isMac,
  isChromeOS,
  isLowEndChromeBook,
  isTeslaMode,
  getSafariVersion,
  isSupportWebGLOffscreenCanvas,
} from '../../global/util';
import {
  VIDEO_TYPE_90P,
  VIDEO_TYPE_180P,
  VIDEO_TYPE_360P,
  VIDEO_TYPE_720P,
  QUALITY_RANGE_MAP,
} from '../video/enum';
import { CAPS_OPTION_SUPPORT_REQUEST_REMOTE_CONTROL } from './constant';

function isChromeSupportSharer() {
  return (
    isChrome() &&
    navigator.mediaDevices &&
    navigator.mediaDevices.getDisplayMedia
  );
}

function isSafariSupportShare() {
  const safari_version = getSafariVersion();
  // getDisplayMedia api is not stable on Safari Ventura, check isSupportWebGLOffscreenCanvas can make sure user upgrade to Sonoma
  return (
    safari_version &&
    parseInt(safari_version, 10) >= 17 &&
    isSupportWebGLOffscreenCanvas
  );
}

export function isChromeNotSupportSharer() {
  return (
    isChrome() &&
    navigator.mediaDevices &&
    !navigator.mediaDevices.getDisplayMedia
  );
}

export function isBrowserSupportSharer() {
  return (
    !isMobileDevice() &&
    isSupportAV() &&
    (isChromeSupportSharer() ||
      isFirefox() ||
      isNewEdge() ||
      isSafariSupportShare() ||
      (navigator.mediaDevices &&
        navigator.mediaDevices.getDisplayMedia &&
        isOpera()))
  );
}

export const getScalableRectDimensionByRatio = (
  containerWidth,
  containerHeight,
  selfWidth,
  selfHeight,
) => {
  const selfRatio = selfHeight / selfWidth;
  const containerRatio = containerHeight / containerWidth;

  if (containerRatio < selfRatio) {
    selfHeight = containerHeight;
    selfWidth = containerHeight / selfRatio;
  } else {
    selfWidth = containerWidth;
    selfHeight = selfWidth * selfRatio;
  }
  return {
    width: selfWidth,
    height: selfHeight,
  };
};

export function resizeObserver(resizeCallback) {
  if (!('ResizeObserver' in window)) {
    return {
      observe: () => {},
      unobserve: () => {},
    };
  } else {
    return new window.ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentRect) {
          resizeCallback(entry.contentRect);
        }
      });
    });
  }
}

export const isSupportShareAudio = () => {
  // only chromium > 74 support this feature.
  return isChromeSupportSharer() && getChromeVersion() >= 74;
};

export const isBrowserSupportReceiveFromMainSession = () => {
  return isSupportAV();
};

export const isPresenterSupportRemoteControl = (caps) =>
  Boolean(caps & CAPS_OPTION_SUPPORT_REQUEST_REMOTE_CONTROL);

export const calculateVideoShareQuality = (rect, receivedVideoQuality) => {
  if (receivedVideoQuality === -1) {
    receivedVideoQuality = VIDEO_TYPE_720P;
  }
  const { height } = rect;
  // 720p use dpiHeight
  let dpiHeight = height;
  if (window.devicePixelRatio) {
    dpiHeight = height * window.devicePixelRatio;
  }
  let quality = VIDEO_TYPE_90P; // dpiHeight < 180
  if (
    //mac dpiHeight>= 700
    isMac() &&
    dpiHeight >= QUALITY_RANGE_MAP.VIDEO_TYPE_720P.MIN_1_MAC
  ) {
    quality = VIDEO_TYPE_720P;
  } else if (
    //chromebook dpiHeight >= 520
    isChromeOS() &&
    !isLowEndChromeBook() &&
    dpiHeight >= QUALITY_RANGE_MAP.VIDEO_TYPE_720P.MIN_1_CHROMEBOOK
  ) {
    quality = VIDEO_TYPE_720P;
  } else if (
    //windows dpiHeight >= 700
    !isChromeOS() &&
    dpiHeight >= QUALITY_RANGE_MAP.VIDEO_TYPE_720P.MIN_1_WINDOWS
  ) {
    quality = VIDEO_TYPE_720P;
  } else if (height >= QUALITY_RANGE_MAP.VIDEO_TYPE_360P.MIN_4) {
    // height >= 270  height< 720
    quality = VIDEO_TYPE_360P;
  } else if (
    // height >= 180
    height >= QUALITY_RANGE_MAP.VIDEO_TYPE_180P.MIN_9
  ) {
    quality = VIDEO_TYPE_180P;
  }

  if (isTeslaMode()) {
    quality = Math.min(receivedVideoQuality, quality, VIDEO_TYPE_180P);
  } else {
    quality = Math.min(receivedVideoQuality, quality);
  }
  return quality;
};

export const getShareStreamInfo = () => {
  const streamInfo =
    globalVariable.avSocket?.socketInstance?.getShareStreamInfo();
  if (streamInfo) {
    const { width, height, deviceId } = streamInfo;
    return {
      width,
      height,
      DeviceID: deviceId || '',
    };
  }
  return {};
};

export const isSharingScreen = () => {
  const streamInfo =
    globalVariable.avSocket?.socketInstance?.getShareStreamInfo();
  if (!streamInfo) return false;
  const { displaySurface } = streamInfo;
  if (displaySurface) {
    return displaySurface === 'monitor';
  }
  return true;
};
