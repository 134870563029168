import { EventQueueProvider } from './event-queue-provider';
import { useEffect } from 'react';

const useEventQueueProvider = (socketType, type, cb) => {
  useEffect(() => {
    EventQueueProvider.getSubscription(socketType).watch(type, cb);
    return () => {
      EventQueueProvider.getSubscription(socketType).unwatch(type, cb);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
};

export { useEventQueueProvider };
