import { setNewLTState } from '../redux/new-LT-action';
export const showLiveTranscriptionToastThunk = (status) => (dispatch) => {
  if (status) {
    dispatch(
      setNewLTState({
        isLTMessageReceived: true,
      }),
    );
  }
};
