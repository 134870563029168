import { createActions } from 'redux-actions';

const SET_ATTENTION_MODE_DIALOG_VISIBLE = 'SET_ATTENTION_MODE_DIALOG_VISIBLE';
const SET_ATTENTION_MODE_STATUS = 'SET_ATTENTION_MODE_STATUS';
const SET_IS_FIRST_TIME_USE = 'SET_IS_FIRST_TIME_USE';
const SET_ATTENTION_MODE_TOAST_FOR_OTHERS =
  'SET_ATTENTION_MODE_TOAST_FOR_OTHERS';
const SET_ATTENTION_MODE_USER_LIST = 'SET_ATTENTION_MODE_USER_LIST';
const SET_SHARE_ATTENTION_MODE_STATUS = 'SET_SHARE_ATTENTION_MODE_STATUS';
const SET_SHARE_ATTENTION_MODE_WHITE_LIST =
  'SET_SHARE_ATTENTION_MODE_WHITE_LIST';
const RESET_ATTENTION_MODE = 'RESET_ATTENTION_MODE';
const SET_IS_MMR_SUPPORT_FM = 'SET_IS_MMR_SUPPORT_FM';
const SET_IS_FOCUS_MODE_ENDING = 'SET_IS_FOCUS_MODE_ENDING';
export const {
  setAttentionModeDialogVisible,
  setAttentionModeStatus,
  setIsFirstTimeUse,
  setAttentionModeToastForOthers,
  setAttentionModeUserList,
  setShareAttentionModeStatus,
  setShareAttentionModeWhiteList,
  resetAttentionMode,
  setIsMmrSupportFm,
  setIsFocusModeEnding,
} = createActions(
  SET_ATTENTION_MODE_DIALOG_VISIBLE,
  SET_ATTENTION_MODE_STATUS,
  SET_IS_FIRST_TIME_USE,
  SET_ATTENTION_MODE_TOAST_FOR_OTHERS,
  SET_ATTENTION_MODE_USER_LIST,
  SET_SHARE_ATTENTION_MODE_STATUS,
  SET_SHARE_ATTENTION_MODE_WHITE_LIST,
  RESET_ATTENTION_MODE,
  SET_IS_MMR_SUPPORT_FM,
  SET_IS_FOCUS_MODE_ENDING,
);
