import { Toast } from '@zoom/zoom-react-ui';
import React from 'react';
import { promptA11yInfo } from '../../../util';

const info = ({ aliveTime, text, showIcon }) =>
  function info({ close }) {
    const showClose = aliveTime === 0;
    return (
      <div className="wc-toast wc-toast--info">
        {showIcon && (
          <i className="wc-toast__left-icon wc-toast__left-icon--info" />
        )}
        <span className="wc-toast__text">{text}</span>
        {showClose && (
          <i
            onKeyDown={() => {}}
            tabIndex={0}
            role="button"
            className="wc-toast__close-icon--info"
            onClick={close}
          />
        )}
      </div>
    );
  };

const success = ({ text }) =>
  function success() {
    return (
      <div className="wc-toast wc-toast--success">
        <span className="wc-toast__text">{text}</span>
      </div>
    );
  };

const error = ({ text }) =>
  function error() {
    return (
      <div className="wc-toast wc-toast--error">
        <span className="wc-toast__text">{text}</span>
      </div>
    );
  };

const warning = ({ text }) =>
  function warning() {
    return (
      <div className="wc-toast wc-toast--warning">
        <span className="wc-toast__text">{text}</span>
      </div>
    );
  };

const notify = ({ text }) =>
  function notify() {
    return (
      <div className="wc-toast wc-toast--notify">
        <span className="wc-toast__text">{text}</span>
      </div>
    );
  };

const content = {
  info,
  success,
  error,
  warning,
  notify,
  notify2: notify,
};

export const wcToast = ({
  aliveTime = 5,
  text,
  type = 'success',
  ariaText,
  placement,
  showIcon = true,
}) => {
  const toastContent = content[type] || content.success;
  const className =
    type === 'notify' && !placement ? 'wc-toast-container--center' : null;
  Toast.toast({
    notificationContent: toastContent({ aliveTime, text, showIcon }),
    aliveTime,
    className,
  });
  if (ariaText) {
    promptA11yInfo(ariaText);
  } else {
    promptA11yInfo(text);
  }
};

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/components/widget/toast/wc-toast.scss';
