export const LIVE_STREAM_BROADCAST_TOKEN_VALIDATION_MODE = {
  LEGACY: 0,
  UTK: 1,
};

export const LIVE_STREAM_STATUS = {
  OFF: 0,
  ON: 1,
  PREPARING: 2,
};

export const LIVE_STREAM_ACTION = {
  STOP_LIVE_STREAM: 0,
  START_LIVE_STREAM: 1,
  PAUSE_LIVE_STREAM: 2,
  UNKNOWN_LIVE_STREAM_ACTION: 3,
};
