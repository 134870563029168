import { createActions } from 'redux-actions';

export const {
  updateMeetingChat,
  updateChatReceiver,
  minimizeChat,
  chatReset,
  updateWebinarAttendeeReceiver,
  // setIsSenderInsufficientPrivileges,
  // setIsImproperReceiver,
  // setIsReceiverAbsent,
  setSelfChatId,
  changeReadChatState,
  cleanUnreadChat,
  deleteChat,
  setChatPinBottom,
  setWaitingRoomChatFlag,
  setChatDraft,
  // setFileBlocked,
  // setFileExceedsLimit,
  setUploadCancelTokens,
  removeUploadCancelToken,
  updateChatReceiverUniqueId,
  updateShowChatPreview,
  setShowChatSettingDropdown,
  // setIsReceiverRestrict,
  set3rdPartyFileWindowVisible,
  set3rdPartyFileService,
  set3rdPartyFileCnonce,
  setChatPopoverStatus,
  setFileDownloadPromptIgnoreList,
} = createActions(
  'UPDATE_MEETING_CHAT',
  'UPDATE_CHAT_RECEIVER',
  'MINIMIZE_CHAT',
  'CHAT_RESET',
  'UPDATE_WEBINAR_ATTENDEE_RECEIVER',
  'SET_IS_RECEIVER_ABSENT',
  'SET_IS_SENDER_INSUFFICIENT_PRIVILEGES',
  'SET_IS_IMPROPER_RECEIVER',
  'SET_SELF_CHAT_ID',
  'CHANGE_READ_CHAT_STATE',
  'CLEAN_UNREAD_CHAT',
  'DELETE_CHAT',
  'SET_CHAT_PIN_BOTTOM',
  'SET_WAITING_ROOM_CHAT_FLAG',
  'SET_CHAT_DRAFT',
  'SET_FILE_BLOCKED',
  'SET_FILE_EXCEEDS_LIMIT',
  'SET_UPLOAD_CANCEL_TOKENS',
  'REMOVE_UPLOAD_CANCEL_TOKEN',
  'UPDATE_CHAT_RECEIVER_UNIQUE_ID',
  'UPDATE_SHOW_CHAT_PREVIEW',
  'SET_SHOW_CHAT_SETTING_DROPDOWN',
  'SET_IS_RECEIVER_RESTRICT',
  'SET_3RD_PARTY_FILE_WINDOW_VISIBLE',
  'SET_3RD_PARTY_FILE_SERVICE',
  'SET_3RD_PARTY_FILE_CNONCE',
  'SET_CHAT_POPOVER_STATUS',
  'SET_FILE_DOWNLOAD_PROMPT_IGNORE_LIST',
);
