import {
  CONTROL_MODE_CONNECTORS_ENUM,
  CONTROL_MODE_ZOOM_MSG_TYPE,
  CONTROL_MODE_MEETING_STATUS,
  CONTROL_MODE_COMMON_ERROR_TYPE,
} from '../../enum';
import { MSFT_ERROR_CODE } from './enum';
import { mediaSDKMsgHandler } from './media-sdk-msg-handler';
import { rwgMsgHandler } from './rwg-msg-handler';
import { xmppMsgHandler } from './xmpp-msg-handler';
import { localEvtHandler } from './local-evt-handler';
import { externalEvtHandler } from './external-evt-handler';
import { localReadyStatesObserver } from './local-ready-states-observer';
import { isMTRAndroid } from '../../../global';
import { generateUUID } from '../../../global/service/generate-uuid';
import { makeLogger, reportToGlobalTracing } from '../../../global/logger';
import { escapeSensitiveContent } from '../../../global/logger/escapeSensitiveContent';

const delayCloseMsftApplicationTime = 5000;

const ConnectionUuid = generateUUID();
let haveSendUuid = false;

const msftAdaptor = ({ store }, { meetingHost: controller }) => {
  return {
    shouldLoad() {
      /**
       *  load specifc external connector on demand with feature detection
       */

      if (!controller) return false;
      const vendorId = controller.getHostState().vendorId;
      if (vendorId !== CONTROL_MODE_CONNECTORS_ENUM.MSFT) {
        return false;
      }

      return true;
    },
    init() {
      /* eslint-disable-next-line no-console */
      console.info(`${this.getVendorId()} adaptor initiated`);
      this.logInitialMeetingState(store);

      externalEvtHandler(store, controller, this);
      this.notifyControllerUuid();
      localReadyStatesObserver.onReady(
        this.notifyControllerJoinSuccess.bind(this),
      );
    },
    logInitialMeetingState(store) {
      const { getState } = store;
      const {
        meeting: { currentUser, conID, confId, svcUrl, res },
      } = getState();
      let hostState = {};
      if (typeof controller.getHostState !== 'undefined') {
        hostState = controller.getHostState();
      }

      const config = {
        userId: currentUser.userId,
        filter: () => {},
        confId,
        conID,
        svcUrl,
        res,
      };
      const logger = makeLogger(['MTR meetingHost State']);

      logger
        .log(`${escapeSensitiveContent(JSON.stringify(hostState))}`, [
          'INITIAL STATE',
        ])
        .then(() => {
          reportToGlobalTracing(config);
        });

      logger
        .log(`Correlation ID: ${hostState?.correlationId}`, ['CORRELATION ID'])
        .then(() => {
          reportToGlobalTracing(config);
        });
    },
    handleZoomMsg(msgType, evt, payload) {
      switch (msgType) {
        case CONTROL_MODE_ZOOM_MSG_TYPE.UI: {
          localEvtHandler(evt, payload, store, this);
          break;
        }
        case CONTROL_MODE_ZOOM_MSG_TYPE.MEDIA_SDK: {
          mediaSDKMsgHandler(evt, payload, store, this);
          break;
        }
        case CONTROL_MODE_ZOOM_MSG_TYPE.RWG: {
          rwgMsgHandler(evt, payload, store, this);
          break;
        }
        case CONTROL_MODE_ZOOM_MSG_TYPE.XMPP: {
          xmppMsgHandler(evt, payload, store, this);
          break;
        }
        default: {
          return false;
        }
      }
    },
    getVendorId() {
      return controller.getHostState().vendorId;
    },
    getCameraId() {
      return controller.getHostState().videoDeviceId;
    },
    getSpeakerId() {
      return controller.getHostState().outputAudioDeviceId;
    },
    getMicId() {
      return controller.getHostState().inputAudioDeviceId;
    },
    notifyControllerAudioUnmuted() {
      this.notifyController({ audioMuted: false });
    },
    notifyControllerAudioMuted() {
      this.notifyController({ audioMuted: true });
    },
    notifyControllerVideoUnmuted() {
      this.notifyController({ videoMuted: false });
    },
    notifyControllerVideoMuted() {
      this.notifyController({ videoMuted: true });
    },
    notifyControllerMeetingHoldOn() {
      this.notifyController({
        state: CONTROL_MODE_MEETING_STATUS.LOBBY,
      });
    },
    notifyControllerMeetingEnd(error, delayQuit = false) {
      if (error) {
        this.notifyControllerWarningInfo(error);
      }
      if (delayQuit) {
        setTimeout(() => {
          this.notifyController({
            state: CONTROL_MODE_MEETING_STATUS.DISCONNECTED,
          });
        }, delayCloseMsftApplicationTime);
      } else {
        this.notifyController({
          state: CONTROL_MODE_MEETING_STATUS.DISCONNECTED,
        });
      }
    },
    notifyControllerStartJoining() {
      this.notifyController({
        state: CONTROL_MODE_MEETING_STATUS.CONNECTING,
      });
    },
    notifyControllerJoinSuccess() {
      this.notifyController({
        state: CONTROL_MODE_MEETING_STATUS.CONNECTED,
      });
    },
    notifyControllerJoinFailure(error) {
      if (error) {
        this.notifyControllerWarningInfo(error);
      }
      setTimeout(() => {
        this.notifyController({
          state: CONTROL_MODE_MEETING_STATUS.DISCONNECTED,
        });
      }, delayCloseMsftApplicationTime);
    },
    notifyControllerActionFailure(errorInfo) {
      /* eslint-disable-next-line no-console */
      console.trace('action failed');

      switch (errorInfo.type) {
        case CONTROL_MODE_COMMON_ERROR_TYPE.MUTE_AUDIO: {
          if (!isMTRAndroid()) {
            this.notifyControllerAudioUnmuted();
          }
          this.notifyController({
            error: MSFT_ERROR_CODE.AUDIO_MUTE_ERROR,
          });
          break;
        }
        case CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO: {
          this.notifyControllerAudioMuted();
          this.notifyController({
            error: MSFT_ERROR_CODE.AUDIO_UNMUTE_ERROR,
          });
          break;
        }
        case CONTROL_MODE_COMMON_ERROR_TYPE.STOP_VIDEO: {
          if (!isMTRAndroid()) {
            this.notifyControllerVideoUnmuted();
          }
          this.notifyController({
            error: MSFT_ERROR_CODE.VIDEO_STOP_ERROR,
          });
          break;
        }
        case CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO: {
          this.notifyControllerVideoMuted();
          this.notifyController({
            error: MSFT_ERROR_CODE.VIDEO_START_ERROR,
          });
          break;
        }
        case CONTROL_MODE_COMMON_ERROR_TYPE.WEBGL_CONTEXT_LOST: {
          this.notifyController({
            error: MSFT_ERROR_CODE.FAILED_ACTION,
          });
          break;
        }
        default:
          break;
      }
    },
    // only give controller a warning, don't close application
    notifyControllerWarningInfo(warning) {
      const { getState } = store;
      const {
        meeting: { currentUser, meetingNumber },
      } = getState();
      const { userId, displayName } = currentUser;
      this.notifyController({
        warning,
        meetingNumber,
        userId,
        displayName,
      });
    },
    notifyControllerUuid() {
      if (!haveSendUuid && isMTRAndroid()) {
        haveSendUuid = true;
        this.notifyController({
          state: 'connectionUuid',
        });
      }
    },
    notifyController(payload) {
      if (payload && isMTRAndroid()) {
        payload.uuid = ConnectionUuid;
      }
      /* eslint-disable-next-line no-console */
      console.trace('Notify controller msg:', payload);
      controller.updateMeetingState(payload);
    },
    dispose() {},
  };
};

export default msftAdaptor;
