import { globalVariable } from '../../../../global/global-variable';

import {
  setActiveVideo,
  setActiveVideoTalkingId,
  setWaitingForVideoFrameActiveUser,
} from '../video-action';
import {
  VIDEO_LAYOUT_SPEAK_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW,
  VIDEO_ACTIVE_SSRC_TYPE,
  VIDEO_TYPE_360P,
} from '../../enum';

import { videoBasicAllAttendeeListSelector } from '../selectors/video-list-selector';
import {
  NEW_ACTIVE_SPEAKER_SSRC,
  CANCEL_NEW_ACTIVE_SPEAKER_BEFORE_CALL_BACK,
} from '../../../../constants/AVNotifyMediaSDKTypes';
import {
  subscribeVideo,
  unsubscribeVideo,
} from '../thunks/video-render-flow-thunk';

export function receivetActiveVideoIdThunk({ body }) {
  return (dispatch) => {
    /* eslint-disable-next-line no-prototype-builtins */
    if (body && body.hasOwnProperty('bVideoOn') && body.hasOwnProperty('id')) {
      dispatch(setActiveVideo({ id: body.id, bVideoOn: body.bVideoOn }));
    }
  };
}

export function receivetActiveVideoTalkingIdThunk({ body }) {
  return (dispatch) => {
    if (body.id) {
      dispatch(setActiveVideoTalkingId(body.id));
    }
  };
}

export function preLoadActiveVideoFrameThunk(data) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: {
        UI: { videoLayout },
        isClientEnableSeeSelfActiveWhenSpeaking,
        pinVideoList,
        spotlightVideoList,
        currentRenderVideo,
        waitingForVideoFrameActiveUser,
      },
      meeting: {
        currentUser: { userId },
      },
      meetingUI: { isMinimizeMode },
      socketStatus: { initVideoDecodeStatus },
    } = state;

    const { id, bVideoOn, type } = data;

    // decode not success, do not preload active video frame / isMinimizeMode
    if (
      !globalVariable.avSocket ||
      initVideoDecodeStatus !== 'success' ||
      isMinimizeMode
    ) {
      if (type === VIDEO_ACTIVE_SSRC_TYPE.ACTIVE_VIDEO_ID) {
        dispatch(setActiveVideo({ id, bVideoOn }));
        return;
      }

      if (type === VIDEO_ACTIVE_SSRC_TYPE.ACTIVE_VIDEO_TALKING_ID) {
        dispatch(setActiveVideoTalkingId(id));
        return;
      }
    }
    // do not preload video frame case
    // isClientEnableSeeSelfActiveWhenSpeaking => setActiveVideo
    // !isClientEnableSeeSelfActiveWhenSpeaking => setActiveVideoTalkingId
    if (
      type === VIDEO_ACTIVE_SSRC_TYPE.ACTIVE_VIDEO_ID &&
      isClientEnableSeeSelfActiveWhenSpeaking
    ) {
      dispatch(setActiveVideo({ id, bVideoOn }));
      return;
    }

    if (
      type === VIDEO_ACTIVE_SSRC_TYPE.ACTIVE_VIDEO_TALKING_ID &&
      !isClientEnableSeeSelfActiveWhenSpeaking
    ) {
      dispatch(setActiveVideoTalkingId(id));
      return;
    }

    // it has already existed pending preload active video frame, cancel pending
    if (waitingForVideoFrameActiveUser) {
      const isCurrentRenderWaitingUser = currentRenderVideo.some(
        (item) =>
          item.user.userId >> 10 === waitingForVideoFrameActiveUser.id >> 10,
      );
      // pending active video frame is not using, unsubscribe and notify sdk haddata false
      if (!isCurrentRenderWaitingUser) {
        if (globalVariable.avSocket?.sendSocket) {
          globalVariable.avSocket.sendSocket(
            CANCEL_NEW_ACTIVE_SPEAKER_BEFORE_CALL_BACK,
            {
              ssrc: waitingForVideoFrameActiveUser.id,
              haddata: false,
            },
          );
        }

        dispatch(
          unsubscribeVideo([
            { user: { userId: waitingForVideoFrameActiveUser.id } },
          ]),
        );
        dispatch(setWaitingForVideoFrameActiveUser(null));
      } else {
        // pending active video frame is using, just notify sdk haddata true
        globalVariable.avSocket.sendSocket(
          CANCEL_NEW_ACTIVE_SPEAKER_BEFORE_CALL_BACK,
          {
            ssrc: waitingForVideoFrameActiveUser.id,
            haddata: true,
          },
        );
        dispatch(setWaitingForVideoFrameActiveUser(null));
      }
    }

    // handle current active video
    // do not preload video frame case  current rendering/pin/spotlight/self user/not speaker view/video off/ minimize
    const videoBasicAllAttendeeList = videoBasicAllAttendeeListSelector(state);
    const isActiveUserVideoOff = videoBasicAllAttendeeList.some(
      (item) => item.userId >> 10 === id >> 10 && !item.displayVideoOn,
    );
    const isCurrentRenderUser = currentRenderVideo.some(
      (item) => item.user.userId >> 10 === id >> 10,
    );

    if (
      isActiveUserVideoOff ||
      isCurrentRenderUser ||
      pinVideoList.length > 0 ||
      spotlightVideoList.length > 0 ||
      id >> 10 === userId >> 10 ||
      (videoLayout !== VIDEO_LAYOUT_SPEAK_VIEW &&
        videoLayout !== VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW)
    ) {
      if (type === VIDEO_ACTIVE_SSRC_TYPE.ACTIVE_VIDEO_ID) {
        dispatch(setActiveVideo({ id, bVideoOn }));
        return;
      }

      if (type === VIDEO_ACTIVE_SSRC_TYPE.ACTIVE_VIDEO_TALKING_ID) {
        dispatch(setActiveVideoTalkingId(id));
        return;
      }
    }

    // start preload video
    dispatch(setWaitingForVideoFrameActiveUser({ id, bVideoOn }));
    //subscribe
    dispatch(
      subscribeVideo([{ user: { userId: id }, quality: VIDEO_TYPE_360P }]),
    );
    //notify sdk
    notifyMediaSDKActiveUser(id);
  };
}

export function preLoadActiveVideoFrameCallBackThunk(ssrc) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: {
        isClientEnableSeeSelfActiveWhenSpeaking,
        waitingForVideoFrameActiveUser,
        currentRenderVideo,
      },
    } = state;
    if (!waitingForVideoFrameActiveUser) {
      return;
    }

    // ative frame video is outdate and outdate active frame video is not using, unsubscribe video
    if (ssrc >> 10 !== waitingForVideoFrameActiveUser.id >> 10) {
      const isCurrentVideoOnRenderUser = currentRenderVideo.some(
        (item) =>
          item.user.userId >> 10 === waitingForVideoFrameActiveUser.id >> 10 &&
          item.user.displayVideoOn,
      );
      if (!isCurrentVideoOnRenderUser) {
        dispatch(
          unsubscribeVideo([
            { user: { userId: waitingForVideoFrameActiveUser.id } },
          ]),
        );
      }
      return;
    }

    if (isClientEnableSeeSelfActiveWhenSpeaking) {
      dispatch(setActiveVideoTalkingId(waitingForVideoFrameActiveUser.id));
    } else {
      dispatch(
        setActiveVideo({
          id: waitingForVideoFrameActiveUser.id,
          bVideoOn: waitingForVideoFrameActiveUser.bVideoOn,
        }),
      );
    }
    dispatch(setWaitingForVideoFrameActiveUser(null));
  };
}

export function handlePendingActiveFrameVideoThunk(list, type) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { waitingForVideoFrameActiveUser },
    } = state;
    if (!waitingForVideoFrameActiveUser) {
      return;
    }

    let isNeedToCancelWaitingVideoFrame = false;

    if (type === 'update') {
      const updateRoster = list[0] || {};
      if (
        updateRoster.id >> 10 === waitingForVideoFrameActiveUser.id >> 10 &&
        // eslint-disable-next-line no-prototype-builtins
        updateRoster.hasOwnProperty('bVideoOn') &&
        !updateRoster.bVideoOn
      ) {
        isNeedToCancelWaitingVideoFrame = true;
      }
    }

    if (type === 'remove') {
      isNeedToCancelWaitingVideoFrame = !list.some(
        ({ id }) => id >> 10 === waitingForVideoFrameActiveUser.id >> 10,
      );
    }
    if (isNeedToCancelWaitingVideoFrame) {
      //notify sdk
      if (globalVariable.avSocket?.sendSocket) {
        globalVariable.avSocket.sendSocket(
          CANCEL_NEW_ACTIVE_SPEAKER_BEFORE_CALL_BACK,
          {
            ssrc: waitingForVideoFrameActiveUser.id,
            haddata: false,
          },
        );
      }
      dispatch(
        unsubscribeVideo([
          { user: { userId: waitingForVideoFrameActiveUser.id } },
        ]),
      );
      dispatch(setWaitingForVideoFrameActiveUser(null));
    }
  };
}

export function notifyMediaSDKActiveUser(id) {
  //notify sdk
  if (globalVariable.avSocket?.sendSocket) {
    globalVariable.avSocket.sendSocket(NEW_ACTIVE_SPEAKER_SSRC, {
      ssrc: id,
    });
  }
}
