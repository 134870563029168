import { createActions } from 'redux-actions';

const TOGGLE_TEST_MODAL = 'TOGGLE_TEST_MODAL';
const SET_TEST_STEP = 'SET_TEST_STEP';
const SET_TESTING_DEVICE = 'SET_TESTING_DEVICE';
const SET_TESTING_STATUS = 'SET_TESTING_STATUS';

export const {
  toggleTestModal,
  setTestStep,
  setTestingDevice,
  setTestingStatus,
} = createActions({
  [TOGGLE_TEST_MODAL]: (payload) => payload,
  [SET_TEST_STEP]: (payload) => payload,
  [SET_TESTING_DEVICE]: (payload) => payload,
  [SET_TESTING_STATUS]: (payload) => payload,
});
