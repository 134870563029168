/*
activeSpeakerIdSelector(active speaker id)
videoBasicAllAttendeeListSizeSelector(all in meeting user size)
spotlightVideoListSelector(spotlighted user without waiting room user)

inMeetingParticipantsSelector ->
(all in meeting user without waiting room user from RWG)

    videoBasicAllAttendeeListSelector ->
    (merge xmpp user, add some properties)

        allViewParticipantsSelector ->
        (add some filter)

            -> activeVideoParticipantsSelector
            (active area list)
                                              -> galleryViewListSelector
                                                (gallery area list)

            -> videoWithCommonFilterListSelector
             (filter Pin Spotlight)
                                              -> suspensionViewListSelector
                                                (supension window area list)
                                              -> speakerViewBarListSelector
                                                (speaker bar area list)

*/
import { createSelector } from 'reselect';
import {
  coOrHostSelector,
  inMeetingParticipantsSelector,
  isInSharingModeSelector,
  raiseHandParticipantSelector,
  xmppAllowTalkRaiseHandSyncListSelector,
  isReceiveSharingSelector,
} from '../../../../global/redux/selector';
import { isZRMultiStreamVideoParentUser } from '../../../../global/service';
import { isSupportMultiView, isAndroidDevice } from '../../../../global/util';
import { MINIMIZED_VIDEO, ACTIVE_VIDEO } from '../../enum';
import { isMeInRoomSelector } from '../../../breakout-room/redux/bo-room-selector';
import { isUserSignLanguageInterpreter } from '../../../interpretation/util';
import { spotlightVideoListSelector } from './video-common-selector';
import {
  speakerSpecialOrderUserSizeSelector,
  isNeedSpeakerSpecialOrderSelector,
  specialOrderUserSizeSelector,
  isNeedSpecialOrderSelector,
} from './video-order-selector';
import { whiteboardStatusSelector } from '../../../whiteboard/redux/whiteboard-selector';
import { WHITEBOARD_STATUS } from '../../../whiteboard/enum';
import { isWebinar } from '../../../../global/service/meeting-types';
import {
  isHost,
  isPanelist,
  isViewOnly,
} from '../../../../global/service/user-types';

const pinVideoListSelector = ({ video: { pinVideoList } }) => pinVideoList;

const hasChangedVideoOrderSelector = ({ video: { hasChangedVideoOrder } }) =>
  hasChangedVideoOrder;

const showNoVideoParticipantsSelector = ({
  video: {
    UI: { showNoVideoParticipants },
  },
}) => showNoVideoParticipants;

const showSelfViewSelector = ({
  video: {
    UI: { showSelfView },
  },
}) => showSelfView;

const currentUserIdSelector = ({
  meeting: {
    currentUser: { userId },
  },
}) => userId;

const currentUserSelector = ({ meeting: { currentUser } }) => currentUser;

export const currentUserRoleSelector = ({
  meeting: {
    currentUser: { userRole },
  },
}) => userRole;

const isSwapVideoSharedSelector = ({
  video: {
    UI: { isSwapVideoShared },
  },
}) => isSwapVideoShared;

const activeNodeIdSelector = ({ video: { activeNodeId } }) => activeNodeId;

const activeVideoTalkingIdSelector = ({ video: { activeVideoTalkingId } }) => {
  return activeVideoTalkingId;
};
const isClientEnableSeeSelfActiveWhenSpeakingSelector = ({
  video: { isClientEnableSeeSelfActiveWhenSpeaking },
}) => {
  if (isAndroidDevice()) {
    return false;
  }
  return isClientEnableSeeSelfActiveWhenSpeaking;
};

const selfVideoShowTypeSelector = ({
  video: {
    UI: { selfVideoShowType },
  },
}) => selfVideoShowType;

const isClientDisableReceiveVideoSelector = ({
  video: { isClientDisableReceiveVideo },
}) => isClientDisableReceiveVideo;

const attentionModeAllowListSelector = (state) =>
  state?.attentionMode?.allowUserList ?? [];

const signLanguageInterpretationLanguageSelector = ({
  interpretation: { signLanguages },
}) => signLanguages;

const signLanguageInterpreterAllowedToTalkSelector = ({
  interpretation: { allowedToTalk },
}) => allowedToTalk;

const isClientPageVisibleSelector = (state) => state.video.isClientPageVisible;

const isShowZrMainUserSelector = (state) => state.video.isShowZrMainUser;

// active speaker may be myself according to setting.
// activeNodeId does not include self id
// activeVideoTalkingId includes self id
export const activeSpeakerIdSelector = createSelector(
  [
    isClientEnableSeeSelfActiveWhenSpeakingSelector,
    activeNodeIdSelector,
    activeVideoTalkingIdSelector,
  ],
  (
    isClientEnableSeeSelfActiveWhenSpeaking,
    activeNodeId,
    activeVideoTalkingId,
  ) => {
    return isClientEnableSeeSelfActiveWhenSpeaking
      ? activeVideoTalkingId || activeNodeId
      : activeNodeId;
  },
);

// get attentionMode user list from RWG
const attentionModeListByRoleAndStatusSelector = createSelector(
  [attentionModeAllowListSelector, coOrHostSelector, isMeInRoomSelector],
  (allowList, coOrHost, inMeInRoom) => {
    if (!allowList.length || coOrHost || inMeInRoom) {
      return [];
    }
    return allowList.map((i) => i >> 10);
  },
);

// 5.12.0 inMeetingVideosSelector -> rename videoBasicAllAttendeeListSelector
// merge xmpp user and all meeting participants
// generate displayVideoOn property
export const videoBasicAllAttendeeListSelector = createSelector(
  [
    inMeetingParticipantsSelector,
    xmppAllowTalkRaiseHandSyncListSelector,
    isClientDisableReceiveVideoSelector,
    currentUserIdSelector,
    attentionModeListByRoleAndStatusSelector,
    isClientPageVisibleSelector,
  ],
  (
    inMeetingParticipants,
    xmppAllowTalkWithRaiseHandSyncList,
    isClientDisableReceiveVideo,
    currentUserId,
    focusModeAllowVideoList,
    isClientPageVisible,
  ) => {
    const isAttentionModeWork = Boolean(focusModeAllowVideoList.length);

    const list = [
      ...inMeetingParticipants,
      ...xmppAllowTalkWithRaiseHandSyncList,
    ].map((item) => {
      let displayVideoOn = item.bVideoOn;
      // let firstName = getFirstWord(item.displayName);
      // let lastName = getLastWord(item.displayName);

      if (isAttentionModeWork && currentUserId !== item.userId) {
        displayVideoOn =
          !!focusModeAllowVideoList.includes(item.userId >> 10) &&
          item.bVideoOn;
      }

      if (isClientDisableReceiveVideo) {
        displayVideoOn = currentUserId === item.userId && item.bVideoOn;
      }
      if (!isClientPageVisible) {
        displayVideoOn = false;
      }

      return { ...item, displayVideoOn /* firstName, lastName*/ };
    });

    return list;
  },
);

// 5.12.0 videoBasicAllAttendeeListSizeSelector -> rename videoBasicAllAttendeeListSizeSelector
export const videoBasicAllAttendeeListSizeSelector = createSelector(
  [videoBasicAllAttendeeListSelector],
  (videoBasicAllAttendeeList) => videoBasicAllAttendeeList.length,
);

// use for list order
// spotlight/pin/raise hand user in front of others
export const highPriorityVideoListSelector = createSelector(
  [
    videoBasicAllAttendeeListSelector,
    pinVideoListSelector,
    raiseHandParticipantSelector,
    spotlightVideoListSelector,
  ],
  (
    videoBasicAllAttendeeList,
    pinVideoList,
    raiseHandParticipant,
    spotlightVideoList,
  ) => {
    let result = [];
    if (raiseHandParticipant.length) {
      const raiseHandUserIds = raiseHandParticipant.map((item) => item.userId);
      const raiseHandUsers = videoBasicAllAttendeeList.filter((item) =>
        raiseHandUserIds.includes(item.userId),
      );
      result = raiseHandUsers;
    }
    pinVideoList.forEach((item) => {
      const pinUser = videoBasicAllAttendeeList.find(
        (participant) => participant.userId === item,
      );
      if (pinUser && !result.some((item) => item.userId === pinUser.userId)) {
        result.push(pinUser);
      }
    });

    spotlightVideoList.forEach((item) => {
      const spotlightUser = videoBasicAllAttendeeList.find(
        (participant) => participant.userId === item,
      );
      if (
        spotlightUser &&
        !result.some((item) => item.userId === spotlightUser.userId)
      ) {
        result.push(spotlightUser);
      }
    });
    return result;
  },
);

function isSignLanguageInterpreterAllowedToTalk(
  participant,
  interpretersAllowedToTalkMap,
) {
  return (
    interpretersAllowedToTalkMap[participant.userId] === true ||
    isHost(participant.userRole)
  );
}

function shouldSignLanguageInterpreterBeRemovedFromMainChannelVideo(
  participant,
  signLanguages,
  interpretersAllowedToTalkMap,
) {
  return (
    isUserSignLanguageInterpreter(participant, signLanguages) &&
    !isSignLanguageInterpreterAllowedToTalk(
      participant,
      interpretersAllowedToTalkMap,
    )
  );
}

// 5.12.0 allVideoParticipantsSelector -> rename allViewParticipantsSelector
// interpreters filter
// pin spotlight raise hand video order
// webinar attendee only show video-on user
// when user operate hide self view form avatar menu
// when user operate hide no video participants view form avatar menu
export const allViewParticipantsSelector = createSelector(
  [
    videoBasicAllAttendeeListSelector,
    pinVideoListSelector,
    spotlightVideoListSelector,
    highPriorityVideoListSelector,
    signLanguageInterpretationLanguageSelector,
    signLanguageInterpreterAllowedToTalkSelector,
    isShowZrMainUserSelector,
    currentUserSelector,
  ],
  (
    videoBasicAllAttendeeList,
    pinVideoList,
    spotlightVideoList,
    highPriorityVideoList,
    signLanguages,
    signLanguageInterpreterAllowToTalkMap,
    isShowZrMainUser,
  ) => {
    // interpreters filter
    const inMeetingVideosWithoutInterpreters = videoBasicAllAttendeeList.filter(
      (item) =>
        !shouldSignLanguageInterpreterBeRemovedFromMainChannelVideo(
          item,
          signLanguages,
          signLanguageInterpreterAllowToTalkMap,
        ),
    );
    let list = [...inMeetingVideosWithoutInterpreters] || [];

    list.sort((a, b) => a.videoOrder - b.videoOrder);
    /*
    // video basic order
    if (!isChangingVideoOrderSelector) {
      list = sortBasicListOrder(list, galleryOrderType);
    }

    // hasChangeVideoOrder case
    // 1 galleryview drag and drop
    // 2 follow host order
    // 3 save gallery order
    if (!isChangingVideoOrderSelector && !hasChangedVideoOrder) {
      const selfOrder = isGalleryOrderMode
        ? SELF_VIEW_DEFAULT_ORDER.FIRST
        : SELF_VIEW_DEFAULT_ORDER.SECOND;

      list = moveSelfDefaultOrder(list, currentUserId, selfOrder);
    }
*/
    if (!isShowZrMainUser) {
      list = list.filter((item) => {
        if (!isZRMultiStreamVideoParentUser(item)) {
          return true;
        }
        if (
          spotlightVideoList.includes(item.userId) ||
          pinVideoList.includes(item.userId) ||
          item.bRaiseHand ||
          !item.displayVideoOn
        ) {
          return true;
        }

        return false;
      });
    }

    // handle pin/spotlight/raisehand user to front position
    list = list.filter((item) => {
      if (
        spotlightVideoList.includes(item.userId) ||
        pinVideoList.includes(item.userId) ||
        item.bRaiseHand
      ) {
        return false;
      }
      return true;
    });

    if (highPriorityVideoList.length) {
      highPriorityVideoList
        .slice()
        .reverse()
        .forEach((item) => list.unshift(item));
    }

    return list;
  },
);

// 5.12.0 for speaker bar/ suspension list/ multi speaker bottom area list filter pin spotlight
export const videoWithCommonFilterListSelector = createSelector(
  [
    allViewParticipantsSelector,
    currentUserIdSelector,
    currentUserRoleSelector,
    pinVideoListSelector,
    spotlightVideoListSelector,
    coOrHostSelector,
    isReceiveSharingSelector,
    isClientEnableSeeSelfActiveWhenSpeakingSelector,
    speakerSpecialOrderUserSizeSelector,
    isNeedSpeakerSpecialOrderSelector,
    hasChangedVideoOrderSelector,
    showNoVideoParticipantsSelector,
    showSelfViewSelector,
    isClientPageVisibleSelector,
    isMeInRoomSelector,
    isInSharingModeSelector,
    whiteboardStatusSelector,
  ],
  (
    allVideoParticipants,
    currentUserId,
    currentUserRole,
    pinVideoList,
    spotlightVideoList,
    coOrHost,
    isReceiveSharing,
    isClientEnableSeeSelfActiveWhenSpeaking,
    speakerSpecialOrderUser,
    isNeedSpeakerSpecialOrder,
    hasChangedVideoOrder,
    showNoVideoParticipants,
    showSelfView,
    isClientPageVisible,
    isMeInRoom,
    isInSharingMode,
    whiteboardStatus,
  ) => {
    let list = [...allVideoParticipants] || [];

    if (allVideoParticipants.length === 1 && !isInSharingMode && !isMeInRoom) {
      list = [];
    }
    // filter pin/spotlight user
    if (
      allVideoParticipants.length === 2 &&
      pinVideoList.length === 0 &&
      !isInSharingMode &&
      !isClientEnableSeeSelfActiveWhenSpeaking
    ) {
      list = list.filter((item) => item.userId === currentUserId);
    }

    if (whiteboardStatus === WHITEBOARD_STATUS.OFF) {
      if ((coOrHost || isPanelist(currentUserRole)) && !isReceiveSharing) {
        if (pinVideoList.length > 0 && spotlightVideoList.length === 0) {
          list = list.filter((item) => !pinVideoList.includes(item.userId));
        }

        if (spotlightVideoList.length > 0 && pinVideoList.length === 0) {
          list = list.filter(
            (item) => !spotlightVideoList.includes(item.userId),
          );
        }

        if (spotlightVideoList.length > 0 && pinVideoList.length > 0) {
          list = list.filter((item) => !pinVideoList.includes(item.userId));
        }
      } else if (!isReceiveSharing) {
        if (pinVideoList.length > 0 && spotlightVideoList.length === 0) {
          list = list.filter((item) => !pinVideoList.includes(item.userId));
        }

        if (spotlightVideoList.length > 0 && pinVideoList.length === 0) {
          list = list.filter(
            (item) => !spotlightVideoList.includes(item.userId),
          );
        }

        if (spotlightVideoList.length > 0 && pinVideoList.length > 0) {
          list = list.filter(
            (item) => !spotlightVideoList.includes(item.userId),
          );
        }
      }
    }

    // self view order
    if (isNeedSpeakerSpecialOrder) {
      const myselfVideoIndex = list.findIndex(
        (item) => item.userId === currentUserId,
      );
      if (myselfVideoIndex > -1) {
        const selfItem = list[myselfVideoIndex];
        list.splice(myselfVideoIndex, 1);
        list.splice(speakerSpecialOrderUser, 0, selfItem);
      }
    } else if (!hasChangedVideoOrder) {
      const myselfVideoIndex = list.findIndex(
        (item) => item.userId === currentUserId,
      );

      if (myselfVideoIndex > -1) {
        const selfItem = list[myselfVideoIndex];
        list.splice(myselfVideoIndex, 1);
        list.unshift(selfItem);
      }
    }

    // webinar attendee only show video-on user
    if (isViewOnly(currentUserRole) && isWebinar()) {
      list = list.filter((item) => item.displayVideoOn);
    }

    // when user operate hide self view form avatar menu
    if (!showSelfView) {
      list = list.filter((item) => item.userId !== currentUserId) || {};
    }

    // when user operate hide no video participants view form avatar menu
    if (!showNoVideoParticipants && isClientPageVisible) {
      list = list.filter((item) => !!item.displayVideoOn);
    }
    return list;
  },
);

// 5.12.0 suspensionVideoSelector -> rename suspensionViewListSelector
// for suspension window three tabs include single view/ multi view
export const suspensionViewListSelector = createSelector(
  [
    videoWithCommonFilterListSelector,
    activeSpeakerIdSelector,
    currentUserSelector,
    currentUserIdSelector,
    selfVideoShowTypeSelector,
    isInSharingModeSelector,
    isSwapVideoSharedSelector, // isShowSuspensionSharedSelector
    videoBasicAllAttendeeListSelector,
  ],
  (
    videoWithCommonFilterList,
    activeSpeakerId,
    currentUser,
    currentUserId,
    selfVideoShowType,
    isInSharingMode,
    isSwapVideoShared,
    videoBasicAllAttendeeList,
  ) => {
    if (selfVideoShowType === MINIMIZED_VIDEO) {
      return [];
    }
    if (selfVideoShowType === ACTIVE_VIDEO || !isSupportMultiView()) {
      if (isInSharingMode) {
        if (isSwapVideoShared) {
          return [];
        }
        if (activeSpeakerId === 0) {
          return videoBasicAllAttendeeList.filter((item) => item.isHost);
        }
        return videoBasicAllAttendeeList.filter(
          (item) => item.userId >> 10 === activeSpeakerId >> 10,
        );
      }
      const videoList = videoWithCommonFilterList.filter(
        (item) => item.userId === currentUserId,
      );

      if (!videoList.length) {
        return videoBasicAllAttendeeList.filter(
          (item) => item.userId === currentUserId,
        );
      }

      return videoList;
    }
    return videoWithCommonFilterList;
  },
);

// 5.12.0 new add for speaker bar
export const speakerViewBarListSelector = createSelector(
  [videoWithCommonFilterListSelector],
  (videoWithCommonFilterList) => {
    return videoWithCommonFilterList;
  },
);

// use for single view main area/minimize main area/speaker active/protect gallery view
export const activeVideoParticipantsSelector = createSelector(
  [
    videoBasicAllAttendeeListSelector,
    pinVideoListSelector,
    currentUserSelector,
    currentUserIdSelector,
    currentUserRoleSelector,
    spotlightVideoListSelector,
    coOrHostSelector,
    signLanguageInterpretationLanguageSelector,
    isClientEnableSeeSelfActiveWhenSpeakingSelector,
    activeSpeakerIdSelector,
    signLanguageInterpreterAllowedToTalkSelector,
  ],
  (
    videoBasicAllAttendeeList,
    pinVideoList,
    currentUser,
    currentUserId,
    currentUserRole,
    spotlightVideoList,
    coOrHost,
    signLanguages,
    isClientEnableSeeSelfActiveWhenSpeaking,
    activeSpeakerId,
    signLanguageInterpreterAllowToTalkMap,
  ) => {
    // attendee
    // webinar

    if (
      pinVideoList.length > 0 &&
      spotlightVideoList.length > 0 &&
      !coOrHost &&
      !isPanelist(currentUserRole)
    ) {
      const tempVideoList = videoBasicAllAttendeeList.filter((item) =>
        spotlightVideoList.includes(item.userId),
      );
      return spotlightVideoList
        .map((item) => tempVideoList.find((subitem) => subitem.userId === item))
        .filter((item) => !!item);
    }

    if (pinVideoList.length > 0) {
      return videoBasicAllAttendeeList.filter((item) =>
        pinVideoList.includes(item.userId),
      );
    }

    const inMeetingVideosWithoutInterpreters = videoBasicAllAttendeeList.filter(
      (item) =>
        !shouldSignLanguageInterpreterBeRemovedFromMainChannelVideo(
          item,
          signLanguages,
          signLanguageInterpreterAllowToTalkMap,
        ),
    );

    if (spotlightVideoList.length > 0) {
      if (!isSupportMultiView()) {
        return inMeetingVideosWithoutInterpreters.filter(
          (item) =>
            item.userId === spotlightVideoList[spotlightVideoList.length - 1],
        );
      }
      const tempVideoList = inMeetingVideosWithoutInterpreters.filter((item) =>
        spotlightVideoList.includes(item.userId),
      );
      return spotlightVideoList
        .map((item) => tempVideoList.find((subitem) => subitem.userId === item))
        .filter((item) => !!item);
    }

    if (inMeetingVideosWithoutInterpreters.length === 1) {
      return inMeetingVideosWithoutInterpreters;
    }

    if (
      inMeetingVideosWithoutInterpreters.length === 2 &&
      !isClientEnableSeeSelfActiveWhenSpeaking
    ) {
      return inMeetingVideosWithoutInterpreters.filter(
        (item) => item.userId !== currentUserId,
      );
    }

    if (
      activeSpeakerId === 0 ||
      inMeetingVideosWithoutInterpreters.findIndex(
        (item) => item.userId >> 10 === activeSpeakerId >> 10,
      ) === -1
    ) {
      return inMeetingVideosWithoutInterpreters.filter(
        (item) => item.userId === currentUserId,
      );
    }

    return inMeetingVideosWithoutInterpreters.filter(
      (item) => item.userId >> 10 === activeSpeakerId >> 10,
    );
  },
);

// 5.12.0 new add for gallery view
export const galleryViewListSelector = createSelector(
  [
    allViewParticipantsSelector,
    activeVideoParticipantsSelector,
    specialOrderUserSizeSelector,
    isNeedSpecialOrderSelector,
    currentUserIdSelector,
    currentUserRoleSelector,
    showNoVideoParticipantsSelector,
    showSelfViewSelector,
    isClientPageVisibleSelector,
  ],
  (
    allViewParticipants,
    activeVideoParticipants,
    specialOrderUserSize,
    isNeedSpecialOrder,
    currentUserId,
    currentUserRole,
    showNoVideoParticipants,
    showSelfView,
    isClientPageVisible,
  ) => {
    let list = [...allViewParticipants] || [];

    // webinar attendee only show video-on user
    if (isViewOnly(currentUserRole) && isWebinar()) {
      list = list.filter((item) => item.displayVideoOn);
    }

    // when user operate hide self view form avatar menu
    if (!showSelfView) {
      list = list.filter((item) => item.userId !== currentUserId) || {};
    }

    // when user operate hide no video participants view form avatar menu
    if (!showNoVideoParticipants && isClientPageVisible) {
      list = list.filter((item) => !!item.displayVideoOn);
    }

    if (isNeedSpecialOrder) {
      const myselfVideoFrameIndex = list.findIndex(
        (item) => item.userId === currentUserId,
      );
      if (myselfVideoFrameIndex > -1) {
        const selfItem = list[myselfVideoFrameIndex];
        list.splice(myselfVideoFrameIndex, 1);
        list.splice(specialOrderUserSize, 0, selfItem);
      }
    }

    if (list.length === 0) {
      return activeVideoParticipants;
    }

    return list;
  },
);

// for tesla
export const teslaAllViewParticipantsSelector = createSelector(
  [
    videoBasicAllAttendeeListSelector,
    signLanguageInterpretationLanguageSelector,
    signLanguageInterpreterAllowedToTalkSelector,
  ],
  (
    videoBasicAllAttendeeList,
    signLanguages,
    signLanguageInterpreterAllowToTalkMap,
  ) => {
    // interpreters filter
    const inMeetingVideosWithoutInterpreters = videoBasicAllAttendeeList.filter(
      (item) =>
        !shouldSignLanguageInterpreterBeRemovedFromMainChannelVideo(
          item,
          signLanguages,
          signLanguageInterpreterAllowToTalkMap,
        ),
    );
    let list = [...inMeetingVideosWithoutInterpreters] || [];

    // video order
    list.sort((a, b) => a.videoOrder - b.videoOrder);

    return list;
  },
);

// for tesla active area
export const teslaActiveVideoParticipantSelector = createSelector(
  [
    teslaAllViewParticipantsSelector,
    currentUserIdSelector,
    activeSpeakerIdSelector,
    currentUserRoleSelector,
  ],
  (teslaList, currentUserId, activeSpeakerId, currentUserRole) => {
    if (teslaList.length === 1) {
      return teslaList;
    }

    if (
      teslaList.length === 2 &&
      !(isViewOnly(currentUserRole) && isWebinar())
    ) {
      return teslaList.filter((item) => item.userId !== currentUserId);
    }

    const list = teslaList.filter(
      (item) => item.userId >> 10 === activeSpeakerId >> 10,
    );

    if (list.length > 0) {
      return list;
    }

    return teslaList.filter(
      (item) => item.userId >> 10 === currentUserId >> 10,
    );
  },
);

// for tesla bar area
export const teslaBarVideoListSelector = createSelector(
  [
    teslaAllViewParticipantsSelector,
    currentUserIdSelector,
    pinVideoListSelector,
    spotlightVideoListSelector,
    highPriorityVideoListSelector,
    speakerSpecialOrderUserSizeSelector,
    isNeedSpeakerSpecialOrderSelector,
    isReceiveSharingSelector,
    currentUserRoleSelector,
    activeSpeakerIdSelector,
  ],
  (
    teslaList,
    currentUserId,
    pinVideoList,
    spotlightVideoList,
    highPriorityVideoList,
    speakerSpecialOrderUser,
    isNeedSpeakerSpecialOrder,
    isReceiveSharing,
    currentUserRole,
    activeSpeakerId,
  ) => {
    let list = [...teslaList] || [];

    if (isViewOnly(currentUserRole) && isWebinar()) {
      list = list.filter((item) => item.displayVideoOn);
      if (list.length === 0 && isReceiveSharing) {
        list = teslaList.filter(
          (item) => item.userId >> 10 === activeSpeakerId >> 10,
        );
      } else if (
        list.length === 1 &&
        list[0].userId >> 10 === activeSpeakerId >> 10 &&
        !isReceiveSharing
      ) {
        list = [];
      }
    } else if (
      teslaList.length === 1 ||
      (teslaList.length === 2 && !isReceiveSharing)
    ) {
      list = [];
    }

    list = list.filter((item) => {
      if (
        spotlightVideoList.includes(item.userId) ||
        pinVideoList.includes(item.userId) ||
        item.bRaiseHand
      ) {
        return false;
      }
      return true;
    });

    if (highPriorityVideoList.length) {
      highPriorityVideoList
        .slice()
        .reverse()
        .forEach((item) => list.unshift(item));
    }

    // self view order
    if (isNeedSpeakerSpecialOrder) {
      const myselfVideoFrameIndex = list.findIndex(
        (item) => item.userId === currentUserId,
      );
      if (myselfVideoFrameIndex > -1) {
        const selfItem = list[myselfVideoFrameIndex];
        list.splice(myselfVideoFrameIndex, 1);
        list.splice(speakerSpecialOrderUser, 0, selfItem);
      }
    } else {
      const myselfVideoFrameIndex = list.findIndex(
        (item) => item.userId === currentUserId,
      );

      if (myselfVideoFrameIndex > -1) {
        const selfItem = list[myselfVideoFrameIndex];
        list.splice(myselfVideoFrameIndex, 1);
        list.unshift(selfItem);
      }
    }

    return list;
  },
);

// for tesla suspension area
export const teslaSuspensionVideoSelector = createSelector(
  [teslaAllViewParticipantsSelector, currentUserIdSelector],
  (teslaAllViewParticipants, currentUserId) => {
    if (teslaAllViewParticipants.length === 2) {
      return teslaAllViewParticipants.filter(
        (item) => item.userId === currentUserId,
      );
    }
    return [];
  },
);

const activeSpeakerListSelector = ({ video: { activeSpeakerList } }) =>
  activeSpeakerList;

export const multiSpeakerConfigSelector = ({ video: { multiSpeakerConfig } }) =>
  multiSpeakerConfig;

export const multiSpeakerActiveVideoSelector = createSelector(
  [
    allViewParticipantsSelector,
    pinVideoListSelector,
    spotlightVideoListSelector,
    activeSpeakerListSelector,
    isClientEnableSeeSelfActiveWhenSpeakingSelector,
    currentUserIdSelector,
    showSelfViewSelector,
  ],
  (
    allViewParticipants,
    pinVideoList,
    spotlightVideoList,
    activeSpeakerList,
    isClientEnableSeeSelfActiveWhenSpeaking,
    currentUserId,
    showSelfView,
  ) => {
    let result = [];
    pinVideoList.forEach((item) => {
      const pinUser = allViewParticipants.find(
        (participant) => participant.userId === item,
      );
      if (pinUser && !result.some((item) => item.userId === pinUser.userId)) {
        result.push(pinUser);
      }
    });

    spotlightVideoList.forEach((item) => {
      const spotlightUser = allViewParticipants.find(
        (participant) => participant.userId === item,
      );
      if (
        spotlightUser &&
        !result.some((item) => item.userId === spotlightUser.userId)
      ) {
        result.push(spotlightUser);
      }
    });

    if (!isClientEnableSeeSelfActiveWhenSpeaking) {
      activeSpeakerList = activeSpeakerList.filter(
        (item) => item !== currentUserId,
      );
    }

    activeSpeakerList.forEach((item) => {
      const activeSpeaker = allViewParticipants.find(
        (participant) => participant.userId === item,
      );
      if (
        activeSpeaker &&
        !result.some((item) => item.userId === activeSpeaker.userId)
      ) {
        result.push(activeSpeaker);
      }
    });

    if (!showSelfView) {
      result = result.filter((item) => item.userId !== currentUserId);
    }

    return result.slice(0, 9);
  },
);

export const multiSpeakerMainVideoBaseSelector = createSelector(
  [videoWithCommonFilterListSelector, multiSpeakerActiveVideoSelector],
  (videoWithCommonFilterList, multiSpeakerActiveVideo) => {
    return videoWithCommonFilterList.filter(
      (item) =>
        multiSpeakerActiveVideo.findIndex((v) => v.userId === item.userId) ===
        -1,
    );
  },
);

export const multiSpeakerMainVideoSelector = createSelector(
  [
    multiSpeakerMainVideoBaseSelector,
    multiSpeakerActiveVideoSelector,
    galleryViewListSelector,
    multiSpeakerConfigSelector,
  ],
  (
    multiSpeakerMainVideoBase,
    multiSpeakerActiveVideo,
    galleryViewList,
    multiSpeakerConfig,
  ) => {
    if (
      multiSpeakerActiveVideo.length > 0 &&
      multiSpeakerActiveVideo.length + multiSpeakerMainVideoBase.length >
        multiSpeakerConfig[0][2]
    ) {
      return multiSpeakerMainVideoBase;
    }
    return galleryViewList;
  },
);
