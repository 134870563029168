import { createActions } from 'redux-actions';

export const {
  setIsZoomIQ,
  setShowRecordSettingDropdown,
  recordingReset,
  setHasAlertTimeoutDialog,
  setSmartRecordingState,
  setShowHostReceiveCloudRecordingRequestDialog,
  setRequestLocalRecordUserList,
} = createActions(
  'SET_IS_ZOOM_I_Q',
  'SET_SHOW_RECORD_SETTING_DROPDOWN',
  'RECORDING_RESET',
  'SET_HAS_ALERT_TIMEOUT_DIALOG',
  'SET_SMART_RECORDING_STATE',
  'SET_SHOW_HOST_RECEIVE_CLOUD_RECORDING_REQUEST_DIALOG',
  'SET_REQUEST_LOCAL_RECORD_USER_LIST',
);
