import { getLocalTime } from '../../../global/util';
import {
  CHAT_MSG_TYPE_TO_ALL,
  CHAT_MSG_TYPE_TO_PANELIST,
  CHAT_MSG_TYPE_TO_SILENT_MODE_USERS,
  CHAT_MSG_TYPE_TO_INDIVIDUAL,
  // CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
  CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS,
} from '../../chat/constants';
import {
  hostsText,
  everyoneText,
  hostAndPanelistsText,
  waitingRoomParticipantsText,
} from '../../chat/resource';
import { produce } from 'immer';
import { YOU as youTxt } from '../../../global/resource';
import { CHAT_CONSTANTS, CHAT_THREAD } from '../../../global/constant';
import { ChatNotSeen } from '../resource';
import { getFileExt, ImageExtentions, isJidAtAll, matchJid } from '../help';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';

function getCorrectChatHeadInfos({
  receiverId,
  chatReceiver,
  currentUserId,
  currentUser,
  attendees,
  currentUserRole,
  isConveyByXmpp,
  receiverTxt,
  type,
}) {
  let receiver = null;
  let isShowPrivately = false;
  let isCCAllPanelists = false;
  let isSilentModeUser = false;
  let isReceiverMe = false;

  if (receiverId === CHAT_MSG_TYPE_TO_ALL) {
    receiver = everyoneText;
    chatReceiver = null;
  } else if (receiverId === CHAT_MSG_TYPE_TO_PANELIST) {
    receiver = hostAndPanelistsText;
    chatReceiver = null;
  } else if (receiverId === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS) {
    /* host chat to all in WR */
    receiver = waitingRoomParticipantsText;
    chatReceiver = null;
  } else if (receiverId === CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS) {
    /* WR chat to hosts group */
    receiver = hostsText;
    chatReceiver = null;
  } else if (receiverId === currentUserId) {
    receiver = youTxt;
    isReceiverMe = true;

    chatReceiver = currentUser;
    if (isViewOnly(currentUserRole)) {
      if (type === CHAT_MSG_TYPE_TO_INDIVIDUAL) {
        isShowPrivately = true;
      } else {
        isCCAllPanelists = true;
      }
    } else if (currentUser.bHold) {
      isSilentModeUser = true;
    } else {
      isShowPrivately = true;
    }
  } else if (isConveyByXmpp) {
    receiver = receiverTxt;
    isCCAllPanelists = true;
  } else if (
    isWebinar() &&
    attendees.findIndex((user) => user.node === receiverId) > -1
  ) {
    const chatUser = attendees.find((user) => user.node === receiverId);
    if (chatUser) {
      receiver = chatUser.name;
    }
    isCCAllPanelists = true;
  } else {
    // const chatUser = attendeesList.find(user => user.userId === receiverId);
    // eslint-disable-next-line no-lonely-if
    if (chatReceiver) {
      receiver = chatReceiver.displayName;
      isShowPrivately = !chatReceiver.bHold;
      isSilentModeUser = chatReceiver.bHold;
    }
  }
  return {
    receiver,
    isReceiverMe,
    isShowPrivately,
    isCCAllPanelists,
    isSilentModeUser,
    isToMyMessage: receiverId === currentUserId,
  };
}

export function generateMentions(atUsers, currentUserConfId) {
  // const mention = [];
  // const atUsersJidMap = _.mapKeys(_.groupBy(atUsers, '_jid'), (val, key) => {
  //   if (isJidAtAll(key)) {
  //     return 'atall';
  //   } else {
  //     return key.split('@')[0];
  //   }
  // });

  const mention = [];
  let isAtAll = false;
  let isAtMe = false;
  _.forEach(atUsers, ({ _jid: jid, _s: start, _e: end, _t: type }) => {
    const atAll = isJidAtAll(jid) ? 1 : 0;
    const atMe = matchJid(jid, currentUserConfId);
    if (!isAtAll) isAtAll = atAll;
    if (!isAtMe) isAtMe = atMe;
    mention.push({
      jid,
      start: Number(start),
      end: Number(end),
      type: Number(type),
      atAll,
      atMe,
    });
  });
  return {
    mention,
    isAtMe,
    isAtAll,
  };
}

export function buildListMeetingChat(
  meetingChat,
  { attendees, attendeesList, currentUser },
  // defaultAvatar,
  {
    text,
    receiverId,
    receiver: messageReceiver,
    receiverJid,
    senderId,
    sender,
    isSenderMe,
    isConveyByXmpp,
    type,
    msgId,
    xmppMsgId,
    // isChatPinBottom,
    isSilentModeUser: isToSilentModeUser, // = false,
    file,
    isEdited = false,
    timeStamp,
    senderSN,
    reply: replyTo,
    ownerRes,
    bid,
    atUsers,
    rtbItems,
    ...others
  },
) {
  // const lastChatMessage = _.last(meetingChat);
  const isFile = !_.isEmpty(file);
  // In order to gaurantee the correctness of avatar, search the right avarar every time.
  // Should be improved in terms of performance.
  const { userId: currentUserId, userRole: currentUserRole } = currentUser;
  let chatSender;
  let chatReceiver;
  if (attendeesList && attendeesList.length > 0) {
    for (let index = 0; index < attendeesList.length; index++) {
      const user = attendeesList[index];
      if (user.userId === senderId) chatSender = user;
      if (user.userId === receiverId) chatReceiver = user;
    }
  }

  // currently only support meeting chat ignore webinar
  if (isWebinar() && attendees && attendees.length > 0) {
    if (!chatSender) {
      chatSender = attendees.find((user) => user.node === senderId);
    }
    if (!chatReceiver) {
      chatReceiver = attendees.find((user) => user.node === receiverId);
    }
  }

  const otherInfo = getCorrectChatHeadInfos({
    receiverId,
    chatReceiver,
    currentUserId,
    currentUser,
    attendees,
    currentUserRole,
    isConveyByXmpp,
    receiverTxt: messageReceiver,
    type,
  });

  const headerInfo = {
    chatSender: { ...chatSender },
    chatReceiver: { ...chatReceiver },
    sender,
    senderId,
    isSenderMe,
    receiverId,
    receiverJid,
    timeStamp: getLocalTime(timeStamp, {
      hour: '2-digit',
      minute: '2-digit',
    }),
    t: timeStamp || Date.now(),
    senderSN,
    isFile,
    isToSilentModeUser,
    isEdited,
    isMyMessage: senderId === currentUserId,
    read: senderId === currentUserId,
    bid,
    ...otherInfo,
  };

  const { isAtAll, isAtMe, mention } = generateMentions(
    atUsers,
    currentUser.strConfUserID,
  );
  const messageContent = {
    text,
    msgId,
    xmppMsgId,
    // read: senderId === currentUserId,
    file,
    t: timeStamp || Date.now(),
    senderSN,
    isEdited,
    rtbItems:
      file || !rtbItems
        ? {}
        : {
            ...rtbItems,
            mention: [...mention, ...(rtbItems.mention || [])],
          },
    ...others,
  };

  const chatItem = {
    ...headerInfo,
    msgId,
    ownerRes,
    xmppMsgId, // maybe null or undefined
    content: messageContent,
    threadCount: 0,
    mainMsgId: null,
    truncateCount: 0,
    fold: CHAT_THREAD.NONE,
    read: senderId === currentUserId,
    isAtAll,
    isAtMe,
  };

  if (file) {
    file.fileExtention = getFileExt(file.fileName?.toLowerCase());
    if (ImageExtentions.includes(file.fileExtention)) {
      file.imgHasPreview = true;
    }
  }

  let newMeetingChat = meetingChat;
  let insertIndex = -1;
  if (!replyTo || replyTo.isEmpty || _.isEmpty(replyTo)) {
    // receive normal message
    newMeetingChat = produce(meetingChat, (draft) => {
      draft.push(chatItem);
    });
    insertIndex = newMeetingChat.length - 1;
  } else {
    const { mainMsgId, mainMsgTime, owner } = replyTo;
    // receive new thread
    const { hasFound, index: mainIndex } = findChatItemIndex(
      mainMsgId,
      meetingChat,
    );
    if (hasFound) {
      // has found main message, insert new reply
      newMeetingChat = produce(meetingChat, (draft) => {
        const mainMessage = draft[mainIndex];
        if (mainMessage.fold === CHAT_THREAD.COLLAPSE) {
          mainMessage.fold =
            mainMessage.threadCount >= 3
              ? CHAT_THREAD.MORE
              : CHAT_THREAD.EXPAND;

          if (mainMessage.threadCount >= 3) {
            mainMessage.truncateCount = mainMessage.threadCount - 3 + 1; // threadCount need +1
          }
        } else if (
          !mainMessage.fold ||
          mainMessage.fold === CHAT_THREAD.NONE ||
          mainMessage.threadCount === 0
        ) {
          mainMessage.fold = CHAT_THREAD.EXPAND;
        }
        mainMessage.threadCount += 1;
        Object.assign(chatItem, {
          isThread: true,
          mainMsgId: mainMessage.xmppMsgId || mainMessage.msgId,
          mainMsgTime: mainMsgTime,
        });
        draft.splice(mainIndex + mainMessage.threadCount || 0, 0, chatItem);
        insertIndex = mainIndex + mainMessage.threadCount || 0;
      });
    } else {
      // not found, insert main & reply
      const dummyMainChatItem = {
        cat: CHAT_CONSTANTS.DUMMY_HEADER,
        threadCount: 1,
        t: mainMsgTime,
        xmppMsgId: mainMsgId,
        msgId: mainMsgId,
        fold: CHAT_THREAD.EXPAND,
        chatSender: {},
        chatReceiver: {},
        isThread: false,
        receiverId,
        ownerRes: owner,
        receiver: messageReceiver,
        content: {
          text: ChatNotSeen,
          read: senderId === currentUserId,
          file,
          t: timeStamp,
          senderSN,
          isEdited,
          xmppMsgId: mainMsgId,
          msgId: mainMsgId,
        },
      };
      Object.assign(chatItem, {
        isThread: true,
        mainMsgId: mainMsgId,
        mainMsgTime: mainMsgTime,
      });
      newMeetingChat = produce(meetingChat, (draft) => {
        draft.push(...[dummyMainChatItem, chatItem]);
      });
      insertIndex = newMeetingChat.length - 1;
    }
  }
  if (senderId === currentUserId) insertIndex = -1;

  return { newMeetingChat, insertIndex, msgId: xmppMsgId || msgId, chatItem };
}

export function findChatItemIndex(targetId, meetingChat) {
  let index, hasFound;
  for (index = 0; index < meetingChat.length; index++) {
    const { xmppMsgId, msgId } = meetingChat[index];
    if ((xmppMsgId && xmppMsgId === targetId) || msgId === targetId) {
      hasFound = true;
      return { hasFound, index };
    }
  }
  return { hasFound, index };
}
