import meetingConfig from 'meetingConfig';
import { isCoHost, isCoOrHost } from '../../../global/service/index';
import { WHITEBOARD_INIT_ROLE, WHITEBOARD_PERMISSION_CODE } from '../enum';
import { createSelector } from 'reselect';
import { currentUserSelector } from '../../../global/redux/selector';

export const presenterNameSelector = ({
  attendeesList: { attendeesList },
  whiteboard: {
    whiteboardSharer: { presenterID },
  },
}) => {
  const attendee = attendeesList.find(
    (attendee) => attendee.userId === presenterID,
  );
  return attendee ? attendee.displayName : '';
};

export const whiteboardStatusSelector = ({
  whiteboard: { whiteboardStatus },
}) => whiteboardStatus;

export const whiteboardSelector = ({ whiteboard }) => whiteboard;

export const canInitiateNewWhiteboardSelector = createSelector(
  [whiteboardSelector, currentUserSelector],
  (whiteboard, currentUser) => {
    const { whiteboardPermissionCode, whiteboardInitRole } = whiteboard;
    // host or coHost
    if (isCoOrHost(currentUser)) {
      if (
        isCoHost(currentUser) &&
        whiteboardInitRole === WHITEBOARD_INIT_ROLE.INTERNAL_USER &&
        !meetingConfig.isSameAccount
      ) {
        return false;
      }
      return true;
    }

    // allow share unchecked for participants
    if (
      whiteboardPermissionCode ===
      WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_LOCK_SHARE
    ) {
      return false;
    }

    // allow share checked for participants
    if (whiteboardInitRole === WHITEBOARD_INIT_ROLE.HOST_ONLY) {
      return false;
    } else if (whiteboardInitRole === WHITEBOARD_INIT_ROLE.INTERNAL_USER) {
      if (meetingConfig.isSameAccount) {
        return true;
      } else {
        return false;
      }
    } else if (whiteboardInitRole === WHITEBOARD_INIT_ROLE.ALL_PARTICIPANTS) {
      return true;
    }

    return true;
  },
);
