export const NONE_TEMPLATE_ID = 'zoom_none_id';
export const NONE_TEMPLATE = {
  accentColor: '',
  bgColor: '',
  id: NONE_TEMPLATE_ID,
  name: 'zoom none',
  options: 0,
  seq: -1,
  textColor: '',
};
