import { CONTROL_MODE_XMPP_MSG_TYPE } from '../../enum';
import { EXIT_MEETING_INTERVAL } from './constant';

export const xmppMsgHandler = (evt, adaptor) => {
  switch (evt) {
    case CONTROL_MODE_XMPP_MSG_TYPE.PROMOTE:
    case CONTROL_MODE_XMPP_MSG_TYPE.DEPROMOTE: {
      adaptor.notifyControllerMeetingHoldOn();
      break;
    }
    case CONTROL_MODE_XMPP_MSG_TYPE.EXPEL: {
      setTimeout(() => {
        adaptor.notifyControllerMeetingHasEnded();
      }, EXIT_MEETING_INTERVAL);
      break;
    }
    default:
      break;
  }
};
