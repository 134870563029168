import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DOM_KEY_CODE } from '../../../enum';

const Checkbox = (props) => {
  const {
    checked,
    defaultChecked,
    disabled,
    onChange,
    className,
    children,
    ...rest
  } = props;
  const [sChecked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    if (checked !== null) {
      setChecked(checked);
    }
  }, [checked]);

  const handleKeyDown = (e) => {
    if (disabled) return;
    if (e.keyCode === DOM_KEY_CODE.SPACE || e.keyCode === DOM_KEY_CODE.ENTER) {
      const checkedValue = !sChecked;
      setChecked(checkedValue);
      e.stopPropagation();
      e.preventDefault();
      if (onChange) {
        onChange(checkedValue);
      }
    }
  };
  const handleClick = () => {
    if (disabled) return;
    const checkedValue = !sChecked;
    setChecked(checkedValue);
    if (onChange) {
      onChange(checkedValue);
    }
  };
  const className1 = classNames('zm-checkbox', className, {
    'zm-checkbox-disabled': disabled,
    'zm-checkbox-checked': sChecked,
  });
  return (
    <div
      className={className1}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      role="checkbox"
      tabIndex={disabled ? '-1' : '0'}
      aria-checked={sChecked}
      disabled={disabled}
      {...rest}
    >
      <div className="zm-checkbox-message">{children}</div>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
};
Checkbox.defaultProps = {
  checked: null,
  disabled: false,
  defaultChecked: false,
  className: '',
  onChange: () => {},
};

export default Checkbox;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/components/widget/checkbox/checkbox.scss';
