import { WHITEBOARD_PERMISSION_CODE, WHITEBOARD_STATUS } from '../enum';
import {
  setIsShowWhiteboardWindow,
  setWhiteboardPermissionCode,
  setWhiteboardStatus,
  setIsWhiteboardCanvasReady,
  setHasStartWhiteboardShare,
  setWhiteboardInitRole,
  setWhiteboardInitWithoutHost,
  setHaveAccessAfterMeeting,
} from './whiteboard-action';
import { globalVariable } from '../../../global/global-variable';
import {
  START_SHARING_WHITEBOARD,
  STOP_SHARING_WHITEBOARD,
  EVT_TYPE_WS_SHARING_UNSUBSCRIBE_REQ,
  SHARER_STATUS_ENUM,
} from '../../sharing/enum';
import { getShareCaptureMode } from '../../sharing/service';
import { sendSocketMessage } from '../../../actions/SocketActions';
import {
  WS_WB_START_SHARE,
  WS_WB_STOP_SHARE,
  WS_WB_CHANGE_SHARE_ROLE_REQ,
  WS_WB_CHANGE_SHARE_TO_ALL_REQ,
} from '../../../constants/ZoomSocketEventTypes';
import { decodeBase64ToBuffer, coOrHostSelector } from '../../../global';
import { WHITEBOARD_MESSAGE } from '../../../constants/AVNotifyMediaSDKTypes';
import {
  showDisableShareWhiteboardDialog,
  showCannotShareWhiteboardAtTheSameTimeDialog,
  showGrabShareWhiteboardConfirmDialog,
  showWhiteboardWhenSharingScreenDialog,
  showDisableWhiteboardWhenSharingScreenDialog,
  showDisableWhiteboardWhenSharingScreenToBoDialog,
} from '../../dialog/redux/dialog-thunk-action';
import { isInRoom } from '../../breakout-room/utils';
import { stopSharer } from '../../sharing/redux/sharing-thunk-action';
import {
  getWhiteboardInitRole,
  getWhiteboardInitWithoutHost,
  getWhiteboardPermissionCode,
  postMessageToWBDashboard,
  updateWBDashboardBasicInfo,
} from '../service';
import { isHost } from '../../../global/service/user-types';
import { isReceiveSharingSelector } from '../../../global/redux/selector';

export const startShareWhiteboard = () => {
  return (dispatch, getState) => {
    const {
      sharing: { sharerSsrc },
    } = getState();

    globalVariable.avSocket.sendSocket(START_SHARING_WHITEBOARD, {
      mode: getShareCaptureMode(),
      ssid: sharerSsrc,
      canvas: 'canvas',
    });
  };
};

export const stopShareWhiteboard = () => {
  return (dispatch, getState) => {
    const {
      sharing: { sharerSsrc },
    } = getState();

    globalVariable.avSocket.sendSocket(STOP_SHARING_WHITEBOARD, {
      mode: getShareCaptureMode(),
      ssid: sharerSsrc,
      canvas: 'canvas',
    });
  };
};

export const openWhiteboard = (data) => {
  return (dispatch, getState) => {
    const { haveAccessAfterMeeting, sharingrole, docId } = data;
    const {
      whiteboard: { whiteboardConfig, whiteboardSharer },
      breakoutRoom: {
        attendee: {
          room: { boToken },
          status,
        },
      },
    } = getState();

    if (docId === whiteboardConfig.docId && whiteboardSharer.dcsID) {
      return;
    }

    dispatch(
      sendSocketMessage({
        evt: WS_WB_START_SHARE,
        body: {
          docId,
          sharingrole,
          haveAccessAfterMeeting: !!haveAccessAfterMeeting,
          boToken: isInRoom(status) ? boToken : '',
        },
      }),
    );
    dispatch(setWhiteboardStatus(WHITEBOARD_STATUS.LOADING));
    dispatch(setIsShowWhiteboardWindow(false));
    dispatch(setHaveAccessAfterMeeting(haveAccessAfterMeeting));
  };
};

export const newWhiteboard = (data) => {
  return (dispatch, getState) => {
    const {
      haveAccessAfterMeeting,
      sharingrole,
      templateType,
      accountId,
      docName,
      templateId,
    } = data;
    const {
      breakoutRoom: {
        attendee: {
          room: { boToken },
          status,
        },
      },
      whiteboard: { whiteboardToken },
    } = getState();

    dispatch(
      sendSocketMessage({
        evt: WS_WB_START_SHARE,
        body: {
          sharingrole,
          docName,
          haveAccessAfterMeeting: !!haveAccessAfterMeeting,
          boToken: isInRoom(status) ? boToken : '',
          templateId,
          templateType: templateType !== '' ? templateType : 0,
          accountId,
          whiteboardToken,
        },
      }),
    );
    dispatch(setWhiteboardStatus(WHITEBOARD_STATUS.LOADING));
    dispatch(setIsShowWhiteboardWindow(false));
    dispatch(setHaveAccessAfterMeeting(haveAccessAfterMeeting));
  };
};

export const closeWhiteboard = () => {
  return (dispatch, getState) => {
    const {
      whiteboard: {
        whiteboardConfig: { docId },
      },
    } = getState();

    globalVariable.whiteboardSdk?.destroy();
    globalVariable.whiteboardSdk = null;
    dispatch(
      sendSocketMessage({
        evt: WS_WB_STOP_SHARE,
        body: {
          docid: docId,
        },
      }),
    );
  };
};

export const leaveWhiteboard = () => {
  return (dispatch, getState) => {
    const {
      sharing: { sharerSsrc },
      whiteboard: {
        whiteboardConfig,
        whiteboardSharer: { dcsID },
      },
      meeting: {
        avsEncryptKeys: { sharingEncryptKey },
        zoomId,
      },
    } = getState();

    const leaveMessage = globalVariable.whiteboardSdk?.conn?.getLeaveMessage();
    if (
      dcsID &&
      whiteboardConfig.EncodedSn &&
      sharingEncryptKey &&
      leaveMessage
    ) {
      globalVariable.avSocket.sendSocket(WHITEBOARD_MESSAGE, {
        message: leaveMessage,
        nodeId: sharerSsrc,
        sn: decodeBase64ToBuffer(zoomId),
        encryptKey: decodeBase64ToBuffer(sharingEncryptKey),
        dcsId: dcsID,
        EncodedSn: decodeBase64ToBuffer(whiteboardConfig.EncodedSn),
      });
    }
  };
};

export const toggleWhiteboardWindow = (isContinueToShare) => {
  return (dispatch, getState) => {
    const {
      whiteboard: {
        whiteboardPermissionCode,
        whiteboardSharer: { presenterID },
      },
      meeting: {
        currentUser: { userId },
      },
      sharing: { sharerStatus, isReceiveSharingFromMainSession },
    } = getState();

    const isReceiveSharing = isReceiveSharingSelector(getState());

    const coOrHost = coOrHostSelector(getState());
    const isSharing =
      isReceiveSharing || sharerStatus !== SHARER_STATUS_ENUM.ED;
    if (!isContinueToShare) {
      if (isSharing) {
        if (coOrHost) {
          if (isReceiveSharingFromMainSession) {
            dispatch(showDisableWhiteboardWhenSharingScreenToBoDialog());
          } else {
            dispatch(showWhiteboardWhenSharingScreenDialog());
          }
        } else {
          dispatch(showDisableWhiteboardWhenSharingScreenDialog());
        }
        return;
      }
    }

    switch (whiteboardPermissionCode) {
      case WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_LOCK_SHARE:
        if (!coOrHost) {
          dispatch(showDisableShareWhiteboardDialog());
        } else {
          dispatch(openWhiteboardWindow());
        }
        break;
      case WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_HOST_GRAB:
        if (coOrHost) {
          dispatch(openWhiteboardWindow());
        } else if (presenterID && presenterID !== userId) {
          dispatch(showCannotShareWhiteboardAtTheSameTimeDialog());
        } else {
          dispatch(openWhiteboardWindow());
        }
        break;
      case WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_ANYONE_GRAB:
        if (presenterID && presenterID !== userId) {
          dispatch(showGrabShareWhiteboardConfirmDialog());
        } else {
          dispatch(openWhiteboardWindow());
        }
        break;
      default:
        break;
    }
  };
};

export const openWhiteboardWindow = () => {
  return (dispatch, getState) => {
    dispatch(setIsShowWhiteboardWindow(true));
    postMessageToWBDashboard({ messageName: 'REFRESH_DATA' });
    updateWBDashboardBasicInfo(getState());
  };
};

/**
 * shareWbPermission in WS_CONF_WB_LOCK_SHARE_INDICATION(28680)
 * Six bits represent four options
 * 0 00 0 00
 * First and second bit: whiteboardPermissionCode(0,1,2,3)
 * Third bit: isWhiteboardShareOptionsLocked(0,1)
 * Fourth and Fifth bit: whiteboardInitRole(0,1,2)
 * Sixth bit: isWhiteboardInitWithoutHost(0,1)
 */
export const handleWhiteboardPermissionCode = (newWhiteboardPermissionCode) => {
  return (dispatch, getState) => {
    dispatch(
      setWhiteboardInitRole(getWhiteboardInitRole(newWhiteboardPermissionCode)),
    );
    dispatch(
      setWhiteboardInitWithoutHost(
        getWhiteboardInitWithoutHost(newWhiteboardPermissionCode),
      ),
    );
    newWhiteboardPermissionCode = getWhiteboardPermissionCode(
      newWhiteboardPermissionCode,
    );
    const state = getState();
    const {
      whiteboard: {
        whiteboardSharer: { presenterID },
        whiteboardPermissionCode,
      },
      meeting: {
        currentUser: { userId },
      },
    } = state;
    const coOrHost = coOrHostSelector(state);
    if (
      newWhiteboardPermissionCode ===
        WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_LOCK_SHARE &&
      !coOrHost
    ) {
      dispatch(setIsShowWhiteboardWindow(false));
      if (presenterID === userId) {
        dispatch(closeWhiteboard());
      }
    }
    if (
      presenterID &&
      !coOrHost &&
      whiteboardPermissionCode ===
        WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_ANYONE_GRAB &&
      newWhiteboardPermissionCode ===
        WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_HOST_GRAB
    ) {
      dispatch(setIsShowWhiteboardWindow(false));
    }
    dispatch(setWhiteboardPermissionCode(newWhiteboardPermissionCode));
    updateWBDashboardBasicInfo(state);
  };
};

export const handleWithdrawCoOrHostPermission = () => {
  return (dispatch, getState) => {
    const {
      whiteboard: {
        whiteboardPermissionCode,
        whiteboardSharer: { presenterID },
      },
      meeting: {
        currentUser: { userId },
      },
    } = getState();
    if (
      whiteboardPermissionCode ===
        WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_LOCK_SHARE ||
      (whiteboardPermissionCode ===
        WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_HOST_GRAB &&
        presenterID &&
        presenterID !== userId)
    ) {
      dispatch(setIsShowWhiteboardWindow(false));
    }
    if (
      whiteboardPermissionCode ===
        WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_LOCK_SHARE &&
      presenterID === userId
    ) {
      dispatch(closeWhiteboard());
    }
  };
};

export const updateWhiteboardProperties = (data) => {
  return (dispatch) => {
    if (data.messageName === 'ChangeTempUserRole') {
      dispatch(
        sendSocketMessage({
          evt: WS_WB_CHANGE_SHARE_ROLE_REQ,
          body: {
            docid: data.docid,
            role: data.role,
          },
        }),
      );
    } else if (data.messageName === 'ShareToAll') {
      dispatch(
        sendSocketMessage({
          evt: WS_WB_CHANGE_SHARE_TO_ALL_REQ,
          body: {
            docid: data.docid,
            bPermanent: data.bPermanent,
          },
        }),
      );
    } else if (data.messageName === 'WhiteboardCanvasReady') {
      dispatch(setIsWhiteboardCanvasReady(true));
    } else if (data.messageName === 'CloseWhiteBoard') {
      dispatch(closeWhiteboard());
    }
  };
};

export const handlePreviousWhiteboard = () => {
  return (dispatch, getState) => {
    const {
      whiteboard: {
        whiteboardSharer: { dcsID, presenterID },
        hasStartWhiteboardShare,
        whiteboardStatus,
      },
      meeting: {
        currentUser: { userId },
      },
    } = getState();
    if (dcsID) {
      dispatch(leaveWhiteboard());
      dispatch(
        sendSocketMessage({
          evt: EVT_TYPE_WS_SHARING_UNSUBSCRIBE_REQ,
          body: { bWb: true },
        }),
      );
    }
    if (presenterID === userId) {
      if (hasStartWhiteboardShare) {
        dispatch(stopSharer(false, true));
        dispatch(stopShareWhiteboard());
      }
    }
    dispatch(setIsWhiteboardCanvasReady(false));
    dispatch(setHasStartWhiteboardShare(false));
    if (whiteboardStatus === WHITEBOARD_STATUS.ON) {
      dispatch(setWhiteboardStatus(WHITEBOARD_STATUS.OFF));
    }
  };
};

export const updateWBDashboardBasicInfoForHostJoin = (message) => {
  return (dispatch, getState) => {
    const {
      whiteboard: { isShowWhiteboardWindow },
    } = getState();
    if (isShowWhiteboardWindow) {
      if (message.body.add && message.body.add.length > 0) {
        const hostUser = message.body.add.find((item) => isHost(item.role));
        if (hostUser) {
          updateWBDashboardBasicInfo(getState());
        }
      }
      if (message.body.update && message.body.update.length > 0) {
        const hostUser = message.body.update.find((item) => isHost(item.role));
        if (hostUser) {
          updateWBDashboardBasicInfo(getState());
        }
      }
    }
  };
};

export const handleCoOrHostChangeForWhiteboard = (
  isHostOrCoHostChange,
  changedValue,
) => {
  return () => {
    const data = isHostOrCoHostChange
      ? { isHost: changedValue }
      : { bCoHost: changedValue };
    globalVariable.whiteboardSdk?.conn?.onPWAMessage({
      messageName: 'PwaInMeetingConfigMessage',
      data,
    });
  };
};
