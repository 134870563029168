import { globalVariable } from '../../../../global/global-variable';
import { getVideoCaptureMode } from '../../service/service';
import {
  isSupportVB,
  setAvStatusInSessionStorage,
} from '../../../../global/service';
import VirtualBackgroundInit from '../../../settings/container/background-pane/virtual-background-init';
import {
  getVideoDPI,
  isExternalControlledMode,
  promptA11yInfo,
  isMTRAndroid,
  getPTZCapability,
} from '../../../../global/util';

import {
  setVideoDecodeLoading,
  setCameraCaptureLoading,
  setCameraIsForbidden,
  setCameraIsTaken,
  setIsLoadingBackgroundFromStartVideo,
  setIsNeedNotifyStartVideoFail,
  setCurrentVideoRenderTag,
  setVideoActionInProgress,
  setShouldRecoverVideoOn,
} from '../video-action';
import {
  START_CAPTURE_VIDEO,
  STOP_CAPTURE_VIDEO,
  MUTE_VIDEO_MODAL_STOP,
  VIDEO_CANVAS,
} from '../../enum';

import { CAN_NOT_DETECT_CAMERA } from '../../resource';
import {
  externalController,
  CONTROL_MODE_ZOOM_MSG_TYPE,
  CONTROL_MODE_ZOOM_UI_ACTION_TYPE,
} from '../../../../controller';

import {
  selfVideoRenderTagSelector,
  isVideoEncodeReadySelector,
} from '../selectors/video-status-selector';
import {
  clearBackground,
  hasBackground,
  isHasBackgroundRecord,
} from '../../../settings/service';
import AliveToast from '../../../../global/containers/notification-manager/alive-toast';
import {
  PWAMeetingEvent,
  sendMsgToPWA,
} from '../../../../global/pwa-integration';
import {
  muteVideo,
  openVBNoticeThunk,
  videoCannotStartNoticeThunk,
} from '../../../dialog/redux/dialog-thunk-action';
import { isWebinar } from '../../../../global/service/meeting-types';
import { isHost, isViewOnly } from '../../../../global/service/user-types';
import { isUseUnifiedRender } from '../../../../global/op-feature-option';
import {
  AVS_TAGS,
  avsLogReport,
} from '../../../../global/logger/log-service/avs-laplace-telemetry';
import { noAvailableCameraSelector } from '../selectors/camera-control-selector';
import { setShowVbInitType } from '../../../settings/redux/settings-action';
import { VB_INIT_TYPE } from '../../../settings/enum';

export function startVideo() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: {
        UI: { loading },
        activeCameraDevice,
      },
      meeting: {
        currentUser: { userRole, videoSsrc },
      },
    } = state;

    const isVideoEncodeReady = isVideoEncodeReadySelector(state);
    const noAvailableCamera = noAvailableCameraSelector(state);

    const isWb = isWebinar();
    const isViewOnlyUser = isViewOnly(userRole);
    if (isWb && isViewOnlyUser) {
      return false;
    }

    if (!isVideoEncodeReady || loading) {
      return false;
    }
    /*
    default device(as a menu item) is reserved by default,
    so the devices length should be greater than 1
    */
    if (noAvailableCamera) {
      promptA11yInfo(CAN_NOT_DETECT_CAMERA);
      AliveToast.toast(CAN_NOT_DETECT_CAMERA);
      dispatch(setVideoDecodeLoading(false));
      return false;
    }

    const videoTag = {};
    if (!isUseUnifiedRender()) {
      const renderTag = selfVideoRenderTagSelector(getState());
      dispatch(setCurrentVideoRenderTag(renderTag));
      videoTag.video = renderTag;
    }

    /**
     * first element of canvasList will be the current video stream output destination,
     * others will be preloaded so that the appropriate time can be used
     */
    const canvasList = [
      VIDEO_CANVAS.MAIN,
      VIDEO_CANVAS.MULTI_VIEW_VIDEO,
      VIDEO_CANVAS.SUSPENSION_VIDEO,
      VIDEO_CANVAS.INTERPRETER_VIDEO,
      VIDEO_CANVAS.MY_VIDEO,
    ];

    dispatch(setCameraCaptureLoading(true));
    /* after 5 seconds, if current user hasn't  started video,
        reset camera capture status
      */
    setTimeout(() => {
      if (!getState().meeting.currentUser.bVideoOn) {
        dispatch(setCameraCaptureLoading(false));
      }
    }, 5000);

    const dpiObject = getVideoDPI() || {};

    dispatch(setVideoActionInProgress(true));
    globalVariable.avSocket.sendSocket(START_CAPTURE_VIDEO, {
      mode: getVideoCaptureMode(),
      ssid: videoSsrc,
      width: dpiObject.width || 0,
      height: dpiObject.height || 0,
      fps: 24,
      canvas: canvasList,
      VideoSelectValue:
        activeCameraDevice && activeCameraDevice !== 'default'
          ? activeCameraDevice
          : null,
      ...(isMTRAndroid() ? { timeout: 15 * 1000 } : {}),
      ...getPTZCapability(),
      ...videoTag,
    });

    if (isExternalControlledMode()) {
      externalController.notifyConnectorZoomMsg(
        CONTROL_MODE_ZOOM_MSG_TYPE.UI,
        CONTROL_MODE_ZOOM_UI_ACTION_TYPE.START_VIDEO,
      );
    }
    return false;
  };
}

export function removeBackgroundMaskInitThunk() {
  return () => {
    if (globalVariable.unmountBackgroundFn) {
      globalVariable.unmountBackgroundFn();
    }
  };
}

export function startVideoWithInitVb() {
  return (dispatch, getState) => {
    const {
      settings: {
        vbBackground: { settingVideoInitSuccess },
        backgroundImage: { backgroundIdx, backgroundName },
        vbSettings: { enableVB, enableForceUseVB, vbList },
      },
      meeting: { zak, loginUserUid, fileReadZak },
    } = getState();
    // login user enableForceUseVB
    if (loginUserUid && enableForceUseVB) {
      if (enableVB) {
        dispatch(removeBackgroundMaskInitThunk());

        VirtualBackgroundInit.start({
          id: 'mask-vb-init-dom',
          backgroundIdx,
          backgroundName,
          settingVideoInitSuccess,
          zak,
          fileReadZak,
          loginUserUid,
          vbList,
          dispatch,
          onAfterFail: () => {
            dispatch(setIsNeedNotifyStartVideoFail(true));
            dispatch(setIsLoadingBackgroundFromStartVideo(false));
            dispatch(videoCannotStartNoticeThunk());
          },
        });
      } else {
        // fail flow =>  alert to open websetting
        dispatch(setIsLoadingBackgroundFromStartVideo(false));
        dispatch(openVBNoticeThunk());
      }
      // login user diasbledForceUseVB  has last operate record
    } else if (loginUserUid && isHasBackgroundRecord(backgroundIdx)) {
      if (enableVB) {
        dispatch(removeBackgroundMaskInitThunk());

        VirtualBackgroundInit.start({
          id: 'mask-vb-init-dom',
          backgroundIdx,
          backgroundName,
          settingVideoInitSuccess,
          zak,
          fileReadZak,
          loginUserUid,
          vbList,
          dispatch,
          onAfterFail: () => {
            dispatch(setIsLoadingBackgroundFromStartVideo(false));
            dispatch(startVideo());
          },
        });
      } else {
        dispatch(setIsLoadingBackgroundFromStartVideo(false));
        dispatch(startVideo());
      }
      // no login user  has operate record
    } else if (!loginUserUid && isHasBackgroundRecord(backgroundIdx)) {
      dispatch(removeBackgroundMaskInitThunk());

      VirtualBackgroundInit.start({
        id: 'mask-vb-init-dom',
        backgroundIdx,
        backgroundName,
        settingVideoInitSuccess,
        zak,
        fileReadZak,
        loginUserUid,
        vbList,
        dispatch,
        onAfterFail: () => {
          dispatch(setIsLoadingBackgroundFromStartVideo(false));
          dispatch(startVideo());
        },
      });
    } else {
      // do not has record
      dispatch(setIsLoadingBackgroundFromStartVideo(false));
      dispatch(startVideo());
    }
  };
}

export function startCaptureVideo() {
  //[3.5.0][user_action]user start capture video
  avsLogReport('user start capture video', [
    AVS_TAGS.user_action,
    AVS_TAGS.video_telemetry,
  ]);
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: {
        UI: { loading, isCameraCaptureLoading },
      },
      video: {
        isFirstOpenVideoInMeeting,
        isLoadingBackgroundFromStartVideo,
        isLoadingBackgroundFromSettings,
      },
      meeting: {
        bCanUnmuteVideo,
        currentUser: { bVideoMute: isCurrentUserVideoMuted, userRole, bCoHost },
      },
      settings: {
        vbBackground: { isVbPreReady },
      },
    } = state;
    const coOrHost = isHost(userRole) || bCoHost;
    const isVideoEncodeReady = isVideoEncodeReadySelector(state);

    if (!isVideoEncodeReady || loading) {
      return false;
    }

    if (isCameraCaptureLoading) {
      return false;
    }

    if (isLoadingBackgroundFromSettings) {
      dispatch(setShouldRecoverVideoOn(true));
      return false;
    }

    if (
      (isCurrentUserVideoMuted && !coOrHost) ||
      (!bCanUnmuteVideo && !coOrHost)
    ) {
      dispatch(
        muteVideo({
          type: MUTE_VIDEO_MODAL_STOP,
        }),
      );
      sendMsgToPWA(PWAMeetingEvent.BAN_VIDEO);
      return false;
    }

    if (isFirstOpenVideoInMeeting && hasBackground()) {
      if (isLoadingBackgroundFromStartVideo) {
        return false;
      }
      if (!isSupportVB()) {
        dispatch(setShowVbInitType(VB_INIT_TYPE.FAIL));
        clearBackground();
      } else if (isVbPreReady) {
        dispatch(setIsLoadingBackgroundFromStartVideo(true));
        dispatch(startVideoWithInitVb());
      }
    } else {
      dispatch(startVideo());
    }

    return false;
  };
}

/**
 * for sake of `startCaptureVideo` method will compute the in meeting participants count,
 * but it depend on next coming roster indication event (bHold:false),
 * so use timer to seize the right moment to start video
 */
export function startCaptureVideoAfterLeaveWaitingRoom() {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { bHold },
      },
    } = getState();
    if (!bHold) {
      dispatch(startCaptureVideo());
    } else {
      setTimeout(() => {
        dispatch(startCaptureVideoAfterLeaveWaitingRoom());
      }, 100);
    }
  };
}

export function stopCaptureVideo(from = 'user') {
  //[3.5.0][user_action]user stop capture video
  avsLogReport('user stop capture video', [
    AVS_TAGS.user_action,
    AVS_TAGS.video_telemetry,
  ]);
  return (dispatch, getState) => {
    const {
      video: { isCameraCaptureLoading, isCameraForbidden },
    } = getState();
    if (!isCameraCaptureLoading) {
      if (!isCameraForbidden) {
        dispatch(setCameraCaptureLoading(true));
        // should rest camera capture state when operation not success
        setTimeout(() => {
          if (getState().meeting.currentUser.bVideoOn) {
            dispatch(setCameraCaptureLoading(false));
          }
        }, 5000);
        dispatch(setCurrentVideoRenderTag(''));
        dispatch(setVideoActionInProgress(true));
        globalVariable.avSocket.sendSocket(STOP_CAPTURE_VIDEO, {});
        if (from === 'user') {
          dispatch(setAvStatusInSessionStorage({ video: 'mute' }));
        }
        if (isExternalControlledMode()) {
          externalController.notifyConnectorZoomMsg(
            CONTROL_MODE_ZOOM_MSG_TYPE.UI,
            CONTROL_MODE_ZOOM_UI_ACTION_TYPE.STOP_VIDEO,
          );
        }
      }
      dispatch(setCameraIsForbidden(false));
      dispatch(setCameraIsTaken(false));
    }
  };
}
