import { ChatEntity } from '@zoom/zdt_events/dist/entities/meetings/Chat';

const chatEntity = new ChatEntity();

const getChatType = (state) => {
  // const CHAT_MSG_TYPE_TO_ALL = 0; // chat to all
  // const CHAT_MSG_TYPE_TO_PANELIST = 1; // chat to all panelists
  // const CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST = 2; // chat to individual attendee and cc panelists
  // const CHAT_MSG_TYPE_TO_INDIVIDUAL = 3; // chat to individual
  // const CHAT_MSG_TYPE_TO_SILENT_MODE_USERS = 4; // chat to waiting room attendee
  //
  // const CHAT_MSG_TYPE_TO_INDIVIDUAL_SILENT_MODE_USER = 5; // chat to individual waiting room particpant
  // const CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS = 6; // particpant in WR chat to host/cohost
  const targetMap = {
    0: 'everyone',
    1: 'group',
    2: 'everyone',
    3: 'p2p',
    4: 'group',
    5: 'p2p',
    6: 'host',
  };
  return targetMap[state.chat?.receiverId] ?? 'everyone';
};

const getWhoCanBeTalked = (state) => {
  // const CHAT_PRIVILEDGE_ALL = 1; // everyone and anyone directly
  // const CHAT_PRIVILEDGE_ALL_PANELIST = 2; // host and panelist
  // const CHAT_PRIVILEDGE_HOST = 3; // host and co-host
  // const CHAT_PRIVILEDGE_NOONE = 4; // no one
  // const CHAT_PRIVILEDGE_EVERYONE_PUBLICLY = 5; // every one
  const targetMap = {
    1: 'everyone',
    2: 'everyone_and_anyone_directly',
    3: 'host_and_co_host',
    4: 'no_one',
    5: 'everyone',
  };
  return targetMap[state.meeting?.chatPriviledge] ?? 'everyone';
};
export const makeChatEntity = (state) => {
  chatEntity.chatSource = 'meeting_chat';
  chatEntity.chatType = getChatType(state);
  chatEntity.participantCanChatWith = getWhoCanBeTalked(state);
  return chatEntity;
};
