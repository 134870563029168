import { iText } from '../../global/util';
import { WB_ROLE } from './enum';

export const whiteboardsText = iText('Whiteboards', 'apac.whiteboards');

export const whiteboardsOptions = iText(
  'Share Whiteboards Options',
  'apac.whiteboards.options',
);

export const allowAccessText = iText(
  'Allow attendees to access after the meeting',
  'apac.whiteboards.isAllowAccessAfterMeeting',
);

export const allowAccessTipsText = iText(
  'Your account admin has placed restrictions on whiteboard sharing. Some attendees may not have access.',
  'apac.whiteboards.isAllowAccessAfterMeetingTips',
);

export const openWhiteboardBtn = iText(
  'Open and Collaborate',
  'apac.whiteboards.openWhiteboards',
);

export const editWhiteboardOption = iText(
  'All Participants can edit',
  'apac.whiteboards.editTips',
);

export const viewWhiteboardOption = iText(
  'All Participants can view',
  'apac.whiteboards.viewTips',
);

export const searchWhiteboards = iText(
  'Search Whiteboards',
  'apac.whiteboards.search',
);

export function displaySearchNoResult(searchValue) {
  return iText(
    `No search results matching '${searchValue}'`,
    'apac.whiteboards.searchNoResult',
    searchValue,
  );
}

export const wbLoadingFailed = iText(
  'Loading failed',
  'apac.whiteboards.wbLoadingFailed',
);

export const sharingWhiteboard = iText(
  'You are collaborating on this whiteboard',
  'apac.whiteboards.sharingWhiteboard',
);

export function receivingWhiteboard(role, userName) {
  return iText(
    `You are ${
      role === WB_ROLE.EDITOR ? 'co-editing' : 'viewing'
    } ${userName}'s whiteboard`,
    'apac.whiteboards.receivingWhiteboard',
    [userName],
  );
}

export const closeParticipantWhiteboardText = iText(
  "Close Participant's Whiteboard",
  'apac.whiteboards.closeParticipantWhiteboard',
);

export const closeWhiteboardText = iText(
  'Close Whiteboard',
  'apac.whiteboards.closeWhiteboard',
);

export const SHARE_WHITEBOARDS = iText(
  'Share Whiteboards',
  'apac.whiteboards.shareWhiteboards',
);

export const POP_UP_BOX_DISABLED_WB_DESC = iText(
  'Host disabled participant whiteboarding',
  'apac.whiteboards.disabledWhiteboards',
);

export const POP_UP_BOX_DISABLED_WB2_DESC = iText(
  'You cannot open a whiteboard when a whiteboard is already open in-meeting.',
  'apac.whiteboards.disabledWhiteboardsForAnother',
);

export const POP_UP_BOX_START_WHITEBOARD_DESC = iText(
  'Opening another whiteboard will close the current whiteboard. Do you want to continue?',
  'apac.whiteboards.stopAndStartNewWhiteboards',
);

export const WHITEBOARD_SESSION_IN_PROGRESS = iText(
  'Whiteboard session in progress',
  'apac.whiteboards.wbSessionInProgress',
);

export const CLOSE_WHITEBOARD_AND_START_SHARING = iText(
  'Sharing your screen will close the whiteboard for everyone. Do you want to continue?',
  'apac.whiteboards.closeWBAndStartShare',
);

export const SCREEN_SHARE_IN_PROGRESS = iText(
  'Screen share in progress',
  'apac.whiteboards.screenShareInProgress',
);

export const CLOSE_SHARING_AND_START_WHITEBOARD = iText(
  'Opening a whiteboard will stop the screen share. Do you want to continue?',
  'apac.whiteboards.closeShareAndStartWB',
);

export const DISABLED_WHITEBOARD_IN_SHARING = iText(
  'You cannot open a whiteboard during a screen share.',
  'apac.whiteboards.disabledWBInSharing',
);

export const DISABLED_WHITEBOARD_IN_SHARING_TO_BO = iText(
  'Unable to share your whiteboard while someone is sharing from the main session',
  'apac.whiteboards.disabledWBInSharingToBo',
);

export const DISABLED_SHARING_IN_WHITEBOARD = iText(
  'You cannot start screen sharing while collaborating on a whiteboard.',
  'apac.whiteboards.disabledSharingInWB',
);

export const WB_CONNECTION_ERROR = iText(
  'Connection Error',
  'apac.whiteboards.wbConnectionError',
);

export const WB_LOAD_ERROR = iText(
  'Unable to load this whiteboard',
  'apac.whiteboards.wbLoadError',
);

export const WB_SIMULTANEOUS_ERROR_TITLE = iText(
  'Your whiteboard collaboration session has ended',
  'apac.whiteboards.wbSimultaneousErrorTitle',
);

export const WB_SIMULTANEOUS_ERROR_CONTENT = iText(
  'Simultaneous screen sharing is not supported with whiteboard collaboration.',
  'apac.whiteboards.wbSimultaneousErrorContent',
);

export const ZOOM_WHITEBOARD_TEXT = iText(
  'Zoom Whiteboard',
  'apac.whiteboards.zoomWB',
);

export const NEW_WHITEBOARD_FEATURE_TEXT = iText(
  'Create and collaborate in and out of meetings with the new Whiteboard tool!',
  'apac.whiteboards.newWBFeature',
);

export const LOAD_WHITEBOARD_TEXT = iText(
  'Loading your whiteboard experience',
  'apac.whiteboards.loadWBText',
);

export const LOADING_WHITEBOARD_TEXT = iText(
  'Loading your whiteboards',
  'apac.whiteboards.loadingWBText',
);

export const ALLOW_SHARE_WB_TEXT = iText(
  'Allow participants to share whiteboard ',
  'apac.whiteboards.allowShareWBText',
);

export const ALLOW_SHARE_WB_TEXT_WEBINAR = iText(
  'Allow Panelists to share whiteboard ',
  'apac.whiteboards.allowShareWBTextWebinar',
);

export const ALLOW_SHARE_WB_TEXT_TIPS = iText(
  'By disabling this setting, nobody else except the host can share whiteboards during this meeting.',
  'apac.whiteboards.allowShareWBTextTips',
);

export const ALLOW_SHARE_WB_TEXT_TIPS_WEBINAR = iText(
  'By disabling this setting, nobody else except the host can share whiteboards during this webinar.',
  'apac.whiteboards.allowShareWBTextTipsWebinar',
);

export const SHARE_WB_LOCK_TEXT = iText(
  'The administrator has locked this setting and you cannot change it. All of your in-meeting whiteboard will use this setting.',
  'apac.whiteboards.shareWBLockText',
);

export const INITIATE_NEW_WB_TEXT = iText(
  'Who can initiate new whiteboard in the meeting?',
  'apac.whiteboards.initiateNewWBText',
);

export const INITIATE_NEW_WB_TEXT_WEBINAR = iText(
  'Who can initiate new whiteboard in the webinar?',
  'apac.whiteboards.initiateNewWBTextWebinar',
);

export const INITIATE_NEW_WB_TEXT_TIPS = iText(
  'All whiteboards created in the meeting will belong to the host.',
  'apac.whiteboards.initiateNewWBTextTips',
);

export const INITIATE_NEW_WB_TEXT_TIPS_WEBINAR = iText(
  'All whiteboards created in the webinar will belong to the host.',
  'apac.whiteboards.initiateNewWBTextTipsWebinar',
);

export const INTERNAL_USERS_TEXT = iText(
  'Internal users',
  'apac.whiteboards.internalUsers',
);

export const INTERNAL_USERS_TEXT_WEBINAR = iText(
  'Host and Internal panelists',
  'apac.whiteboards.internalUsersWebinar',
);

export const ALLOW_SHARE_WB_WITHOUT_HOST = iText(
  'Allow participants initiate new whiteboard without host in the meeting.',
  'apac.whiteboards.allowShareWBWithoutHost',
);

export const ALLOW_SHARE_WB_WITHOUT_HOST_WEBINAR = iText(
  'Allow panelists initiate new whiteboard without host in the webinar.',
  'apac.whiteboards.allowShareWBWithoutHostWebinar',
);

export const ALL_CAN_SHARE_WB = iText(
  'All participants can share whiteboard',
  'apac.whiteboards.allCanShareWb',
);

export const ALL_CAN_SHARE_WB_WEBINAR = iText(
  'All panelists can share whiteboard',
  'apac.whiteboards.allCanShareWbWebinar',
);

export const ONLY_HOST_CAN_SHARE_WB = iText(
  'Only host can share whiteboard',
  'apac.whiteboards.onlyHostCanShareWb',
);

export const DISABLE_ALLOW_WB = iText(
  "This option is disabled because you disabled 'Allow participants to share whiteboard'",
  'apac.whiteboards.disableAllowWb',
);

export const DISABLE_ALLOW_WB_WITHOUT_HOST = iText(
  "This option is disabled because you choose 'only host can initiate new whiteboard in the meeting'",
  'apac.whiteboards.disableAllowWbWhitoutHost',
);
