/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import AliveToast from '../../../../global/containers/notification-manager/alive-toast';
import {
  cdnBackgroundlist,
  FILE_SERVER_TYPE,
  PUBLIC_LOACL_CUSTOM_IMAGE,
  CDN_BACKGROUND_IMAGE,
} from '../../enum';
import {
  formatCDNImageList,
  formatCloudImageList,
  formatLocalImageList,
  getAllLocalImage,
  initResourceManager,
  loadCDNImage,
  loadLocalImage,
  isSupportGuestCustomUpload,
} from '../../service';
import {
  videoVbSettingThunk,
  finishVbSettingThunk,
  saveBackgroundStatusThunk,
  setBackgroundImageThunk,
} from '../../redux/settings-thunk-action';
import { DOWNLOAD_FAILED_IMAGE } from '../../resource';
import ProtectedCanvas from '../../../../global/components/ProtectedCanvas';
import { globalVariable } from '../../../../global/global-variable';
import { Provider } from 'react-redux';
import { makeLogger } from '../../../../global/logger';
import { createRoot } from 'react-dom/client';

const CANVAS_WIDTH = 379;
const CANVAS_HEIGHT = 213;
const AUTO_HIDE_TIME = 3000;

const videologger = makeLogger(['Video']);
const VIDEO_LOG = {
  RECOVER_VB_FAIL: 'recover VB,fail to download all background image',
};

const Temp = (props) => {
  const {
    vbList,
    backgroundIdx,
    backgroundName,
    zak,
    fileReadZak,
    loginUserUid,
    close,
    onAfterFail,
    onAfterSuccess,
    dispatch,
  } = props;

  const [backgroundURL, setBackgroundURL] = useState('');

  const backgroundCountRef = useRef(-1);
  const dynamicPropRef = useRef(null);
  dynamicPropRef.current = {
    backgroundIdx,
  };

  const loadBackgroundImage = ({ autoChange, currentId }) => {
    return new Promise((resolve) => {
      getAllLocalImage(loginUserUid).then((res) => {
        const cdnList = formatCDNImageList(cdnBackgroundlist).filter(
          (item) => item.id === 'blur',
        );
        const localList = formatLocalImageList(res);
        const cloudList = formatCloudImageList(vbList);
        const allList = [...cdnList, ...cloudList, ...localList];
        if (!allList.length) {
          if (onAfterFail) {
            videologger.log(VIDEO_LOG.RECOVER_VB_FAIL);
            onAfterFail(new Error('fail_background_all_not_found'));
          }
          close();
          return;
        }

        let imageObject;
        if (autoChange) {
          if (backgroundCountRef.current >= allList.length) {
            if (onAfterFail) {
              videologger.log(VIDEO_LOG.RECOVER_VB_FAIL);
              onAfterFail(new Error('fail_background_all_not_found'));
            }
            close();
            return;
          }
          imageObject = allList[backgroundCountRef.current];
          if (imageObject.id === 'blur') {
            resolve(imageObject.id);
          }
        } else {
          imageObject = [...cdnList, ...cloudList, ...localList].find(
            (item) => item.id === currentId,
          );
        }

        if (!imageObject) {
          backgroundCountRef.current += 1;

          loadBackgroundImage({ autoChange: true })
            .then((res) => {
              setBackgroundURL(res);
            })
            .catch((e) => {
              if (onAfterFail) {
                onAfterFail(e);
              }
              close();
            });
          return;
        }

        let zakToken = '';

        if (imageObject.fsType === FILE_SERVER_TYPE.FILE_SERVER) {
          zakToken =
            imageObject.isAdminUpload || imageObject.isDef ? fileReadZak : zak;
        }

        const headers = {};

        if (zakToken) {
          headers.zak = zakToken;
        }

        initResourceManager(imageObject.isLocal ? loginUserUid : '').then(
          (resourceManager) => {
            resourceManager
              .getFile(imageObject.previewBig, {
                saveRootPath: imageObject.previewBig,
                downloadOptions: {
                  xhrParams: {
                    headers,
                    url: imageObject.previewBig,
                    encoding: 1,
                    method: 'GET',
                  },
                },
              })
              .getResult()
              .then((res) => {
                const objectURL = URL.createObjectURL(res.data);
                const imageDom = document.getElementById('backgroundTemp');
                imageDom.src = objectURL;
                imageDom.onload = () => {
                  dispatch(
                    setBackgroundImageThunk({
                      id: imageObject.id,
                      name: imageObject.displayName,
                    }),
                  );

                  dispatch(
                    saveBackgroundStatusThunk({
                      loginUserUid,
                      backgroundIdx: imageObject.id,
                      backgroundName: imageObject.displayName,
                    }),
                  );
                  resolve(objectURL);
                };
              })
              .catch(() => {
                if (!autoChange && backgroundName) {
                  AliveToast.toast({
                    message: `${DOWNLOAD_FAILED_IMAGE} ${backgroundName}`,
                    aliveTime: AUTO_HIDE_TIME,
                  });
                }
                backgroundCountRef.current += 1;

                loadBackgroundImage({ autoChange: true })
                  .then((res) => {
                    setBackgroundURL(res);
                  })
                  .catch((e) => {
                    if (onAfterFail) {
                      onAfterFail(e);
                    }
                    close();
                  });
              });
          },
        );
      });
    });
  };

  const loadCDNBackgroundImage = ({ autoChange, currentId }) => {
    return new Promise((resolve, reject) => {
      getAllLocalImage(PUBLIC_LOACL_CUSTOM_IMAGE).then((res) => {
        const localList = formatLocalImageList(res);
        const allList = isSupportGuestCustomUpload()
          ? [...cdnBackgroundlist, ...localList]
          : cdnBackgroundlist;

        let imageObject;
        if (autoChange) {
          if (backgroundCountRef.current > allList.length) {
            videologger.log(VIDEO_LOG.RECOVER_VB_FAIL);
            reject(new Error('fail_background_all_not_found'));
            return;
          }
          imageObject = allList[backgroundCountRef.current];
          if (imageObject.id === 'blur') {
            resolve(imageObject.id);
          }
        } else {
          imageObject = allList.find((item) => item.id === currentId);
        }

        if (!imageObject) {
          if (!autoChange && backgroundName) {
            AliveToast.toast({
              message: `${DOWNLOAD_FAILED_IMAGE} ${backgroundName}`,
              aliveTime: AUTO_HIDE_TIME,
            });
          }

          backgroundCountRef.current += 1;
          loadCDNBackgroundImage({ autoChange: true })
            .then((res) => {
              setBackgroundURL(res);
            })
            .catch((e) => {
              if (onAfterFail) {
                onAfterFail(e);
              }
              close();
            });
          return;
        }

        let loadImageCallBack = loadCDNImage;
        if (imageObject.isLocal) {
          loadImageCallBack = loadLocalImage;
        }
        initResourceManager(
          imageObject.isLocal ? PUBLIC_LOACL_CUSTOM_IMAGE : '',
        ).then((resourceManager) => {
          loadImageCallBack({
            resourceManager,
            imageObject,
            folderName: CDN_BACKGROUND_IMAGE,
          }).then((res) => {
            if (res.previewSmallStatus === 'success') {
              const imageDom = document.getElementById('backgroundTemp');
              imageDom.src = res.displayURL;
              imageDom.onload = () => {
                resolve(res.displayURL);
              };
            } else {
              if (!autoChange && backgroundName) {
                AliveToast.toast({
                  message: `${DOWNLOAD_FAILED_IMAGE} ${backgroundName}`,
                  aliveTime: AUTO_HIDE_TIME,
                });
              }

              backgroundCountRef.current += 1;
              loadCDNBackgroundImage({ autoChange: true })
                .then((res) => {
                  setBackgroundURL(res);
                })
                .catch((e) => {
                  if (onAfterFail) {
                    onAfterFail(e);
                  }
                  close();
                });
            }
          });
        });
      });
    });
  };

  const initLoginUserImage = (currentId) => {
    loadBackgroundImage({ autoChange: false, currentId })
      .then((res) => {
        setBackgroundURL(res);
      })
      .catch((e) => {
        if (onAfterFail) {
          onAfterFail(e);
        }
        close();
      });
  };

  const initNoLoginUserImage = (currentId) => {
    loadCDNBackgroundImage({ autoChange: false, currentId })
      .then((res) => {
        setBackgroundURL(res);
      })
      .catch((e) => {
        if (onAfterFail) {
          onAfterFail(e);
        }
        close();
      });
  };

  useEffect(() => {
    const { backgroundIdx } = dynamicPropRef.current;
    if (backgroundIdx === 'blur') {
      setBackgroundURL(backgroundIdx);
    } else if (loginUserUid) {
      initLoginUserImage(backgroundIdx);
    } else {
      initNoLoginUserImage(backgroundIdx);
    }

    return () => {
      dispatch(
        finishVbSettingThunk({
          isCloseCamera: false,
          isSwitch: false,
        }),
      );
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (!backgroundURL) {
      return;
    }

    setTimeout(() => {
      dispatch(
        videoVbSettingThunk({
          canvas: 'maskbackgroundCanvasTemp',
          bgdom: backgroundURL === 'blur' ? 'blur' : 'backgroundTemp',
          isOpenCamera: true,
        }),
      );
      if (onAfterSuccess) {
        onAfterSuccess();
      }
    }, 0);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [backgroundURL]);

  const store = useContext(globalVariable.storeContext.getContext());

  return (
    <div style={{ display: 'none' }}>
      <Provider store={store}>
        <ProtectedCanvas
          id="maskbackgroundCanvasTemp"
          width={CANVAS_WIDTH}
          height={CANVAS_HEIGHT}
        />
      </Provider>
      <img alt="" id="backgroundTemp" src="" />
    </div>
  );
};

const VirtualBackgroundInit = Temp;

function start(config) {
  const div = document.createElement('div');

  if (config.id) {
    div.setAttribute('id', config.id);
  }

  document.body.appendChild(div);
  const root = createRoot(div);

  function render(props) {
    const { ref, ...restProps } = props;
    root.render(<VirtualBackgroundInit ref={ref} {...restProps} />);
  }

  function close() {
    setTimeout(() => {
      root.unmount();
      if (div.parentNode) {
        div.parentNode.removeChild(div);
      }
    }, 0);
  }

  const currentConfig = { ...config, close };

  render(currentConfig);
  globalVariable.unmountBackgroundFn = close;
}

VirtualBackgroundInit.start = start;

export default VirtualBackgroundInit;
