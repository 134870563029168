/*
[Usage Doc] How to implement lazy load for web-client: https://zoomvideo.atlassian.net/wiki/spaces/~6278bc1023d61e006fc3a01b/pages/2526683236/How+to+implement+lazy+load+for+web-client#3.-Why-some-CSS-style-disordered-after-LazyLoad?
[RFC] https://zoomvideo.atlassian.net/wiki/spaces/~6278bc1023d61e006fc3a01b/pages/2464973276/Request+For+Comments+Doc+User+Experience+Optimization+for+web-client
*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withErrorBoundary from '../global/components/hoc/error-boundary.js';
import Loading from './Loading';

// load() function should return a React Component Object,
// instead of a plain JS function, A simple solution is add `connect()(YourFunctionComponent)`
function LazyLoader({ load, fallback = <Loading />, ...props }) {
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    if (!Component && load) {
      load((exports) => {
        setComponent(exports?.default);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use JS logic instead of <Subspense />
  // Because we want to avoid fallback:
  // https://reactjs.org/docs/code-splitting.html#avoiding-fallbacks
  return Component ? <Component {...props} /> : fallback;
}
LazyLoader.propTypes = {
  load: PropTypes.func,
  fallback: PropTypes.element,
  children: PropTypes.element,
};

export default withErrorBoundary(LazyLoader);
