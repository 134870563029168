import { iText } from '../../global/util';

export const REPORT_PARTICIPANT_WINDOW_TITLE = iText(
  'Report',
  'apac.wc_security.report_title',
);
export const LOCK_MEETING = iText('Lock Meeting', 'apac.dialog.lock_meeting');
export const UNLOCK_MEETING = iText('Unlock Meeting', 'apac.wc_unlock_meeting');
export const ENABLE_WAITING_ROOM = iText(
  'Enable Waiting Room',
  'apac.security.waiting_room',
);
export const ALLOW_PARTICIPANTS_TO = iText(
  'Allow participants to:',
  'apac.security.allow_participants_to',
);
export const SHARE_SCREEN = iText(
  'Share Screen',
  'apac_wc_sharer_entry_button_label1',
);
export const CHAT = iText('Chat', 'apac.wc_chat.chat');
export const RENAME_THEMSELVES = iText(
  'Rename Themselves',
  'apac.security.rename_themselves',
);
export const REMOVE_PARTICIPANT = iText(
  'Remove Participant...',
  'apac.security.remove_participant',
);
export const REPORT = iText('Report...', 'apac.security.report');
export const HOST_TOOLS = iText('Host Tools', 'apac.security.button');

export const REPORT_SELECT_REPOTER_TITLE = iText(
  'Who do you want to report?',
  'apac.wc_security.report_select_reporter_title',
);

export const REPORT_PROBLEM_TITLE = iText(
  'What was the problem?',
  'apac.wc_security.report_problem_title',
);

export const REPORT_PROBLEM1 = iText(
  'Inappropriate screen sharing',
  'apac.wc_security.report_problem1',
);
export const REPORT_PROBLEM2 = iText(
  'Inappropriate video',
  'apac.wc_security.report_problem2',
);
export const REPORT_PROBLEM3 = iText(
  'Uninvited guest',
  'apac.wc_security.report_problem3',
);
export const REPORT_PROBLEM4 = iText(
  'Abusive conduct',
  'apac.wc_security.report_problem4',
);
export const REPORT_PROBLEM5 = iText(
  'Intellectual property violation',
  'apac.wc_security.report_problem5',
);
export const REPORT_PROBLEM6 = iText(
  'Other',
  'apac.wc_security.report_problem6',
);

export const REPORT_COMMENTS_TITLE = iText(
  'Additional Information',
  'apac.wc_security.report_comments_title',
);
export const REPORT_ADDITIONNAL_COMMENTS = iText(
  'Additional comments',
  'apac.wc_security.report_additional_comments',
);
export const REPORT_SEND_BTN = iText('Send', 'apac.wc_security.report_send');

export const REPORT_PRIVACY_POLICY = iText(
  'Privacy Policy',
  'apac.wc_security.report_privacy_policy',
);

export const PRIVACY_LEGAL_POLICIES = iText(
  'Privacy & Legal Policies',
  'apac.wc_security.privacy_legal_policies',
);

export const REPORT_ABUSE = iText(
  'Report Abuse',
  'apac.wc_security.report_abuse',
);

export const privacyPolicyText = (placeholder) =>
  iText(
    `By sending this report, you authorize Zoom to access all data in this
    report, subject to Zoom’s ${placeholder}. This data includes all
    attached files and screenshots, your user information, the user
    information of those you report, and all relevant meeting information.`,
    'apac.wc_security.report_all_privacy_policy',
    placeholder,
  );

export const REPORT_FILE_SIZE_LIMIT_ERROR1 = iText(
  'Your file exceeds the 25MB limit',
  'apac.wc_security.report_file_size_limit_error1',
);
export const REPORT_FILE_SIZE_LIMIT_ERROR2 = iText(
  'Your files exceed the 25MB limit',
  'apac.wc_security.report_file_size_limit_error2',
);

export const REPORT_FILE_UPLOAD_ERROR = iText(
  'Upload failed,please try again',
  'apac.wc_security.report_file_upload_error',
);

export const REPORT_FILE_FORMAT_ERROR = iText(
  'The format is not supported',
  'apac.wc_security.report_file_format_error',
);

export const REPORT_PARTICIPANTS_LEFT_LABEL = iText(
  'Left/Removed',
  'apac.wc_security.report_participants_left_label',
);

export const REPORT_FEEDBACK_TITLE = iText(
  'Thanks for reporting the incident',
  'apac.wc_security.report_feedback_title',
);

export const REPORT_FEEDBACK_TIPS = (placeholder) =>
  iText(
    `We will investigate further and act based on our results.${placeholder} about ways to secure your meeting.`,
    'apac.wc_security.report_feedback_tips',
    placeholder,
  );
export const UNMUTE_THEMSELVES = iText(
  'Unmute Themselves',
  'apac.security.unmute_themselves',
);

export const HIDE_USER_AVATARS_TEXT = iText(
  'Hide Profile Pictures',
  'apac.security.report_hide_user_avatars',
);

export const DISPLAY_USER_AVATARS_TEXT = iText(
  'Display Profile Pictures',
  'apac.security.report_display_user_avatars',
);

export const SUSPEND_USER_ACTIVITIES_TEXT = iText(
  'Suspend Participant Activities',
  'apac.security.report_suspend_user_activities',
);

export const REPORT_TO_ZOOM_TEXT = iText(
  'Report to Zoom',
  'apac.security.report_to_zoom',
);

export const SUSPEND_ALL_ACTIVITIES_TITLE_TEXT = iText(
  'Suspend all participant activities?',
  'apac.security.report_suspend_activities_title',
);

export const SUSPEND_DIALOG_CONTENT_TEXT = iText(
  'Everyone’s video and audio will be turned off, screen sharing will stop, and the meeting will be locked.',
  'apac.security.report_suspend_diaalog_content',
);

export const SUSPEND_BUTTON_TEXT = iText(
  'Suspend',
  'apac.security.report_suspend_btn',
);

export const REPORT_FEEDBACK_NEW_TITLE = iText(
  'Thank you for submitting a report',
  'apac.wc_security.report_feedback_new_title',
);

export const REPORT_NEW_FEEDBACK_TIPS = iText(
  'Our Trust & Safety team will send you an email after the meeting to learn more about the incident.',
  'apac.wc_security.report_feedback_new_tips',
);

export const REPORT_WHAT_HAPPENED_TITLE = iText(
  'What happened?',
  'apac.wc_security.report_what_happened',
);

export const YOUR_EMAIL_ADDRESS_TEXT = iText(
  'Your email address',
  'apac.wc_security.report_email_address',
);

export const INCLUDE_DESKTOP_SCREENSHOT_TEXT = iText(
  'Include desktop screenshot',
  'apac.wc_security.report_include_desktop_screenshot',
);

export const VIEW_SCREENSHOT_TEXT = iText(
  'View Screenshot',
  'apac.wc_security.report_view_screenshot',
);

export const REPORT_PROBLEM_11 = iText(
  'Impersonation',
  'apac.wc_security.report_problem11',
);
export const REPORT_PROBLEM_10 = iText(
  'Copyright or trademark infringement',
  'apac.wc_security.report_problem10',
);
export const REPORT_PROBLEM_9 = iText(
  'Spam',
  'apac.wc_security.report_problem9',
);
export const REPORT_PROBLEM_8 = iText(
  'Private information',
  'apac.wc_security.report_problem8',
);
export const REPORT_PROBLEM_7 = iText(
  'Suicide or self-harm',
  'apac.wc_security.report_problem7',
);
export const REPORT_PROBLEM_6 = iText(
  'Offensive, illegal, or abusive',
  'apac.wc_security.report_problem6',
);
export const REPORT_PROBLEM_5 = iText(
  'Copyright or trademark infringement',
  'apac.wc_security.report_problem5',
);
export const REPORT_PROBLEM_0 = iText(
  'Other / I’ll tell you later',
  'apac.wc_security.report_problem1',
);

export const REPORT_BTN_CANCEL_TEXT = iText(
  'Don’t Report',
  'apac.wc_security.report_cancel_btn',
);

export const REPORT_REMOVE_USERS_TIP = iText(
  'Reported users will be removed from your meeting',
  'apac.wc_security.report_remove_users_tip',
);
export const newPrivacyPolicyText = (placeholder) =>
  iText(
    `  By sending this report, you authorize Zoom to access all data in this report,
     subject to Zoom’s  ${placeholder}. This data includes screenshots, your user information, the user information of those you report, and all relevant meeting information.`,
    'apac.wc_security.report_all_privacy_policy_new',
    placeholder,
  );

export const REPORT_PRIVACY_STATEMENT = iText(
  'Privacy Statement',
  'apac.wc_security.report_privacy_statement',
);

export const SUSPEND_SUCCESS_TIP = iText(
  'Participant activities have been suspended',
  'apac.wc_security.report_suspend_success',
);

export const SEND_FEEDBACK = iText(
  'Send Feedback',
  'apac.wc_security.report_send_feedback',
);

export const SEND_REPORT = iText('Send Report', 'apac.wc_security.send_report');
