import { createActions, createAction } from 'redux-actions';

const SET_SIMU_LIVE_MUTE = 'SET_SIMU_LIVE_MUTE';
const SET_ASN_IDS = 'SET_ASN_IDS';
const AUDIO_RESET = 'AUDIO_RESET';
const SET_ACTIVE_MICROPHONE = 'SET_ACTIVE_MICROPHONE';
const SET_ACTIVE_SPEAKER = 'SET_ACTIVE_SPEAKER';
const SET_AUDIO_MENU_VISIBLE = 'SET_AUDIO_MENU_VISIBLE';
const SET_MICROPHONE_DEVICES_LIST = 'SET_MICROPHONE_DEVICES_LIST';
const SET_SPEAKER_DEVICES_LIST = 'SET_SPEAKER_DEVICES_LIST';
const SET_MICROPHONE_IS_FORBIDDEN = 'SET_MICROPHONE_IS_FORBIDDEN';

const SET_SELECTED_JOIN_TAB = 'SET_SELECTED_JOIN_TAB';
const SET_JOIN_AUDIO_DIALOG_VISIBLE_STATE =
  'SET_JOIN_AUDIO_DIALOG_VISIBLE_STATE';
const SET_JOIN_VOIP_LOADING = 'SET_JOIN_VOIP_LOADING';
const SET_DIAL_OUT_IS_SUCCESS = 'SET_DIAL_OUT_IS_SUCCESS';
const SET_DIAL_OUT_SEQUENCE = 'SET_DIAL_OUT_SEQUENCE';
const SET_DIAL_OUT_RETURN_CODE = 'SET_DIAL_OUT_RETURN_CODE';
const SET_DIAL_OUT_SELECTED_COUNTRY = 'SET_DIAL_OUT_SELECTED_COUNTRY';
const SET_DIAL_OUT_PHONE_NUMBER = 'SET_DIAL_OUT_PHONE_NUMBER';
const SET_REMEMBER_PHONE_NUMBER_CHECKED = 'SET_REMEMBER_PHONE_NUMBER_CHECKED';
const SET_HOST_MUTED_ALL = 'SET_HOST_MUTED_ALL';
const SET_SELF_MUTE_OR_UNMUTE = 'SET_SELF_MUTE_OR_UNMUTE';
const SET_SELF_HANG_UP = 'SET_SELF_HANG_UP';

const SET_AUDIO_SSRC_INDICATION = 'SET_AUDIO_SSRC_INDICATION';
const SET_WEBINAR_ATTENDEE_PHONE_INDICATION =
  'SET_WEBINAR_ATTENDEE_PHONE_INDICATION';
const SET_IS_ALLOW_TO_TALK_UNMUTE_IN_PROGRESS =
  'SET_IS_ALLOW_TO_TALK_UNMUTE_IN_PROGRESS';
const SET_IS_JOIN_COMPUTER_AUDIO_IN_PROGRESS =
  'SET_IS_JOIN_COMPUTER_AUDIO_IN_PROGRESS';
const SET_CONTROLLER_DISABLE_AUDIO = 'SET_CONTROLLER_DISABLE_AUDIO';
const SET_DESKTOP_AUDIO_STATUS = 'SET_DESKTOP_AUDIO_STATUS';
const SET_IS_LEAVING_AUDIO_FOR_SHARING_DESKTOP_AUDIO =
  'SET_IS_LEAVING_AUDIO_FOR_SHARING_DESKTOP_AUDIO';
const SET_IS_GUARDING_AUDIO_CONTEXT_CONFLICT =
  'SET_IS_GUARDING_AUDIO_CONTEXT_CONFLICT';
const SET_SHOULD_UNMUTE_AFTER_FINISH_SHARING_DESKTOP_AUDIO =
  'SET_SHOULD_UNMUTE_AFTER_FINISH_SHARING_DESKTOP_AUDIO';
const SET_MUTE_UNMUTE_ACTION_IN_PROGRESS = 'SET_MUTE_UNMUTE_ACTION_IN_PROGRESS';
const SET_CLIENT_ENABLE_AUTO_JOIN_AUDIO = 'SET_CLIENT_ENABLE_AUTO_JOIN_AUDIO';
const SET_CLIENT_ENABLE_MUTE_AUDIO_WHEN_JOIN_MEETING =
  'SET_CLIENT_ENABLE_MUTE_AUDIO_WHEN_JOIN_MEETING';
const SET_CLIENT_ENABLE_HOLD_SPACE_KEY_TO_UNMUTE =
  'SET_CLIENT_ENABLE_HOLD_SPACE_KEY_TO_UNMUTE';
const AUDIO_STATUS_SENT = 'AUDIO_STATUS_SENT';
const SET_CLIENT_ENABLE_SYNC_BUTTONS_ON_HEADSET =
  'SET_CLIENT_ENABLE_SYNC_BUTTONS_ON_HEADSET';
const SET_DENOISE_ENABLED = 'SET_DENOISE_ENABLED';
const SET_STEREO_ENABLED = 'SET_STEREO_ENABLED';
const CHANGE_AUDIO_PROFILE = 'CHANGE_AUDIO_PROFILE';
const DEVICE_CHANGE = 'DEVICE_CHANGE';
const SET_MIC_MUTED_BY_SYSTEM = 'SET_MIC_MUTED_BY_SYSTEM';

export const {
  setSimuLiveMute,
  setAsnIds,
  audioReset,
  setActiveMicrophone,
  setActiveSpeaker,
  setAudioMenuVisible,
  setMicrophoneDevicesList,
  setSpeakerDevicesList,
  setMicrophoneIsForbidden,
  setSelectedJoinTab,
  setJoinAudioDialogVisibleState,
  setJoinVoipLoading,
  setDialOutSequence,
  setDialOutReturnCode,
  setDialOutSelectedCountry,
  setDialOutPhoneNumber,
  setRememberPhoneNumberChecked,
  setHostMutedAll,
  setSelfMuteOrUnmute,
  setSelfHangUp,
  setAudioSsrcIndication,
  setWebinarAttendeePhoneIndication,
  setIsAllowToTalkUnmuteInProgress,
  setIsJoinComputerAudioInProgress,
  setControllerDisableAudio,
  setDesktopAudioStatus,
  setIsLeavingAudioForSharingDesktopAudio,
  setIsGuardingAudioContextConflict,
  setShouldUnmuteAfterFinishSharingDesktopAudio,
  setMuteUnmuteActionInProgress,
  setClientEnableAutoJoinAudio,
  setClientEnableMuteAudioWhenJoinMeeting,
  setClientEnableHoldSpaceKeyToUnmute,
  audioStatusSent,
  setClientEnableSyncButtonsOnHeadset,
  setDenoiseEnabled,
  setStereoEnabled,
  changeAudioProfile,
} = createActions({
  [SET_SIMU_LIVE_MUTE]: (payload) => payload,
  [SET_ASN_IDS]: (payload) => payload,
  [AUDIO_RESET]: (payload) => payload,
  [SET_ACTIVE_MICROPHONE]: (payload) => payload,
  [SET_ACTIVE_SPEAKER]: (payload) => payload,
  [SET_AUDIO_MENU_VISIBLE]: (payload) => payload,
  [SET_MICROPHONE_DEVICES_LIST]: (payload) => payload,
  [SET_SPEAKER_DEVICES_LIST]: (payload) => payload,
  [SET_MICROPHONE_IS_FORBIDDEN]: (payload) => payload,
  [SET_DIAL_OUT_IS_SUCCESS]: (payload) => payload,
  [SET_SELECTED_JOIN_TAB]: (payload) => payload,
  [SET_JOIN_AUDIO_DIALOG_VISIBLE_STATE]: (payload) => payload,
  [SET_JOIN_VOIP_LOADING]: (payload) => payload,
  [SET_DIAL_OUT_SEQUENCE]: (payload) => payload,
  [SET_DIAL_OUT_RETURN_CODE]: (payload) => payload,
  [SET_DIAL_OUT_SELECTED_COUNTRY]: (payload) => payload,
  [SET_DIAL_OUT_PHONE_NUMBER]: (payload) => payload,
  [SET_REMEMBER_PHONE_NUMBER_CHECKED]: (payload) => payload,
  [SET_HOST_MUTED_ALL]: (payload) => payload,
  [SET_SELF_MUTE_OR_UNMUTE]: (payload) => payload,
  [SET_SELF_HANG_UP]: (payload) => payload,
  [SET_AUDIO_SSRC_INDICATION]: (payload) => payload,
  [SET_WEBINAR_ATTENDEE_PHONE_INDICATION]: (payload) => payload,
  [SET_IS_ALLOW_TO_TALK_UNMUTE_IN_PROGRESS]: (payload) => payload,
  [SET_IS_JOIN_COMPUTER_AUDIO_IN_PROGRESS]: (payload) => payload,
  [SET_CONTROLLER_DISABLE_AUDIO]: (payload) => payload,
  [SET_DESKTOP_AUDIO_STATUS]: (payload) => payload,
  [SET_IS_LEAVING_AUDIO_FOR_SHARING_DESKTOP_AUDIO]: (payload) => payload,
  [SET_IS_GUARDING_AUDIO_CONTEXT_CONFLICT]: (payload) => payload,
  [SET_SHOULD_UNMUTE_AFTER_FINISH_SHARING_DESKTOP_AUDIO]: (payload) => payload,
  [SET_MUTE_UNMUTE_ACTION_IN_PROGRESS]: (payload) => payload,
  [SET_CLIENT_ENABLE_AUTO_JOIN_AUDIO]: (payload) => payload,
  [SET_CLIENT_ENABLE_MUTE_AUDIO_WHEN_JOIN_MEETING]: (payload) => payload,
  [SET_CLIENT_ENABLE_HOLD_SPACE_KEY_TO_UNMUTE]: (payload) => payload,
  [AUDIO_STATUS_SENT]: (payload) => payload,
  [SET_CLIENT_ENABLE_SYNC_BUTTONS_ON_HEADSET]: (payload) => payload,
  [SET_DENOISE_ENABLED]: (payload) => payload,
  [SET_STEREO_ENABLED]: (payload) => payload,
  [CHANGE_AUDIO_PROFILE]: (payload) => payload,
});

export const deviceChange = createAction(DEVICE_CHANGE, (payload) => payload);
export const setMicMutedBySystem = createAction(SET_MIC_MUTED_BY_SYSTEM);
export const setPeerConnRecvReady = createAction('SET_PEER_CONN_RECV_READY');
export const setPeerConnSendReady = createAction('SET_PEER_CONN_SEND_READY');
