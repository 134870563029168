import meetingConfig from 'meetingConfig';
import {
  SHARER_SETTING_MENU_CATEGORY_OPTION2,
  SHARER_SETTING_MENU_CATEGORY_OPTION2_WEBINAR,
} from '../sharing/resource';
// import { getIframeContent } from './service';
export const getLauncherAppId = () => {
  const id = meetingConfig.zappInfo?.clientId;
  if (id && !ZOOM_APP_NAME.launcher) {
    ZOOM_APP_NAME.launcher = id;
  }
  return id;
};
export const ZOOM_APP_NAME = {
  sidecar: 'sidecar',
  launcher: null,
  // shareBackground: '6oeEzvN2SqqanE1sFpAi2w',
};

export const LAUNCH_MODE = {
  install: 'install',
  launch: 'launch',
};

export const ZoomAppInfo = {
  // [ZOOM_APP_NAME value]: {appId, appHomeUrl, appHandler}
  // '6oeEzvN2SqqanE1sFpAi2w': {
  //   appHomeUrl: 'https://shared-backgrounds.zoomappdev.cloud/api/zoomapp/home',
  //   appHandler: () => getIframeContent(ZOOM_APP_NAME.shareBackground),
  // },
};

export const inviteMessageType = {
  all: 0,
  allPanelist: 1,
  individualAndCCPanelist: 2,
  individual: 3,
};

export const allParticipantsItem = {
  displayName: SHARER_SETTING_MENU_CATEGORY_OPTION2,
  userId: inviteMessageType.all,
  zoomID: inviteMessageType.all,
};

export const hostAndPanelistItem = {
  displayName: SHARER_SETTING_MENU_CATEGORY_OPTION2_WEBINAR,
  userId: inviteMessageType.allPanelist,
  zoomID: inviteMessageType.allPanelist,
};

export const LauncherAppAction = {
  open: 'open',
  close: 'close',
};

export const currentSupportedApps = [
  'Virtual Backgrounds',
  'Timer',
  'Shared Backgrounds',
];

export const RESPONSE_TYPES = {
  SUCCESS: { message: 'Success' },
  FAILURE: { message: 'Failure' },
};

export const ZOOM_APP_ERROR_CODE = {
  10002: 'Validation error, please check API parameters.',
  10065: 'No Breakout Room exist.',
  10092: 'Breakout Rooms are not open.',
  10095: 'The count is over the max count.',
  10096: 'The assignment type is incorrect.',
  10121: 'Breakout Rooms are already open.',
  10122: 'Bo count and the name size do not match.',
  10104: 'Change Breakout Room failed.',
  10075: 'The participant ID is error, please get the newest participant ID.',
};
