import { iText } from '../../global/util';

export const WEBINAR_ENDED = iText(
  'The webinar has been ended',
  'apac.dialog.webinar_ended',
);
export const WEBINAR_ENDED_DESC = iText(
  'This webinar has been ended by host.',
  'apac.dialog.host_end_webinar',
);
export const OK_TEXT = iText('OK', 'apac.dialog.ok');
export const START_VIDEO = iText('Start Video', 'apac.wc_video.start_video');
export const STOP_VIDEO = iText('Stop Video', 'apac.wc_video.stop_video');
export const ASK_TO_START_VIDEO = iText(
  'Ask to Start Video',
  'apac.wc_video.ask_start_video',
);

export const STOP_ROOM_VIDEO = iText(
  'Stop Room Video',
  'apac.wc_video.stop_room_video',
);

export const MEETING_TALKING = iText('Talking:', 'apac.meeting_taking');
export const MEETING_TALKING_NEW = iText('Talking:', 'apac.meeting_talking');
export const CAN_NOT_DETECT_CAMERA = iText(
  'Cannot detect your camera, please check the device and connection and try again.',
  'apac.wc_video.cannot_detect_camera',
);
export const VIDEO_BTN_TEXT1 = iText('Video', 'apac.wc_video.video');
export const VIDEO_BTN_TEXT2 = iText(
  'Start Video',
  'apac.wc_video.start_video',
);
export const VIDEO_BTN_TEXT3 = iText('Stop Video', 'apac.wc_video.stop_video');
export const VIDEO_BTN_TEXT4 = iText(
  'Video Disabled',
  'apac.wc_video.video-disabled',
);
export const CAMERA_SELECT = iText('Select a Camera', 'apac.wc_select_camera');

export const HIDE_NON_VIDEO_PARTICIPANTS = iText(
  'Hide Non-video Participants',
  'apac.meeting_hide_non_video',
);
export const HIDE_SELF_VIEW = iText(
  'Hide Self View',
  'apac.meeting_hide_self_view',
);

export const SPOTLIGHT_VIDEO = iText(
  'Spotlight Video',
  'apac.wc_video.spotlight_video',
);

export const SPOTLIGHT_FOR_EVERYONE = iText(
  'Spotlight for Everyone',
  'apac.wc_video.spotlight_for_everyone',
);

export const ADD_SPOTLIGHT = iText(
  'Add Spotlight',
  'apac.wc_video.add_spotlight',
);

export const REPLACE_SPOTLIGHT = iText(
  'Replace Spotlight',
  'apac.wc_video.replace_spotlight',
);

export const REMOVE_SPOTLIGHT = iText(
  'Remove Spotlight',
  'apac.wc_video.remove_spotlight',
);

export const ADD_PIN = iText('Add Pin', 'apac_meeting_add_pin');
export const REPLACE_PIN = iText('Replace Pin', 'apac_meeting_replace_pin');
export const REMOVE_PIN = iText('Remove Pin', 'apac_meeting_remove_pin');
export const ALLOW_TO_MULTI_PIN = iText(
  'Allow to Multi-pin',
  'apac_allow_to_multi_pin',
);

export const DONNOT_ALLOW_TO_MULTI_PIN = iText(
  "Don't Allow to Multi-pin",
  'apac_donnot_allow_to_multi_pin',
);

export const SWITCH_TO_SHARED_CONTENT = iText(
  'Switch to Shared Content',
  'apac.switch_to_shared_content',
);

export const MIRROR_MY_VIDEO = iText('Mirror My Video', 'apac.mirror_my_video');
export const MIRROR_MY_VIDEO2 = iText(
  'Mirror my video',
  'apac.mirror_my_video2',
);

export const SAVE_VIDEO_ORDER = iText(
  'Save Video Order',
  'apac.save_video_order',
);
export const LOAD_VIDEO_ORDER = iText(
  'Load Saved Order',
  'apac.load_saved_order',
);

export const ORDER_FEATURE_TIPS = iText(
  'Save your gallery order for future meetings in the view menu',
  'apac.save_video_order_tips',
);
export const SHARING_PERFORMANT_NOTIFICATION = iText(
  'To optimize performance, your video is disabled during screen sharing',
  'apac.wc_sharing.performant_notification',
);
export const VIDEO_DISABLED_NOTIFICATION = iText(
  'Video is disabled because your vehicle is not in park',
  'apac.wc_video.video-disabled-notification',
);

export const CHANGE_PANELIST_APPEARANCE = iText(
  'Change Panelist Appearance',
  'apac.wc_video.change_panelist_appearance',
);

export const SELECT_A_NAME_TAG = iText(
  'Select a Name Tag',
  'apac.wc_video.select_a_name_tag',
);

export const PANELIST_NAME = iText('Name', 'apac.wc_video.panelist_name');

export const PANELIST_DESCRIPTION = iText(
  'Description',
  'apac.wc_video.panelist_description',
);

export const PANELIST_PRONOUNS = iText(
  'Pronouns',
  'apac.wc_video.panelist_pronouns',
);

export const PANELIST_PLACEHOLDER = iText(
  'Placeholder',
  'apac.wc_video.panelist_placeholder',
);

export const PANELIST_APPLY = iText('Apply', 'apac.wc_video.panelist_apply');

export const SESSION_APPEARANCE_FAILED = iText(
  'In-session appearance failed. Please check and try again.',
  'apac.wc_video.session_appearance_failed',
);

export const UNABLE_TO_LOAD_PANELIST_APPEARANCE = iText(
  'Unable to load panelist appearance',
  'apac.wc_video.unable_to_load_panelist_appearance',
);

export const ONLY_SELF_VIEW = iText(
  'Self view enabled until there is another video to display',
  'apac.wc_video.only_self_view',
);

export const SORT_GALLERY_BY = iText(
  'Sort Gallery By',
  'apac.wc_video.sort_gallery_by',
);

export const FIRST_NAME_ORDER_ASC = iText(
  'First Name (A - Z)',
  'apac.wc_video.first_name_asc_new',
);

export const FIRST_NAME_ORDER_DES = iText(
  'First Name (Z - A)',
  'apac.wc_video.first_name_des_new',
);

export const LAST_NAME_ORDER_ASC = iText(
  'Last Name (A - Z)',
  'apac.wc_video.last_name_asc_new',
);

export const LAST_NAME_ORDER_DES = iText(
  'Last Name (Z - A)',
  'apac.wc_video.last_name_des_new',
);

export const MEETING_ENTRY_TIME_ORDER_ASC = iText(
  'Entry Time (First - Last)',
  'apac.wc_video.meeting_entry_time_new2',
);

export const MEETING_ENTRY_TIME_ORDER_DES = iText(
  'Entry Time (Last - First)',
  'apac.wc_video.meeting_entry_time_new',
);

export const SORT_GALLERY_TIP = iText(
  'You are now sharing your video order with all participants. You can still sort your gallery view order or drag and drop participants.',
  'apac.wc_video.sort_gallery_tip',
);

export const SORT_GALLERY_TIP2 = iText(
  'Participants are now sorted by Last Name (Ascending) in your Gallery View from left to right, then top to bottom.',
  'apac.wc_video.sort_gallery_tip2',
);

export const HOST_STOP_VIDEO = iText(
  'Host has stopped your video',
  'apac.wc_video.stopped_by_host',
);

export const REQUEST_CAMERA_CONTROL = iText(
  'Request Camera Control',
  'apac.request_camera_control',
);

export const STOP_CAMERA_CONTROL = iText(
  'Stop Camera Control',
  'apac.stop_camera_control',
);

export const START_CAMERA_CONTROL = iText(
  'Start Camera Control',
  'apac.start_camera_control',
);

export const ADD_CAMERA_CONTROL = iText(
  'Add to Camera Control Group',
  'apac.add_camera_control',
);

export const REMOVE_CAMERA_CONTROL = iText(
  'Remove from Camera Control Group',
  'apac.remove_camera_control',
);

export function controlleeDeclineCameraControl(userName) {
  return iText(
    `${userName} declined your request for camera control`,
    'apac.controllee_decline_camera_control',
    [userName],
  );
}

export function controllerCanCameraControl(userName) {
  return iText(
    `You can control ${userName}'s camera now`,
    'apac.controller_can_camera_control',
    [userName],
  );
}

export const SELF_STOPPED_CAMERA_CONTROL = iText(
  'You stopped camera control',
  'apac.self_stopped_camera_control',
);

export function stoppedCameraControl(userName) {
  return iText(
    `${userName} stopped camera control`,
    'apac.stopped_camera_control',
    [userName],
  );
}

export const SWITCH_CAMERA = iText('Switch Camera', 'apac.switch_camera');

export function controlleePanelTips(userName) {
  return iText(
    `${userName} can control your camera now`,
    'apac.controllee_panel_tips',
    [userName],
  );
}

export function controlleePanelContent(userName) {
  return iText(
    `${userName} is controlling your camera`,
    'apac.controllee_panel_content',
    [userName],
  );
}

export function addedToAutoAnswerGroup(userName) {
  return iText(
    `You added ${userName} to your Camera Control Group`,
    'apac.added_to_auto_answer_group',
    [userName],
  );
}

export function addedFailedToAutoAnswerGroup(userName) {
  return iText(
    `You can not add ${userName} to your Camera Control Group`,
    'apac.added_failed_to_auto_answer_group',
    [userName],
  );
}

export function removedFromAutoAnswerGroup(userName) {
  return iText(
    `You have removed ${userName} from your Camera Control Group`,
    'apac.remove_from_auto_answer_group',
    [userName],
  );
}

export function removeFailedFromAutoAnswerGroup(userName) {
  return iText(
    `You can not remove ${userName} from your Camera Control Group`,
    'apac.remove_failed_from_auto_answer_group',
    [userName],
  );
}

export const START_CAMERA_CONTROL_BY_MENU = iText(
  'You can access camera control in their dropdown menu by selecting "Start Camera Control"',
  'apac.start_camera_control_by_menu',
);

export const PLAYING_PICTURE_IN_PICTURE = iText(
  'Playing picture in picture',
  'apac.pip.playing_picture_in_picture',
);

export function multiSpeakerActiveConditionTip(count) {
  return iText(
    `Multi-speaker view activates for ${count + 1} or more participants.`,
    'apac.multi_speaker_active_condition_tip',
    [count],
  );
}
