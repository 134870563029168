/* eslint-disable  no-console */
import {
  setQaEnabled,
  updateAnswer,
  updateQaCmd,
  updateQuestion,
} from './q-a-action';

import { coOrHostSelector } from '../../../global';
import {
  changePanelViewState,
  PanelName,
} from '../../../global/redux/set-panel-action';
import AliveToast from '../../../global/containers/notification-manager/alive-toast';
import { HOST_DISABLED_QA, HOST_ENABLED_QA } from '../resource';
import { isQAButtonShow, qaIsInMMR } from './q-a-selector';

export function updateQuestionThunk(newQuestions) {
  return (dispatch, getState) => {
    dispatch(updateQuestion({ currentState: getState(), newQuestions }));
  };
}
export function updateAnswerThunk(newAnswerList) {
  return (dispatch, getState) => {
    dispatch(updateAnswer({ currentState: getState(), newAnswerList }));
  };
}

export function updateCmdThunk(qaData) {
  return (dispatch, getState) => {
    dispatch(updateQaCmd({ currentState: getState(), qaData }));
  };
}

export function getQaEnabledThunk(newQaEnabled) {
  return (dispatch, getState) => {
    const isQAFeatureCanEnable = isQAButtonShow(getState());
    if (!newQaEnabled) {
      if (qaIsInMMR(getState())) {
        dispatch(changePanelViewState(PanelName.QA2, false));
      } else {
        dispatch(changePanelViewState(PanelName.QA, false));
      }
    }
    if (!coOrHostSelector(getState()) && isQAFeatureCanEnable) {
      if (newQaEnabled) {
        AliveToast.uniqueToast({
          message: HOST_ENABLED_QA,
          name: HOST_ENABLED_QA,
          usePrevious: true,
        });
      } else {
        AliveToast.uniqueToast({
          message: HOST_DISABLED_QA,
          name: HOST_DISABLED_QA,
          usePrevious: true,
        });
      }
    }

    dispatch(setQaEnabled(newQaEnabled));
  };
}
