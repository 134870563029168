import * as types from '../constants/MeetingActionTypes';
import {
  exitFullscreen,
  isJoinVoIP,
  isNewJoinFlowEnabled,
  isShowNewFeatureTips,
  launchFullscreen,
} from '../global/service';
import { liveTranscriptionResetAll } from '../features/live-transcription/redux/live-transcription-thunk-action';
import meetingUI from '../reducers/MeetingUIReducer';
import { LOADING_TEXT_NOTIFICATION_TYPES } from '../global/containers/notification-manager/loading-text-notification';
import { initWebglContextLostMenu } from '../global/containers/notification-manager/msft-webgl-lost-notification';
import * as userTypes from '../constants/UserRoles';
import { isInRoom, isJoiningRoom } from '../features/breakout-room/utils';
import { SHARER_STATUS_ENUM } from '../features/sharing/enum';
import {
  stopSharer,
  unsubscribeSharing,
} from '../features/sharing/redux/sharing-thunk-action';
import { globalVariable } from '../global/global-variable';
import { leaveVoipAudio } from '../features/audio/redux/audio-thunk-action';
import {
  clearAllPanel,
  convertAllPanelToWindowIfSizeLimited,
  getPanelViewState,
  PanelName,
} from '../global/redux/set-panel-action';
import { closeAllRefDialog } from '../features/dialog/service/command-dialog';
import { clearMarksForAVSOnceFailOver } from '../performance';

export function resizeScreen(data) {
  return { type: types.RESIZE_SCREEN, data };
}

export function toggleViewBtnFocused(data) {
  return { type: types.TOGGLE_VIEW_BTN_FOCUSED, data };
}

export function toggleDialog(type, data) {
  return (dispatch, getState) => {
    if (type === 'JoinDialog') {
      if (!getState().meetingUI.isCalling) {
        dispatch({
          type: types.SHOW_CALLOUT_STATUS,
          callOut: { showStatus: false },
        });
      }
      dispatch({ type: types.SHOW_JOINDIALOG, showJoinDialog: data });
    } else if (type === 'InviteDialog') {
      dispatch({ type: types.SHOW_INVITEDIALOG, showInviteDialog: data });
    }
  };
}

export function showJoinDialog() {
  return (dispatch) => {
    dispatch({ type: types.SHOW_JOINDIALOG, showJoinDialog: true });
  };
}

export function showMeetingLockTip() {
  return (dispatch) => {
    dispatch({ type: types.SHOW_MEETING_LOCK_TIP, showMeetingLockedTip: true });
    setTimeout(() => {
      dispatch({
        type: types.SHOW_MEETING_LOCK_TIP,
        showMeetingLockedTip: false,
      });
    }, 3000);
  };
}

export function showSuspensionWindow(data) {
  return { type: types.SHOW_SUSPENSION_WINDOW, data };
}

export function changePerViewNum(data) {
  return { type: types.CHANGE_PERVIEW_NUM, data };
}

export const setWebClientFocused = (data) => ({
  type: types.WEB_CLIENT_IS_FOCUSED,
  data,
});

export function updateJoinConfType(data) {
  return { type: types.UPDATE_JOINCONFTYPE, data };
}

export function enterOrExitFullScreen(data) {
  if (data) {
    launchFullscreen(document.documentElement);
  } else {
    exitFullscreen();
  }
  return { type: types.ENTER_EXIT_FULL_SCREEN, data };
}

export function inviteOthers(data) {
  return { type: types.INVITE_OTHERS, data };
}

export function copy() {
  return (dispatch) => {
    dispatch({ type: types.COPY_EMAIL, copied: 'start' });
    setTimeout(() => {
      dispatch({ type: types.COPY_EMAIL, copied: 'success' });
    }, 1000);
  };
}

export function copyURL() {
  return (dispatch) => {
    dispatch({ type: types.COPY_URL, copied: 'start' });
    setTimeout(() => {
      dispatch({ type: types.COPY_URL, copied: 'success' });
    }, 3000);
  };
}

function avDetectOnHoldStatus(bHold) {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
      sharing: { sharerStatus, isReceiveSharing, shareeCurrentActiveNodeId },
    } = getState();

    if (bHold) {
      // meeting -> waiting room
      dispatch({
        type: types.SET_CURRENT_USER_AUDIO_VIDEO_STATE_BEFORE_ON_HOLD,
        payload: {
          audio: currentUser.audio,
          muted: currentUser.muted,
          bVideoOn: currentUser.bVideoOn,
        },
      });
      if (isJoinVoIP(currentUser)) {
        // bHold status in redux has not changed, when this called, need to passed to leaveVoipAudio
        dispatch(leaveVoipAudio(undefined, undefined, undefined, bHold));
      }
      if (!isNewJoinFlowEnabled()) {
        if (globalVariable.avSocket?.destroy) {
          globalVariable.avSocket.destroy();
        }
      }

      if (sharerStatus !== SHARER_STATUS_ENUM.ED) {
        dispatch(stopSharer());
      }

      if (isReceiveSharing) {
        dispatch(unsubscribeSharing(shareeCurrentActiveNodeId));
      }
    }
  };
}

// I delete the debounce here. The debounce turned the whole thunk into async, caused the AVSocket resouce conflict.
// When you join meeting with waiting room enabled, and the host admit you very quickly (<100ms), then:
// delay setOnHold(True) => JoinMeetingSuccess => AVSocket connect => invoke AVSocket init audio in async
// => exec setOnHold(True) => AVSocket init audio failed because it was destroyed.
let inHoldBefore = false;
const _setOnHold = (bHold, dispatch) => {
  if (bHold) {
    /**
     * when bHold changed before this, preview will join audio first
     * avDetectOnHoldStatus will leave audio then, preview audio will not work
     */
    dispatch(avDetectOnHoldStatus(bHold));
  }
  dispatch({ type: types.IS_ON_HOLD, bHold });

  if (bHold) {
    closeAllRefDialog();
    dispatch(liveTranscriptionResetAll());
    dispatch(clearAllPanel());
    inHoldBefore = true;
  } else {
    if (inHoldBefore) {
      inHoldBefore = false;
      clearMarksForAVSOnceFailOver();
    }
  }
};
export function setOnHold(bHold) {
  return (dispatch) => {
    _setOnHold(bHold, dispatch);
  };
}
export function showNoSupportInfo(data) {
  return { type: types.SHOW_NO_SUPPORT_INFO, data };
}

export function setWindowZIndex(data) {
  return { type: types.SET_WINDOW_ZINDEX, data };
}

export function setRightContainerWidth(data) {
  return { type: types.SET_RIGHT_CONTAINER_WIDTH, data };
}

export function setAvDisabledTipVisible(data) {
  return { type: types.SET_AV_DISABLED_TIP_VISIBLE, data };
}

export function meetingUIReset(allReset) {
  return (dispatch, getState) => {
    const {
      showJoinDialog,
      perViewNum,
      joinConfType,
      windowSize,
      isWebClientFocused,
      isOnHoldDefaultTrue,
      isMinimizeMode,
    } = getState().meetingUI;
    setTimeout(() => {
      dispatch(initWebglContextLostMenu());
    }, 0);
    const isOnHold = easyStore.easyGet('isOnHold');
    if (allReset) {
      return dispatch({
        type: types.MEETING_UI_RESET,
        data: {
          isOnHold,
          isOnHoldDefaultTrue,
          windowSize,
          isWebClientFocused,
          showNewFeatureNotification: isShowNewFeatureTips(),
          isMinimizeMode,
        },
      });
    }

    return dispatch({
      type: types.MEETING_UI_RESET,
      data: {
        isOnHold,
        isOnHoldDefaultTrue,
        showJoinDialog,
        showRightContainer: getPanelViewState(
          getState(),
          PanelName.rightPanelContainer,
        ),
        perViewNum,
        joinConfType,
        windowSize,
        isWebClientFocused,
        showNewFeatureNotification: isShowNewFeatureTips(),
        isMinimizeMode,
      },
    });
  };
}

export function setAudioMergeWindowVisible(data) {
  return {
    type: types.SET_MERGE_AUDIO_VISIBLE,
    data,
  };
}

export function resizeWindow(data) {
  return (dispatch) => {
    dispatch(convertAllPanelToWindowIfSizeLimited());
    dispatch({ type: types.RESIZE_WINDOW, data });
  };
}

export const setStartRecordingNotificationVisible = (visible) => (dispatch) => {
  if (visible) {
    dispatch(
      meetingUI({
        type: types.ADD_LOADING_NOTIFICATION,
        data: LOADING_TEXT_NOTIFICATION_TYPES.START_RECORDING.value,
      }),
    );
  } else {
    dispatch(
      meetingUI({
        type: types.REMOVE_LOADING_NOTIFICATION,
        data: LOADING_TEXT_NOTIFICATION_TYPES.START_RECORDING.value,
      }),
    );
  }
};

export function setReclaimHostNotification(data) {
  return { type: types.SHOW_RECLAIM_HOST_NOTIFICATION, data };
}

export function checkReclaimHostStatus(users = []) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId },
        isOriginhost,
      },
      breakoutRoom: {
        attendee: { status: boStatus },
      },
    } = getState();
    const host = users.find((u) =>
      [
        userTypes.USER_ROLE_HOST,
        userTypes.UESR_ROLE_OWNERHOST,
        userTypes.WEBINAR_HOST,
      ].includes(u.role),
    );
    const isInBO = isInRoom(boStatus) || isJoiningRoom(boStatus);
    if (isOriginhost && !isInBO && host) {
      dispatch(setReclaimHostNotification(host.id !== userId));
    }
  };
}

export const setIsMinimizeMode = (data) => {
  return (dispatch) => {
    dispatch(
      meetingUI({
        type: types.SET_IS_MINIMIZE_MODE,
        data,
      }),
    );
  };
};

export const setIsPIPMode = (data) => {
  return (dispatch) => {
    dispatch(
      meetingUI({
        type: types.SET_IS_PIP_MODE,
        data,
      }),
    );
  };
};

export const setNewWaitingRoomFlowLoading = (data) => {
  return (dispatch) => {
    dispatch(
      meetingUI({
        type: types.SET_NEW_WAITING_ROOM_FLOW_LOADING,
        data,
      }),
    );
  };
};
export const toggleIsUILayoutCleaned = (data) => {
  return { type: types.TOGGLE_IS_UI_LAYOUT_CLEANED, data };
};
