import { createSelector } from 'reselect';

export const shareAttentionModeListSelector = ({
  attentionMode: { shareWhiteList },
}) => shareWhiteList;

export const shareAttentionModeStatusSelector = ({
  attentionMode: { shareAttentionMode },
}) => shareAttentionMode;

export const attentionModeStatusSelector = ({
  attentionMode: { isEnableAttentionMode },
}) => isEnableAttentionMode;

export const isMMRVideoSupportSelector = ({
  attentionMode: { isMMRVideoSupport },
}) => isMMRVideoSupport;

export const isMMRShareSupportSelector = ({
  attentionMode: { isMMRShareSupport },
}) => isMMRShareSupport;

export const isMMRSupportFocusModeSelector = createSelector(
  [isMMRVideoSupportSelector, isMMRShareSupportSelector],
  (videoSupport, shareSupport) => videoSupport && shareSupport,
);

export const isFocusModeEndingSelector = ({
  attentionMode: { isVideoEnding, isShareEnding },
}) => isVideoEnding && isShareEnding;
