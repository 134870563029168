import { getFileSuffix } from '../../features/chat/utils';
import meetingConfig from '../meetingConfig';
import { getSafeBase64 } from '../util';
import {
  messageType,
  MixMsgFileType,
  MixMsgFileTypeList,
  MixMsgFileTypeCode,
  operaType,
} from './enums';
import {
  FileObjItem,
  FileFontStyle,
  ImageSize,
  MessageResponse,
  SendFileParams,
} from './@types/type';
import { getFileExt, ImageExtentions } from '../../features/new-chat/help';
import { generateUUID } from '../service/generate-uuid';
import { MeetingUser } from './@types/meetingTypes';
import { errorLog } from '../web-client-logger';

export const getToChannel = () => {
  return (
    meetingConfig.meetingOptions.channelId ||
    `${getSafeBase64(meetingConfig.mid)}@${
      meetingConfig.xmppConfig?.wcXMPPConferenceServer
    }`
  );
};

function safeParse(str = '{}') {
  let result = {};

  try {
    result = JSON.parse(str);
  } catch (e) {
    errorLog(`JSON Parse failed: "${str}"`);
  }

  return result;
}

function getFileType(type) {
  let res;
  MixMsgFileTypeList.some((item) => {
    if (type & +item[1]) {
      res = item[0];
      return true;
    }

    return false;
  });
  return res;
}
export function getAllFiles(fontStyle: {
  item: FileFontStyle[];
}): FileObjItem[] | undefined {
  if (!fontStyle.item || fontStyle.item.length === 0) return;

  const result: Array<FileObjItem> = [];
  fontStyle.item.forEach((item) => {
    if (item.fileid && item.filesize) {
      try {
        result.push({
          fileId: item.fileid,
          // 文件 id 如果当前消息为文件消息的话
          fileName: item.filename ?? '',
          size: Number(item.filesize),
          type: item.type || 0,
          defaultImage: '',
          imageSize: item.imagesize || { cx: 0, cy: 0 },
          fileType: getFileType(item.type || 0),
          startpos: item.startpos || 0,
          endpos: item.endpos || 0,
          reserve1: safeParse(item.reserve1),
          reserve2: safeParse(item.reserve2),
        });
      } catch (e) {
        throw `getAllFiles ${e}`;
      }
    }
  });
  return result.sort((a, b) => a.startpos - b.startpos);
}

const fileSuffixMap = {
  image: ['png', 'jpg', 'jpeg', 'gif', 'svg'],
  mix: ['png', 'jpg', 'jpe', 'jpeg', 'gif'],
};

const fileType = {
  mix: 'mix',
};

function getMixMsgFileType(fileType: string) {
  if (!fileType) {
    return 0;
  }
  const lowerType = fileType.toLowerCase();
  let res = 0;
  MixMsgFileTypeList.some((item) => {
    if (String(item[0]).toLowerCase() === lowerType) {
      res = parseInt(item[1] as string);
      return true;
    }
    return false;
  });
  return res;
}

function getImageSize(file): Promise<ImageSize> {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      URL.revokeObjectURL(img.src);
      resolve({ cx: width, cy: height });
    };

    img.onerror = () => {
      resolve({
        cx: 0,
        cy: 0,
      });
    };

    img.src = URL.createObjectURL(file);
  });
}

export function getFileTypeInRtb(file: File): Promise<{
  type: number;
  fileType: string;
  fileName: string;
  size: number;
  imageSize?: ImageSize;
}> {
  let suffix = getFileSuffix(file.name);
  let type = 0;
  let imageSize;

  if (suffix === 'jpeg' || suffix === 'jpe') {
    suffix = 'jpg';
  }

  if (/*!sendAsFile && */ fileSuffixMap[fileType.mix].includes(suffix)) {
    type = getMixMsgFileType(suffix);
    return getImageSize(file).then((imageSize) => {
      return {
        type,
        fileType: suffix,
        fileName: file.name,
        size: file.size,
        imageSize,
      };
    });
  } else {
    suffix = MixMsgFileType.OtherFile;
    type = MixMsgFileTypeCode.OtherFile;
  }

  return Promise.resolve({
    type,
    fileType: suffix,
    fileName: file.name,
    size: file.size,
    imageSize: imageSize,
  });
}

export function delay(milliseconds: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}
export function getFileMessageTypeByName(fileName: string) {
  const fileExt = getFileExt(fileName.toLowerCase());
  const type = ImageExtentions.includes(fileExt)
    ? messageType.image
    : messageType.file;
  return { type, fileExt };
}
export function blobToDataURL(blob) {
  const reader = new FileReader();

  function dispose() {
    reader.onload = reader.onerror = null;
  }

  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      resolve(e.target!.result);
      dispose();
    };

    reader.onerror = function (e) {
      reject(e);
      dispose();
    };

    reader.readAsDataURL(blob);
  });
}

export type InfoMessage = Omit<MessageResponse, 'actionInfo'>;
export function createInfoMessage({
  message,
  // type,
  channelJid = getToChannel(),
  // channelName = '',
  // desc = '',
  operateType = operaType.new,
}: {
  message: string;
  channelJid?: string;
  operateType?: operaType;
}): InfoMessage {
  return {
    id: generateUUID(),
    channelJid,
    messageType: messageType.info,
    time: Date.now(),
    senderJid: null,
    senderName: null,
    message: message || '',
    messageXmlStr: '',
    operType: operateType,
  };
}

const ignoreSuffix = {
  enable: false,
  list: [''],
};

export function getFileDownloadPromptIgnoreList(): {
  enable: boolean;
  list: string[];
} {
  if (!meetingConfig.fileTransfer.fileDownloadPromptIgnoreList) {
    return {
      enable: false,
      list: [],
    };
  }
  if (ignoreSuffix.enable) return ignoreSuffix;
  ignoreSuffix.list =
    meetingConfig.fileTransfer.fileDownloadPromptIgnoreList.split(',');
  ignoreSuffix.enable = true;
  return ignoreSuffix;
}

export function createBaseMessage(
  currentUser: MeetingUser,
  channelJid: string,
) {
  const { zoomID, displayName, avatar } = currentUser;
  const currentTime = Date.now();

  return {
    id: `${currentUser.uniqueIndex}-${generateUUID()}`,
    channelJid,
    time: currentTime,
    senderJid: zoomID,
    senderName: displayName || '',
    senderAvatar: avatar,
    operType: operaType.new,
    // UI do not need messageXmlStr
    messageXmlStr: '',
    isUnreadMsg: false,
  };
}

export function createFileMessage(
  { file, replyId, mainMsgTime }: SendFileParams & { file: File },
  currentUser: MeetingUser,
  channelJid: string,
): MessageResponse {
  const baseMessage = createBaseMessage(currentUser, channelJid);
  return {
    ...baseMessage,
    messageType: messageType.file,
    message: '',
    file: {
      fileId: baseMessage.id,
      fileName: file.name,
      size: file.size,
      // ts-ignore
      file: file,
    },
    replyId,
    replyThreadTime: mainMsgTime,
  };
}
