import { createSelector } from 'reselect';
import {
  currentUserSelector,
  isMeSelector,
} from '../../../../global/redux/selector';
import { hasExistingSharers } from '../../../sharing/redux/sharing-selector';
import { farEndCameraPtzCapability } from '../../utils';
import meetingConfig from 'meetingConfig';
import {
  isEnableAutoAcceptFECCInPwa,
  isExternalControlledMode,
  isSupportFarEndCameraControl,
  isZRMultiStreamVideoChildUser,
  isZRUserByCaps,
} from '../../../../global';

export const cameraControlleeInfoSelector = ({
  attendeesList: { attendeesList },
  video: { cameraControlleeGroup, pinVideoList },
}) => {
  if (pinVideoList?.length !== 1) {
    return null;
  }
  const userId = pinVideoList[0];
  if (cameraControlleeGroup?.some((item) => item === userId)) {
    return attendeesList.find((item) => item.userId === userId);
  }
  return null;
};

export const isShowSwitchCameraBtnSelector = createSelector(
  [cameraControlleeInfoSelector, ({ video }) => video],
  (cameraControlleeInfo, { currentSpeakerActiveVideo }) => {
    return (
      currentSpeakerActiveVideo?.length == 1 &&
      currentSpeakerActiveVideo[0]?.user?.userId ===
        cameraControlleeInfo?.userId &&
      cameraControlleeInfo.bVideoOn
    );
  },
);

const participantPropsSelector = (_, { participant }) => participant;

export const isShowControlleePanelSelector = createSelector(
  [
    isShowSwitchCameraBtnSelector,
    cameraControlleeInfoSelector,
    participantPropsSelector,
  ],
  (isShowSwitchCameraBtn, cameraControlleeInfo, participant) => {
    return (
      isShowSwitchCameraBtn &&
      farEndCameraPtzCapability(cameraControlleeInfo) &&
      cameraControlleeInfo?.userId === participant?.userId
    );
  },
);

export const isShowCameraControlSelector = createSelector(
  [isMeSelector, hasExistingSharers, participantPropsSelector],
  (isMe, hasSharer, participant) => {
    return (
      meetingConfig.meetingOptions.enableCameraControl &&
      !isExternalControlledMode() &&
      !isMe &&
      !hasSharer &&
      participant.bVideoOn &&
      isSupportFarEndCameraControl(participant.caps) &&
      !isZRMultiStreamVideoChildUser(participant)
    );
  },
);

export const cameraControllerInfoSelector = ({
  attendeesList: { attendeesList },
  video: { cameraControllerUserId },
}) =>
  attendeesList.find(
    (item) => cameraControllerUserId && item.userId === cameraControllerUserId,
  );

export const isShowControlleePanelBtnSelector = ({
  video: { cameraControllerUserId },
}) => !!cameraControllerUserId;

// add attendee level option
// bo / waiting room / webinar
export const isShowCameraControlGroupSelector = createSelector(
  [currentUserSelector, ({ video }) => video, participantPropsSelector],
  (currentUser, { isEnableAutoAcceptCameraControl }, participant) => {
    return (
      isEnableAutoAcceptFECCInPwa() &&
      isEnableAutoAcceptCameraControl &&
      !isExternalControlledMode() &&
      currentUser.userId !== participant.userId &&
      !!participant.bLogIn &&
      !isZRUserByCaps(participant.caps)
    );
  },
);

export const isShowStartCameraControlSelector = createSelector(
  [
    isShowCameraControlGroupSelector,
    ({ video }) => video,
    hasExistingSharers,
    participantPropsSelector,
  ],
  (
    isShowCameraControlGroup,
    { startCameraControlGroup },
    hasSharer,
    participant,
  ) => {
    return (
      isShowCameraControlGroup &&
      !hasSharer &&
      !!startCameraControlGroup.find(
        (item) => item.strConfUserID === participant.strConfUserID,
      ) &&
      participant.bVideoOn &&
      isSupportFarEndCameraControl(participant.caps)
    );
  },
);

export const noAvailableCameraSelector = (state) => {
  const {
    video: { cameraDevicesList },
  } = state;

  return (
    cameraDevicesList.filter((camera) => camera.deviceId !== 'default')
      .length == 0
  );
};
