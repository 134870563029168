import { timeoutCommandSocketEvents, defaultTimeout } from './timeout-events';

export class EventTimeoutTrackerTask {
  constructor({ message, eventBus, store }) {
    this.message = message;
    this.eventBus = eventBus;
    this.store = store;
    this.timeout = defaultTimeout;
    this.timer = null;
    this.eventName = '';
    this.targetResEventType = null;
    this.eventHandler = null;
    this.status = 0;
    this.timeoutExecutor = () => {};
    this.resValidator = () => {};
    this.run();
  }

  run() {
    const { body: reqBody } = this.message;
    this.eventHandler = (resBody) => {
      if (this.resValidator(resBody, reqBody)) {
        this.stopTimer();
      }
    };
    this.initEventParamsFromConfig();
    this.listen();
    this.startTimer();
  }

  startTimer() {
    const { dispatch } = this.store;
    this.status = 1;
    this.timer = setTimeout(() => {
      this.timeoutExecutor(dispatch);
      this.removeListener();
      this.status = -1;
    }, this.timeout);
  }

  stopTimer() {
    clearTimeout(this.timer);
    this.timer = null;
    this.removeListener();
    this.status = -1;
  }

  listen() {
    if (!this.targetResEventType) {
      return;
    }
    if (Array.isArray(this.targetResEventType)) {
      this.targetResEventType.forEach((eventType) => {
        this.eventBus.on(eventType, this.eventHandler);
      });
    } else {
      this.eventBus.on(this.targetResEventType, this.eventHandler);
    }
  }

  removeListener() {
    if (!this.targetResEventType) {
      return;
    }
    if (Array.isArray(this.targetResEventType)) {
      this.targetResEventType.forEach((eventType) => {
        this.eventBus.off(eventType, this.eventHandler);
      });
    } else {
      this.eventBus.off(this.targetResEventType, this.eventHandler);
    }
  }

  initEventParamsFromConfig() {
    const { evt, body } = this.message;
    const keys = Object.keys(timeoutCommandSocketEvents);
    const targetEventKey = keys.find((key) => key === `${evt}`);
    if (targetEventKey) {
      const targetEventConf = timeoutCommandSocketEvents[targetEventKey];
      this.targetResEventType = targetEventConf.responseEventType;
      this.timeout = targetEventConf.timeout;
      targetEventConf.actions.forEach((action) => {
        if (action.reqValidator(body)) {
          this.resValidator = action.resValidator;
          this.eventName = action.name;
          this.timeoutExecutor = action.timeoutExecutor;
        }
      });
    }
    if (!this.resValidator || !this.eventName) {
      throw new Error('no timeout event conf found');
    }
  }
}
