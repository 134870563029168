import { globalVariable } from '../../../global/global-variable';
import {
  setIsOpen,
  setRedoStatus,
  setShareUserAnnotationInfo,
  setUndoStatus,
  updateUsedAnnotationSharingList,
} from './annotation-action';
import * as AVNotifyMediaSDKTypes from '../../../constants/AVNotifyMediaSDKTypes';
import { ANNO_ACTIONS, ANNOTATION_STATUS } from '../enum';
import { shareTypeOfSupportAnnotate } from '../constants';
import { shareUserListSelector } from '../../../global/redux/selector';

export const toggleCloseAnnoToolBarThunk = () => (dispatch, getState) => {
  const {
    annotation: { isOpen },
  } = getState();

  if (isOpen) {
    dispatch(setIsOpen(false));
    dispatch(setUndoStatus(false));
    dispatch(setRedoStatus(false));
    globalVariable.avSocket.sendSocket(
      AVNotifyMediaSDKTypes.ANNOTATION_ACTIONS,
      {
        actionType: ANNO_ACTIONS.CLOSE_TOOL_BAR,
      },
    );
  }

  return;
};

export const toggleStartAnnotationThunk = () => (dispatch, getState) => {
  const state = getState();
  const {
    annotation: { usedAnnotationSharingList },
    sharing: { shareeCurrentActiveNodeId, sharerSsrc },
    meeting: {
      currentUser: { displayName: currentUserName },
    },
  } = getState();
  const shareUserList = shareUserListSelector(state);
  const currentShareUser = shareUserList.find(
    (user) => user.userId === shareeCurrentActiveNodeId,
  );
  if (!currentShareUser) {
    dispatch(toggleCloseAnnoToolBarThunk());
    return;
  }
  const presenterId = usedAnnotationSharingList.find(
    (ssrc) => ssrc === currentShareUser.shareSsrc,
  );

  dispatch(setIsOpen(true));
  if (!presenterId) {
    const newList = [...usedAnnotationSharingList, currentShareUser.shareSsrc];
    dispatch(updateUsedAnnotationSharingList(newList));
  }

  globalVariable.avSocket.sendSocket(AVNotifyMediaSDKTypes.START_ANNOTATION_A, {
    userName: currentUserName,
    presenterId: currentShareUser.shareSsrc,
    shareSessionId: sharerSsrc,
  });
};

export const toggleStopAnnotationThunk =
  (updateList) => (dispatch, getState) => {
    const state = getState();
    const {
      annotation: {
        usedAnnotationSharingList,
        isOpen,
        shareUserAnnotationInfo,
      },
      sharing: { shareeCurrentActiveNodeId },
    } = state;
    const shareUserList = shareUserListSelector(state);
    const currentShareUser = shareUserList.find(
      (user) => user.userId === shareeCurrentActiveNodeId,
    );

    if (!updateList) {
      return;
    }

    // stop share
    const stopOrPausedSharingUserList = updateList.filter(
      (item) =>
        typeof item.bShareOn === 'boolean' &&
        (item.bShareOn === false || typeof item.bSharePause === 'boolean'),
    );
    if (!stopOrPausedSharingUserList.length) {
      return;
    }
    const filterList = [];

    Object.keys(shareUserAnnotationInfo).forEach((userId) => {
      const sharingUser = stopOrPausedSharingUserList.find(
        (updateUser) => updateUser.id === Number(userId),
      );

      if (sharingUser) {
        //  current viewing sharing is stoped, need to close
        if (
          currentShareUser &&
          sharingUser.shareSsrc === currentShareUser.shareSsrc &&
          isOpen
        ) {
          dispatch(toggleCloseAnnoToolBarThunk());
        }

        if (!sharingUser.bShareOn) {
          // stop & clear annotation
          globalVariable.avSocket.sendSocket(
            AVNotifyMediaSDKTypes.STOP_ANNOTATION_A,
            {
              presenterId: sharingUser.shareSsrc,
            },
          );
          filterList.push(sharingUser.shareSsrc);
          return;
        }

        // pause share
        const newShareAnnotationInfo = {
          ...(shareUserAnnotationInfo[sharingUser.id] || {
            userId: sharingUser.id,
          }),
          hideAnnoteBtn: sharingUser.bSharePause,
        };
        shareUserAnnotationInfo[sharingUser.id] = newShareAnnotationInfo;
        dispatch(setShareUserAnnotationInfo(shareUserAnnotationInfo));
      }
    });

    if (usedAnnotationSharingList.length > 0) {
      dispatch(
        updateUsedAnnotationSharingList(
          usedAnnotationSharingList.filter(
            (ssrc) => !filterList.includes(ssrc),
          ),
        ),
      );
    }
    return;
  };

export const handleChangeRedoOrUndoStatusThunk = (type, data) => (dispatch) => {
  const { status } = data;

  if (type === ANNO_ACTIONS.REDO) {
    dispatch(setRedoStatus(status));
  } else if (type === ANNO_ACTIONS.UNDO) {
    dispatch(setUndoStatus(status));
  }
};

export const toggleAnnotateAction =
  (type, params = {}) =>
  () => {
    globalVariable.avSocket.sendSocket(
      AVNotifyMediaSDKTypes.ANNOTATION_ACTIONS,
      {
        actionType: type,
        ...params,
      },
    );
  };

export const updateShareUserAnnoInfoThunk = (data) => (dispatch, getState) => {
  const {
    annotation: { shareUserAnnotationInfo, isOpen },
    sharing: { shareeCurrentActiveNodeId },
  } = getState();

  const { shareUserId, ...info } = data;

  let newShareAnnotationInfo = {};
  if (shareUserAnnotationInfo[shareUserId]) {
    newShareAnnotationInfo = {
      ...shareUserAnnotationInfo[shareUserId],
      ...info,
    };
  } else {
    newShareAnnotationInfo = {
      ...info,
      userId: shareUserId,
    };
  }
  shareUserAnnotationInfo[shareUserId] = newShareAnnotationInfo;
  // presenter disable annotate or switch shareType
  if (
    (info.annotationOff === ANNOTATION_STATUS.OFF ||
      !shareTypeOfSupportAnnotate.includes(info.shareType)) &&
    shareUserId === shareeCurrentActiveNodeId &&
    isOpen
  ) {
    dispatch(toggleCloseAnnoToolBarThunk());
  }
  // update share user annotate status
  dispatch(setShareUserAnnotationInfo(shareUserAnnotationInfo));
};
