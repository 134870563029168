import { DEVICE_STATUS, DEVICE_STATUS_PARAM } from './consts';

export const parseTestResultToParam = (testResult) => {
  if (!testResult) {
    return DEVICE_STATUS_PARAM.NOT_TESTED;
  }
  if (testResult.status === DEVICE_STATUS.GOOD) {
    return DEVICE_STATUS_PARAM.WORKING;
  } else if (testResult.status === DEVICE_STATUS.BAD) {
    return DEVICE_STATUS_PARAM.NOT_TESTED;
  } else {
    return DEVICE_STATUS_PARAM.NOT_TESTED;
  }
};
