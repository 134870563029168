import { rawStringToBuffer, buffer2Str } from '../../../../global/util';
import { messengerproto } from './messagerproto-es6';
import Long from 'long';

const FontStyte = messengerproto.FontStyte;
// export function rtbStrToFontStyle(str) {
//   const buf = rawStringToBuffer(atob(str));
//   const pbf = new Pbf(buf);
//   return FontStytePbf.read(pbf);
// }

// export function fontStyleToRtbStr(fontStyleItemList) {
//   const pbf = new Pbf();
//   FontStytePbf.write(fontStyleItemList, pbf);
//   const buffer = pbf.finish();
//   const base64Str = btoa(buffer2Str(buffer));
//   return base64Str;
// }
// console.log(
//   rtbStrToFontStyle(
//     'CjcIgICABBAIGAgiFnZsUWhsQ2VUVHBLVnVpSU1lblpGRVEqCWltYWdlLnBuZzDbgAE6BQi+AxBL',
//   ),
// );
export function rtbStrToFontStyle(str) {
  const decodedStr = atob(str);
  const buffer = rawStringToBuffer(decodedStr);
  const formatRes = FontStyte.decode(buffer);

  if (formatRes.item && Array.isArray(formatRes.item)) {
    formatRes.item.forEach((fontItem) => {
      const { type, ver } = fontItem;
      fontItem.type = Long.isLong(type) ? type.toNumber() : type;
      fontItem.ver = Long.isLong(ver) ? ver.toNumber() : ver;
    });
  }

  return formatRes;
}

export function fontStyleToRtbStr(fontStyleItemList) {
  const buffer = FontStyte.encode(fontStyleItemList).finish();
  const base64Str = btoa(buffer2Str(buffer));
  return base64Str;
}
