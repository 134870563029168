export const ANNO_CANVAS_COONTAINER_DOM_SELECTOR =
  '#sharee-container > .sharee-container__viewport';

export enum AnnoToolType {
  ANNO_TOOL_TYPE_NONE,
  ANNO_TOOL_TYPE_PEN,
  ANNO_TOOL_TYPE_HIGHLIGHTER,
  ANNO_TOOL_TYPE_SPOTLIGHT,
  ANNO_TOOL_TYPE_ARROW,
  ANNO_TOOL_TYPE_TEXTBOX,
  ANNO_TOOL_TYPE_PICTURE,
  ANNO_TOOL_TYPE_ERASER,
  ANNO_TOOL_TYPE_BRUSH_ERASER,
  ANNO_TOOL_TYPE_PICKER,
  ANNO_TOOL_TYPE_SELECTOR,
  ANNO_TOOL_TYPE_AUTO_LINE,
  ANNO_TOOL_TYPE_AUTO_ARROW1,
  ANNO_TOOL_TYPE_AUTO_ARROW2,
  ANNO_TOOL_TYPE_AUTO_RECTANGLE,
  ANNO_TOOL_TYPE_AUTO_RECTANGLE_SEMI_FILL,
  ANNO_TOOL_TYPE_AUTO_ROUNDEDRECTANGLE,
  ANNO_TOOL_TYPE_AUTO_ROUNDEDRECTANGLE_SEMI_FILL,
  ANNO_TOOL_TYPE_AUTO_ELLIPSE,
  ANNO_TOOL_TYPE_AUTO_ELLIPSE_SEMI_FILL,
  ANNO_TOOL_TYPE_AUTO_DOUBLE_ARROW,
  ANNO_TOOL_TYPE_AUTO_RECTANGLE_FILL,
  ANNO_TOOL_TYPE_AUTO_ROUNDEDRECTANGLE_FILL,
  ANNO_TOOL_TYPE_AUTO_ELLIPSE_FILL,
  ANNO_TOOL_TYPE_AUTO_DIAMOND,
  ANNO_TOOL_TYPE_AUTO_STAMP_ARROW,
  ANNO_TOOL_TYPE_AUTO_STAMP_CHECK,
  ANNO_TOOL_TYPE_AUTO_STAMP_X,
  ANNO_TOOL_TYPE_MULTI_FLAT_PEN,
  ANNO_TOOL_TYPE_MULTI_FLAT_ERASER,
  ANNO_TOOL_TYPE_MULTI_THICKNESS_PEN,
  ANNO_TOOL_TYPE_AUTO_STAMP_STAR,
  ANNO_TOOL_TYPE_AUTO_STAMP_HEART,
  ANNO_TOOL_TYPE_AUTO_STAMP_QM,
  ANNO_TOOL_TYPE_MULTI_SHAPE_DETECTOR,
  ANNO_TOOL_TYPE_MULTI_TEXT_DETECTOR,
  ANNO_TOOL_TYPE_FADE_PEN,
  ANNO_TOOL_TYPE_AUTO_DIAMOND_SEMI_FILL,
  ANNO_TOOL_TYPE_AUTO_DIAMOND_FILL,
  ANNO_TOOL_TYPE_MULTI_HANDWRITE_PEN,
  NUMBER_OF_ANNO_TOOL_TYPE,
}

export enum ANNO_ACTIONS {
  SELECT_MOUSE,
  SELECT_TOOL,
  SELECT_ERASER,
  UNDO,
  REDO,
  CLEAR,
  CLOSE_TOOL_BAR,
  UPDATE_PARAMS,
  PAUSE_ANNOTATION,
  PALETTE = 'PALETTE',
}

export enum ANNO_CLEAR_TYPE {
  CLEAR_MINE,
  CLEAR_VIEWER,
  CLEAR_ALL,
}

export enum ANNO_UI_LINE_WIDTH {
  THIN = 1,
  REGULAR = 2,
  THICK = 4,
  BULK = 8,
}

export enum SELECT_TOOL_TYPE {
  PEN = 0,
  LINE_WIDTH,
}

export enum SHARE_TYPE {
  SSB_MC_AS_SHARED_OBJ_UNKNOWN = -1,
  SSB_MC_AS_SHARED_OBJ_SCREEN,
  SSB_MC_AS_SHARED_OBJ_WINDOW,
  SSB_MC_AS_SHARED_OBJ_DATA,
  SSB_MC_AS_SHARED_OBJ_WIFI_DEVICE,
  SSB_MC_AS_SHARED_OBJ_WHITEBOARD,
  SSB_MC_AS_SHARED_OBJ_CAMERA,
  SSB_MC_AS_SHARED_OBJ_WIRED_DEVICE,
  SSB_MC_AS_SHARED_OBJ_FRAME,
  SSB_MC_AS_SHARED_OBJ_DROPBOX,
  SSB_MC_AS_SHARED_OBJ_ONE_DRIVER,
  SSB_MC_AS_SHARED_OBJ_GOOGLE_DRIVER,
  SSB_MC_AS_SHARED_OBJ_BOX,
  SSB_MC_AS_SHARED_OBJ_PPT_VIRTUAL_BG,
  SSB_MC_AS_SHARED_OBJ_WHITEBOARD_CAMERA,
  SSB_MC_AS_SHARED_OBJ_EXTERNAL_WHITEBOARD,
  SSB_MC_AS_SHARED_OBJ_MS_SHAREPOINT,
  SSB_MC_AS_SHARED_OBJ_PROCESS,
  SSB_MC_AS_SHARED_OBJ_VIDEO_FILE,
  SSB_MC_AS_SHARED_OBJ_PICKER,
}

export enum ANNOTATION_STATUS {
  ON,
  OFF,
}
