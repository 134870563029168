/* eslint-disable react/prop-types */
import React from 'react';

const MenuItem = (props) => {
  const { children } = props;
  return (
    <li role="menuitem" tabIndex="-1" {...props}>
      {children}
    </li>
  );
};

MenuItem.displayName = 'MenuItem';

export default MenuItem;
