function generateZoneSeq(list) {
  const zonelist = (list || []).map((item) => item.zone);
  const uniqZoneList = _.uniq(zonelist);
  return uniqZoneList;
}

function generateBreakList(list, uniqZoneList) {
  const zoneMap = uniqZoneList.reduce((obj, item) => {
    Object.assign(obj, { [item]: [] });
    return obj;
  }, {});

  list.forEach((item) => {
    zoneMap[item.zone].push(item);
  });

  return zoneMap;
}

function compareListDiff(listMap, prevListMap, zoneListSeq) {
  const diffListMap = zoneListSeq.reduce((obj, item) => {
    Object.assign(obj, { [item]: { start: [], stop: [], zoom: [] } });
    return obj;
  }, {});

  zoneListSeq.forEach((zoneId) => {
    const curZoneRowList = listMap[zoneId];
    const prevZoneRowList = prevListMap[zoneId];

    const curListVideoOn = curZoneRowList.filter(
      (item) => !!item.user.displayVideoOn,
    );

    const prevListVideoOn = prevZoneRowList.filter(
      (item) => !!item.user.displayVideoOn,
    );

    const curListVideoOnIds = curListVideoOn.map((item) => item.user.userId);

    const prevListVideoOnIds = prevListVideoOn.map((item) => item.user.userId);

    if (prevZoneRowList.length && !curZoneRowList.length) {
      diffListMap[zoneId].stop = prevListVideoOn;
      return;
    }

    if (!prevZoneRowList.length && curZoneRowList.length) {
      diffListMap[zoneId].start = curListVideoOn;
      return;
    }

    const addVideoOnList = (curListVideoOn || []).filter(
      (item) => prevListVideoOnIds.indexOf(item.user.userId) === -1,
    );

    const delVideoOnList = (prevListVideoOn || []).filter(
      (item) => curListVideoOnIds.indexOf(item.user.userId) === -1,
    );

    const commonVideoOnList = (curListVideoOn || []).filter(
      (item) => prevListVideoOnIds.indexOf(item.user.userId) > -1,
    );

    const changeVideoOnList = [];

    commonVideoOnList.forEach((item) => {
      prevListVideoOn.forEach((subItem) => {
        if (item.user.userId === subItem.user.userId) {
          if (
            item.x !== subItem.x ||
            item.y !== subItem.y ||
            item.width !== subItem.width ||
            item.height !== subItem.height ||
            item.quality !== subItem.quality
          ) {
            // only change qualiy,not render
            if (
              item.x === subItem.x &&
              item.y === subItem.y &&
              item.width === subItem.width &&
              item.height === subItem.height
            ) {
              changeVideoOnList.push({ ...item, onlyChangeQuality: true });
            } else {
              changeVideoOnList.push({ ...item, onlyChangeQuality: false });
            }
          }
        }
      });
    });

    diffListMap[zoneId].start = addVideoOnList;
    diffListMap[zoneId].stop = delVideoOnList;
    diffListMap[zoneId].zoom = changeVideoOnList;
  });

  return diffListMap;
}

function renderDiff(previousCurrentRenderVideo, currentRenderVideo) {
  const zoneListSeq = generateZoneSeq(currentRenderVideo);
  const preZoneListSeq = generateZoneSeq(previousCurrentRenderVideo);
  const allZoneListSeq = _.uniq([...zoneListSeq, ...preZoneListSeq]);

  const prevListMap = generateBreakList(
    previousCurrentRenderVideo,
    allZoneListSeq,
  );
  const listMap = generateBreakList(currentRenderVideo, allZoneListSeq);
  const diffListMap = compareListDiff(listMap, prevListMap, allZoneListSeq);

  const renderDiffMap = Object.keys(diffListMap)
    .map((item) => diffListMap[item])
    .reduce(
      (obj, item) => {
        return {
          start: [...obj.start, ...item.start],
          stop: [...obj.stop, ...item.stop],
          zoom: [...obj.zoom, ...item.zoom],
        };
      },
      {
        start: [],
        stop: [],
        zoom: [],
      },
    );

  return renderDiffMap;
}
export default renderDiff;
