import { MeetingSettingsEntity } from '@zoom/zdt_events/dist/entities/meetings/MeetingSettings';
import { currentUserMutedSelector } from '../../selectors/global-audio-selector';
import { currentUserVideoStateSelector } from '../../selectors/global-video-selector';
import { autoVideo } from '../../containers/av-socket/auto-video/auto-video';
import { VideoLayout } from '../constant';
import { needAutoJoinAudio } from '../../containers/av-socket/auto-audio/need-auto-join-audio';
import { videoLayoutSelector } from '../../../features/video/redux/selectors/video-layout-selector';

const meetingSettingEntity = new MeetingSettingsEntity();
// "isAudioMuted", "isVideoStarted", "virtualBackground", "videoFilter", "avatar", "supportsVideoPreview", "meetingView", "micOnJoinEnabled", "videoOnJoinEnabled"].

export const makeMeetingSettingEntity = (state) => {
  meetingSettingEntity.isAudioMuted = currentUserMutedSelector(state);
  meetingSettingEntity.isVideoStarted = currentUserVideoStateSelector(state);
  meetingSettingEntity.virtualBackground =
    state.settings.backgroundImage.backgroundName ?? 'no selected';
  meetingSettingEntity.videoFilter = '';
  meetingSettingEntity.avatar = '';
  meetingSettingEntity.supportsVideoPreview = true;
  meetingSettingEntity.meetingView =
    VideoLayout[videoLayoutSelector(state)] ?? 'immersive';
  const ret1 = needAutoJoinAudio();
  meetingSettingEntity.micOnJoinEnabled = ret1;
  const ret2 = autoVideo();
  meetingSettingEntity.videoOnJoinEnabled = ret2;
  return meetingSettingEntity;
};
