import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {
  COMPOSITE_CHECKBOX_MASK_ENUM,
  COMPOSITE_CHECKBOX_ALLY_BORDER_PATTERN_ENUM,
  DOM_KEY_CODE,
} from '../../enum';
import { getNextA11yLevel } from '../../util';
/* eslint-disable react/prop-types */

const MASK_PATTERN_MAP = {
  [COMPOSITE_CHECKBOX_MASK_ENUM.PATTERN1]:
    'composite-check-box__mask--pattern1',
};

const ALLY_BORDER_PATTERN_MAP = {
  [COMPOSITE_CHECKBOX_ALLY_BORDER_PATTERN_ENUM.PATTERN1]:
    'composite-check-box__is-lbl--pattern1',
};

const checkStateChangeEvent = (checkedNow) => ({
  target: {
    checked: !checkedNow,
  },
});

const checkboxStateHandler = (onChange) => (e) => {
  if (DOM_KEY_CODE.ENTER === e.keyCode) {
    onChange(checkStateChangeEvent(e.target.checked));
  }
};

function CompositeCheckbox({
  labelText,
  checked,
  onChange,
  isLocked,
  isRadioPattern,
  groupName,
  rootClassName,
  maskPattern,
  a11yBorderPattern,
  a11yLevelBase,
  a11yWalkerPolicy,
  firstCBA11yLevelBase,
  currentCBListCount,
  labelClassName,
  a11yText,
  ...rest
}) {
  const id = _.uniqueId();
  const allyBorderClassNameModifier =
    ALLY_BORDER_PATTERN_MAP[a11yBorderPattern];
  const cbLblClassName = classNames('composite-check-box__is-lbl', {
    'composite-check-box__is-lbl--radio-pattern': isRadioPattern,
    [allyBorderClassNameModifier]: !!allyBorderClassNameModifier,
    [labelClassName]: Boolean(labelClassName),
  });
  const domInputType = isRadioPattern ? 'radio' : 'checkbox';

  const rootEleClassName = classNames('composite-check-box ax-outline', {
    [rootClassName]: !!rootClassName,
  });
  const maskClassNameModifier = MASK_PATTERN_MAP[maskPattern];
  const maskClassName = classNames('composite-check-box__mask', {
    [maskClassNameModifier]: !!maskClassNameModifier,
  });

  let a11yProps = null;
  if (!isLocked) {
    a11yProps = {
      'data-a-l': a11yLevelBase,
      'data-a-walk-policy': a11yWalkerPolicy,
      'data-a-l-list-info': firstCBA11yLevelBase
        ? `${firstCBA11yLevelBase},${getNextA11yLevel(
            firstCBA11yLevelBase,
            currentCBListCount - 1,
          )}`
        : null,
    };
  }

  return (
    <div aria-label={a11yText} className={rootEleClassName} {...rest}>
      <input
        checked={checked}
        type={domInputType}
        onChange={onChange}
        onKeyDown={checkboxStateHandler(onChange)}
        className="composite-check-box__is-cb"
        id={id}
        name={groupName}
        disabled={isLocked}
        aria-label={labelText}
        {...a11yProps}
      />
      <label className={cbLblClassName} htmlFor={id}>
        <span>{labelText}</span>
      </label>
      {isLocked && <div className={maskClassName} />}
    </div>
  );
}

CompositeCheckbox.defaultProps = {
  groupName: '',
  isLocked: false,
  rootClassName: '',
  labelClassName: '',
  a11yBorderPattern: COMPOSITE_CHECKBOX_ALLY_BORDER_PATTERN_ENUM.PATTERN1,
};

export { CompositeCheckbox };

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/components/widget/composite-checkbox.scss';
