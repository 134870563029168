import zmitt from '@zoom/zmitt';
import {
  isShouldHandleCommandSocketEvent,
  isShouldTrackCommandSocketEvent,
} from './timeout-events';
import { EventTimeoutTrackerTask } from './event-timeout-tracker-task';

export class EventTimeoutTracker {
  static instance = null;

  static getInstance() {
    if (!this.instance) {
      this.instance = new EventTimeoutTracker();
    }
    return this.instance;
  }

  constructor() {
    this.eventBus = zmitt();
    this.tasks = [];
  }

  init(store) {
    this.store = store;
  }

  getEventBus() {
    return this.eventBus;
  }

  listenCommandSocoket(message) {
    if (isShouldTrackCommandSocketEvent(message)) {
      this.createTimeoutTrackerTask(message);
    }
  }

  notifyCommandSocoket({ evt, body }) {
    this.clearIdleTrackerTask();
    if (isShouldHandleCommandSocketEvent(evt)) {
      this.eventBus.emit(evt, body);
    }
  }

  createTimeoutTrackerTask(message) {
    this.tasks.push(
      new EventTimeoutTrackerTask({
        message,
        eventBus: this.eventBus,
        store: this.store,
      }),
    );
  }

  clearIdleTrackerTask() {
    const runingTasks = this.tasks.filter((task) => task.status === 1);
    this.tasks = runingTasks;
  }

  failOver() {
    this.eventBus.offAll();
    this.tasks.forEach((task) => {
      if (task.status === 1) {
        task.stopTimer();
      }
    });
    this.tasks = [];
  }
}
