import {
  CHAT_PRIVILEDGE_EVERYONE_PUBLICLY,
  CHAT_PRIVILEDGE_HOST,
  CHAT_PRIVILEDGE_NOONE,
} from '../../constants/Constants';
import {
  getMeetingChannelId,
  getUserFullName,
  getUserXmppJidRemoveMN,
} from '../../global/service';
import { SEND_CHAT_WARNING } from '../../global/constant';
import {
  CHAT_MSG_TYPE_TO_SILENT_MODE_USERS,
  CHAT_MSG_TYPE_TO_ALL,
  HTML5_CAPS_OPTION_SUPPORT_FILE_TRANSFER,
} from '../chat/constants';
import { isBuildInChatReceiver } from '../chat/utils';
import {
  cutString,
  getSafeBase64,
  isExternalControlledMode,
} from '../../global/util';
import meetingConfig from 'meetingConfig';
import { generateUUID } from '../../global/service/generate-uuid';
import { ChatReplyToOldTxt } from './resource';
/**
 *
 * @param {*} param-1
 * @param {*} useZoomID
 * @returns [result, receiverId]
 */
export function checkBeforeSentAndGetReceiverId(
  {
    receiverId,
    receiverSN,
    attendeesList,
    isMeInRoom,
    chatPriviledge,
    currentUserPrivateChatMsgDisabled,
    currentIsCoOrHost,
    isSilentModeUser = false,
    isSameMeetingMessage = true,
  },
  useZoomID,
) {
  if (receiverId === CHAT_MSG_TYPE_TO_ALL && !isSameMeetingMessage) {
    return [SEND_CHAT_WARNING.NOT_IN_SAME_MEETING, receiverId];
  }
  if (receiverId === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS) {
    if (!currentIsCoOrHost) return [SEND_CHAT_WARNING.NO_PRIVILEGE];
    return [SEND_CHAT_WARNING.NONE, receiverId];
  }
  const meetingReceiver = attendeesList.find((user) => {
    if (useZoomID) {
      return user.zoomID === receiverSN;
    } else {
      return user.userId === receiverId;
    }
  });

  // A -> B direct message, put B on hold, then reply to B
  // reply to someone'DM, but someone is be hold
  if (meetingReceiver && meetingReceiver.bHold && !isSilentModeUser) {
    // if (!currentIsCoOrHost) return [SEND_CHAT_WARNING.NO_PRIVILEGE];
    // return [SEND_CHAT_WARNING.NONE, meetingReceiver.userId];
    return [SEND_CHAT_WARNING.EDIT_DM_ON_HOLD];
  }
  // TODO
  // if (isWebinar) {
  //   // TODO
  // }
  if (meetingReceiver) {
    if (currentUserPrivateChatMsgDisabled) {
      return [SEND_CHAT_WARNING.RESTRICTED];
    }
    if (meetingReceiver.bPrivateChatMsgDisabled && !meetingReceiver.bHold) {
      return [SEND_CHAT_WARNING.RESTRICTED];
    }

    if (meetingReceiver.bid && !isMeInRoom) {
      return [SEND_CHAT_WARNING.ABSENT];
    }
    if (!currentIsCoOrHost) {
      const isReceiverHost = meetingReceiver.isHost || meetingReceiver.bCoHost;
      if (
        (chatPriviledge === CHAT_PRIVILEDGE_HOST ||
          chatPriviledge === CHAT_PRIVILEDGE_EVERYONE_PUBLICLY) &&
        !isReceiverHost
      ) {
        return [SEND_CHAT_WARNING.IMPROPER_RECEIVER];
      }
    }

    return [SEND_CHAT_WARNING.NONE, meetingReceiver.userId];
  }

  if (!currentIsCoOrHost) {
    const isReceiverEveryone = receiverId === CHAT_MSG_TYPE_TO_ALL;
    if (chatPriviledge === CHAT_PRIVILEDGE_HOST && isReceiverEveryone) {
      // return setIsImproperReceiver(true);
      return [SEND_CHAT_WARNING.IMPROPER_RECEIVER];
    }
    if (chatPriviledge === CHAT_PRIVILEDGE_NOONE) {
      return [SEND_CHAT_WARNING.IMPROPER_RECEIVER];
    }
  }

  if (isBuildInChatReceiver(receiverId)) {
    return [SEND_CHAT_WARNING.NONE, receiverId];
  }
  return [SEND_CHAT_WARNING.ABSENT];
}

export const getIsSupportReplyFile = ({
  meetingConfig,
  restrictFileTransfer,
  selfCaps,
  bIbDisableFileTransfer,
  receiverCaps,
  isShowPrivately,
  shareInChat,
}) => {
  // dropbox is not supported
  const shareItems = (shareInChat || []).filter(
    (item) => item.type !== 'dropbox',
  );
  const noShareItems =
    _.isEmpty(shareItems) ||
    meetingConfig.meetingOptions?.isDisable3rdFileTransfer ||
    !meetingConfig.isLogin; // guest can't send 3rd-party file
  if (
    ((!meetingConfig?.fileTransfer?.isEnable ||
      restrictFileTransfer ||
      !(selfCaps & HTML5_CAPS_OPTION_SUPPORT_FILE_TRANSFER)) &&
      noShareItems) ||
    isExternalControlledMode() ||
    bIbDisableFileTransfer || // Information barriers
    // currentUser.bHold || // waiting room can not reach
    // receiverId === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS || // Can't send file to waiting room
    !window.easyStore.easyGet('gcmEnabled') // IE and old Chrome not support
  ) {
    return false;
  }

  // TODO ignore webinar
  // if (isWebinar()) {
  //   if (isViewOnly(currentUserUserRole)) {
  //     return false;
  //   }

  //   if (
  //     isPanelist(currentUserUserRole) &&
  //     meetingConfig?.fileTransfer?.isDisablePanelist &&
  //     meetingConfig?.fileTransfer?.isEnable &&
  //     noShareItems
  //   ) {
  //     return false;
  //   }

  //   const attendee = xmppUserList.attendees.find(
  //     (user) => user.node === receiverId,
  //   );
  //   if (
  //     attendee &&
  //     !(attendee.clientCap & WEBNIAR_CLIENT_CAP_SUPPORT_FILE_TRANSFER)
  //   ) {
  //     return false;
  //   }
  // }

  // const participant = attendeesList.attendeesList.find(
  //   (user) => user.userId === receiverId,
  // );
  if (
    isShowPrivately &&
    !(receiverCaps & HTML5_CAPS_OPTION_SUPPORT_FILE_TRANSFER)
  ) {
    return false;
  }

  return true;
};

const getMockSessionTo = () => {
  const mockSessionTo = `${meetingConfig.mid}@${meetingConfig.xmppConfig?.wcXMPPConferenceServer}`;
  return mockSessionTo;
};
export function getXmppMsgFromToSenderNameMid(meetingJid, defaultName) {
  const resId = 'ZoomChat_pc';
  let jid = getUserXmppJidRemoveMN(meetingJid, meetingConfig.meetingNumber);
  const xmppDomain = meetingConfig.xmppConfig?.wcXMPPPingServer;
  if (jid && xmppDomain && !jid.endsWith(xmppDomain)) {
    jid = `${jid}@${xmppDomain}`;
  }
  return {
    from: jid ? `${jid}/${resId}` : '',
    to: getMeetingChannelId() || getMockSessionTo(),
    senderName: getUserFullName() || defaultName,
    isLogin: meetingConfig.isLogin,
    meetid: meetingConfig.mid,
  };
}

export const PADDING_PX = 10;
export const MAIN_AVATAR_WIDTH = 50;
export const THREAD_AVATAR_WIDTH = 40;

export function renderHelper(
  { previous, current, index, showUserProfileIcon, isReply },
  styles,
) {
  const { voteMap, ...headerInfo } = current;
  const {
    senderId: preSenderId,
    receiverId: preReceiverId,
    receiverJid: preReceiverJid,
    sender: preSender,
    threadCount: preThreadCount,
    isSilentModeUser: preIsSilentModeUser,
    isThread: preIsThread,
    cat: preCat,
    isFile: preIsFile,
    voteMap: preHasVote,
    isEdited: preIsEdited,
  } = previous;

  const {
    senderId: lastSenderId,
    receiverId: lastReceiverId,
    receiverJid: lastReceiverJid,
    sender: lastSender,
    isSilentModeUser: lastIsSilentModeUser,
    isEdited: lastIsEdited,
    isFile,
    isConveyByXmpp,
    threadCount,
    // cat,
    // mainMsgId,
    // mainMsgTime,
    // t,
  } = headerInfo;

  const isAppendToLastMessage =
    !voteMap &&
    !preHasVote && // voted message always header
    !isFile && // file always a single group
    !threadCount && // thread always is a single group
    !lastIsEdited &&
    !preIsEdited &&
    lastSender === preSender &&
    lastSenderId === preSenderId &&
    ((isConveyByXmpp && lastReceiverJid === preReceiverJid) ||
      (lastReceiverId === preReceiverId &&
        lastIsSilentModeUser === preIsSilentModeUser &&
        !preIsFile));
  const isHeader =
    index === 0 ||
    !!preCat || // previous has cat
    lastIsEdited || // previous has been edited
    preThreadCount > 0 || // previous has thread
    !isAppendToLastMessage ||
    (!isReply && preIsThread) ||
    isFile || // file always header
    !!voteMap; // voted message always header

  const isThreadHeader =
    (isReply && !previous.isThread) || (isReply && !isAppendToLastMessage);
  Object.assign(styles, {
    paddingLeft: !showUserProfileIcon
      ? PADDING_PX
      : isHeader
      ? '2px'
      : MAIN_AVATAR_WIDTH,
    width: isReply
      ? styles.width - PADDING_PX // (showUserProfileIcon ? MAIN_AVATAR_WIDTH : PADDING_PX)
      : styles.width,
    // width: styles.width,
    ...(isReply &&
      !isThreadHeader && {
        paddingLeft: showUserProfileIcon ? THREAD_AVATAR_WIDTH : PADDING_PX,
      }),
    ...(isThreadHeader && {
      paddingLeft: showUserProfileIcon ? 0 : PADDING_PX,
    }),
    ...(isReply && {
      marginLeft: showUserProfileIcon ? MAIN_AVATAR_WIDTH : PADDING_PX,
    }),
  });

  return { updatedStyles: styles, isThreadHeader, isHeader };
}

// Not support to render as <img> in blob format: svg
// IM client not support preview: webp, svg
export const ImageExtentions = ['png', 'jpg', 'jpeg', 'gif'];

function arrToMap(arr) {
  return arr.reduce(function (result, item) {
    result[item] = item;
    return result;
  }, {});
}

export const FILE_EXTENTIONS = {
  unknown: 'unknown',
  ...arrToMap(ImageExtentions),
};

export function getFileExt(fileName) {
  if (!fileName?.split) {
    return FILE_EXTENTIONS.unknown;
  }
  const nameArr = fileName.split('.');
  const ext = nameArr[nameArr.length - 1];
  return FILE_EXTENTIONS[ext] || FILE_EXTENTIONS.unknown;
}

export function clearSelection() {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }
}
/**
 *
 * longestCommonSubString('xxx@abc x @abcdsadfasdf@abcxxxx', '@abc')
 * {result: 4, indexes: [7, 14, 27]}
 *
 * longestCommonSubString('xxx@abc x @abcdsadfasdf@abcxxxx', '@abcx')
 * {result: 5, indexes: [28]}
 */
export function longestCommonSubString(long, short) {
  let m = long.length;
  let n = short.length;
  let dp = [new Array(n + 1).fill(0)];
  let result = 0;
  let indexes = [];
  for (let i = 1; i <= m; i++) {
    dp[i] = [0];
    for (let j = 1; j <= n; j++) {
      if (long[i - 1] === short[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
        // console.log(result, dp[i][j])
        // result = Math.max(dp[i][j], result);
        if (result < dp[i][j]) {
          result = dp[i][j];
          indexes = [i];
        } else if (result === dp[i][j]) {
          if (indexes[indexes.length - 1] < i) {
            indexes.push(i);
          }
        }
      } else {
        dp[i][j] = 0;
      }
    }
  }
  // console.log(dp)
  return { result, indexes };
}
export function matchJid(jidFromNative = '@', strConfUserID = '') {
  return (
    jidFromNative.split('@')[0]?.toLowerCase() === strConfUserID.toLowerCase()
  );
}

export function isJidAtAll(atJid) {
  return (
    atJid.endsWith('atall') ||
    !!(
      meetingConfig.meetingOptions.channelId &&
      atJid.indexOf(meetingConfig.meetingOptions.channelId) !== -1
    ) ||
    atJid.indexOf(getSafeBase64(meetingConfig.mid)) !== -1
  );
}

// es-ES, en-US, de-DE, zh-CN, zh-TW,fr-FR, pt-PT, jp-JP, ru-RU, ko-KO,it-IT, vi-VN,pl-PL, tr-TR, 'id-ID'. 'nl-NL'
// map jp-JP to ja-JP, ko-KO -> ko-KR
export function reMapLang(langCountry) {
  const reMap = {
    'jp-JP': 'ja-JP',
    'ko-KO': 'ko-KR',
  };
  if (reMap[langCountry]) return reMap[langCountry];
  return langCountry;
}

export function generateLocalMsgId(currentUser) {
  if (currentUser.uniqueIndex) {
    return `${currentUser.uniqueIndex}-{${generateUUID()}}`;
  }
  return `{${generateUUID()}}`;
}

export function getCompatibleOldChatText(meetingChat, replyTo, originalInput) {
  if (_.isEmpty(replyTo) || !replyTo.mainMsgId) return originalInput;

  const mainChat = meetingChat.find(
    (item) => item.xmppMsgId === replyTo.mainMsgId,
  );

  if (mainChat) {
    const txt = mainChat.isFile
      ? mainChat.content.file.fileName
      : mainChat.content.text;

    if (txt) {
      return `${ChatReplyToOldTxt} "${cutString(txt, 20)}"\n\n${originalInput}`;
    }
    return originalInput;
  }

  return originalInput;
}
