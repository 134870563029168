import { iText } from '../../global/util';

export const everyoneText = iText('Everyone', 'apac.wc_chat.everyone');

export const allPanelistsText = iText(
  'All panelists',
  'apac.wc_chat.all_panelists',
);
export const allPAndAText = iText(
  'All panelists and attendees',
  'apac.wc_chat.panelists_attendees',
);
export const hostAndPanelistsText = iText(
  'Hosts and Panelists',
  'apac.wc_chat.host_panelists',
);

export const noOneText = iText('No one', 'apac.wc_chat.no_one');
export const publicyOnlyText = iText(
  'Everyone publicly',
  'apac.wc_chat.everyone_publicly_fix_1',
);
export const hostOnlyText = iText('Host only', 'apac.wc_chat.host_only');
export const meetingHostOnlyText = iText(
  'Host and co-hosts',
  'apac.wc_chat.host_co_hosts',
);

export const fromText = iText('From', 'apac.wc_chat.from');
export const toText = iText('to', 'apac.wc_chat.uppercase_to_2');
export const andAllPanelistsText = iText(
  'and all panelists',
  'apac.wc_chat.cc_panelists',
);
export const chatDisableText = iText('Chat disabled', 'apac.wc_chat.disable');
export const chatDisabledByDlpText = iText(
  'Chat has been disabled by your admin for compliance purposes',
  'apac.wc_chat.disabledByDlp',
);
export const attendeeChatDisableText = iText(
  'The host has disabled attendee chat.',
  'apac.wc_chat.disable_chat',
);
export const isNotInThisMeetingText = iText(
  ' is not in this meeting',
  'apac.wc_chat.not_in_meeting',
);
export const moreText = iText('More', 'apac.wc_more');
export const AllowAttendeesToChatWithText = iText(
  'Allow attendees to chat with',
  'apac.wc_chat.allow_chat_with',
);
export const allowParticipantChatWith = iText(
  'Participants Can Chat with',
  'apac.wc_chat.allow_chat_with_meeting',
);

export const attendeesChatWithText = iText(
  'Attendees Can Chat with',
  'apac.wc_chat.attendee_chat_with',
);

export const panelistsChatWithText = iText(
  'Panelists Can Chat with',
  'apac.wc_chat.panelist_chat_with',
);
export const closeText = iText('Close', 'apac.wc_chat.close');
export const minimizeText = iText('Minimize', 'apac.wc_chat.minimize');
export const expandText = iText('Expand', 'apac.wc_chat.expand');
export const popUpText = iText('Pop Out', 'apac.wc_chat.popout');
export const zoomGroupChatText = iText(
  'Zoom Group Chat',
  'apac.wc_chat.group_chat',
);
export const newMessageText = iText('new message ↓', 'apac.wc_chat.new_msg');
export const chatBoxPlaceholderType = iText(
  'Type message here ...',
  'apac.wc_chat.type_msg',
);
export const chatBoxPlaceholderInWR = iText(
  'Chat with hosts ...',
  'apac.wc_chat.type_msg_hosts',
);
export const everyoneText2 = iText(
  'Everyone publicly and privately',
  'apac.wc_chat.everyone_2',
);
export const everyoneTextMeeting = iText(
  'Everyone and anyone directly',
  'apac.wc_chat.everyone_directly',
);
export const hostText2 = iText('(Host)', 'apac.wc_host');
export const coHostText = iText('(Co-host)', 'apac.wc_co_host');
export const panelistText = iText('Panelist', 'apac.wc_webinar_panelist');
export const chatDisabledIBText = iText(
  'Chat is disabled because your admin has restricted communication between certain users.',
  'apac.wc_chat.disabled_ib',
);
export const meText = iText('Me', 'apac.wc_chat.me');
export const meTextLowerCase = iText('me', 'apac.wc_chat.me_lower');
export const inWaitingRoomText = iText(
  'in Waiting Room',
  'apac.wc_chat.in_waiting_room',
);
export const inMeetingText = iText('in Meeting', 'apac.wc_chat.in_meeting');
export const privatelyText = iText(
  'Direct Message',
  'apac.wc_chat.direct_message',
);

export const meetingMessageText = iText(
  'Meeting Message',
  'apac.wc_chat.meeting_message',
);
export function improperChatReceiverText(receiver) {
  return iText(
    `you cannot chat with ${receiver} during this meeting`,
    'apac.wc_chat.improper_receiver',
    [receiver],
  );
}
export const waitingRoomOnlyHostWarningText = iText(
  'Only host and co-host can send messages to everyone in waiting room',
  'apac.wc_chat.waiting_room_warning',
);

export const waitingRoomParticipantsText = iText(
  'Waiting Room Participants',
  'apac.wc_chat_waiting_room_participants',
);

export const waitingRoomText = iText(
  'Waiting Room',
  'apac.wc_chat_waiting_room',
);

export const hostsText = iText('Hosts', 'apac.wc_hosts');

export const FILE_DOWNLOAD_TIP = iText(
  'Click to download',
  'apac.wc_chat_file_download_tip',
);

export const DOWNLOAD = iText('Download', 'apac.wc_download');

export const FILE_RESEND_TIP = iText(
  'Click to resend',
  'apac.wc_chat_file_resend_tip',
);

export const MESSAGE_COULD_NOT_SEND_TIP = iText(
  'Message could not send',
  'apac.wc_chat_message_not_send_tip',
);

export const FILE_BLOCKED_TIP = iText(
  'File is blocked for security reasons',
  'apac.wc_chat_file_blocked_tip',
);

export const FILE_EXCEEDS_LIMIT_TIP = iText(
  "Your file cannot be sent because it exceeds your account's max file size limit",
  'apac.wc_chat_file_exceeds_limit_tip',
);

export const FILE_UPLOAD_TEXT = iText(
  'Click to upload',
  'apac.wc_chat_file_upload_text',
);

export const FILE_DOWNLOADED_TEXT = iText(
  'Downloaded on computer',
  'apac.wc_chat_file_donwloaded_text',
);

export const FILE_DOWNLOADED_AGAIN_TEXT = iText(
  'Click to download again',
  'apac.wc_chat_file_donwloaded_again_text',
);

export const showChatPreviewsText = iText(
  'Show Chat Previews',
  'apac.wc_chat.show_chat_previews',
);

export function restrictDirectMsgText(receiver) {
  return iText(
    `${receiver} is restricted from receiving direct messages.`,
    'apac.wc_chat_restrict_direct_message',
    [receiver],
  );
}

export const selfRestrictDirectMsgText = iText(
  'You are restricted from sending direct messages.',
  'apac.wc_chat_restrict_direct_message_self',
);

export const FILE_REMOVED_TIP = iText(
  'File is removed',
  'apac.wc_chat_file_removed_tip',
);

export const FILE_UPLOADING_TIP = iText(
  'File is uploading',
  'apac.wc_chat_file_uploading_tip',
);

export const FILE_DOWNLOADING_TIP = iText(
  'File is downloading',
  'apac.wc_chat_file_downloading_tip',
);

export const thirdPartyFileFromTip = (shareTypeName) =>
  iText(
    `File from ${shareTypeName}`,
    'apac.wc_chat_third_party_file_from_tip',
    [shareTypeName],
  );

export const thirdPartyFileOpenTip = (shareTypeName) =>
  iText(
    `Click to open in ${shareTypeName}`,
    'apac.wc_chat_third_party_file_open_tip',
    [shareTypeName],
  );

export const CHAT_MESSAGE_OPTION_TIP = iText(
  'chat message option',
  'apac.wc_chat_message_option_tip',
);

/* new chat text */
export const REPLY = iText('Reply', 'apac.wc_chat_reply');
export const REPLIES = iText('Replies', 'apac.wc_chat_replies');
export const COLLAPSE_ALL = iText('Collapse All', 'apac.wc_bo.collapse_all');
// export const VIEW_MORE = iText('View more', 'apac.wc_chat_view_more');
export function viewMoreText(count) {
  return iText(`View ${count} More...`, 'apac.wc_chat_view_more_count', [
    count,
  ]);
}
export const imageSentTxt = (name) =>
  iText(`${name} has sent you an image`, 'apac.wc_chat_send_image', [name]);
export const fileSentTxt = (name) =>
  iText(`${name} has sent you an file`, 'apac.wc_chat_send_file', [name]);

export const chatHeaderInfoTitle = iText(
  'Continue the Conversation',
  'apac.chat.header_info_title',
);
export const chatHeaderInfoContent = iText(
  "There's now a meeting group chat named after this meeting in Team Chat. Continue the conversation there at any time.",
  'apac.chat.header_info_content3',
);

export const chatHeaderInfoTitleUnLogin = iText(
  "You're chatting as a guest",
  'apac.chat.header_info_title_un_login',
);
// export const chatHeaderInfoContentUnLogin = iText(
//   "Messages sent here are also posted in the dedicated meeting group chat in Team Chat. As a guest, you can only see and respond to messages posted during the meeting, but Members of the meeting group chat can see, save and share what you post even if they aren't present in the meeting.",
//   'apac.chat.header_info_content_un_login',
// );
// export const chatHeaderInfoContentUnLogin = iText(
//   "Messages sent here are also posted in the dedicated meeting group chat in Team Chat. As a guest, you can only see and respond to messages posted during the meeting, but non-guest participants can see, save and share what you post even if they aren't present in the meeting.",
//   'apac.chat.header_info_content_un_login2',
// );

export const getChatHeaderInfoContentUnLogin = (p, d) => {
  return iText(
    `Messages sent here are also posted in a meeting group chat in Team Chat. This means: ${p}
    Guests like you can post and see messages only during the meeting. ${d}All others can see, save, and share all posts even if they aren't present in the meeting.
    `,
    'apac.chat.header_info_content_un_login3',
    [p, d],
  );
};

export const chatHeaderInfoTitleExternal = iText(
  'This is a meeting group chat',
  'apac.chat.header_info_title_external',
);

// export const chatHeaderInfoContentExternal = iText(
//   "Messages sent here are also posted in the dedicated meeting group chat in Team Chat. As a guest, you can only see and respond to messages posted during the meeting, but non-guest participants can see, save and share what you post even if they aren't present in the meeting.",
//   'apac.chat.header_info_content_external_update',
// );

// update 5.15.5
export const getChatHeaderInfoContentExternal = (p, d) =>
  iText(
    `Messages sent here are also posted in a meeting group chat in Team Chat. This means: ${p}
    Guests like you can post and see messages only during the meeting. ${d} All others can see, save, and share all posts even if they aren't present in the meeting.
    `,
    'apac.chat.header_info_content_external_update2',
    [p, d],
  );

export const chatHeaderInfoContentExternal2 = iText(
  'To continue chatting after the meeting, ask the host to add you to the chat.',
  'apac.chat.header_info_content_external3',
);
// export const chatHeaderInfoContentFirstIMDisable = iText(
//   'Messages sent here are also posted in the dedicated meeting group chat in Team Chat. As a guest, you can only see and respond to messages posted during the meeting, but non-guest participants can see, save and share what you post even if they aren’t present in the meeting.',
//   'apac.chat.header_info_disable_im',
// );

export const getChatHeaderInfoContentFirstIMDisable = (
  paragraph,
  delimiter,
) => {
  return iText(
    `Messages sent here are also posted in a meeting group chat in Team Chat. This means: ${paragraph} Guests like you can post and see messages only during the meeting. ${delimiter}All others can see, save, and share all posts even if they aren't present in the meeting.`,
    'apac.chat.header_info_disable_im_paragraph',
    [paragraph, delimiter],
  );
};

export const chatHeaderInfoContentIMDisable = iText(
  'To continue chatting after the meeting, ask your admin to enable Team Chat.',
  'apac.chat.header_info_disable_im',
);

export const getChatHeaderInfoContentWeb = (tag) => {
  return iText(
    `There's now a meeting group chat named after this meeting in Team Chat. You can find this meeting chat and continue the conversation there any time. To use Team Chat, ${tag} and sign in the Zoom app`,
    'apac.chat.header_info_content_web3',
    [tag],
  );
};
export const chatYourComputer = iText(
  'Your Computer',
  'apac_chat.your_computer',
);
export const downLoadTxt = iText('download', 'apac.wc_download');

export const FILE_DOWNLOAD_DIALOG_TITLE = iText(
  'Potential Security Issue Detected',
  'apac.chat.file_download_dialog_title',
);

export const fileDownloadDialogContent = (username, filename) =>
  iText(
    `${username} sent you ${filename}. Files with this extension may be harmful to your computer. Are you sure you want to download this file?`,
    'apac.chat.file_download_dialog_content',
    [username, filename],
  );

export const FILE_DOWNLOAD_TRUST_TEXT = iText(
  'Always trust files from this sender',
  'apac.chat.file_download_trust_text',
);
