// import axios from 'axios';
import meetingConfig from 'meetingConfig';
import { coOrHostSelector } from '../../../global/redux/selector';
import {
  CHAT_CONSTANTS,
  CHAT_MSG_FROM,
  CHAT_THREAD,
  IQ_FROM_IM,
  JOIN_MEETING_POLICY,
  SEND_CHAT_WARNING,
} from '../../../global/constant';
import { getMeetingChannelId, isEnablePMC } from '../../../global/service';
import { decodeBase64, encodeBase64 } from '../../../global/util';
import {
  CHAT_MSG_TYPE_TO_ALL,
  CHAT_MSG_TYPE_TO_SILENT_MODE_USERS,
  // CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
  CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS,
  CHAT_FILE_TYPE,
} from '../../chat/constants';
import { hostsText } from '../../chat/resource';

import {
  beginDecrypt,
  beginEncrypt,
  ivType,
} from '../../../global/crypto/index';
import { isSupportedTimeZone } from '../../reaction/utils';
import {
  pushSysInfo,
  updateNewMeetingChat,
  toggleThread,
  updateNewChatIndexes,
  removeThreadInput,
  changeReadNewChatState,
  addThreadInput,
  saveLatestMessage,
  setQuoteText,
} from './new-chat-action';
import { WS_CONF_CHAT_REQ } from '../../../constants/ZoomSocketEventTypes';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { extractMsgFromXml, extractMsgFromIM } from '../transform/cover';
import { buildSendXmppMsgData } from '../transform/buildSendXmppMsgData';
import { errorLog, infoLog } from '../../../global/web-client-logger';
import { YOU as youTxt } from '../../../global/resource';
import { JoinAsGuest } from '../resource';
import { buildListMeetingChat } from './buildListMeetingChat';
import {
  getCorrect3rdPartyFileUrl,
  getFileDownloadUrl,
  isBuildInChatReceiver,
} from '../../chat/utils';
import {
  checkBeforeSentAndGetReceiverId,
  generateLocalMsgId,
  getXmppMsgFromToSenderNameMid,
  getCompatibleOldChatText,
} from '../help';
import { tryUpdateChatReceiver } from '../../chat/redux/chat-thunk-action';
import {
  sendMentionMessage,
  updateEditedChatMessage,
} from './new-chat-core-thunk';
import { updateReceiverEmojis } from './chat-iq-thunk';
import { createCollectorThunk } from '../../../global/utils/create-collector-thunk';
import { isString } from 'lodash';
import { generateUUID } from '../../../global/service/generate-uuid';
import { isViewOnly } from '../../../global/service/user-types';
import { setChatWarning as showNewChatWarning } from '../../../actions/MeetingUIPureAction';

export function tryUpdateReceiverWhenRecDm() {
  return (dispatch, getState) => {
    const {
      chat: { receiverId, chatDraft },
      newChat: { meetingChat },
    } = getState();

    if (receiverId === CHAT_MSG_TYPE_TO_ALL && !chatDraft) {
      const lastChatItem = _.last(meetingChat.filter((item) => !item.cat));
      dispatch(
        tryUpdateChatReceiver({
          receiverId: lastChatItem.senderId,
          receiver: lastChatItem.chatSender.displayName,
        }),
      );
    }
  };
}

function updateWaitingRoomChat({
  latestChatMessage,
  isNewXmppChat,
  isOnHold,
  targetUser,
  receiveUser,
  isChatPinBottom,
  // currentUserId,
  // defaultAvatar,
  // currentUserRole,
  bid,
}) {
  return (dispatch, getState) => {
    const {
      newChat: { meetingChat },
      attendeesList: { attendeesList },
      meeting: { currentUser },
    } = getState();
    let senderText = hostsText;

    if (isOnHold) {
      senderText = hostsText;
    } else if (targetUser) {
      senderText = targetUser.displayName;
    }
    let promiseList = [];
    if (!isNewXmppChat) {
      promiseList.push(
        Promise.resolve({ text: decodeBase64(latestChatMessage.text || '') }),
      );
    } else {
      promiseList.push(
        extractMsgFromXml(decodeBase64(latestChatMessage.xmppMsgData)),
      );
    }
    Promise.all(promiseList)
      .then(([{ text, ...rest }]) => {
        const newMeetingChat = buildListMeetingChat(
          meetingChat,
          {
            attendees: [],
            attendeesList,
            currentUser,
          },
          {
            text,
            receiverId: latestChatMessage.attendeeNodeID,
            receiver: null,
            senderId: latestChatMessage.destNodeID,
            sender: senderText,
            isConveyByXmpp: false,
            msgId: latestChatMessage.msgID,
            isChatPinBottom,
            isSilentModeUser: Boolean(receiveUser),
            bid,
            ...rest,
            // file: ,
          },
        );
        dispatch(updateNewChatThunk(newMeetingChat));
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
      });
  };
}

export function onReceiveNewChatMessage(message) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: {
        bIbDisableChat,
        xmppUserList: { attendees },
        currentUser,
        // defaultAvatar,
        restrictFeatures,
      },
      attendeesList: { attendeesList },
      chat: { isChatPinBottom },
      // newChat: { meetingChat },
      meetingUI: { isOnHold },
    } = state;
    if (restrictFeatures[JOIN_MEETING_POLICY.CHAT]) return;
    const coOrHost = coOrHostSelector(state);

    const {
      userId: currentUserId,
      userRole: currentUserRole,
      bid,
    } = currentUser;

    /**
     * when panelist using native client sends chat messages to attendees,
     * they received two identical messages from RWG Socket Channel and XMPP Socket Channel.
     * That is duplicate.
     *
     * new meeting chat not support webinar, so the isDuplicated was not used
     */
    const { attendeeNodeID, msgID, xmppMsgData } = message.body || {};
    const isNewXmppChat = !!xmppMsgData;
    // const hasOldKVChat = !!text;

    const isDuplicateChatMessage =
      isViewOnly(currentUserRole) && attendeeNodeID === currentUserId && !msgID;
    if (
      typeof message.body !== 'undefined' &&
      !bIbDisableChat &&
      !isDuplicateChatMessage
    ) {
      const latestChatMessage = message.body;
      let senderSN = latestChatMessage.sn;
      let targetUser;
      if (!senderSN) {
        targetUser = attendeesList.find(
          (user) => user.userId === latestChatMessage.destNodeID,
        );
        if (targetUser) {
          senderSN = targetUser.zoomID;
        }
      }
      /**
       * waiting room messages are unencrypted
       */
      const receiveUser = attendeesList.find(
        (user) => user.userId === latestChatMessage.attendeeNodeID,
      );

      // waiting room chat
      if (
        latestChatMessage.attendeeNodeID ===
          CHAT_MSG_TYPE_TO_SILENT_MODE_USERS ||
        latestChatMessage.attendeeNodeID ===
          CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS ||
        isOnHold ||
        (coOrHost && receiveUser?.bHold)
      ) {
        dispatch(
          updateWaitingRoomChat({
            latestChatMessage,
            isNewXmppChat,
            isChatPinBottom,
            isOnHold,
            receiveUser,
            targetUser,
            // currentUserId,
            // currentUserRole,
            // defaultAvatar,
            bid,
          }),
        );
      } else {
        // meeting chat
        const sender = attendeesList.find(
          (user) => user.userId === latestChatMessage.destNodeID,
        );
        /* eslint-disable-next-line no-prototype-builtins */
        const senderName = latestChatMessage.hasOwnProperty('senderName')
          ? decodeBase64(latestChatMessage.senderName)
          : sender.displayName;

        Promise.all([
          beginDecrypt({
            decryptedText: isNewXmppChat
              ? latestChatMessage.xmppMsgData
              : latestChatMessage.text,
            userId: senderSN,
            type: ivType.RWG_CHAT,
          }),
          // beginDecrypt({
          //   decryptedText: latestChatMessage.text,
          //   userId: senderSN,
          //   type: ivType.RWG_CHAT,
          // }),
        ])
          // receive text
          .then(([{ message } = {}]) => {
            // console.log(other);
            // console.log(message, oldMessage, 222);
            // newMessage = message;
            if (isNewXmppChat) {
              if (
                latestChatMessage.chatFrom === CHAT_MSG_FROM.CHAT_FROM_PMC_IM
              ) {
                // pmc message from channel
                return extractMsgFromIM(message);
              } else if (
                latestChatMessage.chatFrom === CHAT_MSG_FROM.CHAT_FROM_DLP
              ) {
                infoLog('from dlp server');
              } else {
                // meeting message
                return extractMsgFromXml(message, latestChatMessage.chatFrom);
              }
            } else {
              // old version chat return directly
              return Promise.resolve({
                text: message,
                msgId: latestChatMessage.msgID,
                xmppMsgId: latestChatMessage.msgID,
                isOldChat: true,
              });
            }
          })
          .then(
            ({
              text: _text,
              timeStamp,
              isEdit,
              fileID,
              isUnsupportedFeature,
              ...rest
            }) => {
              infoLog(
                'isXmpp ' +
                  isNewXmppChat +
                  'isUnsupportedFeature ' +
                  isUnsupportedFeature,
              );
              infoLog('decrypt txt: ' + _text);
              infoLog(`msg rest: ${JSON.stringify(rest)}`);

              if (rest.isAtEvent) {
                infoLog('received atEvent');
                return;
                // return dispatch(updateOneChatMessageThunk(rest));
              }
              if (rest.isVote) {
                let senderObj = sender;
                let storedSN = senderSN;
                if (rest.type === IQ_FROM_IM) {
                  // stored iq message used sn(zoomID) as key
                  // so if message from IM, use the in meeting user's sn
                  // const realSenderId = Number(latestChatMessage.realSenderID);
                  // const inMeetingAttendee = attendeesList.find(
                  //   (item) => item.userId === realSenderId,
                  // );
                  // senderObj = inMeetingAttendee || {
                  //   displayName: rest.senderName,
                  //   userId: latestChatMessage.realSenderID,
                  // };
                  // storedSN = inMeetingAttendee?.zoomID || senderSN;
                  senderObj = {
                    // txt gw  userid
                    userId: latestChatMessage.attendeeNodeID,
                    displayName: rest.senderName,
                  };
                }

                return dispatch(
                  updateReceiverEmojis({
                    ...rest,
                    senderSN: storedSN,
                    senderObj,
                    senderId: senderObj.userId,
                  }),
                );
              }
              // if (rest.atUsers) {
              //   console.log(rest.atUsers);
              // }
              const {
                newChat: { meetingChat: meetingChat2 },
                meeting: { fileServerDomain },
              } = getState();

              let msgInfo = {
                senderId: latestChatMessage.destNodeID,
                receiverId: latestChatMessage.attendeeNodeID,
              };

              // reset msg from IM, because IM message was forward by gateway
              if (
                latestChatMessage.chatFrom === CHAT_MSG_FROM.CHAT_FROM_PMC_IM
              ) {
                msgInfo.senderId = Number(latestChatMessage.realSenderID);
                msgInfo.receiverId = CHAT_MSG_TYPE_TO_ALL;
              }
              if (isUnsupportedFeature) {
                const newMeetingChat = buildListMeetingChat(
                  meetingChat2,
                  {
                    attendees,
                    attendeesList,
                    currentUser,
                  },
                  {
                    text: _text,
                    receiverId: msgInfo.receiverId,
                    senderId: msgInfo.senderId,
                    receiver: null,
                    sender: senderName,
                    isConveyByXmpp: false,
                    msgId: msgID,
                    isChatPinBottom,
                    timeStamp,
                    senderSN,
                    bid,
                    isUnsupportedFeature,
                    ...rest,
                  },
                );
                dispatch(updateNewChatThunk(newMeetingChat));
                return;
              }
              if (_text !== null && !fileID) {
                let text = _text;

                if (
                  text &&
                  text.includes('🇹🇼') &&
                  meetingConfig.meetingOptions.enableFilterTWEmoji === true &&
                  !isSupportedTimeZone()
                ) {
                  text = text.replaceAll('🇹🇼', 'TW');
                  // if receive TW flag direct return;
                }
                if (isEdit) {
                  // received edited message from IM
                  return dispatch(
                    updateEditedChatMessage({
                      text,
                      xmppMsgId: rest.targetXmppMsgId,
                      timeStamp: timeStamp || rest.timeStamp,
                      styleItems: rest.styleItems,
                      atUsers: rest.atUsers,
                    }),
                  );
                }
                const newMeetingChat = buildListMeetingChat(
                  meetingChat2,
                  {
                    attendees,
                    attendeesList,
                    currentUser,
                  },
                  {
                    text,
                    receiverId: msgInfo.receiverId,
                    senderId: msgInfo.senderId,
                    receiver: null,
                    sender: senderName,
                    isConveyByXmpp: false,
                    msgId: msgID,
                    isChatPinBottom,
                    timeStamp,
                    senderSN,
                    bid,
                    ...rest,
                  },
                );
                dispatch(updateNewChatThunk(newMeetingChat));
              } else {
                // receive file msg from IM
                // const {
                //   fileID,
                //   fileType,
                //   fileName,
                //   fileSize,
                //   fileObj,
                //   senderName,
                //   shareType,
                //   previewUrl,
                //   downloadUrl,
                // } = rest;
                const fileInfo = { ...rest, fileID };
                const { fileType } = rest;
                // if (_.isUndefined(fileType)) return;
                // const is3rdFileShareFromIM
                const is3rdFileShareFromIM =
                  fileType === CHAT_FILE_TYPE.THIRD_PARTY;
                if (is3rdFileShareFromIM) {
                  fileInfo.correctPreviewUrl =
                    getCorrect3rdPartyFileUrl(fileInfo);
                }
                // keep it same as receive im meeting file, not decoded
                fileInfo.senderName = latestChatMessage.senderName;
                fileInfo.uploadBaseInfo = { fileMsgIndex: meetingChat2.length };
                fileInfo.senderSN = senderSN;
                fileInfo.fileUrl = getFileDownloadUrl(
                  fileInfo,
                  fileServerDomain,
                );
                const newMeetingChat = buildListMeetingChat(
                  meetingChat2,
                  {
                    attendees,
                    attendeesList,
                    currentUser,
                  },
                  {
                    // receiverId: latestChatMessage.attendeeNodeID,
                    // senderId: latestChatMessage.destNodeID,
                    receiverId: msgInfo.receiverId,
                    senderId: msgInfo.senderId,
                    receiver: null,
                    sender: senderName,
                    isConveyByXmpp: false,
                    msgId: latestChatMessage.msgID,
                    isChatPinBottom,
                    file: fileInfo,
                    senderSN,
                    bid,
                    ...rest,
                  },
                );
                dispatch(updateNewChatThunk(newMeetingChat));
              }
            },
          )
          .catch((err) => {
            // eslint-disable-next-line
            console.log(err);
          });
      }
    }
  };
}

function sendRwgChat(
  { text, styleItems, mention },
  destNodeID,
  attendeeNodeID = 0,
  replyTo,
) {
  return (dispatch, getState) => {
    const {
      meeting: { zoomId, meetingTopic, currentUser },
      newChat: { meetingChat },
    } = getState();
    const localXmppMsgId = generateLocalMsgId(currentUser);
    const ct = Date.now();

    return Promise.all([
      beginEncrypt({
        text: getCompatibleOldChatText(meetingChat, replyTo, text), //`${text}`,
        type: ivType.RWG_CHAT,
      }),
      buildSendXmppMsgData({
        id: localXmppMsgId,
        file: undefined,
        message: `${text}`,
        rtbItems: { item: styleItems, mention },
        channelName: meetingTopic,
        // from: undefined,
        // to: undefined,
        // senderName: currentUser.displayName,
        ...getXmppMsgFromToSenderNameMid(
          currentUser.strConfUserID,
          currentUser.displayName,
        ),
        ct,
        replyTo,
      }).then((xml) => {
        return beginEncrypt({ text: xml, type: ivType.RWG_CHAT });
      }),
    ]).then(([oldVersionTxt, encryptedXml]) => {
      dispatch(
        sendSocketMessage({
          evt: WS_CONF_CHAT_REQ,
          body: {
            text: oldVersionTxt,
            xmppMsgData: encryptedXml,
            destNodeID,
            attendeeNodeID,
            sn: zoomId,
            msgID: localXmppMsgId,
          },
        }),
      );
      return {
        text,
        receiverId: attendeeNodeID || destNodeID,
        type: 'rwg',
        xmppMsgId: localXmppMsgId,
        rtbItems: { item: styleItems, mention },
        timeStamp: ct,
        reply: replyTo
          ? {
              mainMsgId: replyTo.mainMsgId,
              mainMsgTime: replyTo.mainMsgTime,
              owner: replyTo.owner,
              isEmpty: false,
            }
          : {
              isEmpty: true,
            },
      };
    });
  };
}

function sendRwgWaitingRoomChat({ text, styleItems, mention }, destNodeID) {
  return (dispatch, getState) => {
    const {
      meeting: { meetingTopic, currentUser },
    } = getState();
    const localXmppMsgId = generateLocalMsgId(currentUser);
    const ct = Date.now();

    return buildSendXmppMsgData({
      id: localXmppMsgId,
      file: undefined,
      message: `${text}`,
      channelName: meetingTopic,
      rtbItems: { item: styleItems, mention },
      ...getXmppMsgFromToSenderNameMid(
        currentUser.strConfUserID,
        currentUser.displayName,
      ),
      ct,
    }).then((xml) => {
      const destNode = Array.isArray(destNodeID) ? destNodeID[1] : destNodeID;
      const attendeeNodeID = Array.isArray(destNodeID) ? destNodeID[0] : 0;
      dispatch(
        sendSocketMessage({
          evt: WS_CONF_CHAT_REQ,
          body: {
            xmppMsgData: encodeBase64(xml),
            text: encodeBase64(text),
            destNodeID: destNode,
            ...(attendeeNodeID
              ? {
                  attendeeNodeID,
                }
              : {}),
            msgID: localXmppMsgId,
          },
        }),
      );

      return {
        text,
        receiverId: attendeeNodeID || destNode,
        type: 'waiting_room',
        isSilentModeUser: true,
        xmppMsgId: localXmppMsgId,
        rtbItems: { item: styleItems, mention },
        t: ct,
      };
    });
  };
}

export function sendNewChatThunk(textOrObj, destNode, replyTo) {
  let text = '';
  let styleItems = [];
  let mention = [];
  if (isString(textOrObj)) {
    text = textOrObj;
  } else {
    text = textOrObj.text;
    styleItems = textOrObj.styleItems;
    mention = textOrObj.mention;
    // TODO send mention invisible event
  }
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();
    return Promise.resolve(0)
      .then(() => {
        const promiseCollection = [];

        // waiting_room chat not support reply & thread
        if (
          destNode === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS || // host to all wr users
          destNode === CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS || // wr use reply to hosts
          Array.isArray(destNode) // host to individual waiting room user & cc other hosts
        ) {
          promiseCollection.push(
            dispatch(
              sendRwgWaitingRoomChat({ text, styleItems, mention }, destNode),
            ),
          );
        } else {
          // TODO if support webinar need update third args
          promiseCollection.push(
            dispatch(
              sendRwgChat({ text, styleItems, mention }, destNode, 0, replyTo),
            ),
          );
        }
        return Promise.all(promiseCollection);
      })
      .then((results) => {
        dispatch(
          updateSenderSelfChatMessage(
            results.find((item) => item.type === 'xmpp') || results[0],
          ),
        );
        // }
        const msgItem = results[0];
        if (msgItem.rtbItems?.mention?.length) {
          // const mentions = results[0].rtbItems.mention.map(m => {
          //   return `${m.jid}@${}`
          // })
          dispatch(
            sendMentionMessage({
              mention: msgItem.rtbItems?.mention,
              localXmppMsgId: generateLocalMsgId(currentUser),
              atMsgId: msgItem.xmppMsgId,
              atMsgT: msgItem.timeStamp,
              destNodeID: destNode,
              attendeeNodeID: 0,
              sn: currentUser.zoomId,
              ct: Date.now(),
              currentUser,
              mainMsgId: replyTo?.mainMsgId,
              mainMsgTime: replyTo?.mainMsgTime,
            }),
          );
        }
      })
      .catch(console.error); // eslint-disable-line no-console
  };
}

/**
 *
 * @param {*} chatMessage.reply reply object
 * @param {*} reply.mainMsgId string
 * @param {*} reply.mainMsgTime string
 * @param {*} reply.owner string
 * @param {*} isSendByXmpp
 * @returns dispatch updateNewChatThunk
 */
export function updateSenderSelfChatMessage(chatMessage, isSendByXmpp = false) {
  return (dispatch, getState) => {
    const {
      chat: { isChatPinBottom },
      meeting: {
        currentUser,
        xmppUserList: { attendees },
      },
      attendeesList: { attendeesList },
      newChat: { meetingChat },
    } = getState();
    const {
      text,
      receiverId,
      receiverJid,
      receiver,
      isSilentModeUser,
      file,
      xmppMsgId,
      ...rest // include xmppMsgId generate by local UUID & timeStamp, replyTo
    } = chatMessage;
    const { userId: currentUserId, bid, strConfUserID } = currentUser;
    const ownerRes = strConfUserID
      ? `${strConfUserID}@${meetingConfig.xmppConfig?.wcXMPPPingServer}/ZoomChat_pc`
      : '';
    const newMeetingChat = buildListMeetingChat(
      meetingChat,
      {
        attendees,
        attendeesList,
        currentUser,
      },
      {
        text,
        receiverId,
        receiver,
        receiverJid,
        senderId: currentUserId,
        sender: youTxt,
        isSenderMe: true,
        isConveyByXmpp: isSendByXmpp,
        isChatPinBottom,
        isSilentModeUser,
        file,
        msgId: xmppMsgId,
        xmppMsgId,
        senderSN: window.easyStore.getCurrentUserId(),
        ownerRes,
        bid,
        ...rest,
      },
    );
    dispatch(updateNewChatThunk(newMeetingChat, true));
  };
}

export function updateNewChatSys(newUsers) {
  return (dispatch, getState) => {
    if (!newUsers) return;
    const { currentUser } = getState().meeting;
    if (!isEnablePMC() || !getMeetingChannelId()) return;
    if (currentUser.bid) return;
    let infos = [];
    for (let index = 0; index < newUsers.length; index++) {
      const newUser = newUsers[index];
      let text;
      const name = newUser.displayName;
      if (newUser.isGuest) {
        // eslint-disable-next-line @babel/new-cap
        text = JoinAsGuest(name);
        infos.push({
          cat: CHAT_CONSTANTS.SYS,
          msgId: `${generateUUID()}`,
          text,
        });
      } else {
        // text = `${name} ${JoinTxt}`;
        //! should not display sys message if joined user is not guest
      }
    }

    dispatch(pushSysInfo(infos));
  };
}

export function changeThreadState(index) {
  return (dispatch, getState) => {
    const { meetingChat } = getState().newChat;
    const targetMsg = meetingChat[index];
    const { fold } = targetMsg;
    const newVal =
      fold !== CHAT_THREAD.EXPAND ? CHAT_THREAD.EXPAND : CHAT_THREAD.COLLAPSE;
    dispatch(
      toggleThread({
        index: index,
        val: newVal,
      }),
    );
    // remove thread input if has no message draft
    // if (targetMsg.hasInput) {
    //   const inputIndex = (targetMsg.threadCount || 0) + index + 1;
    //   // if (meetingChat[inputIndex].messageDraft) return;
    //   // const newMeetingChat = produce(meetingChat, (draft) => {
    //   //   draft[index].hasInput = false;
    //   //   draft.splice(inputIndex, 1);
    //   //   return draft;
    //   // });

    //   // dispatch(updateNewMeetingChat({ newMeetingChat }));
    //   dispatch(removeThreadInputThunk(inputIndex, targetMsg.xmppMsgId));
    // }
  };
}
export function updateNewChatThunk(
  { newMeetingChat, /* insertIndex */ msgId, chatItem },
  isSelf = false,
) {
  return (dispatch, getState) => {
    dispatch(updateNewMeetingChat({ newMeetingChat }));
    dispatch(saveLatestMessage(chatItem));
    if (isSelf) return;
    setTimeout(() => {
      const {
        newChat: { meetingChat },
      } = getState();
      const targetIndex = meetingChat.findIndex((chat) => chat.msgId === msgId);
      if (targetIndex > -1 && !meetingChat[targetIndex]?.read) {
        dispatch(
          updateNewChatIndexes({ insertIndex: targetIndex, msgId, chatItem }),
        );
      }
    }, 500);
  };
}

export const debounceChangeReadChatState = createCollectorThunk(
  changeReadNewChatState,
  150,
  { trailing: true, leading: false },
);
export function focusInput(containerIndex) {
  const inputItemRow = document.getElementById(
    `chat-item-container-${containerIndex}`,
  );
  inputItemRow?.querySelector('.ProseMirror')?.focus();
}

// todo
/*
    if quote thread, try to add input box and set quote
    if quote main message
      if has input , set thread input quote
      if no input, set bottom quote
    if quote waiting room message, set bottom quote directly

    */
export function addQuoteThunk(index, mainMsgId) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      newChat: { meetingChat },
      // meeting: { currentUser, chatPriviledge },
      // attendeesList: { attendeesList },
    } = state;

    if (!mainMsgId) {
      errorLog(`received id ${mainMsgId}`);
      return Promise.reject('not support quote');
    }
    const mainMessageIndex = meetingChat.findIndex(
      (item) =>
        (item.xmppMsgId && item.xmppMsgId === mainMsgId) ||
        item.msgId === mainMsgId,
    );
    if (mainMessageIndex === -1)
      return Promise.reject('cannot find main message');

    const clickedMessage = meetingChat[index];
    const mainMessage = meetingChat[mainMessageIndex];

    const quoteText = clickedMessage.content.text;
    if (mainMessage.hasInput) {
      dispatch(
        setQuoteText({
          isBottom: false,
          quoteText,
          index:
            mainMessageIndex +
            mainMessage.threadCount +
            1 -
            mainMessage.truncateCount,
        }),
      );
    } else {
      //set quote to bottom box
      dispatch(setQuoteText({ isBottom: 1, quoteText }));
    }
  };
}

export function addThreadInputThunk(index, mainMsgId) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      newChat: { meetingChat },
      meeting: { currentUser, chatPriviledge },
      attendeesList: { attendeesList },
    } = state;
    const isMeInRoom = !!currentUser.bid;
    if (!mainMsgId) {
      errorLog(`received id ${mainMsgId}`);
      dispatch(showNewChatWarning([SEND_CHAT_WARNING.UNSUPPORTED_TYPE]));
      return Promise.reject('not support reply');
    }
    const mainMessageIndex = meetingChat.findIndex(
      (item) =>
        (item.xmppMsgId && item.xmppMsgId === mainMsgId) ||
        item.msgId === mainMsgId,
    );
    if (mainMessageIndex === -1)
      return Promise.reject('cannot find main message');
    const mainMessage = meetingChat[mainMessageIndex];

    const insertInputIndex =
      mainMessageIndex + (mainMessage.threadCount || 0) + 1;

    if (mainMessage.hasInput) {
      const inputIndex =
        mainMessageIndex +
        mainMessage.threadCount +
        1 -
        mainMessage.truncateCount;
      return Promise.resolve({
        index: inputIndex,
      });
    }

    // waiting room chat not support replies reactions
    if (
      mainMessage.receiverId === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS ||
      mainMessage.receiverId === CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS ||
      mainMessage.isSilentModeUser
    ) {
      dispatch(showNewChatWarning([SEND_CHAT_WARNING.EXCEPTION_WR]));
      return Promise.reject('not support reply');
    }
    const isBuiltInChatType = isBuildInChatReceiver(mainMessage.receiverId);

    let inputReceiverId, receiverSN, selfCaps, receiverCaps, receiver;
    /**
     * send replies is reply to message's owner
     * get message owner
     */
    if (isBuiltInChatType) {
      inputReceiverId = mainMessage.receiverId;
      selfCaps = currentUser.caps;
      receiver = mainMessage.receiver;
    } else if (mainMessage.isShowPrivately) {
      if (mainMessage.isMyMessage) {
        inputReceiverId = mainMessage.receiverId;
        receiver = mainMessage.receiver;
        receiverSN = mainMessage.chatReceiver?.zoomID;
        selfCaps = currentUser.caps;
        receiverCaps = mainMessage.chatReceiver?.caps;
      } else if (mainMessage.isToMyMessage) {
        inputReceiverId = mainMessage.senderId;
        receiver = mainMessage.sender;
        receiverSN = mainMessage.senderSN;
        selfCaps = currentUser.caps;
        receiverCaps = mainMessage.chatSender?.caps;
      }
    }
    const beforeCheckVals = checkBeforeSentAndGetReceiverId(
      {
        // receiver: mainMessage.receiver,
        receiverId: mainMessage.receiverId,
        receiverSN: receiverSN,
        attendeesList,
        isMeInRoom,
        chatPriviledge,
        currentUserPrivateChatMsgDisabled: false,
        currentIsCoOrHost: currentUser.isHost || currentUser.bCoHost,
        isSilentModeUser: mainMessage.isSilentModeUser,
        isSameMeetingMessage: mainMessage.bid === currentUser.bid,
      },
      true,
    );
    if (beforeCheckVals[0] !== SEND_CHAT_WARNING.NONE) {
      dispatch(showNewChatWarning([beforeCheckVals[0], receiver]));
      return Promise.reject('priviledge check failed');
    }

    const item = {
      isThread: true,
      cat: CHAT_CONSTANTS.INPUT,
      receiverId: inputReceiverId, //mainMessage.receiverId,
      receiver,
      msgId: generateUUID(),
      messageDraft: {
        // quoteText,
      },
      mainMsgId: mainMessage.xmppMsgId || mainMessage.msgId,
      receiverSN,
      mainMsgTime: mainMessage.t,
      owner: mainMessage.ownerRes,
      selfCaps,
      receiverCaps,
      bid: currentUser.bid,
    };

    // dispatch(updateNewMeetingChat({ newMeetingChat }));
    dispatch(addThreadInput({ item, mainMessageIndex, insertInputIndex }));
    // setTimeout(() => {
    //   // const inputItemRow = document.getElementById(
    //   //   `chat-item-container-${insertInputIndex}`,
    //   // );
    //   // inputItemRow?.getElementsByTagName('textarea')[0]?.focus();
    //   focusInput(insertInputIndex);
    // }, 30);
    return Promise.resolve({ index: insertInputIndex });
  };
}

export function removeThreadInputThunk(index, mainMsgId) {
  return (dispatch, getState) => {
    const {
      newChat: { meetingChat },
    } = getState();
    if (!index || !mainMsgId) return;
    const mainMessageIndex = meetingChat.findIndex(
      (item) => item.xmppMsgId === mainMsgId,
    );

    const inputItem = meetingChat[index];
    // const mainMessage = meetingChat[mainMessageIndex];
    if (inputItem && inputItem.cat !== CHAT_CONSTANTS.INPUT) return;

    dispatch(removeThreadInput({ removeIndex: index, mainMessageIndex }));
  };
}
