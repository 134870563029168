import {
  resetPollLoadingStatus,
  setPollingState,
  setIsPollingRequestSent,
  setPollData,
  recheckCanSubmit,
  setWaitingRwgPollStateLoading,
  setPollingDocMaxSize,
  setPollingDocSize,
  setPollingUrlType,
  setEditingPollingId,
  setHighlightPollingIds,
  setPollingId,
  setModuleFrom,
  setSavedPollName,
  setPollListLoading,
  setPollingDocLibraryMaxSize,
  setPollingDocLibrarySize,
} from './poll-action';
import { mapSubmitDataFromPollDataItem } from '../utils';
import { coOrHostSelector } from '../../../global/redux/selector';
import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import { toggleInstanceOpen } from '../../dialog/redux/dialog-action';
import Modal from '../../../global/components/widget/modal';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { POLLING_APAC } from '../resource';
import {
  BACK_TO_CLIENT_ACTION,
  MODULE_FROM,
  PollingActionTypes,
  PollingStatus,
  POLL_API,
  POLL_URL_TYPE,
} from '../constants';
import { isNotInRoom } from '../../breakout-room/utils';
import { restrictPollingSelector } from './poll-selector';
import { PWAMeetingEvent, sendMsgToPWA } from '../../../global/pwa-integration';
import axios from 'axios';
import meetingConfig from 'meetingConfig';
import { CANCEL, DIALOG_CANCEL, DIALOG_CONTINUE } from '../../dialog/resource';
import { isWebinar } from '../../../global/service/meeting-types';

export function setPollingStateThunk(data) {
  return (dispatch, getState) => {
    const state = getState();
    const isPollingRestrict = restrictPollingSelector(state);
    if (isPollingRestrict) return;
    const coOrHost = coOrHostSelector(state);
    dispatch(setPollingState({ ...data, coOrHost }));
    dispatch(recheckCanSubmit());
    const previousPollingStatus = state.poll.pollingStatus;
    const currentPollingStatus = data.status;
    if (
      previousPollingStatus !== PollingStatus.ZMPollUIStatus_Doing_Poll &&
      currentPollingStatus === PollingStatus.ZMPollUIStatus_Doing_Poll
    ) {
      sendMsgToPWA(PWAMeetingEvent.POLL_INBOX);
    }
    if (
      previousPollingStatus !== PollingStatus.ZMPollUIStatus_Viewing_Results &&
      currentPollingStatus === PollingStatus.ZMPollUIStatus_Viewing_Results
    ) {
      sendMsgToPWA(PWAMeetingEvent.POLL_RESULT_SHARED);
    }
  };
}

const defaultOkButtonProps = {
  type: 'primary',
  size: 'default',
  className: 'zm-btn-legacy',
};
const defaultCancelButtonProps = {
  type: 'default',
  size: 'default',
  className: 'zm-btn-legacy',
};
const deleteOkButtonProps = {
  type: 'error',
  size: 'default',
  className: 'zm-btn-legacy',
};

const defaultClassName = 'zm-modal-legacy dialog-reconnect-container';

const sendPollingAction =
  (action, pollingId, restData) => (dispatch, getState) => {
    const state = getState();
    const {
      poll: {
        loadingStatus: { rwgStatusChangeCount: prevRwgStatusChangeCount },
      },
    } = state;
    const data = {
      evt: socketEventTypes.WS_CONF_POLLING_USER_ACTION_REQ,
      body: {
        action,
        PollingId: pollingId,
        ...restData,
      },
    };
    dispatch(sendSocketMessage(data));

    dispatch(setWaitingRwgPollStateLoading(true));
    setTimeout(() => {
      const {
        poll: {
          loadingStatus: { rwgStatusChangeCount: nextRwgStatusChangeCount },
        },
      } = getState();
      if (prevRwgStatusChangeCount === nextRwgStatusChangeCount) {
        dispatch(setWaitingRwgPollStateLoading(false));
      }
    }, 10 * 1000);
  };

export const startPolling = (pollingId) => (dispatch) => {
  dispatch(sendPollingAction(PollingActionTypes.start, pollingId));
};

export const endPolling = (pollingId) => (dispatch) => {
  dispatch(sendPollingAction(PollingActionTypes.close, pollingId));
};

export const relaunchPolling = (pollingId) => (dispatch) => {
  dispatch(sendPollingAction(PollingActionTypes.reopen, pollingId));
};

export const stopSharePolling = (pollingId) => (dispatch) => {
  dispatch(sendPollingAction(PollingActionTypes.stopShareResult, pollingId));
};

export const startSharePolling = (pollingId) => (dispatch) => {
  dispatch(sendPollingAction(PollingActionTypes.startShareResult, pollingId));
};

export const submitPolling = (pollingData, pollingId) => (dispatch) => {
  const Questions = pollingData.Questions.map(mapSubmitDataFromPollDataItem);

  dispatch(
    sendPollingAction(PollingActionTypes.submit, pollingId, {
      submitData: JSON.stringify({ Questions }),
    }),
  );
};

export const pollingRequestDoc = () => (dispatch) => {
  dispatch(
    sendSocketMessage({
      evt: socketEventTypes.WS_CONF_POLLING_REQ,
      body: {
        bOn: true,
      },
    }),
  );

  dispatch(setIsPollingRequestSent(true));
};

export const onPollDataReceived = (data) => (dispatch, getState) => {
  const isPollingRestrict = restrictPollingSelector(getState());
  if (isPollingRestrict) return;
  const coOrHost = coOrHostSelector(getState());
  dispatch(setPollData({ pollList: data, coOrHost }));
  dispatch(setPollListLoading(false));
};

export const pollingErrorPopUp = ({ error, errorMsg }) => {
  return (dispatch) => {
    let content = '';
    if (error === 1) {
      if (errorMsg) {
        content = errorMsg;
      } else {
        content =
          'Your submitting is failed, because the polling has been closed.';
      }
    } else if (Math.floor(error / 1000) === 1) {
      content = 'Some thing went wrong, please retry.';
    } else {
      content = `error code: ${error}`;
    }
    dispatch(toggleInstanceOpen(true));
    Modal.confirm({
      className: defaultClassName,
      okText: 'Continue',
      okButtonProps: defaultOkButtonProps,
      contentLabel: 'polling error confirm dialog',
      title: 'Polling Error',
      okCancel: false,
      content,
      onOk: () => {
        dispatch(resetPollLoadingStatus());
      },
      onAfterClose: () => {
        dispatch(toggleInstanceOpen(false));
      },
    });
  };
};

export function relaunchConfirm(pollingId) {
  return (dispatch, getState) => {
    dispatch(toggleInstanceOpen(true));
    const {
      meeting: { bAllowPanelistVote },
    } = getState();
    // should initialize redux checked value
    Modal.confirm({
      className: 'zm-modal-legacy poll-relaunch-confirm',
      okText: DIALOG_CONTINUE,
      okButtonProps: defaultOkButtonProps,
      cancelText: DIALOG_CANCEL,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: POLLING_APAC.RelaunchConfirmDialogLabel,
      title: POLLING_APAC.PollingText,
      content: POLLING_APAC.RelaunchConfirmDialogContent,
      checkboxText: isWebinar() ? POLLING_APAC.AllowPanelistToVote : '',
      checkboxProps: {
        onChange: (bOn) => {
          dispatch(
            sendSocketMessage({
              evt: socketEventTypes.WS_CONF_PANELIST_VOTE_REQ,
              body: {
                bOn,
              },
            }),
          );
        },
        defaultChecked: bAllowPanelistVote,
      },
      onOk: () => {
        dispatch(relaunchPolling(pollingId));
        dispatch(setPollingId({ pollingId }));
        dispatch(setPollingUrlType(POLL_URL_TYPE.DETAIL));
      },
      onAfterClose: () => {
        dispatch(toggleInstanceOpen(false));
      },
    });
  };
}

export const sendPollFailoverToken = () => (dispatch, getState) => {
  const {
    breakoutRoom: {
      attendee: { status },
    },
  } = getState();

  const pollFailoverToken = easyStore.easyGet('ZM-POLL-TOKEN');
  if (pollFailoverToken && isNotInRoom(status)) {
    const pollFailoverData = {
      evt: socketEventTypes.WS_CONF_POLLING_SET_POLLING_TOKEN,
      body: {
        strTk: pollFailoverToken,
      },
    };

    dispatch(sendSocketMessage(pollFailoverData));
  }
};

export const setShowOneQuestionAtOneTime = (value) => (dispatch) => {
  dispatch(
    sendSocketMessage({
      evt: socketEventTypes.WS_CONF_POLLING_OPTION_CHANGE_REQ,
      body: {
        allowShowOneQuestion: value,
      },
    }),
  );
};

export const setShowQuestionsInRandomOrder = (value) => (dispatch) => {
  dispatch(
    sendSocketMessage({
      evt: socketEventTypes.WS_CONF_POLLING_OPTION_CHANGE_REQ,
      body: {
        allowRandomOrder: value,
      },
    }),
  );
};

export const setShowCorrectAnswersToAll = (value) => (dispatch) => {
  dispatch(
    sendSocketMessage({
      evt: socketEventTypes.WS_CONF_POLLING_OPTION_CHANGE_REQ,
      body: {
        allowShowAnswer: value,
      },
    }),
  );
};

export const setAllowPanelistVote = (value) => (dispatch) => {
  dispatch(
    sendSocketMessage({
      evt: socketEventTypes.WS_CONF_PANELIST_VOTE_REQ,
      body: {
        bOn: value,
      },
    }),
  );
};

export const onPollingDocReceived = (data) => (dispatch) => {
  if (!data) return;

  const {
    PollingDocMaxSize,
    PollingDocSize,
    PollingDocLibraryMaxSize,
    PollingDocLibrarySize,
  } = data;
  dispatch(setPollingDocMaxSize(PollingDocMaxSize ?? 50));
  dispatch(setPollingDocSize(PollingDocSize ?? 0));
  dispatch(setPollingDocLibraryMaxSize(PollingDocLibraryMaxSize ?? 10));
  dispatch(setPollingDocLibrarySize(PollingDocLibrarySize ?? 0));
};

export const onPollingDuplicate =
  (pollingId, bFromLibrary = false) =>
  (dispatch, getState) => {
    if (!pollingId) {
      return;
    }

    const {
      meeting: { meetingNumber },
      poll: {
        pollingDocSize,
        pollingDocMaxSize,
        pollingDocLibraryMaxSize,
        pollingDocLibrarySize,
      },
    } = getState();

    if (
      (!bFromLibrary && pollingDocSize >= pollingDocMaxSize) ||
      (bFromLibrary && pollingDocLibrarySize >= pollingDocLibraryMaxSize)
    ) {
      return;
    }

    const tk = easyStore.easyGet('ZM-POLL-TOKEN');
    const duplicateUrl = `${meetingConfig.baseUrl}${POLL_API.DUPLICATE}/${meetingNumber}/${pollingId}?addToLibrary=${bFromLibrary}&&tk=${tk}`;

    dispatch(setPollListLoading(true));
    axios.post(duplicateUrl).finally(() => {
      setTimeout(() => {
        dispatch(setPollListLoading(false));
      }, 10000);
    });
  };

export const openPollingDeleteDialog =
  (pollingId, pollingName, bFromLibrary = false) =>
  (dispatch) => {
    if (!pollingId) {
      return;
    }

    Modal.confirm({
      className: defaultClassName,
      okButtonProps: deleteOkButtonProps,
      okText: POLLING_APAC.MenuDelete,
      contentLabel: POLLING_APAC.deletePollDialogContent(pollingName),
      title: POLLING_APAC.DeletePollDialogTitle,
      cancelText: CANCEL,
      content: POLLING_APAC.deletePollDialogContent(pollingName),
      onOk: () => {
        dispatch(onPollingDelete(pollingId, bFromLibrary));
        const pollListDOM = document.querySelector('#poll-list');
        pollListDOM?.focus();
      },
      onAfterClose: () => {
        dispatch(toggleInstanceOpen(false));
        const pollListDOM = document.querySelector('#poll-list');
        pollListDOM?.focus();
      },
    });
  };

export const onPollingDelete =
  (pollingId, bFromLibrary = false) =>
  (dispatch, getState) => {
    if (!pollingId) {
      return;
    }

    const {
      meeting: { meetingNumber },
    } = getState();
    const tk = easyStore.easyGet('ZM-POLL-TOKEN');
    const deleteUrl = `${meetingConfig.baseUrl}${POLL_API.DELETE}/${meetingNumber}/${pollingId}?isSavedPoll=${bFromLibrary}&&tk=${tk}`;

    dispatch(setPollListLoading(true));
    axios.post(deleteUrl).finally(() => {
      setTimeout(() => {
        dispatch(setPollListLoading(false));
      }, 10000);
    });
  };

export const onPollingCreate =
  (moduleFrom = MODULE_FROM.LIST) =>
  (dispatch, getState) => {
    const { pollingDocSize, pollingDocMaxSize } = getState().poll;

    if (pollingDocSize >= pollingDocMaxSize) {
      return;
    }

    dispatch(setPollingUrlType(POLL_URL_TYPE.CREATE));
    dispatch(setModuleFrom(moduleFrom));
  };

export const onPollingEdit =
  (pollingId, moduleFrom = MODULE_FROM.LIST) =>
  (dispatch) => {
    dispatch(setPollingUrlType(POLL_URL_TYPE.EDIT));
    dispatch(setEditingPollingId(pollingId));
    dispatch(setModuleFrom(moduleFrom));
  };

export const toastPollSavedTip = (name) => (dispatch) => {
  dispatch(setSavedPollName(name));
  setTimeout(() => {
    dispatch(setSavedPollName(''));
  }, [5000]);
};

export const onBackToClient = (params) => (dispatch) => {
  if (!params) {
    return;
  }

  const { action, moduleFrom, pollingId, pollingName } = params;

  if (moduleFrom === MODULE_FROM.LIST) {
    dispatch(setPollingUrlType(POLL_URL_TYPE.LIST));
    if (action === BACK_TO_CLIENT_ACTION.ADD) {
      dispatch(setPollListLoading(true));
      dispatch(setHighlightPollingIds({ pollingId, action: 'add' }));
      setTimeout(() => {
        dispatch(setPollListLoading(false));
      }, 10 * 1000);
    }
  } else if (moduleFrom === MODULE_FROM.DETAIL) {
    dispatch(setPollingUrlType(POLL_URL_TYPE.DETAIL));
    dispatch(toastPollSavedTip(pollingName));
    dispatch(setPollingId({ pollingId }));
  }
};

export const onPollingModalClose = () => (dispatch) => {
  dispatch(setPollingUrlType(POLL_URL_TYPE.LIST));
  dispatch(setEditingPollingId(null));
};

export const onChangePollingStarStatus =
  (starred, pollingId, fromModule) => (dispatch, getState) => {
    if (!pollingId) {
      return;
    }

    const {
      meeting: { meetingNumber },
    } = getState();
    const tk = easyStore.easyGet('ZM-POLL-TOKEN');
    const api = starred ? POLL_API.STAR : POLL_API.UNSTAR;
    const deleteUrl = `${meetingConfig.baseUrl}${api}/${meetingNumber}/v2?tk=${tk}`;
    const payload = {
      surveyId: pollingId,
      fromModule: fromModule,
    };
    dispatch(setPollListLoading(true));
    axios.post(deleteUrl, payload).finally(() => {
      setTimeout(() => {
        dispatch(setPollListLoading(false));
      }, 10000);
    });
  };
