import { createActions } from 'redux-actions';

export const {
  changeTab,
  setExpandedStatus,
  setQaList,
  setTextBoxState,
  clearQaData,
  toggleMeetingQaWidget,
  setBAllowAnonymousQuestion,
  setBAllowAttendeeViewAllQuestion,
  setBAllowAttendeeUpvoteQuestion,
  setBAllowAttendeeCommentQuestion,
  setSortType,
  qaMeetingReset,
  newUpdateQa,
  setUnreadQAListForAttendee,
  setQaEnabled,
  updateWebinarQAState,
  updateQaCmd,
  updateAnswer,
  updateQuestion,
} = createActions(
  'UPDATE_WEBINAR_Q_A_STATE',
  'CHANGE_TAB',
  'SET_EXPANDED_STATUS',
  'SET_QA_LIST',
  'SET_TEXT_BOX_STATE',
  'CLEAR_QA_DATA',
  'TOGGLE_MEETING_QA_WIDGET',
  'SET_B_ALLOW_ANONYMOUS_QUESTION',
  'SET_B_ALLOW_ATTENDEE_VIEW_ALL_QUESTION',
  'SET_B_ALLOW_ATTENDEE_UPVOTE_QUESTION',
  'SET_B_ALLOW_ATTENDEE_COMMENT_QUESTION',
  'SET_SORT_TYPE',
  'QA_MEETING_RESET',
  'NEW_UPDATE_QA',
  'SET_UNREAD_Q_A_LIST_FOR_ATTENDEE',
  'SET_QA_ENABLED',
  'UPDATE_QA_CMD',
  'UPDATE_ANSWER',
  'UPDATE_QUESTION',
);
