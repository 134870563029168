function getDuplicateUserKeyAndMaxQuality(list) {
  if (!list?.length) {
    return null;
  }

  const hasMap = {};
  let duplicateKeyAndMaxQuality = null;

  list.forEach((item) => {
    if (hasMap[item.user.userId]) {
      duplicateKeyAndMaxQuality = {
        duplicateKey: item.user.userId,
        maxQuality: Math.max(hasMap[item.user.userId].quality, item.quality),
      };
    } else {
      hasMap[item.user.userId] = {
        userId: item.user.userId,
        quality: item.quality,
      };
    }
  });

  return duplicateKeyAndMaxQuality;
}

function getCuurentSubscribeList({
  prevListVideoOn,
  curListVideoOn,
  renderDiffMap,
  prevDuplicateUserKeyAndMaxQuality,
}) {
  const prevListVideoOnIds = prevListVideoOn.map((item) => item.user.userId);

  const commonVideoOnListIds = (curListVideoOn || [])
    .filter((item) => prevListVideoOnIds.indexOf(item.user.userId) > -1)
    .map((item) => item.user.userId);

  const zoomQualityDiffList = [];
  const startQualityDiffList = [];
  const stopQualityDiffList = [];

  renderDiffMap.zoom.map((item) => {
    prevListVideoOn.map((subItem) => {
      if (
        subItem.user.userId === item.user.userId &&
        item.quality !== subItem.quality
      ) {
        zoomQualityDiffList.push({
          quality: item.quality,
          user: item.user,
        });
      }
    });
  });

  renderDiffMap.start.map((item) => {
    prevListVideoOn.map((subItem) => {
      if (
        subItem.user.userId === item.user.userId &&
        item.quality !== subItem.quality
      ) {
        startQualityDiffList.push({
          quality: item.quality,
          user: item.user,
        });
      }
    });
  });

  renderDiffMap.stop.map((item) => {
    curListVideoOn.map((subItem) => {
      if (
        subItem.user.userId === item.user.userId &&
        item.quality !== subItem.quality
      ) {
        stopQualityDiffList.push({
          quality: subItem.quality,
          user: subItem.user,
        });
      }
    });
  });

  let cuurentSubscribeList = renderDiffMap.start
    .filter((item) => commonVideoOnListIds.indexOf(item.user.userId) === -1)
    .map((item) => ({
      quality: item.quality,
      user: item.user,
    }))
    .concat(startQualityDiffList)
    .concat(zoomQualityDiffList)
    .concat(stopQualityDiffList);

  cuurentSubscribeList.forEach((item) => {
    curListVideoOn.forEach((subitem) => {
      if (item.user.userId === subitem.user.userId) {
        if (item.quality < subitem.quality) {
          item.quality = subitem.quality;
        }
      }
    });
  });

  cuurentSubscribeList = _.uniqBy(cuurentSubscribeList, 'user.userId');

  cuurentSubscribeList.forEach((item) => {
    prevListVideoOn.forEach((subitem) => {
      if (
        (item.user.userId !== prevDuplicateUserKeyAndMaxQuality?.duplicateKey &&
          item.user.userId === subitem.user.userId &&
          item.quality === subitem.quality) ||
        (item.user.userId === prevDuplicateUserKeyAndMaxQuality?.duplicateKey &&
          item.quality === prevDuplicateUserKeyAndMaxQuality.maxQuality)
      ) {
        item.removeKey = true;
      }
    });
  });

  cuurentSubscribeList = cuurentSubscribeList
    .filter((item) => !item.removeKey)
    .sort((a, b) => a.quality - b.quality);
  return cuurentSubscribeList;
}

function getCuurentUnsubscribeList({
  prevListVideoOn,
  curListVideoOn,
  renderDiffMap,
}) {
  const prevListVideoOnIds = prevListVideoOn.map((item) => item.user.userId);

  const commonVideoOnListIds = (curListVideoOn || [])
    .filter((item) => prevListVideoOnIds.indexOf(item.user.userId) > -1)
    .map((item) => item.user.userId);

  const cuurentUnsubscribeList = renderDiffMap.stop
    .filter((item) => commonVideoOnListIds.indexOf(item.user.userId) === -1)
    .map((item) => ({
      quality: item.quality,
      user: item.user,
    }));

  return _.uniqBy(cuurentUnsubscribeList, 'user.userId');
}

function subscribeDiff({
  previousCurrentRenderVideo,
  currentRenderVideo,
  renderDiffMap,
}) {
  const prevListVideoOn = (previousCurrentRenderVideo || []).filter(
    (item) => !!item.user.displayVideoOn,
  );

  const curListVideoOn = (currentRenderVideo || []).filter(
    (item) => !!item.user.displayVideoOn,
  );

  const prevDuplicateUserKeyAndMaxQuality =
    getDuplicateUserKeyAndMaxQuality(prevListVideoOn);
  const curDuplicateUserKeyAndMaxQuality =
    getDuplicateUserKeyAndMaxQuality(curListVideoOn);

  const cuurentSubscribeList = getCuurentSubscribeList({
    prevListVideoOn,
    curListVideoOn,
    renderDiffMap,
    prevDuplicateUserKeyAndMaxQuality,
    curDuplicateUserKeyAndMaxQuality,
  });

  const cuurentUnsubscribeList = getCuurentUnsubscribeList({
    prevListVideoOn,
    curListVideoOn,
    renderDiffMap,
  });

  return {
    cuurentUnsubscribeList,
    cuurentSubscribeList,
  };
}

export default subscribeDiff;
