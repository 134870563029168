/* used for backend monitor log */
export const enum trackMessageType {
  text = 0,
  jpeg = 1,
  audio = 2,
  video = 3,
  png = 5,
  gif = 6,
  audioV2 = 7,
  otherFile = 10,
  giphy = 12,
  codeSnippet = 13,
  fileAndText = 17,
  zappMessage = 18,
  callAccepted = 51,
  callDeclined = 52,
  callCancel = 54,
  callOutgoing = 55,
  revokeMessage = 80,
  screenshot = 81,
  dropbox = 111,
  microsoftOneDrive = 112,
  googleDrive = 113,
  box = 114,
  microsoftSharePoint = 115,
  subSyncAction = 201,
  commonSyncAction = 202,
  linkPreview = 203,
  editEvent = 204,
  atEvent = 205,
  appAction = 206,
  e2EActionV2KeyReq = 208,
  e2EActionV2KeyRep = 209,
  unknown = 888,
}
export const imageTrackMessageTypes = [
  trackMessageType.jpeg,
  trackMessageType.png,
  trackMessageType.gif,
];
export const MESSAGE_FEATURE_NOT_SET = 0;
export const MESSAGE_FEATURE_TEXT_E2E_V2 = 1 << 0;
export const MESSAGE_FEATURE_FILE_E2E_V2 = 1 << 1;
export const MESSAGE_FEATURE_TEXT = 1 << 2;
export const MESSAGE_FEATURE_RAW_FILE = 1 << 3;
export const MESSAGE_FEATURE_PNG = 1 << 4;
export const MESSAGE_FEATURE_JPG = 1 << 5;
export const MESSAGE_FEATURE_GIF = 1 << 6;
export const MESSAGE_FEATURE_AMR = 1 << 7;
export const MESSAGE_FEATURE_IMG_PREVIEW_E2E_V2 = 1 << 8;
export const MESSAGE_FEATURE_GIPHY = 1 << 9;
export const MESSAGE_FEATURE_CODESNIPPET = 1 << 10;
export const MESSAGE_FEATURE_FileIntegration = 1 << 11;
export const MESSAGE_FEATURE_OPUS = 1 << 12;
export const MESSAGE_FEATURE_LinkUnfurling = 1 << 13;
export const MESSAGE_FEATURE_GIPHYV2 = 1 << 14;
export const MESSAGE_FEATURE_FILEANDTEXT = 1 << 15;

export const MESSAGE_FEATURE_ZAPPMESSAGE = 1 << 16;
export const MESSAGE_FEATURE_REMINDER = 1 << 17;
export const MESSAGE_FEATURE_MEET_TO_CHAT = 1 << 18; // Meet to Chat
export const MESSAGE_FEATURE_MEET_TO_CHAT2 = 1 << 19; // Meet to Chat

export const MESSAGE_FEATURE_VIDEO_MSG = 1 << 20; // video message
export const MESSAGE_FEATURE_SCHEDULE_MEETING = 1 << 21; // schedule meeting
export const MESSAGE_FEATURE_MAIL = 1 << 22;
export const MESSAGE_FEATURE_MEETING_CHAT_CARD = 1 << 23; // meeting chat card
export const MESSAGE_FEATURE_MeetFileIntegration = 1 << 24;
export const MESSAGE_FEATURE_PMC_MEET_CHAT = 1 << 25; // PMC: message from meet chat. old client in IM will show need upgrade

// workaround for comment.
// 1. comment need to be recognized by historic clients as normal msgs
// 2. comment need to be recognized by comment clients as a comment msg
// so any msg with feature set MESSAGE_FEATURE_TEXT | MESSAGE_FEATURE_RAW_FILE is a comment msg.
export const MESSAGE_FEATURE_COMMENT =
  MESSAGE_FEATURE_TEXT | MESSAGE_FEATURE_RAW_FILE;

/* identify specific message feature */
export const enum messageFeatureType {
  notSet = 0,
  textE2eV2 = 1,
  fileE2eV2 = 2,
  text = 4,
  rawFile = 8,
  comment = 12,
  png = 16,
  jpeg = 32,
  gif = 64,
  amr = 128,
  imgPreviewE2eV2 = 256,
  giphy = 512,
  codeSnippet = 1024,
  fileIntegration = 2048,
  opus = 4096,
  linkUnfurling = 8192,
  giphyV2 = 16384,
  fileAndText = 32768,
  zappMessage = 65536,
  meetToChat = 262144,
  meetToChat2 = 524288,
  meetingFileIntegration = 16777216,
  quote = 33554436,
  mix = 33587200,
  meetingGiphyMain = 33570816,
  meetingGiphyThread = 33570816 + 12,
  meetingReviseMxiT = 167804928,
  meetingReviseMxiR = 167804928 + 12,
  emoji = 555,
  meetingFileShare = (1 << 24) | (1 << 25),
}
/* used for modify message  */
export const enum ZoomTaskFeature {
  IM_emoji = 2049,
  None = 0,
  Ack = 1,
  Echo = 2,
  NoNOS = 16,
  NoEchoToMyOtherRes = 32,
  EchoToOtherRes = 64,
  AckRouteResult = 128,
  PartialDelivery = 256,
  ForceDelivery = 4096,
  E2E11Message = 35,
  E2EMUCMessage = 50,
  E2EInvitation = 35,
  E2EAction = 50,
  E2EAction_OnlyForMe = 19,
  E2EAction_V2KeyReq = 4546,
  E2EAction_V2KeyRep = 4162,
  CallAction = 3,
  SubsciptionSync = 4099,
  FileStatusSync = 3,
  RevokeMessage = 3,
  EditMessage = 3,
  LinkMetaInfo = 3,
  AddonMessage = 67,
  AtEvent = 3,
  CommonSyncAction = 4098,
  TransferLcp = 34,
  RequestLcp = 34,
}
// for 3rd file share
export const enum fileIntegrationType {
  none = 0,
  dropbox = 1,
  MicrosoftOneDrive = 2,
  GoogleDrive = 3,
  Box = 4,
  MicrosoftSharePoint = 5,
}

export enum fileSubType {
  none = 0,
  screenShot = 1,
  whiteboard = 2,
  whiteboardShared = 3,
  video = 4,
  meetChatFile = 5,
}

export enum zoomXmppMsgType {
  systemMessage = 0,
  text = 1,
  video = 2,
  picture = 3,
  audio = 4,
  rawFile = 5,
  call = 6,
  callAccept = 9,
  callDecline = 11,
  jpg = 3,
  jpeg = 3,
  png = 12,
  gif = 13,
  codeSnippet = 14,
  callCancel = 15,
  fileIntegration = 16,
  audioV2 = 17,
  fileAndMsg = 18,
  placeHolder = 19,
}
