import { createSelector } from 'reselect';
import {
  isInitial,
  isRoomInProgress,
  isInRoom,
  isRoomTimeUpForAttendee,
  isRoomClosing,
  isRoomNotStarted,
  checkIsSupportForceJoinOrLeaveBoByCaps,
  checkIsSupportCoHostStartOrStopBoByCaps,
  checkHasCapsEditBOAfterBOStart,
  isEnableEditBOAfterBOStart,
  isSupportBoViewActivityStatus,
  isJoiningRoom,
} from '../utils';
import {
  meetingHostSelector,
  currentRoleSelector,
} from '../../../global/redux/selector';
import {
  canBeAssignedToHugeBo,
  getParticipantsListItemAvatarNew,
  getDefaultAvatar,
  isZRMultiStreamVideoChildUser,
  isJoinVoIP,
} from '../../../global/service';
import {
  CAPS_OPTION_SUPPORT_AUTO_MOVE_TO_MAIN_SESSION,
  CAPS_OPTION_SUPPORT_CHOOSE_ROOM,
  CAPS_OPTION_SUPPORT_VEIW_BO_ACTIVITY,
} from '../../../global/constant';
import { isSupportBroadcastVoice } from '../../../global/util';

const participantPropsSelector = (_, { participant }) => participant;

const getParticipantHasCapsToChooseRoomSelector = createSelector(
  [participantPropsSelector],
  (participant) => !!(participant.caps & CAPS_OPTION_SUPPORT_CHOOSE_ROOM),
);

const bAllowedShowAvatar = ({ meeting: { bAllowedAvatar } }) => bAllowedAvatar;

const roomListSelector = ({ breakoutRoom: { roomList } }) => roomList;

const roomControlStatusSelector = ({ breakoutRoom: { controlStatus } }) =>
  controlStatus;

const attendeeBoStatusSelector = ({
  breakoutRoom: {
    attendee: { status },
  },
}) => status;

const hasReceiveBoClosingResSelector = ({
  breakoutRoom: { hasReceiveBoClosingRes },
}) => hasReceiveBoClosingRes;

const preAssignBoListSelector = ({ breakoutRoom: { preAssignBoList } }) =>
  preAssignBoList;

const preAssignmentToAssignDictSelector = ({
  breakoutRoom: { preAssignmentToAssignDict },
}) => preAssignmentToAssignDict;

const isSupportHugeBoSelector = ({ breakoutRoom: { hugeBo } }) => hugeBo;

const attendeeBoRoomSelector = ({
  breakoutRoom: {
    attendee: { room },
  },
}) => room?.boId;

const mainSessionAttendeeListSelector = ({
  breakoutRoom: { mainSessionAttendeeList },
}) => mainSessionAttendeeList;

const hostZoomIdSelector = ({ breakoutRoom: { hostZoomId } }) => hostZoomId;

const isHostBeforeJoinBoSelector = ({ breakoutRoom: { isHostBeforeJoinBo } }) =>
  isHostBeforeJoinBo;

const hasAgreedHostCanViewUserActivityStatusSelector = ({
  breakoutRoom: { hasAgreedHostCanViewUserActivityStatus },
}) => hasAgreedHostCanViewUserActivityStatus;

const participantsChooseRoomOptionSelector = ({
  breakoutRoom: {
    options: { participantsChooseRoom },
  },
}) => participantsChooseRoom;

const roomUIStatusSelector = ({
  breakoutRoom: {
    UI: { showRoomHasStartedNotify, showSelfSelectHasStartedPopover },
  },
}) => ({ showRoomHasStartedNotify, showSelfSelectHasStartedPopover });

const myInfoSelector = ({ meeting: { zoomId, isHost, userGUID } }) => ({
  zoomId,
  isHost,
  userGUID,
});

const myInfoZoomIdSelector = ({ meeting: { zoomId } }) => zoomId;

const attendeesListSelector = ({ attendeesList: { attendeesList } }) =>
  attendeesList;

const currentUserSelector = ({ meeting: { currentUser } }) => currentUser;
const latestInviterSelector = ({ breakoutRoom: { latestInviter } }) =>
  latestInviter;

const broadcastMessageSelector = ({ breakoutRoom: { broadcastMessage } }) =>
  broadcastMessage;

const hasEverBeenPreAssignedDictSelector = ({
  breakoutRoom: { hasEverBeenPreAssignedDict },
}) => hasEverBeenPreAssignedDict;

const allAttendeesListSelector = createSelector(
  [mainSessionAttendeeListSelector, attendeesListSelector],
  (mainSessionAttendees, currentBoAttendees) => {
    return [...mainSessionAttendees, ...currentBoAttendees];
  },
);

const shouldShowTabsSelector = createSelector(
  [currentRoleSelector, roomControlStatusSelector],
  ({ isHost, bCoHost }, controlStatus) =>
    (isHost || bCoHost) && isRoomInProgress(controlStatus),
);

const currentUserHasCapToChooseRoomSelector = createSelector(
  [attendeesListSelector, currentUserSelector],
  (attendeesList, currentUser) => {
    const me = attendeesList.find(
      (attendee) => attendee.userId === currentUser.userId,
    );
    if (me && me.caps) {
      return !!(me.caps & CAPS_OPTION_SUPPORT_CHOOSE_ROOM);
    }
    return false;
  },
);

const currentUserHasCapsToViewBoStatusSelector = createSelector(
  [currentUserSelector, currentRoleSelector],
  (currentUser, currentRole) => {
    const { isHost, bCoHost } = currentRole;
    const hasCapsToView = !!(
      currentUser.caps & CAPS_OPTION_SUPPORT_VEIW_BO_ACTIVITY
    );
    return hasCapsToView && (isHost || bCoHost);
  },
);

const boFactorTimeSelector = ({ breakoutRoom: { boFactorTime } }) =>
  boFactorTime;

// update
const isMeInRoomSelector = createSelector(
  [roomControlStatusSelector, attendeeBoStatusSelector, attendeeBoRoomSelector],
  (controlStatus, attendeeStatus, boId) =>
    (isRoomInProgress(controlStatus) || isRoomClosing(controlStatus)) &&
    (isInRoom(attendeeStatus) || isRoomTimeUpForAttendee(attendeeStatus)) &&
    !!boId,
);

const isHostInMeetingSelector = createSelector(
  [currentRoleSelector, isMeInRoomSelector],
  ({ isHost }, isMeInRoom) => isHost && !isMeInRoom,
);

const isHostInBoSelector = createSelector(
  [currentRoleSelector, isMeInRoomSelector],
  ({ isHost }, isMeInRoom) => isHost && isMeInRoom,
);

const isCoHostInMeetingSelector = createSelector(
  [currentRoleSelector, isMeInRoomSelector],
  ({ bCoHost }, isMeInRoom) => bCoHost && !isMeInRoom,
);

const isCoHostInBoSelector = createSelector(
  [currentRoleSelector, isMeInRoomSelector],
  ({ bCoHost }, isMeInRoom) => bCoHost && isMeInRoom,
);

const getMeetingStartTimeOnMMRSelector = createSelector(
  [boFactorTimeSelector],
  (boFactorTime) => boFactorTime.meetingStartTimeOnMMR,
);

const roomListSizeSelector = createSelector(
  [roomListSelector],
  (roomList) => roomList.length,
);

const canExchangeAttendeeSelector = createSelector(
  [roomListSelector],
  (roomList) => {
    let roomHasAttendee = false;
    // it can be exchanged if there are more than one room has attendees
    for (let i = 0; i < roomList.length; i++) {
      const room = roomList[i];
      if (room.attendeeIdList && room.attendeeIdList.length > 0) {
        if (roomHasAttendee) {
          return true;
        }
        roomHasAttendee = true;
      }
    }
    return false;
  },
);

const isBoRoomHasStartedIamNotInSelector = createSelector(
  [
    roomListSelector,
    myInfoSelector,
    roomControlStatusSelector,
    attendeeBoStatusSelector,
    roomUIStatusSelector,
    isHostBeforeJoinBoSelector,
  ],
  (
    roomList,
    myInfo,
    controlStatus,
    attendeeRoomStatus,
    { showRoomHasStartedNotify },
    isHostBeforeJoinBo,
  ) => {
    const { userGUID, isHost } = myInfo;
    if (
      isHost ||
      isHostBeforeJoinBo ||
      isInRoom(attendeeRoomStatus) ||
      !isRoomInProgress(controlStatus) ||
      !showRoomHasStartedNotify
    ) {
      return false;
    }
    for (let i = 0; i < roomList.length; i++) {
      const room = roomList[i];
      const { attendeeIdList } = room;
      if (attendeeIdList.find((id) => id === userGUID)) {
        return false;
      }
    }
    return true;
  },
);

const unassignedAttendeeListSeletor = createSelector(
  [
    roomListSelector,
    attendeesListSelector,
    myInfoZoomIdSelector,
    mainSessionAttendeeListSelector,
    isMeInRoomSelector,
    isSupportHugeBoSelector,
  ],
  (
    roomList,
    attendeesList = [],
    zoomId,
    mainSessionAttendeeList,
    isMeInRoom,
    hugeBo,
  ) => {
    let assignedAttendeeList = [];
    roomList.forEach((room) => {
      const { attendeeIdList } = room;
      assignedAttendeeList = assignedAttendeeList.concat(attendeeIdList);
    });
    let allAttendeeList = [];
    if (isMeInRoom) {
      allAttendeeList = mainSessionAttendeeList;
    } else {
      allAttendeeList = attendeesList;
    }
    // the unassigned list must not contain  myself
    return allAttendeeList.filter(
      (user) =>
        !user.isHost &&
        !user.bHold &&
        user.zoomID !== zoomId &&
        assignedAttendeeList.indexOf(user.userGUID) === -1 &&
        !user.bid &&
        ((hugeBo && canBeAssignedToHugeBo(user.caps)) || !hugeBo) &&
        !isZRMultiStreamVideoChildUser(user),
    );
  },
);

const allParticipantsMapSelector = createSelector(
  [
    unassignedAttendeeListSeletor,
    roomListSelector,
    attendeesListSelector,
    mainSessionAttendeeListSelector,
    isMeInRoomSelector,
    roomControlStatusSelector,
  ],
  (
    unassignedAttendeeList,
    roomList,
    attendeesList,
    mainSessionAttendeeList,
    isMeInRoom,
    controlStatus,
  ) => {
    let allParticipantList;
    if (isMeInRoom) {
      allParticipantList = Array.from(mainSessionAttendeeList, (item) => [
        item.userGUID,
        { ...item },
      ]);
    } else {
      allParticipantList = Array.from(attendeesList, (item) => [
        item.userGUID,
        { ...item },
      ]);
    }
    const allParticipantListMap = new Map(allParticipantList);
    const currentRoomAttendeeIdList = unassignedAttendeeList.map(
      (attendee) => attendee.userGUID,
    );
    roomList.forEach((room, index) => {
      const { attendeeIdList, boId, name } = room;
      attendeeIdList.forEach((attendee) => {
        if (isMeInRoom) {
          if (allParticipantListMap.has(attendee)) {
            let copyParticipant = { ...allParticipantListMap.get(attendee) };
            copyParticipant.roomDisplayname = name;
            copyParticipant._boId = boId;
            copyParticipant.display = true;
            copyParticipant.isUnassignedGroup = false;
            copyParticipant.parentIndex = index;
            copyParticipant.currentRoomAttendeeIdList = attendeeIdList;
            allParticipantListMap.set(attendee, copyParticipant);
          }
        } else {
          if (allParticipantListMap.has(attendee)) {
            const { bHold } = allParticipantListMap.get(attendee);
            if (
              bHold &&
              (isRoomInProgress(controlStatus) ||
                isRoomNotStarted(controlStatus))
            ) {
              return;
            }
            let copyParticipant = { ...allParticipantListMap.get(attendee) };
            copyParticipant.roomDisplayname = name;
            copyParticipant._boId = boId;
            copyParticipant.display = true;
            copyParticipant.isUnassignedGroup = false;
            copyParticipant.parentIndex = index;
            copyParticipant.currentRoomAttendeeIdList = attendeeIdList;
            allParticipantListMap.set(attendee, copyParticipant);
          }
        }
      });
      if (isMeInRoom) {
        allParticipantListMap.forEach((value, key) => {
          let copyParticipant = { ...value };
          if (!copyParticipant.display && copyParticipant.bid === boId) {
            copyParticipant.roomDisplayname = name;
            copyParticipant._boId = boId;
            copyParticipant.display = true;
            copyParticipant.isUnassignedGroup = false;
            copyParticipant.parentIndex = index;
            copyParticipant.currentRoomAttendeeIdList = attendeeIdList;
            allParticipantListMap.set(key, copyParticipant);
          }
        });
      }
    });
    unassignedAttendeeList.forEach((item) => {
      let copyParticipant = { ...allParticipantListMap.get(item.userGUID) };
      copyParticipant.roomDisplayname = 'Unassigned';
      copyParticipant._boId = -1;
      copyParticipant.parentIndex = -1;
      copyParticipant.display = true;
      copyParticipant.isUnassignedGroup = true;
      copyParticipant.currentRoomAttendeeIdList = currentRoomAttendeeIdList;
      allParticipantListMap.set(item.userGUID, copyParticipant);
    });
    allParticipantListMap.forEach((value, key) => {
      if (!value.display) {
        allParticipantListMap.delete(key);
      }
    });
    return allParticipantListMap;
  },
);

const unassignedAttendeeListIncludingBHoldSeletor = createSelector(
  [
    roomListSelector,
    attendeesListSelector,
    myInfoSelector,
    mainSessionAttendeeListSelector,
    isMeInRoomSelector,
    isSupportHugeBoSelector,
  ],
  (
    roomList,
    attendeesList = [],
    myInfo,
    mainSessionAttendeeList,
    isMeInRoom,
    hugeBo,
  ) => {
    let assignedAttendeeList = [];
    roomList.forEach((room) => {
      const { attendeeIdList } = room;
      assignedAttendeeList = assignedAttendeeList.concat(attendeeIdList);
    });
    const { zoomId } = myInfo;
    let allAttendeeList = [];
    if (isMeInRoom) {
      allAttendeeList = mainSessionAttendeeList;
    } else {
      allAttendeeList = attendeesList;
    }
    // the unassigned list must not contain  myself
    return allAttendeeList.filter(
      (user) =>
        !user.isHost &&
        user.zoomID !== zoomId &&
        assignedAttendeeList.indexOf(user.userGUID) === -1 &&
        !user.bid &&
        ((hugeBo && canBeAssignedToHugeBo(user.caps)) || !hugeBo),
    );
  },
);

const unassignedAttendeeSizeSeletor = createSelector(
  [unassignedAttendeeListSeletor],
  (unassignedAttendeeList) => unassignedAttendeeList.length,
);

const attendeeListGroupedByRoomSeletor = createSelector(
  [roomListSelector, attendeesListSelector],
  (roomList, attendeesList = []) =>
    roomList.map((room) => {
      const { attendeeIdList } = room;
      const attendeeListInRoom = [];
      attendeeIdList.forEach((attendeeId) => {
        const targetAttendee = attendeesList.find(
          (attendee) => attendee.userGUID === attendeeId,
        );
        if (targetAttendee) {
          attendeeListInRoom.push(targetAttendee);
        }
      });
      return {
        boId: room.boId,
        attendeeList: attendeeListInRoom,
      };
    }),
);

const unassignedAttendeeListGroupedByRoomIdSeletor = createSelector(
  [roomListSelector, attendeesListSelector, unassignedAttendeeListSeletor],
  (roomList, attendeesList = [], unassignedAttendeeList = []) =>
    roomList.map((room) => {
      const { attendeeIdList } = room;
      const currentRoomAssignedAttendeeList = attendeeIdList
        ? attendeesList.filter(
            (attendee) => attendeeIdList.indexOf(attendee.userGUID) > -1,
          )
        : [];
      return {
        boId: room.boId,
        attendeeList: currentRoomAssignedAttendeeList.concat(
          unassignedAttendeeList,
        ),
      };
    }),
);

const hasAssignedAnyAttendeeSelector = createSelector(
  [roomListSelector],
  (roomList) =>
    roomList.some(
      ({ attendeeIdList }) => attendeeIdList && attendeeIdList.length > 0,
    ),
);

// who is the host in main meeting
const hostInMainSessionSelector = createSelector(
  [
    mainSessionAttendeeListSelector,
    hostZoomIdSelector,
    isMeInRoomSelector,
    meetingHostSelector,
  ],
  (mainSessionAttendeeList, hostZoomId, isMeInRoom, meetingHost) => {
    const defaultHostName = 'Host';
    if (isMeInRoom) {
      if (!hostZoomId) {
        return defaultHostName; // something wrong perhaps
      }
      const host = mainSessionAttendeeList.find(
        (parti) => parti.zoomID === hostZoomId,
      );
      if (host) {
        return host.displayName;
      }
    } else if (meetingHost) {
      return meetingHost.displayName;
    }
    return defaultHostName;
  },
);

const hostAttendeeForBreakoutRoomSelector = createSelector(
  [mainSessionAttendeeListSelector, hostZoomIdSelector, attendeesListSelector],
  (mainSessionAttendeeList, hostZoomId, attendeesList) => {
    let host = null;
    if (hostZoomId) {
      host = mainSessionAttendeeList.find(
        (attendee) => attendee.zoomID === hostZoomId,
      );
    } else {
      host = attendeesList.find((attendee) => attendee.isHost);
    }
    return host;
  },
);

const hostIdSetSelector = createSelector(
  [hostAttendeeForBreakoutRoomSelector],
  (hostAttendee) => {
    return {
      bid: hostAttendee ? hostAttendee.bid : '',
      userGUID: hostAttendee ? hostAttendee.userGUID : '',
    };
  },
);

const currentUserCanSelectRoomSelector = createSelector(
  [participantsChooseRoomOptionSelector, currentUserHasCapToChooseRoomSelector],
  (participantsChooseRoomOption, currentUserHasCapToChooseRoom) =>
    participantsChooseRoomOption && currentUserHasCapToChooseRoom,
);

const inviterNameSelector = createSelector(
  [allAttendeesListSelector, latestInviterSelector, meetingHostSelector],
  (allAttendees, latestInviterUserGUID, host) => {
    const inviter = allAttendees.find(
      (attendee) => attendee.userGUID === latestInviterUserGUID,
    );
    if (inviter) return inviter.displayName;
    if (host) return host.displayName;
    return null;
  },
);

const currentUserAndHostHasCapsToForceJoinOrLeaveBoSelector = createSelector(
  [currentUserSelector, hostAttendeeForBreakoutRoomSelector],
  (currentUser, host) => {
    if (currentUser && host) {
      return (
        checkIsSupportForceJoinOrLeaveBoByCaps(currentUser?.caps) &&
        checkIsSupportForceJoinOrLeaveBoByCaps(host?.caps)
      );
    }
    return false;
  },
);

const currentUserIsAbleToManageBoSelector = createSelector(
  [
    currentRoleSelector,
    currentUserSelector,
    hostAttendeeForBreakoutRoomSelector,
  ],
  ({ isHost, bCoHost }, currentUser, host) => {
    if (isHost) return true;
    if (bCoHost && currentUser && host) {
      return (
        checkIsSupportCoHostStartOrStopBoByCaps(currentUser?.caps) &&
        checkIsSupportCoHostStartOrStopBoByCaps(host?.caps)
      );
    }
    return false;
  },
);

const canEditBOAfterBOStartSelector = createSelector(
  [
    currentUserIsAbleToManageBoSelector,
    currentUserSelector,
    hostAttendeeForBreakoutRoomSelector,
  ],
  (currentUserIsAbleToManageBo, currentUser, host) => {
    const isCurrentUserAble = checkHasCapsEditBOAfterBOStart(currentUser?.caps);
    const isHostAble = checkHasCapsEditBOAfterBOStart(host?.caps);
    return (
      isEnableEditBOAfterBOStart() &&
      currentUserIsAbleToManageBo &&
      isCurrentUserAble &&
      isHostAble
    );
  },
);

const shouldShowSelfSelectRoomPopoverSelector = createSelector(
  [
    attendeeBoStatusSelector,
    roomControlStatusSelector,
    currentUserCanSelectRoomSelector,
    currentUserIsAbleToManageBoSelector,
    roomUIStatusSelector,
  ],
  (
    attendeeBoStatus,
    roomControlStatus,
    currentUserCanSelectRoom,
    currentUserIsAbleToManageBo,
    { showSelfSelectHasStartedPopover },
  ) => {
    if (
      currentUserIsAbleToManageBo ||
      !isInitial(attendeeBoStatus) ||
      !isRoomInProgress(roomControlStatus) ||
      !currentUserCanSelectRoom ||
      !showSelfSelectHasStartedPopover
    ) {
      return false;
    }
    return true;
  },
);

const newPreAssignedAttendeesSelector = createSelector(
  [
    unassignedAttendeeListIncludingBHoldSeletor,
    preAssignmentToAssignDictSelector,
    preAssignBoListSelector,
    hasEverBeenPreAssignedDictSelector,
  ],
  (
    unassignedAttendeeList,
    preAssignmentToAssignDict,
    preAssignBoList,
    hasEverBeenPreAssignedDict,
  ) => {
    return unassignedAttendeeList
      .filter(
        (unassignedAttendee) =>
          !!preAssignmentToAssignDict[unassignedAttendee.userEmail] ||
          preAssignBoList.find(
            (bo) =>
              bo.email && bo.email.indexOf(unassignedAttendee.userEmail) !== -1,
          ),
      )
      .filter((attendee) => {
        return !hasEverBeenPreAssignedDict[attendee?.userGUID];
      });
  },
);

const participantShouldNotBeMadeHostBecauseSelfSelectCapsSelector =
  createSelector(
    [
      getParticipantHasCapsToChooseRoomSelector,
      roomControlStatusSelector,
      participantsChooseRoomOptionSelector,
    ],
    (hasCaps, controlStatus, participantsChooseRoom) => {
      if (
        !hasCaps &&
        !isRoomNotStarted(controlStatus) &&
        participantsChooseRoom
      )
        return true;
      return false;
    },
  );

const broadcastSenderAndMessageSelector = createSelector(
  [
    broadcastMessageSelector,
    allAttendeesListSelector,
    hostInMainSessionSelector,
    bAllowedShowAvatar,
  ],
  (broadcastMessage, allAttendees, defaultHostName, bAllowedAvatar) => {
    const sender = allAttendees.find(
      (a) => a.zoomID === broadcastMessage.zoomId,
    );
    let displayName = defaultHostName;
    let avatar = getDefaultAvatar();
    if (sender) {
      displayName = sender.displayName;
      avatar = getParticipantsListItemAvatarNew(sender, '', '', bAllowedAvatar);
    }
    return {
      message: broadcastMessage.message,
      displayName,
      avatar,
    };
  },
);

const shouldStartClosingCountDownSelector = createSelector(
  [roomControlStatusSelector, hasReceiveBoClosingResSelector],
  (controlStatus, hasReceiveBoClosingRes) => {
    return isRoomClosing(controlStatus) && hasReceiveBoClosingRes;
  },
);

const shouldShowAgreeHostCanViewUserActivityStatusDialogSelector =
  createSelector(
    [
      currentUserHasCapsToViewBoStatusSelector,
      isMeInRoomSelector,
      hasAgreedHostCanViewUserActivityStatusSelector,
    ],
    (hasCapsToView, isMeInRoom, hasAgreedHostCanViewUserActivityStatus) => {
      return (
        isSupportBoViewActivityStatus() &&
        !hasCapsToView &&
        isMeInRoom &&
        !hasAgreedHostCanViewUserActivityStatus
      );
    },
  );

const isAbleToBroadcastVoiceSelector = createSelector(
  [
    currentRoleSelector,
    currentUserSelector,
    roomControlStatusSelector,
    attendeeBoStatusSelector,
  ],
  ({ isHost }, currentUser, controlStatus, status) => {
    const isInBO = isInRoom(status) || isJoiningRoom(status);
    return (
      isHost &&
      isJoinVoIP(currentUser) &&
      !currentUser.muted &&
      isRoomInProgress(controlStatus) &&
      !isInBO &&
      isSupportBroadcastVoice()
    );
  },
);

const isAbleToShowBroadcastVoicePanelSelector = createSelector(
  [currentRoleSelector, attendeeBoStatusSelector],
  ({ isHost }, status) => {
    const isInBO = isInRoom(status) || isJoiningRoom(status);
    return isHost && !isInBO && isSupportBroadcastVoice();
  },
);

export const isInBOSelector = createSelector(
  [
    (state) => {
      const {
        breakoutRoom: {
          attendee: { status },
        },
      } = state;
      return status;
    },
  ],
  (boStatus) => {
    const isInBO = isInRoom(boStatus) || isJoiningRoom(boStatus);
    return isInBO;
  },
);

const attendeeListInMainSessionSelector = createSelector(
  [mainSessionAttendeeListSelector, attendeesListSelector, isInBOSelector],
  (mainSessionAttendees, attendeesList, isInBo) => {
    if (isInBo) {
      return mainSessionAttendees;
    }
    return attendeesList;
  },
);

export const isShowOptionAutoMoveToMainSessionSelector = createSelector(
  [currentUserSelector, meetingHostSelector],
  (currentUser, meetingHost) => {
    if (currentUser.isHost) {
      return true;
    }
    if (currentUser.bCoHost) {
      return Boolean(
        meetingHost?.caps & CAPS_OPTION_SUPPORT_AUTO_MOVE_TO_MAIN_SESSION,
      ); // check current host support the new option or not
    }
    return false;
  },
);

export {
  isHostInMeetingSelector,
  isHostInBoSelector,
  isCoHostInMeetingSelector,
  isCoHostInBoSelector,
  unassignedAttendeeListSeletor,
  attendeeListGroupedByRoomSeletor,
  unassignedAttendeeListGroupedByRoomIdSeletor,
  hasAssignedAnyAttendeeSelector,
  roomListSizeSelector,
  canExchangeAttendeeSelector,
  unassignedAttendeeSizeSeletor,
  isBoRoomHasStartedIamNotInSelector,
  hostInMainSessionSelector,
  isMeInRoomSelector,
  getMeetingStartTimeOnMMRSelector,
  currentUserCanSelectRoomSelector,
  inviterNameSelector,
  shouldShowSelfSelectRoomPopoverSelector,
  newPreAssignedAttendeesSelector,
  unassignedAttendeeListIncludingBHoldSeletor,
  participantsChooseRoomOptionSelector,
  roomControlStatusSelector,
  participantShouldNotBeMadeHostBecauseSelfSelectCapsSelector,
  currentUserIsAbleToManageBoSelector,
  hostAttendeeForBreakoutRoomSelector,
  currentUserAndHostHasCapsToForceJoinOrLeaveBoSelector,
  broadcastSenderAndMessageSelector,
  shouldStartClosingCountDownSelector,
  hostIdSetSelector,
  attendeeBoStatusSelector,
  canEditBOAfterBOStartSelector,
  shouldShowAgreeHostCanViewUserActivityStatusDialogSelector,
  currentUserHasCapsToViewBoStatusSelector,
  allParticipantsMapSelector,
  isAbleToBroadcastVoiceSelector,
  isAbleToShowBroadcastVoicePanelSelector,
  shouldShowTabsSelector,
  attendeeListInMainSessionSelector,
};
