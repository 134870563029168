import { sendSocketMessage } from '../../../../actions/SocketActions';

import {
  setSpotlightVideoList,
  setShouldRebackPreviousView,
  clearSpotlightVideoList,
} from '../video-action';
import {
  VIDEO_LAYOUT_GALLERY_VIEW,
  VIDEO_LAYOUT_SPEAK_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW,
  VIDEO_LAYOUT_MULTI_SPEAKER_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW,
} from '../../enum';

import * as socketEventTypes from '../../../../constants/ZoomSocketEventTypes';

import { spotlightVideoListSelector } from '../selectors/video-common-selector';
import { setVideoLayoutThunk } from './video-layout-change-thunk';
import { isWebinar } from '../../../../global/service/meeting-types';
import { isHost, isViewOnly } from '../../../../global/service/user-types';

export function receiveSpotlight(data) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userRole },
      },
      video: {
        UI: { videoLayout },
        shouldRebackPreviousView,
      },
    } = getState();

    const tempList = (
      (data && data.group_list && data.group_list[0]) ||
      []
    ).map((item) => item.id);

    if (
      (videoLayout === VIDEO_LAYOUT_GALLERY_VIEW ||
        videoLayout === VIDEO_LAYOUT_MULTI_SPEAKER_VIEW) &&
      tempList.length > 0
    ) {
      if (!(isWebinar() && isViewOnly(userRole))) {
        dispatch(setShouldRebackPreviousView(videoLayout));
        dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SPEAK_VIEW));
      }
    }

    dispatch(
      setSpotlightVideoList({
        type: 'receive',
        list: _.uniq(tempList) || [],
      }),
    );

    if (shouldRebackPreviousView && !_.uniq(tempList).length) {
      if (videoLayout === VIDEO_LAYOUT_SPEAK_VIEW) {
        dispatch(setVideoLayoutThunk(shouldRebackPreviousView));
      }

      if (videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW) {
        if (shouldRebackPreviousView === VIDEO_LAYOUT_GALLERY_VIEW) {
          dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW));
        } else {
          dispatch(
            setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW),
          );
        }
      }
      dispatch(setShouldRebackPreviousView(''));
    }
  };
}

export function updateSpotlightList(message) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userRole },
      },
      attendeesList: { attendeesList },
    } = getState();
    if (!isHost(userRole)) {
      return;
    }

    const spotlightVideoList = spotlightVideoListSelector(getState());

    if (spotlightVideoList.length === 0) {
      return;
    }

    if (!_.isEmpty(message.body.update)) {
      const userList = message.body.update || [];
      userList.forEach((item) => {
        if (item.bHold && spotlightVideoList.includes(item.id)) {
          dispatch(
            sendSocketMessage({
              evt: socketEventTypes.WS_CONF_SET_GROUP_LAYOUT,
              body: {
                id: item.id,
                bReplace: false,
                bSpotlight: false,
                bUnSpotlightAll: false,
              },
            }),
          );
        }
      });
    }

    if (!_.isEmpty(message.body.remove)) {
      const userList = message.body.remove || [];
      userList.forEach((item) => {
        if (spotlightVideoList.includes(item.id)) {
          dispatch(
            sendSocketMessage({
              evt: socketEventTypes.WS_CONF_SET_GROUP_LAYOUT,
              body: {
                id: item.id,
                bReplace: false,
                bSpotlight: false,
                bUnSpotlightAll: false,
              },
            }),
          );
        }
      });
    }

    spotlightVideoList.forEach((id) => {
      if (!attendeesList.find((attendee) => attendee.userId === id)) {
        dispatch(
          sendSocketMessage({
            evt: socketEventTypes.WS_CONF_SET_GROUP_LAYOUT,
            body: {
              id,
              bReplace: false,
              bSpotlight: false,
              bUnSpotlightAll: false,
            },
          }),
        );
      }
    });
  };
}

export function clearSpotlightVideoListThunk(checkLayout) {
  return (dispatch, getState) => {
    const {
      video: {
        shouldRebackPreviousView,
        UI: { videoLayout },
      },
    } = getState();

    dispatch(clearSpotlightVideoList());
    if (checkLayout && shouldRebackPreviousView) {
      if (videoLayout === VIDEO_LAYOUT_SPEAK_VIEW) {
        dispatch(setVideoLayoutThunk(shouldRebackPreviousView));
      }

      if (videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW) {
        if (shouldRebackPreviousView === VIDEO_LAYOUT_GALLERY_VIEW) {
          dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW));
        } else {
          dispatch(
            setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW),
          );
        }
      }
      setShouldRebackPreviousView('');
    }
  };
}
