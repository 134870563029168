import { iText } from '../../global/util';
import { isWebinar } from '../../global/service/meeting-types';
import {
  anonymousAttendeeWebinarText,
  QA_webinar_TIP1,
  QA_webinar_TIP2,
  qaSettingAllowCommentWebinar,
  qaSettingAllowViewWebinar,
  qaSettingAllowVoteWebinar,
  qaWelcomeBannerWebinarText,
  webinarAttendeeCanSubmitQuestionsText,
} from '../q-a/resource';

export const QA_SETTING_OPTION_TXT_PARTICIPANTS_CAN_SUBMIT_QUESTION =
  isWebinar()
    ? webinarAttendeeCanSubmitQuestionsText
    : iText(
        'Participants can submit questions',
        'apac.wc_qa.setting_option_participants_can_submit_question',
      );
export const QA_SETTING_TXT_ALLOW_VIEW = isWebinar()
  ? qaSettingAllowViewWebinar
  : iText(
      'Allow participants to view',
      'apac.wc_qa.setting_allow_view_participants',
    );
export const QA_SETTING_OPTION_TXT_ALLOW_VOTE = isWebinar()
  ? qaSettingAllowVoteWebinar
  : iText(
      'Participants can upvote',
      'apac.wc_qa.setting_option_allow_vote_participants',
    );
export const QA_SETTING_OPTION_TXT_ALLOW_COMMENT = isWebinar()
  ? qaSettingAllowCommentWebinar
  : iText(
      'Participants can comment',
      'apac.wc_qa.setting_option_allow_comment_participants',
    );
export const ANONYMOUS_ATTENDEE = isWebinar()
  ? anonymousAttendeeWebinarText
  : iText('Anonymous Participant', 'apac.wc_qa.anonymous_participant');
export const QA_TIP1 = isWebinar()
  ? QA_webinar_TIP1
  : iText(
      'Everyone in this meeting can see open questions',
      'apac.wc_qa.qa_tip1_meeting',
    );
export const QA_TIP2 = isWebinar()
  ? QA_webinar_TIP2
  : iText(
      'Only hosts and cohost can see the questions',
      'apac.wc_qa.qa_tip2_meeting',
    );
export const qaWelcomeBanner = isWebinar()
  ? qaWelcomeBannerWebinarText
  : iText(
      'Questions you ask the host and cohost will show up here',
      'apac.wc_qa.qa_tip3_meeting',
    );
export const HOST_ENABLED_QA = iText(
  'The host has enabled Q&A',
  'apac.qa.host_enabled_qa',
);
export const HOST_DISABLED_QA = iText(
  'The host has disabled Q&A',
  'apac.qa.host_disabled_qa',
);
export const ENABLE_QA = iText('Enable Q&A', 'apac.qa.enable_qa');

export const openQuestion = (number) =>
  iText(`${number} open questions`, 'apac.qa.any_open_question', [number]);

export const placeholderForManualCC = iText(
  'Your captions will appear to meeting participants as you type.',
  'apac.wc_cc.placeholder2',
);
