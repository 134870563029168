import React from 'react';
import PropTypes from 'prop-types';

function slotify(Wrappee) {
  function SlotifyWrapper({ children, ...otherProps }) {
    if (children) {
      let slotProps = {};
      if (!Array.isArray(children)) {
        slotProps = { [children.props.slot]: [children] };
      } else {
        slotProps = children.reduce((accumulator, singleEle) => {
          if (!Array.isArray(accumulator[singleEle.props.slot])) {
            accumulator[singleEle.props.slot] = [singleEle];
          } else {
            accumulator[singleEle.props.slot].push(singleEle);
          }
          return accumulator;
        }, {});
      }
      return <Wrappee {...otherProps} {...slotProps} />;
    }
    return <Wrappee {...otherProps} />;
  }

  SlotifyWrapper.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf((propValue) => {
        const SLOT_REQUIRED =
          'Slotify component expects all child element with slot property';
        if (Array.isArray(propValue)) {
          if (!propValue.every((val) => val.props.slot)) {
            return new Error(SLOT_REQUIRED);
          }
        } else if (!propValue.slot) {
          return new Error(SLOT_REQUIRED);
        }
      }),
    ]),
  };
  return SlotifyWrapper;
}

export { slotify };
