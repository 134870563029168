import { WEBINAR_ATTENDEES } from '../../../constants/UserRoles';
import { getAvatarBKColor } from '../../../global/service';
import { decodeBase64 } from '../../../global/util';
import { isWebinar } from '../../../global/service/meeting-types';
import { isHost, isViewOnly } from '../../../global/service/user-types';

const isLeagueParticipant = (participant) => {
  // tesla don't support breakout room
  return !participant.bid;
};

const isLeagueWebinarAttendee = (attendee) => {
  return attendee.role.toString() === WEBINAR_ATTENDEES;
};

/**
 * @description store participants info:
 * get data from redux is async, data is not right.
 * a map struction is better for performance
 */
export const participantsMap = {
  participants: new Map(),
  webinarAttendes: new Map(),
  /**
   * @description some times xmpp nodeId is not correct, nodeId in command socket is correct, need to map by jid
   * @example Map<jid, { dn2UserId: number, xmppUserId: number }>
   */
  webinarJidUseridMap: new Map(),
  currentUserId: 0,
  isCurrentUserWebinarAttendee: false,
  setCurrentUserId(userId) {
    this.currentUserId = userId;
  },
  setIsWebinarAttendee(isAttendee) {
    this.isCurrentUserWebinarAttendee = isWebinar() && isAttendee;
    if (this.isCurrentUserWebinarAttendee) {
      this.clearOthers();
    }
  },
  getCurrentUser() {
    return this.currentUserId && this.getUserInfo(this.currentUserId);
  },
  clear(onlyClearOthers) {
    this.participants.clear();
    this.webinarAttendes.clear();
    if (!onlyClearOthers) {
      this.webinarJidUseridMap.clear();
    }
  },
  clearOthers() {
    if (this.currentUserId) {
      const currentUser = this.getCurrentUser();
      const isExistParticipant = this.isExistParticipant(this.currentUserId);
      const isExistWebinarAttendee = this.isExistWebinarAttendee(
        this.currentUserId,
      );
      this.clear(true);
      if (isExistParticipant) {
        this.participants.set(this.currentUserId, currentUser);
      } else if (isExistWebinarAttendee) {
        this.webinarAttendes.set(this.currentUserId, currentUser);
      }
    } else {
      this.clear(true);
    }
  },
  getUserInfo(userId) {
    return this.getParticipant(userId) || this.getWebinarAttendee(userId);
  },
  getParticipant(userId) {
    return this.participants.get(userId);
  },
  isExistParticipant(userId) {
    return this.participants.has(userId);
  },
  getWebinarAttendee(userId) {
    return this.webinarAttendes.get(userId);
  },
  isExistWebinarAttendee(userId, jid) {
    if (this.webinarAttendes.has(userId)) return true;
    if (jid) {
      const userIdInfo = this.webinarJidUseridMap.get(jid);
      const realId = userIdInfo && userIdInfo.dn2UserId;
      if (realId) return this.webinarAttendes.has(realId);
    }
    return false;
  },
  getExistWebinarAttendeeUserId(userId, jid) {
    if (!jid) return userId;
    const userIdInfo = this.webinarJidUseridMap.get(jid);
    if (!userIdInfo || !userIdInfo.dn2UserId) return userId;
    return userIdInfo.dn2UserId;
  },
  updateWebinarAttendeeUserId(jid, { dn2UserId, xmppUserId }) {
    if (jid) {
      const userIdInfo = this.webinarJidUseridMap.get(jid);
      if (!userIdInfo) {
        this.webinarJidUseridMap.set(jid, {
          ...(dn2UserId ? { dn2UserId } : {}),
          ...(xmppUserId ? { xmppUserId } : {}),
        });
        return;
      }
      if (dn2UserId) userIdInfo.dn2UserId = dn2UserId;
      if (xmppUserId) userIdInfo.xmppUserId = xmppUserId;
    }
  },
  update(participants) {
    participants.forEach((item) => {
      const { operation, ...restProps } = item;
      const { is_webinar_attendee, user_id } = restProps;
      const map = is_webinar_attendee
        ? this.webinarAttendes
        : this.participants;
      if (operation === 'add') {
        map.set(user_id, { ...restProps });
      } else if (operation === 'update') {
        const user = map.get(user_id) || {};
        map.set(user_id, { ...user, ...restProps });
      } else if (operation === 'remove') {
        map.delete(user_id);
      }
    });
  },
  /**
   * @description remove duplicate roster
   */
  removeDulicateRoster(roster, userId) {
    const userInfo = this.getUserInfo(userId);
    if (userInfo) {
      Object.keys(roster).forEach((key) => {
        if (userInfo[key] === roster[key]) {
          delete roster[key];
        }
      });
    }
    return roster;
  },
};

const getFilterProps = (roster, keys = [], isFromXmpp) => {
  const filterProps = {};
  const {
    dn2,
    bHold,
    audio,
    muted,
    bVideoOn,
    avatar,
    id,
    role,
    bCoHost,
    name,
    node,
  } = roster;
  const userId = isFromXmpp ? node : id;
  keys.forEach((key) => {
    if (key === 'dn2' && dn2) {
      filterProps.display_name = decodeBase64(dn2);
      filterProps.background_color = getAvatarBKColor(filterProps.display_name);
    } else if (key === 'bHold' && bHold !== undefined) {
      filterProps.in_waiting_room = !!bHold;
    } else if (key === 'audio' && audio !== undefined) {
      filterProps.audio_status = audio;
    } else if (key === 'muted' && muted !== undefined) {
      filterProps.audio_muted = muted;
    } else if (key === 'bVideoOn' && bVideoOn !== undefined) {
      filterProps.video_muted = !bVideoOn;
    } else if (key === 'avatar' && avatar) {
      filterProps.profile_image_url = avatar;
    } else if (key === 'role' && role !== undefined) {
      filterProps.is_host = isHost(role);
    } else if (key === 'bCoHost' && bCoHost !== undefined) {
      filterProps.is_cohost = bCoHost;
    } else if (key === 'name' && name) {
      filterProps.display_name = name;
      filterProps.background_color = getAvatarBKColor(filterProps.display_name);
    }
  });
  return participantsMap.removeDulicateRoster(filterProps, userId);
};

const updateCanUnmuteAudioOrVideo = (store, adaptor, updateProps = {}) => {
  const { is_host, is_cohost } = updateProps;
  const { getState } = store;
  const {
    meeting: { currentUser },
  } = getState();
  const isViewOnlyUser = isViewOnly(currentUser.userRole);
  if ((is_host !== undefined || is_cohost !== undefined) && !isViewOnlyUser) {
    const coOrHost = is_host || is_cohost;
    adaptor.notifyControllerAudioCanUnMuted({
      coOrHostRoleChangeTo: coOrHost,
    });
    adaptor.notifyControllerVideoCanUnMuted({
      coOrHostRoleChangeTo: coOrHost,
    });
  }
};

export function updateParticipantsListByWs(data, store, adaptor) {
  const { getState } = store;
  const {
    meeting: { currentUser, jid },
  } = getState();
  let participants = [];
  /** new added participants */
  const addList = (data.add || [])
    .filter((item) => isLeagueParticipant(item))
    .reduce((prev, roster) => {
      const { id: userId, role } = roster;
      const updateProps = getFilterProps(roster, [
        'dn2',
        'bHold',
        'avatar',
        'role',
        'bCoHost',
      ]);
      if (
        !prev.some((item) => {
          if (item.user_id === userId) {
            // in case of repeated roster indication
            Object.keys(updateProps).forEach((key) => {
              item[key] = updateProps[key];
            });
            return true;
          }
          return false;
        })
      ) {
        const isMe = currentUser.userId === userId;
        const isAttendee = isViewOnly(role);
        if (isMe) {
          participantsMap.setCurrentUserId(userId);
          if (role !== undefined) {
            participantsMap.setIsWebinarAttendee(isAttendee);
          }
          updateCanUnmuteAudioOrVideo(store, adaptor, updateProps);
        }
        if (participantsMap.isCurrentUserWebinarAttendee && !isMe) {
          // webinar attendee don't need to know others
          return prev;
        }
        const isExistParticipant = participantsMap.isExistParticipant(userId);
        const hasUpdateProps = Object.keys(updateProps).length > 0;
        if (isExistParticipant) {
          if (hasUpdateProps) {
            prev.push({
              operation: 'update',
              user_id: userId,
              ...updateProps,
            });
          }
        } else {
          const isExistWebinarAttendee =
            participantsMap.isExistWebinarAttendee(userId);
          if (isAttendee && !isExistWebinarAttendee) {
            if (isMe) {
              const userIdInfo = participantsMap.webinarJidUseridMap.get(jid);
              /** remove wrong userId from xmpp */
              if (
                userIdInfo &&
                userIdInfo.xmppUserId &&
                userIdInfo.xmppUserId !== userId
              ) {
                prev.push({
                  operation: 'remove',
                  user_id: userIdInfo.xmppUserId,
                  is_webinar_attendee: true,
                });
              }
              participantsMap.updateWebinarAttendeeUserId(jid, {
                dn2UserId: userId,
              });
            }
            prev.push({
              operation: 'add',
              user_id: userId,
              is_webinar_attendee: true,
              ...(isMe ? { is_me: true } : {}),
              ...updateProps,
            });
          } else if (isExistWebinarAttendee) {
            if (hasUpdateProps) {
              prev.push({
                operation: 'update',
                user_id: userId,
                is_webinar_attendee: true,
                ...updateProps,
              });
            }
          } else {
            prev.push({
              operation: 'add',
              user_id: userId,
              ...(isMe ? { is_me: true } : {}),
              ...updateProps,
            });
          }
        }
      }
      return prev;
    }, []);
  participantsMap.update(addList);
  participants = participants.concat(addList);

  /** updated participants */
  const updateList = (data.update || [])
    .filter((item) => isLeagueParticipant(item))
    .reduce((prev, roster) => {
      const { id: userId } = roster;
      const updateProps = getFilterProps(roster, [
        'dn2',
        'audio',
        'bHold',
        'muted',
        'bVideoOn',
        'role',
        'bCoHost',
      ]);
      if (Object.keys(updateProps).length > 0) {
        if (
          !prev.some((item) => {
            if (item.user_id === userId) {
              // in case of repeated roster indication
              Object.keys(updateProps).forEach((key) => {
                item[key] = updateProps[key];
              });
              return true;
            }
            return false;
          })
        ) {
          const isMe = currentUser.userId === userId;
          if (isMe) {
            updateCanUnmuteAudioOrVideo(store, adaptor, updateProps);
          }
          const isExistParticipant = participantsMap.isExistParticipant(userId);
          if (isExistParticipant) {
            prev.push({
              operation: 'update',
              user_id: userId,
              ...updateProps,
            });
          } else {
            const isExistWebinarAttendee =
              participantsMap.isExistWebinarAttendee(userId);
            if (isExistWebinarAttendee) {
              prev.push({
                operation: 'update',
                user_id: userId,
                is_webinar_attendee: true,
                ...updateProps,
              });
            }
          }
        }
      }
      return prev;
    }, []);
  participantsMap.update(updateList);
  participants = participants.concat(updateList);

  /** removed participants */
  const removeList = (data.remove || []).reduce((prev, { id: userId }) => {
    if (!prev.some((item) => item.user_id === userId)) {
      const isExistParticipant = participantsMap.isExistParticipant(userId);
      if (isExistParticipant) {
        prev.push({
          operation: 'remove',
          user_id: userId,
        });
      } else {
        /** only disable allow to talk will run into this, should not remove, only remove audio_status */
        const webinarAttendeeUser = participantsMap.getWebinarAttendee(userId);
        if (webinarAttendeeUser && webinarAttendeeUser.audio_status) {
          prev.push({
            operation: 'update',
            user_id: userId,
            is_webinar_attendee: true,
            audio_status: '',
          });
        }
      }
    }
    return prev;
  }, []);
  participantsMap.update(removeList);
  participants = participants.concat(removeList);

  if (participants.length > 0) {
    adaptor.notifyControllerParticipantsList(participants);
  }
}

export function updateWebinarAttendeesListByWs(data, store, adaptor) {
  const { getState } = store;
  const {
    meeting: { currentUser },
  } = getState();
  let participants = [];
  const attendeeList = (data || []).filter((item) => {
    item.node = parseInt(item.node, 10);
    return isLeagueWebinarAttendee(item);
  });
  const addList = attendeeList.reduce((prev, roster) => {
    const { node: userId, jid } = roster;
    const updateProps = getFilterProps(roster, ['name'], true);
    if (
      !prev.some((item) => {
        if (item.user_id === userId) {
          // in case of repeated roster indication
          Object.keys(updateProps).forEach((key) => {
            item[key] = updateProps[key];
          });
          return true;
        }
        return false;
      })
    ) {
      const isMe = currentUser.userId === userId;
      if (isMe) {
        participantsMap.setCurrentUserId(userId);
        updateCanUnmuteAudioOrVideo(store, adaptor, updateProps);
      }
      const isExistWebinarAttendee = participantsMap.isExistWebinarAttendee(
        userId,
        jid,
      );
      if (isExistWebinarAttendee) {
        const hasUpdateProps = Object.keys(updateProps).length > 0;
        if (hasUpdateProps) {
          prev.push({
            operation: 'update',
            user_id: participantsMap.getExistWebinarAttendeeUserId(userId, jid),
            is_webinar_attendee: true,
            ...updateProps,
          });
        }
      } else {
        participantsMap.updateWebinarAttendeeUserId(jid, {
          xmppUserId: userId,
        });
        prev.push({
          operation: 'add',
          user_id: userId,
          is_webinar_attendee: true,
          ...(isMe ? { is_me: true } : {}),
          ...updateProps,
        });
      }
    }
    return prev;
  }, []);
  participantsMap.update(addList);
  participants = participants.concat(addList);

  /** web-im only give the full list, need to compare from list */
  const memberListMap = new Map();
  /** use map calculate from n*n to 2*n */
  attendeeList.forEach(({ node: userId }) => {
    memberListMap.set(userId, true);
  });
  const removeList = [];
  participantsMap.webinarAttendes.forEach((_, attendeeId) => {
    // self should not be remove from here, because sometimes xmpp nodeId is not correct
    if (!memberListMap.has(attendeeId) && currentUser.userId !== attendeeId) {
      removeList.push({
        operation: 'remove',
        user_id: attendeeId,
        is_webinar_attendee: true,
      });
    }
  });
  memberListMap.clear();
  participantsMap.update(removeList);
  participants = participants.concat(removeList);

  if (participants.length > 0) {
    adaptor.notifyControllerParticipantsList(participants);
  }
}
