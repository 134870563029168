import {
  CHAT_MSG_TYPE_TO_ALL,
  CHAT_MSG_TYPE_TO_SILENT_MODE_USERS,
  CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
  CHAT_MSG_TYPE_TO_INDIVIDUAL_SILENT_MODE_USER,
  CHAT_FILE_3RD_PARTY_MESSAGE_TYPE,
  CHAT_FILE_TYPE,
  thirdPartyFileEncryptedKeys,
} from './constants';
import {
  CHAT_PRIVILEDGE_EVERYONE_PUBLICLY,
  PANELIST_CHAT_PRIVILEGE,
  CHAT_PRIVILEDGE_HOST,
  USER_STATUS_FAILOVER,
} from '../../constants/Constants';
// import {
//   setIsReceiverAbsent,
//   setIsReceiverRestrict,
//   setIsSenderInsufficientPrivileges,
//   setIsImproperReceiver,
// } from './redux/chat-action';
import { isMeInRoomSelector } from '../breakout-room/redux/bo-room-selector';
import { isCoOrHost, isNewChatUIReady } from '../../global/service';
import {
  getCorrect3rdPartyFileUrl,
  getFileSuffix,
  isBuildInChatReceiver,
  isMMRSupportWRTwoWayChat,
  isWRTwoWayChatWebEnable,
} from './utils';
import {
  clear3rdPartyInfo,
  updateSenderChatMessage,
} from './redux/chat-thunk-action';
import { sendSocketMessage } from '../../actions/SocketActions';
import { WS_CONF_CHAT_FILE_TRANSFER_REQ } from '../../constants/ZoomSocketEventTypes';
import meetingConfig from 'meetingConfig';
import { sendNewChatFileShare } from '../new-chat/redux/new-chat-file-thunk';
import {
  FILE_DOWNLOAD_DIALOG_TITLE,
  DOWNLOAD,
  fileDownloadDialogContent,
  FILE_DOWNLOAD_TRUST_TEXT,
} from './resource';
import Modal from '../../global/components/widget/modal';
import { easyStore, storeType } from '../../global/easy-store';
import { decodeBase64 } from '../../global/util';
import { instantDialogRef } from '../dialog/service/command-dialog';
import { isWebinar } from '../../global/service/meeting-types';
import { isPanelist, isViewOnly } from '../../global/service/user-types';
import {
  defaultCancelButtonProps,
  defaultOkButtonProps,
} from '../dialog/constant';
import { setChatWarning } from '../../actions/MeetingUIPureAction';
import { SEND_CHAT_WARNING } from '../../global/constant';
import { CANCEL } from '../../global/resource';
import { beginDecrypt, beginEncrypt, ivType } from '../../global/crypto';
import { omit } from 'lodash';
import { errorLog } from '../../global/web-client-logger';

export const encryptFileInfo = (file, encryptedKeys, ivType) => {
  if (!file) {
    return new Promise((resolve, reject) => {
      reject('No file info');
    });
  }
  const promiseCollection = [];
  encryptedKeys.forEach((key) => {
    if (!file[key]) {
      return;
    }

    promiseCollection.push(
      beginEncrypt({
        text: file[key],
        type: ivType,
      }),
    );
  });

  return Promise.all(promiseCollection);
};

export const decryptFileInfo = (file, senderSN, decryptedKeys, ivType) => {
  if (!file) {
    return new Promise((resolve, reject) => {
      reject('No file info');
    });
  }
  const promiseCollection = [];
  decryptedKeys.forEach((key) => {
    if (!file[key]) {
      return;
    }

    promiseCollection.push(
      beginDecrypt({
        decryptedText: file[key],
        userId: senderSN,
        type: ivType,
      }),
    );
  });

  return Promise.all(promiseCollection);
};

export const addEventListenerFor3rdPartyFile = (store) => {
  const { dispatch, getState } = store;

  window.addEventListener('message', (e) => {
    // check message type and origin
    if (
      e &&
      e.data?.type === CHAT_FILE_3RD_PARTY_MESSAGE_TYPE &&
      e.origin === meetingConfig.integrationDomain
    ) {
      if (!e.data?.result) {
        return;
      }

      let message = {};
      try {
        message = JSON.parse(e.data?.result?.wd);
      } catch {
        dispatch(clear3rdPartyInfo());
        return;
      }

      const {
        fileSize,
        fileId,
        fileName,
        type,
        downloadUrl,
        previewPath,
        previewUrl,
        cnonce,
        userId,
      } = message;
      const {
        chat: { thirdPartyFileCnonce, thirdPartyFileReplyTo },
        meeting: { currentUser },
      } = getState();
      if (cnonce !== thirdPartyFileCnonce || currentUser.uid !== userId) {
        dispatch(clear3rdPartyInfo());
        return;
      }
      const isNewChat = isNewChatUIReady(!!currentUser.bid);
      const destNode =
        isNewChat && !_.isEmpty(thirdPartyFileReplyTo)
          ? {
              destNodeID: thirdPartyFileReplyTo.receiverId,
              attendeeNodeID: 0, // TODO need update if new chat support webinar
            }
          : getDestNode(getState(), dispatch);
      if (_.isNil(destNode?.destNodeID)) {
        dispatch(clear3rdPartyInfo());
        return;
      }
      const shareFile = {
        fileID: fileId,
        fileSize,
        fileName,
        fileType: CHAT_FILE_TYPE.THIRD_PARTY,
        destNodeID: destNode.destNodeID,
        attendeeNodeID: destNode.attendeeNodeID,
        receiverType: 0,
        previewPath,
        previewUrl,
        downloadUrl,
        correctPreviewUrl: getCorrect3rdPartyFileUrl(message),
        shareType: type,
        bEncrypted: false,
      };
      const receiverId =
        destNode.destNodeID === CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST
          ? destNode.attendeeNodeID
          : destNode.destNodeID;
      const chatMessage = {
        receiverId,
        isSilentModeUser: false,
        file: shareFile,
      };
      const body = omit(shareFile, 'correctPreviewUrl');
      body.fileSize = parseInt(fileSize);
      const sendFileMessage = () => {
        return (dispatch) => {
          if (isNewChat) {
            dispatch(sendNewChatFileShare(body, chatMessage));
          } else {
            dispatch(
              sendSocketMessage({
                evt: WS_CONF_CHAT_FILE_TRANSFER_REQ,
                body,
              }),
            );
            dispatch(updateSenderChatMessage(chatMessage));
          }

          dispatch(clear3rdPartyInfo());
        };
      };
      if (shouldEncrypt3rdPartyFile()) {
        encryptFileInfo(
          body,
          thirdPartyFileEncryptedKeys,
          ivType.THIRD_CHAT_FILE_INFO,
        )
          .then(
            ([
              fileName,
              fileSize,
              shareType,
              previewPath,
              previewUrl,
              downloadUrl,
            ]) => {
              body.bEncrypted = true;
              body.fileName = fileName;
              body.fileSize = fileSize;
              body.shareType = shareType;
              body.previewPath = previewPath;
              body.previewUrl = previewUrl;
              body.downloadUrl = downloadUrl;
              dispatch(sendFileMessage());
            },
          )
          .catch((e) => errorLog(e));
      } else {
        dispatch(sendFileMessage());
      }
    }
  });
};

export const getDestNode = (state, dispatch) => {
  const {
    chat: {
      receiverId: originReceiverId,
      waitingRoomChatFlag,
      receiverUniqueId,
      receiver,
    },
    meeting: {
      chatPriviledge,
      panelistChatPriviledge,
      currentUser,
      xmppUserList,
    },
    attendeesList: { attendeesList },
    meetingUI: { isOnHold },
  } = state;

  const coOrHost = isCoOrHost(currentUser);
  const isBuiltInChatType = isBuildInChatReceiver(originReceiverId);
  const isSupportWaitingRoomTWChat =
    isMMRSupportWRTwoWayChat(waitingRoomChatFlag);
  const currentUserPrivateChatMsgDisabled = currentUser.bPrivateChatMsgDisabled;
  const isMeInRoom = isMeInRoomSelector(state);

  if (isOnHold) {
    // waiting room participant send to host group
    return {
      destNodeID: originReceiverId,
    };
  }

  // waiting room
  if (originReceiverId === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS) {
    if (!coOrHost) {
      return dispatch(setChatWarning([SEND_CHAT_WARNING.NO_PRIVILEGE])); //dispatch(setIsSenderInsufficientPrivileges(true));
    }
    return {
      destNodeID: originReceiverId,
    };
  }

  const meetingReceiver = attendeesList.find(
    (user) =>
      user.userId === originReceiverId || user.zoomID === receiverUniqueId,
  );
  let receiverId = originReceiverId;
  // the failover user nodeId changes
  if (meetingReceiver && meetingReceiver.nUserStatus === USER_STATUS_FAILOVER) {
    receiverId = meetingReceiver.userId;
  }

  if (meetingReceiver && meetingReceiver.bHold) {
    if (coOrHost) {
      if (isWRTwoWayChatWebEnable() && isSupportWaitingRoomTWChat) {
        return {
          destNodeID: receiverId,
          attendeeNodeId: CHAT_MSG_TYPE_TO_INDIVIDUAL_SILENT_MODE_USER,
        };
      }
      return dispatch(setChatWarning([SEND_CHAT_WARNING.ABSENT, receiver])); //dispatch(setIsReceiverAbsent(true));
    }
    return dispatch(setChatWarning([SEND_CHAT_WARNING.NO_PRIVILEGE, receiver])); //dispatch(setIsSenderInsufficientPrivileges(true));
  }

  // webinar
  if (isWebinar()) {
    if (isViewOnly(currentUser.userRole)) {
      return {
        destNodeID: receiverId,
      };
    }
    // restrict webinar host send direct message to panelist
    if (
      (meetingReceiver && meetingReceiver.bPrivateChatMsgDisabled) ||
      (meetingReceiver && currentUserPrivateChatMsgDisabled)
    ) {
      return dispatch(setChatWarning([SEND_CHAT_WARNING.RESTRICTED, receiver])); //dispatch(setIsReceiverRestrict(true));
    }
    const attendee = xmppUserList.attendees.find(
      (user) => user.node === receiverId,
    );
    if (coOrHost) {
      if (attendee) {
        return {
          destNodeID: CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
          attendeeNodeID: attendee.node,
        };
      }
      if (meetingReceiver || attendee || isBuiltInChatType) {
        return {
          destNodeID: receiverId,
        };
      }
      // return dispatch(setIsReceiverAbsent(true));
      return dispatch(setChatWarning([SEND_CHAT_WARNING.ABSENT, receiver]));
    }
    if (isPanelist(currentUser.userRole) && !coOrHost) {
      if (
        panelistChatPriviledge === PANELIST_CHAT_PRIVILEGE.ALL_PANELIST &&
        (receiverId === CHAT_MSG_TYPE_TO_ALL || attendee)
      ) {
        // return dispatch(setIsImproperReceiver(true));
        return dispatch(
          setChatWarning([SEND_CHAT_WARNING.IMPROPER_RECEIVER, receiver]),
        );
      }
      if (attendee) {
        return {
          destNodeID: CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
          attendeeNodeID: attendee.node,
        };
      }
      if (meetingReceiver || isBuiltInChatType) {
        return {
          destNodeID: receiverId,
        };
      }
      // return dispatch(setIsReceiverAbsent(true));
      return dispatch(setChatWarning([SEND_CHAT_WARNING.ABSENT, receiver]));
    }
  }

  // meeting
  if (meetingReceiver) {
    if (currentUserPrivateChatMsgDisabled) {
      // return dispatch(setIsReceiverRestrict(true));
      return dispatch(setChatWarning([SEND_CHAT_WARNING.RESTRICTED, receiver]));
    }
    if (meetingReceiver.bPrivateChatMsgDisabled) {
      // return dispatch(setIsReceiverRestrict(true));
      return dispatch(setChatWarning([SEND_CHAT_WARNING.RESTRICTED, receiver]));
    }
    if (!coOrHost) {
      const isReceiverHost = meetingReceiver.isHost || meetingReceiver.bCoHost;
      if (
        (chatPriviledge === CHAT_PRIVILEDGE_HOST ||
          chatPriviledge === CHAT_PRIVILEDGE_EVERYONE_PUBLICLY) &&
        !isReceiverHost
      ) {
        // return dispatch(setIsImproperReceiver(true));
        return dispatch(
          setChatWarning([SEND_CHAT_WARNING.IMPROPER_RECEIVER, receiver]),
        );
      }
      if (meetingReceiver.bid && !isMeInRoom) {
        // return dispatch(setIsReceiverAbsent(true));
        return dispatch(setChatWarning([SEND_CHAT_WARNING.ABSENT, receiver]));
      }
    }
    return {
      destNodeID: receiverId,
    };
  }
  if (!coOrHost) {
    const isReceiverEveryone = receiverId === CHAT_MSG_TYPE_TO_ALL;
    if (chatPriviledge === CHAT_PRIVILEDGE_HOST && isReceiverEveryone) {
      // return setIsImproperReceiver(true);
      return dispatch(
        setChatWarning([SEND_CHAT_WARNING.IMPROPER_RECEIVER, receiver]),
      );
    }
  }

  if (isBuiltInChatType)
    return {
      destNodeID: receiverId,
    };

  return dispatch(setChatWarning([SEND_CHAT_WARNING.ABSENT, receiver]));
};

export function doubleConfirmBeforeDownloadFile({
  file,
  downloadFileFunc,
  fileDownloadPromptIgnoreList,
}) {
  const senderIds = easyStore.easyGet('remember_file_sender_ids');
  const senderIdList = senderIds ? senderIds.split(',') : [];
  const { fileName } = file;
  const suffix = `.${getFileSuffix(file.fileName)}`;

  if (
    fileDownloadPromptIgnoreList &&
    !fileDownloadPromptIgnoreList.includes(suffix) &&
    !file.uploadBaseInfo?.destNode && // not be sent by myself
    !senderIdList.includes(String(file.destNodeID))
    // if message is synced from IM, destNodeID is always the txtGW, so this prompts will not popup
  ) {
    const content = fileDownloadDialogContent(
      decodeBase64(file.senderName),
      fileName,
    );
    Modal.confirm({
      className: 'zm-modal-legacy chat-download-dialog',
      title: FILE_DOWNLOAD_DIALOG_TITLE,
      okText: DOWNLOAD,
      okButtonProps: defaultOkButtonProps,
      cancelButtonProps: defaultCancelButtonProps,
      contentLabel: content,
      content: content,
      cancelText: CANCEL,
      checkboxText: FILE_DOWNLOAD_TRUST_TEXT,
      checkboxProps: {
        className: 'chat-download-dialog__checkbox',
        checked: easyStore.easyGet('remember_file_sender_checked'),
      },
      ref: instantDialogRef,
      onOk: (e, checked) => {
        easyStore.easySet(
          'remember_file_sender_checked',
          checked,
          storeType.sessionStorage,
        );
        if (checked) {
          senderIdList.push(String(file.destNodeID));
          easyStore.easySet(
            'remember_file_sender_ids',
            senderIdList.join(','),
            storeType.sessionStorage,
          );
        }
        downloadFileFunc();
      },
      onCancel: (e, checked) => {
        easyStore.easySet(
          'remember_file_sender_checked',
          checked,
          storeType.sessionStorage,
        );
      },
    });
    return;
  }
  downloadFileFunc();
}

export const shouldEncrypt3rdPartyFile = () =>
  meetingConfig.meetingOptions.enable3rdFileEncrypt;

export function resolveFileInfo(file, senderSN, updateMeetingChatWithFile) {
  return (dispatch) => {
    if (!file) {
      return;
    }
    const fileInfo = file;
    if (fileInfo.fileType === CHAT_FILE_TYPE.LOCAL) {
      // Receive Local File
      if (meetingConfig.fileTransfer.isEnableFileTransferEncrypted) {
        decryptFileInfo(
          fileInfo,
          senderSN,
          ['fileName', 'fileSize'],
          ivType.CHAT_FILE_INFO,
        )
          .then(([{ message: fileName }, { message: fileSize }]) => {
            fileInfo.bEncrypted = true;
            fileInfo.fileName = fileName;
            fileInfo.fileSize = fileSize;
            dispatch(updateMeetingChatWithFile(fileInfo));
          })
          .catch((e) => errorLog(e));
      } else {
        dispatch(updateMeetingChatWithFile(fileInfo));
      }
    } else {
      // Receive 3rd-party file
      const { bEncrypted } = fileInfo;
      if (bEncrypted) {
        decryptFileInfo(
          fileInfo,
          senderSN,
          thirdPartyFileEncryptedKeys,
          ivType.THIRD_CHAT_FILE_INFO,
        )
          .then(
            ([
              { message: fileName },
              { message: fileSize },
              { message: shareType },
              { message: previewPath },
              { message: previewUrl },
            ]) => {
              fileInfo.bEncrypted = true;
              fileInfo.fileName = fileName;
              fileInfo.fileSize = fileSize;
              fileInfo.shareType = shareType;
              fileInfo.previewPath = previewPath;
              fileInfo.previewUrl = previewUrl;
              fileInfo.correctPreviewUrl = getCorrect3rdPartyFileUrl(fileInfo);
              dispatch(updateMeetingChatWithFile(fileInfo));
            },
          )
          .catch((e) => errorLog(e));
      } else {
        dispatch(updateMeetingChatWithFile(fileInfo));
      }
    }
  };
}
