import { getDefaultUserRole } from '../global/service/user-role';

export const emptyUser = {
  userRole: getDefaultUserRole(),
  userId: 0, // WS_CONF_JOIN_RES
  userGUID: 0,
  participantId: 0,
  displayName: null,
  res: {},
  audio: null,
  muted: false,
  avatar: null,
  isHost: false,
  bCoHost: false,
  hasAsn: false,
  audioConnectionStatus: 0,
  bHold: false,
  bRaiseHand: false,
  feedback: 0,
  userType: 0,
  sharerOn: false,
  sharerPause: false,
  bLocalRecord: false,
  isAllowTalk: false,
  isGuest: true,
  bCanAdmit: false,
  bCanPinMultiVideo: false,
  bCapsPinMultiVideo: false,
  videoOrder: 0,
  keepPhoneConnected: false,
  strConfUserID: '', // alias of jid
  bid: '',
  isAddedToCameraControlGroup: false,
};

export const emptyNonverbalFeedbackCount = {
  handshake: 0,
  yes: 0,
  no: 0,
  slower: 0,
  faster: 0,
  dislike: 0,
  like: 0,
  clap: 0,
  coffee: 0,
  away: 0,
  more: 0,
};
