import { globalVariable } from '../../../../global/global-variable';
import { sendSocketMessage } from '../../../../actions/SocketActions';
import { canTurnOnOffWatermarkSelector } from '../selectors/video-status-selector';

import { setIsWatermarkTurnOn } from '../video-action';

import {
  WATERMARK_VISIBLE_ON,
  ConnectionType,
  WATERMARK_COVER_TYPE,
} from '../../enum';

import { WS_CONF_TURN_ON_OFF_WATERMARK_REQ } from '../../../../constants/ZoomSocketEventTypes';
import { SWITCH_WATER_MARK_FLAG } from '../../../../constants/AVNotifyMediaSDKTypes';
import { getWaterMarkText } from '../../../../global/service';

export const renderWatermark = (enableWaterMark) => (dispatch, getState) => {
  const state = getState();
  const {
    video: {
      watermarkVisibleOn,
      watermarkOpacityLevel,
      watermarkCoverType,
      watermarkPosition,
    },
  } = state;
  let type;
  if (watermarkVisibleOn === WATERMARK_VISIBLE_ON.SHARED_CONTENT) {
    type = ConnectionType.SHARING;
  } else if (watermarkVisibleOn === WATERMARK_VISIBLE_ON.VIDEO_FEEDS) {
    type = ConnectionType.VIDEO;
  } else {
    type = undefined;
  }
  dispatch(setIsWatermarkTurnOn(enableWaterMark));
  const message = {
    enableWaterMark,
    waterMarkText: getWaterMarkText(),
    type,
    watermarkOpacity: watermarkOpacityLevel,
    watermarkRepeated: watermarkCoverType === WATERMARK_COVER_TYPE.REPEATED,
    watermarkPosition,
  };
  if (globalVariable.avSocket?.sendSocket) {
    globalVariable.avSocket.sendSocket(SWITCH_WATER_MARK_FLAG, message);
  }
};

export const turnOnOffWatermark = (isTurnOn) => (dispatch, getState) => {
  const state = getState();
  const canTurnOnOffWatermark = canTurnOnOffWatermarkSelector(state);
  if (!canTurnOnOffWatermark) return;

  const data = {
    evt: WS_CONF_TURN_ON_OFF_WATERMARK_REQ,
    body: {
      bOn: isTurnOn,
    },
  };
  dispatch(sendSocketMessage(data));
};
