import { createActions } from 'redux-actions';

const SET_IS_WB_SETTING_VISIBLE = 'SET_IS_WB_SETTING_VISIBLE';
const SET_IS_SHOW_WHITEBOARD_WINDOW = 'SET_IS_SHOW_WHITEBOARD_WINDOW';
const SET_WHITEBOARD_STATUS = 'SET_WHITEBOARD_STATUS';
const SET_WHITEBOARD_CONFIG = 'SET_WHITEBOARD_CONFIG';
const SET_WHITEBOARD_SHARER = 'SET_WHITEBOARD_SHARER';
const SET_WHITEBOARD_PERMISSION_CODE = 'SET_WHITEBOARD_PERMISSION_CODE';
const SET_WHITEBOARD_TOKEN = 'SET_WHITEBOARD_TOKEN';
const SET_IS_WHITEBOARD_SHARE_STREAM_ON = 'SET_IS_WHITEBOARD_SHARE_STREAM_ON';
const SET_IS_WHITEBOARD_CANVAS_READY = 'SET_IS_WHITEBOARD_CANVAS_READY';
const SET_HAS_START_WHITEBOARD_SHARE = 'SET_HAS_START_WHITEBOARD_SHARE';
const WHITEBOARD_RESET = 'WHITEBOARD_RESET';
const TOGGLE_SHOW_PERMISSION_WIDGET = 'TOGGLE_SHOW_PERMISSION_WIDGET';
const SET_WHITEBOARD_INIT_ROLE = 'SET_WHITEBOARD_INIT_ROLE';
const SET_WHITEBOARD_INIT_WITHOUT_HOST = 'SET_WHITEBOARD_INIT_WITHOUT_HOST';
const SET_IS_WHITEBOARD_WINDOW_LOADING = 'SET_IS_WHITEBOARD_WINDOW_LOADING';
const SET_HAVE_ACCESS_AFTER_MEETING = 'SET_HAVE_ACCESS_AFTER_MEETING';

export const {
  setIsWbSettingVisible,
  setIsShowWhiteboardWindow,
  setWhiteboardStatus,
  setWhiteboardConfig,
  setWhiteboardSharer,
  setWhiteboardPermissionCode,
  setWhiteboardToken,
  setIsWhiteboardShareStreamOn,
  setIsWhiteboardCanvasReady,
  setHasStartWhiteboardShare,
  whiteboardReset,
  toggleShowPermissionWidget,
  setWhiteboardInitRole,
  setWhiteboardInitWithoutHost,
  setIsWhiteboardWindowLoading,
  setHaveAccessAfterMeeting,
} = createActions({
  [SET_IS_WB_SETTING_VISIBLE]: (payload) => payload,
  [SET_IS_SHOW_WHITEBOARD_WINDOW]: (payload) => payload,
  [SET_WHITEBOARD_STATUS]: (payload) => payload,
  [SET_WHITEBOARD_CONFIG]: (payload) => payload,
  [SET_WHITEBOARD_SHARER]: (payload) => payload,
  [SET_WHITEBOARD_PERMISSION_CODE]: (payload) => payload,
  [SET_WHITEBOARD_TOKEN]: (payload) => payload,
  [SET_IS_WHITEBOARD_SHARE_STREAM_ON]: (payload) => payload,
  [SET_IS_WHITEBOARD_CANVAS_READY]: (payload) => payload,
  [SET_HAS_START_WHITEBOARD_SHARE]: (payload) => payload,
  [WHITEBOARD_RESET]: (payload) => payload,
  [TOGGLE_SHOW_PERMISSION_WIDGET]: (payload) => payload,
  [SET_WHITEBOARD_INIT_ROLE]: (payload) => payload,
  [SET_WHITEBOARD_INIT_WITHOUT_HOST]: (payload) => payload,
  [SET_IS_WHITEBOARD_WINDOW_LOADING]: (payload) => payload,
  [SET_HAVE_ACCESS_AFTER_MEETING]: (payload) => payload,
});
