import { sendSocketMessage } from '../../../actions/SocketActions';
import { WS_CONF_SEND_DTRESULT_REQ } from '../../../constants/ZoomSocketEventTypes';
import { isAutoTurnOnVideo } from '../../../controller';
import { STEP_TYPES } from '../consts';
import { parseTestResultToParam } from '../utils';
import { setTestStep, toggleTestModal } from './test-meeting-action';

export const uploadTestResultThunk = (testResult) => {
  return (dispatch) => {
    dispatch(
      sendSocketMessage({
        evt: WS_CONF_SEND_DTRESULT_REQ,
        body: {
          cams: parseTestResultToParam(testResult.camera),
          spks: parseTestResultToParam(testResult.speaker),
          mics: parseTestResultToParam(testResult.microphone),
        },
      }),
    );
  };
};

export function showTestMeetingModalThunk() {
  return (dispatch) => {
    dispatch(toggleTestModal(true));
    const videoOn = isAutoTurnOnVideo();
    const step = videoOn ? STEP_TYPES.TEST_CAMERA : STEP_TYPES.START_CAMERA;
    dispatch(setTestStep(step));
  };
}

export function showTestAudioDeviceModalThunk() {
  return (dispatch) => {
    dispatch(setTestStep(STEP_TYPES.TEST_SPEAKER));
    dispatch(toggleTestModal(true));
  };
}
