/* eslint-disable react/prop-types */

import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DOM_KEY_CODE } from '../../../enum';

const ZoomButton = forwardRef((props, ref) => {
  const {
    type,
    size,
    width,
    block,
    outlineStyle,
    className,
    styleProps,
    children,
    ariaLabel,
    iconType,
    disabled,
    loading,
    role,
    ...rest
  } = props;
  let sizeClz = '';
  switch (size) {
    case 'large':
      sizeClz = 'lg';
      break;
    case 'small':
      sizeClz = 'sm';
      break;
    default:
      break;
  }
  const buttonRef = useRef(null);
  useImperativeHandle(ref, () => buttonRef.current);
  let styles = styleProps || {};
  if (!_.isNil(width)) {
    styles = { ...styleProps, width };
  }

  const classes = classNames('zm-btn', className, {
    [`zm-btn--${type}`]: type,
    [`zm-btn__outline--${outlineStyle}`]: outlineStyle,
    [`zm-btn--${sizeClz}`]: sizeClz,
    'zm-btn--block': block,
    'zm-btn--disabled': disabled,
    'zm-btn-icon': iconType,
  });
  const iconNode = iconType ? (
    <i className={classNames('zm-icon', `zm-icon-${iconType}`)} />
  ) : null;
  const handleClick = (e) => {
    const { onClick } = props;
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  const handleKeyDown = (e) => {
    if (
      !disabled &&
      (e.keyCode === DOM_KEY_CODE.SPACE || e.keyCode === DOM_KEY_CODE.ENTER)
    ) {
      handleClick(e);
    }
  };

  const { htmlType, ...otherProps } = rest;
  if (otherProps.href !== undefined) {
    return (
      <a
        {...otherProps}
        className={classes}
        style={styles}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role={role || 'button'}
        aria-label={ariaLabel}
        tabIndex={disabled ? -1 : 0}
        ref={buttonRef}
      >
        {iconNode}
        {children}
      </a>
    );
  }
  return (
    <button
      {...otherProps}
      type={htmlType || 'button'}
      className={classes}
      style={styles}
      aria-label={ariaLabel}
      onClick={handleClick}
      tabIndex={disabled ? -1 : 0}
      ref={buttonRef}
    >
      {iconNode}
      {children}
      <span
        style={{ display: loading ? 'inline-block' : 'none' }}
        className="loading"
      />
    </button>
  );
});

ZoomButton.displayName = 'ZoomButton';
ZoomButton.propTypes = {
  type: PropTypes.oneOf(['default', 'primary', 'brown', 'error']),
  block: PropTypes.bool,
  outlineStyle: PropTypes.oneOf(['blue', 'white']),
  iconType: PropTypes.string,
  size: PropTypes.oneOf(['large', 'default', 'small']),
  width: PropTypes.number,
  htmlType: PropTypes.oneOf(['submit', 'button', 'reset']),
  className: PropTypes.string,
  styleProps: PropTypes.objectOf(PropTypes.string),
  ariaLabel: PropTypes.string,
  tabIndex: PropTypes.number,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
ZoomButton.defaultProps = {
  type: 'default',
  block: false,
  outlineStyle: 'blue',
  iconType: null,
  size: 'default',
  width: null,
  htmlType: 'button',
  className: '',
  styleProps: null,
  ariaLabel: '',
  tabIndex: 0,
  loading: false,
  onClick: () => {},
};

export default ZoomButton;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/components/widget/button/zoom-button.scss';
