import { getChromeVersion, getEdgeVersion, isChrome, isOpera } from './util';

function getPIPCreator() {
  if (window.self !== window.top) {
    return window.top;
  }

  return window.self;
}

function isSameOrigin() {
  const context = getPIPCreator();
  try {
    return !!context.location.origin;
  } catch {
    return false;
  }
}

export function isSupportPIP() {
  return (
    'documentPictureInPicture' in window &&
    isSameOrigin() &&
    (getChromeVersion() >= supportedBrowserVersions.CHROME ||
      getEdgeVersion() >= supportedBrowserVersions.EDGE)
  );
}

const EXIT_TRIGGER_TYPE = {
  EVENT: 'event',
  FUNCTION_CALL: 'function_call',
};

const EVENT_NAME_LIST = ['resize', 'afterExit', 'afterEnter'];
export const EVENT_NAME_MAP = {
  RESIZE: 'resize',
  AFTER_EXIT: 'afterExit',
  AFTER_ENTER: 'afterEnter',
};

const PLACEHOLDER_ELEMENT_ID = 'PIP-window--placeholder_element_id';

const supportedBrowserVersions = {
  CHROME: 116,
  EDGE: 123,
};

class PIPWindow {
  constructor(config) {
    // In PIP window of edge, click on some item in the menu causes a crash. - Waiting for the official Edge team to fix it.
    if (
      getChromeVersion() >= supportedBrowserVersions.CHROME ||
      getEdgeVersion() >= supportedBrowserVersions.EDGE
    ) {
      this._init(config);
    } else {
      // eslint-disable-next-line no-console, prettier/prettier
      console.warn(
        'The browser does not support Document Picture-in-Picture (PiP) window',
      );
    }
  }

  _init(options) {
    const { pipBoxId } = options;
    this.pipBoxId = pipBoxId;
  }

  _copyStyleSheets() {
    [...document.styleSheets].forEach((styleSheet) => {
      try {
        const cssRules = [...styleSheet.cssRules]
          .map((rule) => rule.cssText)
          .join('');
        const style = document.createElement('style');

        style.textContent = cssRules;
        this.pipWin.document.head.appendChild(style);
      } catch (e) {
        const link = document.createElement('link');

        link.rel = 'stylesheet';
        link.type = styleSheet.type;
        link.media = styleSheet.media;
        link.href = styleSheet.href;
        this.pipWin.document.head.appendChild(link);
      }
    });
  }

  _initEvent() {
    this.pipWin.addEventListener('resize', () => {
      // (Exception)Failed to execute 'resizeTo' on 'Window': resizeTo() requires user activation in document picture-in-picture
      // this.pipWin.resizeTo(this.pipWidth, this.pipHeight);
      this.resize?.({
        height: this.pipWin.innerHeight,
        width: this.pipWin.innerWidth,
      });
    });

    // this.pipWin.addEventListener('pagehide', () => {
    //   this.onLeavePIP(EXIT_TRIGGER_TYPE.EVENT);
    // });
    // the back to tab button and the close button
    this.pipWin.addEventListener('unload', () => {
      this.onLeavePIP(EXIT_TRIGGER_TYPE.EVENT);
    });

    const context = getPIPCreator();
    if (context.documentPictureInPicture) {
      context.documentPictureInPicture.addEventListener('enter', () => {
        // execute callback when enter the PIP window
        this.afterEnter?.();
      });
    }
  }

  _changeGetDomMethod() {
    // modify prototype method of Document
    // getElementById
    Document.prototype.originalGetElementById =
      Document.prototype.getElementById;
    Document.prototype.getElementById = (id) => {
      const dom = document.originalGetElementById(id);
      if (dom) {
        return dom;
      }
      return this.pipWin.document.getElementById(id);
    };
  }

  _restoreDomLocation() {
    const placeholderEle = document.getElementById(PLACEHOLDER_ELEMENT_ID);
    if (placeholderEle) {
      const parentNode = placeholderEle.parentNode;
      const pipElement = this.pipWin.document.getElementById(this.pipBoxId);
      parentNode.replaceChild(pipElement, placeholderEle);
    }
  }

  _beforeDestory() {
    if (!Document.prototype.originalGetElementById) {
      return;
    }
    Document.prototype.getElementById =
      Document.prototype.originalGetElementById;
    Document.prototype.originalGetElementById = null;
  }

  on(eventName, callback) {
    if (!callback) {
      return;
    }
    if (eventName && EVENT_NAME_LIST.includes(eventName)) {
      this[eventName] = callback;
    } else {
      // eslint-disable-next-line no-console
      console.error(`Event ${eventName} does not exist.`);
    }
  }

  off(eventName) {
    if (!eventName) {
      EVENT_NAME_LIST.forEach((name) => {
        this[name] = null;
      });
    }

    if (EVENT_NAME_LIST.includes(eventName)) {
      this[eventName] = null;
    }
  }

  async enterPIP(options) {
    if (!isSupportPIP()) {
      // eslint-disable-next-line no-console, prettier/prettier
      console.warn(
        '[warning] The boswer is not support PIP. Or PIP requires top-level window implementation when current window is iframe window',
      );
      return false;
    }

    const { pipBoxId, capturePipClickEvent, ...pipOptions } = options;

    if (pipBoxId) {
      this.pipBoxId = pipBoxId;
    }

    if (!this.pipBoxId) {
      return false;
    }

    const context = getPIPCreator();
    if (!context.documentPictureInPicture.window) {
      // this.pipWidth = pipOptions.width;
      // this.pipHeight = pipOptions.height;
      if (isChrome()) {
        // add border height(30)
        pipOptions.height = pipOptions.height + 30;
      }
      this.pipWin = await context.documentPictureInPicture.requestWindow(
        pipOptions,
      );

      this._copyStyleSheets();
      this._initEvent();
      // record target element location
      const placeholderEle = document.createElement('div');
      placeholderEle.style.display = 'none';
      placeholderEle.id = PLACEHOLDER_ELEMENT_ID;
      const pipElement = document.getElementById(this.pipBoxId);
      // TODO: move all element to the PIP window when pipElement is body node

      // pipElement is not body node
      const parentNode = pipElement.parentNode;
      parentNode.insertBefore(placeholderEle, pipElement);
      this.pipWin.document.body.append(pipElement);

      if (capturePipClickEvent) {
        this.pipWin.document.body.addEventListener('click', (e) => {
          capturePipClickEvent(e.target);
        });
      }

      this._changeGetDomMethod();
      return true;
    }

    this.onLeavePIP();
    return false;
  }

  onLeavePIP(triggerType = EXIT_TRIGGER_TYPE.FUNCTION_CALL) {
    if (isSupportPIP()) {
      if (!this.pipWin) {
        this.closePipWin();
      } else {
        this._beforeDestory();
        this._restoreDomLocation();
        if (triggerType === EXIT_TRIGGER_TYPE.FUNCTION_CALL) {
          const context = getPIPCreator();
          context.documentPictureInPicture?.window.close();
        }
        // execute callback when exit the PIP window
        this.afterExit?.();
        this.pipWin = null;
      }
    }
  }

  closePipWin() {
    if (isSupportPIP()) {
      const context = getPIPCreator();
      if (context.documentPictureInPicture?.window) {
        context.documentPictureInPicture.window.close();
      }
    }
  }
}

export default PIPWindow;

let pipWin = null;
export const getPipInstance = (targetElementId) => {
  if (!isSupportPIP() || pipWin || isOpera()) {
    return pipWin;
  }

  pipWin = new PIPWindow({ pipBoxId: targetElementId });
  return pipWin;
};
