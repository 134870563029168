// join
export const JOIN_MEETING_START = 'JOIN_MEETING_START';
export const JOIN_MEETING_SUCCESS = 'JOIN_MEETING_SUCCESS';
export const JOIN_MEETING_FAILED = 'JOIN_MEETING_FAILED';
export const SET_AV_ABILITY = 'SET_AV_ABILITY';
// record
export const RECORD_MEETING_START = 'RECORD_MEETING_START';
export const RECORD_MEETING_SUCCESS = 'RECORD_MEETING_SUCCESS';
export const RECORD_MEETING_RESET = 'RECORD_MEETING_RESET';
// sharing
export const SHARE_MEETING_START = 'SHARE_MEETING_START';
export const SHARE_MEETING_SUCCESS = 'SHARE_MEETING_SUCCESS';
export const SHARE_MEETING_FAILED = 'SHARE_MEETING_FAILED';
// lock
export const LOCK_MEETING_START = 'LOCK_MEETING_START';
export const LOCK_MEETING_SUCCESS = 'LOCK_MEETING_SUCCESS';
export const LOCK_MEETING_FAILED = 'LOCK_MEETING_FAILED';
// ws
export const WS_CONF_ROSTER_INDICATION = 'WS_CONF_ROSTER_INDICATION';
export const WS_CONF_ATTRIBUTE_INDICATION = 'WS_CONF_ATTRIBUTE_INDICATION';
export const WS_AUDIO_DIALOUT_REQ = 'WS_AUDIO_DIALOUT_REQ';
export const WS_AUDIO_CANCEL_DIALOUT_REQ = 'WS_AUDIO_CANCEL_DIALOUT_REQ';
export const WS_AUDIO_ASN_INDICATION = 'WS_AUDIO_ASN_INDICATION';
export const WS_CONF_RENAME_REQ = 'WS_CONF_RENAME_REQ';
export const WS_AUDIO_DIALOUT_RES = 'WS_AUDIO_DIALOUT_RES';
export const WS_CONF_INVITE_CRC_DEVICE_REQ = 'WS_CONF_INVITE_CRC_DEVICE_REQ';
export const WS_CONF_INVITE_CRC_DEVICE_RES = 'WS_CONF_INVITE_CRC_DEVICE_RES';
export const WS_CONF_CANCEL_INVITE_CRC_DEVICE_REQ =
  'WS_CONF_CANCEL_INVITE_CRC_DEVICE_REQ';
export const WS_CONF_RAISE_LOWER_HAND_REQ = 'WS_CONF_RAISE_LOWER_HAND_REQ';
export const WS_CONF_CHAT_REQ = 'WS_CONF_CHAT_REQ';
export const WS_CONF_CHAT_INDICATION = 'WS_CONF_CHAT_INDICATION';
export const WS_CONF_CHAT_PRIVILEDGE_REQ = 'WS_CONF_CHAT_PRIVILEDGE_REQ';
export const UPDATE_WEBINAR_TAB_STATUS = 'UPDATE_WEBINAR_TAB_STATUS';
export const UPDATE_PARTICIPANTS_REMOVE_MODE =
  'UPDATE_PARTICIPANTS_REMOVE_MODE';
export const WS_VIDEO_LEADERSHIP_INDICATION = 'WS_VIDEO_LEADERSHIP_INDICATION';
export const WS_CONF_ASSIGN_CC_REQ = 'WS_CONF_ASSIGN_CC_REQ';
export const WS_CONF_ALLOW_RAISE_HAND_REQ = 'WS_CONF_ALLOW_RAISE_HAND_REQ';
export const WS_CONF_ALLOW_UNMUTE_AUDIO_REQ = 'WS_CONF_ALLOW_UNMUTE_AUDIO_REQ';
export const WS_CONF_ALLOW_PARTICIPANT_RENAME_REQ =
  'WS_CONF_ALLOW_PARTICIPANT_RENAME_REQ';
// cc
export const UPDATE_CC = 'UPDATE_CC';
export const ENABLE_CLOSED_CAPTION = 'ENABLE_CLOSED_CAPTION';
export const UPDATE_MESSAGE_LATEST = 'UPDATE_MESSAGE_LATEST';
export const LISTEN_CLOSED_CAPTION = 'LISTEN_CLOSED_CAPTION';
export const CLOSED_CAPTION_OPENWINDOW = 'CLOSED_CAPTION_OPENWINDOW';
export const PUSH_CLOSED_CAPTION_MESSAGE = 'PUSH_CLOSED_CAPTION_MESSAGE';
export const UPDATE_CC_EDITOR = 'UPDATE_CC_EDITOR';
export const UPDATE_MESSGAE_TEMP = 'UPDATE_MESSGAE_TEMP';
export const SHOW_CC_ASSIGNTIP = 'SHOW_CC_ASSIGNTIP';
export const SHOW_CC_AVAILABLE_TIPS = 'SHOW_CC_AVAILABLE_TIPS';
export const UPDATE_TEMP_REALTIME_CC = 'UPDATE_TEMP_REALTIME_CC';
export const UPDATE_TARGET_TO_ASSIGN = 'UPDATE_TARGET_TO_ASSIGN';
export const SHOW_ASSIGN_CC_CONFIRM = 'SHOW_ASSIGN_CC_CONFIRM';

// feedback
export const UPDATE_NONVERBAL_FEEDBACK_COUNT =
  'UPDATE_NONVERBAL_FEEDBACK_COUNT';
export const CLEAR_ATTENDEE_FEEDBACK = 'CLEAR_ATTENDEE_FEEDBACK';
export const CLEAR_CURRENTUSER_FEEDBACK = 'CLEAR_CURRENTUSER_FEEDBACK';

// CallOutCountry
export const SELECT_CALLOUTCOUNTRY = 'SELECT_CALLOUTCOUNTRY';
export const SELECT_CALLOUTCOUNTRY2 = 'SELECT_CALLOUTCOUNTRY2';
export const SELECT_DAILINCOUNTRY = 'SELECT_DAILINCOUNTRY';
// change host
export const SET_MEETING_HOST = 'SET_MEETING_HOST';
export const SET_MEETING_ATTENDEE = 'SET_MEETING_ATTENDEE'; // for webinar
export const SET_MEETING_PANELIST = 'SET_MEETING_PANELIST';
export const SET_MEETING_TOPIC = 'SET_MEETING_TOPIC';
export const WS_CONF_HOST_CHANGE_INDICATION = 'WS_CONF_HOST_CHANGE_INDICATION';
export const WS_CONF_COHOST_CHANGE_INDICATION =
  'WS_CONF_COHOST_CHANGE_INDICATION';
export const WS_CONF_MODERATOR_CHANGE_INDICATION =
  'WS_CONF_MODERATOR_CHANGE_INDICATION';
// ui
export const CLEAR_ATTENDEES_LIST = 'CLEAR_ATTENDEES_LIST';
export const SHOW_CANVAS = 'SHOW_CANVAS';
export const DIALIN_OPENMODAL = 'DIALIN_OPENMODAL';
export const CHANGE_SUSPENSION_TYPE = 'CHANGE_SUSPENSION_TYPE';
export const SHOW_SUSPENSION_WINDOW = 'SHOW_SUSPENSION_WINDOW';
export const CHANGE_PERVIEW_NUM = 'CHANGE_PERVIEW_NUM';
export const WEB_CLIENT_IS_FOCUSED = 'WEB_CLIENT_IS_FOCUSED';
export const SHOW_NO_SUPPORT_INFO = 'SHOW_NO_SUPPORT_INFO';
export const SET_RIGHT_CONTAINER_WIDTH = 'SET_RIGHT_CONTAINER_WIDTH';
export const SET_COMMON_WINDOW_TO_CENTER = 'SET_COMMON_WINDOW_TO_CENTER';
export const IS_RESIZE_WINDOW = 'IS_RESIZE_WINDOW';
export const SET_WINDOW_ZINDEX = 'SET_WINDOW_ZINDEX';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const SET_ATTENDEE_RAISEHAND = 'SET_ATTENDEE_RAISEHAND';
export const INIT_SCREEN = 'INIT_SCREEN';
export const RESIZE_WINDOW = 'RESIZE_WINDOW';
export const RESIZE_SCREEN = 'RESIZE_SCREEN';
export const SHOW_JOINDIALOG = 'SHOW_JOINDIALOG';
export const SHOW_INVITEDIALOG = 'SHOW_INVITEDIALOG';
export const SHOW_MENU = 'SHOW_MENU';
export const SHOW_AUDIOMENU = 'SHOW_AUDIOMENU';
export const SHOW_VIDEOMENU = 'SHOW_VIDEOMENU';
export const SHOW_VGAMENU = 'SHOW_VGAMENU';
export const SHOW_MIC_DISALLOW_MENU = 'SHOW_MIC_DISALLOW_MENU';
export const SHOW_SHARE_DISALLOW_MENU = 'SHOW_SHARE_DISALLOW_MENU';
export const SHOW_MORE_MENU = 'SHOW_MORE_MENU';
export const SHOW_HIDE_MEETING_SHARING = 'SHOW_HIDE_MEETING_SHARING';
export const ENTER_EXIT_FULL_SCREEN = 'ENTER_EXIT_FULL_SCREEN';
export const RESIZE_CANVAS = 'RESIZE_CANVAS';
export const SHOW_PARTICIPANTS_LIST = 'SHOW_PARTICIPANTS_LIST';
export const HIDE_ASN = 'HIDE_ASN';
export const UPDATE_JOINCONFTYPE = 'UPDATE_JOINCONFTYPE';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SET_ERRORCODE = 'SET_ERRORCODE';
export const SET_ATTENDEE_ASN = 'SET_ATTENDEE_ASN';
export const SET_ATTENDEE_HOLD_ON_UNIQ = 'SET_ATTENDEE_HOLD_ON_UNIQ';
export const SET_ATTENDEE_NEW_UNIQ = 'SET_ATTENDEE_NEW_UNIQ';
export const SAVE_WEB_SDK_USER = 'SAVE_WEB_SDK_USER';
export const SET_ATTENDEE_REMOVE_UNIQ = 'SET_ATTENDEE_REMOVE_UNIQ';
export const SET_ATTENDEE_WAITING_ROOM_USER_UNIQ =
  'SET_ATTENDEE_WAITING_ROOM_USER_UNIQ';
export const SET_ATTENDEE_RAISE_HAND_UNIQ = 'SET_ATTENDEE_RAISE_HAND_UNIQ';
export const SWAP_VIDEO_ORDER = 'SWAP_VIDEO_ORDER';
export const RESET_VIDEO_ORDER = 'RESET_VIDEO_ORDER';
export const ADD_FAILOVER_USERS_IN_WR = 'ADD_FAILOVER_USERS_IN_WR';
export const REMOVE_FAILOVER_USERS_IN_WR = 'REMOVE_FAILOVER_USERS_IN_WR';
export const ADD_FAILOVER_USERS_IN_MEETING = 'ADD_FAILOVER_USERS_IN_MEETING';
export const REMOVE_FAILOVER_USERS_IN_MEETING =
  'REMOVE_FAILOVER_USERS_IN_MEETING';
export const SET_CURRENTUSER_ASN = 'SET_CURRENTUSER_ASN';
export const SHARING_TIP = 'SHARING_TIP';
export const TALKING_TIP = 'TALKING_TIP';
export const SHOW_CALLOUT_STATUS = 'SHOW_CALLOUT_STATUS';
export const UPDATE_CALLING_STATUS = 'UPDATE_CALLING_STATUS';
export const SHOW_INVITE_STATUS = 'SHOW_INVITE_STATUS';
export const RESET_DIALOUTERRORCODE = 'RESET_DIALOUTERRORCODE';
export const INVITE_OTHERS = 'INVITE_OTHERS';
export const SHOW_MEETING_LOCK_TIP = 'SHOW_MEETING_LOCK_TIP';
export const UPDATE_CALLOUT_PHONENUMBER = 'UPDATE_CALLOUT_PHONENUMBER';
export const UPDATE_INVITE_PHONENUMBER = 'UPDATE_INVITE_PHONENUMBER';
export const UPDATE_INVITE_NAME = 'UPDATE_INVITE_NAME';
export const ADD_INVITE_SEQ = 'ADD_INVITE_SEQ';
export const DELETE_INVITE_SEQ = 'DELETE_INVITE_SEQ';
export const UPDATE_INVITE_SEQ = 'UPDATE_INVITE_SEQ';
export const COPY_EMAIL = 'COPY_EMAIL';
export const COPY_URL = 'COPY_URL';
export const UPDATE_RENAME__VALUE = 'UPDATE_RENAME__VALUE';
export const SET_PHONENUMBER_COOKIE = 'SET_PHONENUMBER_COOKIE';
export const REMEMBER_PN_CHECK = 'REMEMBER_PN_CHECK';
export const REMEMBER_LOCK_CHECK = 'REMEMBER_LOCK_CHECK';
export const IS_ON_HOLD = 'IS_ON_HOLD';
export const SET_CURRENT_USER_ROLE = 'SET_CURRENT_USER_ROLE';
export const SET_RWC_RESPONSE = 'SET_RWC_RESPONSE';
export const SET_IS_RECONNECTING_XMPP = 'SET_IS_RECONNECTING_XMPP';
export const IS_FAILOVER_TO_SOCKET_CLOSE = 'IS_FAILOVER_TO_SOCKET_CLOSE';
export const SET_IS_NOW_FAILOVER_IN_PROGRESS =
  'SET_IS_NOW_FAILOVER_IN_PROGRESS';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_DLP_POLICY = 'REFRESH_DLP_POLICY';
export const SET_IS_CHAT_DISABLED_BY_DLP = 'DISABLE_CHAT_BY_DLP';
export const SET_ACTIVE_VIDEO_ZOOM_STYLE = 'SET_ACTIVE_VIDEO_ZOOM_STYLE';
export const SET_SUS_ACTIVE_VIDEO_ZOOM_STYLE =
  'SET_SUS_ACTIVE_VIDEO_ZOOM_STYLE';
export const SET_IS_JOIN_VOIP_LOADING = 'SET_IS_JOIN_VOIP_LOADING';
export const LOADING_VIDEO = 'LOADING_VIDEO';
export const RECORD_PRE_HOLD_STATUS = 'RECORD_PRE_HOLD_STATUS';
export const RECORD_PRE_AUDIO_VOIP_STATUS = 'RECORD_PRE_AUDIO_VOIP_STATUS';
export const ME_RAISE_HAND_AS_ATTENDEE = 'ME_RAISE_HAND_AS_ATTENDEE';
export const UPDATE_XMPP_USER_HAND_AUDIO = 'UPDATE_XMPP_USER_HAND_AUDIO';
export const DIALOUT_SUCCESS = 'DIALOUT_SUCCESS';
export const UPDATE_XMPP_USER_LIST = 'UPDATE_XMPP_USER_LIST';
export const SET_CURRENT_USER_IS_MUTE_VIDEO = 'SET_CURRENT_USER_IS_MUTE_VIDEO';
export const IS_XMPP_PROMOTE = 'IS_XMPP_PROMOTE';
// Audio and Video
export const WS_VIDEO_ACTIVE_INDICATION = 'WS_VIDEO_ACTIVE_INDICATION';
export const WS_AUDIO_VOIP_JOIN_CHANNEL_REQ = 'WS_AUDIO_VOIP_JOIN_CHANNEL_REQ';
export const WS_VIDEO_SUBSCRIBE_REQ = 'WS_VIDEO_SUBSCRIBE_REQ';
export const USER_FORBID_MIC = 'USER_FORBID_MIC';
export const USER_FORBID_SHARE = 'USER_FORBID_SHARE';
export const USER_FORBID_CAMERA = 'USER_FORBID_CAMERA';
export const WS_VIDEO_UNSUBSCRIBE_REQ = 'WS_VIDEO_UNSUBSCRIBE_REQ';
export const CHANGE_RESTART_WEBRTC_TIME = 'CHANGE_RESTART_WEBRTC_TIME';
export const AV_SHARING_STATUS_INDICATION = 'AV_SHARING_STATUS_INDICATION';
export const WS_VIDEO_MUTE_VIDEO_REQ = 'WS_VIDEO_MUTE_VIDEO_REQ';
export const WS_VIDEO_MUTE_INDICATION = 'WS_VIDEO_MUTE_INDICATION';
export const WS_VIDEO_SSRC_INDICATION = 'WS_VIDEO_SSRC_INDICATION';
export const SET_CAMERA_IS_TAKEN = 'SET_CAMERA_IS_TAKEN';
export const SET_CAMERA_CAPTURE_LOADING = 'SET_CAMERA_CAPTURE_LOADING';
export const SET_CURRENT_VIDEO_QUALITY = 'SET_CURRENT_VIDEO_QUALITY';
export const SET_CURRENT_RECEIVED_SHARE_QUALITY =
  'SET_CURRENT_RECEIVED_SHARE_QUALITY';
export const INCREASE_RWG_RETRY_COUNT = 'INCREASE_RWG_RETRY_COUNT';
export const UPDATE_SPOTLIGHTED_USER = 'UPDATE_SPOTLIGHTED_USER';
export const WS_AUDIO_SSRC_INDICATION = 'WS_AUDIO_SSRC_INDICATION';
export const SET_CURRENT_USER_ALLOW_TO_TALK = 'SET_CURRENT_USER_ALLOW_TO_TALK';
export const SET_CURRENT_USER_VIDEO_STATE = 'SET_CURRENT_USER_VIDEO_STATE';
export const SET_CURRENT_USER_AUDIO_VIDEO_STATE_BEFORE_ON_HOLD =
  'SET_CURRENT_USER_AUDIO_VIDEO_STATE_BEFORE_ON_HOLD';
export const SET_DISABLE_AUDIO_QOS = 'SET_DISABLE_AUDIO_QOS';
export const SET_DISABLE_VIDEO_QOS = 'SET_DISABLE_VIDEO_QOS';

export const CLEAR_MAIN_JID = 'CLEAR_MAIN_JID';
export const MEETING_RESET = 'MEETING_RESET';
export const MEETING_UI_RESET = 'MEETING_UI_RESET';
export const RESET_TIME = 'RESET_TIME';
export const WS_CONF_ADMIT_ALL_SILENT_USERS_INDICATION =
  'WS_CONF_ADMIT_ALL_SILENT_USERS_INDICATION';
export const SET_MERGE_AUDIO_VISIBLE = 'SET_MERGE_AUDIO_VISIBLE';
export const SET_WEBINAR_TOKEN = 'SET_WEBINAR_TOKEN';
export const SET_WEBINAR_BROADCAST = 'SET_WEBINAR_BROADCAST';
export const SET_WEBINAR_BROADCAST_TIP_VISIBLE =
  'SET_WEBINAR_BROADCAST_TIP_VISIBLE';
export const SET_IS_WAITING_ROOM_LOADING = 'SET_IS_WAITING_ROOM_LOADING';
export const SET_IS_REJOIN_LOADING = 'SET_IS_REJOIN_LOADING';
export const WS_CONF_UPDATE_MEETING_TOPIC_REQ =
  'WS_CONF_UPDATE_MEETING_TOPIC_REQ';
export const SET_MEETING_REGION = 'SET_MEETING_REGION';
export const UPDATE_GATEWAY_INFO_LIST = 'UPDATE_GATEWAY_INFO_LIST';
export const RESET_CURRENT_USER_AUDIO_MUTE_BEFORE_RESET =
  'RESET_CURRENT_USER_AUDIO_MUTE_BEFORE_RESET';
export const SET_SHOW_WAITING_ROOM_NOTIFICATION =
  'SET_SHOW_WAITING_ROOM_NOTIFICATION';
export const WS_CONF_CAN_ADMIT_WHEN_NOHOST_PRESENT_INDICATION =
  'WS_CONF_CAN_ADMIT_WHEN_NOHOST_PRESENT_INDICATION';
export const SET_AV_DISABLED_TIP_VISIBLE = 'SET_AV_DISABLED_TIP_VISIBLE';
export const SET_DRAG_LAYOUT = 'SET_DRAG_LAYOUT';
export const WS_CONF_FOLLOW_HOST_VIDEO_ORDER =
  'WS_CONF_FOLLOW_HOST_VIDEO_ORDER';

export const SET_SHOW_NEW_FEATURE_NOTIFICATION =
  'SET_SHOW_NEW_FEATURE_NOTIFICATION';

export const SET_SHOW_REACTION_ANIMATION_NEW_FEATURE_NOTIFICATION =
  'SET_SHOW_REACTION_ANIMATION_NEW_FEATURE_NOTIFICATION';

export const SET_SHOW_UPDATEBROWSER_NOTIFICATION =
  'SET_SHOW_UPDATEBROWSER_NOTIFICATION';
export const SET_SIZE_SCALE = 'SET_SIZE_SCALE';
export const RESET_INTERPRETERS = 'RESET_INTERPRETERS';

export const SET_ARCHIVE_INFO = 'SET_ARCHIVE_INFO';
export const SET_DNI_INFO = 'SET_DNI_INFO';
export const SET_APP_SIGNAL_INFO = 'SET_APP_SIGNAL_INFO';
export const WS_AVS_ENCRYPT_KEY_INDICATION = 'WS_AVS_ENCRYPT_KEY_INDICATION';

export const SET_SHARE_PRONOUNS_TYPE = 'SET_SHARE_PRONOUNS_TYPE';
export const SET_SHOW_SHARE_PRONOUN_NOTIFICATION =
  'SET_SHOW_SHARE_PRONOUN_NOTIFICATION';
export const SET_USER_PRONOUN = 'SET_USER_PRONOUN';
export const SET_GETTY_CONFIG = 'SET_GETTY_CONFIG';
export const SET_LIVE_STREAM_STATUS = 'SET_LIVE_STREAM_STATUS';
export const ADD_LOADING_NOTIFICATION = 'ADD_LOADING_NOTIFICATION';
export const REMOVE_LOADING_NOTIFICATION = 'REMOVE_LOADING_NOTIFICATION';
export const TOGGLE_WEBGL_LOST_MENU = 'TOGGLE_WEBGL_LOST_MENU';

// not joined list
export const TOGGLE_NOT_JOINED_LIST = 'TOGGLE_NOT_JOINED_LIST';
export const SET_NOT_JOINED_LIST = 'SET_NOT_JOINED_LIST';
export const CHANGE_NOT_JOINED_LIST = 'CHANGE_NOT_JOINED_LIST';

export const UPDATE_ALL_ATTENDEES_ORDER = 'UPDATE_ALL_ATTENDEES_ORDER';
export const UPDATE_ALL_XMPP_ALLOW_TALK_LIST =
  'UPDATE_ALL_XMPP_ALLOW_TALK_LIST';

export const SHOW_RECLAIM_HOST_NOTIFICATION = 'SHOW_RECLAIM_HOST_NOTIFICATION';

export const SET_MERGE_QUALITY = 'SET_MERGE_QUALITY';
// restrict features
export const SET_RESTRICT_FEATURES = 'SET_RESTRICT_FEATURES';
export const TOGGLE_ZR_MULTI_STREAM_VIDEO_USER =
  'TOGGLE_ZR_MULTI_STREAM_VIDEO_USER';

export const SET_NETWORK_CHANGE_COUNTER = 'SET_NETWORK_CHANGE_COUNTER';
export const SET_NETWORK_TYPE = 'SET_NETWORK_TYPE';
export const TOGGLE_VIEW_BTN_FOCUSED = 'TOGGLE_VIEW_BTN_FOCUSED';

export const SET_IS_MINIMIZE_MODE = 'SET_IS_MINIMIZE_MODE';
export const SET_CONSENT_POSITION = 'SET_CONSENT_POSITION';
export const SET_CURRENT_USER_KEEP_PHONE_CONNECTED =
  'SET_CURRENT_USER_KEEP_PHONE_CONNECTED';
export const RENAME_ZR_CHILD_USER_DISPLAYNAME =
  'RENAME_ZR_CHILD_USER_DISPLAYNAME';
export const SET_LOST_CONTEXT_CANVAS_LIST = 'SET_LOST_CONTEXT_CANVAS_LIST';
export const RECORD_FAILOVER_REASON = 'RECORD_FAILOVER_REASON';
export const CLEAR_FAILOVER_DATA = 'CLEAR_FAILOVER_DATA';
export const RECORD_FAILOVER_SUCCESS_POINT = 'RECORD_FAILOVER_SUCCESS_POINT';

export const SET_CURRENT_USER_UID = 'SET_CURRENT_USER_UID';
export const SET_CURRENT_USER_AID = 'SET_CURRENT_USER_AID';
export const SET_PANEL_VIEW_STATE = 'SET_PANEL_VIEW_STATE';

export const SET_AUDIO_BRIDGE_TOKEN = 'SET_AUDIO_BRIDGE_TOKEN';
export const SET_AUDIO_BRIDGE_LOCAL_FLAG = 'SET_AUDIO_BRIDGE_LOCAL_FLAG';
export const SET_IS_ENABLE_PWA_CHAT = 'SET_IS_ENABLE_PWA_CHAT';
export const SET_CONF_READY = 'SET_CONF_READY';
export const SET_AUDIO_SESSION_READY = 'SET_AUDIO_SESSION_READY ';
export const SET_VIDEO_SESSION_READY = 'SET_VIDEO_SESSION_READY ';
export const SET_SHARE_SESSION_READY = 'SET_SHARE_SESSION_READY ';

export const SET_JOIN_MEETING_RES = 'SET_JOIN_MEETING_RES';
export const SET_MEETING_LCP = 'SET_MEETING_LCP';
export const SET_ORIGINAL_MEETING_STATUS = 'SET_ORIGINAL_MEETING_STATUS';
export const SET_IS_TRANSFER_MEETING = 'SET_IS_TRANSFER_MEETING';
export const SET_GIPHY_RATING = 'SET_GIPHY_RATING';
export const SET_IS_ADDED_TO_CAMERA_CONTROL_GROUP =
  'SET_IS_ADDED_TO_CAMERA_CONTROL_GROUP';
export const SET_GIPHY_ON = 'SET_GIPHY_ON';
export const SET_IS_PIP_MODE = 'SET_IS_PIP_MODE';

export const HANDLE_MEETING_CONFIG_INDICATION =
  'HANDLE_MEETING_CONFIG_INDICATION';

export const UPDATE_ATTENDEE_IN_PRACTICE_SESSION =
  'UPDATE_ATTENDEE_IN_PRACTICE_SESSION';

export const SET_ATTENDEE_CHAT_DISABLE_HYPER_LINKS =
  'SET_ATTENDEE_CHAT_DISABLE_HYPER_LINKS';

export const SET_SHARE_FILE_CONFIG = 'SET_SHARE_FILE_CONFIG';

export const SET_NEW_WAITING_ROOM_FLOW_LOADING =
  'SET_NEW_WAITING_ROOM_FLOW_LOADING';
export const TOGGLE_IS_UI_LAYOUT_CLEANED = 'TOGGLE_IS_UI_LAYOUT_CLEANED';
