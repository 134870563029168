import { PING_RWC_DOMAIN_EMPTY } from '../../../constants/ExeternalControllerErrorTypes';
import { isMTRAndroid } from '../../../global';
import {
  CONTROL_MODE_ZOOM_UI_ACTION_TYPE,
  JOIN_FLOW_ERROR_CODE,
  RWG_CONF_END_ERROR_CODE,
} from '../../enum';
import { localReadyStatesObserver } from './local-ready-states-observer';

export function localEvtHandler(evt, payload, store, adaptor) {
  switch (evt) {
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.START_VIDEO:
      adaptor.notifyControllerVideoUnmuted();
      break;
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.STOP_VIDEO:
      adaptor.notifyControllerVideoMuted();
      break;
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.UNMUTE_AUDIO:
      adaptor.notifyControllerAudioUnmuted();
      break;
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.MUTE_AUDIO:
      adaptor.notifyControllerAudioMuted();
      break;
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.CONNECTING: {
      adaptor.notifyControllerStartJoining();
      break;
    }
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.FAILOVER: {
      localReadyStatesObserver.reset();
      if (!isMTRAndroid()) {
        adaptor.notifyControllerMeetingHoldOn();
      }
      break;
    }
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.LEAVE_MEETING: {
      adaptor.notifyControllerWarningInfo(
        RWG_CONF_END_ERROR_CODE.LEAVEREASON_USER_GESTURE,
      );
      adaptor.notifyControllerMeetingEnd();
      break;
    }
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.PING_RWC: {
      const { reason } = payload;
      adaptor.notifyControllerJoinFailure(
        reason === PING_RWC_DOMAIN_EMPTY
          ? JOIN_FLOW_ERROR_CODE.PING_RWC_DOMAIN_EMPTY
          : JOIN_FLOW_ERROR_CODE.PING_RWC_FAIL,
      );
      break;
    }
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.REFRESH_TOKEN: {
      const { isReconnect } = payload;
      if (isReconnect) {
        adaptor.notifyControllerJoinFailure(
          JOIN_FLOW_ERROR_CODE.REFRESH_TOKEN_FAIL,
        );
      } else {
        adaptor.notifyControllerWarningInfo(
          JOIN_FLOW_ERROR_CODE.REFRESH_TOKEN_FAIL,
        );
      }
      break;
    }
    default:
      break;
  }
}
