import { useEffect, useRef } from 'react';

export const useClickOutSide2 = (buttonRef, excludeQuerySelector, callback) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      const tooltip = document.querySelector(excludeQuerySelector);
      if (
        !buttonRef.current ||
        buttonRef.current.contains(event.target) ||
        !tooltip ||
        tooltip.contains(event.target)
      ) {
        return;
      }
      callback();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [buttonRef, excludeQuerySelector]);
};

const defaultEvents = ['mousedown', 'touchstart'];

export const useClickAway = (ref, onClickAway, events = defaultEvents) => {
  const savedCallback = useRef(onClickAway);
  useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);
  useEffect(() => {
    const handler = (event) => {
      const { current: el } = ref;
      if (el && !el.contains(event.target)) {
        savedCallback.current(event);
      }
    };

    events.forEach((eventName) => {
      document.addEventListener(eventName, handler);
    });
    return () => {
      events.forEach((eventName) => {
        document.removeEventListener(eventName, handler);
      });
    };
  }, [events, ref]);
};

export default useClickAway;
