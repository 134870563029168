import { updateQa, updateQaOfflineAnswer } from './q-a-action';
import { JOIN_MEETING_POLICY } from '../../../global/constant';
import _ from 'lodash';
import { generateCurrentDateString } from '../utils';
import { isSimulive } from '../../../global/service';
import { isHost } from '../../../global/service/user-types';

export function updateQuestionThunk(data) {
  return (dispatch, getState) => {
    const {
      meeting: {
        jid,
        currentUser: { userRole },
        xmppUserList: { host, panelists },
        restrictFeatures,
      },
      attendeesList: { attendeesList },
      video: {
        simuLive: { autoreplyEnabled, autoreplyMsg },
      },
      QA: { questionList },
    } = getState();
    if (restrictFeatures[JOIN_MEETING_POLICY.QA]) return;
    const { command, id } = data?.target || {};
    const hasQuestion = questionList.some((question) => question.id === id);
    dispatch(
      updateQa({
        ...data,
        jid,
        userRole,
        xmppHostAndPanelists: host.concat(panelists),
        attendeesList,
        state: getState(),
      }),
    );

    if (
      !hasQuestion &&
      isSimulive() &&
      autoreplyEnabled &&
      (command === 'question' || command === 'publicview')
    ) {
      setTimeout(
        () => {
          dispatch(
            updateQaOfflineAnswer({
              targetQid: id,
              offlineAnswer: {
                id: _.uniqueId(),
                jid: '',
                name: 'Host',
                private: false,
                status: -1,
                text: autoreplyMsg,
                time: generateCurrentDateString(),
              },
            }),
          );
        },
        isHost(userRole) ? 0 : 1000,
      );
    }
  };
}
