import {
  CHAT_PRIVILEDGE_ALL,
  CHAT_PRIVILEDGE_ALL_PANELIST,
  CHAT_PRIVILEDGE_HOST,
  CHAT_PRIVILEDGE_NOONE,
  PANELIST_CHAT_PRIVILEGE,
} from '../../constants/Constants';
import {
  everyoneText,
  hostAndPanelistsText,
  hostsText,
  meetingHostOnlyText,
  noOneText,
  waitingRoomParticipantsText,
} from './resource';
import { YOU as youTxt } from '../../global/resource';

export const CHAT_MSG_TYPE_TO_ALL = 0; // chat to all
export const CHAT_MSG_TYPE_TO_PANELIST = 1; // chat to all panelists
export const CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST = 2; // chat to individual attendee and cc panelists
export const CHAT_MSG_TYPE_TO_INDIVIDUAL = 3; // chat to individual
export const CHAT_MSG_TYPE_TO_SILENT_MODE_USERS = 4; // chat to waiting room attendee

export const CHAT_MSG_TYPE_TO_INDIVIDUAL_SILENT_MODE_USER = 5; // chat to individual waiting room particpant
export const CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS = 6; // particpant in WR chat to host/cohost

export const CHAT_RECEIVER_TEXT_MAP = {
  [CHAT_MSG_TYPE_TO_ALL]: everyoneText,
  [CHAT_MSG_TYPE_TO_PANELIST]: hostAndPanelistsText,
  [CHAT_MSG_TYPE_TO_SILENT_MODE_USERS]: waitingRoomParticipantsText,
  [CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS]: hostsText,
  me: youTxt,
};

const chatReceiverMap = new Map();
const builtInChatTypeIterator = [
  [CHAT_MSG_TYPE_TO_ALL, CHAT_MSG_TYPE_TO_ALL],
  [CHAT_MSG_TYPE_TO_PANELIST, CHAT_MSG_TYPE_TO_PANELIST],
  [
    CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
    CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
  ],
  [CHAT_MSG_TYPE_TO_INDIVIDUAL, CHAT_MSG_TYPE_TO_INDIVIDUAL],
  [CHAT_MSG_TYPE_TO_SILENT_MODE_USERS, CHAT_MSG_TYPE_TO_SILENT_MODE_USERS],
  [
    CHAT_MSG_TYPE_TO_INDIVIDUAL_SILENT_MODE_USER,
    CHAT_MSG_TYPE_TO_INDIVIDUAL_SILENT_MODE_USER,
  ],
  [
    CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS,
    CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS,
  ],
];
builtInChatTypeIterator.forEach((entry) => {
  chatReceiverMap.set(entry[0], entry[1]);
});
export const BUILT_IN_CHAT_RECEIVER_MAP = chatReceiverMap;

export const CHAT_CMD_NONE = 0;
export const CHAT_CMD_DELETE = 1;
export const CHAT_CMD_MODIFY = 2;

export const CHAT_DELETED_OR_MODIFIED_BY_NONE = 0;
export const CHAT_DELETED_OR_MODIFIED_BY_DLP = 1;

export const CHAT_DISABLED_BY_NONE = 0;
export const CHAT_DISABLED_BY_DLP = 1;

export const DLPCheckType = {
  keyword: 1,
  regex: 2,
};

export const DLPActionType = {
  confirm: 2,
  block: 3,
};

export const chatPriviledgeToText = {
  [CHAT_PRIVILEDGE_ALL]: everyoneText,
  [CHAT_PRIVILEDGE_ALL_PANELIST]: hostAndPanelistsText,
  [CHAT_PRIVILEDGE_HOST]: meetingHostOnlyText,
  [CHAT_PRIVILEDGE_NOONE]: noOneText,
};
export const panelistChatPriviledgeToText = {
  [PANELIST_CHAT_PRIVILEGE.ALL_PANELIST]: hostAndPanelistsText,
  [PANELIST_CHAT_PRIVILEGE.EVERYONE]: everyoneText,
};
export const chatPriviledgeToReceiverId = {
  [CHAT_PRIVILEDGE_ALL]: CHAT_MSG_TYPE_TO_ALL,
  [CHAT_PRIVILEDGE_ALL_PANELIST]: CHAT_MSG_TYPE_TO_PANELIST,
};

export const panelistChatPriviledgeToReceiverId = {
  [PANELIST_CHAT_PRIVILEGE.ALL_PANELIST]: CHAT_MSG_TYPE_TO_PANELIST,
  [PANELIST_CHAT_PRIVILEGE.EVERYONE]: CHAT_MSG_TYPE_TO_ALL,
};

export const CHAT_FILE_UPLOAD_STATUS = {
  INIT: 1,
  PENDING: 2,
  SUCCESS: 3,
  COMPLETE: 4,
};

export const CHAT_FILE_DOWNLOAD_STATUS = {
  DOWNLOADING: 1,
  SUCCESS: 2,
  CANCELED: 3,
  ERROR: 4,
};

export const CHAT_FILE_CHUNK_SIZE = 20 * 1024 * 1024;
export const CHAT_FILE_ENCODE_BLOCK_SIZE = 32 * 1024;
export const CHAT_FILE_DECODE_BLOCK_SIZE = 32 * 1024 + 35;
export const CHAT_FILE_MAX_SIZE = 2 * 1024 * 1024 * 1024;
export const CHAT_FILE_READER_CHUNK_SIZE = 1024 * 1024 * 1024;

export const CHAT_FILE_IGNORE_LIST = [];
export const CHAT_FILE_BLACK_LIST = ['exe'];

export const HTML5_CAPS_OPTION_SUPPORT_FILE_TRANSFER = 0x00010000;

export const CHAT_FILE_UPLOAD_ERROR_TYPE = {
  NORMAL: 1,
  FILE_REMOVED: 2,
};

export const CHAT_FILE_3RD_PARTY_IFRAME_ID = '3rd-party-file-iframe';

export const CHAT_FILE_TYPE = {
  LOCAL: 0,
  THIRD_PARTY: 1,
};

export const CHAT_FILE_3RD_PARTY_MESSAGE_TYPE = 'ZOOM_CHOOSER_RESULT';

export const CHAT_MESSAGE_OPTIONS_CLASS = 'chat-message__options';
export const CHAT_MESSAGE_CONTENT_ID = 'chat-message-content';
export const CHAT_MESSAGE_OPTION_ID = 'chat-message-option';

export const thirdPartyFileEncryptedKeys = [
  'fileName',
  'fileSize',
  'shareType',
  'previewPath',
  'previewUrl',
];
