import { VoipMuteSVG } from '../../../../svg/icons/footer';
import React, { useState } from 'react';
import { useMediaSDKEvent } from '../../../../global/hooks/use-media-sdk-event';
import { AUDIO_LEVEL_INDICATOR } from '../../../../constants/AVNotifyAPPTypes';

const VoipActiveIcon = () => {
  const [audioLevel, setAudioLevel] = useState(0);
  const indicatorHeight = (audioLevel / 9) * 15;
  useMediaSDKEvent(AUDIO_LEVEL_INDICATOR, (data) => {
    setAudioLevel(data.value);
  });
  return (
    <div className="audio-voip-active-icon">
      <VoipMuteSVG />
      <div className="voip-icon-inner">
        <div
          className="audio-level-indicator"
          style={{ height: indicatorHeight }}
        />
      </div>
    </div>
  );
};

export default VoipActiveIcon;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/features/audio/components/voip-active-icon/voip-active-icon.scss';
