import React from 'react';
import PropTypes from 'prop-types';
import {
  ELEMENT_LI_BREAK,
  ELEMENT_PARAGRAPH_BREAK,
} from '../../global/constant';
import { Link2 } from './link2';
import reactStringReplace from '../../global/resct-string-replace';
/* eslint-disable react/prop-types */

export const Paragraph = ({ textParts, ...restProps }) => {
  const ret = textParts.filter((v) => v);
  if (ret.length === 0) {
    return null;
  }

  return (
    <>
      {textParts
        .filter((v) => v)
        .map((v, index) => (
          <p
            key={v}
            role="tabpanel"
            className="disclaimer__body-part"
            style={index === textParts.length - 1 ? { padding: 0 } : {}}
            {...restProps}
          >
            {v}
          </p>
        ))}
    </>
  );
};

Paragraph.propTypes = {
  textParts: PropTypes.array.isRequired,
};

function UnorderedList({ list }) {
  return (
    <ul>
      {list.map((li, index) => {
        return <li key={index}>{li}</li>;
      })}
    </ul>
  );
}

UnorderedList.propTypes = {
  list: PropTypes.shape({
    map: PropTypes.func,
  }),
};

export const ParagraphWithUl = ({ textPartsWithUl, textParts }) => {
  const [p, ul] = textPartsWithUl.split(ELEMENT_PARAGRAPH_BREAK);
  const lis = ul.split(ELEMENT_LI_BREAK);
  const renderList = [p, lis, ...textParts];
  return (
    <>
      {renderList
        .filter((v) => v)
        .map((v, index) => (
          <p
            key={v}
            className="disclaimer__body-part"
            style={index === renderList.length - 1 ? { padding: 0 } : {}}
          >
            {Array.isArray(v) ? <UnorderedList list={v} /> : v}
          </p>
        ))}
    </>
  );
};
ParagraphWithUl.defaultProps = {
  textParts: [],
};
ParagraphWithUl.propTypes = {
  textParts: PropTypes.array,
  textPartsWithUl: PropTypes.string,
};

export const makeUrlText = (url, text) => `<a href="${url}">${text}</a>`;
export const ParagraphWithUrlParser = ({ textParts, url }) => {
  const regex = /<a[^>]*>.*?<\/a>/g;
  const sectionText = []
    .concat(textParts)
    .flatMap((v) => v)
    .flatMap((v) => v?.split?.('\n') || v)
    .flatMap((v) => v?.split?.('<br/>') || v)
    .flatMap((v) => v?.split?.('<br><br>') || v)
    .filter((v) => v)
    .map((v) => {
      try {
        const matches = v.match(regex);
        if (matches?.length === 1 && matches[0]) {
          const parser = new DOMParser();
          const a = parser
            .parseFromString(matches[0], 'text/html')
            ?.querySelector('a');
          return reactStringReplace(v, matches[0], () => (
            <Link2 link={url || a.href} text={a.innerText} />
          ));
        }
        return v;
      } catch (e) {
        return v;
      }
    });
  return <Paragraph textParts={sectionText} />;
};
