import { createActions } from 'redux-actions';

const SET_REACTION_PICKER_VISIBLE = 'SET_REACTION_PICKER_VISIBLE';

const SET_EMOJI_PICKER_VISIBLE = 'SET_EMOJI_PICKER_VISIBLE';

const UPDATE_RECEIVED_REACTIONS = 'UPDATE_RECEIVED_REACTIONS';
const UPDATE_EXPIRED_REACTIONS = 'UPDATE_EXPIRED_REACTIONS';

const SET_ALL_EMOJI_SET_RESOURCE_STATUS = 'SET_ALL_EMOJI_SET_RESOURCE_STATUS';
const SET_IS_ALL_EMOJI_SET_REQUIRED = 'SET_IS_ALL_EMOJI_SET_REQUIRED';

export const SET_ALL_EMOJI_SET = 'SET_ALL_EMOJI_SET';
export const SET_ALL_EMOJI_META = 'SET_ALL_EMOJI_META';
const SET_ALL_EMOJI_CATEGORIES = 'SET_ALL_EMOJI_CATEGORIES';
const SET_FREQUENTLY_USED_EMOJIS = 'SET_FREQUENTLY_USED_EMOJIS';
const SET_ALL_EMOJI_DOWNLOAD_PROGRESS = 'SET_ALL_EMOJI_DOWNLOAD_PROGRESS';
const SET_EMOJI_PICKER_TYPE = 'SET_EMOJI_PICKER_TYPE';
const ADD_BULLETS = 'ADD_BULLETS';
const CLEAR_BULLETS = 'CLEAR_BULLETS';

const SET_IS_WEBINAR_REACTION_ALLOWED = 'SET_IS_WEBINAR_REACTION_ALLOWED';

const SET_WEBINAR_REACTION_DISPLAY_MODE = 'SET_WEBINAR_REACTION_DISPLAY_MODE';
const SET_HAS_SENT_REACTION_IN_HIDDEN_MODE =
  'SET_HAS_SENT_REACTION_IN_HIDDEN_MODE';

const SET_WEBINAR_REACTION_DISPLAY_MODE_DROPDOWN_VISIBLE =
  'SET_WEBINAR_REACTION_DISPLAY_MODE_DROPDOWN_VISIBLE';

const SET_REACTIONS_RESTRICTION = 'SET_REACTIONS_RESTRICTION';

const SET_IS_SUPPORT_MEETING_ANIMATED_REACTION =
  'SET_IS_SUPPORT_MEETING_ANIMATED_REACTION';

export const {
  setReactionPickerVisible,
  setEmojiPickerVisible,
  updateReceivedReactions,
  updateExpiredReactions,
  setAllEmojiSetResourceStatus,
  setIsAllEmojiSetRequired,
  setAllEmojiSet,
  setAllEmojiMeta,
  setAllEmojiCategories,
  setFrequentlyUsedEmojis,
  setAllEmojiDownloadProgress,
  setEmojiPickerType,
  addBullets,
  clearBullets,
  setIsWebinarReactionAllowed,
  setWebinarReactionDisplayMode,
  setHasSentReactionInHiddenMode,
  setWebinarReactionDisplayModeDropdownVisible,
  setReactionsRestriction,
  setIsSupportMeetingAnimatedReaction,
} = createActions({
  [SET_REACTION_PICKER_VISIBLE]: (payload) => payload,
  [SET_EMOJI_PICKER_VISIBLE]: (payload) => payload,
  [UPDATE_RECEIVED_REACTIONS]: (payload) => payload,
  [UPDATE_EXPIRED_REACTIONS]: (payload) => payload,
  [SET_ALL_EMOJI_SET_RESOURCE_STATUS]: (payload) => payload,
  [SET_IS_ALL_EMOJI_SET_REQUIRED]: (payload) => payload,
  [SET_ALL_EMOJI_SET]: (payload) => payload,
  [SET_ALL_EMOJI_META]: (payload) => payload,
  [SET_ALL_EMOJI_CATEGORIES]: (payload) => payload,
  [SET_FREQUENTLY_USED_EMOJIS]: (payload) => payload,
  [SET_ALL_EMOJI_DOWNLOAD_PROGRESS]: (payload) => payload,
  [SET_EMOJI_PICKER_TYPE]: (payload) => payload,
  [ADD_BULLETS]: (payload) => payload,
  [CLEAR_BULLETS]: (payload) => payload,
  [SET_IS_WEBINAR_REACTION_ALLOWED]: (payload) => payload,
  [SET_WEBINAR_REACTION_DISPLAY_MODE]: (payload) => payload,
  [SET_HAS_SENT_REACTION_IN_HIDDEN_MODE]: (payload) => payload,
  [SET_WEBINAR_REACTION_DISPLAY_MODE_DROPDOWN_VISIBLE]: (payload) => payload,
  [SET_REACTIONS_RESTRICTION]: (payload) => payload,
  [SET_IS_SUPPORT_MEETING_ANIMATED_REACTION]: (payload) => payload,
});
