import { iText } from '../../util';
import { setWebglLostMenuDisable } from '../../../reducers/MeetingUIReducer';
import { storeType } from '../../easy-store';
import AliveToast from './alive-toast';
import { replaceCanvasWhenWebglContextLost } from '../../op-feature-option';

let timer = null;
const webglLostName = 'webgl-context-lost';

const clearTimer = () => {
  if (timer) {
    clearInterval(timer);
    timer = null;
  }
};

export const ENABLE_WEBGL_LOST_NOTIFICATION_TEXT = iText(
  'Enable auto-refresh if video is unavailable',
  'apac.wc_video_webgl_context_lost_tip_enable',
);

export const hideWebglLostNotification = () => {
  clearTimer();
  AliveToast.close(webglLostName);
};

export const toggleWebglContextLostMenuEnable = (disable) => {
  return (dispatch) => {
    if (disable && timer) {
      hideWebglLostNotification();
    }
    easyStore.easySet('webglLostSwitch', disable, storeType.localStorage);
    dispatch(setWebglLostMenuDisable(disable));
  };
};

export const initWebglContextLostMenu = () => {
  return (dispatch) => {
    const visible = easyStore.easyGet('webglLostSwitch');
    dispatch(setWebglLostMenuDisable(!!visible));
  };
};

export const showWebglLostNotification = () => {
  return (_dispatch, getState) => {
    const {
      meetingUI: { isWebglLostMenuDisable },
    } = getState();
    if (timer || isWebglLostMenuDisable || replaceCanvasWhenWebglContextLost)
      return;
    const duration = 1;
    timer = setTimeout(() => {
      window.location.reload();
      clearTimer();
    }, duration * 1000);
    AliveToast.uniqueToast({
      message: iText(
        `Please wait, your video experience will be refreshed in ${duration}s...`,
        'apac.wc_video_webgl_context_lost_tip',
        duration,
      ),
      showClose: false,
      aliveTime: 0,
      name: webglLostName,
      usePrevious: true,
      onAfterClose: clearTimer,
    });
  };
};
