import { DeviceEntity } from '@zoom/zdt_events/dist/entities/meetings/Device';
import { currentVersion } from '../../../../version';
import meetingConfig from 'meetingConfig';
import {
  getBrowserVersion,
  getOSName,
  getOSVersion,
} from '../../utils/browser-detect';

const device = new DeviceEntity();

const getBrowserType = () => {
  // Get the browser name and version
  const userAgent = navigator.userAgent;
  let browserName, browserVersion;

  // Chrome
  if (/Chrome\/(\d+)/.test(userAgent)) {
    browserName = 'Chrome';
    browserVersion = userAgent.match(/Chrome\/(\d+)/)[1];
  }
  // Firefox
  else if (/Firefox\/(\d+)/.test(userAgent)) {
    browserName = 'Firefox';
    browserVersion = userAgent.match(/Firefox\/(\d+)/)[1];
  }
  // Safari
  else if (/Safari\/(\d+)/.test(userAgent)) {
    browserName = 'Safari';
    browserVersion = userAgent.match(/Version\/(\d+)/)[1];
  }
  // Edge
  else if (/Edg\/(\d+)/.test(userAgent)) {
    browserName = 'Microsoft Edge';
    browserVersion = userAgent.match(/Edg\/(\d+)/)[1];
  }
  // Other browsers
  else {
    browserName = 'other browser';
    browserVersion = 'N/A';
  }

  if (browserName) {
    return `${browserName} V${browserVersion}`;
  }
};

const getGpuInfo = () => {
  return JsMediaSDK_Instance.util?.getGpuInfo?.();
};

const getCpuInfo = () => {
  // Get the CPU information
  return navigator.hardwareConcurrency
    ? `${navigator.hardwareConcurrency} cores`
    : 'Unknown';
};

const getAvailableRAM = () => {
  return navigator.deviceMemory || 0;
};

const getResolution = () => {
  return window.screen.width + 'x' + window.screen.height;
};

const getNetworkType = () => {
  return navigator.connection ? navigator.connection.effectiveType : 'Unknown';
};

const getCurrentLanguage = () => {
  return meetingConfig.lang;
};

const getAppVersion = () => {
  const clientType = 'webclient';
  return `${currentVersion.version} (${clientType})`;
};

const getRegion = () => {
  return meetingConfig.userRegion;
};

export const makeDeviceEntity = () => {
  device.deviceManufacturer = getBrowserType();
  device.appVersion = getAppVersion();
  device.gpuModel = getGpuInfo();
  device.cpuType = getCpuInfo();
  device.totalRamGb = getAvailableRAM();
  device.resolution = getResolution();
  device.networkType = getNetworkType();
  device.languageSetting = getCurrentLanguage();
  device.deviceCountry = getRegion();
  device.deviceModel = getBrowserVersion();
  device.osName = getOSName();
  device.osVersion = getOSVersion();
  return device;
};
