import { createSelector } from 'reselect';
import { isSimulive, isNormalVideoMode } from '../../../global/service';
import { isLowEndChromeBook } from '../../../global/util';

const currentSettingsSelector = ({
  settings: {
    settingsDialog: { currentSettingsTab },
  },
}) => currentSettingsTab;

const currentUserSelector = ({ meeting: { currentUser } }) => currentUser;

const isSupportMeetingAnimatedReactionSelector = ({
  reaction: { isSupportMeetingAnimatedReaction },
}) => isSupportMeetingAnimatedReaction;
const showMeetingAnimatedReactionSelector = ({
  settings: { showMeetingAnimatedReaction },
}) => showMeetingAnimatedReaction;

const currentSettingsTabSelector = createSelector(
  [currentSettingsSelector, currentUserSelector],
  (currentSettingsTab) => {
    if (currentSettingsTab) {
      return currentSettingsTab;
    }

    if (isSimulive()) {
      return 'about';
    }

    return 'general';
  },
);

const shouldShowEnableMeetingAnimatedReactionSettingSelector = createSelector(
  [isSupportMeetingAnimatedReactionSelector],
  (isSupportMeetingAnimatedReaction) => {
    return (
      isSupportMeetingAnimatedReaction &&
      isNormalVideoMode() &&
      !isLowEndChromeBook()
    );
  },
);

const isMeetingAnimatedEnabledSelector = createSelector(
  [
    shouldShowEnableMeetingAnimatedReactionSettingSelector,
    showMeetingAnimatedReactionSelector,
  ],
  (isSupported, isEnabled) => {
    return isSupported && isEnabled;
  },
);

export {
  currentSettingsTabSelector,
  shouldShowEnableMeetingAnimatedReactionSettingSelector,
  isMeetingAnimatedEnabledSelector,
};
