import { EventSender } from '@zoom/zdt_events';
import { MeetingInteractV2Event } from '@zoom/zdt_events/dist/events/meetings/MeetingInteractV2';
import { checkRole } from '../service/user-types';
import { errorLog } from '../web-client-logger';
import { getProductType, getReduxPromise } from './telemetry-utils';
import { entitiesMap, theNecessaryEntities } from './entity';
import meetingConfig from 'meetingConfig';

const makeEventData = ({
  eventSource,
  featureName,
  eventLocation = null,
  eventName,
  eventContext = null,
  errorReason = null,
  state,
  ...rest
}) => {
  const eventObject = new MeetingInteractV2Event();
  eventObject.productType = getProductType();
  eventObject.userRole = checkRole(state.meeting?.currentUser);
  eventObject.eventSource = eventSource;
  eventObject.featureName = featureName;
  eventObject.eventType = 'clicked';
  eventObject.eventLocation = eventLocation;
  eventObject.eventName = eventName;
  eventObject.eventContext = eventContext;
  eventObject.errorReason = errorReason;
  Object.keys(rest)
    .filter((v) => !!entitiesMap[v])
    .concat(theNecessaryEntities)
    .forEach((v) => {
      eventObject[v] = entitiesMap[v].maker(state);
      eventObject[v]?.err?.forEach?.((v) => errorLog(`telemetry: ${v}`));
    });
  return eventObject;
};
/**
 * @param {{chatEntity: boolean, meetingPreferencesEntity: boolean, featureName: string, eventLocation: string, meetingSettingsEntity: boolean, eventSource: string, eventName: string, breakoutRoomEntity: boolean}} props same as makeEventData.
 */
export const sendTelemetryEvent = (props) => {
  const toggle = meetingConfig.collectionOption?.toggle;
  const url =
    CLIENT_ENV === 'development'
      ? 'https://us-zoom-prod1.mini.snplow.net'
      : meetingConfig.collectionOption?.collectionUrl;
  return getReduxPromise()
    .then(({ getState }) => {
      if (typeof props !== 'object') {
        return Promise.reject('bad parameters');
      } else {
        Object.assign(props, { state: getState() });
        const eventData = makeEventData(props);
        /**
         * url
         * local:http://localhost:9093
         * MINI: https://us-zoom-prod1.mini.snplow.net default
         * PROD: https://evt-us.ds.corp.zoom.us
         * Qa: https://evt-us.qa.ds.corp.zoom.us
         */
        return new EventSender(eventData, {
          url,
          name: 'PWA-Client',
        }).sendEvent(toggle);
      }
    })
    .catch(errorLog);
};
