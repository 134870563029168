import {
  CONTROL_MODE_CONNECTORS_ENUM,
  CONTROL_MODE_ZOOM_MSG_TYPE,
  CONTROL_MODE_MEETING_STATUS,
  CONTROL_MODE_COMMON_ERROR_TYPE,
  CONTROL_MODE_CLASSNAME_ENUM,
} from '../../enum';
import { TESLA_ERROR_CODE } from './enum';
import { mediaSDKMsgHandler } from './media-sdk-msg-handler';
import { rwgMsgHandler, rosterAllInited } from './rwg-msg-handler';
import { xmppMsgHandler } from './xmpp-msg-handler';
import { localEvtHandler } from './local-evt-handler';
import { externalEvtHandler } from './external-evt-handler';
import { localReadyStatesObserver } from './local-ready-states-observer';
import { coOrHostSelector, isJoinAudio, isTeslaMode } from '../../../global';
import { participantsMap } from './participants-manager';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';

const delayCloseMsftApplicationTime = 5000;

const teslaAdaptor = ({ store }) => {
  return {
    shouldLoad() {
      return isTeslaMode();
    },
    init() {
      /* eslint-disable-next-line no-console */
      console.info(`${this.getVendorId()} adaptor initiated`);
      externalEvtHandler(store, this);
      localReadyStatesObserver.onReady(
        this.notifyControllerJoinSuccess.bind(this),
      );
      rosterAllInited.setAllRosterReceivedCallback(
        this.notifyControllerAllRosterReceived.bind(this),
      );
      this.initContainerStyle();
    },
    initContainerStyle() {
      const rootContainer = document.getElementById('root');
      if (rootContainer) {
        rootContainer.classList.add(CONTROL_MODE_CLASSNAME_ENUM.TESLA);
      }
      document.body.classList.add(CONTROL_MODE_CLASSNAME_ENUM.TESLA_BODY);
    },
    handleZoomMsg(msgType, evt, payload) {
      switch (msgType) {
        case CONTROL_MODE_ZOOM_MSG_TYPE.UI: {
          localEvtHandler(evt, payload, store, this);
          break;
        }
        case CONTROL_MODE_ZOOM_MSG_TYPE.MEDIA_SDK: {
          mediaSDKMsgHandler(evt, payload, store, this);
          break;
        }
        case CONTROL_MODE_ZOOM_MSG_TYPE.RWG: {
          rwgMsgHandler(evt, payload, store, this);
          break;
        }
        case CONTROL_MODE_ZOOM_MSG_TYPE.XMPP: {
          xmppMsgHandler(evt, payload, store, this);
          break;
        }
        default: {
          break;
        }
      }
    },
    getVendorId() {
      return CONTROL_MODE_CONNECTORS_ENUM.TESLA;
    },
    getCameraId() {
      // return controller.getHostState().videoDeviceId;
    },
    getSpeakerId() {
      // return controller.getHostState().outputAudioDeviceId;
    },
    getMicId() {
      // return controller.getHostState().inputAudioDeviceId;
    },
    notifyControllerAudioUnmuted() {
      this.notifyController({
        method: 'call_status',
        params: {
          audio_muted: false,
        },
      });
    },
    notifyControllerAudioMuted() {
      this.notifyController({
        method: 'call_status',
        params: {
          audio_muted: true,
        },
      });
    },
    notifyControllerAudioCanUnMuted({
      audioConnectedChangeTo,
      coOrHostRoleChangeTo,
      bCanUnmuteAudioChangeTo,
      bAllowTalkChangeTo,
      bHoldChangeTo,
    }) {
      const { getState } = store;
      const state = getState();
      const {
        meeting: { bCanUnmute, currentUser },
        meetingUI: { isOnHold },
      } = state;
      const coOrHost = coOrHostSelector(state);
      const { isAllowTalk, userRole } = currentUser;
      const isViewOnlyUser = isViewOnly(userRole);
      const isAudioConnected =
        audioConnectedChangeTo !== undefined
          ? audioConnectedChangeTo
          : isJoinAudio(currentUser);
      const isCoOrHost =
        coOrHostRoleChangeTo !== undefined ? coOrHostRoleChangeTo : coOrHost;
      const isBeCanUnmuteAudio =
        bCanUnmuteAudioChangeTo !== undefined
          ? bCanUnmuteAudioChangeTo
          : bCanUnmute;
      const isWebinarAttendeeViewOnly =
        isWebinar() &&
        isViewOnlyUser &&
        !(bAllowTalkChangeTo !== undefined ? bAllowTalkChangeTo : isAllowTalk);
      const isInWaingRoom =
        bHoldChangeTo !== undefined ? bHoldChangeTo : isOnHold;
      const bool =
        isAudioConnected &&
        (isCoOrHost || isBeCanUnmuteAudio) &&
        !isWebinarAttendeeViewOnly &&
        !isInWaingRoom;
      this.notifyController({
        method: 'call_status',
        params: {
          can_unmute_audio: bool,
        },
      });
    },
    notifyControllerVideoUnmuted() {
      this.notifyController({
        method: 'call_status',
        params: {
          video_off: false,
        },
      });
    },
    notifyControllerVideoMuted() {
      this.notifyController({
        method: 'call_status',
        params: {
          video_off: true,
        },
      });
    },
    notifyControllerVideoCanUnMuted({
      videoEncodeStatusChangeTo,
      coOrHostRoleChangeTo,
      bCanUnmuteVideoChangeTo,
      bHoldChangeTo,
    }) {
      const { getState } = store;
      const state = getState();
      const {
        meeting: { bCanUnmuteVideo, currentUser },
        socketStatus: { initVideoEncodeStatus },
        meetingUI: { isOnHold },
      } = state;
      const coOrHost = coOrHostSelector(state);
      const { userRole } = currentUser;
      const isViewOnlyUser = isViewOnly(userRole);
      const isCoOrHost =
        coOrHostRoleChangeTo !== undefined ? coOrHostRoleChangeTo : coOrHost;
      const isBeCanUnmuteVideo =
        bCanUnmuteVideoChangeTo !== undefined
          ? bCanUnmuteVideoChangeTo
          : bCanUnmuteVideo;
      const isWebinarAttendeeViewOnly = isWebinar() && isViewOnlyUser;
      const isVideoEncodeSuccess =
        videoEncodeStatusChangeTo !== undefined
          ? videoEncodeStatusChangeTo
          : initVideoEncodeStatus;
      const isInWaingRoom =
        bHoldChangeTo !== undefined ? bHoldChangeTo : isOnHold;
      const bool =
        isVideoEncodeSuccess === 'success' &&
        (isCoOrHost || isBeCanUnmuteVideo) &&
        !isWebinarAttendeeViewOnly &&
        !isInWaingRoom;
      this.notifyController({
        method: 'call_status',
        params: {
          can_unmute_video: bool,
        },
      });
    },
    notifyControllerHideAvatar(bool) {
      this.notifyController({
        method: 'call_status',
        params: {
          hide_profile_images: bool,
        },
      });
    },

    notifyControllerMeetingHoldOn() {
      this.notifyController({
        method: 'call_status',
        params: {
          call_state: CONTROL_MODE_MEETING_STATUS.LOBBY,
        },
      });
      this.notifyControllerParticipantsList([
        {
          operation: 'clear',
        },
      ]);
      this.notifyControllerAudioCanUnMuted({
        bHoldChangeTo: true,
      });
      this.notifyControllerVideoCanUnMuted({
        bHoldChangeTo: true,
      });
    },
    notifyControllerMeetingEnd(error, delayQuit = false) {
      participantsMap.clear();
      this.notifyControllerAudioConnected(false);
      this.notifyControllerVideoConnected(false);
      this.notifyControllerParticipantsList([
        {
          operation: 'clear',
        },
      ]);
      if (error) {
        this.notifyControllerWarningInfo(error);
      }
      this.notifyController({
        method: 'call_status',
        params: {
          call_state: CONTROL_MODE_MEETING_STATUS.DISCONNECTED,
        },
      });
      /**
       * @openapi
       *
       * components:
       *   messages:
       *     call_ended:
       *       summary: meeting call flow ended
       *       payload:
       *         type: object
       *         properties:
       *           method:
       *             const: call_ended
       * channels:
       *   call_ended:
       *     subscribe:
       *       message:
       *         $ref: '#/components/messages/call_ended'
       */
      if (delayQuit) {
        setTimeout(() => {
          this.notifyController({
            method: 'call_ended',
          });
        }, delayCloseMsftApplicationTime);
      } else {
        this.notifyController({
          method: 'call_ended',
        });
      }
    },
    notifyControllerStartJoining() {
      /**
       * @openapi
       *
       * components:
       *   messages:
       *     call_started:
       *       summary: meeting call flow started
       *       payload:
       *         type: object
       *         properties:
       *           method:
       *             const: call_started
       * channels:
       *   call_started:
       *     subscribe:
       *       message:
       *         $ref: '#/components/messages/call_started'
       */
      this.notifyController({
        method: 'call_started',
      });
      participantsMap.clear();
      this.notifyControllerParticipantsList([
        {
          operation: 'clear',
        },
      ]);
      this.notifyController({
        method: 'call_status',
        params: {
          call_state: CONTROL_MODE_MEETING_STATUS.CONNECTING,
        },
      });
    },
    notifyControllerJoinSuccess() {
      this.notifyController({
        method: 'call_status',
        params: {
          call_state: CONTROL_MODE_MEETING_STATUS.CONNECTED,
        },
      });
    },
    notifyControllerJoinFailure(error) {
      if (error) {
        this.notifyControllerWarningInfo(error);
      }
      this.notifyController({
        method: 'call_status',
        params: {
          call_state: CONTROL_MODE_MEETING_STATUS.DISCONNECTED,
        },
      });
      setTimeout(() => {
        this.notifyController({
          method: 'call_ended',
        });
      }, delayCloseMsftApplicationTime);
    },
    notifyControllerActionFailure(errorInfo) {
      /* eslint-disable-next-line no-console */
      console.trace('action failed');
      switch (errorInfo.type) {
        case CONTROL_MODE_COMMON_ERROR_TYPE.MUTE_AUDIO: {
          this.notifyControllerAudioUnmuted();
          this.notifyControllerWarningInfo(TESLA_ERROR_CODE.AUDIO_MUTE_ERROR);
          break;
        }
        case CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO: {
          this.notifyControllerAudioMuted();
          this.notifyControllerWarningInfo(TESLA_ERROR_CODE.AUDIO_UNMUTE_ERROR);
          break;
        }
        case CONTROL_MODE_COMMON_ERROR_TYPE.STOP_VIDEO: {
          this.notifyControllerVideoUnmuted();
          this.notifyControllerWarningInfo(TESLA_ERROR_CODE.VIDEO_STOP_ERROR);
          break;
        }
        case CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO: {
          this.notifyControllerVideoMuted();
          this.notifyControllerWarningInfo(TESLA_ERROR_CODE.VIDEO_START_ERROR);
          break;
        }
        case CONTROL_MODE_COMMON_ERROR_TYPE.WEBGL_CONTEXT_LOST: {
          this.notifyControllerWarningInfo(TESLA_ERROR_CODE.FAILED_ACTION);
          break;
        }
        default:
          break;
      }
    },
    notifyControllerTopicChange(topic) {
      this.notifyController({
        method: 'call_status',
        params: {
          name: topic,
        },
      });
    },
    /**
     * @openapi
     *
     * components:
     *   messages:
     *     call_status:
     *       summary: meeting status info
     *       payload:
     *         type: object
     *         properties:
     *           method:
     *             const: call_status
     *           params:
     *             type: object
     *             properties:
     *               audio_muted:
     *                 description: user self's audio mute/unmute status
     *                 type: boolean
     *               can_unmute_audio:
     *                 description: if user self has the permission to unmute audio
     *                 type: boolean
     *               video_off:
     *                 description: user self's camera on/off status
     *                 type: boolean
     *               can_unmute_video:
     *                 description: if user self has the permission to open camera
     *                 type: boolean
     *               hide_profile_images:
     *                 description: if user self should render profile image in participants list, if not use first char of display_name
     *                 type: boolean
     *               call_state:
     *                 $ref: '#/components/schemas/call_state'
     *               name:
     *                 description: meeting's topic or title
     *                 type: string
     *                 example: xxx's meeting
     *               is_webinar:
     *                 description: is meeting or webinar
     *                 type: boolean
     *               audio_connected:
     *                 description: connected to audio now, can talk with people now
     *                 type: boolean
     *               video_connected:
     *                 description: video init successfully now, can start video or subscribe other's video now
     *                 type: boolean
     *   schemas:
     *     call_state:
     *       type: string
     *       description: >
     *         meeting join flow process:
     *           * connecting: join flow started but not finished yet
     *           * connected: join meeting successfully
     *           * disconnected: disconnected from meeting
     *           * reconnecting: is reconnecting to the meeting
     *           * lobby: be putted into waiting room
     *           * server_connected: connected to rwg server, rwg server can push participants info now
     *       enum:
     *         - connecting
     *         - connected
     *         - disconnected
     *         - reconnecting
     *         - lobby
     *       example: connected
     * channels:
     *   call_status:
     *     subscribe:
     *       message:
     *         $ref: '#/components/messages/call_status'
     */
    notifyControllerAllRosterReceived() {
      const { getState } = store;
      const state = getState();
      const {
        meeting: {
          meetingTopic,
          bCanUnmute,
          bCanUnmuteVideo,
          bAllowedAvatar,
          currentUser,
        },
        socketStatus: { initVideoEncodeStatus },
        meetingUI: { isOnHold },
      } = state;

      const coOrHost = coOrHostSelector(state);
      const { isAllowTalk, userRole } = currentUser;
      const isViewOnlyUser = isViewOnly(userRole);
      const isWebinarAttendeeAudioViewOnly =
        isWebinar() && isViewOnlyUser && !isAllowTalk;
      let isBeCanUnmuteAudio =
        isJoinAudio(currentUser) &&
        (coOrHost || bCanUnmute) &&
        !isWebinarAttendeeAudioViewOnly &&
        !isOnHold;

      const isWebinarAttendeeVideoViewOnly = isWebinar() && isViewOnlyUser;
      const isBeCanUnmuteVideo =
        initVideoEncodeStatus === 'success' &&
        (coOrHost || bCanUnmuteVideo) &&
        !isWebinarAttendeeVideoViewOnly &&
        !isOnHold;

      this.notifyController({
        method: 'call_status',
        params: {
          name: meetingTopic,
          can_mute_audio: true,
          can_unmute_audio: isBeCanUnmuteAudio,
          can_mute_video: true,
          can_unmute_video: isBeCanUnmuteVideo,
          hide_profile_images: !bAllowedAvatar,
          ...(isWebinar() ? { is_webinar: isWebinar() } : {}),
        },
      });
    },
    notifyControllerNetworkReconnecting() {
      this.notifyController({
        method: 'call_status',
        params: {
          call_state: CONTROL_MODE_MEETING_STATUS.NETWORK_RECONNECTING,
        },
      });
    },
    notifyControllerRwgConnected() {
      this.notifyController({
        method: 'call_status',
        params: {
          call_state: CONTROL_MODE_MEETING_STATUS.RWG_CONNECTED,
        },
      });
    },
    notifyControllerAudioConnected(bool) {
      this.notifyController({
        method: 'call_status',
        params: {
          audio_connected: bool,
        },
      });
    },
    notifyControllerVideoConnected(bool) {
      this.notifyController({
        method: 'call_status',
        params: {
          video_connected: bool,
        },
      });
    },
    /**
     * @openapi
     *
     * components:
     *   messages:
     *     call_participants:
     *       summary: zoom notify third party participants list update operations
     *       payload:
     *         type: object
     *         properties:
     *           method:
     *             const: call_participants
     *           params:
     *             type: object
     *             required:
     *               - operations
     *             properties:
     *               operations:
     *                 type: array
     *                 description: participant operation("add" | "update" | "remove" | "clear")
     *                 items:
     *                   anyOf:
     *                     - $ref: '#/components/schemas/update_participant'
     *                     - $ref: '#/components/schemas/add_participant'
     *                     - $ref: '#/components/schemas/remove_participant'
     *                     - $ref: '#/components/schemas/clear_participant'
     *   schemas:
     *     user_id:
     *       type: number
     *       description: zoom user's identify id
     *       example: 16780288
     *     in_waiting_room:
     *       description: participant is in waiting room or not
     *       type: boolean
     *       example: false
     *     is_webinar_attendee:
     *       description: participant is webinar attendee or not
     *       type: boolean
     *       example: false
     *     is_me:
     *       description: participant is user self or not
     *       type: boolean
     *       example: false
     *     is_host:
     *       description: participant role is host or not
     *       type: boolean
     *       example: false
     *     is_cohost:
     *       description: participant role is cohost or not
     *       type: boolean
     *       example: false
     *     profile_image_url:
     *       description: participant's profile image url. If empty, can use first char of display_name as the profile image
     *       type: string
     *     audio_status:
     *       type: string
     *       description: >
     *         participant's audio join status:
     *           * "": audio not joined
     *           * computer: join audio by computer
     *           * phone: join audio by phone
     *       enum:
     *         - ''
     *         - computer
     *         - phone
     *       example: computer
     *     background_color:
     *           type: string
     *           description: char avatar render background color when profile image empty
     *           enum:
     *             - '#27ae60'
     *             - '#16a085'
     *             - '#2980b9'
     *             - '#8e44ad'
     *             - '#34495e'
     *             - '#f39c12'
     *             - '#d35400'
     *             - '#c0392b'
     *     add_participant:
     *       type: object
     *       required:
     *         - operation
     *         - user_id
     *       properties:
     *         operation:
     *           description: trigger when new participant join the meeting
     *           const: add
     *         user_id:
     *           $ref: '#/components/schemas/user_id'
     *         display_name:
     *           type: string
     *           example: Test User
     *         background_color:
     *           $ref: '#/components/schemas/background_color'
     *         in_waiting_room:
     *           $ref: '#/components/schemas/in_waiting_room'
     *         is_webinar_attendee:
     *           $ref: '#/components/schemas/is_webinar_attendee'
     *         is_me:
     *           $ref: '#/components/schemas/is_me'
     *         is_host:
     *           $ref: '#/components/schemas/is_host'
     *         is_cohost:
     *           $ref: '#/components/schemas/is_cohost'
     *         profile_image_url:
     *           $ref: '#/components/schemas/profile_image_url'
     *     update_participant:
     *       type: object
     *       required:
     *         - operation
     *         - user_id
     *       properties:
     *         operation:
     *           description: trigger when participant information change
     *           const: update
     *         user_id:
     *           $ref: '#/components/schemas/user_id'
     *         display_name:
     *           description: participant rename will update display_name
     *           type: string
     *         background_color:
     *           $ref: '#/components/schemas/background_color'
     *         audio_status:
     *           $ref: '#/components/schemas/audio_status'
     *         audio_muted:
     *           description: audio mute/unmute status
     *           type: boolean
     *         video_muted:
     *           description: camera on/off status
     *           type: boolean
     *         in_waiting_room:
     *           $ref: '#/components/schemas/in_waiting_room'
     *         active_speaker:
     *           description: participant is speaking or not
     *           type: boolean
     *         is_webinar_attendee:
     *           $ref: '#/components/schemas/is_webinar_attendee'
     *         is_host:
     *           $ref: '#/components/schemas/is_host'
     *         is_cohost:
     *           $ref: '#/components/schemas/is_cohost'
     *     remove_participant:
     *       type: object
     *       required:
     *         - operation
     *         - user_id
     *       properties:
     *         operation:
     *           description: trigger when participant leave the meeting
     *           const: remove
     *         user_id:
     *           $ref: '#/components/schemas/user_id'
     *         is_webinar_attendee:
     *           $ref: '#/components/schemas/is_webinar_attendee'
     *     clear_participant:
     *       type: object
     *       properties:
     *         operation:
     *           description: trigger when reconect to the meeting
     *           const: clear
     * channels:
     *   call_participants:
     *     subscribe:
     *       message:
     *         $ref: '#/components/messages/call_participants'
     */
    notifyControllerParticipantsList(participants) {
      this.notifyController({
        method: 'call_participants',
        params: {
          operations: participants,
        },
      });
    },
    // only give a warning, don't close application
    notifyControllerWarningInfo(warning) {
      const { getState } = store;
      const {
        meeting: { currentUser, meetingNumber },
      } = getState();
      const { userId, displayName } = currentUser;
      /**
       * @openapi
       *
       * components:
       *   messages:
       *     debug_log:
       *       summary: meeting debug log
       *       payload:
       *         type: object
       *         properties:
       *           method:
       *             const: debug_log
       * channels:
       *   debug_log:
       *     subscribe:
       *       message:
       *         $ref: '#/components/messages/debug_log'
       */
      this.notifyController({
        method: 'debug_log',
        params: JSON.stringify({
          warning,
          meetingNumber,
          userId,
          displayName,
        }),
      });
    },
    notifyControllerFailover() {
      participantsMap.clear();
      this.notifyControllerAudioConnected(false);
      this.notifyControllerVideoConnected(false);
      this.notifyControllerParticipantsList([
        {
          operation: 'clear',
        },
      ]);
    },
    notifyController(payload) {
      window.teslaSocket.sendMessage(payload);
    },
    dispose() {},
  };
};

export default teslaAdaptor;
