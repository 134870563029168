import { infoLog } from '../../../global/web-client-logger';
import { fileSentTxt, imageSentTxt } from '../../chat/resource';
import { fontStyleToRtbStr } from './rtb/index';
import { generateUUID } from '../../../global/service/generate-uuid';
import {
  messageFeatureType,
  zoomXmppMsgType,
  fileSubType,
  ZoomTaskFeature,
  trackMessageType,
} from './enum';
import {
  getMentions,
  fileSuffixMap,
  fileType,
  chatType,
  xmlns,
  getFileIntegrationType,
  RESID,
} from './cover';
import { convertJSon2XML, getFileSuffix } from '../../chat/utils';

/**
 *  @typedef { import("../../../global/ChatSDK/@types/meetingTypes").meetChatFileExtra} meetChatFileExtra
 */
/**
 * @param file file object
 * @param file.size string
 * @param file.id
 * @param file.name
 * @param file.fileObj
 *
 * @param shareFile share file object
 * @param sharedFile.fileID string
 * @param sharedFile.fileSize number
 * @param sharedFile.fileName string
 * @param sharedFile.shareType string
 * @param sharedFile.previewUrl string
 *
 * @param replyTo reply object
 * @param replyTo.mainMsgId string
 * @param replyTo.mainMsgTime string
 * @param replyTo.owner string
 *
 * @param rtbItems.item Array
 * @param {meetingChatExt} meetChatFileExtra
 */

export function buildSendXmppMsgData({
  id,
  message,
  file,
  from, // self jid
  to, // receiver jid or meeting id
  channelName, // meetingTopic
  senderName, // senderName from web, currentUser.displayName
  sharedFile,
  ct = Date.now(),
  isLogin,
  meetid,
  replyTo,
  rtbItems = {},
  meetChatExtra = {},
}) {
  const isFileMessage = message == null && file != null; // treat undefined as null
  const isTextMessage = message != null && file == null;
  const isFileShare = sharedFile != null;
  if (!id) {
    id = generateUUID();
  }
  // TODO giphy
  let isImageMessage = false;
  let isGiphyMessage = false;
  const isReplyMsg = !_.isEmpty(replyTo);
  let replyObj = {};
  if (isReplyMsg) {
    const owner = replyTo.owner ? replyTo.owner.replace(/@xmpp.+/, '') : '';
    replyObj = {
      _msg_id: replyTo.mainMsgId,
      _thread_t: replyTo.mainMsgTime,
      ...(owner ? { _owner: owner } : {}),
    };
  }

  let rtb = fontStyleToRtbStr(rtbItems);
  const atJids = getMentions(rtbItems);

  let format = '%1$@ in %2$@: %3$@';
  let body;
  let fileSuffix;
  let fileSize;
  let fileId;
  let fileName;
  let k; // fileKey?

  if (isTextMessage) {
    body = message;
  } else if (isFileMessage) {
    const { size, id, name: fName, fileObj } = file;
    fileSize = size;
    fileId = id;
    fileName = fName;
    k = fileObj;
    fileSuffix = getFileSuffix(fName);
    const suffixIndex = fileSuffixMap[fileType.image].indexOf(fileSuffix);

    if (suffixIndex !== -1) {
      isImageMessage = true;
      // isGiphyMessage = isImageMessage && suffixIndex === 3;
      // image
      body = imageSentTxt(senderName);
      format = '%1$@ sent you an image';
    } else {
      // common file
      body = fileSentTxt(senderName);
      format = '%1$@ sent you a file';
    }
  }

  let msgFeature = isImageMessage
    ? messageFeatureType[fileSuffix]
    : isFileMessage
    ? messageFeatureType.rawFile
    : isGiphyMessage
    ? messageFeatureType.giphyV2
    : messageFeatureType.text;

  // tracked message type
  let msgType = isImageMessage
    ? trackMessageType[fileSuffix] || trackMessageType.otherFile
    : isFileMessage
    ? trackMessageType.otherFile
    : isGiphyMessage
    ? trackMessageType.giphy
    : trackMessageType.text;
  if (isFileShare) {
    body = fileSentTxt(senderName);
    msgFeature = messageFeatureType.meetingFileShare;
    format = '%1$@ sent you a file';
    // format = `%1$@ sent you a file from ${}`;
  }
  if (isReplyMsg) msgFeature = msgFeature | messageFeatureType.comment;

  const sns = {
    format,
    args: {
      arg: [senderName, channelName],
      ...(isTextMessage ? { body: null } : {}),
    },
  };
  const msgDescObj = {
    message: {
      ...(from ? { _from: from } : {}),
      ...(to ? { _to: to } : {}),
      _id: id,
      _type: !to
        ? chatType.groupchat
        : to.includes('conference')
        ? chatType.groupchat
        : chatType.chat,
      _xmlns: xmlns.client,
      body,
      sns,
      meetchat: {
        // TODO
        _ct: ct,
        ...(meetid ? { _meetid: meetid } : {}),
        _from: 'meet',
        _is_guest: isLogin ? 0 : 1, // 0: not guest has jid, 1 is guest
        ...(meetChatExtra ? meetChatExtra : {}),
      },
      zmext: {
        msg_type: msgType,
        /* fixed */
        from: {
          _n: senderName,
          _res: 'ZoomChat_pc', // need resourceId {deviceInfo} ?
        },
        to: null,
        ...(atJids
          ? {
              at: {
                user: atJids,
              },
            }
          : {}),
        visible: true,
        /* format */
        ...(isTextMessage
          ? rtb
            ? { rt: { _b: rtb } }
            : {}
          : isFileMessage || isImageMessage
          ? {
              obj: {
                _k: k,
                _id: fileId,
                _s: fileSize,
                _nm: fileName,
                _f: zoomXmppMsgType[fileSuffix] || zoomXmppMsgType.rawFile,
                _st: fileSubType.none,
                _fs: 0, // fileScope
              },
            }
          : {}),
        msg_feature: msgFeature,
        ...(isReplyMsg ? { reply: replyObj } : {}),
      },
      ...(isFileShare
        ? {
            it: {
              _t: getFileIntegrationType(sharedFile.shareType),
              _id: sharedFile.fileID,
              _pl: sharedFile.previewUrl,
              _n: sharedFile.fileName,
              _tt: 0,
              _s: sharedFile.fileSize,
              _pp: sharedFile.previewPath,
            },
          }
        : {}),
    },
  };

  return new Promise((resolve, reject) => {
    let msgXml = '';
    try {
      msgXml = convertJSon2XML(msgDescObj);
      resolve(msgXml);
      infoLog(`send xml ${msgXml}`);
    } catch (error) {
      reject(error);
    }
  });
}
export function buildDeleteMsgXml({
  id,
  // message,
  // file,
  from, // self jid
  to, // receiver jid or meeting id
  // channelName, // meetingTopic
  senderName, // senderName from web, currentUser.displayName
  xmppMsgId, // id of revoked msg
  threadId, // id of revoked msg's thread
  t = Date.now(),
  threadT = Date.now(),
  isLogin,
  meetid,
}) {
  const msgDescObj = {
    message: {
      ...(from ? { _from: from } : {}),
      ...(to ? { _to: to } : {}),
      // _from: from,
      _id: id,
      _type: !to
        ? chatType.groupchat
        : to.includes('conference')
        ? chatType.groupchat
        : chatType.chat,
      _xmlns: xmlns.client,
      zmext: {
        msg_type: trackMessageType.revokeMessage,
        from: {
          _n: senderName,
          _res: 'ZoomChat_pc',
        },
        to: null,
        visible: true,
      },
      revoke: {
        _id: xmppMsgId,
        _t: t,
        _thread_t: threadT || t,
        ...(!threadId ? {} : { _thread: threadId }),
        // _has_comment: 0,
      },
      zmtask: {
        _feature: ZoomTaskFeature.RevokeMessage,
      },
      meetchat: {
        _ct: t,
        ...(meetid ? { _meetid: meetid } : {}),
        _from: 'meet',
        _is_guest: isLogin ? 0 : 1,
      },
    },
  };
  infoLog('send delete json' + JSON.stringify(msgDescObj));

  return new Promise((resolve, reject) => {
    let msgXml = '';
    try {
      msgXml = convertJSon2XML(msgDescObj);
      resolve(msgXml);
      infoLog(`send xml ${msgXml}`);
    } catch (error) {
      reject(error);
    }
  });
}
export function buildEditedMsgXml({
  from,
  to,
  id,
  senderName,
  xmppMsgId,
  text,
  msgT,
  t = Date.now(),
  isLogin,
  meetid,
  rtbItems,
}) {
  const msgToType = !to
    ? chatType.groupchat
    : to.includes('conference')
    ? chatType.groupchat
    : chatType.chat;
  let rtb = fontStyleToRtbStr(rtbItems);
  const atJids = getMentions(rtbItems);

  const newMessage = {
    message: {
      ...(from ? { _from: from } : {}),
      ...(to ? { _to: to } : {}),
      // _from: from,
      _id: xmppMsgId,
      _type: msgToType,
      body: text,
      zmext: {
        _prev: 0,
        _t: msgT,
        msg_type: 0,
        msg_feature: messageFeatureType.text,
        visible: true,
        to: null,
        ...(rtb ? { rt: { _b: rtb } } : {}),
        ...(atJids
          ? {
              at: {
                user: atJids,
              },
            }
          : {}),
        from: {
          _n: senderName,
          _res: 'ZoomChat_pc',
        },
      },
      meetchat: {
        _ct: msgT,
        ...(meetid ? { _meetid: meetid } : {}),
        _from: 'meet',
        _is_guest: isLogin ? 0 : 1,
      },
    },
  };
  const msgDescObj = {
    message: {
      ...(from ? { _from: from } : {}),
      ...(to ? { _to: to } : {}),
      // _from: from,
      _id: id,
      _type: msgToType,
      _xmlns: xmlns.client,
      zmedit: {
        _id: xmppMsgId,
        _t: msgT,
        ...newMessage,
      },
      zmext: {
        msg_type: trackMessageType.editEvent,
        from: {},
        to: null,
        visible: false,
      },
      zmtask: {
        _feature: ZoomTaskFeature.RevokeMessage,
        _type: 'medit',
        _xmlns: xmlns.imcmd,
      },
      meetchat: {
        _ct: t,
        ...(meetid ? { _meetid: meetid } : {}),
        _from: 'meet',
        _is_guest: isLogin ? 0 : 1,
      },
    },
  };
  return new Promise((resolve, reject) => {
    try {
      const xmlStr = convertJSon2XML(msgDescObj);
      resolve(xmlStr);
    } catch (error) {
      reject(error);
    }
  });
}
export function buildSendVoteEmoji({
  id,
  cid,
  from, // self jid
  to, // receiver jid or meeting id
  ct = Date.now(),
  isLogin,
  meetid,
  mainMsgId,
  mainMsgTime,
  action,
  encodedEmoji,
}) {
  const session = to.split('@')[0];
  const iqDecObj = {
    iq: {
      _id: id,
      _type: 'set',
      _xmlns: xmlns.client,
      ...(from ? { _from: from } : {}),
      ...(to ? { _to: to } : {}),
      query: {
        _xmlns: xmlns.emoji,
        emoji: {
          _action: action,
          _session: session,
          _type: 'groupchat',
          _msgId: mainMsgId,
          _msg_t: mainMsgTime,
          _cid: cid,
          _id: encodedEmoji,
        },
      },
      meetchat: {
        _ct: ct,
        ...(meetid ? { _meetid: meetid } : {}),
        _from: 'meet',
        _is_guest: isLogin ? 0 : 1, // 0: not guest has jid, 1 is guest
      },
    },
  };
  return new Promise((resolve, reject) => {
    let msgXml = '';
    try {
      msgXml = convertJSon2XML(iqDecObj);
      resolve(msgXml);
      infoLog(`send emoji xml ${msgXml}`);
    } catch (error) {
      reject(error);
    }
  });
}
export function buildInvisibleAtEventXML({
  id,
  ct,
  atMsgId,
  atMsgT,
  mainMsgId,
  mainMsgTime,
  mention,
  from,
  to,
  senderName,
  isLogin,
  meetid,
}) {
  const atUsers =
    mention.length === 1
      ? {
          _jid: mention[0].jid,
        }
      : mention.map((m) => ({ _jid: m.jid }));
  const msgObj = {
    message: {
      _xmlns: xmlns.client,
      ...(to ? { _to: to } : {}),
      _id: id,
      _type: !to
        ? chatType.groupchat
        : to.includes('conference')
        ? chatType.groupchat
        : chatType.chat,
      ...(from ? { _from: from } : {}),
      zmext: {
        msg_type: trackMessageType.atEvent,
        from: {
          _n: senderName,
          _res: RESID,
        },
        to: null,
        visible: false,
        // _prev: atMsgT,
        // _t: ct,
      },
      zmat: {
        at: {
          user: atUsers,
        },
        _id: atMsgId,
        _t: atMsgT,
        ...(mainMsgId && mainMsgTime
          ? {
              _thrdid: mainMsgId,
              _thrdt: mainMsgTime,
            }
          : {}),
      },
      zmtask: {
        _type: 'zmat',
        _feature: ZoomTaskFeature.AtEvent,
        _xmlns: xmlns.imcmd,
      },

      meetchat: {
        _ct: ct,
        ...(meetid ? { _meetid: meetid } : {}),
        _from: 'meet',
        _is_guest: isLogin ? 0 : 1,
      },
    },
  };
  return new Promise((resolve, reject) => {
    let msgXml = '';
    try {
      msgXml = convertJSon2XML(msgObj);
      resolve(msgXml);
      infoLog(`send invisible xml ${msgXml}`);
    } catch (error) {
      reject(error);
    }
  });
}
