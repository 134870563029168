import React from 'react';
import * as actions from './attention-mode-action';
import {
  coOrHostSelector,
  attendeesListSelector,
  shareUserListSelector,
  shareUserSelector,
  meetingHostSelector,
  inMeetingParticipantsSizeSelector,
  isReceiveSharingSelector,
} from '../../../global/redux/selector';
import { isCoOrHost } from '../../../global/service';
import AliveToast from '../../../global/containers/notification-manager/alive-toast';
import {
  ATTENTION_MODE_HOST_TOAST,
  toastForHostsShareAttentionMode,
  toastOfATShareAll,
  toastOfATShareHostOnly,
  focusModeEndingTxt,
} from '../resource';
import { sendSocketMessage } from '../../../actions/SocketActions';
import {
  WS_CONF_VIDEO_ATTENTION_MODE_SWITCH,
  WS_CONF_SHARE_ATTENTION_MODE_SET_TYPE_REQ,
} from '../../../constants/ZoomSocketEventTypes';
import ZoomButton from '../../../global/components/widget/button/zoom-button';
import { OK_TEXT } from '../../video/resource';
import {
  EVT_TYPE_WS_SHARING_REMOTE_CONTROL_REQ,
  SHARER_STATUS_ENUM,
} from '../../sharing/enum';
import { getPrepareForReceiveShare } from '../../sharing/redux/sharing-thunk-action';
import { setSharingActiveNode } from '../../sharing/redux/sharing-action';
import { SHARE_ATTENTION_MODE_ENUM } from '../enum';
import { spotlightVideoListSelector } from '../../video/redux/selectors/video-common-selector';
import { clearPinVideoListThunk } from '../../video/redux/thunks/update-pin-thunk';
import { spotLightVideo } from '../../dialog/redux/dialog-thunk-action';

export function releaseRemoteControl(nodeId = null) {
  return (dispatch, getState) => {
    const {
      sharing: { isRemoteControl, shareeCurrentActiveNodeId },
    } = getState();
    if (isRemoteControl) {
      dispatch(
        sendSocketMessage({
          evt: EVT_TYPE_WS_SHARING_REMOTE_CONTROL_REQ,
          body: {
            id: nodeId || shareeCurrentActiveNodeId,
            bOn: false,
          },
        }),
      );
    }
  };
}

const TOAST_KEY = 'FOCUS_MODE_ENDING';
const closeEndingToast = () => {
  AliveToast.close(TOAST_KEY);
};

export const showEndingToast = (isHost) => {
  AliveToast.uniqueToast({
    name: TOAST_KEY,
    aliveTime: isHost ? 5000 : 15000,
    message: focusModeEndingTxt,
    btnComponent: isHost ? null : (
      <ZoomButton
        type="primary"
        styleProps={{ fontWeight: 'bold' }}
        onClick={closeEndingToast}
      >
        {OK_TEXT}
      </ZoomButton>
    ),
  });
};

export const setIsFocusModeEndingThunk =
  ({ videoEnding = false, shareEnding = false }) =>
  (dispatch, getState) => {
    const state = getState();
    const coOrHost = coOrHostSelector(state);
    const {
      attentionMode: { isVideoEnding, isShareEnding },
    } = state;

    dispatch(
      actions.setIsFocusModeEnding({
        isVideoEnding: isVideoEnding || videoEnding,
        isShareEnding: isShareEnding || shareEnding,
      }),
    );
    if ((isVideoEnding || videoEnding) && (isShareEnding || shareEnding)) {
      showEndingToast(coOrHost);
    }
  };

export const showToastForHostOrCohost = () => {
  AliveToast.toast({
    message: ATTENTION_MODE_HOST_TOAST,
    aliveTime: 5000,
  });
};

export const toggleAttentionMode =
  (isOpen = true) =>
  (dispatch, getState) => {
    const state = getState();
    const {
      attentionMode: { isAttentionModeSettingEnable, isEnableAttentionMode },
      video: {
        UI: { videoLayout },
        pinVideoList,
      },
    } = state;
    const coOrHost = coOrHostSelector(state);
    if (!isAttentionModeSettingEnable || !coOrHost) {
      return;
    }
    if (isEnableAttentionMode === isOpen) return;
    dispatch(
      sendSocketMessage({
        evt: WS_CONF_VIDEO_ATTENTION_MODE_SWITCH,
        body: {
          bOn: isOpen,
        },
      }),
    );
    if (isOpen) {
      const meetingHost = meetingHostSelector(state);
      const spotlightUserList = spotlightVideoListSelector(state);
      const inMeetingSize = inMeetingParticipantsSizeSelector(state);
      if (
        meetingHost &&
        meetingHost.bVideoOn &&
        inMeetingSize > 2 &&
        spotlightUserList.length < 9 &&
        !spotlightUserList.includes(meetingHost.userId)
      ) {
        if (pinVideoList.length > 0) {
          dispatch(clearPinVideoListThunk(false));
        }
        dispatch(
          spotLightVideo({
            type: 'add',
            userId: meetingHost.userId,
            userName: meetingHost.displayName,
            videoLayout,
            pinVideoList: [],
          }),
        );
      }
    }
  };

let closeAtShareToast = null;
function closeHostOnlyToast() {
  if (closeAtShareToast) {
    closeAtShareToast();
    closeAtShareToast = null;
  }
}
export function showATShareHostOnlyToast() {
  closeHostOnlyToast();
  AliveToast.toast({
    messageTip: toastOfATShareHostOnly,
    btnComponent: (onClose) => {
      closeAtShareToast = onClose;
      return (
        <ZoomButton
          type="primary"
          styleProps={{ fontWeight: 'bold' }}
          onClick={() => {
            onClose();
          }}
        >
          {OK_TEXT}
        </ZoomButton>
      );
    },
    aliveTime: 0,
  });
}

export function showATSHareAllToast() {
  AliveToast.toast({
    messageTip: toastOfATShareAll,
  });
}

export function showATShareToastForHost() {
  AliveToast.toast({ messageTip: toastForHostsShareAttentionMode });
}

export const onShareAttentionModeChangeThunk =
  (status) => (dispatch, getState) => {
    const state = getState();
    const coOrHost = coOrHostSelector(state);
    dispatch(actions.setShareAttentionModeStatus(status));

    const isReceiveSharing = isReceiveSharingSelector(state);

    const {
      attentionMode: { isEnableAttentionMode },
      meeting: { currentUser },
      sharing: { sharerStatus },
    } = state;

    if (isEnableAttentionMode) {
      if (state.meeting.currentUser.bHold) return;
      if (status === SHARE_ATTENTION_MODE_ENUM.HOST_ONLY) {
        if (isReceiveSharing) {
          if (coOrHost) {
            showATShareToastForHost();
          } else {
            const shareUser = shareUserSelector(state);
            const shareUserList = shareUserListSelector(state);

            // if has other sharing user, change receiving sharing on `onSetShareAttentionModeList`
            if (shareUser && !isCoOrHost(shareUser) && !shareUserList.length) {
              dispatch(releaseRemoteControl(shareUser.shareUserId));
              dispatch(
                setSharingActiveNode({
                  activeNodeID: shareUser.shareUserId,
                  bStatus: 0,
                  ssrc: 0,
                }),
              );
            }
          }
        }

        dispatch(tryToShowToastForPtsInFocusMode());
      } else if (status === SHARE_ATTENTION_MODE_ENUM.ALL_PARTICIPANT) {
        if (state.meeting.currentUser.sharerOn && !coOrHost) {
          closeHostOnlyToast();
          AliveToast.toast({
            aliveTime: 5000,
            messageTip: toastOfATShareAll,
          });
        }
      }
    } else {
      const shareUserList = shareUserListSelector(state);
      const sharer = shareUserList.filter(
        (user) => user.userId !== currentUser.userId,
      )[0];
      if (
        sharer &&
        !isReceiveSharing &&
        sharerStatus === SHARER_STATUS_ENUM.ED
      ) {
        dispatch(
          setSharingActiveNode({
            activeNodeID: sharer.userId,
            bStatus: 1,
            ssrc: sharer.userId,
          }),
        );
      }
    }
    dispatch(actions.setIsFocusModeEnding({ isShareEnding: false }));
  };

export const tryToShowToastForPtsInFocusMode = () => (dispatch, getState) => {
  const state = getState();
  const coOrHost = coOrHostSelector(state);
  const {
    attentionMode: { shareAttentionMode },
    sharing: { sharerStatus },
  } = state;
  if (
    !coOrHost &&
    shareAttentionMode === SHARE_ATTENTION_MODE_ENUM.HOST_ONLY &&
    sharerStatus === SHARER_STATUS_ENUM.ING
  ) {
    showATShareHostOnlyToast();
  }
};

export const setShareAttentionModeType = (type) => (dispatch, getState) => {
  const {
    attentionMode: { shareAttentionMode },
  } = getState();
  if (shareAttentionMode === type) return;
  dispatch(
    sendSocketMessage({
      evt: WS_CONF_SHARE_ATTENTION_MODE_SET_TYPE_REQ,
      body: {
        nOn: type,
      },
    }),
  );
};

export const onAttentionModeChangeThunk = (isOpen) => (dispatch, getState) => {
  const state = getState();
  const coOrHost = coOrHostSelector(state);
  dispatch(actions.setAttentionModeStatus(isOpen));
  if (isOpen) {
    if (coOrHost) {
      showToastForHostOrCohost();
    } else {
      dispatch(
        actions.setAttentionModeToastForOthers({ beSpotlighted: false }),
      );
    }
  } else {
    dispatch(actions.setAttentionModeToastForOthers(false));
    // for close attention, because receive [0]
    dispatch(actions.setAttentionModeUserList([]));
    closeHostOnlyToast();
    closeEndingToast();
  }
  dispatch(actions.setAttentionModeDialogVisible(false));
  dispatch(actions.setIsFocusModeEnding({ isVideoEnding: false }));
};

export const setAttentionModeEnableThunk = () => (dispatch, getState) => {
  const state = getState();
  const {
    attentionMode: { isFirstTimeUse },
  } = state;

  if (isFirstTimeUse) {
    dispatch(toggleAttentionMode(true));
  } else {
    dispatch(actions.setAttentionModeDialogVisible(true));
  }
};

export const onRecvAttentionModeList = (list) => (dispatch) => {
  // maybe receive [0]
  const filterList = list.filter(Boolean);
  if (filterList.length) {
    dispatch(actions.setAttentionModeUserList(filterList));
  }
};

export const onSetShareAttentionModeList =
  (rwgList = []) =>
  (dispatch, getState) => {
    const state = getState();
    const {
      sharing: { shareeCurrentActiveNodeId, sharerStatus },
      meeting: { currentUser },
      attentionMode: { isEnableAttentionMode, shareAttentionMode },
    } = state;
    const isReceiveSharing = isReceiveSharingSelector(state);
    const attendees = attendeesListSelector(state);
    const coOrHost = coOrHostSelector(state);
    if (coOrHost) return;
    // fix rwg roster_share_map issue
    const attendeesIds = attendees.map((user) => user.userId);
    const list = rwgList.filter((id) => attendeesIds.includes(id));

    dispatch(actions.setShareAttentionModeWhiteList(list));
    if (list.length) {
      if (isReceiveSharing && sharerStatus === SHARER_STATUS_ENUM.ED) {
        if (!list.includes(shareeCurrentActiveNodeId)) {
          const newSharerId = list.filter(
            (id) =>
              id >> 10 !== shareeCurrentActiveNodeId >> 10 &&
              id !== currentUser.userId,
          )[0];
          // change share user
          if (newSharerId) {
            dispatch(
              setSharingActiveNode({
                activeNodeID: newSharerId,
                bStatus: 1,
                ssrc: newSharerId,
              }),
            );
          }
        }
      } else {
        const value = list[0];
        if (sharerStatus === SHARER_STATUS_ENUM.ED) {
          dispatch(
            getPrepareForReceiveShare({
              activeNodeID: value,
              bStatus: 1,
              ssrc: value,
            }),
          );
        }
      }
    } else {
      if (isReceiveSharing) {
        if (isEnableAttentionMode) {
          dispatch(releaseRemoteControl(shareeCurrentActiveNodeId));
          dispatch(
            setSharingActiveNode({
              activeNodeID: shareeCurrentActiveNodeId,
              bStatus: 0,
              ssrc: 0,
            }),
          );
        }
      } else {
        /**
         * if close focus mode, will receive empty list from server
         * if someone is sharer on, sub share
         */
        const shareUser = attendees.filter(
          (user) => user.sharerOn && user.userId !== currentUser.userId,
        )[0];
        if (shareUser && sharerStatus === SHARER_STATUS_ENUM.ED) {
          if (
            (isEnableAttentionMode && isCoOrHost(shareUser)) ||
            !isEnableAttentionMode ||
            shareAttentionMode === SHARE_ATTENTION_MODE_ENUM.ALL_PARTICIPANT
          ) {
            dispatch(
              getPrepareForReceiveShare({
                activeNodeID: shareUser.userId,
                bStatus: 1,
                ssrc: shareUser.userId,
              }),
            );
          }
        }
      }
    }
  };

export const showAttentionModeGotToast = () => (dispatch, getState) => {
  // be spotlighted will touch
  const state = getState();
  const coOrHost = coOrHostSelector(state);
  const {
    meeting: { currentUser },
    attentionMode: { attentionModeToastVisible },
  } = state;
  if (coOrHost || currentUser.bHold) return;

  if (!attentionModeToastVisible) {
    dispatch(actions.setAttentionModeToastForOthers(true));
  }
};

export const resetAttentionModeThunk = () => (dispatch) => {
  dispatch(actions.resetAttentionMode());
  if (closeAtShareToast) {
    closeAtShareToast = null;
  }
  closeEndingToast();
};

export function showAttentionModeGotToastForSpotlight({ id, bLeadershipOn }) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: {
        currentUser: { userId },
      },
      attentionMode: { isEnableAttentionMode },
    } = state;
    if (!isEnableAttentionMode) return;
    if (bLeadershipOn && userId >> 10 === id >> 10) {
      dispatch(showAttentionModeGotToast());
    }
  };
}

export function setIsMmrSupportFocusMode({
  bIsMMRShareSupport,
  bIsMMRVideoSupport,
}) {
  return (dispatch, getState) => {
    const {
      attentionMode: { isMMRShareSupport, isMMRVideoSupport },
    } = getState();

    dispatch(
      actions.setIsMmrSupportFm({
        isMMRShareSupport: bIsMMRShareSupport || isMMRShareSupport,
        isMMRVideoSupport: bIsMMRVideoSupport || isMMRVideoSupport,
      }),
    );
  };
}

export function handleFocusModal(bCoOrHost) {
  return (dispatch, getState) => {
    const state = getState();
    const currentIsCoOrHost = coOrHostSelector(state);
    const {
      sharing: { sharerStatus, shareeCurrentActiveNodeId },
      attentionMode: { shareAttentionMode, isEnableAttentionMode },
      meeting: { currentUser },
    } = state;
    if (!bCoOrHost && state.attentionMode.attentionModeDialogVisible) {
      dispatch(actions.setAttentionModeDialogVisible(false));
    }
    if (!isEnableAttentionMode) return;

    const newCoOrHost = currentIsCoOrHost && bCoOrHost;
    const lostCoOrHost = !currentIsCoOrHost && !bCoOrHost;

    const isReceiveSharing = isReceiveSharingSelector(state);

    /**
     * host only, p sharing, host/co change don't push share-list
     */
    if (shareAttentionMode === SHARE_ATTENTION_MODE_ENUM.HOST_ONLY) {
      const shareUser = attendeesListSelector(state).find(
        (user) => user.userId === shareeCurrentActiveNodeId,
      );
      if (newCoOrHost) {
        const shareUserList = shareUserListSelector(state);
        // for host try to sub share
        if (
          isReceiveSharing &&
          sharerStatus === SHARER_STATUS_ENUM.ED &&
          !isCoOrHost(shareUser)
        ) {
          const newSharer = shareUserList.filter(
            (user) =>
              user.userId >> 10 !== shareeCurrentActiveNodeId >> 10 &&
              user.userId !== currentUser.userId,
          )[0];

          // change share user
          if (newSharer) {
            dispatch(
              setSharingActiveNode({
                activeNodeID: newSharer.userId,
                bStatus: 1,
                ssrc: newSharer.userId,
              }),
            );
          }
        }
        if (
          !isReceiveSharing &&
          shareUserList.length &&
          sharerStatus === SHARER_STATUS_ENUM.ED
        ) {
          dispatch(
            getPrepareForReceiveShare({
              activeNodeID: shareUserList[0].userId,
              bStatus: 1,
              ssrc: shareUserList[0].userId,
            }),
          );
        }
      }
      if (lostCoOrHost) {
        // for particpant, should unsub share or change to see hosts' sharing

        if (isReceiveSharing && shareUser && !isCoOrHost(shareUser)) {
          dispatch(releaseRemoteControl(shareeCurrentActiveNodeId));
          const sharingUsers = shareUserListSelector(state);
          // will touch useSharingStopSwitchNewSharer(settimeout 50), just unsub here
          if (sharingUsers.length === 0) {
            dispatch(
              setSharingActiveNode({
                activeNodeID: shareeCurrentActiveNodeId,
                bStatus: 0,
                ssrc: 0,
              }),
            );
          }
        }
      }
    }
  };
}
