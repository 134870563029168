// this file main for AtteneesListReducer.js
// add videoOrder property for attendeeList
// use for sort
import compose from 'lodash/fp/compose';
import {
  isZRMultiStreamVideoUser,
  isZRMultiStreamVideoChildUser,
} from './index';

export const sortVideoOrderByDragList = (
  AttendeeListGroupdraft,
  newUser,
  dragList,
) => {
  (dragList || AttendeeListGroupdraft.dragList || []).forEach((item, index) => {
    let existingAttendee = AttendeeListGroupdraft.attendeesList.find(
      (attendee) => attendee.userId === item.id,
    );
    if (existingAttendee) {
      existingAttendee.videoOrder = index;
    } else if (newUser) {
      existingAttendee = newUser.find(
        (attendee) => attendee.userId === item.id,
      );
      if (existingAttendee) {
        existingAttendee.videoOrder = index;
      }
    }
  });
  return AttendeeListGroupdraft;
};
// for ZR order
const sortZrChildGroup = (list) => {
  return (list || []).sort(
    (a, b) => a.nMultiStreamVideoPos - b.nMultiStreamVideoPos,
  );
};

const findUserIndex = (draft, userId) => {
  return draft.attendeesList.findIndex((user) => user.userId === userId);
};

// sort ZR user
export const sortZrVideoOrder = (draft) => {
  const hasZrMap = Object.keys(draft.zrUserMap || {});
  if (!hasZrMap.length) {
    return draft;
  }

  Object.keys(draft.zrUserMap).forEach((id) => {
    draft.zrUserMap[id] = sortZrChildGroup(draft.zrUserMap[id]);
    const parentIdx = findUserIndex(draft, Number(id));
    if (parentIdx > -1) {
      const parentVideoOrder = draft.attendeesList[parentIdx].videoOrder;

      const childList = draft.zrUserMap[id];
      childList.forEach((childItem, idx) => {
        childItem.videoOrder = parentVideoOrder + (idx + 1) / 10;
      });
    }
  });

  const flattenZrChildList = Object.keys(draft.zrUserMap).reduce(
    (result, id) => {
      return [...result, ...draft.zrUserMap[id]];
    },
    [],
  );

  draft.attendeesList.forEach((item) => {
    if (!isZRMultiStreamVideoUser(item)) {
      return item;
    }

    if (isZRMultiStreamVideoChildUser(item)) {
      flattenZrChildList.forEach((subitem) => {
        if (item.userId === subitem.userId) {
          item.videoOrder = subitem.videoOrder;
        }
      });
    }

    return item;
  });

  return draft;
};
// -----------
// sort xmpp user order
const sortXmppVideoOrder = (draft) => {
  if (!draft.xmppAllowTalkList.length) {
    return draft;
  }

  let minOrder = -1;
  draft.attendeesList.forEach((item) => {
    minOrder = Math.min(minOrder, item.videoOrder);
  });

  minOrder -= 1;

  draft.xmppAllowTalkList.forEach((item) => {
    item.videoOrder = minOrder;
    minOrder -= 1;
  });
  return draft;
};

// sort video order
const sortVideoOrder = (draft, currentUserId) => {
  let videoOnPointer = 0;
  let videoOffPointer =
    draft.attendeesList.filter((item) => !item.bHold).length - 2;

  draft.attendeesList.forEach((item) => {
    if (!item.bHold) {
      if (item.userId === currentUserId) {
        item.videoOrder = 0.5;
      } else if (item.bVideoOn) {
        item.videoOrder = videoOnPointer;
        videoOnPointer += 1;
      } else {
        item.videoOrder = videoOffPointer;
        videoOffPointer -= 1;
      }
    }

    if (item.bHold) {
      item.videoOrder = 9999;
    }
  });
  return draft;
};

// origin order
export const sortBasicAttendeeList = compose(
  sortZrVideoOrder,
  sortXmppVideoOrder,
  sortVideoOrder,
);

// use for add a new user
const sortNewVideoOrder = (draft, newUser) => {
  let maxOrder = 0;
  draft.attendeesList.forEach((item) => {
    maxOrder = Math.max(maxOrder, item.videoOrder);
  });
  newUser.forEach((item) => {
    const existingNewUser = draft.attendeesList.find(
      (attendee) => attendee.userId === item.userId,
    );
    if (existingNewUser) {
      existingNewUser.videoOrder = maxOrder + 1;
      maxOrder += 1;
    }
  });

  return draft;
};

// origin order with a new user
export const sortNewBasicAttendeeList = compose(
  sortZrVideoOrder,
  sortXmppVideoOrder,
  sortNewVideoOrder,
);

// sort for save gallery order
export function sortOrderBySaveGallery(list, attendees) {
  const attendeeOrderIndexList = attendees
    .map((item) => item.videoOrder)
    .sort((a, b) => a - b);
  const confUserIdMap = new Map();
  attendees.forEach((item) => confUserIdMap.set(item.strConfUserID, true));
  const sortedList = Array.from(
    new Set(list.filter((strConfUserID) => confUserIdMap.get(strConfUserID))),
  );
  for (let index = 0; index < sortedList.length; index++) {
    const videoOrderIndex = attendeeOrderIndexList[index];
    const foundedUser = attendees.find(
      (item) => item.strConfUserID === sortedList[index],
    );
    const oldOrderUser = attendees.find(
      (item) => item.videoOrder === videoOrderIndex,
    );
    if (foundedUser && oldOrderUser) {
      const temp = foundedUser.videoOrder;
      foundedUser.videoOrder = oldOrderUser.videoOrder;
      oldOrderUser.videoOrder = temp;
    }
  }
  return attendees;
}

const GALLERY_ORDER_TYPE = {
  DEFAULT: 0,
  FIRST_NAME_ORDER_ASC: 1,
  FIRST_NAME_ORDER_DES: 2,
  LAST_NAME_ORDER_ASC: 3,
  LAST_NAME_ORDER_DES: 4,
  MEETING_ENTRY_TIME_ORDER_ASC: 5,
  MEETING_ENTRY_TIME_ORDER_DES: 6,
};

// sort Alphabetical video list order
export const sortOrderByAlphabetical = (
  attendeeListDraft,
  galleryOrderType,
) => {
  let sortList = [
    ...attendeeListDraft.attendeesList,
    ...attendeeListDraft.xmppAllowTalkList,
  ];
  switch (galleryOrderType) {
    case GALLERY_ORDER_TYPE.DEFAULT:
      // not reorder
      break;
    case GALLERY_ORDER_TYPE.FIRST_NAME_ORDER_ASC:
      sortList = sortList.sort((a, b) => {
        if (a.firstName === '') return 1;
        if (b.firstName === '') return -1;
        return a.firstName.localeCompare(b.firstName);
      });
      break;
    case GALLERY_ORDER_TYPE.FIRST_NAME_ORDER_DES:
      sortList = sortList.sort((a, b) => {
        if (a.firstName === '') return 1;
        if (b.firstName === '') return -1;
        return b.firstName.localeCompare(a.firstName);
      });
      break;
    case GALLERY_ORDER_TYPE.LAST_NAME_ORDER_ASC:
      sortList = sortList.sort((a, b) => {
        if (a.lastName === '') return 1;
        if (b.lastName === '') return -1;
        return a.lastName.localeCompare(b.lastName);
      });
      break;
    case GALLERY_ORDER_TYPE.LAST_NAME_ORDER_DES:
      sortList = sortList.sort((a, b) => {
        if (a.lastName === '') return 1;
        if (b.lastName === '') return -1;
        return b.lastName.localeCompare(a.lastName);
      });
      break;
    case GALLERY_ORDER_TYPE.MEETING_ENTRY_TIME_ORDER_ASC:
      break;
    case GALLERY_ORDER_TYPE.MEETING_ENTRY_TIME_ORDER_DES:
      sortList = sortList.reverse();
      break;
    default:
  }

  sortList.forEach((item, idx) => {
    item.videoOrder = idx;
  });

  return attendeeListDraft;
};

/*
  put user to fixed position
  such as self always
  userRuleList =[
    {
      userId
      position: 1,2///
    }
  ]
*/
export const sortUserToSpeaclOrder = (attendeeListDraft, userRuleList) => {
  let list = [
    ...attendeeListDraft.attendeesList,
    ...attendeeListDraft.xmppAllowTalkList,
  ];

  userRuleList.forEach((userRule) => {
    list.forEach((item) => {
      if (item.userId === userRule.userId) {
        //video order -0.5 -0.1 0 1 2 3 4 5 6 7
        if (userRule.position === 1) {
          item.videoOrder = -0.5;
        }
        if (userRule.position === 2) {
          item.videoOrder = -0.1;
        }
      }
    });
  });
};
