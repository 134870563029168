import { USER_STATUS_FAILOVER } from '../../constants/Constants';
import { getMeetingChannelId, isEnablePMC } from '../../global/service';
import { CHAT_CONSTANTS, SOCKET_NAME_ENUM } from '../../global/constant';
import { pushSysInfo } from '../new-chat/redux/new-chat-action';
import { generateUUID } from '../../global/service/generate-uuid';
// import { decodeBase64 } from '../../global/util';
export default {
  [SOCKET_NAME_ENUM.COMMAND]: {
    // ['userJoin'](store, message) {
    //   const { dispatch } = store;

    //   if (!_.isEmpty(message.body.add)) {
    //     const userList = message.body.add;
    //     for (let i = 0; i < userList.length; i++) {
    //       const user = userList[i];
    //       const info = {
    //         type: 'join',
    //         id: `${generateUUID()}`,
    //         text: `${decodeBase64(user.dn2)} join`,
    //       };
    //       dispatch(pushSysInfo(info));
    //     }
    //   }
    // },
    ['userLeave'](store, message) {
      const { getState, dispatch } = store;
      const currentUser = getState().meeting.currentUser;
      if (!isEnablePMC() || !getMeetingChannelId()) return;
      if (currentUser.bid) return; // BO not need this
      const attendeesList = getState().attendeesList.attendeesList;
      if (!_.isEmpty(message.body.remove)) {
        const userList = message.body.remove;
        for (let i = 0; i < userList.length; i++) {
          const user = userList[i];
          const removedAttendee = attendeesList.find(
            (attendee) => attendee.userId === user.id,
          );
          if (removedAttendee) {
            if (
              !(user.nUserStatus && user.nUserStatus === USER_STATUS_FAILOVER)
            ) {
              // console.log(removedAttendee);
              if (removedAttendee.isGuest) {
                const info = {
                  text: `${removedAttendee.displayName} left`,
                  msgId: `${generateUUID()}`,
                  cat: CHAT_CONSTANTS.SYS,
                };
                dispatch(pushSysInfo(info));
              }
            } else {
              // console.log('failover');
            }
          }
        }
      }
    },
  },
};
