import meetingConfig from 'meetingConfig';
import { INTERPRETATION_LANG } from './resource';
import { INTERPRETATION_LANG_TYPES } from './constant';
import {
  AUDIO_CC_SELECT_LANGUAGE,
  AUDIO_INTERPRETATION_ENABLE,
  AUDIO_INTERPRETATION_LIST_INFO,
  AUDIO_INTERPRETATION_MUTE,
  AUDIO_INTERPRETATION_SELECT_LANGUAGE,
} from '../audio/enum';
import { globalVariable } from '../../global/global-variable';

export const getLanguageDisplayName = (type) => {
  return INTERPRETATION_LANG[`INTERPRETATION_LANG${type}`];
};

export const getSignLanguageDisplayName = (type) => {
  const lang = meetingConfig.signInterpreterInfo?.langList?.find(
    (lang) => lang.langId === type,
  );
  if (lang) {
    return lang.name;
  }

  return '';
};

export const isOriginalAudio = (type) => {
  return type === INTERPRETATION_LANG_TYPES.ORIGINAL;
};

export const isAudioLanguageInterpreter = ({
  isInterpreter,
  activeInterpretationLanguage,
}) => {
  return (
    isInterpreter &&
    typeof activeInterpretationLanguage !== 'undefined' &&
    activeInterpretationLanguage !== INTERPRETATION_LANG_TYPES.ORIGINAL
  );
};

export const getInterpretationLanguageIconText = (
  languages,
  interpretationLanguage,
) => {
  const targetLang = languages.find(
    (language) => language.type === interpretationLanguage,
  );

  if (targetLang) {
    return targetLang.iconText;
  }

  return '';
};

export const getInterpretationInfoById = (id) => {
  const langList = meetingConfig.languageInterpretation || [];
  return langList.find((lang) => lang.id === id);
};

export const getSignLanguageInterpretationInfoById = (id) => {
  const signLangList = meetingConfig.signInterpreterInfo?.langList || [];
  return signLangList.find((lang) => lang.langId === id);
};

export const notifySdkToEnableInterpretation = () => {
  globalVariable.avSocket.sendSocket(AUDIO_INTERPRETATION_ENABLE, {
    enable: true,
  });
};

export const notifySdkToDisableInterpretation = () => {
  globalVariable.avSocket.sendSocket(AUDIO_INTERPRETATION_ENABLE, {
    enable: false,
  });
};

export const notifySdkToMuteOrUnmuteOriginalAudio = (mute) => {
  globalVariable.avSocket.sendSocket(AUDIO_INTERPRETATION_MUTE, {
    mute,
  });
};

export const notifySdkToSelectLanguageChannel = (lang) => {
  globalVariable.avSocket.sendSocket(AUDIO_INTERPRETATION_SELECT_LANGUAGE, {
    lang,
  });
};

export const notifySdkToSelectLanguageChannelForNewLTT = (lang) => {
  if (globalVariable.avSocket?.sendSocket) {
    globalVariable.avSocket.sendSocket(AUDIO_CC_SELECT_LANGUAGE, {
      lang: isOriginalAudio(lang) ? 10 : lang,
    });
  }
};

export const notifySdkToUpdateInterpreterList = (list = []) => {
  globalVariable.avSocket.sendSocket(AUDIO_INTERPRETATION_LIST_INFO, {
    interpreterList: list,
  });
};
