import { decodeBase64, encodeBase64, iText } from '../../../global/util';
import {
  CHAT_MSG_TYPE_TO_SILENT_MODE_USERS,
  CHAT_MSG_TYPE_TO_INDIVIDUAL,
  CHAT_CMD_MODIFY,
  CHAT_FILE_UPLOAD_STATUS,
  CHAT_FILE_DOWNLOAD_STATUS,
} from '../../chat/constants';
import { beginDecrypt, beginEncrypt, ivType } from '../../../global/crypto';
import { extractMsgFromXml } from '../transform/cover';
import {
  buildEditedMsgXml,
  buildDeleteMsgXml,
} from '../transform/buildSendXmppMsgData';
import { errorLog } from '../../../global/web-client-logger';
import { findChatItemIndex } from './buildListMeetingChat';
import { deleteNewChat, pushSysInfo } from './new-chat-action';
import { CHAT_CONSTANTS } from '../../../global/constant';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { WS_CONF_CHAT_CMD_REQ } from '../../../constants/ZoomSocketEventTypes';
import { generateLocalMsgId, getXmppMsgFromToSenderNameMid } from '../help';
import {
  DeleteChatContent,
  DeleteChatTitleTxt,
  YouDeleteMsgTxt,
} from '../resource';
import Modal from '../../../global/components/widget/modal';
import { DIALOG_CANCEL } from '../../dialog/resource';
import { stopUploadNewChatFileRequest } from './new-chat-file-thunk';
import { deleteMessageDialogRef } from '../../dialog/service/command-dialog';
import { updateEditedChatMessage } from './new-chat-core-thunk';
import { generateUUID } from '../../../global/service/generate-uuid';

export function onReceiveEditChat({ msgID, xmppMsgData, sn: senderSN }) {
  return (dispatch, getState) => {
    const {
      newChat: { meetingChat },
      // meeting: { currentUser },
    } = getState();
    // let index, groupIndex;
    // let hasFound = false;
    // for (index = 0; index < meetingChat.length; index++) {
    //   const { chatMsgs } = meetingChat[index];
    //   for (groupIndex = 0; groupIndex < chatMsgs.length; groupIndex++) {
    //     const message = chatMsgs[groupIndex];
    //     if (message.xmppMsgId === msgID) {
    //       hasFound = true;
    //       break;
    //     }
    //   }
    //   if (hasFound) break;
    // }
    const { hasFound, index } = findChatItemIndex(msgID, meetingChat);
    if (!hasFound) return;
    const updateChatState = ({
      text,
      timeStamp,
      rtbItems,
      atUsers,
      ...rest
    }) => {
      dispatch(
        updateEditedChatMessage(
          {
            text,
            xmppMsgId: rest.targetXmppMsgId,
            timeStamp: timeStamp,
            rtbItems,
            atUsers,
          },
          {
            foundedIndex: index,
          },
        ),
      );
    };
    const chatItem = meetingChat[index];
    const isWaitingRoomChat =
      chatItem.receiverId === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS ||
      chatItem.receiverId === CHAT_MSG_TYPE_TO_INDIVIDUAL ||
      chatItem.isSilentModeUser;
    if (isWaitingRoomChat) {
      return extractMsgFromXml(decodeBase64(xmppMsgData))
        .then(updateChatState)
        .catch(errorLog);
    }

    const sn = senderSN || chatItem.senderSN || chatItem.chatSender?.zoomID;

    if (!sn) return;
    beginDecrypt({
      decryptedText: xmppMsgData,
      type: ivType.RWG_CHAT,
      userId: sn,
    })
      .then(({ message: xmlStr }) => {
        return extractMsgFromXml(xmlStr);
      })
      .then(updateChatState)
      .catch(errorLog);
  };
}
export function sendEditChatCmd(
  { styleItems, text, editedMsgId: xmppMsgId, msgT, mention },
  { destNodeID, attendeeNodeID, isSilentModeUser, receiverId },
) {
  // callbackFn,
  return (dispatch, getState) => {
    // console.log(text, xmppMsgId);
    const {
      meeting: { currentUser },
    } = getState();
    const id = generateLocalMsgId(currentUser);

    const isUnEncryptedMsg =
      isSilentModeUser ||
      receiverId === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS ||
      receiverId === CHAT_MSG_TYPE_TO_INDIVIDUAL;
    buildEditedMsgXml({
      id,
      // from: '',
      // to: '',
      msgT,
      xmppMsgId,
      text,
      rtbItems: { item: styleItems },
      // senderName: currentUser.displayName,
      ...getXmppMsgFromToSenderNameMid(
        currentUser.strConfUserID,
        currentUser.displayName,
      ),
    })
      .then((xml) => {
        return Promise.all(
          isUnEncryptedMsg
            ? [
                Promise.resolve(encodeBase64(text)),
                Promise.resolve(encodeBase64(xml)),
              ]
            : [
                beginEncrypt({ type: ivType.RWG_CHAT, text: `${text}` }),
                beginEncrypt({ type: ivType.RWG_CHAT, text: xml }),
              ],
        );
      })
      .then(([old, xmppMsgData]) => {
        dispatch(
          sendSocketMessage({
            evt: WS_CONF_CHAT_CMD_REQ,
            body: {
              xmppMsgData,
              editContent: old,
              destNodeID, //: currentUser.userId,
              attendeeNodeID,
              msgID: xmppMsgId,
              cmd: CHAT_CMD_MODIFY,
            },
          }),
        );
      })
      .then(() => {
        dispatch(
          updateEditedChatMessage(
            {
              text,
              // msgId,
              xmppMsgId,
              rtbItems: { item: styleItems, mention },
            },
            {
              isGetPatches: true,
            },
          ),
        );
        // callbackFn();
      });
  };
}

const deletedChatRemark = new Map();

export function confirmDeleteMsg(data, { xmppMsgId, t, threadT, threadId }) {
  return (dispatch) => {
    Modal.confirm({
      className: 'zm-modal-legacy',
      okButtonProps: {
        type: 'error',
      },
      okText: iText('Delete', 'apac.common.delete'),
      cancelText: DIALOG_CANCEL,
      cancelButtonProps: {},
      contentLabel: 'delete message dialog',
      okCancel: true,
      title: DeleteChatTitleTxt,
      content: DeleteChatContent,
      ref: deleteMessageDialogRef,
      rModalProps: {
        shouldCloseOnOverlayClick: false,
        shouldReturnFocusAfterClose: true,
        shouldFocusAfterRender: true,
      },
      onAfterClose: () => {
        document.getElementById('chat-list-content')?.focus();
      },
      onOk: () => {
        dispatch(sendDeleteChatCmd(data, { xmppMsgId, t, threadT, threadId }));
        deleteMessageDialogRef.current.close();
      },
      onCancel() {
        deleteMessageDialogRef.current.close();
      },
    });
  };
}
export function sendDeleteChatCmd(params, { xmppMsgId, t, threadT, threadId }) {
  return (dispatch, getState) => {
    const { file, ...data } = params;
    if (file) {
      const { downloadCancelToken, uploadStatus, downloadStatus } = file;
      if (uploadStatus === CHAT_FILE_UPLOAD_STATUS.PENDING) {
        dispatch(stopUploadNewChatFileRequest(file));
        return;
      }
      if (downloadStatus === CHAT_FILE_DOWNLOAD_STATUS.DOWNLOADING) {
        if (downloadCancelToken) {
          downloadCancelToken();
        }
      }
    }
    const { currentUser } = getState().meeting;
    const id = generateLocalMsgId(currentUser);
    buildDeleteMsgXml({
      id,
      ...getXmppMsgFromToSenderNameMid(
        currentUser.strConfUserID,
        currentUser.displayName,
      ),
      xmppMsgId,
      t,
      threadT: threadT || t,
      threadId: threadId || xmppMsgId,
    })
      .then((xml) => {
        return beginEncrypt({ text: xml, type: ivType.RWG_CHAT });
      })
      .then((xmppMsgData) => {
        data.body.xmppMsgData = xmppMsgData;
        data.body.msgID = xmppMsgId;
        dispatch(sendSocketMessage({ ...data }));
        deletedChatRemark.set(xmppMsgId, true);
      })
      .catch((err) => {
        errorLog(err);
      });
  };
}

export function onDeleteNewChatMsg(msgID) {
  return (dispatch) => {
    /* need deleter's zoomId to decrypt xmppMsgData */
    // const { msgID /* xmppMsgData deleteedOrModified */ } = message.body || {};
    dispatch(deleteNewChat(msgID));

    const isSelfDeleted = deletedChatRemark.get(msgID);

    if (isSelfDeleted) {
      deletedChatRemark.delete(msgID);
      dispatch(
        pushSysInfo([
          {
            cat: CHAT_CONSTANTS.SYS,
            msgId: `${generateUUID()}`,
            text: YouDeleteMsgTxt,
          },
        ]),
      );
    }

    // if (!xmppMsgData) {
    //   // dispatch(deleteNewChat(msgID));
    // } else {
    //   // extractDeleteInfoFromXml(xmppMsgData).then(({ id }) => {
    //   //   dispatch(deleteNewChat(id));
    //   // });
    // }
  };
}
