/* eslint-disable react/prop-types */
import React from 'react';
import {
  CONNECT_ERROR_MEETING_CONTENT,
  CONNECT_ERROR_MEETING_TITLE,
  CONNECT_ERROR_WEBINAR_CONTENT,
  CONNECT_ERROR_WEBINAR_TITLE,
  DIALOG_RETRY,
  DIALOG_LEAVE,
  REPORT_PROBLEM,
} from '../../resource';
import { REPORT_PARTICIPANT_WINDOW_TITLE } from '../../../security/resource';
import { NetworkType } from '../../../../controller/vendors/tesla/enum';
import { isExternalControlledMode, isTeslaMode } from '../../../../global/util';
import { Button } from '@zoom/zoom-react-ui';
import Modal from '../../../../global/components/widget/modal';
import './ConnectErrorDialog.scss';
import { isWebinar } from '../../../../global/service/meeting-types';

const defaultOkButtonProps = {
  type: 'primary',
  size: 'default',
  className: 'zm-btn-legacy',
};
const defaultCancelButtonProps = {
  type: 'default',
  size: 'default',
  className: 'zm-btn-legacy',
};
const reportProblemButtonStyle = {
  color: '#0956B5',
  border: 'none',
};
const ConnectErrorDialog = (props) => {
  const {
    visible,
    setConnectErrorDialogVisible,
    leaveMeetingThunkAction,
    networkType,
    setReportProblemDialogVisible,
  } = props;
  const onRetry = () => {
    setConnectErrorDialogVisible(false);
    window.location.reload();
  };
  const onExit = () => {
    setConnectErrorDialogVisible(false);
    leaveMeetingThunkAction();
  };
  const isWB = isWebinar();
  const footer =
    networkType !== NetworkType.NONE ? (
      <div className="connect-dialog-footer">
        <Button
          style={reportProblemButtonStyle}
          onClick={() => setReportProblemDialogVisible(true)}
        >
          {REPORT_PROBLEM}
        </Button>
        <Button onClick={onRetry} {...defaultOkButtonProps}>
          {DIALOG_RETRY}
        </Button>
        <Button onClick={onExit} {...defaultCancelButtonProps}>
          {DIALOG_LEAVE}
        </Button>
      </div>
    ) : (
      <div className="connect-dialog-footer">
        <Button
          {...defaultOkButtonProps}
          onClick={() => setReportProblemDialogVisible(true)}
        >
          {REPORT_PARTICIPANT_WINDOW_TITLE}
        </Button>
        <Button onClick={onExit} {...defaultCancelButtonProps}>
          {DIALOG_LEAVE}
        </Button>
      </div>
    );

  return (
    <Modal
      className="zm-modal-legacy"
      contentLabel="Meeting connected timeout."
      visible={visible}
      title={isWB ? CONNECT_ERROR_WEBINAR_TITLE : CONNECT_ERROR_MEETING_TITLE}
      showClose={false}
      isAutoTimeout={isExternalControlledMode() && !isTeslaMode()}
      onTimeout={onExit}
      footer={footer}
    >
      <div className="content">
        {isWB ? CONNECT_ERROR_WEBINAR_CONTENT : CONNECT_ERROR_MEETING_CONTENT}
      </div>
    </Modal>
  );
};

export default ConnectErrorDialog;

ConnectErrorDialog.defaultProps = {
  networkType: '',
  leaveMeetingThunkAction: () => {},
};

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/features/dialog/components/connect-error-dialog/ConnectErrorDialog.scss';
