import { createActions } from 'redux-actions';

const SET_MASK_TOOL_IDX = 'SET_MASK_TOOL_IDX';
const SET_MASK_SCALE = 'SET_MASK_SCALE';
const SET_MASK_POSITION = 'SET_MASK_POSITION';
const SET_MASK_SETTING_VIDEO_INIT_SUCCESS =
  'SET_MASK_SETTING_VIDEO_INIT_SUCCESS';
const SETTINGS_PANE_RESET = 'SETTINGS_PANE_RESET';
const SHOW_SETTINGS_DIALOG = 'SHOW_SETTINGS_DIALOG';
const SET_CURRENT_SETTINGS_TAB = 'SET_CURRENT_SETTINGS_TAB';
const SET_CURRENT_USER_SKIN_TONE = 'SET_CURRENT_USER_SKIN_TONE';
const SET_SHOW_REACTION_TOOL_BAR = 'SET_SHOW_REACTION_TOOL_BAR';
const SET_CLOUD_VB_SETTINGS = 'SET_CLOUD_VB_SETTINGS';
const SET_MASK_RATO = 'SET_MASK_RATO';
const SET_SHOW_USER_PROFILE_ICON = 'SET_SHOW_USER_PROFILE_ICON';
const AUDIO_DATA_ENCODING = 'AUDIO_DATA_ENCODING';
const AUDIO_DATA_DECODING = 'AUDIO_DATA_DECODING';
const VIDEO_DATA_ENCODING = 'VIDEO_DATA_ENCODING';
const VIDEO_DATA_DECODING = 'VIDEO_DATA_DECODING';
const SHARE_DATA_ENCODING = 'SHARE_DATA_ENCODING ';
const SHARE_DATA_DECODING = 'SHARE_DATA_DECODING ';

const SET_VB_SETTING_VIDEO_INIT_SUCCESS = 'SET_VB_SETTING_VIDEO_INIT_SUCCESS';

const SET_IS_VB_PRE_READY = 'SET_IS_VB_PRE_READY';

const SET_BACKGROUND_LIST = 'SET_BACKGROUND_LIST';

const SET_BACKGROUND_IMAGE = 'SET_BACKGROUND_IMAGE';

const SET_IS_VB_MODE = 'SET_IS_VB_MODE';

const SET_MASK_LIST = 'SET_MASK_LIST';

const SET_SHOW_VB_INIT_TYPE = 'SET_SHOW_VB_INIT_TYPE';

const SET_VIDEO_QUALITY_VISIBILITY = 'SET_VIDEO_QUALITY_VISIBILITY';
const SET_SHOW_MEETING_ANIMATED_REACTION = 'SET_SHOW_MEETING_ANIMATED_REACTION';

export const {
  setMaskPosition,
  setMaskSettingVideoInitSuccess,
  settingsPaneReset,
  setIsHideSettingsMaskTips,
  setCurrentSettingsTab,
  showSettingsDialog,
  setCurrentUserSkinTone,
  setShowReactionToolBar,
  setCloudVbSettings,
  setShowUserProfileIcon,
  audioDataEncoding,
  audioDataDecoding,
  videoDataEncoding,
  videoDataDecoding,
  shareDataEncoding,
  shareDataDecoding,
  setVbSettingVideoInitSuccess,
  setIsVbPreReady,
  setBackgroundList,
  setBackgroundImage,
  setShowVbInitType,
  setVideoQualityVisibility,
  setShowMeetingAnimatedReaction,
} = createActions({
  [SET_MASK_TOOL_IDX]: (payload) => payload,
  [SET_MASK_SCALE]: (payload) => payload,
  [SET_MASK_POSITION]: (payload) => payload,
  [SET_MASK_SETTING_VIDEO_INIT_SUCCESS]: (payload) => payload,
  [SETTINGS_PANE_RESET]: (payload) => payload,
  [SHOW_SETTINGS_DIALOG]: (payload) => payload,
  [SET_CURRENT_SETTINGS_TAB]: (payload) => payload,
  [SET_CURRENT_USER_SKIN_TONE]: (payload) => payload,
  [SET_SHOW_REACTION_TOOL_BAR]: (payload) => payload,
  [SET_CLOUD_VB_SETTINGS]: (payload) => payload,
  [SET_MASK_RATO]: (payload) => payload,
  [SET_SHOW_USER_PROFILE_ICON]: (payload) => payload,
  [AUDIO_DATA_ENCODING]: (payload) => payload,
  [AUDIO_DATA_DECODING]: (payload) => payload,
  [VIDEO_DATA_ENCODING]: (payload) => payload,
  [VIDEO_DATA_DECODING]: (payload) => payload,
  [SHARE_DATA_ENCODING]: (payload) => payload,
  [SHARE_DATA_DECODING]: (payload) => payload,
  [SET_VB_SETTING_VIDEO_INIT_SUCCESS]: (payload) => payload,
  [SET_IS_VB_PRE_READY]: (payload) => payload,
  [SET_BACKGROUND_LIST]: (payload) => payload,
  [SET_BACKGROUND_IMAGE]: (payload) => payload,
  [SET_IS_VB_MODE]: (payload) => payload,
  [SET_MASK_LIST]: (payload) => payload,
  [SET_SHOW_VB_INIT_TYPE]: (payload) => payload,
  [SET_VIDEO_QUALITY_VISIBILITY]: (payload) => payload,
  [SET_SHOW_MEETING_ANIMATED_REACTION]: (payload) => payload,
});
