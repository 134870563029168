import zlib from 'browserify-zlib';
import {
  ATTENDEE_STATUS,
  batchCreateRoomsConstants,
  BO_ROOM_STATUS,
} from '../constant';
import {
  clearUserJoinBoInfoInStorage,
  isMeShouldJoinBo,
  setUserJoinBoInfoIntoStorage,
  changeRoomDataFromServerToLocal,
  setBCoHostStorage,
  clearBCoHostInStorage,
  isInRoom,
} from '../utils';
import {
  setAttendeeRoomInfo,
  setAttendeeRoomStatus,
  setIsHostBeforeJoinBo,
  setWaitingRoomTipVisible,
  setBoFactorMeetingStartTime,
  setInviteJoinBoRoomDialogVisible,
  addRooms,
  setPreRoomSize,
  batchUpdateRoomSizeList,
  setJoinBoConfId,
  setBoLoading,
  addPreAssignmentToAssignDict,
  setBoRoomsHasCreated,
  setHasCheckedFailoverAttendeeBoStatus,
  setMainSessionConfId,
} from './bo-room-action';
import { invokeBoJoinInterceptor } from './bo-room-thunk-aciton';
import {
  WS_CONF_ROSTER_INDICATION,
  UPDATE_CURRENT_USER,
  WS_CONF_ASSIGN_CC_REQ,
} from '../../../constants/MeetingActionTypes';
import { encodeUnsafeBase64 } from '../../../global/util';
import {
  CONF_FAIL_MEETING_HAS_CLOSED,
  CONF_SUCCESS,
  CONF_FAIL_HAS_BEEN_REMOVED,
} from '../../../constants/ErrorTypes';
import { wcToast } from '../../../global/components/widget/toast/wc-toast';
import { CONF_ENDED_MEETING_TITLE } from '../../dialog/resource';
import { SESSIONSTORAGE_KEYS } from '../../../global';
import {
  setConfAttributeIndication,
  hideLoading,
  joinMeetingFailed,
} from '../../../actions/MeetingActions';
import { getDraftRoom, removeDraftRoom, getDraftRoomSize } from '../cache';
import { addRoomDebounce } from '../a11y/utils';
import { setReconnectDialogVisible } from '../../dialog/redux/dialog-action';
import { closeSocket } from '../../../actions/SocketActions';
import { globalVariable } from '../../../global/global-variable';
import {
  startToViewMainSesionSharingInBo,
  prepareForReceiveSharing,
  changeLockShare,
} from '../../sharing/redux/sharing-thunk-action';
import { isBrowserSupportReceiveFromMainSession } from '../../sharing/util';
import * as AVNotifyMediaSDKTypes from '../../../constants/AVNotifyMediaSDKTypes';
import {
  alertUnableToViewShareFromMainSessionTip,
  closeUnableToViewShareFromMainSessionTip,
} from '../../sharing/service';
import * as meetingActions from '../../../actions/MeetingActions';
import { isShowOptionAutoMoveToMainSessionSelector } from './bo-room-selector';
import { leaveMeetingThunkAction } from '../../../global/redux/thunk-action/end-meeting-request';

export function getStartBoRequestBodyProto(state, controlStatus, boRoomStatus) {
  const {
    breakoutRoom: {
      roomList,
      roomNextId,
      options: {
        autoJoin,
        backToMainSession,
        autoCloseTimer,
        timerDuration,
        timerAutoEnd,
        needCountDown,
        waitseconds,
        participantsChooseRoom,
        isAutoMovetoMainSessionEnabled,
      },
      boFactorTime: { boRoomStartTimeOnMMR },
      hugeBo,
      preAssignmentEnabled,
    },
    attendeesList: { attendeesList },
  } = state;
  const hostGUID = attendeesList.find((user) => user.isHost)?.userGUID ?? '';

  const isShowOptionAutoMoveToMainSession =
    isShowOptionAutoMoveToMainSessionSelector(state);
  // transform the data to be the format rwg needs
  const roomListForServer = roomList.map((room) => ({
    BID: room.boId,
    MeetingTitle: room.name,
    MeetingToken: room.boToken,
    Status: boRoomStatus,
    HostID: room.hostId,
    ParticipantList: room.attendeeIdList.filter((id) => id !== hostGUID),
  }));
  const boListJson = {
    ControlStatus: controlStatus,
    NameIndex: roomNextId,
    IsAutoJoinEnabled: +autoJoin,
    IsBackToMainSessionEnabled: +backToMainSession,
    IsParticipantsChooseRoomEnabled: +participantsChooseRoom,
    IsTimerEnabled: +autoCloseTimer,
    TimerDuration: timerDuration,
    IsTimerAutoEndEnabled: +timerAutoEnd,
    WaitSeconds: needCountDown ? waitseconds : 0,
    StartTimeOnMMR: boRoomStartTimeOnMMR,
    ItemList: roomListForServer,
    IsPreAssignmentEnabled: +preAssignmentEnabled,
    IsAutoMovetoMainSessionEnabled: isShowOptionAutoMoveToMainSession
      ? isAutoMovetoMainSessionEnabled
      : false,
  };
  const boListJsonString = JSON.stringify(boListJson);
  let proto = encodeUnsafeBase64(boListJsonString);
  // if support huge bo,we need use gzip compression
  if (hugeBo) {
    // base64 unsafe
    proto = zlib.gzipSync(proto).toString('base64');
  }
  return {
    proto,
  };
}

export function getRoomAttendeeIds(unassignedAttendeeList, roomSize, roomNo) {
  const totalUnassignedAttendeeSize = unassignedAttendeeList.length;
  const roomAttendeeList = [];
  for (let i = roomNo; i < totalUnassignedAttendeeSize; i += roomSize) {
    roomAttendeeList.push(unassignedAttendeeList[i].userGUID);
  }
  return roomAttendeeList;
}

export function readyToJoinBo(dispatch, myRoom, zoomId, confID = '') {
  dispatch(setAttendeeRoomInfo(myRoom));
  dispatch(setJoinBoConfId(confID));
  dispatch(setAttendeeRoomStatus(ATTENDEE_STATUS.BE_INVITED));
  setUserJoinBoInfoIntoStorage({
    status: ATTENDEE_STATUS.BE_INVITED,
    boId: myRoom.boId,
  });
}

export function hostReadyToJoinBo(dispatch, myRoom, zoomId, confID = '') {
  dispatch(setIsHostBeforeJoinBo(true));
  dispatch(setAttendeeRoomInfo(myRoom));
  dispatch(setJoinBoConfId(confID));
  setUserJoinBoInfoIntoStorage({
    status: ATTENDEE_STATUS.IN_ROOM,
    boId: myRoom.boId,
  });
}

export function checkFailoverAttendeeBoStatus(dispatch, getState) {
  const {
    meeting: { zoomId, isHost, userGUID },
    breakoutRoom: { roomList, hasCheckedFailoverAttendeeBoStatus },
  } = getState();
  if (hasCheckedFailoverAttendeeBoStatus) {
    return false;
  }
  // when fist receiving the proto message, RWG does't know who is the host, so the roomList is null at this time
  // it is a invalid message here.
  if (roomList.length > 0) {
    dispatch(setHasCheckedFailoverAttendeeBoStatus(true));
  }
  const hasJoinBoStatus = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_Bo_HasJoinBoMeeting,
  );
  if (hasJoinBoStatus) {
    const [attendeeStatus, boId] = hasJoinBoStatus.split(';');
    switch (attendeeStatus) {
      case ATTENDEE_STATUS.BE_INVITED: {
        if (isHost) {
          clearUserJoinBoInfoInStorage();
          return true;
        }
        const myRoom = isMeShouldJoinBo(roomList, userGUID);
        if (myRoom) {
          readyToJoinBo(dispatch, myRoom, zoomId);
          const needNotifyUserJoinBo = easyStore.easyGet(
            SESSIONSTORAGE_KEYS.webClient_Bo_HasReceiveJoinCmd,
          );
          // check if it's the proto message after bcohost indication this time
          // when true we should not pop up the join dialog
          const bCoHost = easyStore.easyGet(
            SESSIONSTORAGE_KEYS.webClient_BCoHost,
          );
          if (needNotifyUserJoinBo && !bCoHost) {
            dispatch(setInviteJoinBoRoomDialogVisible(true));
          }
        }
        break;
      }
      case ATTENDEE_STATUS.IN_ROOM: {
        // update the autoJoin value to be true,and we can join the bo meeting auto
        // when the host in bo refreshes his page,he needs join the bo auto
        if (isHost && roomList.length > 0) {
          const targetRoom = roomList.find((room) => room.boId === boId);
          dispatch(
            invokeBoJoinInterceptor(
              targetRoom || {
                boId, // mock a targetRoom just contains boId if host joins another bo before failover
              },
            ),
          );
          clearUserJoinBoInfoInStorage();
        } else if (roomList.length > 0) {
          const myRoom = isMeShouldJoinBo(roomList, userGUID);
          if (myRoom) {
            readyToJoinBo(dispatch, myRoom, zoomId);
            dispatch(invokeBoJoinInterceptor());
          }
          clearUserJoinBoInfoInStorage();
        }
        break;
      }
      default:
        return false;
    }
    return true;
  }
  return false;
}

export const changeToReturnedStatus = (
  dispatch,
  { myRoom, boId, boToken, zoomId },
  boConfId,
) => {
  dispatch(setAttendeeRoomStatus(ATTENDEE_STATUS.RETURN_MAIN_SESSION));
  // myRoom is the data from RWG,so the boId in myRoom is named BID
  if (myRoom && myRoom.BID === boId) {
    readyToJoinBo(
      dispatch,
      {
        ...changeRoomDataFromServerToLocal(myRoom),
        boToken,
      },
      zoomId,
      boConfId,
    );
  }
};

export const handleMainSessionRes = (message, dispatch) => {
  const {
    body: { res, elapsed, confID },
  } = message;
  if (res === CONF_FAIL_MEETING_HAS_CLOSED) {
    wcToast({ text: CONF_ENDED_MEETING_TITLE, type: 'error' });
    clearUserJoinBoInfoInStorage();
    dispatch(leaveMeetingThunkAction());
  } else if (res === CONF_SUCCESS) {
    dispatch(
      setBoFactorMeetingStartTime({
        elapsed,
        joinBoLocalTime: new Date().getTime() / 1000,
      }),
    );
    dispatch(setMainSessionConfId(confID));
  } else if (res === CONF_FAIL_HAS_BEEN_REMOVED) {
    dispatch(
      setReconnectDialogVisible({
        visible: true,
        errorCode: res,
      }),
    );
    clearUserJoinBoInfoInStorage();
    dispatch(joinMeetingFailed(message));
    dispatch(hideLoading(false));
    dispatch(closeSocket(false));
    if (globalVariable.avSocket?.destroy) {
      globalVariable.avSocket.destroy();
    }
  }
};

export const updateAttendeesList = (data, type, dispatch, state) => {
  const {
    meeting: {
      currentUser: { userId },
    },
    attendeesList: { attendeesList },
    breakoutRoom: { mainSessionAttendeeList },
    video: { hasChangedVideoOrder },
  } = state;
  data.forEach((updateAttendee) => {
    if (
      !_.isUndefined(updateAttendee.role) ||
      !_.isUndefined(updateAttendee.bCoHost) ||
      !_.isUndefined(updateAttendee.bCCEditor) ||
      !_.isUndefined(updateAttendee.bHold)
    ) {
      const exitParticipant = mainSessionAttendeeList.find(
        (item) => item.userId === updateAttendee.id,
      );
      if (exitParticipant) {
        // There is a new user joining the waiting room
        // we need give a tip
        if (updateAttendee.bHold) {
          dispatch(setWaitingRoomTipVisible(true));
          return;
        }

        const attendeeInBO = attendeesList.find(
          (attendee) => attendee.zoomID === exitParticipant.zoomID,
        );
        dispatch(meetingActions.updatebCCEditor(updateAttendee));
        if (attendeeInBO) {
          // for merge attendeeList status with main session. some case
          // main session status is different from bo status, but we need bo status
          // so here delete main session,such as bRaiseHand/bCapsPinMultiVideo/uniqueIndex

          delete updateAttendee.bRaiseHand;
          delete updateAttendee.uniqueIndex;

          delete updateAttendee.bCapsPinMultiVideo;
          // rwg has synced this user options
          // delete updateAttendee.bPrivateChatMsgDisabled;
          dispatch({
            type: WS_CONF_ROSTER_INDICATION,
            message: {
              body: {
                update: [
                  {
                    ...updateAttendee,
                    id: attendeeInBO.userId,
                  },
                ],
                userId,
                hasChangedVideoOrder,
              },
            },
          });
          if (updateAttendee.bCCEditor) {
            dispatch({
              type: WS_CONF_ASSIGN_CC_REQ,
              data: true,
            });
          }
          // update current user
          if (userId === attendeeInBO.userId) {
            let bodyData = {};
            if (type === 1) {
              bodyData = {
                add: [
                  {
                    ...updateAttendee,
                    id: attendeeInBO.userId,
                  },
                ],
              };
            } else {
              bodyData = {
                update: [
                  {
                    ...updateAttendee,
                    id: attendeeInBO.userId,
                  },
                ],
              };
            }
            dispatch({
              type: UPDATE_CURRENT_USER,
              message: {
                body: bodyData,
              },
            });
          }
        }
      }
    }
  });
};

export const handleCoHostUpdateIndication = (bCoHost) => {
  if (bCoHost) {
    setBCoHostStorage();
  } else {
    clearBCoHostInStorage();
  }
};

export const updateMeetingAttrInBo = (dispatch, message) => {
  if (
    !_.isUndefined(message.body.bAllowAttendeeRename) ||
    !_.isUndefined(message.body.chatPriviledge) ||
    !_.isUndefined(message.body.confStatus) ||
    !_.isUndefined(message.body.freeNotification) ||
    !_.isUndefined(message.body.freeDuration)
  ) {
    dispatch(setConfAttributeIndication(message, true));
  }

  if (_.has(message, 'body.lockShare')) {
    dispatch(changeLockShare(message.body.lockShare));
  }

  if (message.body.encryptKey) {
    easyStore.setMainSessionKey(message.body.encryptKey);
  }
};

export const isAllAttendeeInBoRoomLeave = ({ status, attendeesList }) => {
  if (isInRoom(status)) {
    return false;
  }
  return attendeesList.length > 0 && attendeesList.every((item) => !item.bid);
};

export const batchCreateRooms = (dispatch, messages) => {
  if (messages.length === 0) {
    return;
  }
  const rooms = [];
  const roomSizeList = [];
  let i = 0;
  const nextToAssignDict = {};
  for (
    let len = Math.min(
      messages.length,
      batchCreateRoomsConstants.renderRoomSizeEachBatch,
    );
    i < len;
    i++
  ) {
    const { bid, seq } = messages[i];
    const draftRoom = getDraftRoom(seq);
    if (draftRoom) {
      rooms.push({
        boId: bid,
        name: draftRoom.name,
        boToken: '', // this api will not return the botoken any more
        boStatus: BO_ROOM_STATUS.GOT_TOKEN,
        hostId: '',
        attendeeIdList: draftRoom.attendeeIdList,
      });
      roomSizeList.push({
        boId: bid,
        size: draftRoom.attendeeIdList.length,
      });
      if (draftRoom.emails) {
        draftRoom.emails.forEach((email) => {
          nextToAssignDict[email] = bid;
        });
      }
      addRoomDebounce(draftRoom.name);
      removeDraftRoom(seq);
    }
  }
  dispatch(addPreAssignmentToAssignDict(nextToAssignDict));
  dispatch(addRooms(rooms));
  dispatch(batchUpdateRoomSizeList(roomSizeList));
  if (getDraftRoomSize() === 0) {
    dispatch(setBoRoomsHasCreated(true));
    dispatch(setPreRoomSize(-1));
    dispatch(setBoLoading(false));
  } else {
    setTimeout(() => {
      batchCreateRooms(dispatch, messages.slice(i));
    }, 0);
  }
};

export const handleSharingFromMainSession =
  (message) => (dispatch, getState) => {
    const {
      body: { bStatus },
    } = message;
    dispatch(startToViewMainSesionSharingInBo(message));
    let msg = message;
    if (bStatus === 0) {
      // check if there is any active sharing in the same bo which is from the old client
      // if true, we should subscribe the old client's sharing
      const {
        attendeesList: { attendeesList },
        meeting: {
          currentUser: { userId },
        },
      } = getState();
      // not subscribe myself
      const oldClientSharerList = attendeesList.filter(
        (attendee) => attendee.sharerOn && attendee.userId !== userId,
      );
      if (oldClientSharerList.length > 0) {
        const oldClientSharer = oldClientSharerList[0];
        msg = {
          body: {
            activeNodeID: oldClientSharer.userId,
            bStatus: 1,
            ssrc: oldClientSharer.shareSsrc,
          },
        };
      }
    }
    // block old chrome to show receive sharing UI
    if (isBrowserSupportReceiveFromMainSession()) {
      dispatch(prepareForReceiveSharing(msg, true));
    } else if (bStatus === 1) {
      alertUnableToViewShareFromMainSessionTip();
    } else {
      dispatch(prepareForReceiveSharing(msg, true));
      closeUnableToViewShareFromMainSessionTip();
    }
  };

export const startToBuildMSChannel = () => (dispatch, getState) => {
  const {
    meeting: { meetingNumber, conID, svcUrl },
  } = getState();
  const url = `wss://${svcUrl}/wc/media/${meetingNumber}?type=ms&cid=${conID}&mode=1`;
  globalVariable.avSocket.sendSocket(
    AVNotifyMediaSDKTypes.BUILD_MS_CHANNEL_IN_BO,
    url,
  );
};

export const startToBuildMAChannel = () => (dispatch, getState) => {
  const {
    meeting: { meetingNumber, conID, svcUrl },
  } = getState();
  const url = `wss://${svcUrl}/wc/media/${meetingNumber}?type=ma&cid=${conID}&mode=1`;
  globalVariable.avSocket.sendSocket(
    AVNotifyMediaSDKTypes.BUILD_MA_CHANNEL_IN_BO,
    url,
  );
};

export const updateSharingInfoFromMainSession =
  (rosterList) => (dispatch, getState) => {
    const {
      breakoutRoom: { mainSessionAttendeeList },
    } = getState();
    rosterList.forEach((roster) => {
      if (
        typeof roster.bShareToBORooms !== 'undefined' ||
        typeof roster.sharerOn !== 'undefined'
      ) {
        let mockActiveNodeData = {
          activeNodeID: '',
          bStatus: 0,
          ssrc: 0,
        };
        // find there is any user sharing to bo in main session or not
        const sharerListFromMainSessionToBo = mainSessionAttendeeList.filter(
          (user) =>
            user.sharerOn &&
            user.bShareToBORooms &&
            user.userId !== roster.userId,
        );
        const isHasSomeOneSharingToBo =
          sharerListFromMainSessionToBo.length > 0;
        if (roster.bShareToBORooms && roster.sharerOn) {
          if (!isHasSomeOneSharingToBo) {
            // for no one sharing
            // firstly share to bo,we should prepare to subscribe it(here we mock a WS_SHARING_STATUS_INDICATION)
            mockActiveNodeData = {
              activeNodeID: roster.userId,
              bStatus: 1,
              ssrc: roster.shareSsrc,
            };
          }
        } else if (
          (!roster.bShareToBORooms && roster.sharerOn) ||
          !roster.sharerOn
        ) {
          const allSharerListFromMainSessionToBo =
            mainSessionAttendeeList.filter(
              (user) => user.sharerOn && user.bShareToBORooms,
            );
          const isLastSharerStopSharing =
            allSharerListFromMainSessionToBo.length === 0;
          if (isLastSharerStopSharing) {
            // all sharing has been stoped from main session
            mockActiveNodeData = {
              activeNodeID: roster.userId,
              bStatus: 0,
              ssrc: 0,
            };
          } else if (isHasSomeOneSharingToBo) {
            // choose a new activeNodeId
            // proritize to choose host's sharing
            const sharingHost = sharerListFromMainSessionToBo.find(
              (user) => user.isHost,
            );
            if (sharingHost) {
              mockActiveNodeData = {
                activeNodeID: sharingHost.userId,
                bStatus: 1,
                ssrc: sharingHost.shareSsrc,
              };
            } else {
              // and then choose other co-host's sharing
              mockActiveNodeData = {
                activeNodeID: sharerListFromMainSessionToBo[0].userId,
                bStatus: 1,
                ssrc: sharerListFromMainSessionToBo[0].shareSsrc,
              };
            }
          }
        }
        if (mockActiveNodeData.activeNodeID) {
          dispatch(
            handleSharingFromMainSession({
              body: mockActiveNodeData,
            }),
          );
        }
      }
    });
  };

export const stopSharingFromMainSession =
  (removeList) => (dispatch, getState) => {
    const {
      breakoutRoom: { mainSessionAttendeeList },
      sharing: { shareeCurrentActiveNodeId },
    } = getState();
    removeList.forEach((roster) => {
      const hasTargetUserStopSharingToBO = mainSessionAttendeeList.find(
        (user) =>
          user.sharerOn && user.bShareToBORooms && user.userId === roster.id,
      );
      const isCurrentReceiveSharingStoped =
        shareeCurrentActiveNodeId === roster.id;
      const othersSharingToBO = mainSessionAttendeeList.filter(
        (user) =>
          user.sharerOn && user.bShareToBORooms && user.userId !== roster.id,
      );
      if (!hasTargetUserStopSharingToBO) {
        return false;
      }
      // no one is sharing to bo, so we should stop receiving sharing
      if (othersSharingToBO.length === 0) {
        dispatch(
          handleSharingFromMainSession({
            body: {
              activeNodeID: roster.id,
              bStatus: 0,
              ssrc: 0,
            },
          }),
        );
      } else if (isCurrentReceiveSharingStoped) {
        // and then choose other 's sharing
        dispatch(
          handleSharingFromMainSession({
            body: {
              activeNodeID: othersSharingToBO[0].userId,
              bStatus: 1,
              ssrc: othersSharingToBO[0].shareSsrc,
            },
          }),
        );
      }
      return true;
    });
  };
