/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { slotify } from '../hoc/slotify';
import { blurGuard } from '../hoc/blur-guard';
import {
  GLOBAL_POPOVER_PATTERN_ENUM,
  DOM_KEY_CODE,
  WC_LOCAL_EVT_ENUM,
} from '../../enum';

const PATTERN_CLASS_MAPPING = {
  [GLOBAL_POPOVER_PATTERN_ENUM.PATTERN1]: 'global-popover--pattern1',
  [GLOBAL_POPOVER_PATTERN_ENUM.PATTERN2]: 'global-popover--pattern2',
};

const emptyFn = () => {};

class GlobalPopover extends Component {
  constructor(props, context) {
    super(props, context);
    const { blurNotifier, onClose } = props;
    blurNotifier.on(WC_LOCAL_EVT_ENUM.BLUR, onClose || emptyFn);
  }

  onEsc = (evt) => {
    evt.stopPropagation();
    if (evt.keyCode === DOM_KEY_CODE.ESC) {
      const { onClose } = this.props;
      onClose();
    }
  };

  componentDidMount() {}

  componentWillUnmount() {
    const { blurNotifier, onClose } = this.props;
    blurNotifier.off('blur', onClose || emptyFn);
  }

  getRootClassName = () => {
    const { pattern, popoverCls } = this.props;
    return classnames('global-popover', {
      [PATTERN_CLASS_MAPPING[pattern]]: pattern,
      [popoverCls]: !_.isNil(popoverCls),
    });
  };

  render() {
    const {
      lowerLeftWidgets,
      lowerRightWidgets,
      title,
      mainText,
      provideRootRef,
    } = this.props;

    return (
      <div
        className={this.getRootClassName()}
        ref={provideRootRef}
        tabIndex={0}
        onKeyUp={this.onEsc}
        role="presentation"
        aria-labelledby="global-popover__header"
        aria-describedby="global-popover__body"
      >
        {title && (
          <h3 id="global-popover__header" className="global-popover__header">
            {title}
          </h3>
        )}
        <div
          id="global-popover__body"
          className="global-popover__body"
          dangerouslySetInnerHTML={{ __html: mainText }}
        />
        {(lowerLeftWidgets || lowerRightWidgets) && (
          <div className="global-popover__footer clearfix">
            <div className="global-popover__left-widget-block">
              {lowerLeftWidgets &&
                lowerLeftWidgets.map((widget, index) => (
                  <div key={index} className="global-popover__widget-cell">
                    {widget}
                  </div>
                ))}
            </div>
            <div className="global-popover__right-widget-block">
              {lowerRightWidgets &&
                lowerRightWidgets.map((widget, index) => (
                  <div key={index} className="global-popover__widget-cell">
                    {widget}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

GlobalPopover.propTypes = {
  pattern: PropTypes.number,
  popoverCls: PropTypes.string,
  lowerLeftWidgets: PropTypes.arrayOf(PropTypes.element),
  lowerRightWidgets: PropTypes.arrayOf(PropTypes.element),
  title: PropTypes.string,
  mainText: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

GlobalPopover.defaultProps = {
  pattern: 0,
  popoverCls: '',
  lowerLeftWidgets: null,
  lowerRightWidgets: null,
  title: '',
  onClose() {},
};

/* eslint-disable-next-line no-class-assign */
GlobalPopover = blurGuard(slotify(GlobalPopover));

export { GlobalPopover };

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/components/widget/global-popover.scss';
