/* eslint-disable react/prop-types */
import React from 'react';

function AskerName({ name }) {
  return (
    <div className="lt-full-transcript__askername">
      {name}
      &nbsp;
    </div>
  );
}

export default AskerName;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/features/live-transcription/components/live-transcript-asker-name.scss';
