import { sendSocketMessage } from '../../../../actions/SocketActions';
import * as socketEventTypes from '../../../../constants/ZoomSocketEventTypes';
import * as userTypes from '../../../../constants/UserRoles';
import {
  ATTENDEE_VIDEO_MODE,
  VIDEO_LAYOUT,
  VIDEO_LAYOUT_STANDARD_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW,
  ATTENDEE_SEND_FLAG,
  VIDEO_LAYOUT_SPEAK_VIEW,
  VIDEO_LAYOUT_GALLERY_VIEW,
} from '../../enum';
import { setAttendeeLayout, setVideoLayout } from '../video-action';
import { SHARER_STATUS_ENUM } from '../../../sharing/enum';
import { isTeslaMode, isGoogleMeetMode } from '../../../../global/util';
import { isOpOpenGoogleMeetMultiViewFlag } from '../../../../global/op-feature-option';

function attendeeViewFlagThunk(flag) {
  return (dispatch) => {
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_ATTENDEE_VIDEO_LAYOUT_MODE_CHANGE_REQ,
        body: {
          AttendeeVideoLayoutFlag: flag,
        },
      }),
    );
  };
}

function attendeeViewThunk(layout) {
  return (dispatch) => {
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_ATTENDEE_VIDEO_LAYOUT_MODE_CHANGE_REQ,
        body: {
          AttendeeVideoLayoutMode: layout,
        },
      }),
    );
  };
}

export function isHost(role) {
  return (role & userTypes.USER_ROLE_HOST) === userTypes.USER_ROLE_HOST;
}

// google meet Intercept for special handling
// op switch open-> VIDEO_LAYOUT_GALLERY_VIEW
// op switch close->VIDEO_LAYOUT_SPEAK_VIEW
// receive share -> VIDEO_LAYOUT_STANDARD_VIEW
// ignore webinar follow host view
export function setGoogleMeetLayoutThunk(layout) {
  return (dispatch, getState) => {
    const {
      video: {
        UI: { videoLayout },
      },
    } = getState();

    // next layout -> share view(VIDEO_LAYOUT_STANDARD_VIEW,VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW,VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW)
    // change -> VIDEO_LAYOUT_STANDARD_VIEW
    if (
      (layout === VIDEO_LAYOUT_STANDARD_VIEW ||
        layout === VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW ||
        layout === VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW) &&
      videoLayout !== VIDEO_LAYOUT_STANDARD_VIEW
    ) {
      dispatch(setVideoLayout(VIDEO_LAYOUT_STANDARD_VIEW));
      return;
    }

    if (isOpOpenGoogleMeetMultiViewFlag()) {
      // next layout -> no share view(VIDEO_LAYOUT_SPEAK_VIEW,VIDEO_LAYOUT_GALLERY_VIEW)
      // change -> VIDEO_LAYOUT_GALLERY_VIEW
      if (
        (layout === VIDEO_LAYOUT_SPEAK_VIEW ||
          layout === VIDEO_LAYOUT_GALLERY_VIEW) &&
        videoLayout !== VIDEO_LAYOUT_GALLERY_VIEW
      ) {
        dispatch(setVideoLayout(VIDEO_LAYOUT_GALLERY_VIEW));
      }
    } else {
      // next layout -> no share view(VIDEO_LAYOUT_SPEAK_VIEW,VIDEO_LAYOUT_GALLERY_VIEW)
      // change -> VIDEO_LAYOUT_SPEAK_VIEW
      if (
        (layout === VIDEO_LAYOUT_SPEAK_VIEW ||
          layout === VIDEO_LAYOUT_GALLERY_VIEW) &&
        videoLayout !== VIDEO_LAYOUT_SPEAK_VIEW
      ) {
        dispatch(setVideoLayout(VIDEO_LAYOUT_SPEAK_VIEW));
      }
    }
    return;
  };
}

/*
 for host check layout broadcast to meeting
*/
export function hostBroadcastLayoutThunk(layout) {
  return (dispatch, getState) => {
    const {
      sharing: { sharerStatus },
    } = getState();
    const isSendShare = sharerStatus !== SHARER_STATUS_ENUM.ED;

    if (layout === VIDEO_LAYOUT_STANDARD_VIEW) {
      dispatch(attendeeViewFlagThunk(ATTENDEE_SEND_FLAG.FOLLOW_STANDARD));
    }

    if (layout === VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW) {
      dispatch(attendeeViewFlagThunk(ATTENDEE_SEND_FLAG.FOLLOW_SIDE_BY_SIDE));
      dispatch(attendeeViewThunk(ATTENDEE_VIDEO_MODE.GALLERY));
      dispatch(attendeeViewThunk(ATTENDEE_VIDEO_MODE.ACTIVE));
    } else if (layout === VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW) {
      dispatch(attendeeViewFlagThunk(ATTENDEE_SEND_FLAG.FOLLOW_SIDE_BY_SIDE));
      dispatch(attendeeViewThunk(ATTENDEE_VIDEO_MODE.ACTIVE));
      dispatch(attendeeViewThunk(ATTENDEE_VIDEO_MODE.GALLERY));
    } else {
      if (isSendShare) {
        dispatch(attendeeViewFlagThunk(ATTENDEE_SEND_FLAG.FOLLOW_SIDE_BY_SIDE));
      }
      dispatch(attendeeViewThunk(VIDEO_LAYOUT[layout]));
    }
    dispatch(setAttendeeLayout(VIDEO_LAYOUT[layout]));
  };
}

export function setVideoLayoutThunkImpl(layout) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userRole },
      },
    } = getState();
    // google meet Intercept for special handling
    // op switch open-> VIDEO_LAYOUT_GALLERY_VIEW
    // op switch close->VIDEO_LAYOUT_SPEAK_VIEW
    // receive share -> VIDEO_LAYOUT_STANDARD_VIEW
    // ignore webinar follow host view
    if (isGoogleMeetMode()) {
      dispatch(setGoogleMeetLayoutThunk(layout));
      return;
    }

    if (
      !isTeslaMode() ||
      (isTeslaMode() &&
        (layout === VIDEO_LAYOUT_STANDARD_VIEW ||
          layout === VIDEO_LAYOUT_SPEAK_VIEW))
    ) {
      dispatch(setVideoLayout(layout));
    }

    if (isHost(userRole) && !isTeslaMode()) {
      dispatch(hostBroadcastLayoutThunk(layout));
    }
  };
}
