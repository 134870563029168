import { UserEntity } from '@zoom/zdt_events/dist/entities/meetings/User';

const userEntity = new UserEntity();

const getUserGuid = (state) => {
  return state?.meeting?.currentUser?.userGUID ?? 'unknown';
};

const getCurrentAccountId = (state) => {
  return state?.meeting?.currentUser?.aid ?? 'unknown';
};
export const makeUserEntity = (state) => {
  userEntity.userId = getUserGuid(state);
  userEntity.accountId = getCurrentAccountId(state);
  return userEntity;
};
