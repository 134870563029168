import React from 'react';
import {
  UNKNOWN_ERROR,
  UNKNOWN_ERROR_ACTION,
  UNKNOWN_ERROR_BTN,
} from '../../../resource';
import ZoomButton from '../../../components/widget/button/zoom-button';

const DefaultFallback = () => {
  const style = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#fff',
    background: '#222',
  };

  return (
    <div style={style}>
      <h1>{UNKNOWN_ERROR}</h1>
      <p style={{ marginTop: '24px' }}>{UNKNOWN_ERROR_ACTION}</p>
      <ZoomButton
        onClick={() => {
          window.location.reload();
        }}
        ariaLabel={UNKNOWN_ERROR_ACTION}
        type="primary"
        style={{ marginTop: '48px', border: 'none', width: '140px' }}
      >
        {UNKNOWN_ERROR_BTN}
      </ZoomButton>
    </div>
  );
};

export default DefaultFallback;
