import React from 'react';
import { cancelToLeave } from '../../../global/redux/thunk-action';
import { iText } from '../../../global/util';
import { SESSIONSTORAGE_KEYS } from '../../../global/constant';
import {
  archiveSettingLearnMore,
  Link2,
  privacyNotificationUrl,
  WcLink,
} from '../../../components/utils/link2';
import { transformStringToElement } from '../../../components/utils/transform-string-to-element';
import { Paragraph } from '../../../components/utils/paragraph';
import { GOT_IT } from '../../recording/resource';
import { DIALOG_LEAVE_MEETING, DIALOG_OK } from '../resource';
import { VIDEO_BTN_TEXT1 } from '../../video/resource';
import { AUDIO } from '../../audio/resource';
import { easyStore, storeType } from '../../../global/easy-store';
import {
  saveGettyConfig,
  setArchivingInfo,
} from '../../../actions/MeetingActions';
import { archivingStatus, archivingDataReady } from '../../chat/utils';
import { accountOwnerText, LEARN_MORE } from '../../../global/resource';
import { closeRefDialog, commonModalThunk } from '../service/command-dialog';
import {
  isAllowOnlyCanTalk,
  isHost,
  isViewOnly,
} from '../../../global/service/user-types';
import reactStringReplace from '../../../global/resct-string-replace';
import meetingConfig from 'meetingConfig';
import { shallowEqual, useDispatch, useSelector, useStore } from 'react-redux';
import { confReadySelector } from '../../../global/selectors/meeting-selector';
import { ConsentDialogType } from '../service/consent-dialog-constant';
import AliveToast from '../../../global/containers/notification-manager/alive-toast';
import { playArchivingAudio } from '../../../global/audio-play';
import { isWebinar } from '../../../global/service/meeting-types';
import { chatViewType } from '../../../global/redux/set-panel-action';
import { toggleChatThunkAction } from '../../chat/redux/chat-thunk-action';

export const accountOwnersText = iText(
  'account owner(s)',
  'apac.account_owners',
);

export const archivingToastTest = iText(
  'This webinar is being archived',
  'apac.archiving.toast',
);

export const ACRToastTest = iText(
  'You are connected to Zoom Phone audio',
  'apac.acr.toast',
);

export const archivingDefaultTitleText = iText(
  'This meeting is being archived',
  'apac.archiving.default_title',
);

const archivingDefaultContent1 = () => {
  const text = (tag) =>
    iText(
      `One or more participants in this meeting are subject to archiving based on their account's policy.Their ${tag} will receive content from this meeting and can share it with apps and others.`,
      'apac.archiving.content1',
      tag,
    );
  return transformStringToElement(
    text('#1*'),
    '#1*',
    <Link2
      link={privacyNotificationUrl()}
      className="archiving__account-owner"
      text={accountOwnerText}
    />,
  );
};

const archivingDefaultContent2 = (text) => {
  return iText(
    `By staying in this meeting, you consent to this meeting's ${text} being archived.`,
    'apac.archiving.content2',
    text,
  );
};

const archivingHostDialogTitle = iText(
  'This meeting ended because archiving failed',
  'apac.archiving.host_title',
);
const archivingParticipantsDialogTitle = iText(
  'You were disconnected from the meeting because archiving failed',
  'apac.archiving.participants_title',
);
const archivingFailDialogTitle = iText(
  'Archiving failed',
  'apac.archiving.fail_title',
);
const archivingFailDialogContent = reactStringReplace(
  iText(
    `This meeting's content is required to be archived for compliance purposes. {0}`,
    'apac.archiving.failed_content_1',
  ),
  '{0}',
  () => <WcLink link={archiveSettingLearnMore()} text={LEARN_MORE} />,
);
const archivingFailChatDialogTitle = iText(
  'Chat has been disabled',
  'apac.archiving.chat_disable_title',
);
const archivingFailChatDialogContent = iText(
  'Messages sent in chat could not be archived for compliance purposes.',
  'apac.archiving.chat_disable_content',
);
export const archivingText = {
  startArchiving: ({
    text,
    description,
    linkText,
    linkUrl,
    title,
    isHost,
    disableCustomerDescription,
  }) => ({
    title: title || archivingDefaultTitleText,
    content: [
      archivingDefaultContent1(),
      archivingDefaultContent2(text),
    ].concat(
      disableCustomerDescription
        ? []
        : description?.split('\n').filter((v) => v),
    ),
    onOk: () => {
      return (dispatch) => {
        easyStore.easySet(
          SESSIONSTORAGE_KEYS.archiveConfirmed,
          true,
          storeType.sessionStorage,
        );
        dispatch(
          saveGettyConfig({
            canAVEnable: true,
          }),
        );
      };
    },
    okText: GOT_IT,
    cancelText: DIALOG_LEAVE_MEETING,
    onCancel: cancelToLeave,
    linkText,
    linkUrl,
    notModal: isHost,
  }),
  failWithNoChat: () => ({
    title: archivingFailChatDialogTitle,
    content: [archivingFailChatDialogContent],
    okText: DIALOG_OK,
  }),
  failAllowContinue: () => ({
    title: archivingFailDialogTitle,
    content: [archivingFailDialogContent],
    okText: DIALOG_OK,
  }),
  failToEndForHost: () => ({
    title: archivingHostDialogTitle,
    content: [archivingFailDialogContent],
    okText: DIALOG_OK,
  }),
  failToEndForOther: () => ({
    title: archivingParticipantsDialogTitle,
    content: [archivingFailDialogContent],
    okText: DIALOG_OK,
  }),
};

const MEETING_ARCHIVE_ENABLED = 0x1;
const MEETING_ARCHIVE_PRIVATE_CHAT_DISABLED = 0x2;
const MEETING_ARCHIVE_VIDEO_ENABLED = 0x8;
const MEETING_ARCHIVE_AUDIO_DISABLED = 0x10;
const MEETING_ARCHIVE_CHAT_DISABLED = 0x20;

const MEETING_CHAT_DISABLED = 0x200;
const MEETING_ARCHIVE_CLOSE_CAPTION_ENABLED = 0x1000000;
const MEETING_ARCHIVE_FAILURE_HANDLE = 0x4000000;
const bitIsOn = (sourceData, bitNumber) =>
  sourceData && !!(BigInt(sourceData) & BigInt(bitNumber));
export const IS_ARCHIVE_ENABLED = (x) => bitIsOn(x, MEETING_ARCHIVE_ENABLED);

const IS_VIDEO_ARCHIVE_ENABLED = (x) =>
  /* eslint-disable-next-line @babel/new-cap */
  IS_ARCHIVE_ENABLED(x) && bitIsOn(x, MEETING_ARCHIVE_VIDEO_ENABLED);

const IS_AUDIO_ARCHIVE_ENABLED = (x) =>
  /* eslint-disable-next-line @babel/new-cap */
  IS_ARCHIVE_ENABLED(x) && !bitIsOn(x, MEETING_ARCHIVE_AUDIO_DISABLED);

const IS_CHAT_ARCHIVE_ENABLED = (x) =>
  /* eslint-disable-next-line @babel/new-cap */
  IS_ARCHIVE_ENABLED(x) && !bitIsOn(x, MEETING_ARCHIVE_CHAT_DISABLED);

const IS_PRIVATE_CHAT_ARCHIVE_ENABLED = (x) =>
  /* eslint-disable-next-line @babel/new-cap */
  IS_CHAT_ARCHIVE_ENABLED(x) &&
  !bitIsOn(x, MEETING_ARCHIVE_PRIVATE_CHAT_DISABLED);

const IS_CLOSE_CAPTION_ARCHIVE_ENABLED = (x) =>
  /* eslint-disable-next-line @babel/new-cap */
  IS_ARCHIVE_ENABLED(x) && bitIsOn(x, MEETING_ARCHIVE_CLOSE_CAPTION_ENABLED);

const IS_USER_CAN_STAY = (x) => !bitIsOn(x, MEETING_ARCHIVE_FAILURE_HANDLE);

// not only archive chat
const IS_MEETING_CHAT_DISABLED_BY_ARCHIVING_FAILED = (x) =>
  /* eslint-disable-next-line @babel/new-cap */
  IS_ARCHIVE_ENABLED(x) && bitIsOn(x, MEETING_CHAT_DISABLED);

export const isArchiveOn = (archiveInfo) => {
  const { status, enabled } = archiveInfo;
  return (
    archivingDataReady(status, enabled) && status === archivingStatus.success
  );
};

export const AAndB = (a, b) =>
  iText(`${a} and ${b}`, 'apac.archiving.subjects_combo_2', [a, b]);

export function archivingSubjectsText(options) {
  const [a, b, c, d] = options;
  switch (options.length) {
    case 1:
      return a;
    case 2:
      /* eslint-disable-next-line @babel/new-cap */
      return AAndB(a, b);
    case 3:
      return iText(`${a}, ${b}, and ${c}`, 'apac.archiving.subjects_combo_3', [
        a,
        b,
        c,
      ]);
    case 4:
      return iText(
        `${a}, ${b}, ${c}, and ${d}`,
        'apac.archiving.subjects_combo_4',
        [a, b, c, d],
      );
    default:
      return '';
  }
}
const archivingChatText = iText(
  'chat(public and direct)',
  'apac.archiving.chat_1',
);
const archivingChatPublicText = iText(
  'chat(public only)',
  'apac.archiving.chat_2',
);
const archivingCCText = iText('transcription', 'apac.archiving.transcription');

export function checkArchivingOptions(props = {}) {
  return (dispatch, getSate) => {
    const {
      meeting: {
        archiveInfo: { acrCount, options: preOptions },
      },
    } = getSate();

    const {
      DisclaimerPrivacy: disclaimerPrivacy,
      ConsentDisabled,
      VoiceDisabled,
    } = props;

    const { description, title } = disclaimerPrivacy ?? {};
    const isArchiveConsentCustomized = description || title;
    let { optionsStr: options, options_fti_str: firstTriggeredOptions } = props;
    options = options === '0' ? firstTriggeredOptions : options;
    firstTriggeredOptions =
      firstTriggeredOptions === '0' ? options : firstTriggeredOptions;
    /* eslint-disable-next-line @babel/new-cap */
    if (!IS_ARCHIVE_ENABLED(options)) {
      return { enabled: false };
    }

    const subjects = [];
    /* eslint-disable-next-line @babel/new-cap */
    if (IS_VIDEO_ARCHIVE_ENABLED(options)) {
      subjects.push(VIDEO_BTN_TEXT1);
    }
    /* eslint-disable-next-line @babel/new-cap */
    if (IS_AUDIO_ARCHIVE_ENABLED(options) || acrCount > 0) {
      subjects.push(AUDIO);
    }

    /* eslint-disable-next-line @babel/new-cap */
    if (IS_CHAT_ARCHIVE_ENABLED(options)) {
      /* eslint-disable-next-line @babel/new-cap */
      if (IS_PRIVATE_CHAT_ARCHIVE_ENABLED(options)) {
        subjects.push(archivingChatText);
      } else {
        subjects.push(archivingChatPublicText);
      }
    }

    /* eslint-disable-next-line @babel/new-cap */
    if (IS_CLOSE_CAPTION_ARCHIVE_ENABLED(options)) {
      subjects.push(archivingCCText);
    }

    const ret = {
      text: archivingSubjectsText(subjects),
      subjects: subjects,
      options: options,
      preOptions,
      isSingle: subjects.length <= 1,
      /* eslint-disable-next-line @babel/new-cap */
      disableChat: IS_MEETING_CHAT_DISABLED_BY_ARCHIVING_FAILED(
        firstTriggeredOptions,
      ),
      /* eslint-disable-next-line @babel/new-cap */
      isUserCanStay: IS_USER_CAN_STAY(options),
      enabled: true,
      /* eslint-disable-next-line @babel/new-cap */
      videoOn: IS_VIDEO_ARCHIVE_ENABLED(options),
      /* eslint-disable-next-line @babel/new-cap */
      audioOn: IS_AUDIO_ARCHIVE_ENABLED(options),
      /* eslint-disable-next-line @babel/new-cap */
      chatOn: IS_CHAT_ARCHIVE_ENABLED(options),
      /* eslint-disable-next-line @babel/new-cap */
      privateChatOn: IS_PRIVATE_CHAT_ARCHIVE_ENABLED(options),
      /* eslint-disable-next-line @babel/new-cap */
      ccOn: IS_CLOSE_CAPTION_ARCHIVE_ENABLED(options),
      showArchivingNotice:
        /* eslint-disable-next-line @babel/new-cap */
        (IS_VIDEO_ARCHIVE_ENABLED(options) ||
          /* eslint-disable-next-line @babel/new-cap */
          IS_AUDIO_ARCHIVE_ENABLED(options)) &&
        !ConsentDisabled,
      promptArchivingVoice:
        /* eslint-disable-next-line @babel/new-cap */
        (IS_VIDEO_ARCHIVE_ENABLED(options) ||
          /* eslint-disable-next-line @babel/new-cap */
          IS_AUDIO_ARCHIVE_ENABLED(options)) &&
        !VoiceDisabled,
      disclaimerPrivacy,
      isArchiveConsentCustomized,
      showArchivingIcon: meetingConfig.meetingOptions.showArchivingIcon ?? 0, // 0 none, 1 all, 2 guest
    };

    dispatch(setArchivingInfo(ret));
  };
}

export function archivingDialogThunk({
  type,
  text,
  isSingle,
  disclaimerPrivacy = {},
  dialogType,
  disableCustomerDescription,
}) {
  return (dispatch, getState) => {
    if (!archivingText[type]) {
      return null;
    }
    const {
      meeting: {
        currentUser: { userRole },
      },
    } = getState();

    const {
      title,
      content,
      okText,
      cancelText,
      onCancel,
      onOk,
      linkText,
      linkUrl,
    } = archivingText[type]({
      ...disclaimerPrivacy,
      text,
      isSingle,
      disableCustomerDescription,
      isHost: isHost(userRole),
    });

    return dispatch(
      commonModalThunk({
        dialogType,
        title,
        contentLabel: content.join(''),
        /* eslint-disable-next-line @babel/new-cap */
        content: Paragraph({ textParts: content }),
        linkText,
        linkUrl,
        okText,
        cancelText,
        onOk:
          onOk &&
          (() => {
            dispatch(onOk(type));
            dispatch(
              saveGettyConfig({
                canAVEnable: true,
              }),
            );
          }),
        onCancel: onCancel && (() => dispatch(onCancel())),
        isModal: false,
      }),
    );
  };
}

export const useArchiveConfig = () => {
  const {
    archiveStatus,
    isOnHold,
    confReady,
    showArchivingNotice,
    promptArchivingVoice,
    userRole,
    text,
    disclaimerPrivacy,
    isSingle,
  } = useSelector((state) => {
    const {
      meeting: {
        archiveInfo: {
          status: archiveStatus,
          text,
          isSingle,
          disclaimerPrivacy,
          showArchivingNotice,
          promptArchivingVoice,
        },
        currentUser: { userRole },
      },
    } = state;
    return {
      archiveStatus,
      showArchivingNotice,
      promptArchivingVoice,
      userRole,
      isOnHold: state.meetingUI.isOnHold,
      confReady: confReadySelector(state),
      text,
      disclaimerPrivacy,
      isSingle,
    };
  }, shallowEqual);
  const dispatch = useDispatch();

  return {
    isOn: archiveStatus === archivingStatus.success,
    enable: !isOnHold && confReady && showArchivingNotice,
    sessionKey: SESSIONSTORAGE_KEYS.archiveConfirmed,
    group: ConsentDialogType.ARCHIVING,
    startDialog: () => {
      dispatch(
        archivingDialogThunk({
          type: 'startArchiving',
          text,
          isSingle,
          disclaimerPrivacy,
          dialogType: 'archiveDialog',
        }),
      );
    },
    startTip: () => {
      const toastText = isWebinar()
        ? archivingToastTest
        : archivingDefaultTitleText;
      AliveToast.toast(toastText);
    },
    onStart: () => {
      if (promptArchivingVoice) {
        playArchivingAudio();
      }
    },
    onStop: () => {
      closeRefDialog('archiveDialog');
    },
    showType: () => {
      if (isViewOnly(userRole) && !isAllowOnlyCanTalk(userRole)) {
        return null;
      } else if (
        isAllowOnlyCanTalk(userRole) ||
        easyStore.easyGet(SESSIONSTORAGE_KEYS.archiveConfirmed) ||
        easyStore.easyGet(SESSIONSTORAGE_KEYS.promoteConfirmed)
      ) {
        return 'tip';
      } else {
        return 'dialog-consent';
      }
    },
  };
};

export const useArchiveFailedConfig = () => {
  const {
    archiveStatus,
    isOnHold,
    confReady,
    showArchivingNotice,
    disableChat,
    isUserCanStay,
    text,
    sessionReady,
    isSingle,
  } = useSelector((state) => {
    const {
      meeting: {
        archiveInfo: {
          status: archiveStatus,
          text,
          isSingle,
          isUserCanStay,
          showArchivingNotice,
          disableChat,
          sessionReady,
        },
      },
    } = state;
    return {
      archiveStatus,
      showArchivingNotice,
      isOnHold: state.meetingUI.isOnHold,
      confReady: confReadySelector(state),
      text,
      isSingle,
      sessionReady,
      isUserCanStay,
      disableChat,
    };
  }, shallowEqual);
  const dispatch = useDispatch();
  const { getState } = useStore();

  return {
    isOn: archiveStatus === archivingStatus.fail,
    enable: !isOnHold && confReady && showArchivingNotice,
    onStart: () => {
      if (disableChat === true) {
        if (isUserCanStay) {
          if (chatViewType(getState())) {
            dispatch(toggleChatThunkAction(false));
          }
          dispatch(
            archivingDialogThunk({
              type: 'failWithNoChat',
              text,
              isSingle,
              dialogType: 'archiveDialog',
            }),
          );
        }
      } else if (sessionReady) {
        dispatch(
          archivingDialogThunk({
            type: 'failAllowContinue',
            text,
            isSingle,
            dialogType: 'archiveDialog',
          }),
        );
      }
    },
  };
};
