import { closeSocket, sendSocketMessage } from '../../../actions/SocketActions';
import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import { leaveWhiteboard } from '../../../features/whiteboard/redux/whiteboard-thunk-action';
import { easyStore } from '../../easy-store';
import { PWAExist } from '../../pwa-integration';
import { isExternalControlledMode } from '../../util';
import {
  CONTROL_MODE_ZOOM_MSG_TYPE,
  CONTROL_MODE_ZOOM_UI_ACTION_TYPE,
  externalController,
} from '../../../controller';
import { isSupportFeedbackAndSurvey, sendLeaveMsgToPWAOnExit } from './index';
import { bParticipantWillMoveToWRSelector } from '../selector';
import { createFeedbackDataToPWA } from '../../service/feedback-service';

export const endMeetingThunkAction =
  (isHungUpRemotely = false) =>
  (dispatch) => {
    // This is called when user clicked on the "End Meeting for All" button to end the meeting.
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_END_REQ,
        body: { isHungUpRemotely },
      }),
    );
  };

export function leaveMeetingThunkAction(options = {}) {
  return (dispatch, getState) => {
    const state = getState();
    /**
     * This is called when user clicked on the "Leave Meeting" button to leave the meeting.
     * "isHungUpRemotely" is used to track if the meeting was ended by host or not.
     * If the meeting was terminated by host remotely, this parameter will prevent from sending
     * another disconnected message to wc_connector since we already sent a REMOTE_HANGUP to connector in rwg-msg-handler.
     */
    const {
      meeting: {
        currentUser: { keepPhoneConnected },
      },
    } = state;
    const bParticipantWillMovedToWR = bParticipantWillMoveToWRSelector(state);
    const data = {
      ...options,
    };
    if (keepPhoneConnected) {
      data.keepPhoneConnected = keepPhoneConnected;
    }
    const evt = bParticipantWillMovedToWR
      ? socketEventTypes.WS_CONF_LEAVE_AND_PUT_ALL_INTO_WR_REQ
      : socketEventTypes.WS_CONF_LEAVE_REQ;
    dispatch(leaveWhiteboard());
    dispatch(
      sendSocketMessage({
        evt,
        body: data,
      }),
    );
  };
}

export function exitConf({
  isLeave,
  isLeaveMeetingRightNow,
  isHungUpRemotely,
  isFailover,
}) {
  return (dispatch, getState) => {
    const {
      meeting: { baseUrl },
    } = getState();
    dispatch(closeSocket(false));

    const feedbackAndSurvey = dispatch(
      isSupportFeedbackAndSurvey(isHungUpRemotely),
    );

    if (!isFailover) {
      easyStore.removeSessionStorage();
    }

    // send leave message after cleaning work
    /* eslint-disable-next-line @babel/new-cap */
    if (PWAExist()) {
      return dispatch(sendLeaveMsgToPWAOnExit(isLeave, feedbackAndSurvey));
    }
    // Smart display mode. If isHungUpRemotely === true --> no need to send another disconnected msg.
    if (isExternalControlledMode() && !isHungUpRemotely) {
      let endReason;
      externalController.notifyConnectorZoomMsg(
        CONTROL_MODE_ZOOM_MSG_TYPE.UI,
        CONTROL_MODE_ZOOM_UI_ACTION_TYPE.LEAVE_MEETING,
        endReason,
      );
    } else if (isLeaveMeetingRightNow && !isExternalControlledMode()) {
      let newUrl = `${baseUrl}/wc`;
      if (feedbackAndSurvey.feedback) {
        newUrl = `${newUrl}?${createFeedbackDataToPWA(feedbackAndSurvey, 0)}`;
      } else if (feedbackAndSurvey.postAttendeeUrl) {
        newUrl = feedbackAndSurvey.postAttendeeUrl;
      }

      setTimeout(() => {
        window.location.href = newUrl;
      }, 1000);
      if (feedbackAndSurvey.survey) {
        window.open(feedbackAndSurvey.survey, '_blank');
      }
    }
  };
}
