import { createSelector } from 'reselect';
import {
  coOrHostSelector,
  isMeSelector,
  currentUserIsHostSelector,
} from '../../../../global/redux/selector';
import { spotlightVideoListSelector } from '../selectors/video-common-selector';
import { isZRMultiStreamVideoChildUser } from '../../../../global/service';

import {
  isAttendeeSelector,
  isMoreThanTwoSelector,
  isShowReportSelector,
  isPrivateShowChatSelector,
  isShowSpotlightSelector,
  isSpotlightedSelector,
  isShowMakeHostSelector,
  isShowMakeCoHostSelector,
  isShowWithdrawCoHostSelector,
  isShowMoveToWaitingRoomSelector,
  isShowRemoveSelector,
  isShowCCPermissionSelector,
  isShowRenameSelector,
  isShowAllowMultiPinSelector,
  isAllowMultiPinSelector,
  isShowAddSpotlightSelector,
  isShowRemoveSpotlightSelector,
  isShowUpdateSpotlightSelector,
  isShowAttendeeVideoSelector,
  isShowPinSelector,
  isPinedSelector,
  isShowZrMainUserMenuSelector,
} from '../../../../global/redux/base-menu-selector';
import { isParticipantSignLanguageInterpreterSelector } from '../../../interpretation/redux/interpretation-selector';

const participantPropsSelector = (_, { participant }) => participant;

const currentUserSelector = ({ meeting: { currentUser } }) => currentUser;

const pinVideoListSelector = ({ video: { pinVideoList } }) => pinVideoList;

const isShowHideSelfSelector = createSelector(
  [
    isMeSelector,
    currentUserSelector,
    pinVideoListSelector,
    spotlightVideoListSelector,
    (state, { participant }) =>
      isParticipantSignLanguageInterpreterSelector(state, participant),
  ],
  (
    isMe,
    currentUser,
    pinVideoList,
    spotlightVideoList,
    isParticipantSignLanguageInterpreter,
  ) => {
    return (
      isMe &&
      !isParticipantSignLanguageInterpreter &&
      !pinVideoList.includes(currentUser.userId) &&
      !spotlightVideoList.includes(currentUser.userId)
    );
  },
);

const isShowReportOthersBtnSelector = createSelector(
  [
    isShowReportSelector,
    isMeSelector,
    participantPropsSelector,
    coOrHostSelector,
    (state, { participant }) =>
      isParticipantSignLanguageInterpreterSelector(state, participant),
  ],
  (
    isShowReport,
    isMe,
    participant,
    coOrHost,
    isParticipantSignLanguageInterpreter,
  ) => {
    if (isZRMultiStreamVideoChildUser(participant)) {
      return false;
    }
    return (
      isShowReport &&
      !isMe &&
      (coOrHost || !isParticipantSignLanguageInterpreter)
    );
  },
);

// menu
export const isDisableSpotlightSelector = createSelector(
  [spotlightVideoListSelector],
  (spotlightVideoList) => {
    if (spotlightVideoList.length >= 9) {
      return true;
    }

    return false;
  },
);
// menu
export const isDisablePinSelector = createSelector(
  [pinVideoListSelector],
  (pinVideoList) => {
    if (pinVideoList.length >= 9) {
      return true;
    }

    return false;
  },
);

export const isShowRemoveLocalLiveStreamPermissionSelector = createSelector(
  [isMeSelector, currentUserIsHostSelector, participantPropsSelector],
  (isMe, isHost, participant) => {
    return (
      !isMe &&
      isHost &&
      participant.bLocalLivestreamPermission &&
      !participant.bTokenSdkLivestreamPermission
    );
  },
);

export {
  isShowHideSelfSelector,
  isMoreThanTwoSelector,
  isPinedSelector,
  isPrivateShowChatSelector,
  isShowRenameSelector,
  isShowSpotlightSelector,
  isShowMakeHostSelector,
  isShowMakeCoHostSelector,
  isShowWithdrawCoHostSelector,
  isShowCCPermissionSelector,
  isShowRemoveSelector,
  isShowMoveToWaitingRoomSelector,
  isAttendeeSelector,
  isSpotlightedSelector,
  isShowPinSelector,
  isShowAllowMultiPinSelector,
  isAllowMultiPinSelector,
  isShowAddSpotlightSelector,
  isShowRemoveSpotlightSelector,
  isShowUpdateSpotlightSelector,
  isShowReportOthersBtnSelector,
  isShowAttendeeVideoSelector,
  isShowZrMainUserMenuSelector,
};
