/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from 'protobufjs/minimal';
// https://zoomvideo.atlassian.net/wiki/spaces/IM/pages/2081234657/Messenge+rich-text+style+offset+definition#2.2-Parse-based-on-Protobuf
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

const $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {});

export const messengerproto = ($root.messengerproto = (() => {
  const messengerproto = {};

  messengerproto.ImageSize = (function () {
    function ImageSize(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    ImageSize.prototype.cx = null;
    ImageSize.prototype.cy = null;

    let $oneOfFields;

    Object.defineProperty(ImageSize.prototype, '_cx', {
      get: $util.oneOfGetter(($oneOfFields = ['cx'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(ImageSize.prototype, '_cy', {
      get: $util.oneOfGetter(($oneOfFields = ['cy'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    ImageSize.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.cx != null && Object.hasOwnProperty.call(message, 'cx'))
        writer.uint32(8).uint32(message.cx);
      if (message.cy != null && Object.hasOwnProperty.call(message, 'cy'))
        writer.uint32(16).uint32(message.cy);
      return writer;
    };

    ImageSize.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.messengerproto.ImageSize();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.cx = reader.uint32();
            break;
          }
          case 2: {
            message.cy = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    ImageSize.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/messengerproto.ImageSize';
    };

    return ImageSize;
  })();

  messengerproto.FontStyteItem = (function () {
    function FontStyteItem(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    FontStyteItem.prototype.type = null;
    FontStyteItem.prototype.startpos = null;
    FontStyteItem.prototype.endpos = null;
    FontStyteItem.prototype.fileid = null;
    FontStyteItem.prototype.filename = null;
    FontStyteItem.prototype.filesize = null;
    FontStyteItem.prototype.imagesize = null;
    FontStyteItem.prototype.ver = null;
    FontStyteItem.prototype.reserve1 = null;
    FontStyteItem.prototype.reserve2 = null;

    let $oneOfFields;

    Object.defineProperty(FontStyteItem.prototype, '_type', {
      get: $util.oneOfGetter(($oneOfFields = ['type'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(FontStyteItem.prototype, '_startpos', {
      get: $util.oneOfGetter(($oneOfFields = ['startpos'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(FontStyteItem.prototype, '_endpos', {
      get: $util.oneOfGetter(($oneOfFields = ['endpos'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(FontStyteItem.prototype, '_fileid', {
      get: $util.oneOfGetter(($oneOfFields = ['fileid'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(FontStyteItem.prototype, '_filename', {
      get: $util.oneOfGetter(($oneOfFields = ['filename'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(FontStyteItem.prototype, '_filesize', {
      get: $util.oneOfGetter(($oneOfFields = ['filesize'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(FontStyteItem.prototype, '_imagesize', {
      get: $util.oneOfGetter(($oneOfFields = ['imagesize'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(FontStyteItem.prototype, '_ver', {
      get: $util.oneOfGetter(($oneOfFields = ['ver'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(FontStyteItem.prototype, '_reserve1', {
      get: $util.oneOfGetter(($oneOfFields = ['reserve1'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(FontStyteItem.prototype, '_reserve2', {
      get: $util.oneOfGetter(($oneOfFields = ['reserve2'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    FontStyteItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.type != null && Object.hasOwnProperty.call(message, 'type'))
        writer.uint32(8).uint64(message.type);
      if (
        message.startpos != null &&
        Object.hasOwnProperty.call(message, 'startpos')
      )
        writer.uint32(16).int32(message.startpos);
      if (
        message.endpos != null &&
        Object.hasOwnProperty.call(message, 'endpos')
      )
        writer.uint32(24).int32(message.endpos);
      if (
        message.fileid != null &&
        Object.hasOwnProperty.call(message, 'fileid')
      )
        writer.uint32(34).string(message.fileid);
      if (
        message.filename != null &&
        Object.hasOwnProperty.call(message, 'filename')
      )
        writer.uint32(42).string(message.filename);
      if (
        message.filesize != null &&
        Object.hasOwnProperty.call(message, 'filesize')
      )
        writer.uint32(48).uint32(message.filesize);
      if (
        message.imagesize != null &&
        Object.hasOwnProperty.call(message, 'imagesize')
      )
        $root.messengerproto.ImageSize.encode(
          message.imagesize,
          writer.uint32(58).fork(),
        ).ldelim();
      if (message.ver != null && Object.hasOwnProperty.call(message, 'ver'))
        writer.uint32(64).int64(message.ver);
      if (
        message.reserve1 != null &&
        Object.hasOwnProperty.call(message, 'reserve1')
      )
        writer.uint32(74).string(message.reserve1);
      if (
        message.reserve2 != null &&
        Object.hasOwnProperty.call(message, 'reserve2')
      )
        writer.uint32(82).string(message.reserve2);
      return writer;
    };

    FontStyteItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.messengerproto.FontStyteItem();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.type = reader.uint64();
            break;
          }
          case 2: {
            message.startpos = reader.int32();
            break;
          }
          case 3: {
            message.endpos = reader.int32();
            break;
          }
          case 4: {
            message.fileid = reader.string();
            break;
          }
          case 5: {
            message.filename = reader.string();
            break;
          }
          case 6: {
            message.filesize = reader.uint32();
            break;
          }
          case 7: {
            message.imagesize = $root.messengerproto.ImageSize.decode(
              reader,
              reader.uint32(),
            );
            break;
          }
          case 8: {
            message.ver = reader.int64();
            break;
          }
          case 9: {
            message.reserve1 = reader.string();
            break;
          }
          case 10: {
            message.reserve2 = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    FontStyteItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/messengerproto.FontStyteItem';
    };

    return FontStyteItem;
  })();

  messengerproto.MailInfo = (function () {
    function MailInfo(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    MailInfo.prototype.mailtitle = null;
    MailInfo.prototype.mailbody = null;
    MailInfo.prototype.fromjid = null;
    MailInfo.prototype.fromname = null;
    MailInfo.prototype.mailtime = null;
    MailInfo.prototype.mailid = null;

    let $oneOfFields;

    Object.defineProperty(MailInfo.prototype, '_mailtitle', {
      get: $util.oneOfGetter(($oneOfFields = ['mailtitle'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(MailInfo.prototype, '_mailbody', {
      get: $util.oneOfGetter(($oneOfFields = ['mailbody'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(MailInfo.prototype, '_fromjid', {
      get: $util.oneOfGetter(($oneOfFields = ['fromjid'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(MailInfo.prototype, '_fromname', {
      get: $util.oneOfGetter(($oneOfFields = ['fromname'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(MailInfo.prototype, '_mailtime', {
      get: $util.oneOfGetter(($oneOfFields = ['mailtime'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(MailInfo.prototype, '_mailid', {
      get: $util.oneOfGetter(($oneOfFields = ['mailid'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    MailInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (
        message.mailtitle != null &&
        Object.hasOwnProperty.call(message, 'mailtitle')
      )
        writer.uint32(10).string(message.mailtitle);
      if (
        message.mailbody != null &&
        Object.hasOwnProperty.call(message, 'mailbody')
      )
        writer.uint32(18).string(message.mailbody);
      if (
        message.fromjid != null &&
        Object.hasOwnProperty.call(message, 'fromjid')
      )
        writer.uint32(26).string(message.fromjid);
      if (
        message.fromname != null &&
        Object.hasOwnProperty.call(message, 'fromname')
      )
        writer.uint32(34).string(message.fromname);
      if (
        message.mailtime != null &&
        Object.hasOwnProperty.call(message, 'mailtime')
      )
        writer.uint32(40).int64(message.mailtime);
      if (
        message.mailid != null &&
        Object.hasOwnProperty.call(message, 'mailid')
      )
        writer.uint32(50).string(message.mailid);
      return writer;
    };

    MailInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.messengerproto.MailInfo();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.mailtitle = reader.string();
            break;
          }
          case 2: {
            message.mailbody = reader.string();
            break;
          }
          case 3: {
            message.fromjid = reader.string();
            break;
          }
          case 4: {
            message.fromname = reader.string();
            break;
          }
          case 5: {
            message.mailtime = reader.int64();
            break;
          }
          case 6: {
            message.mailid = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    MailInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/messengerproto.MailInfo';
    };

    return MailInfo;
  })();

  messengerproto.AppPreviewCard = (function () {
    function AppPreviewCard(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    AppPreviewCard.prototype.payload = null;

    let $oneOfFields;

    Object.defineProperty(AppPreviewCard.prototype, '_payload', {
      get: $util.oneOfGetter(($oneOfFields = ['payload'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    AppPreviewCard.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (
        message.payload != null &&
        Object.hasOwnProperty.call(message, 'payload')
      )
        writer.uint32(10).string(message.payload);
      return writer;
    };

    AppPreviewCard.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.messengerproto.AppPreviewCard();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.payload = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    AppPreviewCard.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/messengerproto.AppPreviewCard';
    };

    return AppPreviewCard;
  })();

  messengerproto.FontStyte = (function () {
    function FontStyte(properties) {
      this.item = [];
      this.previewCard = [];
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    FontStyte.prototype.item = $util.emptyArray;
    FontStyte.prototype.mailinfo = null;
    FontStyte.prototype.previewCard = $util.emptyArray;
    FontStyte.prototype.declineMsg = null;

    let $oneOfFields;

    Object.defineProperty(FontStyte.prototype, '_mailinfo', {
      get: $util.oneOfGetter(($oneOfFields = ['mailinfo'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    Object.defineProperty(FontStyte.prototype, '_declineMsg', {
      get: $util.oneOfGetter(($oneOfFields = ['declineMsg'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    FontStyte.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.item != null && message.item.length)
        for (let i = 0; i < message.item.length; ++i)
          $root.messengerproto.FontStyteItem.encode(
            message.item[i],
            writer.uint32(10).fork(),
          ).ldelim();
      if (
        message.mailinfo != null &&
        Object.hasOwnProperty.call(message, 'mailinfo')
      )
        $root.messengerproto.MailInfo.encode(
          message.mailinfo,
          writer.uint32(18).fork(),
        ).ldelim();
      if (message.previewCard != null && message.previewCard.length)
        for (let i = 0; i < message.previewCard.length; ++i)
          $root.messengerproto.AppPreviewCard.encode(
            message.previewCard[i],
            writer.uint32(26).fork(),
          ).ldelim();
      if (
        message.declineMsg != null &&
        Object.hasOwnProperty.call(message, 'declineMsg')
      )
        $root.messengerproto.DeclineMsg.encode(
          message.declineMsg,
          writer.uint32(34).fork(),
        ).ldelim();
      return writer;
    };

    FontStyte.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.messengerproto.FontStyte();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.item && message.item.length)) message.item = [];
            message.item.push(
              $root.messengerproto.FontStyteItem.decode(
                reader,
                reader.uint32(),
              ),
            );
            break;
          }
          case 2: {
            message.mailinfo = $root.messengerproto.MailInfo.decode(
              reader,
              reader.uint32(),
            );
            break;
          }
          case 3: {
            if (!(message.previewCard && message.previewCard.length))
              message.previewCard = [];
            message.previewCard.push(
              $root.messengerproto.AppPreviewCard.decode(
                reader,
                reader.uint32(),
              ),
            );
            break;
          }
          case 4: {
            message.declineMsg = $root.messengerproto.DeclineMsg.decode(
              reader,
              reader.uint32(),
            );
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    FontStyte.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/messengerproto.FontStyte';
    };

    return FontStyte;
  })();

  messengerproto.DeclineMsg = (function () {
    function DeclineMsg(properties) {
      if (properties)
        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    DeclineMsg.prototype.meetingID = null;

    let $oneOfFields;

    Object.defineProperty(DeclineMsg.prototype, '_meetingID', {
      get: $util.oneOfGetter(($oneOfFields = ['meetingID'])),
      set: $util.oneOfSetter($oneOfFields),
    });

    DeclineMsg.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (
        message.meetingID != null &&
        Object.hasOwnProperty.call(message, 'meetingID')
      )
        writer.uint32(10).string(message.meetingID);
      return writer;
    };

    DeclineMsg.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      let end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.messengerproto.DeclineMsg();
      while (reader.pos < end) {
        let tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.meetingID = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    DeclineMsg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = 'type.googleapis.com';
      }
      return typeUrlPrefix + '/messengerproto.DeclineMsg';
    };

    return DeclineMsg;
  })();

  return messengerproto;
})());

export { $root as default };
