export const OPEN_SOCKET = 'OPEN_SOCKET';
export const CLOSE_SOCKET = 'CLOSE_SOCKET';
export const UPDATE_RENDER_VIDEO_STATUS = 'UPDATE_RENDER_VIDEO_STATUS';
export const OPEN_XMPP_SOCKET = 'OPEN_XMPP_SOCKET';
export const CLOSE_XMPP_SOCKET = 'CLOSE_XMPP_SOCKET';
export const CONFLICT_XMPP_SOCKET = 'CONFLICT_XMPP_SOCKET';
export const INIT_AUDIO_DECODE_SUCCESS = 'INIT_AUDIO_DECODE_SUCCESS';
export const INIT_AUDIO_ENCODE_SUCCESS = 'INIT_AUDIO_ENCODE_SUCCESS';
export const INIT_VIDEO_DECODE_SUCCESS = 'INIT_VIDEO_DECODE_SUCCESS';
export const INIT_VIDEO_ENCODE_SUCCESS = 'INIT_VIDEO_ENCODE_SUCCESS';
export const INIT_AUDIO_DECODE_FAILED = 'INIT_AUDIO_DECODE_FAILED';
export const INIT_AUDIO_ENCODE_FAILED = 'INIT_AUDIO_ENCODE_FAILED';
export const INIT_VIDEO_DECODE_FAILED = 'INIT_VIDEO_DECODE_FAILED';
export const INIT_VIDEO_ENCODE_FAILED = 'INIT_VIDEO_ENCODE_FAILED';
export const INIT_SHARING_SUCCESS = 'INIT_SHARING_SUCCESS';
export const SOCKETSTATUS_RESET = 'SOCKETSTATUS_RESET';
export const SET_SOCKET_BEEN_INITED = 'SET_SOCKET_BEEN_INITED';
