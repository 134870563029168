/* eslint-disable react/prop-types */
import React from 'react';
import './NameTag.scss';

function NameTag(props) {
  const { sbAColor, sbBColor, sbTColor, sbName, sbPron, sbDesc } =
    props.sessionBranding;
  const nameTagStyle = {
    color: sbTColor,
    backgroundColor: sbBColor,
  };
  const accentStyle = {
    backgroundColor: sbAColor,
  };

  return (
    <div className="preview-name-tag" style={nameTagStyle}>
      <div className="name-tag-inner">
        <div className="name-tag-accent" style={accentStyle}></div>
        <div className="name-tag-name">
          {sbName}
          {sbPron && `(${sbPron})`}
        </div>
        <div className="name-tag-desc">{sbDesc}</div>
      </div>
    </div>
  );
}

export default NameTag;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/features/preview/component/name-tag/NameTag.scss';
