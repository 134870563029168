import { createActions } from 'redux-actions';

export const {
  setZoomAppToken,
  setZoomAppProto,
  setZoomAppState,
  setSidecarInfo,
  resetZoomApp,
} = createActions(
  'SET_ZOOM_APP_TOKEN',
  'SET_ZOOM_APP_PROTO',
  'SET_ZOOM_APP_STATE',
  'SET_SIDECAR_INFO',
  'RESET_ZOOM_APP',
);
