import meetingConfig from 'meetingConfig';
import {
  PWA_WB_DASHBOARD,
  WHITEBOARD_INIT_ROLE,
  WHITEBOARD_PERMISSION_CODE,
  WHITEBOARD_DASHBOARD,
} from './enum';
import { openWhiteboard, newWhiteboard } from './redux/whiteboard-thunk-action';
import { exchangeWhiteboardToken } from '../../global/redux/thunk-action/refresh-rwc-token';
import { canInitiateNewWhiteboardSelector } from './redux/whiteboard-selector';
import { setIsWhiteboardWindowLoading } from './redux/whiteboard-action';
import _ from 'lodash';
import { isWebinar } from '../../global/service/meeting-types';

export const postMessageToWBDashboard = (data) => {
  if (window[WHITEBOARD_DASHBOARD]) {
    window[WHITEBOARD_DASHBOARD].postMessage(
      {
        type: PWA_WB_DASHBOARD,
        data,
      },
      meetingConfig.baseUrl,
    );
  }
};

export const updateWBDashboardBasicInfo = (state) => {
  const isDisableNewWhiteboard = !canInitiateNewWhiteboardSelector(state);
  const data = {
    messageName: 'RESPONSE_BASIC_INFO',
    supportNewFromClient: true,
    meetingName: meetingConfig.meetingTopic,
    isDisableNewWhiteboard: isDisableNewWhiteboard,
    isWebinar: isWebinar(),
    isDisableSaveCloudWhiteboard:
      !meetingConfig.meetingOptions.isWebclientInMeetingWBEnabledSave2Cloud,
    zmCalBindDocs: _.isEmpty(meetingConfig.meetingOptions.meetingDocIds)
      ? []
      : [meetingConfig.meetingOptions.meetingDocIds],
  };
  postMessageToWBDashboard(data);
};

export const addEventListenerForWhiteboardDashboard = (store) => {
  const { dispatch, getState } = store;
  window.addEventListener('message', (event) => {
    if (event.origin !== meetingConfig.baseUrl) {
      return;
    }
    if (event && event.data && event.data.type === PWA_WB_DASHBOARD) {
      const message = event.data.data;
      if (message.messageName) {
        switch (message.messageName) {
          case 'REQUEST_TOKEN': {
            const {
              whiteboard: { whiteboardToken },
            } = getState();
            if (whiteboardToken) {
              event.source.postMessage(
                {
                  type: PWA_WB_DASHBOARD,
                  data: { messageName: 'RESPONSE_TOKEN', whiteboardToken },
                },
                meetingConfig.baseUrl,
              );
            }
            break;
          }
          case 'REFRESH_TOKEN': {
            dispatch(exchangeWhiteboardToken());
            break;
          }
          case 'REQUEST_BASIC_INFO': {
            updateWBDashboardBasicInfo(getState());
            dispatch(setIsWhiteboardWindowLoading(false));
            break;
          }
          case 'OPEN_WHITEBOARD': {
            dispatch(openWhiteboard(message.wbInfo));
            break;
          }
          case 'NEW_WHITEBOARD': {
            dispatch(newWhiteboard(message.wbInfo));
            break;
          }
        }
      }
    }
  });
};

export const computeWBLockShare = (
  whiteboardPermissionCode,
  whiteboardInitRole,
  isWhiteboardInitWithoutHost,
) => {
  const isWhiteboardShareOptionsLocked =
    meetingConfig.meetingOptions.isWhiteboardShareOptionsLocked;
  const isWhiteboardInitWithoutHostBit = isWhiteboardInitWithoutHost
    ? 0b100000
    : 0;

  let whiteboardInitRoleBit = 0;
  if (whiteboardInitRole === WHITEBOARD_INIT_ROLE.INTERNAL_USER) {
    whiteboardInitRoleBit = 0b001000;
  } else if (whiteboardInitRole === WHITEBOARD_INIT_ROLE.ALL_PARTICIPANTS) {
    whiteboardInitRoleBit = 0b010000;
  }

  const isWhiteboardShareOptionsLockedBit = isWhiteboardShareOptionsLocked
    ? 0b000100
    : 0;

  let whiteboardPermissionCodeBit = 0;
  if (
    whiteboardPermissionCode ===
    WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_HOST_GRAB
  ) {
    whiteboardPermissionCodeBit = 0b000001;
  } else if (
    whiteboardPermissionCode ===
    WHITEBOARD_PERMISSION_CODE.CMM_WB_SETTING_ANYONE_GRAB
  ) {
    whiteboardPermissionCodeBit = 0b000011;
  }

  return (
    isWhiteboardInitWithoutHostBit |
    whiteboardInitRoleBit |
    isWhiteboardShareOptionsLockedBit |
    whiteboardPermissionCodeBit
  );
};

/**
 * Get the Fourth and Fifth bit
 * origin code: 1 01 0 00
 * & 0x18       0 11 0 00
 * =            0 01 0 00
 * >> 3
 * =            0 00 0 01
 * return 1
 */
const BINARY_WHICH_FOURTH_AND_FIFTH_BITS_ARE_ONE = 0x18;
export const getWhiteboardInitRole = (whiteboardPermissionCode) => {
  return (
    (whiteboardPermissionCode & BINARY_WHICH_FOURTH_AND_FIFTH_BITS_ARE_ONE) >> 3
  );
};

const BINARY_WHICH_SIXTH_BIT_IS_ONE = 0x20;
export const getWhiteboardInitWithoutHost = (whiteboardPermissionCode) => {
  return (whiteboardPermissionCode & BINARY_WHICH_SIXTH_BIT_IS_ONE) >> 5
    ? true
    : false;
};

const BINARY_WHICH_FIRST_AND_SECOND_BITS_ARE_ONE = 0x3;
export const getWhiteboardPermissionCode = (whiteboardPermissionCode) => {
  return whiteboardPermissionCode & BINARY_WHICH_FIRST_AND_SECOND_BITS_ARE_ONE;
};
