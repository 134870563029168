import { createActions } from 'redux-actions';

export const {
  setLiveStreamStatus,
  setLiveStreamBroadcastInfo,
  setRequestingChannel,
  addLiveStreamTokenReqId,
  setRequestPermissionInfo,
  setLiveStreamTip,
} = createActions(
  'SET_LIVE_STREAM_STATUS',
  'SET_LIVE_STREAM_BROADCAST_INFO',
  'SET_REQUESTING_CHANNEL',
  'ADD_LIVE_STREAM_TOKEN_REQ_ID',
  'SET_REQUEST_PERMISSION_INFO',
  'SET_LIVE_STREAM_TIP',
);
