import { produce } from 'immer';

import {
  setCustomGalleryViewOrder,
  setHasChangedVideoOrder,
  setIsDownloadingViewOrder,
  setGalleryOrderType,
  setIsGalleryOrderMode,
} from '../video-action';

import {
  currentUserSelector,
  inMeetingParticipantsSelector,
  isCoOrHost,
  checkIsHostSupportGallerySortType,
  isSupportGallerySortMode,
} from '../../../../global';
import {
  sortZrVideoOrder,
  sortOrderByAlphabetical,
  sortOrderBySaveGallery,
  sortBasicAttendeeList,
  sortUserToSpeaclOrder,
} from '../../../../global/service/sortAttendeeList';
import {
  updateAllAttendees,
  updateAllXmppAllowTalkList,
} from '../../../../reducers/AttendeesListReducer';
import { processGalleryOrderList } from '../../utils';
import { GALLERY_ORDER_TYPE } from '../../enum';
import { WS_CONF_SET_GALLERY_REVIEW_SORT_TYPE } from '../../../../constants/ZoomSocketEventTypes';
import { sendSocketMessage } from '../../../../actions/SocketActions';

export function onLoadCustomVideoOrderThunk(list) {
  return (dispatch, getState) => {
    dispatch(setIsDownloadingViewOrder(false));
    const sortedList = processGalleryOrderList(list);
    if (sortedList && sortedList.length) {
      dispatch(setCustomGalleryViewOrder(sortedList));
      const state = getState();
      const attendees = inMeetingParticipantsSelector(state);
      const newSortedAttendees = produce(attendees, (draft) => {
        return sortZrVideoOrder(sortOrderBySaveGallery(sortedList, draft));
      });
      const currentUser = currentUserSelector(state);
      const isCurrentCoOrHost = isCoOrHost(currentUser);
      if (isCurrentCoOrHost) {
        dispatch(setGalleryOrderTypeThunk(GALLERY_ORDER_TYPE.DEFAULT));
        dispatch(setHasChangedVideoOrder(true));
      }
      dispatch(updateAllAttendees({ list: newSortedAttendees }));
    }
  };
}

/*
  setGalleryOrder and sort order
  special case
  GALLERY_ORDER_TYPE.DEFAULT => next order
                             => basic order
*/
export function setGalleryOrderTypeThunk(type, keepCurrentOrder = true) {
  return (dispatch, getState) => {
    const {
      meeting: { bFollowHostVideo, isHost },
      video: { galleryOrderType },
    } = getState();

    if (!isSupportGallerySortMode()) {
      return;
    }

    if (galleryOrderType === type && !bFollowHostVideo) {
      return;
    }

    dispatch(setGalleryOrderType(type));
    // follow host video case broadcast to all
    if (isHost && bFollowHostVideo) {
      dispatch(
        sendSocketMessage({
          evt: WS_CONF_SET_GALLERY_REVIEW_SORT_TYPE,
          body: {
            sortType: type,
          },
        }),
      );
    }

    dispatch(setIsGalleryOrderMode(type !== GALLERY_ORDER_TYPE.DEFAULT));
    if (type === GALLERY_ORDER_TYPE.DEFAULT) {
      if (keepCurrentOrder) {
        dispatch(setHasChangedVideoOrder(true));
      } else {
        dispatch(sortBasicAttendeeListThunk());
      }
    } else {
      dispatch(sortGalleryOrderByAlphabeticalThunk());
      dispatch(setHasChangedVideoOrder(true));
    }
  };
}

// sort by alphabetical
export function sortGalleryOrderByAlphabeticalThunk() {
  return (dispatch, getState) => {
    const {
      video: { galleryOrderType },
      meeting: {
        currentUser: { userId },
        bFollowHostVideo,
      },
      attendeesList,
    } = getState();
    const hostUser = attendeesList.attendeesList.find((item) => item.isHost);

    const newSortedAttendees = produce(attendeesList, (draft) => {
      return sortOrderByAlphabetical(draft, galleryOrderType);
    });
    const newSortedAttendees2 = produce(newSortedAttendees, (draft) => {
      let rule = [
        {
          userId,
          position: 1,
        },
      ];

      if (
        bFollowHostVideo &&
        checkIsHostSupportGallerySortType(hostUser?.caps)
      ) {
        rule = [
          {
            userId: hostUser?.userId,
            position: 1,
          },
          {
            userId,
            position: 2,
          },
        ];
      }

      return sortUserToSpeaclOrder(draft, rule);
    });

    dispatch(updateAllAttendees({ list: newSortedAttendees2.attendeesList }));
    dispatch(
      updateAllXmppAllowTalkList({
        list: newSortedAttendees2.xmppAllowTalkList,
      }),
    );
  };
}

// sort to origin sort
function sortBasicAttendeeListThunk() {
  return (dispatch, getState) => {
    const {
      attendeesList,
      meeting: {
        currentUser: { userId },
      },
    } = getState();
    const newSortedAttendees = produce(attendeesList, (draft) => {
      return sortBasicAttendeeList(draft, userId);
    });

    dispatch(updateAllAttendees({ list: newSortedAttendees.attendeesList }));
    dispatch(
      updateAllXmppAllowTalkList({
        list: newSortedAttendees.xmppAllowTalkList,
      }),
    );
  };
}

// old client host set sort type to none
export function newHostGallerySortTypeModifyThunk(user) {
  return (dispatch, getState) => {
    const { attendeesList } = getState();
    const newHost =
      attendeesList.attendeesList?.find((item) => item.userId === user.id) ||
      {};
    if (!checkIsHostSupportGallerySortType(newHost?.caps)) {
      dispatch(setGalleryOrderType(GALLERY_ORDER_TYPE.DEFAULT));
    }
  };
}
