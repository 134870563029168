import {
  setInterpretationStart,
  setInterpretationList,
  setInterpretationLangType,
  setInterpretationTooltipVisible,
  setInterpretationMute,
  signLanguageInterpretationLangSelected,
  signLanguagesUpdated,
  signLanguageInterpretationStarted,
  signLanguageInterpretationAllowToTalkChanged,
  setInterpretationWindowVisible,
  setInterpreterList,
  interpreterWindowShowTypeChanged,
} from './interpretation-action';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { resetInterpreters } from '../../../reducers/AttendeesListReducer';
import {
  WS_AUDIO_SET_PARTICIPANT_ACTIVE_LANGUAGE,
  WS_CONF_HOST_START_STOP_UPDATE_INTERPERTATION_REQ,
} from '../../../constants/ZoomSocketEventTypes';
import {
  notifySdkToSelectLanguageChannel,
  notifySdkToEnableInterpretation,
  notifySdkToDisableInterpretation,
  isOriginalAudio,
  notifySdkToUpdateInterpreterList,
  notifySdkToMuteOrUnmuteOriginalAudio,
} from '../service';
import { isJoinVoIP, isJoinAudio } from '../../../global/service';
import {
  DisallowTipType,
  UnmuteByHostType,
} from '../../../features/dialog/enum';
import { disallowTip } from '../../../features/dialog/components/disallow-tip';
import {
  muteAudio,
  attendeeReadyToTalkByVoip,
  mutedOrUnMutedByHost,
} from '../../../features/audio/redux/audio-thunk-action';
import {
  audioInterpretationLanguageFlatListSelector,
  isCurrentUserSignLanguageInterpreterSelector,
  isCurrentUserSignLanguageInterpreterAllowedToTalkSelector,
} from './interpretation-selector';
import {
  INTERPRETATION_LANG_TYPES,
  SIGN_LANGUAGE_INTERPRETATION_LANG_TYPES,
} from '../constant';
import { isNotInRoom } from '../../breakout-room/utils';
import { WS_CONF_ROSTER_INDICATION } from '../../../constants/MeetingActionTypes';
import AliveToast from '../../../global/containers/notification-manager/alive-toast';
import {
  INTERPRETATION_END_TIP,
  SIGN_LANGUAGE_INTERPRETATION_END_TIP,
} from '../resource';
import meetingConfig from 'meetingConfig';
import {
  isUserSignLanguageInterpreter,
  getUserActiveSignLanguageInterpretationLanguageID,
} from '../util';
import { ACTIVE_VIDEO } from '../../video/enum';
import { setUnmuteByHostDialogVisible } from '../../../features/dialog/redux/dialog-action';
import { interpreterIdsSelector } from '../../../global/redux/selector';
import { easyStore, storeType } from '../../../global/easy-store';

const notifyInterpreterList = (isForceUpdateInterpreter) => {
  return (dispatch, getState) => {
    const {
      attendeesList: { attendeesList },
    } = getState();
    if (attendeesList.length === 0) {
      setTimeout(() => {
        dispatch(notifyInterpreterList());
      }, 800);
      return;
    }
    const previousInterpreterIdList = easyStore.easyGet('online-interpreters');
    const interpreterIdList = interpreterIdsSelector(getState());
    if (
      isForceUpdateInterpreter ||
      !previousInterpreterIdList ||
      !_.isEqual(
        _.sortBy(previousInterpreterIdList),
        _.sortBy(interpreterIdList),
      )
    ) {
      notifySdkToUpdateInterpreterList(interpreterIdList);
      easyStore.easySet(
        'online-interpreters',
        interpreterIdList,
        storeType.memory,
      );
    }
  };
};

export const selectInterpretationLanguage = (type) => {
  return (dispatch) => {
    dispatch(setInterpretationLangType(type));
    dispatch(
      sendSocketMessage({
        evt: WS_AUDIO_SET_PARTICIPANT_ACTIVE_LANGUAGE,
        body: {
          language: type,
        },
      }),
    );
    // value 10 is original-audio for sdk,-1 is for RWG
    notifySdkToSelectLanguageChannel(isOriginalAudio(type) ? 10 : type);
  };
};
/**
 * this function will call all of the sdk interfaces for interpretation
 * this will be called when
 * 1.media sdk first initialize successfully
 * 2.after failover and auto join computer audio
 */
export const initSdkInterpretationChannel = () => {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
      interpretation: { isStart, signLanguageStarted, languageType, isMute },
      breakoutRoom: {
        attendee: { status },
      },
    } = getState();

    if (isStart && isNotInRoom(status)) {
      dispatch(setInterpretationTooltipVisible(true));
      notifySdkToEnableInterpretation();
      dispatch(notifyInterpreterList(true));
      dispatch(selectInterpretationLanguage(languageType));
      if (!isOriginalAudio(languageType)) {
        notifySdkToMuteOrUnmuteOriginalAudio(isMute);
      }
    }

    if (
      signLanguageStarted &&
      isNotInRoom(status) &&
      isCurrentUserSignLanguageInterpreterSelector(getState())
    ) {
      if (
        !isCurrentUserSignLanguageInterpreterAllowedToTalkSelector(getState())
      ) {
        dispatch(muteAudio(currentUser.userId));
        disallowTip({
          type: DisallowTipType.INTERPRETER_MICROPHONE,
          dispatch,
        });
      }

      dispatch(setInterpretationTooltipVisible(false));
    }
  };
};

export const resetToOriginalAudioChanel = () => {
  return (dispatch) => {
    dispatch(setInterpretationMute(false));
    notifySdkToMuteOrUnmuteOriginalAudio(false);
    dispatch(selectInterpretationLanguage(INTERPRETATION_LANG_TYPES.ORIGINAL));
  };
};

export const handleInterpretationStart = (body) => {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();
    const isStart = body.bLanguageStart;
    dispatch(setInterpretationStart(isStart));
    if (isJoinVoIP(currentUser)) {
      if (isStart) {
        dispatch(initSdkInterpretationChannel());
      } else {
        AliveToast.toast(INTERPRETATION_END_TIP);
        dispatch(setInterpretationTooltipVisible(false));
        dispatch(resetToOriginalAudioChanel());
        notifySdkToDisableInterpretation();
      }
    }

    if (!isStart) {
      dispatch(resetInterpreters());
      dispatch(setInterpretationList([]));
    }
  };
};

export const handleInterpretersChange = (body) => {
  return (dispatch) => {
    let interpreters = body;
    if (!Array.isArray(body)) {
      interpreters = [body];
    }
    interpreters.forEach((interpreter) => {
      const { userID, bIsInterpreter, nActiveLanguage } = interpreter;
      dispatch({
        type: WS_CONF_ROSTER_INDICATION,
        message: {
          body: {
            update: [
              {
                isInterpreter: bIsInterpreter,
                activeInterpretationLanguage: nActiveLanguage,
                id: userID,
              },
            ],
          },
        },
      });
    });
    dispatch(notifyInterpreterList());
  };
};

export const handleInterpretationLanguageListChange = (body) => {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
      interpretation: { isStart, languageType },
    } = getState();
    dispatch(setInterpretationList(body.data || []));
    // update interpreter list for manage window
    const interpreterList = (body.data || []).map(
      ({ firstLanguageID, secondLanguageID, user_guid, user_email }) => {
        return {
          firstLanguageID,
          secondLanguageID,
          guid: _.isEmpty(user_guid) ? undefined : user_guid,
          email: user_email,
        };
      },
    );
    dispatch(setInterpreterList(interpreterList));
    // MMR will not send bLanguageStart cmd to us again,so we need to set this flag when receive the list change cmd
    if (!isStart) {
      dispatch(
        handleInterpretationStart({
          bLanguageStart: true,
        }),
      );
    } else if (isJoinVoIP(currentUser)) {
      dispatch(notifyInterpreterList());
    }
    // when lang list is changed, we should reset current user's currentLangugeType if he has selected
    // a non-exist language type
    if (!isOriginalAudio(languageType)) {
      const languages = audioInterpretationLanguageFlatListSelector(getState());
      const lastLanguage = languages.find(
        (language) => language.type === languageType,
      );
      if (!lastLanguage) {
        // reset to original audio
        dispatch(resetToOriginalAudioChanel());
      }
    }
  };
};

export const handleCurrentInterpretationLanguageChange = () => {
  return () => {};
};

export const handleSignLanguageInterpretationStart = (body) => {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();
    const isStarted = body.bStatus;

    const wasCurrentUserSignLanguageInterpreterAllowedToTalk =
      isCurrentUserSignLanguageInterpreterAllowedToTalkSelector(getState());

    dispatch(signLanguageInterpretationStarted(isStarted));
    dispatch(setInterpretationTooltipVisible(isStarted));

    if (isJoinAudio(currentUser)) {
      if (!isStarted) {
        AliveToast.toast(SIGN_LANGUAGE_INTERPRETATION_END_TIP);

        if (
          isCurrentUserSignLanguageInterpreterSelector(getState()) &&
          !wasCurrentUserSignLanguageInterpreterAllowedToTalk
        ) {
          // The current user was an interpreter (who was not allowed to talk) right before sign language interpretation stopped, so show a tooltip
          // indicating their microphone is now allowed.
          disallowTip({
            type: DisallowTipType.INTERPRETER_MICROPHONE_ALLOWED,
            dispatch,
          });
        }
      }
    }

    if (!isStarted) {
      dispatch(
        signLanguageInterpretationLangSelected(
          SIGN_LANGUAGE_INTERPRETATION_LANG_TYPES.ORIGINAL,
        ),
      );

      dispatch(signLanguagesUpdated([]));
    }
  };
};

export const handleSignLanguageInterpretersChange = (body) => {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();

    const wasCurrentUserInterpreter =
      isCurrentUserSignLanguageInterpreterSelector(getState());

    dispatch(signLanguagesUpdated(body.data));

    // Since the current user is a web client user, we don't need to check for sign language
    // capabilities since we know we support it. Only need to check other user's capabilities.
    const isCurrentUserSignLanguageInterpreter = isUserSignLanguageInterpreter(
      currentUser,
      body.data,
      false,
    );

    if (isCurrentUserSignLanguageInterpreter) {
      // force the current user to be in the channel they are interpreting for
      dispatch(
        signLanguageInterpretationLangSelected(
          getUserActiveSignLanguageInterpretationLanguageID(
            currentUser,
            body.data,
          ),
        ),
      );
    }

    if (isCurrentUserSignLanguageInterpreter && !wasCurrentUserInterpreter) {
      // the current user is becoming a sign language interpreter, so hide the tooltip (because they can't change their language now), mute them
      dispatch(setInterpretationTooltipVisible(false));

      if (isJoinAudio(currentUser)) {
        dispatch(initSdkInterpretationChannel());
      }
    } else if (
      !isCurrentUserSignLanguageInterpreter &&
      wasCurrentUserInterpreter &&
      isJoinAudio(currentUser)
    ) {
      // the current user was an interpreter, but is not anymore.
      disallowTip({
        type: DisallowTipType.INTERPRETER_MICROPHONE_ALLOWED,
        dispatch,
      });

      dispatch(interpreterWindowShowTypeChanged(ACTIVE_VIDEO));
    }
  };
};

export const handleChangeSignLanguageInterpreterHostOrCoHostPrivilege = (
  isHostOrCoHost,
) => {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();

    const isCurrentUserSignLanguageInterpreter =
      isCurrentUserSignLanguageInterpreterSelector(getState());

    if (isJoinAudio(currentUser) && isCurrentUserSignLanguageInterpreter) {
      if (!isHostOrCoHost) {
        dispatch(initSdkInterpretationChannel());
      } else {
        disallowTip({
          type: DisallowTipType.INTERPRETER_MICROPHONE_ALLOWED,
          dispatch,
        });
      }
    }
  };
};

export const handleSignLanguageAllowInterpreterToTalk = (body) => {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();

    dispatch(signLanguageInterpretationAllowToTalkChanged(body.data));

    const currentUserEntry = body.data.find(
      (entry) => entry.user_id === currentUser.userId,
    );

    if (currentUserEntry) {
      if (currentUserEntry.bStatus === true) {
        dispatch(attendeeReadyToTalkByVoip());
        if (isJoinAudio(currentUser)) {
          disallowTip({
            type: DisallowTipType.INTERPRETER_MICROPHONE_ALLOWED,
            dispatch,
          });
        }
      } else if (isJoinAudio(currentUser)) {
        dispatch(
          setUnmuteByHostDialogVisible({
            visible: false,
            type: UnmuteByHostType.UNMUTE_ALLOW_TALK,
          }),
        );
        dispatch(mutedOrUnMutedByHost({ muted: true }));
        dispatch(muteAudio(currentUser.userId));
        disallowTip({
          type: DisallowTipType.INTERPRETER_MICROPHONE,
          dispatch,
        });
      }
    }
  };
};

export const handleManageInterpretation = (status, interpreterList) => {
  return (dispatch) => {
    dispatch(setInterpretationWindowVisible(false));
    dispatch(setInterpreterList(interpreterList));
    dispatch(
      sendSocketMessage({
        evt: WS_CONF_HOST_START_STOP_UPDATE_INTERPERTATION_REQ,
        body: {
          nStatus: status,
          interpreterInfoList: interpreterList,
        },
      }),
    );
  };
};

export const initInterpreterList = () => {
  return (dispatch, getState) => {
    const {
      interpretation: { isStart, isInterpreterListInit },
    } = getState();

    if (isStart || isInterpreterListInit) {
      return;
    }
    const interperterInfo = meetingConfig.meetingInterpretationInfo;
    if (
      !interperterInfo?.isEnableLanguageInterpretation ||
      !interperterInfo.info
    ) {
      return;
    }
    const interperterList = interperterInfo.info.map(
      ({ email, firstLangId, secondLangId }) => {
        return {
          email,
          firstLanguageID: firstLangId,
          secondLanguageID: secondLangId,
        };
      },
    );
    dispatch(setInterpreterList(interperterList));
  };
};
