import meetingConfig from 'meetingConfig';
import { CHAT_FILE_TYPE } from '../../../features/chat/constants';
import { JOIN_MEETING_POLICY } from '../../constant';
import { easyStore } from '../../easy-store';
import { decodeBase64 } from '../../util';
import _ from 'lodash';
import { beginDecrypt, ivType } from '../../crypto';
import { extractFileInfoFromXml } from '../../../features/new-chat/transform/cover';
import { RWGMessage, addFileMessage } from './thunk';
import {
  getCorrect3rdPartyFileUrl,
  getFileDownloadUrl,
} from '../../../features/chat/utils';
import { produce } from 'immer';
import { MessageResExt } from '../@types/type';
import { messageType, operaType } from '../enums';
import { restructure } from './action';
import { errorLog } from '../../web-client-logger';

type fileInfoType = {
  fileID: string;
  fileType: string;
  fileName: string;
  fileSize: string;
  fileObj: string;
  senderName: string;
  shareType: string;
  previewUrl: string;
  previewPath: string;
  downloadUrl: string;
  destNodeID: number;
};

// type fileInfo2 = {
//   senderSN?: string;
//   fileName: string;
//   fileSize: string;
//   xmppMsgId?: string;
// } & fileInfoType;

type RWGfileBody = fileInfoType & {
  xmppMsgData: string;
  sn?: string;
  fileType: number;
  msgID: string;
  attendeeNodeID: number;
  destNodeID: number;
  text?: string;
};

export function onReceiveOneChatFileMessage(message: RWGMessage<RWGfileBody>) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: {
        bIbDisableChat,
        // xmppUserList: { attendees },
        // defaultAvatar,
        restrictFeatures,
        fileServerDomain,
      },
      attendeesList: { attendeesList },
      // newChat: { meetingChat },
    } = state;

    if (restrictFeatures[JOIN_MEETING_POLICY.CHAT]) return;
    const { xmppMsgData } = message.body || {};

    const isNewXmppChat = !!xmppMsgData;
    if (typeof message.body !== 'undefined' && !bIbDisableChat) {
      const latestChatMessage = message.body;
      let senderSN = latestChatMessage.sn;
      let targetUser;
      let senderName = decodeBase64(latestChatMessage.senderName);
      let senderUser = {
        senderSN,
        senderName,
      };
      /* eslint-disable-next-line no-prototype-builtins */
      if (!senderSN || !latestChatMessage.hasOwnProperty('senderName')) {
        targetUser = attendeesList.find(
          (user) => user.userId === latestChatMessage.destNodeID,
        );
        if (targetUser) {
          senderSN = targetUser.zoomID;
          senderName = targetUser.displayName;
          senderUser = targetUser;
        }
      }

      const is3rdFileShare =
        latestChatMessage.fileType !== CHAT_FILE_TYPE.LOCAL;

      let fileInfo;
      const promiseList: PromiseLike<{ message: string }>[] = [];
      if (!isNewXmppChat) {
        // old version
        if (
          !_.isNil(latestChatMessage.fileType) &&
          easyStore.easyGet('gcmEnabled')
        ) {
          fileInfo = _.pick(latestChatMessage, [
            'fileID',
            'fileType',
            'fileName',
            'fileSize',
            'fileObj',
            'senderName',
            'shareType',
            'previewUrl',
            'previewPath',
            'downloadUrl',
            'destNodeID',
          ]);
          fileInfo.senderSN = senderSN;
          fileInfo.fileUrl = getFileDownloadUrl(fileInfo, fileServerDomain);
          fileInfo.correctPreviewUrl = getCorrect3rdPartyFileUrl(fileInfo);
        }
        if (fileInfo) {
          if (!is3rdFileShare) {
            // receive local file
            if (meetingConfig.fileTransfer.isEnableFileTransferEncrypted) {
              promiseList.push(
                beginDecrypt({
                  decryptedText: fileInfo.fileName,
                  userId: senderSN,
                  type: ivType.RWG_CHAT,
                }),
              );
              promiseList.push(
                beginDecrypt({
                  decryptedText: fileInfo.fileSize,
                  userId: senderSN,
                  type: ivType.RWG_CHAT,
                }),
              );
            } else {
              promiseList.push(
                Promise.resolve({ message: decodeBase64(fileInfo.fileName) }),
              );
              promiseList.push(
                Promise.resolve({ message: decodeBase64(fileInfo.fileSize) }),
              );
            }
          } else {
            promiseList.push(Promise.resolve({ message: fileInfo.fileName }));
            promiseList.push(Promise.resolve({ message: fileInfo.fileSize }));
          }
          Promise.all(promiseList).then(
            ([{ message: fileName }, { message: fileSize }]) => {
              fileInfo.fileName = fileName;
              fileInfo.fileSize = fileSize;
              fileInfo.xmppMsgId = latestChatMessage.msgID;
              fileInfo.reply = {};
              fileInfo.timeStamp = Date.now();

              dispatch(addFileMessage(fileInfo, message, senderUser));
            },
          );
        }
      } else {
        promiseList.push(
          beginDecrypt({
            decryptedText: latestChatMessage.xmppMsgData,
            userId: senderSN,
            type: ivType.RWG_CHAT,
          }).then(({ message }) => {
            return extractFileInfoFromXml(message);
          }),
        );
        Promise.all(promiseList).then(([_fileInfo]) => {
          fileInfo = _fileInfo;
          // for download dialog, raw base64 string
          fileInfo.senderName = latestChatMessage.senderName;
          fileInfo.destNodeID = latestChatMessage.destNodeID;
          fileInfo.correctPreviewUrl = getCorrect3rdPartyFileUrl(fileInfo);
          if (!is3rdFileShare) {
            // fileInfo.uploadBaseInfo = { fileMsgIndex: meetingChat2.length };
            fileInfo.senderSN = senderSN;
            fileInfo.fileUrl = getFileDownloadUrl(fileInfo, fileServerDomain);
          }
          dispatch(addFileMessage(fileInfo, message, senderUser));
        });
      }
    }
  };
}

export const clearAllOneChatFileMessage = () => {
  return (dispatch, getState) => {
    const {
      oneChat: { list },
    } = getState();
    try {
      const newMeetingChat = produce(list, (draft: MessageResExt[]) => {
        draft.forEach((msg) => {
          // revoke file and mix
          if (
            msg.messageType === messageType.file ||
            msg.messageType === messageType.mix
          ) {
            msg.operType = operaType.revoke;

            if (msg.comments?.length) {
              msg.comments.forEach((comment) => {
                if (
                  comment.messageType === messageType.file ||
                  msg.messageType === messageType.mix
                ) {
                  comment.operType = operaType.revoke;
                }
              });
            }
          }
        });
      });

      return dispatch(restructure(newMeetingChat));
    } catch (error) {
      errorLog(error);
    }
  };
};
