// be careful, don't do any import in this file!!!

let GlobalVariableStore = {};

export const setGlobalVariableStore = (obj) => {
  Object.assign(GlobalVariableStore, obj);
};

export const cleanGlobalVariableStore = () => {
  GlobalVariableStore = {};
};

export const getGlobalVariableStore = () => {
  return GlobalVariableStore;
};
