import React, { Component } from 'react';
import { DOM_KEY_CODE } from '../../enum';

const performClick = (keyCode, rootJDom, targetJDom, backupTargetJDom) => {
  if (targetJDom) {
    targetJDom.click();
  } else if (!targetJDom && backupTargetJDom) {
    backupTargetJDom.click();
  } else {
    const target = rootJDom.querySelectorAll('[data-a-l]')[0];
    target.focus();
    target.click();
  }
};

const tryRestoreSelectedTab = (keyCode, rootJDom, targetDom) => {
  if (
    keyCode === DOM_KEY_CODE.TAB &&
    targetDom.getAttribute('role') === 'tab'
  ) {
    rootJDom.querySelectorAll('[aria-selected=true]')[0].focus();
    return false;
  }
  return true;
};

const focusFallbackNode = (fallbackDomSelectors) => {
  if (fallbackDomSelectors) {
    fallbackDomSelectors.every((s) => {
      const jDom = document.querySelector(s);
      if (jDom) {
        jDom.focus();
        return false;
      }
      return true;
    });
  }
};

function withFocusWalker(
  Wrappee,
  rootId,
  openPropSubscriber,
  fallbackDomSelectors,
  keyboardEventHandler,
  injectEventHandler = false,
) {
  const performFocus = (keyCode, rootJDom, targetJDom, backupTargetJDom) => {
    if (targetJDom) {
      if (tryRestoreSelectedTab(keyCode, rootJDom, targetJDom)) {
        targetJDom.focus();
      }
    } else if (!targetJDom && backupTargetJDom) {
      if (tryRestoreSelectedTab(keyCode, rootJDom, backupTargetJDom)) {
        backupTargetJDom.focus();
      }
    } else {
      focusFallbackNode(fallbackDomSelectors);
    }
  };
  if (!keyboardEventHandler) {
    throw new Error('Required parameter keyboardEventHandler');
  }

  // eslint-disable-next-line react/display-name
  return class extends Component {
    getWalkJDoms = (currentDom) => {
      const rootJDom = document.getElementById(rootId);
      let currentKey;
      let currentLevelFirstKey;
      let currentLevelFirstJDom;
      let nextKey;
      let nextJDom;
      let prevKey;
      let prevJDom;
      let parentKey;
      let parentJDom;
      let parentNextKey;
      let parentNextJDom;
      let parentNextFirstChildKey;
      let parentNextFirstChildJDom;
      let parentPrevKey;
      let parentPrevJDom;
      let firstChildKey;
      let firstChildJDom;
      let firstChildFirstChildKey;
      let firstChildFirstChildJDom;
      let parentParentKey;
      let parentParentJDom;
      let parentParentPrevKey;
      let parentParentPrevJDom;
      let parentParentNextKey;
      let parentParentNextJDom;
      let parentParentNextFirstChildKey;
      let parentParentNextFirstChildJDom;
      let firstListNodeKey;
      let firstListNodeJDom;
      let prevFirstListNodeKey;
      let prevFirstListNodeJDom;
      let prevListLastNodeKey;
      let prevListLastNodeJDom;
      let parentListLastNodeKey;
      let parentListLastNodeJDom;
      let parentListFirstNodeKey;
      let parentListFirstNodeJDom;
      let lastListNodeKey;
      let lastListNodeJDom;
      let nextLastListNodeKey;
      let nextLastListNodeJDom;
      let parentLevelFirstChildKey;
      let parentLevelFirstChildJDom;
      let parentParentLevelFirstChildKey;
      let parentParentLevelFirstChildJDom;
      let parentPrevSamePositionChildKey;
      let parentPrevSamePositionChildJDom;
      let parentNextSamePositionChildKey;
      let parentNextSamePositionChildJDom;

      currentKey = currentDom.getAttribute('data-a-l');

      const prevTempArr = currentKey.split('-');
      prevTempArr[prevTempArr.length - 1] =
        +prevTempArr[prevTempArr.length - 1] - 1;
      prevKey = prevTempArr.join('-');
      prevJDom = rootJDom.querySelector(`[data-a-l="${prevKey}"]`);

      const nextTempArr = currentKey.split('-');
      nextTempArr[nextTempArr.length - 1] =
        +nextTempArr[nextTempArr.length - 1] + 1;
      nextKey = nextTempArr.join('-');
      nextJDom = rootJDom.querySelector(`[data-a-l="${nextKey}"]`);

      parentKey = currentKey.substring(0, currentKey.lastIndexOf('-'));
      parentJDom = rootJDom.querySelector(`[data-a-l="${parentKey}"]`);

      const currentLevelFirstTempArr = currentKey.split('-');
      currentLevelFirstTempArr[currentLevelFirstTempArr.length - 1] = 0;
      currentLevelFirstKey = currentLevelFirstTempArr.join('-');
      currentLevelFirstJDom = rootJDom.querySelector(
        `[data-a-l="${currentLevelFirstKey}"]`,
      );

      // same level node searching pending

      const parentPrevTempArr = parentKey.split('-');
      parentPrevTempArr[parentPrevTempArr.length - 1] =
        +parentPrevTempArr[parentPrevTempArr.length - 1] - 1;
      parentPrevKey = parentPrevTempArr.join('-');
      parentPrevJDom = rootJDom.querySelector(`[data-a-l="${parentPrevKey}"]`);

      const parentNextTempArr = parentKey.split('-');
      parentNextTempArr[parentNextTempArr.length - 1] =
        +parentNextTempArr[parentNextTempArr.length - 1] + 1;
      parentNextKey = parentNextTempArr.join('-');
      parentNextJDom = rootJDom.querySelector(`[data-a-l="${parentNextKey}"]`);
      parentNextTempArr[parentNextTempArr.length - 1] =
        +parentNextTempArr[parentNextTempArr.length - 1] + 1;

      parentNextFirstChildKey = `${parentNextKey}-0`;
      parentNextFirstChildJDom = rootJDom.querySelector(
        `[data-a-l="${parentNextFirstChildKey}"]`,
      );

      const getParentNextNthJDom = (x) => {
        const tempArr = parentKey.split('-');
        tempArr[tempArr.length - 1] = +tempArr[tempArr.length - 1] + x;

        return rootJDom.querySelector(`[data-a-l="${tempArr.join('-')}"]`);
      };

      firstChildKey = `[data-a-l="${currentKey}-0"]`;
      firstChildJDom = currentDom.querySelector(firstChildKey);

      firstChildFirstChildKey = `[data-a-l="${currentKey}-0-0"]`;
      firstChildFirstChildJDom = currentDom.querySelector(
        firstChildFirstChildKey,
      );

      parentParentKey = parentKey.substring(0, parentKey.lastIndexOf('-'));
      parentParentJDom = parentParentKey
        ? document.querySelector(`[data-a-l="${parentParentKey}"]`)
        : null;

      parentLevelFirstChildKey = `${parentParentKey}-0`;
      parentLevelFirstChildJDom = rootJDom.querySelector(
        `[data-a-l="${parentLevelFirstChildKey}"]`,
      );

      parentParentLevelFirstChildKey = `${parentParentKey.substring(
        0,
        parentParentKey.lastIndexOf('-'),
      )}-0`;
      parentParentLevelFirstChildJDom = rootJDom.querySelector(
        `[data-a-l="${parentParentLevelFirstChildKey}"]`,
      );

      const parentParentPrevTempArr = parentParentKey.split('-');
      parentParentPrevTempArr[parentParentPrevTempArr.length - 1] =
        +parentParentPrevTempArr[parentParentPrevTempArr.length - 1] - 1;
      parentParentPrevKey = parentParentPrevTempArr.join('-');
      parentParentPrevJDom = rootJDom.querySelector(
        `[data-a-l="${parentParentPrevKey}"]`,
      );

      const parentParentNextTempArr = parentParentKey.split('-');
      parentParentNextTempArr[parentParentNextTempArr.length - 1] =
        +parentParentNextTempArr[parentParentNextTempArr.length - 1] + 1;
      parentParentNextKey = parentParentNextTempArr.join('-');
      parentParentNextJDom = rootJDom.querySelector(
        `[data-a-l="${parentParentNextKey}"]`,
      );

      parentParentNextFirstChildKey = `${parentParentNextKey}-0`;
      parentParentNextFirstChildJDom = rootJDom.querySelector(
        `[data-a-l="${parentParentNextFirstChildKey}"]`,
      );

      const firstLastListNodeNodeInfo =
        currentDom.getAttribute('data-a-l-list-info');
      if (firstLastListNodeNodeInfo) {
        [firstListNodeKey, lastListNodeKey] = firstLastListNodeNodeInfo
          .split(',')
          .map((v) => v.trim());
        firstListNodeJDom = rootJDom.querySelector(
          `[data-a-l="${firstListNodeKey}"]`,
        );
        lastListNodeJDom = rootJDom.querySelector(
          `[data-a-l="${lastListNodeKey}"]`,
        );

        const prevFirstListNodeTempArr = firstListNodeKey.split('-');
        prevFirstListNodeTempArr[prevFirstListNodeTempArr.length - 1] =
          +prevFirstListNodeTempArr[prevFirstListNodeTempArr.length - 1] - 1;
        prevFirstListNodeKey = prevFirstListNodeTempArr.join('-');
        prevFirstListNodeJDom = rootJDom.querySelector(
          `[data-a-l="${prevFirstListNodeKey}"]`,
        );

        const nextLastListNodeTempArr = lastListNodeKey.split('-');
        nextLastListNodeTempArr[nextLastListNodeTempArr.length - 1] =
          +nextLastListNodeTempArr[nextLastListNodeTempArr.length - 1] + 1;
        nextLastListNodeKey = nextLastListNodeTempArr.join('-');
        nextLastListNodeJDom = rootJDom.querySelector(
          `[data-a-l="${nextLastListNodeKey}"]`,
        );
      }

      const prevDomListInfo = prevJDom?.getAttribute('data-a-l-list-info');
      if (prevDomListInfo) {
        prevListLastNodeKey = prevDomListInfo.split(',')[1];
        prevListLastNodeJDom = rootJDom.querySelector(
          `[data-a-l="${prevListLastNodeKey}"]`,
        );
      }

      const parentDomListInfo = parentJDom?.getAttribute('data-a-l-list-info');
      if (parentDomListInfo) {
        [parentListFirstNodeKey, parentListLastNodeKey] =
          parentDomListInfo.split(',');
        parentListLastNodeJDom = rootJDom.querySelector(
          `[data-a-l="${parentListLastNodeKey}"]`,
        );

        parentListFirstNodeJDom = rootJDom.querySelector(
          `[data-a-l="${parentListFirstNodeKey}"]`,
        );
      }
      const parentSamePositionChildTempArr = currentKey.split('-');
      if (parentKey && parentSamePositionChildTempArr.length >= 2) {
        const parentPrevSamePositionChildTempArr = [
          ...parentSamePositionChildTempArr,
        ];
        parentPrevSamePositionChildTempArr[
          parentPrevSamePositionChildTempArr.length - 2
        ] =
          +parentPrevSamePositionChildTempArr[
            parentPrevSamePositionChildTempArr.length - 2
          ] - 1;
        parentPrevSamePositionChildKey =
          parentPrevSamePositionChildTempArr.join('-');
        parentPrevSamePositionChildJDom = rootJDom.querySelector(
          `[data-a-l="${parentPrevSamePositionChildKey}"]`,
        );

        const parentNextSamePositionChildTempArr = [
          ...parentSamePositionChildTempArr,
        ];
        parentNextSamePositionChildTempArr[
          parentNextSamePositionChildTempArr.length - 2
        ] =
          +parentNextSamePositionChildTempArr[
            parentNextSamePositionChildTempArr.length - 2
          ] + 1;
        parentNextSamePositionChildKey =
          parentNextSamePositionChildTempArr.join('-');
        parentNextSamePositionChildJDom = rootJDom.querySelector(
          `[data-a-l="${parentNextSamePositionChildKey}"]`,
        );
      }
      const getParent = (dom) => {
        if (!dom) return null;
        const currentKey = dom.getAttribute('data-a-l');
        const parentKey = currentKey.substring(0, currentKey.lastIndexOf('-'));
        const parentJDom = rootJDom.querySelector(`[data-a-l="${parentKey}"]`);
        return parentJDom;
      };
      const getFirstChild = (dom) => {
        if (!dom) return null;
        const domListInfo = dom?.getAttribute('data-a-l-list-info');
        if (domListInfo) {
          const firstNodeKey = domListInfo.split(',')[0];
          return rootJDom.querySelector(`[data-a-l="${firstNodeKey}"]`);
        }
        return null;
      };
      const getNext = (dom) => {
        if (!dom) return null;
        const currentKey = dom.getAttribute('data-a-l');

        const nextTempArr = currentKey.split('-');
        nextTempArr[nextTempArr.length - 1] =
          +nextTempArr[nextTempArr.length - 1] + 1;
        const nextKey = nextTempArr.join('-');
        return rootJDom.querySelector(`[data-a-l="${nextKey}"]`);
      };

      const getPrev = (dom) => {
        if (!dom) return null;
        const currentKey = dom.getAttribute('data-a-l');

        const nextTempArr = currentKey.split('-');
        nextTempArr[nextTempArr.length - 1] =
          +nextTempArr[nextTempArr.length - 1] - 1;
        const nextKey = nextTempArr.join('-');
        return rootJDom.querySelector(`[data-a-l="${nextKey}"]`);
      };

      return {
        rootJDom,
        currentKey,
        currentDom,
        currentLevelFirstKey,
        currentLevelFirstJDom,
        nextKey,
        nextJDom,
        prevKey,
        prevJDom,
        parentKey,
        parentJDom,
        parentNextKey,
        parentNextJDom,
        getParentNextNthJDom,
        parentPrevKey,
        parentPrevJDom,
        firstChildKey,
        firstChildJDom,
        firstChildFirstChildKey,
        firstChildFirstChildJDom,
        parentParentKey,
        parentParentJDom,
        parentParentPrevKey,
        parentParentPrevJDom,
        parentParentNextKey,
        parentParentNextJDom,
        firstListNodeKey,
        firstListNodeJDom,
        prevFirstListNodeKey,
        prevFirstListNodeJDom,
        lastListNodeKey,
        lastListNodeJDom,
        nextLastListNodeKey,
        nextLastListNodeJDom,
        parentLevelFirstChildKey,
        parentLevelFirstChildJDom,
        parentParentLevelFirstChildKey,
        parentParentLevelFirstChildJDom,
        prevListLastNodeJDom,
        parentListLastNodeKey,
        parentListLastNodeJDom,
        parentListFirstNodeKey,
        parentListFirstNodeJDom,
        parentNextSamePositionChildJDom,
        parentPrevSamePositionChildJDom,
        parentNextSamePositionChildKey,
        parentPrevSamePositionChildKey,
        parentNextFirstChildKey,
        parentNextFirstChildJDom,
        parentParentNextFirstChildKey,
        parentParentNextFirstChildJDom,
        getParent,
        getFirstChild,
        getNext,
        getPrev,
      };
    };

    a11yKeyEventHandler = (e) => {
      const { target: currentDom, keyCode } = e;

      const { TAB, UP, DOWN, LEFT, RIGHT, PAGE_UP, PAGE_DOWN } = DOM_KEY_CODE;

      if (
        [TAB, UP, DOWN, LEFT, RIGHT, PAGE_UP, PAGE_DOWN].every(
          (whiteListKey) => whiteListKey !== keyCode,
        )
      ) {
        return true;
      }

      if (!currentDom.getAttribute('data-a-l')) {
        return true;
      }

      const allDomNodes = this.getWalkJDoms(currentDom);
      const walkerPolicy = Number(
        currentDom.getAttribute('data-a-walk-policy'),
      );
      const hasPerformed = keyboardEventHandler(
        walkerPolicy,
        performFocus,
        performClick,
        e,
        allDomNodes,
      );
      // hasPerformed represent the focusWalker doing the perform successfully,
      // so it's necessary to prevent the Native focus behavior
      if (hasPerformed) {
        e.preventDefault();
        e.stopPropagation();
      }
      return false;
    };

    /* focusoutEventHandler = e => {
      setTimeout(() => {
        const rootDom = document.getElementById(rootId);
        if (rootDom && !rootDom.contains(document.activeElement)) {
          fallbackDomSelectors &&
            fallbackDomSelectors.every(s => {
              const jDom = document.querySelector(s);
              if (jDom) {
                jDom.focus();
                return false;
              }
              return true;
            });
        }
      }, 0);
    }; */

    componentDidMount() {
      this.initEventListener();
    }

    initEventListener = () => {
      setTimeout(() => {
        const rootJDom = document.getElementById(rootId);
        if (!this.eventBind && rootJDom) {
          this.eventBind = true;
          rootJDom.addEventListener('keydown', this.a11yKeyEventHandler, false);
          const startNode = rootJDom.querySelector('[data-a-l="0-0"]');
          if (startNode) {
            startNode.focus();
          } else {
            focusFallbackNode(fallbackDomSelectors);
          }
        }
      }, 0);
    };

    disposeEventListener = () => {
      const rootJDom = document.getElementById(rootId);
      if (rootJDom) {
        rootJDom.removeEventListener('keydown', this.a11yKeyEventHandler);
      }
      this.eventBind = false;
    };

    componentDidUpdate(prevProps) {
      const previousOpenFlag = openPropSubscriber(prevProps);
      const currentOpenFlag = openPropSubscriber(this.props);
      if (previousOpenFlag !== currentOpenFlag && currentOpenFlag) {
        this.initEventListener();
      } else if (previousOpenFlag !== currentOpenFlag && !currentOpenFlag) {
        this.disposeEventListener();
      }
    }

    componentWillUnmount() {
      this.disposeEventListener();
    }

    render() {
      const events = injectEventHandler
        ? {
            initEventListener: this.initEventListener,
            disposeEventListener: this.disposeEventListener,
          }
        : {};
      return <Wrappee {...this.props} {...events} />;
    }
  };
}

export { withFocusWalker };
