export const MSFT_ERROR_CODE = {
  INVALID_PARA: {
    code: 400024,
    message: 'Requested action failed',
  },
  FAILED_ACTION: {
    code: 400027,
    message: 'Webgl context lost',
  },
  COMMON_ERROR: {
    code: 500000,
    message: 'Internal server error',
  },
  AUDIO_MUTE_ERROR: {
    code: 600001,
    message: 'Mute request failed',
  },
  AUDIO_UNMUTE_ERROR: {
    code: 600002,
    message: 'Unmute request failed',
  },
  VIDEO_STOP_ERROR: {
    code: 600003,
    message: 'Stop video request failed',
  },
  VIDEO_START_ERROR: {
    code: 600004,
    message: 'Start video request failed',
  },
};
