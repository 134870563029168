import {
  HTML5_CAPS_OPTION_SUPPORT_INTERPERTATION,
  HTML5_CAPS_OPTION_SUPPORT_SIGN_LANGUAGE,
} from './constant';
import meetingConfig from 'meetingConfig';
import {
  CHAT_FILE_DOWNLOAD_STATUS,
  CHAT_FILE_UPLOAD_STATUS,
} from '../chat/constants';
import { isWebinar } from '../../global/service/meeting-types';
import { isViewOnly } from '../../global/service/user-types';

// to check if host can select a participant to be an interpreter
export const isEnableToBeInterpreter = (user) =>
  Boolean(user?.caps & HTML5_CAPS_OPTION_SUPPORT_INTERPERTATION) &&
  (!isWebinar() || (isWebinar() && !isViewOnly(user.role)));

// to check if participant can be matched as an interpreter when join in meeting
export const isEnableMatchInterpreter = (user) =>
  Boolean(user?.caps & HTML5_CAPS_OPTION_SUPPORT_INTERPERTATION) &&
  user?.bLogIn &&
  (!isWebinar() || (isWebinar() && !isViewOnly(user.role)));

export const isEnableCustomLanTo25 = () =>
  meetingConfig.meetingOptions.isEnableCustomInterpretationLanguage25;

// returns whether or not a user is a sign language interpreter.
export const isUserSignLanguageInterpreter = (
  user,
  signLanguages,
  checkCaps = true,
) => {
  if (
    checkCaps &&
    user.caps &&
    !(user.caps & HTML5_CAPS_OPTION_SUPPORT_SIGN_LANGUAGE)
  ) {
    return false;
  }

  return signLanguages.some((signLanguage) => {
    return isUserSignLanguageInterpreterForLanguage(user, signLanguage);
  });
};

// returns whether or not a user is a sign language interpreter for a given sign language.
export const isUserSignLanguageInterpreterForLanguage = (
  user,
  signLanguage,
) => {
  return (
    user.userGUID === signLanguage.user_guid ||
    (user.bLogIn &&
      signLanguage.user_email != '' &&
      signLanguage.user_email === user.userEmail)
  );
};

// returns the active interpretation language for a user
export const getUserActiveSignLanguageInterpretationLanguageID = (
  user,
  signLanguages,
) => {
  return signLanguages.find((signLanguage) =>
    isUserSignLanguageInterpreterForLanguage(user, signLanguage),
  )?.signLanguageID;
};

export const getIsFileLoading = ({
  uploadStatus,
  uploadCanceled,
  uploadError,
  downloadStatus,
}) => {
  return (
    !uploadCanceled &&
    !uploadError &&
    (uploadStatus === CHAT_FILE_UPLOAD_STATUS.PENDING ||
      uploadStatus === CHAT_FILE_UPLOAD_STATUS.INIT ||
      uploadStatus === CHAT_FILE_UPLOAD_STATUS.COMPLETE ||
      downloadStatus === CHAT_FILE_DOWNLOAD_STATUS.DOWNLOADING)
  );
};
