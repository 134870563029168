import AliveToast from '../../../global/containers/notification-manager/alive-toast';
import { SUSPEND_SUCCESS_TIP } from '../resource';
import { toggleShowParticipantsList } from '../../participants/redux/participants-thunk-action';

export const handleSuspendRes = () => {
  return (dispatch) => {
    AliveToast.toast({
      message: SUSPEND_SUCCESS_TIP,
      showClose: true,
    });
    dispatch(toggleShowParticipantsList(true));
  };
};
