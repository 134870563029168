import {
  setAsnIds,
  setMicrophoneIsForbidden,
  setActiveMicrophone,
  setActiveSpeaker,
  setJoinVoipLoading,
  setDialOutReturnCode,
  setHostMutedAll,
  setSelfMuteOrUnmute,
  setSelfHangUp,
  setJoinAudioDialogVisibleState,
  setIsAllowToTalkUnmuteInProgress,
  setDesktopAudioStatus,
  setIsLeavingAudioForSharingDesktopAudio,
  setIsGuardingAudioContextConflict,
  setShouldUnmuteAfterFinishSharingDesktopAudio,
  setMuteUnmuteActionInProgress,
  audioStatusSent,
  setIsJoinComputerAudioInProgress,
  setMicMutedBySystem,
} from './audio-action';
import { setTalkingTipVisible } from '../../video/redux/video-action';
import * as types from '../../../constants/MeetingActionTypes';
import { UnmuteByHostType } from '../../dialog/enum';
import {
  setUnmuteByHostDialogVisible,
  setJoinVoipTimeoutDialogVisible,
} from '../../dialog/redux/dialog-action';
import {
  isJoinVoIP,
  isJoinAudio,
  setAvStatusInSessionStorage,
  isAskToUnmute,
} from '../../../global/service';
import {
  isSupportAV,
  isSupportOpenMicWhenShareAudio,
} from '../../../global/util';
import { changeRestartWebRtcTime } from '../../../actions/MeetingActions';
import {
  monitorRWGSendCurrentUserAudioRoster,
  sendSocketMessage,
} from '../../../actions/SocketActions';
import { globalVariable } from '../../../global/global-variable';
import { wcToast } from '../../../global/components/widget/toast/wc-toast';
import {
  CallMeStatus,
  LEAVE_COMPUTER_AUDIO,
  WS_AUDIO_VOIP_JOIN_CHANNEL_REQ,
  AudioConnectionStatus,
  CHANGE_AUDIO_MIC,
  CHANGE_AUDIO_SPEAKER,
  AUDIO_ANIMATION_TIMEOUT,
  MICROPHONE,
  SPEAKER,
} from '../enum';
import {
  HOST_MUTED_EVERYONE,
  HOST_MUTED_YOU,
  HOST_SPOTLIGHT_YOU,
} from '../resource';
import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import * as AVNotifyMediaSDKTypes from '../../../constants/AVNotifyMediaSDKTypes';
import {
  externalController,
  CONTROL_MODE_ZOOM_MSG_TYPE,
  CONTROL_MODE_ZOOM_UI_ACTION_TYPE,
} from '../../../controller';
import { coOrHostSelector } from '../../../global/redux/selector';
import {
  voipEnabled,
  initAudioEncode,
  lockableTaskQueueManager,
} from '../service';
import {
  isSupportAudioWorklet,
  isExternalControlledMode,
  isSafari,
  isTeslaMode,
  SESSIONSTORAGE_KEYS,
  isMTRAndroid,
  isYealink,
} from '../../../global';
import { JOIN_MEETING_POLICY } from '../../../global/constant';
import { isShowSuspensionViewSelector } from '../../video/redux/selectors/video-layout-selector';
import { MINIMIZED_VIDEO } from '../../video/enum';
import { notifySdkToDisableInterpretation } from '../../interpretation/service';
import { easyStore, storeType } from '../../../global/easy-store';
import { initSdkInterpretationChannel } from '../../interpretation/redux/interpretation-thunk-action';
import { setLeaveSharingAudio } from '../../sharing/redux/sharing-action';
import { PWAMeetingEvent, sendMsgToPWA } from '../../../global/pwa-integration';
import {
  activeMicrophoneLabelSelector,
  audioProfileSelector,
  hasAudioOptionsSelector,
} from './audio-selector';
import { isAudioBridge } from '../../../global/op-feature-option';
import { makeLogger } from '../../../global/logger';
import {
  isAllowOnlyCanTalk,
  isViewOnly,
} from '../../../global/service/user-types';
import { Job } from '@zoom/common-utils';
import { JOB_ENUM } from '../../../job_enum';
import { AUDIO_PROFILE_TYPE } from '../../settings/constant';
import { getEnableTip } from '../../settings/service';
import AliveToast from '../../../global/containers/notification-manager/alive-toast';
import {
  AVS_TAGS,
  avsLogReport,
} from '../../../global/logger/log-service/avs-laplace-telemetry';

import deviceManager from '../../../device-manager';

export const audioLogger = makeLogger(['Audio']);

let currentUserAsnTimer = null;
let talkingAsnTimer = null;
let joinVoipTimer = null;
let dialOutTimer = null;

let setAttendeeAsnTimerSet = {};

const TALKING_INDICATION_TIMEOUT = 1000;
const JOIN_VOIP_TIMEOUT = 10000;
const DIAL_OUT_RESET_TIMEOUT = 1000;
const notifyExternalControllerAsn = (id, hasAsn) => {
  if (isTeslaMode()) {
    externalController.notifyConnectorZoomMsg(
      CONTROL_MODE_ZOOM_MSG_TYPE.UI,
      CONTROL_MODE_ZOOM_UI_ACTION_TYPE.AUDIO_ASN,
      {
        id,
        hasAsn,
      },
    );
  }
};
export function getAsn(message, isFromMainSession = false) {
  return (dispatch, getState) => {
    if (message && message.body) {
      const data = message.body;
      const asnIds = {};
      const {
        attendeesList: { attendeesList, xmppAllowTalkList },
        meeting: {
          currentUser: { userId: currentUserId },
        },
        video: {
          currentRenderVideo,
          isWebinarOnlyShowContent,
          UI: { selfVideoShowType },
        },
        breakoutRoom: { mainSessionAttendeeList = [] },
      } = getState();
      const currentVideoList = currentRenderVideo.map((item) => item.user);

      const isShowSuspensionView = isShowSuspensionViewSelector(getState());
      ['asn1', 'asn2', 'asn3'].forEach((item) => {
        /* eslint-disable-next-line no-prototype-builtins */
        if (data.hasOwnProperty(item)) {
          const value = data[item];
          asnIds[item] = value;
          if (currentUserId === value) {
            if (currentUserAsnTimer) {
              clearTimeout(currentUserAsnTimer);
              currentUserAsnTimer = null;
            } else {
              dispatch({
                type: types.SET_CURRENTUSER_ASN,
                data: { id: value, hasAsn: true },
              });
              notifyExternalControllerAsn(value, true);
            }
            currentUserAsnTimer = setTimeout(() => {
              dispatch({
                type: types.SET_CURRENTUSER_ASN,
                data: { id: value, hasAsn: false },
              });
              notifyExternalControllerAsn(value, false);
              currentUserAsnTimer = null;
            }, AUDIO_ANIMATION_TIMEOUT);
          }
        }
      });

      [
        ...attendeesList,
        ...xmppAllowTalkList,
        ...(isFromMainSession ? mainSessionAttendeeList : []),
      ].forEach((attendee) => {
        for (let i = 1; i <= 3; i++) {
          const item = `asn${i}`;
          const { userId, displayName } = attendee;
          if (asnIds[item] !== undefined && userId === asnIds[item]) {
            if (
              asnIds.asnUser === undefined &&
              (!currentVideoList.some((item) => item.userId === userId) ||
                (isWebinarOnlyShowContent &&
                  currentVideoList.some((item) => item.userId === userId)) ||
                (isShowSuspensionView && selfVideoShowType === MINIMIZED_VIDEO))
            ) {
              asnIds.asnUser = displayName;
            } else if (
              !currentVideoList.some((item) => item.userId === userId) ||
              (isWebinarOnlyShowContent &&
                currentVideoList.some((item) => item.userId === userId)) ||
              (isShowSuspensionView && selfVideoShowType === MINIMIZED_VIDEO)
            ) {
              asnIds.asnUser = `${asnIds.asnUser}, ${displayName}`;
            }
            if (talkingAsnTimer) {
              clearTimeout(talkingAsnTimer);
              talkingAsnTimer = null;
            } else {
              dispatch(setTalkingTipVisible(true));
            }

            // 3 seconds after, hide the indicator
            talkingAsnTimer = setTimeout(() => {
              dispatch(setTalkingTipVisible(false));
              talkingAsnTimer = null;
            }, TALKING_INDICATION_TIMEOUT);

            if (!attendee.hasAsn) {
              const asnId = asnIds[item];

              if (setAttendeeAsnTimerSet[asnId]) {
                clearTimeout(setAttendeeAsnTimerSet[asnId]);
                setAttendeeAsnTimerSet[asnId] = null;
              } else {
                dispatch({
                  type: types.SET_ATTENDEE_ASN,
                  data: { id: asnId, hasAsn: true },
                });
                notifyExternalControllerAsn(asnId, true);
              }

              setAttendeeAsnTimerSet[asnId] = setTimeout(() => {
                dispatch({
                  type: types.SET_ATTENDEE_ASN,
                  data: { id: asnId, hasAsn: false },
                });
                notifyExternalControllerAsn(asnId, false);
                setAttendeeAsnTimerSet[asnId] = null;
              }, AUDIO_ANIMATION_TIMEOUT);
            }
            break;
          }
        }
      });
      dispatch(setAsnIds(asnIds));
    }
  };
}

export const processAudioCaptureTask = (task) => (dispatch) => {
  const taskType = lockableTaskQueueManager.LOCKABLE_TYPE_ENUM.AUDIO_CAPTURE;
  if (task) {
    lockableTaskQueueManager.pushTask(taskType, task);
  } else {
    lockableTaskQueueManager.doNext(taskType);
  }
  dispatch(
    setIsGuardingAudioContextConflict(
      lockableTaskQueueManager.checkIsLocked(taskType),
    ),
  );
};

const getAudioConfigFromUserAgent = (key) => {
  if (!isTeslaMode()) return false;
  const configReg = new RegExp(` ${key}:(\\d)`);
  const result = configReg.exec(navigator.userAgent);
  if (result && result[1] && result[1] === '1') {
    return true;
  }
  return false;
};

export const callMediaSdkJoinVoip =
  (
    isAttendee,
    {
      audioSsrc,
      activeMicrophone,
      activeSpeaker,
      isAutoJoin,
      muted,
      enableHID,
    },
  ) =>
  (dispatch, getState) => {
    dispatch(
      processAudioCaptureTask(() => {
        const {
          meeting: { conID, svcUrl, ABtoken, supportLocalAB },
          security: { reportDomain },
          audio: { isDenoiseEnabled },
        } = getState();
        const audioProfile = audioProfileSelector(getState());
        const microphoneLabel = activeMicrophoneLabelSelector(getState());
        const joinAudioParams = {
          CaptureAudio: !isAttendee,
          CaptureAudioInfo: {
            ssrc: audioSsrc,
            AudioSelectValue:
              activeMicrophone === 'default' ? null : activeMicrophone,
            microphoneLabel,
            defaultMuted: muted,
            enableHID,
            audioProfile,
          },
          denoiseSwitch: isDenoiseEnabled,
          speakerInfo: {
            defaultDeviceId: activeSpeaker,
          },
          checkAutoplay: isAutoJoin,
          disableAudioAGC:
            getAudioConfigFromUserAgent('agc') ||
            (isMTRAndroid() && isYealink()),
          disableNoiseSuppression:
            getAudioConfigFromUserAgent('ns') ||
            (isMTRAndroid() && isYealink()),
          disableBrowserAec:
            getAudioConfigFromUserAgent('aec') ||
            (isMTRAndroid() && isYealink()),
        };
        if (isAudioBridge()) {
          joinAudioParams.audioBridge = {
            rwgHost: svcUrl,
            cid: conID,
            nginxHost: reportDomain,
            abToken: ABtoken,
            supportLocalAB,
          };
        }
        audioLogger.log(`join audio info: ${JSON.stringify(joinAudioParams)}`, [
          'AUDIO JOIN FLOW',
        ]);
        Job.start(
          JOB_ENUM.JOIN_COMPUTER_AUDIO,
          () => {
            globalVariable.avSocket.sendSocket(
              AVNotifyMediaSDKTypes.JOIN_COMPUTER_AUDIO,
              joinAudioParams,
            );
            dispatch(setIsJoinComputerAudioInProgress(true));
          },
          { timeout: 20 * 1000 },
        )
          .then(() => {
            if (
              audioProfile.currentSelect === AUDIO_PROFILE_TYPE.ORIGINAL_SOUND
            ) {
              const enabledTip = getEnableTip(
                audioProfile.originalSound.stereo,
                audioProfile.currentSelect,
              );
              AliveToast.uniqueToast({
                name: 'audio-profile-change',
                showClose: true,
                messageTip: enabledTip,
              });
            }
          })
          .catch((e) => {
            audioLogger.warn(e.message, ['AUDIO JOIN FLOW']);
          })
          .finally(() => {
            dispatch(setIsJoinComputerAudioInProgress(false));
          });
      }),
    );

    if (isAttendee) {
      dispatch(initSdkInterpretationChannel());
    }
  };

export function umnuteAfterSpotlighted({ id, bLeadershipOn }) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: {
        currentUser: { audio, muted, userId },
        bCanUnmute,
      },
    } = state;
    const coOrHost = coOrHostSelector(state);
    if (bLeadershipOn && userId >> 10 === id >> 10) {
      if (
        (audio === null || audio === '' || muted === true) &&
        (coOrHost || bCanUnmute)
      ) {
        dispatch(
          setUnmuteByHostDialogVisible({
            visible: true,
            type: UnmuteByHostType.SPOTLIGHT,
          }),
        );
        sendMsgToPWA(PWAMeetingEvent.UNMUTE_REQUEST);
      } else {
        wcToast({
          text: HOST_SPOTLIGHT_YOU,
          type: 'notify',
        });
      }
    }
  };
}

function onJoinAudioByVoipTimeout() {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
      audio: {
        UI: { joinVoipLoading },
      },
      socketStatus: { initAudioEncodeStatus, initAudioDecodeStatus },
    } = getState();
    if (!isJoinVoIP(currentUser) && joinVoipLoading) {
      dispatch(setJoinVoipLoading(false));
      dispatch(setJoinVoipTimeoutDialogVisible(true));
      audioLogger.log(
        `join audio timeout: encode-${initAudioEncodeStatus}, decode-${initAudioDecodeStatus}`,
        ['AUDIO JOIN FLOW'],
      );
    }
  };
}

export function sendJoinVoipAudioRequest(bOn) {
  return (dispatch) => {
    dispatch(
      sendSocketMessage({
        evt: WS_AUDIO_VOIP_JOIN_CHANNEL_REQ,
        body: {
          bOn,
        },
      }),
    );
  };
}

export function sendChangeAudioStatusRequest(
  oldAudioConnectionStatus,
  newAudioConnectionStatus,
) {
  return (dispatch) => {
    dispatch(
      sendSocketMessage({
        evt: WS_AUDIO_VOIP_JOIN_CHANNEL_REQ,
        body: {
          oldAudioConnectionStatus,
          audioConnectionStatus: newAudioConnectionStatus,
        },
      }),
    );
    dispatch(audioStatusSent(newAudioConnectionStatus));
  };
}

export function leaveVoipAudio(
  shouldKeepAudioChannel = false,
  shouldNotDisableInterpretation = false,
  callback,
  isOnHold,
) {
  return (dispatch, getState) => {
    avsLogReport('user leave audio', [
      AVS_TAGS.user_action,
      AVS_TAGS.audio_telemetry,
    ]);
    const {
      audio: { isMicrophoneMutedBySystem },
    } = getState();
    if (isMicrophoneMutedBySystem) {
      dispatch(setMicMutedBySystem(false));
    }
    dispatch(
      processAudioCaptureTask(() => {
        audioLogger.log('leave audio', ['AUDIO JOIN FLOW']);
        globalVariable.avSocket
          .sendSocket(LEAVE_COMPUTER_AUDIO, null)
          .then((v) => {
            if (typeof callback === 'function') {
              return callback(v);
            }
            return v;
          });
        // audioJoin will record the last status of audio before fail over, so auto-av can do audio status recover
        // if it leave audio due to waiting room, audioJoin should keep status instead of setting 'left'
        if (!isOnHold) {
          dispatch(setAvStatusInSessionStorage({ audioJoin: 'left' }));
        }
      }),
    );
    if (!shouldNotDisableInterpretation) {
      notifySdkToDisableInterpretation();
    }
    if (!shouldKeepAudioChannel) {
      dispatch(sendJoinVoipAudioRequest(false));
    }
  };
}
export function pauseOrPlayVoipAudio(bPause) {
  return () => {
    globalVariable.avSocket.sendSocket(
      AVNotifyMediaSDKTypes.PAUSE_OR_RESUME_AUDIO_DECODE,
      {
        bPause,
      },
    );
  };
}

export function joinVoipThunk(isAutoJoin = false) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userRole },
        currentUser,
      },
      audio: {
        activeMicrophone,
        activeSpeaker,
        audioSsrc,
        isClientEnableSyncButtonsOnHeadset,
      },
    } = getState();
    if (!isJoinVoIP(currentUser)) {
      dispatch(setJoinVoipLoading(true));
      dispatch(sendJoinVoipAudioRequest(true));
    }
    dispatch(setAvStatusInSessionStorage({ audioJoin: 'joined' }));
    const isAttendee = isViewOnly(userRole) && !isAllowOnlyCanTalk(userRole);
    dispatch(
      callMediaSdkJoinVoip(isAttendee, {
        audioSsrc,
        activeMicrophone,
        activeSpeaker,
        isAutoJoin,
        muted: currentUser.muted,
        enableHID: isClientEnableSyncButtonsOnHeadset,
      }),
    );
  };
}

const subscribeOrUnsubscribeRwgAudio = () => (dispatch, getState) => {
  const {
    meeting: { currentUser },
  } = getState();
  const isVoipConnected = isJoinVoIP(currentUser);
  /**
   * if more than 10 seconds still can not connect the voip,
   *  it should pop up a dialog to tell attendee detail information.
   *  */
  if (joinVoipTimer) {
    clearTimeout(joinVoipTimer);
    joinVoipTimer = null;
  }

  if (!isVoipConnected) {
    joinVoipTimer = setTimeout(() => {
      dispatch(onJoinAudioByVoipTimeout());
    }, JOIN_VOIP_TIMEOUT);
  }
};

export function joinOrLeaveAudioByVoip() {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
      audio: { isMicrophoneForbidden, isDesktopAudioOn },
    } = getState();
    const isVoipConnected = isJoinVoIP(currentUser);
    // notify js_media_sdk join or leave audio
    if (isVoipConnected) {
      dispatch(leaveVoipAudio());
      dispatch(changeRestartWebRtcTime(0));
      if (isMicrophoneForbidden) {
        setMicrophoneIsForbidden(false);
      }
    } else {
      // join rwg audio channel and sdk computer audio
      dispatch(joinVoipThunk());
      // subscribe or unsubscribe rwg server audio data
      dispatch(subscribeOrUnsubscribeRwgAudio());
      if (isDesktopAudioOn && !isSupportOpenMicWhenShareAudio()) {
        dispatch(setSelfMuteOrUnmute(true));
        dispatch(muteAudio(currentUser.userId));
        dispatch(setIsLeavingAudioForSharingDesktopAudio(true));
        globalVariable.avSocket.sendSocket(
          AVNotifyMediaSDKTypes.STOP_CAPTURE_AUDIO,
          {
            ssrc: 0,
          },
        );
      }
    }
  };
}

export function setDialOutReturnCodeThunk(code) {
  return (dispatch, getState) => {
    const {
      audio: {
        dialOut: { phoneNumber, selectedCountry },
      },
    } = getState();
    dispatch(setDialOutReturnCode(code));
    easyStore.easySet(
      SESSIONSTORAGE_KEYS.callMeStatusCode,
      code,
      storeType.sessionStorage,
    );
    if (code === CallMeStatus.disconnected) {
      easyStore.easySet(
        SESSIONSTORAGE_KEYS.callMePhoneNumber,
        '',
        storeType.sessionStorage,
      );
    } else {
      easyStore.easySet(
        SESSIONSTORAGE_KEYS.callMePhoneNumber,
        `${phoneNumber}#${selectedCountry.code}#${selectedCountry.value}#${selectedCountry.label}`,
        storeType.sessionStorage,
      );
    }
  };
}

export function handleDialOutResponse(data) {
  return (dispatch, getState) => {
    if (data.body.result !== undefined) {
      const {
        audio: {
          dialOut: { sequence: dialOutSequence },
          UI: { isSelfHangUp },
        },
        meetingUI: {
          invite: { seq: inviteSequence },
        },
      } = getState();
      const {
        seq,
        body: { result },
      } = data;
      if (dialOutSequence === seq) {
        /**
         * if dial out status is not calling,ringing,accepetd or connected,
         * reset dial out code after 1 second.
         */
        if ([1, 2, 3, 8].indexOf(result) === -1) {
          if (dialOutTimer !== null) {
            clearTimeout(dialOutTimer);
            dialOutTimer = null;
          }
          dialOutTimer = setTimeout(() => {
            dispatch(setDialOutReturnCodeThunk(-1));
          }, DIAL_OUT_RESET_TIMEOUT);
        }
        if (isSelfHangUp) {
          dispatch(setSelfHangUp(false));
        } else {
          dispatch(setDialOutReturnCodeThunk(result));
        }
      } else if (inviteSequence === seq) {
        const data = {
          seq,
          result,
        };
        dispatch({ type: types.UPDATE_INVITE_SEQ, data });
        if (result !== 1 && result !== 2 && result !== 3) {
          dispatch({
            type: types.SHOW_INVITE_STATUS,
            invite: {
              showIndication: false,
              seq: data.seq,
            },
          });
          setTimeout(() => {
            dispatch({ type: types.DELETE_INVITE_SEQ, data });
          }, 1000);
        }
      }
    }
  };
}

export function handleDialOutCancelResponse(data) {
  return (dispatch) => {
    if (data.body.result !== undefined) {
      const {
        body: { result },
      } = data;
      if (result === 0 || result === 11) {
        dispatch(setDialOutReturnCodeThunk(-1));
      }
    }
  };
}

// Shows or hides the join audio dialog, and as a side effect broadcasts the audio connection status.
// realCurrentUser is used in the case where the redux currentUser has not yet been updated, and so is out of sync.
export function setJoinAudioDialogVisible(visible, realCurrentUser = null) {
  return (dispatch, getState) => {
    const state = getState();
    let {
      meetingUI: { showInviteDialog },
      meeting: { currentUser },
      audio: { lastSentAudioConnectionStatus },
    } = state;

    if (!hasAudioOptionsSelector(state)) {
      // eslint-disable-next-line no-console
      console.warn('has no audio options');
      return dispatch(setJoinAudioDialogVisibleState(false));
    }

    if (visible && showInviteDialog) {
      // when invite zoom phone need not hide invite dialog
      // dispatch({ type: types.SHOW_INVITEDIALOG, showInviteDialog: false });
    }

    if (realCurrentUser) {
      currentUser = realCurrentUser;
    }

    if (visible && !isJoinAudio(currentUser)) {
      // the dialog is opening, and we are not currently connected to audio.
      // the previous connection status may have been success, failure, or just not yet connected.
      dispatch(
        sendChangeAudioStatusRequest(
          lastSentAudioConnectionStatus,
          AudioConnectionStatus.CONNECTING,
        ),
      );
    } else if (!visible && isJoinAudio(currentUser)) {
      dispatch(
        sendChangeAudioStatusRequest(
          lastSentAudioConnectionStatus,
          AudioConnectionStatus.CONNECT_SUCCESS,
        ),
      );
    } else if (!visible && !isJoinAudio(currentUser)) {
      // the dialog is closing after we did not connect to audio.
      dispatch(
        sendChangeAudioStatusRequest(
          lastSentAudioConnectionStatus,
          AudioConnectionStatus.CONNECT_FAIL,
        ),
      );
    }

    dispatch(setJoinAudioDialogVisibleState(visible));
  };
}

export function unmutedByHost() {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { muted, audio },
      },
    } = getState();
    if (audio !== null && audio !== '' && muted === true) {
      dispatch(
        setUnmuteByHostDialogVisible({
          visible: true,
          type: UnmuteByHostType.UNMUTE,
        }),
      );
      sendMsgToPWA(PWAMeetingEvent.UNMUTE_REQUEST);
    }
  };
}

export function unmuteAudio(userId) {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();
    avsLogReport('user unmute audio', [
      AVS_TAGS.user_action,
      AVS_TAGS.audio_telemetry,
    ]);
    easyStore.easySet('forceMuteAudio', false, storeType.memory);
    dispatch(setAvStatusInSessionStorage({ audio: 'unmute' }));
    const data = {
      evt: socketEventTypes.WS_AUDIO_MUTE_REQ,
      body: {
        bMute: false,
        id: userId,
      },
    };
    const isMe = userId === currentUser.userId;
    if (isTeslaMode() && isMe) {
      dispatch(setMuteUnmuteActionInProgress(true));
    }
    dispatch(sendSocketMessage(data));
    if (isExternalControlledMode() && isMe) {
      externalController.notifyConnectorZoomMsg(
        CONTROL_MODE_ZOOM_MSG_TYPE.UI,
        CONTROL_MODE_ZOOM_UI_ACTION_TYPE.UNMUTE_AUDIO,
      );
    }
    if (isMe) {
      dispatch(monitorRWGSendCurrentUserAudioRoster(false));
    }
  };
}

export function muteAudio(userId, notUserAction) {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();
    avsLogReport('user mute audio', [
      AVS_TAGS.user_action,
      AVS_TAGS.audio_telemetry,
    ]);
    const data = {
      evt: socketEventTypes.WS_AUDIO_MUTE_REQ,
      body: {
        bMute: true,
        id: userId,
      },
    };
    if (!notUserAction) {
      easyStore.easySet('forceMuteAudio', false, storeType.memory);
      dispatch(setAvStatusInSessionStorage({ audio: 'mute' }));
    } else {
      easyStore.easySet('forceMuteAudio', true, storeType.memory);
    }
    const isMe = userId === currentUser.userId;
    if (isTeslaMode() && isMe) {
      dispatch(setMuteUnmuteActionInProgress(true));
    }
    dispatch(sendSocketMessage(data));
    if (isExternalControlledMode() && isMe) {
      externalController.notifyConnectorZoomMsg(
        CONTROL_MODE_ZOOM_MSG_TYPE.UI,
        CONTROL_MODE_ZOOM_UI_ACTION_TYPE.MUTE_AUDIO,
      );
    }
    if (isMe) {
      dispatch(monitorRWGSendCurrentUserAudioRoster(true));
    }
  };
}

export const leaveAudioByVoipForShareDesktopAudio = () => {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId, muted },
      },
      audio: { isMicrophoneForbidden },
    } = getState();
    // For VOIP user, To make this user a fake disabled muted, tell rwg that this user is muted.
    // But do not call sdk mute this user.
    dispatch(setShouldUnmuteAfterFinishSharingDesktopAudio(!muted));
    dispatch(setSelfMuteOrUnmute(true));
    dispatch(muteAudio(userId));
    dispatch(setIsLeavingAudioForSharingDesktopAudio(true));
    dispatch(changeRestartWebRtcTime(0));
    if (isMicrophoneForbidden) {
      setMicrophoneIsForbidden(false);
    }
  };
};

/**
 * when userRole is changed and the value is 24/26(allow to talk attendee),
 * we need initAudioEncode in advance.
 */
export function initAudioEncodeWhenUserRoleChange(role) {
  return (dispatch, getState) => {
    if (!isAllowOnlyCanTalk(role)) {
      return;
    }
    const {
      meeting: {
        currentUser: { userId },
        meetingJoinStatus,
        meetingNumber,
        conID,
        confId,
        svcUrl,
      },
      socketStatus: { initAudioEncodeStatus },
    } = getState();
    initAudioEncode({
      initAudioEncodeStatus,
      meetingJoinStatus,
      conID,
      meetingNumber,
      svcUrl,
      userId,
      confId,
    });
    if (isSupportAV() && isSupportAudioWorklet()) {
      dispatch({
        type: types.SET_AV_ABILITY,
        data: { canAudioEncode: true },
      });
    }
  };
}

// user clicked the Unmute button on unmute-by-host-dialog.
export function attendeTalkByVoip() {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
      dialog: {
        unmuteByHost: { type },
      },
    } = getState();
    const { userId } = currentUser;
    const isJoinedAudio = isJoinAudio(currentUser);
    const isVoipConnected = isJoinVoIP(currentUser);
    if (!isSupportAV() && !isJoinedAudio) {
      return;
    }
    if (type === UnmuteByHostType.UNMUTE_ALLOW_TALK) {
      // join computer audio and unmute myself
      dispatch(setIsAllowToTalkUnmuteInProgress(true));
      if (isVoipConnected) {
        dispatch(leaveVoipAudio(true));
      }
      dispatch(joinVoipThunk());
    }
    if (!isJoinedAudio) {
      dispatch(sendJoinVoipAudioRequest(true));
    }
    dispatch(setSelfMuteOrUnmute(true));
    dispatch(unmuteAudio(userId));
    dispatch(setUnmuteByHostDialogVisible(false));
  };
}

export function attendeeReadyToTalkByVoip() {
  return (dispatch, getState) => {
    const {
      socketStatus: { initAudioEncodeStatus },
    } = getState();
    if (
      initAudioEncodeStatus === 'success' ||
      !isSupportAV() ||
      !isSupportAudioWorklet()
    ) {
      dispatch(
        setUnmuteByHostDialogVisible({
          visible: true,
          type: UnmuteByHostType.UNMUTE_ALLOW_TALK,
        }),
      );
      sendMsgToPWA(PWAMeetingEvent.UNMUTE_REQUEST);
      dispatch({
        type: types.SET_CURRENT_USER_ALLOW_TO_TALK,
        payload: true,
      });
    } else {
      setTimeout(() => {
        dispatch(attendeeReadyToTalkByVoip());
      }, 200);
    }
  };
}

export function webinarAttendeeJoinVoip(isAllowTalk, promoterId) {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();
    const { userId } = currentUser;
    if (promoterId === userId) {
      if (isAllowTalk) {
        // when attendee is allowed to talk,he need init audio encode
        // before he clicks unmute btn of the unmute-by-host-dialog whether he will join audio by voip or phone,
        // because he may join audio by phone first,hang up next,and then join audio by voip.
        dispatch(attendeeReadyToTalkByVoip());
      } else {
        dispatch(muteAudio(userId));
        dispatch(setUnmuteByHostDialogVisible(false));
        dispatch({
          type: types.SET_CURRENT_USER_ALLOW_TO_TALK,
          payload: isAllowTalk,
        });
        if (isJoinVoIP(currentUser)) {
          dispatch(
            leaveVoipAudio(true, true, () => {
              // when Mac attendee has't allowed auto-play on his Safari setting,
              // after host disable allow him to talk,there is a bug that attendee will not hear others.
              // so we have to disconnect audio and let the user rejoin audio manually.
              const isSafariAllowTalkUser =
                isSafari() && isSupportAudioWorklet();
              if (!isSafariAllowTalkUser) {
                dispatch(joinVoipThunk());
              } else {
                dispatch(leaveVoipAudio());
              }
            }),
          );
        }
      }
    }
  };
}

const isMuteByHost = (() => {
  let preStatus = null;
  return (caps) => {
    const current = caps && isAskToUnmute(caps);
    const ret = preStatus === false && current;
    preStatus = current;
    return ret;
  };
})();

export const mutedOrUnMutedByHost = (item) => {
  return (dispatch, getState) => {
    const {
      audio: { isHostMutedAll },
      meeting: {
        isHost,
        currentUser: { audio },
      },
    } = getState();
    if (!audio) {
      // do nothing
    } else if (!isHost) {
      if (item.muted) {
        dispatch(setAvStatusInSessionStorage({ audio: 'mute' }));

        if (isMuteByHost(item.caps)) {
          if (isHostMutedAll) {
            wcToast({
              text: HOST_MUTED_EVERYONE,
              type: 'notify',
            });
            dispatch(setHostMutedAll(false));
          } else {
            wcToast({
              text: HOST_MUTED_YOU,
              type: 'notify',
            });
            sendMsgToPWA(PWAMeetingEvent.MUTED_BY_HOST);
          }
        }
      }
    }
  };
};

export function hangUpAudio() {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId },
      },
    } = getState();
    dispatch(setSelfMuteOrUnmute(true));
    const data = {
      evt: socketEventTypes.WS_AUDIO_DROP_REQ,
      body: {
        id: userId,
      },
    };
    dispatch(sendSocketMessage(data));
  };
}

export function leaveAudioWhenPromoteOrDepromote(action) {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser, restrictFeatures },
    } = getState();
    const hasRestrictComputerAudio =
      restrictFeatures[JOIN_MEETING_POLICY.DISABLE_COMPUTER_AUDIO];
    if (
      isJoinVoIP(currentUser) &&
      action === 'promote' &&
      !voipEnabled(false, hasRestrictComputerAudio)
    ) {
      dispatch(leaveVoipAudio());
    }
  };
}

export const startDesktopAudio = () => (dispatch, getState) => {
  const {
    meeting: { currentUser },
    audio: { activeMicrophone, activeSpeaker, audioSsrc, isStereoEnabled },
  } = getState();
  const { userRole } = currentUser;

  // only one audio can stay joined: computer audio || desktop audio
  // 1. leave current user's audio before share desktop audio
  const isVoipConnected = isJoinVoIP(currentUser);
  if (isVoipConnected && !isSupportOpenMicWhenShareAudio()) {
    dispatch(leaveAudioByVoipForShareDesktopAudio());
  }

  // cannot join desktop audio if already joined it.
  // Leave desktop audio no matter if desktop audio is on. sdk will check this.
  dispatch(
    processAudioCaptureTask(() => {
      audioLogger.log('pause sharing audio', ['AUDIO SHARING FLOW']);
      globalVariable.avSocket.sendSocket(
        AVNotifyMediaSDKTypes.LEAVE_DESKTOP_AUDIO,
        {
          // if isPause is 0, the whole sharing audio will be ended and cannot re-join
          // expect getting user's consent agian.
          isPause: 1,
        },
      );
    }),
  );

  // 2. call media sdk
  dispatch(
    processAudioCaptureTask(() => {
      const {
        meeting: { conID, svcUrl, ABtoken, supportLocalAB },
        security: { reportDomain },
      } = getState();

      const sharingAudioParams = {
        CaptureAudio: !isViewOnly(userRole),
        CaptureAudioInfo: {
          ssrc: audioSsrc,
          AudioSelectValue:
            activeMicrophone === 'default' ? null : activeMicrophone,
        },
        speakerInfo: {
          defaultDeviceId: activeSpeaker,
        },
        checkAutoplay: false,
        stereoEnable: isStereoEnabled,
      };
      if (isAudioBridge()) {
        sharingAudioParams.audioBridge = {
          rwgHost: svcUrl,
          cid: conID,
          nginxHost: reportDomain,
          abToken: ABtoken,
          supportLocalAB,
        };
      }
      audioLogger.log(
        `sharing audio info: ${JSON.stringify(sharingAudioParams)}`,
        ['AUDIO SHARING FLOW'],
      );
      globalVariable.avSocket.sendSocket(
        AVNotifyMediaSDKTypes.JOIN_DESKTOP_AUDIO,
        sharingAudioParams,
      );
    }),
  );

  dispatch(setDesktopAudioStatus(true));
};

// rejoin computer audio if left it before
export const rejoinTheLeavingAudioForSharingDesktopAudio =
  () => (dispatch, getState) => {
    const state = getState();
    const {
      meeting: {
        currentUser: { userId },
        currentUser,
      },
      audio: {
        isLeavingAudioForSharingDesktopAudio,
        shouldUnmuteAfterFinishSharingDesktopAudio,
      },
    } = state;

    if (isLeavingAudioForSharingDesktopAudio) {
      // reset leave audio reason
      dispatch(setIsLeavingAudioForSharingDesktopAudio(false));
      if (!isJoinVoIP(currentUser)) {
        dispatch(joinVoipThunk());
      }
    }
    if (shouldUnmuteAfterFinishSharingDesktopAudio) {
      // reset unmute audio reason
      dispatch(setShouldUnmuteAfterFinishSharingDesktopAudio(false));
      dispatch(setSelfMuteOrUnmute(true));
      dispatch(unmuteAudio(userId));
    }
  };

export const stopDesktopAudio = () => (dispatch, getState) => {
  const state = getState();
  const {
    audio: { isDesktopAudioOn },
  } = state;
  if (isDesktopAudioOn === true) {
    // 1. call media sdk
    dispatch(
      processAudioCaptureTask(() => {
        audioLogger.log('stop sharing audio', ['AUDIO SHARING FLOW']);
        globalVariable.avSocket.sendSocket(
          AVNotifyMediaSDKTypes.LEAVE_DESKTOP_AUDIO,
          {
            isPause: 0,
          },
        );
      }),
    );

    // 2. set local state
    dispatch(setDesktopAudioStatus(false));
  }
  dispatch(setLeaveSharingAudio(true));
  if (!isSupportOpenMicWhenShareAudio()) {
    dispatch(rejoinTheLeavingAudioForSharingDesktopAudio());
  }
};

// desktop audio means sharing audio
export const localSwitchDesktopOrComputerAudioCapture =
  (toComputerAudio) => (dispatch, getState) => {
    const {
      audio: { isDesktopAudioOn },
    } = getState();

    if (toComputerAudio) {
      if (isDesktopAudioOn) {
        dispatch(
          processAudioCaptureTask(() => {
            audioLogger.log('pause sharing audio', ['AUDIO SHARING FLOW']);
            globalVariable.avSocket.sendSocket(
              AVNotifyMediaSDKTypes.LEAVE_DESKTOP_AUDIO,
              {
                isPause: 1,
              },
            );
          }),
        );

        dispatch(setDesktopAudioStatus(false));
        if (!isSupportOpenMicWhenShareAudio()) {
          dispatch(rejoinTheLeavingAudioForSharingDesktopAudio());
        }
      }
    } else {
      dispatch(startDesktopAudio());
    }
  };

export const setActiveSpeakerOrMicrophoneWithLogThunk =
  (deviceId, type) => (dispatch, getState) => {
    if (!deviceId || !type) return;

    const {
      audio: { activeSpeaker, activeMicrophone },
    } = getState();

    const audioDeviceLogger = (type) => {
      audioLogger.log(`audio ${type} current: ${deviceId}`, [
        'AUDIO JOIN FLOW',
      ]);
      audioLogger.log(`audio ${type} set from media sdk: ${deviceId}`, [
        'AUDIO JOIN FLOW',
      ]);
    };
    if (
      (type === MICROPHONE && deviceId !== activeMicrophone) ||
      (type === SPEAKER && deviceId !== activeSpeaker)
    ) {
      audioDeviceLogger(type);
      if (type === MICROPHONE) {
        dispatch(setActiveMicrophone(deviceId));
      } else if (type === SPEAKER) {
        dispatch(setActiveSpeaker(deviceId));
      }
    }
  };

export function changeMicrophoneThunk(deviceId) {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
      audio: { microphoneDevicesList },
    } = getState();
    avsLogReport('user change microphone', [
      AVS_TAGS.user_action,
      AVS_TAGS.audio_telemetry,
    ]);
    const activeDevice = microphoneDevicesList.find(
      (device) => device.deviceId === deviceId,
    );
    if (isJoinVoIP(currentUser)) {
      globalVariable.avSocket.sendSocket(CHANGE_AUDIO_MIC, {
        ssrc: 0,
        AudioSelectValue: deviceId === 'default' ? null : deviceId,
        microphoneLabel: activeDevice?.label,
        defaultMuted: currentUser.muted,
      });
      dispatch(changeRestartWebRtcTime(0));
    }
  };
}

export function manuallyChangeMicrophoneThunk(deviceId) {
  return (dispatch) => {
    deviceManager.manuallySelectMicrophone(deviceId);
    dispatch(changeMicrophoneThunk(deviceId));
  };
}

export function changeSpeakerThunk(deviceId) {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();
    avsLogReport('user change speaker', [
      AVS_TAGS.user_action,
      AVS_TAGS.audio_telemetry,
    ]);
    if (isJoinVoIP(currentUser)) {
      globalVariable.avSocket.sendSocket(CHANGE_AUDIO_SPEAKER, {
        ssrc: 0,
        AudioSelectValue: deviceId,
      });
      dispatch(changeRestartWebRtcTime(0));
    }
  };
}

export function manuallyChangeSpeakerThunk(deviceId) {
  return (dispatch) => {
    deviceManager.manuallySelectSpeaker(deviceId);
    dispatch(changeSpeakerThunk(deviceId));
  };
}
