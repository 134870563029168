/* eslint-disable react/prop-types */
import React from 'react';
import { isExternalControlledMode, outSideLinkWrap } from '../../global/util';

export const privacyNotificationUrl = (lan = 'en-us') =>
  `https://support.zoom.us/hc/${lan}/articles/360059564372-In-Product-Privacy-Notifications`;

export const appSignalLearnMoreUrl = (lan = 'en-us') =>
  `https://support.zoom.us/hc/${lan}/articles/360060577291`;
export const archivingDefaultLearMoreUrl = (lan = 'en-us') =>
  `https://support.zoom.us/hc/${lan}/articles/360050431572`;
export const aiLearnMoreUrl = (lan = 'en-us') =>
  `https://support.zoom.us/hc/${lan}/articles/16269138857229`;
export const summaryLearnMoreUrl = (lan = 'en-us') =>
  `https://support.zoom.us/hc/${lan}/articles/14423152801933`;
export const meetingQueryLearnMoreUrl = (lan = 'en-us') =>
  `https://support.zoom.us/hc/${lan}/articles/17396904087309`;
export const archiveSettingLearnMore = (lan = 'en-us') =>
  `https://support.zoom.us/hc/${lan}/articles/360061037572-Archiving-settings-for-meetings-and-webinars`;
export const Link2 = ({
  link,
  className = '',
  text,
  children,
  onClick,
  ariaLabel,
}) => {
  if (onClick) {
    return (
      <button
        tabIndex={0}
        type="button"
        className={`wc-link2 ax-outline-blue ${className}`}
        onClick={onClick}
        aria-label={ariaLabel}
      >
        {children || text}
      </button>
    );
  }
  return (
    <WcLink link={link} text={text} ariaLabel={ariaLabel} className={className}>
      {children}
    </WcLink>
  );
};

export const WcLink = ({
  link,
  text,
  children,
  ariaLabel,
  onClick = () => {},
  className = '',
}) => {
  if (link && !isExternalControlledMode()) {
    return (
      <a
        onClick={onClick}
        href={outSideLinkWrap(link)}
        className={`wc-link2 ${className}`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={ariaLabel || text || children}
      >
        {children || text}
      </a>
    );
  }

  return <span className="wc_link2">{children || text}</span>;
};

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/components/utils/link2.scss';
