import { iText } from '../../global/util';

export const START_ATTENTION_MODE_TEXT = iText(
  'Start Focus Mode',
  'apac.wc_start_focus_mode',
);
export const STOP_ATTENTION_MODE_TEXT = iText(
  'Stop Focus Mode ',
  'apac.wc_stop_focus_mode',
);

export const ATTENTION_MODE_TEXT = iText('FOCUS MODE', 'apac.wc_focus_mode');

// updated new
export const ATTENTION_MODE_SPOTLIGHTED = iText(
  'Everyone can see your video because the host spotlighted you',
  'apac.wc_focus_mode_spotlighted',
);

// updated
export const ATTENTION_MODE_HOST_TOAST = iText(
  'Participants can see only the host, co-hosts, and spotlighted users',
  'apac.wc_focus_mode_toast_forhost',
);

// updated
export const ATTENTION_MODE_PTCP_TOAST = iText(
  'Meeting is in Focus Mode: Only hosts and co-hosts can see your video.',
  'apac.wc_focus_mode_toast_for_ptcp',
);

export { GOT_IT } from '../recording/resource';

export const ATTENTION_MODE_TIPS_HOST = iText(
  'This meeting is in focus mode. Participants can see only hosts’ videos, shared content, and videos of spotlighted participants.',
  'apac.wc_focus_mode_hosts_tips',
);
export const ATTENTION_MODE_TIPS_OTHERS = iText(
  'This meeting is in focus mode. You can see only videos of hosts and spotlighted participants, as well as content shared by all.',
  'apac.wc_focus_mode_others_tips',
);

export const ATTENTION_MODE_DIALOG_CONTENT = iText(
  "Participants will only be able to see hosts' videos, shared content, and videos of spotlighted participants.",
  'apac.wc_focus_mode_dialog_content',
);

export const ATTENTION_MODE_DIALOG_NOTE = iText(
  'Note: You can allow shared content to be viewed by everyone in the "Screen Sharing" menu.',
  'apac.wc_focus_mode_dialog_content_note',
);

export const ATTENTION_MODE_DIALOG_SILENCE = iText(
  "Don't ask me again",
  'apac.wc_focus_mode_dialog_silence',
);

export const ATTENTION_MODE_SHARE_TXT = iText(
  'Shared screens can be seen by',
  'apac.wc_focus_mode_share',
);

export const allParticipantText = iText(
  'All participants',
  'apac.wc_all_participants',
);

export const toastForHostsShareAttentionMode = iText(
  'Content shared by participants is only visible to hosts and co-hosts',
  'apac.wc_focus_mode_share_toast_hosts',
);

export const toastOfATShareHostOnly = iText(
  'Your shared screen can be seen only by host and co-hosts',
  'apac.wc_focus_mode_toast_share_hostonly',
);

export const toastOfATShareAll = iText(
  'Your shared screen can be seen by all participants',
  'apac.wc_focus_mode_toast_share_all',
);

export const focusModeEndingTxt = iText(
  "Focus mode is ending. Participants who've started video will be seen by everybody.",
  'apac.wc_focus_mode_ending',
);
