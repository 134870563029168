import React from 'react';
import meetingConfig from 'meetingConfig';

import {
  changeRequestLtPermission,
  clearRequestLtArr,
  liveTranscriptionReset,
  pushRequestLtArr,
  setHasRequestLt,
  setIsSubtitleShowing,
  setLiveTranscriptionStatus,
  shiftRequestLtArr,
  toggleRequestDialog,
  updateMessage,
} from './live-transcription-action';
import {
  listenClosedCaption,
  updateMessageLatest,
} from '../../../actions/MeetingActions';
import { CLOSED_CAPTION_TURN_ON_TITLE } from '../../footer/resource';
import {
  hostAnsLtRequestTxt,
  LIVE_TRANSCRIPTION_ANONYMOUS_REQUEST,
  LIVE_TRANSCRIPTION_DISABLE_TIP,
  LIVE_TRANSCRIPTION_REQUEST_DIALOG,
  LIVE_TRANSCRIPTION_SILENCE_REQ,
  LIVE_TRANSCRIPTION_TURN_ON_TITLE,
} from '../resource';
import {
  CANCEL as CANCEL_COMMON_TEXT,
  DECLINE,
  ENABLE_COMMON_TEXT,
  REQUEST_COMMON_TEXT,
} from '../../../global/resource';

import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import { sendSocketMessage } from '../../../actions/SocketActions';
import {
  LIVE_TRANSCRIPTION_REQ_OPERATION,
  LIVE_TRANSCRIPTION_STATUS,
} from '../constant';
import { getParticipantsListItemAvatarNew } from '../../../global/service';

import Modal from '../../../global/components/widget/modal';
import {
  ansDialogRef,
  requestDenyDialogRef,
  requestLtDialogRef,
} from '../components/request-lt-modal-ctrl';

import ZoomButton from '../../../global/components/widget/button/zoom-button';
import AskerName from '../components/live-transcript-asker-name';
import { DIALOG_OK } from '../../dialog/resource';
import { JOIN_MEETING_POLICY } from '../../../global/constant';
import { PWAMeetingEvent, sendMsgToPWA } from '../../../global/pwa-integration';
import {
  changePanelViewState,
  PanelName,
} from '../../../global/redux/set-panel-action';
import { createCollectorThunk } from '../../../global/utils/create-collector-thunk';
import { isLiveTranscriptionInMeetingEnabledSelector } from '../../new-live-transcription/redux/new-LT-selector';

const closeHostAnsDialog = () => {
  if (ansDialogRef.current) {
    ansDialogRef.current.close();
    ansDialogRef.current = null;
  }
};

const closeRequestLtDialog = () => {
  if (requestLtDialogRef.current) {
    requestLtDialogRef.current.close();
  }
};

const closeRequestDenyDialog = () => {
  if (requestDenyDialogRef.current) {
    requestDenyDialogRef.current.close();
  }
};

export const enableTranscription = (isToEnable) => (dispatch) => {
  dispatch(
    sendSocketMessage({
      evt: socketEventTypes.WS_CONF_LIVE_TRANSCRIPTION_ON_OFF_REQ,
      body: {
        op: isToEnable
          ? LIVE_TRANSCRIPTION_REQ_OPERATION.GW_LIVE_CC_START
          : LIVE_TRANSCRIPTION_REQ_OPERATION.GW_LIVE_CC_STOP,
      },
    }),
  );
};

export const hideSubtitle = () => (dispatch) => {
  dispatch(listenClosedCaption(false));
  dispatch(setIsSubtitleShowing(false));
};

export const showSubtitle = () => (dispatch, getState) => {
  const state = getState();
  const {
    meeting: {
      closedCaption: { closedCaptionMessagesTemp },
    },
  } = state;

  let newMessage = isLiveTranscriptionInMeetingEnabledSelector(state)
    ? LIVE_TRANSCRIPTION_TURN_ON_TITLE
    : CLOSED_CAPTION_TURN_ON_TITLE;
  if (closedCaptionMessagesTemp) {
    newMessage = closedCaptionMessagesTemp;
  }
  dispatch(updateMessageLatest(newMessage));
  dispatch(listenClosedCaption(true));

  dispatch(setIsSubtitleShowing(true));
};

export const switchFullTranscriptionPanel = (v) => (dispatch) => {
  dispatch(changePanelViewState(PanelName.fullTranscription, v));
};

// export const closeFullTranscript = () => (dispatch) => {
//   dispatch(changePanelViewState(PanelName.fullTranscription, false));
// };
//
// export const openFullTranscript = () => (dispatch) => {
//   dispatch(changePanelViewState(PanelName.fullTranscription, true));
// };

export const liveTranscriptionResetAll = () => (dispatch) => {
  closeHostAnsDialog();
  closeRequestDenyDialog();
  closeRequestLtDialog();
  dispatch(switchFullTranscriptionPanel(false));
  dispatch(liveTranscriptionReset());
};

// export const transcriptWindowOpen = (isOpen) => (dispatch) => {
//   if (isOpen) {
//     dispatch(switchFullTranscriptionPanel(false));
//     dispatch(setIsTranscriptionWindowOpen(true));
//   } else {
//     dispatch(setIsTranscriptionWindowOpen(false));
//   }
// };

export const updateLiveTranscriptionMessage =
  (payload) => (dispatch, getState) => {
    const {
      attendeesList: { attendeesList },
      meeting: { h323Avatar, defaultAvatar, bAllowedAvatar },
      liveTranscription: { allMessages },
    } = getState();
    const newPayload = { ...payload };
    const user =
      attendeesList.find(
        (attendee) => attendee.userId === newPayload.destNodeID,
      ) || null;
    const oldMessage = allMessages[payload.srcMsgID];
    if (user) {
      newPayload.previousDisplayName = user.displayName;
      newPayload.previousAvatar = getParticipantsListItemAvatarNew(
        user,
        h323Avatar,
        defaultAvatar,
        bAllowedAvatar,
      );
    }
    if (oldMessage && !user) {
      newPayload.previousDisplayName = oldMessage.previousDisplayName;
      newPayload.previousAvatar = oldMessage.previousAvatar;
    }
    if (!oldMessage && !user) {
      newPayload.previousDisplayName = '';
      newPayload.previousAvatar = '';
    }
    if (!oldMessage && !user) {
      newPayload.previousDisplayName = '';
      newPayload.previousAvatar = '';
    }
    dispatch(updateMessage(newPayload));
  };
const setLiveTranscriptionStatusDebounce = createCollectorThunk(
  setLiveTranscriptionStatus,
  100,
);
export const onReceiveLiveTranscriptionOn =
  (status) => (dispatch, getState) => {
    const {
      liveTranscription: {
        hasParticpantReqLt,
        liveTranscriptionStatus: preLiveTranscriptionStatus,
      },
      meeting: { restrictFeatures },
    } = getState();
    if (restrictFeatures[JOIN_MEETING_POLICY.CC]) return;
    dispatch(setLiveTranscriptionStatusDebounce(status));
    if (
      status === LIVE_TRANSCRIPTION_STATUS.CMM_LIVE_CC_STATUS_START &&
      preLiveTranscriptionStatus !==
        LIVE_TRANSCRIPTION_STATUS.CMM_LIVE_CC_STATUS_START
    ) {
      closeRequestLtDialog();
      if (hasParticpantReqLt) {
        dispatch(showSubtitle());
      }
    }
  };

export const sendAssignClosedCaptionRequest = (userId) => (dispatch) => {
  const body = {
    id: userId,
    bCCEditor: true,
  };
  const dataResult = {
    evt: socketEventTypes.WS_CONF_ASSIGN_CC_REQ,
    body,
  };
  dispatch(sendSocketMessage(dataResult));
};

const defaultLeftButtonProps = {
  type: 'default',
  size: 'default',
  className: 'zm-btn-legacy',
};
const rightButtonProps = {
  type: 'primary',
  size: 'default',
  className: 'zm-btn-legacy',
};
export const particpantRequestLt = () => (dispatch, getState) => {
  const {
    liveTranscription: { allowRequestLt },
  } = getState();
  if (!allowRequestLt) {
    Modal.confirm({
      className: 'zm-modal-legacy zm-modal-nocontent',
      okButtonProps: rightButtonProps,
      okText: DIALOG_OK,
      okCancel: false,
      contentLabel: LIVE_TRANSCRIPTION_DISABLE_TIP,
      title: LIVE_TRANSCRIPTION_DISABLE_TIP,
      ref: requestDenyDialogRef,
      rModalProps: {
        shouldCloseOnOverlayClick: false,
        shouldFocusAfterRender: true,
        shouldReturnFocusAfterClose: true,
      },
    });
    return;
  }
  let bAnonymous = false;
  // fix focus lock of audio panel
  dispatch(toggleRequestDialog(true));
  Modal.confirm({
    rModalProps: {
      shouldCloseOnOverlayClick: false,
      shouldFocusAfterRender: true,
      shouldReturnFocusAfterClose: true,
    },
    className: 'zm-modal-legacy zm-modal-nocontent',
    okButtonProps: defaultLeftButtonProps,
    okText: CANCEL_COMMON_TEXT,
    cancelText: REQUEST_COMMON_TEXT,
    cancelButtonProps: rightButtonProps,
    contentLabel: LIVE_TRANSCRIPTION_REQUEST_DIALOG,
    title: LIVE_TRANSCRIPTION_REQUEST_DIALOG,
    ref: requestLtDialogRef,
    onOK: () => {
      dispatch(toggleRequestDialog(false));
    },
    onCancel: () => {
      dispatch(
        sendSocketMessage({
          evt: socketEventTypes.WS_ASK_HOST_START_LIVE_TRANSCRIPT_REQ,
          body: {
            bAnonymous,
          },
        }),
      );
      dispatch(toggleRequestDialog(false));
      dispatch(setHasRequestLt(true));
    },
    checkboxText: LIVE_TRANSCRIPTION_ANONYMOUS_REQUEST,
    checkboxProps: {
      onChange: (value) => {
        bAnonymous = value;
      },
      defaultChecked: bAnonymous,
    },
  });
};

export const tryDismissRequestLtDialog = (message) => () => {
  if (message.body && message.body.update) {
    const arr = message.body.update;
    const hasBeCCEditorAssign =
      Array.isArray(arr) && arr.some((item) => item.bCCEditor);
    if (hasBeCCEditorAssign) {
      closeRequestLtDialog();
      closeHostAnsDialog();
    }
  }
};

export const onHostUpdateRequestLtCap = (flag) => (dispatch) => {
  dispatch(
    sendSocketMessage({
      evt: socketEventTypes.WS_CONF_ALLOW_ASK_HOST_START_LIVE_TRANSCRIPT_REQ,
      body: {
        enable: flag,
      },
    }),
  );

  // not allow
  if (!flag) {
    closeRequestLtDialog();
  } else {
    // Allow
    closeRequestDenyDialog();
  }
};

export const onHostChangeResetRequestLtCap =
  (flag, hostChangeMsg = false) =>
  (dispatch) => {
    // host not change, maybe failover
    if (meetingConfig.isHost && hostChangeMsg) return;
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_ALLOW_ASK_HOST_START_LIVE_TRANSCRIPT_REQ,
        body: {
          enable: flag,
        },
      }),
    );
    dispatch(clearRequestLtArr());
    closeRequestDenyDialog();
    closeRequestLtDialog();
    closeHostAnsDialog();
  };

export const onChangeRequestLtPermission = (message) => (dispatch) => {
  dispatch(changeRequestLtPermission(message.body.enable));
  // close request lt dialog if host disallow
  if (!message.body.enable) {
    closeRequestLtDialog();
  }
};

const handleHostDeclineAll = () => (dispatch) => {
  dispatch(onHostUpdateRequestLtCap(false));
  dispatch(clearRequestLtArr());
  closeHostAnsDialog();
};
const handleHostApproveLt = () => (dispatch) => {
  dispatch(
    sendSocketMessage({
      evt: socketEventTypes.WS_HOST_CONSENT_START_LIVE_TRANSCRIPT_REQ,
      body: {
        bApproved: true,
      },
    }),
  );
  dispatch(clearRequestLtArr());
  closeHostAnsDialog();
};
const handleHostDeclineOneLt = () => (dispatch, getState) => {
  closeHostAnsDialog();
  dispatch(shiftRequestLtArr());
  dispatch(toggleRequestDialog(false));
  const {
    liveTranscription: { requestLtArr },
  } = getState();

  if (requestLtArr.length) {
    setTimeout(() => {
      dispatch(showHostAnsDialog());
    }, 100);
  }
};

function getAskerName(state) {
  let askerName = '';
  const {
    liveTranscription: { requestLtArr },
  } = state;

  const askItem = requestLtArr[0];
  if (askItem.bAnonymous) {
    askerName = 'A particpant';
  } else {
    const {
      attendeesList: { attendeesList },
    } = state;
    const asker = attendeesList.find(
      ({ userId }) => userId === askItem.askerID,
    );
    askerName = (asker && asker.displayName) || 'A particpant';
  }
  return askerName;
}

const showHostAnsDialog = () => (dispatch, getState) => {
  if (ansDialogRef.current) return;
  const {
    liveTranscription: { requestLtArr, liveTranscriptionStatus },
  } = getState();
  if (
    requestLtArr.length === 0 ||
    liveTranscriptionStatus !==
      LIVE_TRANSCRIPTION_STATUS.CMM_LIVE_CC_STATUS_STOP
  ) {
    return;
  }
  const askerName = getAskerName(getState());
  // not found asker user
  if (!askerName) return;
  const askItem = requestLtArr[0];
  dispatch(toggleRequestDialog(true));
  Modal.confirm({
    rModalProps: {
      shouldCloseOnOverlayClick: false,
      shouldFocusAfterRender: true,
      shouldReturnFocusAfterClose: true,
      shouldCloseOnEsc: true,
    },
    onClose: () => {
      dispatch(handleHostDeclineOneLt(askItem));
    },
    className: 'zm-modal-legacy zm-modal-nocontent',
    contentLabel: hostAnsLtRequestTxt(askerName),
    title: (
      <>
        <AskerName name={askerName} />
        {hostAnsLtRequestTxt('')}
      </>
    ),
    ref: ansDialogRef,
    footer: (
      <div className="zm-modal-footer-default">
        <div
          className="zm-modal-footer-default-actions"
          style={{ marginLeft: 0 }}
        >
          <ZoomButton
            onClick={() => dispatch(handleHostDeclineAll())}
            {...defaultLeftButtonProps}
          >
            {LIVE_TRANSCRIPTION_SILENCE_REQ}
          </ZoomButton>
          <ZoomButton
            onClick={() => dispatch(handleHostDeclineOneLt())}
            {...defaultLeftButtonProps}
          >
            {DECLINE}
          </ZoomButton>
          <ZoomButton
            onClick={() => dispatch(handleHostApproveLt())}
            {...rightButtonProps}
          >
            {ENABLE_COMMON_TEXT}
          </ZoomButton>
        </div>
      </div>
    ),
  });
};

const handlePWAEvent = () => (dispatch, getState) => {
  const askerName = getAskerName(getState());
  sendMsgToPWA(PWAMeetingEvent.LIVE_TRANSCRIPTION_REQUEST, { name: askerName });
};

export const onHostReceiveLtRequests = (message) => (dispatch) => {
  /**
   * e.g.
   * askerID: 33556480
   * bAnonymous: false
   */
  dispatch(pushRequestLtArr(message.body));
  dispatch(showHostAnsDialog());
  dispatch(handlePWAEvent());
};
