import { createActions } from 'redux-actions';

const SET_REPORT_CONTENT_WINDOW_VISIBLE = 'SET_REPORT_CONTENT_WINDOW_VISIBLE';
const SET_REPORT_FEEDBACK_DIALOG_VISIBLE = 'SET_REPORT_FEEDBACK_DIALOG_VISIBLE';

const SET_REPORTED_USERS = 'SET_REPORTED_USERS';
const SET_REPORTED_USER_REMOVE_STATUS = 'SET_REPORTED_USER_REMOVE_STATUS';

const SET_SECURITY_DROPDOWN_VISIBLE = 'SET_SECURITY_DROPDOWN_VISIBLE';
const SET_REQUEST_ENABLE_WAITING_ROOM = 'SET_REQUEST_ENABLE_WAITING_ROOM';
const SET_REQUEST_LOCK_MEETING = 'SET_REQUEST_LOCK_MEETING';
const SECURITY_RESET = 'SECURITY_RESET';
const SET_REPORT_DOMAIN = 'SET_REPORT_DOMAIN';
const SET_NEED_REPORT_TO_ZOOM = 'SET_NEED_REPORT_TO_ZOOM';
const SET_SUSPEND_DIALOG_VISIBLE = 'SET_SUSPEND_DIALOG_VISIBLE';

export const {
  setReportContentWindowVisible,
  setSecurityDropdownVisible,
  setRequestEnableWaitingRoom,
  setRequestLockMeeting,
  setReportedUsers,
  setReportFeedbackDialogVisible,
  setReportedUserRemoveStatus,
  securityReset,
  setReportDomain,
  setNeedReportToZoom,
  setSuspendDialogVisible,
} = createActions({
  [SET_REPORT_CONTENT_WINDOW_VISIBLE]: (payload) => payload,
  [SET_SECURITY_DROPDOWN_VISIBLE]: (payload) => payload,
  [SET_REQUEST_ENABLE_WAITING_ROOM]: (payload) => payload,
  [SET_REQUEST_LOCK_MEETING]: (payload) => payload,
  [SET_REPORTED_USERS]: (payload) => payload,
  [SET_REPORT_FEEDBACK_DIALOG_VISIBLE]: (payload) => payload,
  [SET_REPORTED_USER_REMOVE_STATUS]: (payload) => payload,
  [SECURITY_RESET]: (payload) => payload,
  [SET_REPORT_DOMAIN]: (payload) => payload,
  [SET_NEED_REPORT_TO_ZOOM]: (payload) => payload,
  [SET_SUSPEND_DIALOG_VISIBLE]: (payload) => payload,
});
