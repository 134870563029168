import { createActions } from 'redux-actions';

export const {
  setContacts,
  setInviteSelectedItems,
  updateUserContact,
  setPwaStatus,
  updateCallerId,
  updateSmsId,
  updateSmsSendStatus,
  updateSmsErrorCode,
  updateInviteCheckbox,
  setInviteIakAndCred,
  setHasBypassWaitingroomCode,
} = createActions(
  'SET_CONTACTS',
  'SET_INVITE_SELECTED_ITEMS',
  'UPDATE_USER_CONTACT',
  'SET_PWA_STATUS',
  'UPDATE_CALLER_ID',
  'UPDATE_SMS_ID',
  'UPDATE_SMS_SEND_STATUS',
  'UPDATE_SMS_ERROR_CODE',
  'UPDATE_INVITE_CHECKBOX',
  'SET_INVITE_IAK_AND_CRED',
  'SET_HAS_BYPASS_WAITINGROOM_CODE',
);
