// clicking on chat icon in main toolbar to open chat panel
import { sendTelemetryEvent } from '../../global/telemetry/telemetry';

const commonRequired = {
  featureName: 'record',
  breakoutRoomEntity: true,
  meetingPreferencesEntity: true,
  meetingSettingsEntity: true,
};

// user clicks on record on the main meeting toolbar and then selects one of the options on the pop_up menu
export const startRecordingInBarTelemetry = () => {
  sendTelemetryEvent({
    eventSource: 'main_meeting_toolbar',
    eventLocation: 'main_meeting_toolbar',
    eventName: 'record_to_the_cloud',
    ...commonRequired,
  });
};

// user sees the consent window and clicks on one of the two options
export const clickRecordingConsentDialogTelemetry = (gotIt) => {
  sendTelemetryEvent({
    eventSource: 'main_meeting_toolbar',
    eventLocation: 'consent_window',
    eventName: gotIt ? 'got_it' : 'leave_meeting',
    ...commonRequired,
  });
};

// user sees the window pop up, which says that their cloud recording storage is full and they chose one of the two options
export const recordingEventName = {
  record_on_this_computer: 'record_on_this_computer',
  continue_record_on_the_cloud: 'continue_record_on_the_cloud',
  recording_management: 'recording_management',
  ok: 'ok',
};
export const clickRecordingFullStorageDialogTelemetry = (eventName) => {
  sendTelemetryEvent({
    eventSource: 'main_meeting_toolbar',
    eventLocation: 'cloud_recording_storage_full_window',
    eventName,
    ...commonRequired,
  });
};

export const clickRecordingUnableDialogTelemetry = (eventName) => {
  sendTelemetryEvent({
    eventSource: 'main_meeting_toolbar',
    eventLocation: 'unable_to_record_window',
    eventName,
    ...commonRequired,
  });
};
