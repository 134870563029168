import { chatLogger } from './logger';
import mitt from './mitt';
import {
  EmojiVoteResponse,
  FileProcessEvent,
  MessageResponse,
  RevokeMessage,
  UnreadInfo,
} from './@types/type';

export type Events = {
  message: MessageResponse[];
  emojiVote: EmojiVoteResponse;
  revokeMessage: RevokeMessage;
  editMessage: MessageResponse;
  connected: unknown;
  fileUploadProgress: FileProcessEvent;
  searchFileDownloadProgress: FileProcessEvent;
  mention: MessageResponse;
  mentionMeMessage: MessageResponse | RevokeMessage;
  updateUserInfo: unknown;
  unreadChannelMap: Record<string, UnreadInfo>;
  channelChange: { jid: string };
  // [key: string]: string;
};

export const emitter = mitt<Events>();
// export const transitEmitter = mitt<Events>();

if (process.env.NODE_ENV === 'development') {
  const originEmit = emitter.emit;
  // @ts-ignore
  emitter.emit = (event: keyof Events, args: unknown) => {
    chatLogger.info(`dispatch event: ${event}`, args);
    originEmit(event, args);
  };
}

//@ts-ignore
window.emitter = emitter;
