import meetingConfig from 'meetingConfig';
import Cookie from 'react-cookie';
import { getAVMeetingParams } from '../../global/service';
import { isSupportAV, isWindows } from '../../global/util';
import {
  CALLING,
  RING,
  CALL_ACCEPTED,
  BUSY,
  FAIL_TO_CALL,
  PHONE_CONNECTED,
  DISCONNECTING_CALL,
  FAIL_TO_DISCONNECT_CALL,
} from './resource';
import { REMEMBER_PHONE_COOKIE_KEY, CALL_TYPES } from './enum';
import { easyStore, storeType } from '../../global/easy-store';
import { globalVariable } from '../../global/global-variable';

/**
 * the supported audio type when schedu a meeting
 */
const isConferenceSupportPhone = () => {
  return (
    meetingConfig.optionAudioType === 'telephony' ||
    meetingConfig.optionAudioType === 'both'
  );
};

export const isAttendeeSupportCallIn = (
  isViewOnlyAttendee,
  isServerSupportAttendeeTelephony,
  isSupportAttendeeTollFreeCallIn,
) => {
  if (!isConferenceSupportPhone()) {
    return false;
  }
  if (!isViewOnlyAttendee) {
    return true;
  }
  // if MMR does't support phone,attendees will also not support call in,
  // because every different attendee connected diffentent MMR perhaps,different MMR server may
  // support phone or not.
  if (!isServerSupportAttendeeTelephony) {
    return false;
  }
  const needFilterPremiumAndTollfreeNumbers = !isSupportAttendeeTollFreeCallIn;
  const allTollNumbers = meetingConfig.tollNumbers || [];
  const allTollNumbersSize = allTollNumbers.length;
  if (!needFilterPremiumAndTollfreeNumbers) {
    // if there is more than one number,attendees will support call in.
    return allTollNumbersSize > 0;
  }
  // if there is one toll number at least(both CALLNUMBER_TOLLFREE and CALLNUMBER_PREMIUM are toll-free numbers),
  // attendees will support call in.
  for (let i = 0; i < allTollNumbersSize; i++) {
    const tollNumber = allTollNumbers[i];
    if (
      tollNumber.type !== CALL_TYPES.CALLNUMBER_TOLLFREE &&
      tollNumber.type !== CALL_TYPES.CALLNUMBER_PREMIUM
    ) {
      return true;
    }
  }
  return false;
};

// filter targer phone numbers when user selected a country code
export const filterPhoneNumbers =
  ({ selectedCountry, isViewOnlyAttendee, isSupportAttendeeTollFreeCallIn }) =>
  (tollNumber) => {
    if (tollNumber.country === selectedCountry.value) {
      // all participants in meeting or panelists in webibar or isSupportAttendeeTollFreeCallIn:true attendees
      // can see all of the phone numbers include toll and toll-free numbers.
      if (!isViewOnlyAttendee || isSupportAttendeeTollFreeCallIn) {
        return true;
      }
      // if attendees who don't support toll-free call in(isSupportAttendeeTollFreeCallIn:false)
      // can not see the toll-free or premium numbers
      if (
        tollNumber.type !== CALL_TYPES.CALLNUMBER_TOLLFREE &&
        tollNumber.type !== CALL_TYPES.CALLNUMBER_PREMIUM
      ) {
        return true;
      }
    }
    return false;
  };
/*
 * optionAudioType: 'voip', 'both', 'telephony', 'other'
 * if schedule meeting only telephony, but no available toll numbers,
 * optionAudioType will fallback to 'voip', ZOOM-298173
 */
export const voipEnabled = (
  isViewOnlyAttendee,
  hasRestrictComputerAudio = false,
) => {
  if (isViewOnlyAttendee) {
    return true;
  }
  if (hasRestrictComputerAudio) return false;
  if (
    !meetingConfig.pstnOptions ||
    meetingConfig.pstnOptions.audioType === 'SAME_WITH_MEETING'
  ) {
    return (
      (meetingConfig.optionAudioType === 'voip' ||
        meetingConfig.optionAudioType === 'both' ||
        (meetingConfig.optionAudioType !== 'telephony' && isSupportAV())) &&
      meetingConfig.optionAudioType !== 'other'
    );
  }
  return false;
};

export const phoneCallEnabled = (
  isViewOnlyAttendee,
  isServerSupportAttendeeTelephony,
  isSupportAttendeeTollFreeCallIn,
) => {
  if (meetingConfig.tollNumbers.length === 0) return false;
  if (
    !meetingConfig.pstnOptions ||
    meetingConfig.pstnOptions.audioType === 'SAME_WITH_MEETING'
  ) {
    return (
      isConferenceSupportPhone() &&
      ((isViewOnlyAttendee &&
        isAttendeeSupportCallIn(
          isViewOnlyAttendee,
          isServerSupportAttendeeTelephony,
          isSupportAttendeeTollFreeCallIn,
        )) ||
        !isViewOnlyAttendee)
    );
  }
  if (
    meetingConfig.pstnOptions.audioType ===
      'ONLY_ALLOW_CALLME_WITH_OWN_PHONENUMBER' &&
    meetingConfig.userPhoneInfo &&
    meetingConfig.userPhoneInfo.length > 0 &&
    meetingConfig.supportCallOut === 1
  ) {
    return false;
  }
  return true;
};

export const callMeEnabled = (isViewOnlyAttendee, isSupportAttendeeCallOut) => {
  if (
    !meetingConfig.pstnOptions ||
    meetingConfig.pstnOptions.audioType === 'SAME_WITH_MEETING'
  ) {
    return (
      isConferenceSupportPhone() &&
      meetingConfig.supportCallOut &&
      ((isViewOnlyAttendee && isSupportAttendeeCallOut) || !isViewOnlyAttendee)
    );
  }
  if (
    meetingConfig.pstnOptions.phoneNumberNotMatchCallOut &&
    meetingConfig.pstnOptions.audioType ===
      'ONLY_ALLOW_CALLME_WITH_OWN_PHONENUMBER'
  ) {
    return true;
  }
  return meetingConfig.supportCallOut;
};

export const thirdPartyAudioEnabled = () => {
  return (
    meetingConfig.support3rdPartyAudio !== 0 &&
    meetingConfig.pstnOptions.audioType !==
      'ONLY_ALLOW_CALLME_WITH_OWN_PHONENUMBER'
  );
};
export const isSupport3rdPartyAudioAndRestrictPhoneNumber = () => {
  return (
    meetingConfig.pstnOptions &&
    meetingConfig.pstnOptions.audioType ===
      'ONLY_ALLOW_CALLME_WITH_OWN_PHONENUMBER' &&
    meetingConfig.support3rdPartyAudio === 1
  );
};
export const isOnlyAllowCallMeWithOwnPhoneNumber = () => {
  return (
    meetingConfig.pstnOptions &&
    meetingConfig.pstnOptions.audioType ===
      'ONLY_ALLOW_CALLME_WITH_OWN_PHONENUMBER' &&
    meetingConfig.userPhoneInfo &&
    meetingConfig.userPhoneInfo.length > 0
  );
};
export const isOnlyAllowOwnPhoneNumberCallOut = () => {
  return (
    isOnlyAllowCallMeWithOwnPhoneNumber() && meetingConfig.supportCallOut === 0
  );
};
export const isOwnPhoneNumberNotMatchCallOut = () => {
  return (
    isOnlyAllowCallMeWithOwnPhoneNumber() &&
    meetingConfig.pstnOptions.phoneNumberNotMatchCallOut
  );
};
export const isNotExistOwnPhoneNumber = () => {
  return (
    meetingConfig.pstnOptions &&
    meetingConfig.pstnOptions.audioType ===
      'ONLY_ALLOW_CALLME_WITH_OWN_PHONENUMBER' &&
    (!meetingConfig.userPhoneInfo ||
      (meetingConfig.userPhoneInfo && meetingConfig.userPhoneInfo.length === 0))
  );
};
export const isRestrictPhoneNumberButDisablePhone = () => {
  return (
    meetingConfig.pstnOptions &&
    meetingConfig.pstnOptions.audioType ===
      'ONLY_ALLOW_CALLME_WITH_OWN_PHONENUMBER' &&
    (meetingConfig.support3rdPartyAudio === 1 ||
      meetingConfig.optionAudioType === 'voip')
  );
};
/**
 * Telephony Service Provider
 */
export const isTSPEnabled = () => {
  if (
    meetingConfig.tsp &&
    (meetingConfig.tsp.isTSP === true || meetingConfig.tsp.isTSP === 'true') &&
    meetingConfig.tsp.callInfo !== undefined
  ) {
    const { callInfo } = meetingConfig.tsp;
    return _.startsWith(callInfo, 'MC') && callInfo.indexOf(';') > 0;
  }
  return false;
};
/**
 *
 * @param {*} participantId
 * return value
 * {
 *  tspMergeCode,
 *  ... other call info properties
 * }
 */
export const getTSPInfo = (participantId) => {
  if (isTSPEnabled()) {
    const { callInfo } = meetingConfig.tsp;
    /**
     * struct of callInfo
     * @type {{
     *  'MC',
     *  'Conference code',
     *  'Participant ID',
     *  'Participant passcode',
     *  'Host passcode',
     *  'Leader PIN',
     *  'Merge code',
     *  }}
     *
     */
    const callInfoMap = _.fromPairs(
      callInfo.split(';').map((v) => v.split(':')),
    );
    const { MC: tspMergeCode, ...restProps } = callInfoMap;
    /* eslint-disable-next-line no-prototype-builtins */
    if (restProps.hasOwnProperty('Participant ID')) {
      restProps['Participant ID'] = participantId;
      restProps['Merge code'] = tspMergeCode;
    } else {
      restProps['Merge code'] = `${tspMergeCode.substr(
        0,
        tspMergeCode.length - 1,
      )}${participantId}#`;
    }
    return {
      tspMergeCode,
      ...restProps,
    };
  }
  return null;
};

export const getCallOutReturnStatus = (code) => {
  let text = null;
  let status = null;
  switch (code) {
    case 0:
    case 1:
      text = CALLING;
      status = 'normal';
      break;
    case 2:
      text = RING;
      status = 'normal';
      break;
    case 3:
      text = CALL_ACCEPTED;
      status = 'success';
      break;
    case 4:
    case 5:
    case 6:
      text = BUSY;
      status = 'fail';
      break;
    case 7:
    case 9:
      text = FAIL_TO_CALL;
      status = 'fail';
      break;
    case 8:
      text = PHONE_CONNECTED;
      status = 'success';
      break;
    case 10:
      text = DISCONNECTING_CALL;
      status = 'fail';
      break;
    case 12:
      text = FAIL_TO_DISCONNECT_CALL;
      status = 'fail';
      break;
    default:
      text = FAIL_TO_CALL;
      status = 'fail';
  }
  return {
    text,
    status,
  };
};

export function getDefaultCallOutState() {
  let defaultCountry = null;
  let defaultPhoneNumber = '';
  let isDefaultRememberChecked = false;

  if (isOnlyAllowCallMeWithOwnPhoneNumber()) {
    const callOutCountryIds = _.map(meetingConfig.callOutCountry, 'id');
    const filteredUserPhoneInfo = _.filter(
      meetingConfig.userPhoneInfo,
      (item) => _.indexOf(callOutCountryIds, item.countryId) > -1,
    );
    defaultCountry = {
      code: filteredUserPhoneInfo[0]?.countryCode || '',
    };
    defaultPhoneNumber = filteredUserPhoneInfo[0]?.phoneNumber || '';
  } else {
    const wcCookiePhoneNumber = Cookie.load(REMEMBER_PHONE_COOKIE_KEY);
    if (wcCookiePhoneNumber && wcCookiePhoneNumber.indexOf('#') > -1) {
      easyStore.easySet(
        REMEMBER_PHONE_COOKIE_KEY,
        wcCookiePhoneNumber,
        storeType.localStorage,
      );
      Cookie.remove(REMEMBER_PHONE_COOKIE_KEY);
    }
    const phoneNumerFromStorage = easyStore.easyGet(REMEMBER_PHONE_COOKIE_KEY);
    if (phoneNumerFromStorage) {
      const [name, phoneNumber] = phoneNumerFromStorage.split('#');
      const country = meetingConfig.callOutCountry.find(
        (item) => item.name === name,
      );
      if (country) {
        defaultCountry = {
          value: country.name,
          label: `${country.name} (${country.code})`,
          code: country.code,
        };
        defaultPhoneNumber = phoneNumber;
        isDefaultRememberChecked = true;
      }
    }
    if (defaultCountry === null) {
      const countryUS = meetingConfig.callOutCountry.find(
        (item) => item.id === 'US',
      );
      const defaultCountry1 = countryUS || meetingConfig.callOutCountry[0];
      if (defaultCountry1) {
        defaultCountry = {
          value: defaultCountry1.name,
          label: `${defaultCountry1.name} (${defaultCountry1.code})`,
          code: defaultCountry1.code,
        };
      } else {
        defaultCountry = {
          value: '',
          label: '',
          code: '',
        };
      }
    }
  }
  return {
    defaultCountry,
    defaultPhoneNumber,
    isDefaultRememberChecked,
  };
}

// Cautious: lockableTaskQueueManager assumes that all the task should use the history state when pushed.
export const lockableTaskQueueManager = (() => {
  const enableLog = false && CLIENT_ENV === 'development';

  let taskStore = {};
  const LOCKABLE_TYPE_ENUM = {
    AUDIO_CAPTURE: 'AUDIO_CAPTURE',
  };

  const log = (...rest) => {
    if (enableLog) {
      /* eslint-disable-next-line no-console */
      console.log(...rest);
    }
  };

  const pushTask = (type, task) => {
    log('pushTask: ', type, task, 'queue: ', taskStore[type]);
    if (!Array.isArray(taskStore[type])) {
      taskStore[type] = [];
    }
    const targetQueue = taskStore[type];
    targetQueue.push(task);
    if (targetQueue.length === 1) {
      log(
        'do task in pushTask:',
        'type:',
        type,
        'task:',
        task,
        'store:',
        JSON.stringify(taskStore),
      );
      task();
    }
  };

  const doNext = (type) => {
    log('doNext: ', type, 'queue: ', taskStore[type]);
    const targetQueue = taskStore[type];
    if (!Array.isArray(targetQueue) || targetQueue.length < 1) {
      return;
    }
    targetQueue.shift();
    if (targetQueue.length) {
      targetQueue[0]();
    }
  };

  const checkIsLocked = (type) => {
    const targetQueue = taskStore[type];
    if (Array.isArray(targetQueue) && targetQueue.length) {
      log('checkIsLocked', true);
      return true;
    }
    log('checkIsLocked', false);
    return false;
  };

  const resetAll = () => {
    taskStore = {};
  };

  const reset = (type) => {
    if (taskStore[type]) {
      taskStore[type] = undefined;
    }
  };

  return {
    pushTask,
    doNext,
    checkIsLocked,
    LOCKABLE_TYPE_ENUM,
    resetAll,
    reset,
  };
})();

export const checkIsAudioCaptureLocked = () => {
  return lockableTaskQueueManager.checkIsLocked(
    lockableTaskQueueManager.LOCKABLE_TYPE_ENUM.AUDIO_CAPTURE,
  );
};

export const initAudioEncode = ({
  initAudioEncodeStatus,
  meetingJoinStatus,
  conID,
  meetingNumber,
  svcUrl,
  userId,
  confId,
}) => {
  let p = Promise.resolve();
  if (initAudioEncodeStatus !== 'success' && isSupportAV()) {
    const { audioEncWorkerPath, audioRwcIpAddress } = getAVMeetingParams({
      meetingStatus: meetingJoinStatus,
      conferenceID: conID,
      meetingNumber,
      svcUrl,
    });
    p = globalVariable.avSocket.enableAudioEncode({
      audioEncWorkerPath,
      audioRwcIpAddress,
      userId,
      confId,
    });
  }
  return p;
};

export const isAudioShortcutTriggered = (e) => {
  if (e.code !== 'KeyA') {
    return;
  }
  if (isWindows()) {
    return e.altKey;
  }
};
