import { createRef } from 'react';
import { toggleInstanceOpen } from '../redux/dialog-action';
import Modal from '../../../global/components/widget/modal';
import { defaultCancelButtonProps, defaultOkButtonProps } from '../constant';

export const instantDialogRef = createRef();
export const startRecordingErrorRef = createRef();
export const deleteMessageDialogRef = createRef();
export const notAllowUnmuteDialogRef = createRef();
export const consentBannerRef = createRef();
export const dialogRefs = {
  instantDialogRef,
  startRecordingErrorRef,
  notAllowUnmuteDialogRef,
  deleteMessageDialogRef,
};
export const makeDialogRef = (type, existRef) => {
  if (existRef) {
    return existRef;
  }
  if (!type) {
    return null;
  }

  if (!dialogRefs[type]) {
    dialogRefs[type] = createRef();
  } else {
    // throttle
    const tmp = dialogRefs[type];
    setTimeout(() => tmp?.current?.close?.(), 200);
    dialogRefs[type] = createRef();
  }

  return dialogRefs[type];
};

export const closeRefDialog = (type) => {
  dialogRefs[type]?.current?.close?.();
};

export function closeAllRefDialog() {
  Object.values(dialogRefs).forEach((ref) => {
    ref?.current?.close?.();
  });
}

export function isExistOnlyOneRefDialog() {
  return Object.values(dialogRefs).filter((ref) => ref.current).length === 1;
}

export function checkModalDialogExist(dialogType) {
  return !!dialogRefs[dialogType]?.current;
}

// please go to see consent-banner

export const commonModalThunk = ({
  cancelText,
  okButtonProps,
  cancelButtonProps,
  className,
  ref: existRef,
  onAfterClose,
  title,
  content,
  okText,
  dialogType,
  onOk,
  onCancel,
  isModal = true,
  contentLabel,
  ...rest
}) => {
  return (dispatch) => {
    const ref = makeDialogRef(dialogType, existRef);
    dispatch(toggleInstanceOpen(true));
    return Modal.confirm({
      id: dialogType,
      title,
      contentLabel: contentLabel || title,
      content,
      className: className || 'zm-modal-legacy archiving-dialog',
      okButtonProps: okButtonProps || defaultOkButtonProps,
      okText,
      onOk,
      cancelButtonProps: cancelButtonProps || defaultCancelButtonProps,
      ref: ref || instantDialogRef,
      cancelText,
      onCancel,
      isModal,
      onAfterClose: () => {
        if (isExistOnlyOneRefDialog()) {
          dispatch(toggleInstanceOpen(false));
        }
        onAfterClose?.();
      },
      ...rest,
    });
  };
};
