import {
  CONTROL_MODE_READY_FACTOR,
  CONTROL_MODE_CAP_FULL,
  CONTROL_MODE_CAP_LEVEL_VIEWONLY,
  RWG_CONF_END_ERROR_CODE,
  RWG_JOIN_RESPONSE_ERROR_CODE,
} from '../../enum';
import {
  WS_CONF_JOIN_RES,
  WS_CONF_HOLD_CHANGE_INDICATION,
  WS_CONF_END_INDICATION,
  WS_CONF_ROSTER_INDICATION,
} from '../../../constants/ZoomSocketEventTypes';
import { CONF_SUCCESS } from '../../../constants/ErrorTypes';
import { setActiveCameraDevice } from '../../../features/video/redux/video-action';
import {
  setActiveSpeaker,
  setActiveMicrophone,
} from '../../../features/audio/redux/audio-action';
import {
  LEAVEREASON_RECONNECT,
  LEAVESUBREASON_WAITINGROOMFAILOVER,
} from '../../../constants/LeaveReasons';
import { localReadyStatesObserver } from './local-ready-states-observer';
import { isMTRAndroid } from '../../../global';
import { RECOONECT_RWG_WHEN_CLOSE } from '../../../constants/ExeternalControllerErrorTypes';
import * as leaveReasonType from '../../../constants/LeaveReasons';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';

export function rwgMsgHandler(evt, payload, store, adaptor) {
  const { dispatch, getState } = store;
  const {
    meeting: { currentUser },
  } = getState();
  const isWb = isWebinar();
  switch (evt) {
    case WS_CONF_JOIN_RES: {
      switch (payload.body.res) {
        case CONF_SUCCESS:
          dispatch(setActiveMicrophone(adaptor.getMicId()));
          dispatch(setActiveSpeaker(adaptor.getSpeakerId()));
          dispatch(setActiveCameraDevice(adaptor.getCameraId()));
          break;
        default:
          if (
            !Object.entries(RWG_JOIN_RESPONSE_ERROR_CODE).some(([, value]) => {
              if (value.code === Number(payload.body.res)) {
                adaptor.notifyControllerJoinFailure(value);
                return true;
              }
              return false;
            })
          ) {
            adaptor.notifyControllerJoinFailure({
              type: 'rwg',
              code: payload.body.res,
              message: 'Join rwg failed',
            });
          }
          break;
      }
      break;
    }
    case WS_CONF_ROSTER_INDICATION: {
      if (payload.body.update) {
        payload.body.update.forEach((user) => {
          if (user.id === currentUser.userId) {
            if (user.muted !== undefined) {
              if (user.muted) {
                adaptor.notifyControllerAudioMuted();
              } else {
                adaptor.notifyControllerAudioUnmuted();
              }
            }
          }
        });
      }
      if (!localReadyStatesObserver.isTargetCapSet()) {
        if (!payload.body.add) return;
        const me = payload.body.add.find(
          (user) => user.id === currentUser.userId,
        );
        if (!me) return;

        const isLimitedSupport = isViewOnly(me.role) && isWb;
        const targetVal = isLimitedSupport
          ? CONTROL_MODE_CAP_LEVEL_VIEWONLY
          : CONTROL_MODE_CAP_FULL;
        localReadyStatesObserver.setTargetCap(targetVal);
        localReadyStatesObserver.complete(
          CONTROL_MODE_READY_FACTOR.RWG_CONNECTED,
        );
        /* eslint-disable-next-line no-console */
        console.error(
          `Ready State Check: rwg mapp result : ${CONTROL_MODE_READY_FACTOR.RWG_CONNECTED}`,
        );
      }
      break;
    }

    case WS_CONF_HOLD_CHANGE_INDICATION: {
      if (payload.body.bHold) {
        adaptor.notifyControllerMeetingHoldOn();
      }
      break;
    }
    case WS_CONF_END_INDICATION: {
      if (
        LEAVEREASON_RECONNECT === payload.body.reason &&
        LEAVESUBREASON_WAITINGROOMFAILOVER === payload.body.subReason
      ) {
        if (!isMTRAndroid()) {
          adaptor.notifyControllerMeetingHoldOn();
        }
      } else if (
        !Object.entries(RWG_CONF_END_ERROR_CODE).some(([, value]) => {
          if (value.code === Number(payload.body.reason)) {
            adaptor.notifyControllerMeetingEnd(value, true);
            return true;
          }
          return false;
        }) &&
        leaveReasonType.LEAVEREASON_USERREQUEST !== payload.body.reason
      ) {
        adaptor.notifyControllerMeetingEnd(
          {
            type: 'rwg-leave-reason',
            code: payload.body.reason,
            message: 'Leave meeting for some reason',
          },
          true,
        );
      }
      break;
    }
    case RECOONECT_RWG_WHEN_CLOSE: {
      adaptor.notifyControllerJoinFailure(
        RWG_JOIN_RESPONSE_ERROR_CODE.RECOONECT_RWG_WHEN_CLOSE_ERROR,
      );
      break;
    }
    default:
      break;
  }
}
