/* eslint-disable react/display-name */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { WEBGL_LOST_REPLACE_CANVAS } from '../../constants/AVNotifyMediaSDKTypes';
import { SET_LOST_CONTEXT_CANVAS_LIST } from '../../constants/MeetingActionTypes';
import { globalVariable } from '../global-variable';

const ProtectedCanvas = forwardRef((props, ref) => {
  const lostContextCanvasList = useSelector(
    (state) => state.meeting.lostContextCanvasList,
  );
  const dispatch = useDispatch();
  const { id } = props;
  const localRef = useRef(null);
  const localRefCallback = useCallback(
    (el) => {
      ref(el);
      localRef.current = el;
    },
    [ref],
  );

  const [reRenderIdx, setRenderIdx] = useState(0);
  useEffect(() => {
    lostContextCanvasList.some((canvasId) => {
      if (id && canvasId === id) {
        dispatch({
          type: SET_LOST_CONTEXT_CANVAS_LIST,
          payload: {
            type: 'remove',
            canvasId,
          },
        });
        setRenderIdx((prevIdx) => {
          return prevIdx + 1;
        });
        return true;
      }
      return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lostContextCanvasList, id]);

  useLayoutEffect(() => {
    const currentCanvas =
      ref && typeof ref !== 'function' ? ref.current : localRef.current;
    if (reRenderIdx > 0 && currentCanvas) {
      globalVariable.avSocket.sendSocket(WEBGL_LOST_REPLACE_CANVAS, {
        canvasId: id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRenderIdx]);

  const finnalRef = ref
    ? typeof ref === 'function'
      ? localRefCallback
      : ref
    : localRef;

  return <canvas {...props} ref={finnalRef} key={reRenderIdx}></canvas>;
});

ProtectedCanvas.propTypes = {
  id: PropTypes.string,
};

export default ProtectedCanvas;
