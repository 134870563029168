import { createSelector } from 'reselect';
import meetingConfig from 'meetingConfig';
import { restrictFeaturesSelector } from '../../../global/redux/restrict-features-selector';
import {
  isEnableOneChatUI,
  isEnablePMC,
  isSimulive,
} from '../../../global/service';
import { JOIN_MEETING_POLICY } from '../../../global/constant';
import { archiveChatDisabled } from '../utils';
import { isOnHoldSelector } from '../../../global/redux/selector';
import { isMeInRoomSelector } from '../../breakout-room/redux/bo-room-selector';
import { isWebinar } from '../../../global/service/meeting-types';
import _ from 'lodash';

export const displayPMCUISelector = createSelector(
  [isOnHoldSelector, isMeInRoomSelector],
  (isOnHold, isInRoom) => {
    return !isOnHold && !isInRoom && isEnablePMC() && !isWebinar();
  },
);

const unreadChatNumber = ({ chat: { meetingChat } }) => {
  return meetingChat;
};

export const unreadChatNumberOld = createSelector(
  [unreadChatNumber],
  (meetingChat) => {
    let counts = 0;
    meetingChat.forEach((meetingChat) => {
      (meetingChat.chatMsgs || []).forEach((msg) => {
        counts += msg.read ? 0 : 1;
      });
    });
    return counts;
  },
);

export const unreadChatNumberNew = createSelector(
  [({ newChat: { newIndexes } }) => newIndexes],
  (newIndexes) => {
    return newIndexes.length;
  },
);

export const unreadChatNumberForOneChat = (state) =>
  state.oneChat.unreadChannelMap;

export const unreadChatNumberSelector = createSelector(
  [
    unreadChatNumberOld,
    unreadChatNumberNew,
    unreadChatNumberForOneChat,
    (_, isNewChat) => isNewChat,
  ],
  (old, newVer, unreadForOneChat, isNewChat) => {
    if (isEnableOneChatUI()) {
      const unreadChatNumber =
        unreadForOneChat[Object.keys(unreadForOneChat)[0]]?.unreadCount;
      return unreadChatNumber;
    }
    if (isNewChat) return newVer;
    return old;
  },
);

const archiveInfoSelector = (state) => state.meeting.archiveInfo;

export const isRestrictChatSelector = createSelector(
  restrictFeaturesSelector,
  (restrictFeatures) => {
    return restrictFeatures[JOIN_MEETING_POLICY.CHAT];
  },
);

export const isChatEnableSelector = createSelector(
  [archiveInfoSelector, isRestrictChatSelector],
  (archiveInfo, restrictChat) =>
    meetingConfig.meetingOptions.isChatEnabled &&
    !isSimulive() &&
    !archiveChatDisabled(archiveInfo) &&
    !restrictChat,
);

const userShareInChatSelector = (state) =>
  state.meeting.shareFileConfig.shareInChat;

export const shareInChatSelector = createSelector(
  userShareInChatSelector,
  (userShareInChat) =>
    _.intersectionBy(
      userShareInChat,
      meetingConfig.fileTransfer.shareInChat,
      'type',
    ),
);
