/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { errorLog } from '../../web-client-logger';
import { makeLogger } from '../../logger';
import DefaultFallback from '../../containers/error-screens/default-fallback/default-fallback';

export const logger = makeLogger(['ERROR_BOUNDARY']);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Note: error is an instance of Error, not ErrorEvent.
    logger.error(
      `Error Boundary caught error: ${error.message}, file: ${error.fileName}, line: ${error.lineNumber}:${error.columnNumber} component stack: ${info.componentStack}, stack: ${error.stack}`,
    );
    errorLog(error.message);
    errorLog(info.componentStack);
  }

  render() {
    const { hasError } = this.state;
    const { children, fallback } = this.props;
    if (hasError) {
      return fallback;
    }
    return children;
  }
}

ErrorBoundary.defaultProps = {
  fallback: <DefaultFallback />,
};

export default function errorBoundary(Component, fallback) {
  const C = (props) => (
    <ErrorBoundary fallback={fallback}>
      <Component {...props} />
    </ErrorBoundary>
  );

  C.displayName = `errorBoundary(${Component.displayName || Component.name})`;
  return C;
}
