import { makeChatEntity } from './chat-entity';
import { makeDeviceEntity } from './device-entity';
import { makeMeetingEntity } from './meeting-entity';
import { makeProductEntity } from './product-entity';
import { makeUserEntity } from './user-entity';
import { makeSessionEntity } from './session-entity';
import { makeBreakoutRoomEntity } from './breakout-room';
import { makeMeetingPreferencesEntity } from './meeting-preferences-entity';
import { makeMeetingSettingEntity } from './meeting-setting-entity';

/**
 * entitiesMap includes all entities that snowplow need, only the non-necessary ones you need to set in props of sendTelemetryEvent
 */
export const entitiesMap = {
  chatEntity: { maker: makeChatEntity, optional: true },
  breakoutRoomEntity: { maker: makeBreakoutRoomEntity, optional: true },
  meetingPreferencesEntity: {
    maker: makeMeetingPreferencesEntity,
    optional: true,
  },
  meetingSettingsEntity: { maker: makeMeetingSettingEntity, optional: true },

  deviceEntity: { maker: makeDeviceEntity },
  meetingEntity: { maker: makeMeetingEntity },
  productEntity: { maker: makeProductEntity },
  userEntity: { maker: makeUserEntity },
  sessionEntity: { maker: makeSessionEntity },
};
export const theNecessaryEntities = Object.entries(entitiesMap)
  .filter(([, value]) => !value.optional)
  .map(([name]) => name);
