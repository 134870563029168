import { noop } from 'lodash';
import { useEffect } from 'react';
import eb from '../event-bus';

export function useMediaSDKEvent(event, handler = noop) {
  useEffect(() => {
    const _handler = ([type, data]) => {
      if (type === event) {
        handler(data);
      }
    };
    eb.on('media-sdk-event', _handler);
    return () => {
      eb.off('media-sdk-event', _handler);
    };
  }, [event, handler]);
}
