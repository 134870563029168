import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { getDeviceType, isExternalControlledMode } from '../../util';
import { sendRWGMessage } from '../../../tasks/global/websocket/webclient-websocket';

export function sendSocketMonitorLog(log) {
  if (CLIENT_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn('[monitor log]: ', log);
  }
  const data = {
    evt: socketEventTypes.WS_CONF_MONITOR_LOG_REQ,
    body: {
      data: `WCL_M, ${log}`,
    },
  };
  sendRWGMessage(data);
  return () => {};
}

export function sendDeviceTypeMonitorLog() {
  return (dispatch) => {
    if (!isExternalControlledMode()) {
      return;
    }

    const data = {
      evt: socketEventTypes.WS_CONF_MONITOR_LOG_REQ,
      body: {
        data: `WCL_DEVICE_TYPE, ${getDeviceType()}`,
      },
    };
    dispatch(sendSocketMessage(data));
  };
}
