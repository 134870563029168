export const INTERPRETATION_LANG_TYPES = {
  ORIGINAL: -1,
  US: 0,
  CN: 1,
  JP: 2,
  DE: 3,
  FR: 4,
  RU: 5,
  PT: 6,
  ES: 7,
  KR: 8,
};

export const SIGN_LANGUAGE_INTERPRETATION_LANG_TYPES = {
  ORIGINAL: 'OG',
  ASL: 'ARE',
  CSL: 'CSL',
  LSF: 'FSL',
  DGS: 'GSG',
  JSL: 'JSL',
  RSL: 'RSL',
  PSR: 'PSR',
  LSE: 'SSP',
  KSL: 'KVK',
  BSL: 'BFI',
};
export const HTML5_CAPS_OPTION_SUPPORT_INTERPERTATION = 0x00004000;
export const HTML5_CAPS_OPTION_SUPPORT_SIGN_LANGUAGE = 0x00008000;

export const INTERPRETATION_EVENT_STATUS = {
  START: 1,
  UPDATE: 2,
  END: 3,
};

export const BASE_CUSTOM_LAN_LIMIT = 5;
export const MAX_CUSTOM_LAN_LIMIT = 25;
