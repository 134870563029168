/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'react-modal';
import classNames from 'classnames';
import ZoomButton from '../button/zoom-button';
import Checkbox from '../checkbox/checkbox';
import { MODAL_BANNER_TIMEOUT } from '../../../../constants/Constants';
import _ from 'lodash';
import { outSideLinkWrap } from '../../../util';

const defaultModalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: 'none',
    zIndex: 1,
  },
};

const Modal = (props) => {
  const {
    onOk,
    onCancel,
    onClose,
    okText,
    cancelText,
    okButtonProps,
    cancelButtonProps,
    isModal,
    visible,
    footer,
    title,
    className,
    contentLabel,
    rModalProps, // todo: remove it to be more simple
    checkboxText,
    linkText,
    linkUrl,
    checkboxProps,
    children,
    isAutoTimeout,
    onTimeout,
    // ...rest, todo: add rest
  } = props;
  const [checked, setChecked] = useState(!!checkboxProps?.checked);
  const { onChange: onCheckboxChange, ...restCheckboxProps } =
    checkboxProps || {};
  const handleCheckBoxChange = (val) => {
    setChecked(val);
    if (onCheckboxChange) {
      onCheckboxChange(val);
    }
  };
  const handleOk = (e) => {
    if (onOk) {
      onOk(e, checked);
    }
  };
  const handleCancel = (e) => {
    if (onCancel) {
      onCancel(e, checked);
    }
  };
  const handleOnClose = (e) => {
    if (onClose) {
      onClose(e, checked);
    } else {
      handleCancel(e, checked);
    }
  };

  useEffect(() => {
    if (visible && isAutoTimeout) {
      setTimeout(() => {
        onTimeout();
      }, MODAL_BANNER_TIMEOUT);
    }
  }, [visible, isAutoTimeout, onTimeout]);

  const footerNode = footer || (
    <div className="zm-modal-footer-default">
      {checkboxText ? (
        <div className="zm-modal-footer-default-checkbox">
          <Checkbox onChange={handleCheckBoxChange} {...restCheckboxProps}>
            {checkboxText}
          </Checkbox>
        </div>
      ) : null}
      {linkText && linkUrl ? (
        <a
          className="zm-modal-footer-default-anchor "
          target="_blank"
          rel="noopener noreferrer"
          title={linkText}
          href={outSideLinkWrap(linkUrl)}
        >
          {linkText}
        </a>
      ) : null}

      <div className="zm-modal-footer-default-actions">
        {!!cancelText && (
          <ZoomButton onClick={handleCancel} {...cancelButtonProps}>
            {cancelText}
          </ZoomButton>
        )}
        {okText ? (
          <ZoomButton onClick={handleOk} {...okButtonProps}>
            {okText}
          </ZoomButton>
        ) : null}
      </div>
    </div>
  );
  let modalStyle = defaultModalStyle;
  if (isModal) {
    Dialog.setAppElement('#root');
  } else {
    modalStyle = {
      overlay: {
        ...defaultModalStyle.overlay,
        pointerEvents: 'none',
        background: 'none',
      },
      content: {
        ...defaultModalStyle.content,
        pointerEvents: 'visible',
      },
    };
  }
  const cls = classNames('zm-modal', className);
  return (
    <Dialog
      isOpen={visible}
      onRequestClose={isModal ? handleOnClose : null}
      style={modalStyle}
      contentLabel={contentLabel}
      {...rModalProps}
      shouldCloseOnOverlayClick={false}
    >
      <div className={cls}>
        <div className="zm-modal-body">
          <div className="zm-modal-body-title">{title}</div>
          {children && <div className="zm-modal-body-content">{children}</div>}
        </div>
        <div className="zm-modal-footer">{footerNode}</div>
      </div>
    </Dialog>
  );
};

Modal.propTypes = {
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  okText: PropTypes.node,
  cancelText: PropTypes.node,
  visible: PropTypes.bool.isRequired,
  isModal: PropTypes.bool,
  title: PropTypes.node,
  footer: PropTypes.node,
  contentLabel: PropTypes.string,
};

Modal.defaultProps = {
  okText: 'confirm',
  onClose: null,
  onCancel: null,
  isModal: true,
  title: '',
  footer: null,
  visible: false,
  onOk: _.noop,
  isAutoTimeout: false,
  onTimeout: () => void 0,
};

export default Modal;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/components/widget/modal/modal.scss';
