import meetingConfig from 'meetingConfig';
import { getBaseUrl } from '../tasks/global/task-utils/common-utils';
import deviceManager from '../device-manager';

const NETWORK_NO_SOURCE = 3;

const isBrowserSupportChangeSpeaker =
  typeof Audio.prototype.setSinkId === 'function';
/**
 * get audio functions to load or play
 * @param url {string}
 * @returns {{play, load}}
 */
const getAudio = (url) => {
  const audio = new Audio(url);
  audio.crossOrigin = 'anonymous';
  let playing = false;
  const ended = () => {
    playing = false;
  };
  audio.addEventListener('ended', ended);
  return {
    play: () => {
      if (audio.networkState === NETWORK_NO_SOURCE) {
        return;
      }
      if (playing) {
        audio.pause();
        audio.currentTime = 0;
      }
      if (isBrowserSupportChangeSpeaker) {
        const { activeSpeaker } = deviceManager.getDeviceState();
        const _activeSpeaker = activeSpeaker === 'default' ? '' : activeSpeaker;
        if (audio.sinkId !== _activeSpeaker) {
          audio.setSinkId(_activeSpeaker);
        }
      }
      /* The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22 */
      audio.play();
      playing = true;
    },
    load: () => {
      audio.load();
    },
  };
};

const getAudioResourceUrl = (resourceName) => {
  let audioUrl = '';
  if (IS_LOCAL) {
    audioUrl = `src/assets/audio/${resourceName}`;
  }
  const baseUrl = getBaseUrl();
  if (baseUrl) {
    audioUrl = `${baseUrl}/audio/${resourceName}`;
  }
  return audioUrl;
};

/**
 * get unique audio item which save in curry function though audioUrl
 * if there is no audio item exist, save it as new item
 * @type {Function}
 */
const getSingleAudio = (() => {
  const unique = [];
  return (audioResourceName) => {
    const url = getAudioResourceUrl(audioResourceName);
    const instance = unique.find((item) => item.audioUrl === url);
    if (instance !== undefined) {
      return instance.audio;
    }
    const newInstance = {
      audioUrl: url,
      audio: getAudio(url),
    };
    unique.push(newInstance);
    return newInstance.audio;
  };
})();

function isAllowChatAndRaiseChime() {
  return meetingConfig.meetingOptions.isAllowChatAndRaiseChime;
}
function isAllowChimeWhenEnterOrExit() {
  return meetingConfig.meetingOptions.playUserJoinLeaveAudio;
}

const AUDIO_TYPES = {
  RAISE_HAND: 'raise-hand-chime.mp3',
  CHAT: 'incoming-msg-chime.mp3',
  PARTICIPANT_ENTER: 'm-entry.mp3',
  PARTICIPANT_EXIT: 'm-exit.mp3',
  PARTICIPANT_ENTER_WAITING_ROOM: 'm-wr.mp3',
  ARCHIVING_START: 'archival.mp3',
  RECORDING_START: 'meeting_is_now_recorded.mp3',
  RECORDING_STOP: 'recording_is_now_stopped.mp3',
};

const allAudioResources = [
  {
    type: AUDIO_TYPES.RAISE_HAND,
    isEnable: isAllowChatAndRaiseChime(),
    audioInstance: getSingleAudio(AUDIO_TYPES.RAISE_HAND),
  },
  {
    type: AUDIO_TYPES.CHAT,
    isEnable: isAllowChatAndRaiseChime(),
    audioInstance: getSingleAudio(AUDIO_TYPES.CHAT),
  },
  {
    type: AUDIO_TYPES.PARTICIPANT_ENTER,
    isEnable: isAllowChimeWhenEnterOrExit(),
    audioInstance: getSingleAudio(AUDIO_TYPES.PARTICIPANT_ENTER),
  },
  {
    type: AUDIO_TYPES.PARTICIPANT_EXIT,
    isEnable: isAllowChimeWhenEnterOrExit(),
    audioInstance: getSingleAudio(AUDIO_TYPES.PARTICIPANT_EXIT),
  },
  {
    type: AUDIO_TYPES.PARTICIPANT_ENTER_WAITING_ROOM,
    isEnable: isAllowChimeWhenEnterOrExit(),
    audioInstance: getSingleAudio(AUDIO_TYPES.PARTICIPANT_ENTER_WAITING_ROOM),
  },
  {
    type: AUDIO_TYPES.ARCHIVING_START,
    isEnable: true,
    audioInstance: getSingleAudio(AUDIO_TYPES.ARCHIVING_START),
  },
  {
    type: AUDIO_TYPES.RECORDING_START,
    isEnable: true,
    audioInstance: getSingleAudio(AUDIO_TYPES.RECORDING_START),
  },
  {
    type: AUDIO_TYPES.RECORDING_STOP,
    isEnable: true,
    audioInstance: getSingleAudio(AUDIO_TYPES.RECORDING_STOP),
  },
];

const playAudio = (type) => {
  const audio = allAudioResources.find((audio) => audio.type === type);
  if (audio) {
    audio.audioInstance.play();
  }
};

export const playRaiseHandAudio = () => {
  playAudio(AUDIO_TYPES.RAISE_HAND);
};
export const playChatAudio = () => {
  playAudio(AUDIO_TYPES.CHAT);
};
export const playParticipantEnterAudio = () => {
  playAudio(AUDIO_TYPES.PARTICIPANT_ENTER);
};
export const playParticipantExitAudio = () => {
  playAudio(AUDIO_TYPES.PARTICIPANT_EXIT);
};
export const playEnterWaitingRoomAudio = () => {
  playAudio(AUDIO_TYPES.PARTICIPANT_ENTER_WAITING_ROOM);
};

export const playArchivingAudio = () => {
  playAudio(AUDIO_TYPES.ARCHIVING_START);
};

export const playRecordingStartAudio = () => {
  playAudio(AUDIO_TYPES.RECORDING_START);
};

export const playRecordingStopAudio = () => {
  playAudio(AUDIO_TYPES.RECORDING_STOP);
};

export const loadAllAudio = () => {
  allAudioResources.forEach((audioConfig) => {
    if (audioConfig.isEnable) {
      audioConfig.audioInstance.load();
    }
  });
};
