import axios from 'axios';
import meetingConfig from 'meetingConfig';
import { produce } from 'immer';
import { encodeBase64, decodeBase64 } from '../../../global/util';
import { beginDecrypt, beginEncrypt, ivType } from '../../../global/crypto';
import { extractFileInfoFromXml } from '../transform/cover';
import { buildSendXmppMsgData } from '../transform/buildSendXmppMsgData';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { WS_CONF_CHAT_FILE_TRANSFER_REQ } from '../../../constants/ZoomSocketEventTypes';
import {
  CHAT_FROM,
  JOIN_MEETING_POLICY,
  MAX_FILES_COUNT,
  SEND_CHAT_WARNING,
} from '../../../global/constant';
import {
  // CHAT_MSG_TYPE_TO_ALL,
  // CHAT_MSG_TYPE_TO_PANELIST,
  // CHAT_MSG_TYPE_TO_SILENT_MODE_USERS,
  // CHAT_MSG_TYPE_TO_INDIVIDUAL,
  // // CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
  // CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS,
  CHAT_FILE_UPLOAD_STATUS,
  CHAT_FILE_DOWNLOAD_STATUS,
  CHAT_FILE_CHUNK_SIZE,
  CHAT_FILE_ENCODE_BLOCK_SIZE,
  CHAT_FILE_DECODE_BLOCK_SIZE,
  CHAT_FILE_UPLOAD_ERROR_TYPE,
  CHAT_FILE_TYPE,
  CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
} from '../../chat/constants';
// import { isSupportedTimeZone } from '../../reaction/utils';
import {
  updateNewChatThunk,
  updateSenderSelfChatMessage,
} from './new-chat-thunk';
// import { buildNewMeetingChat } from './buildNewMeetingChat';
import { buildListMeetingChat } from './buildListMeetingChat';
import {
  setSelectedFiles,
  updateNewMeetingChat,
  updateNewMeetingChatFile,
} from './new-chat-action';
import {
  removeUploadCancelToken,
  setUploadCancelTokens,
} from '../../chat/redux/chat-action';
import { getIsFileLoading } from '../../interpretation/util';
import {
  getCorrect3rdPartyFileUrl,
  getFileDownloadUrl,
  getFileSuffix,
  getMimeTypeByName,
} from '../../chat/utils';
import { combineArrayBuffer } from '../../chat/redux/chat-thunk-action';
import { readFileAsChunk } from '../../settings/service';
import {
  ImageExtentions,
  checkBeforeSentAndGetReceiverId,
  generateLocalMsgId,
  getXmppMsgFromToSenderNameMid,
} from '../help';
import {
  doubleConfirmBeforeDownloadFile,
  resolveFileInfo,
} from '../../chat/service';
import { deleteOneMessageById } from './new-chat-reducer';
import { calcFileHashHex } from './calcFileHashHex';
import { bridgeEmitter } from '../../../global/ChatSDK/bridgeEmitter';
import {
  ENC_ENUM,
  FILE_UPLOAD_CHANNEL,
  KEY_GEN_ENUM,
  cloudTypeMap,
} from '../../../global/ChatSDK/enums';
import { isEnablePMC, isEnablePMCFileSync } from '../../../global/service';
import {
  setChatWarning,
  setChatWarning as showNewChatWarning,
} from '../../../actions/MeetingUIPureAction';
import { makeLogger } from '../../../global/logger';

const logger = makeLogger(['New Chat File Transfer']);

const CancelToken = axios.CancelToken;
export function sendNewChatFileShare(oldBody, oldChatMessage) {
  return (dispatch, getState) => {
    if (bridgeEmitter.all.get('sendCloudFileMessage')?.length) {
      const { file } = oldChatMessage;
      const content = {
        fileName: file.fileName,
        fileSize: file.fileSize,
        thirdPartyInfo: {
          id: file.fileID,
          previewLink: file.previewUrl,
          previewPath: file.previewPath,
          fileName: file.fileName,
          fileSize: +file.fileSize,
          thirdPartyFileType: '0', // make zop happy
          integrationType: '' + cloudTypeMap[file.shareType],
        },
      };
      bridgeEmitter.emit('sendCloudFileMessage', content);
      return;
    }
    const {
      meeting: { meetingTopic, currentUser, chatPriviledge },
      chat: { thirdPartyFileReplyTo },
      attendeesList: { attendeesList },
    } = getState();
    const newBody = oldBody;
    const chatMessage = oldChatMessage;
    const xmppMsgId = generateLocalMsgId(currentUser);

    // fileID: fileId,
    // fileSize: parseInt(fileSize),
    // fileName,
    // fileType: CHAT_FILE_TYPE.THIRD_PARTY,
    // destNodeID: destNode.destNodeID,
    // attendeeNodeID: destNode.attendeeNodeID,
    // previewUrl,
    // downloadUrl,
    // shareType: type,
    const { destNodeID } = newBody;
    if (!_.isEmpty(thirdPartyFileReplyTo)) {
      const beforeCheckVals = checkBeforeSentAndGetReceiverId(
        {
          receiverId: destNodeID,
          attendeesList,
          chatPriviledge,
          isMeInRoom: !!currentUser.bid,
          currentUserPrivateChatMsgDisabled:
            currentUser.bPrivateChatMsgDisabled,
          currentIsCoOrHost: currentUser.isHost || currentUser.boCoHost,
          // isSameMeetingMessage: true, // not needed
        },
        false,
      );
      if (beforeCheckVals[0] !== SEND_CHAT_WARNING.NONE) {
        return dispatch(
          showNewChatWarning([
            beforeCheckVals[0],
            thirdPartyFileReplyTo.receiver,
          ]),
        );
      }
    }

    buildSendXmppMsgData({
      id: xmppMsgId,
      channelName: meetingTopic,
      sharedFile: chatMessage.file,
      ...getXmppMsgFromToSenderNameMid(
        currentUser.strConfUserID,
        currentUser.displayName,
      ),
      replyTo: thirdPartyFileReplyTo,
    })
      .then((xmlStr) => {
        return beginEncrypt({ text: xmlStr, type: ivType.RWG_CHAT });
        // newBody.destNodeID = destNodeID;
        // newBody.attendeeNodeID = attendeeNodeID;
      })
      .then((xmppMsgData) => {
        // console.log(xmppMsgData, 11111);
        newBody.xmppMsgData = xmppMsgData;
        newBody.msgID = xmppMsgId;
        // newBody.fileName = 'to old1111';
        dispatch(
          sendSocketMessage({
            evt: WS_CONF_CHAT_FILE_TRANSFER_REQ,
            body: newBody,
          }),
        );
        chatMessage.reply = thirdPartyFileReplyTo;
        chatMessage.timeStamp = Date.now();
        chatMessage.msgId = xmppMsgId;
        chatMessage.xmppMsgId = xmppMsgId;
        dispatch(updateSenderSelfChatMessage(chatMessage));
      });
  };
}
export function onReceiveNewChatFileMessage(message) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: {
        bIbDisableChat,
        xmppUserList: { attendees },
        currentUser,
        // defaultAvatar,
        restrictFeatures,
        fileServerDomain,
      },
      attendeesList: { attendeesList },
      chat: { isChatPinBottom },
      // newChat: { meetingChat },
    } = state;
    const {
      // userId: currentUserId,
      // userRole: currentUserRole,
      bid,
    } = currentUser;
    if (restrictFeatures[JOIN_MEETING_POLICY.CHAT]) return;
    // const coOrHost = coOrHostSelector(state);
    const { xmppMsgData } = message.body || {};

    const isNewXmppChat = !!xmppMsgData;
    if (typeof message.body !== 'undefined' && !bIbDisableChat) {
      const latestChatMessage = message.body;
      let senderSN = latestChatMessage.sn;
      let targetUser;
      let senderName = decodeBase64(latestChatMessage.senderName);
      /* eslint-disable-next-line no-prototype-builtins */
      if (!senderSN || !latestChatMessage.hasOwnProperty('senderName')) {
        targetUser = attendeesList.find(
          (user) => user.userId === latestChatMessage.destNodeID,
        );
        if (targetUser) {
          senderSN = targetUser.zoomID;
          senderName = targetUser.displayName;
        }
      }

      const is3rdFileShare =
        latestChatMessage.fileType !== CHAT_FILE_TYPE.LOCAL;

      let fileInfo;
      if (!isNewXmppChat) {
        // old version
        if (
          !_.isNil(latestChatMessage.fileType) &&
          easyStore.easyGet('gcmEnabled')
        ) {
          fileInfo = _.pick(latestChatMessage, [
            'fileID',
            'fileType',
            'fileName',
            'fileSize',
            'fileObj',
            'senderName',
            'shareType',
            'previewUrl',
            'previewPath',
            'downloadUrl',
            'destNodeID',
            'bEncrypted',
          ]);
          fileInfo.senderSN = senderSN;
          fileInfo.fileUrl = getFileDownloadUrl(fileInfo, fileServerDomain);
          fileInfo.correctPreviewUrl = getCorrect3rdPartyFileUrl(fileInfo);
          // fileInfo.uploadBaseInfo = { fileMsgIndex: meetingChat.length };
        }
        if (fileInfo) {
          const updateMeetingChatWithFile = (fileInfo) => {
            const {
              newChat: { meetingChat: meetingChat2 },
            } = getState();
            const newMeetingChat = buildListMeetingChat(
              meetingChat2,
              {
                attendees,
                attendeesList,
                currentUser,
              },
              {
                receiverId: latestChatMessage.attendeeNodeID,
                receiver: null,
                senderId: latestChatMessage.destNodeID,
                sender: senderName,
                isConveyByXmpp: false,
                msgId: latestChatMessage.msgID,
                xmppMsgId: latestChatMessage.msgID, // could reply from old client
                isChatPinBottom,
                file: fileInfo,
                senderSN,
                bid,
              },
            );
            dispatch(updateNewChatThunk(newMeetingChat));
          };
          dispatch(
            resolveFileInfo(fileInfo, senderSN, updateMeetingChatWithFile),
          );
        }
      } else {
        // new xml chat
        const promiseList = [];
        // if (is3rdFileShare) {
        //   promiseList.push(
        //     Promise.resolve(decodeBase64(latestChatMessage.xmppMsgData)).then(
        //       (xml) => {
        //         return extractFileInfoFromXml(xml, true);
        //       },
        //     ),
        //   );
        // } else {
        promiseList.push(
          beginDecrypt({
            decryptedText: latestChatMessage.xmppMsgData,
            userId: senderSN,
            type: ivType.RWG_CHAT,
          }).then(({ message }) => {
            return extractFileInfoFromXml(message);
          }),
        );
        // }
        Promise.all(promiseList).then(([_fileInfo]) => {
          fileInfo = _fileInfo;
          // for download dialog, raw base64 string
          fileInfo.senderName = latestChatMessage.senderName;
          fileInfo.destNodeID = latestChatMessage.destNodeID;
          fileInfo.correctPreviewUrl = getCorrect3rdPartyFileUrl(fileInfo);
          const {
            newChat: { meetingChat: meetingChat2 },
          } = getState();
          if (!is3rdFileShare) {
            // fileInfo.uploadBaseInfo = { fileMsgIndex: meetingChat2.length };
            fileInfo.senderSN = senderSN;
            fileInfo.fileUrl = getFileDownloadUrl(fileInfo, fileServerDomain);
          }
          const newMeetingChat = buildListMeetingChat(
            meetingChat2,
            {
              attendees,
              attendeesList,
              currentUser,
            },
            {
              receiverId: latestChatMessage.attendeeNodeID,
              receiver: null,
              senderId: latestChatMessage.destNodeID,
              sender: senderName,
              isConveyByXmpp: false,
              msgId: latestChatMessage.msgID,
              isChatPinBottom,
              file: fileInfo,
              timeStamp: fileInfo.timeStamp,
              xmppMsgId: fileInfo.xmppMsgId,
              reply: fileInfo.reply,
              ownerRes: fileInfo.ownerRes,
              senderSN,
              bid,
            },
          );
          dispatch(updateNewChatThunk(newMeetingChat));
        });
      }
    }
  };
}

export const uploadNewChatFile =
  (fileBlob, destNode, reply = {}) =>
  (dispatch, getState) => {
    const {
      // newChat: { meetingChat },
      meeting: { currentUser },
    } = getState();
    if (_.isNil(destNode?.destNodeID) || !fileBlob) {
      return;
    }
    const requestUUID = generateLocalMsgId(currentUser);
    const uploadFile = {
      fileName: fileBlob.name,
      fileSize: fileBlob.size,
      senderSN: easyStore.getCurrentUserId(),
      fileType: CHAT_FILE_TYPE.LOCAL,
      type: fileBlob.type,
      uploadPercent: 0,
      originFile: fileBlob,
      uploadBaseInfo: {
        requestUUID,
        destNode,
      },
      uploadChunkInfo: {
        partNumber: 0,
      },
      xmppMsgId: requestUUID,
      msgId: requestUUID,
      reply,
    };
    const receiverId =
      destNode.destNodeID === CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST
        ? destNode.attendeeNodeID
        : destNode.destNodeID;
    const ct = Date.now();
    const chatMessage = {
      receiverId,
      isSilentModeUser: false,
      file: uploadFile,
      xmppMsgId: requestUUID,
      msgId: requestUUID,
      reply: reply,
      timeStamp: ct,
    };
    fileBlob.xmppMsgId = requestUUID;
    fileBlob.ct = ct;
    fileBlob.reply = reply;

    dispatch(updateSenderSelfChatMessage(chatMessage));
    if (fileBlob.size > CHAT_FILE_CHUNK_SIZE) {
      dispatch(uploadFileInit(fileBlob, destNode, requestUUID));
    } else {
      dispatch(uploadFullFile(fileBlob, destNode, requestUUID, reply));
    }
  };

export const updateNewChatFilePropertys =
  (values, xmppMsgId) => (dispatch, getState) => {
    const {
      newChat: { meetingChat },
    } = getState();

    if (!values || !xmppMsgId) {
      return;
    }

    const index = meetingChat.findIndex((item) => item.xmppMsgId === xmppMsgId);
    if (index === -1) return;

    // const newMeetingChat = produce(meetingChat, (draft) => {
    //   const chatMsgs = draft[index]?.chatMsgs;
    //   if (chatMsgs && chatMsgs[0]) {
    //     // file message is always the first one
    //     const file = chatMsgs[0]['file'];
    //     chatMsgs[0].file = _.assign({}, file, values);
    //   }
    // });
    const file = meetingChat[index].content.file;
    if (file) {
      dispatch(
        updateNewMeetingChatFile({ file: _.assign({}, file, values), index }),
      );
    }
    // dispatch(updateNewMeetingChat(newMeetingChat));
  };

const updateDownloadProgress = _.throttle((p, { xmppMsgId }, dispatch) => {
  const percent = _.parseInt(100 * (p.loaded / p.total));
  dispatch(updateNewChatFilePropertys({ downloadPercent: percent }, xmppMsgId));
}, 1000);

export const downloadChatFile = (
  file,
  isPreview = false,
  isEncrypted = true,
) => {
  return (dispatch, getState) => {
    const {
      meeting: { zmk },
    } = getState();
    const { fileUrl, senderSN, fileName } = file;
    dispatch(
      updateNewChatFilePropertys(
        {
          downloadStatus: CHAT_FILE_DOWNLOAD_STATUS.DOWNLOADING,
          downloadPercent: 0,
        },
        file.xmppMsgId,
      ),
    );
    axios
      .get(fileUrl, {
        headers: {
          zmk,
          ['Zoom-File-Origin']: 'redirect=support_auth',
        },
        responseType: 'arraybuffer',
        onDownloadProgress: (p) => updateDownloadProgress(p, file, dispatch),
        cancelToken: new CancelToken((cancel) => {
          dispatch(
            updateNewChatFilePropertys(
              {
                downloadCancelToken: cancel,
              },
              file.xmppMsgId,
            ),
          );
        }),
      })
      .then((res) => {
        let loaded = 0;
        const decodePromiseCollection = [];
        if (isEncrypted) {
          while (loaded < res.data.byteLength) {
            // decode the last 2 blocks together
            const blockSize =
              res.data.byteLength - loaded < CHAT_FILE_DECODE_BLOCK_SIZE * 2
                ? CHAT_FILE_DECODE_BLOCK_SIZE * 2
                : CHAT_FILE_DECODE_BLOCK_SIZE;
            decodePromiseCollection.push(
              beginDecrypt({
                decryptedText: res.data.slice(loaded, loaded + blockSize),
                userId: senderSN,
                type: ivType.CHAT_FILE,
              }).then(({ message }) => {
                return message;
              }),
            );
            loaded += blockSize;
          }
        } else {
          decodePromiseCollection.push(Promise.resolve(res.data));
        }

        Promise.all(decodePromiseCollection).then((results) => {
          const blob = new Blob(results, { type: getMimeTypeByName(fileName) });
          const url = window.URL.createObjectURL(blob);
          let previewURL = file?.previewURL ? file?.previewURL : url;
          if (!isPreview) {
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
          }
          dispatch(
            updateNewChatFilePropertys(
              {
                downloadStatus: CHAT_FILE_DOWNLOAD_STATUS.SUCCESS,
                previewURL,
              },
              file.xmppMsgId,
            ),
          );
        });
      })
      .catch((e) => {
        if (e.toString() !== 'Cancel') {
          dispatch(
            updateNewChatFilePropertys(
              {
                downloadStatus: CHAT_FILE_DOWNLOAD_STATUS.ERROR,
              },
              file.xmppMsgId,
            ),
          );
        } else {
          dispatch(
            updateNewChatFilePropertys(
              {
                downloadStatus: CHAT_FILE_DOWNLOAD_STATUS.CANCELED,
              },
              file.xmppMsgId,
            ),
          );
        }
      })
      .finally(() => {
        dispatch(
          updateNewChatFilePropertys(
            {
              downloadPercent: 0,
            },
            file.xmppMsgId,
          ),
        );
      });
  };
};

export const handleClickFile = (file) => (dispatch, getState) => {
  const {
    fileType,
    uploadErrorType,
    originFile,
    uploadError,
    uploadCanceled,
    correctPreviewUrl,
    fr,
  } = file;
  const { fileDownloadPromptIgnoreList } = getState().chat;
  const isLoading = getIsFileLoading(file);
  if (Number(fileType) === CHAT_FILE_TYPE.THIRD_PARTY) {
    if (correctPreviewUrl) {
      window.open(correctPreviewUrl, '_blank');
    }
    return;
  }
  if (
    isLoading ||
    uploadErrorType === CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED
  ) {
    return;
  }

  if (uploadError || uploadCanceled) {
    dispatch(resendFile(originFile, file));
    return;
  }

  const isEncrypted = fr !== CHAT_FROM.IM;
  // dispatch(downloadChatFile(file));
  doubleConfirmBeforeDownloadFile({
    file,
    downloadFileFunc: () => {
      dispatch(downloadChatFile(file, false, isEncrypted));
    },
    fileDownloadPromptIgnoreList,
  });
};

const onUploadFail = (
  error,
  fileMsgId,
  errorType = CHAT_FILE_UPLOAD_ERROR_TYPE.NORMAL,
) => {
  return (dispatch) => {
    if (error?.toString() === 'Cancel') {
      dispatch(
        updateNewChatFilePropertys(
          {
            uploadCanceled: true,
            uploadPercent: 0,
          },
          fileMsgId,
        ),
      );
    } else {
      logger.error(
        `New chat file transfer error: ${error.message}, file: ${error.fileName}, line: ${error.lineNumber}:${error.columnNumber}, stack: ${error.stack}`,
      );
      dispatch(
        updateNewChatFilePropertys(
          {
            uploadError: true,
            uploadPercent: 0,
            uploadErrorType: errorType,
          },
          fileMsgId,
        ),
      );
    }
  };
};
const updateFullUploadProgress = _.throttle((p, fileMsgId, dispatch) => {
  const percent = _.parseInt(100 * (p.loaded / p.total));
  dispatch(updateNewChatFilePropertys({ uploadPercent: percent }, fileMsgId));
}, 1000);

function sendFileTransferMessage(res, { file, destNode, requestUUID, reply }) {
  return (dispatch, getState) => {
    const {
      meeting: {
        // svcUrl,
        // conID,
        // zmk,
        fileServerDomain,
        currentUser,
        meetingTopic,
      },
    } = getState();
    const headers = res.headers;
    const fileID = headers['zoom-file-id'];
    const fileObj = headers['zoom-file-obj'];
    const fileUrl = getFileDownloadUrl({ fileObj }, fileServerDomain);

    const promiseCollection = [];
    const localXmppMsgId = file.xmppMsgId;
    promiseCollection.push(
      buildSendXmppMsgData({
        id: localXmppMsgId,
        file: {
          fileObj,
          id: fileID,
          size: file.size,
          name: file.name,
        },
        ct: file.ct,
        // message: text,
        channelName: meetingTopic,
        ...getXmppMsgFromToSenderNameMid(
          currentUser.strConfUserID,
          currentUser.displayName,
        ),
        replyTo: reply,
        // from: '90xfk32orewxzgfnvke6ig@xmppdev.zoom.us/ZoomChat_pc',
        // to: 'Lj3ljndVQS+cQq4N5c/2EQ==@conference.xmppdev.zoom.us,
        // senderName: currentUser.displayName,
        meetChatExtra: {
          _enc_alg: ENC_ENUM.GCM_AES,
          _kg: KEY_GEN_ENUM.INVALID,
          _pmc_file_sync: isEnablePMCFileSync() ? 1 : 0,
          _send_id: currentUser.userId,
          _recv_id: destNode.destNodeID,
        },
      }).then((xmlStr) => {
        return beginEncrypt({ text: xmlStr, type: ivType.RWG_CHAT });
      }),
    );
    if (meetingConfig.fileTransfer.isEnableFileTransferEncrypted) {
      promiseCollection.push(
        beginEncrypt({
          text: file.name,
          type: ivType.RWG_CHAT,
        }),
      );
      promiseCollection.push(
        beginEncrypt({
          text: file.size,
          type: ivType.RWG_CHAT,
        }),
      );
    } else {
      promiseCollection.push(Promise.resolve(encodeBase64(file.name)));
      promiseCollection.push(encodeBase64(`${file.size}`));
    }

    // update self chat
    dispatch(
      updateNewChatFilePropertys(
        {
          fileUrl,
          fileObj,
          fileID,
          uploadPercent: 100,
          uploadStatus: CHAT_FILE_UPLOAD_STATUS.SUCCESS,
          xmppMsgId: localXmppMsgId,
        },
        file.xmppMsgId,
      ),
    );
    return Promise.all(promiseCollection)
      .then(([xmppMsgData, oldFileName, oldFileSize]) => {
        dispatch(
          sendSocketMessage({
            evt: WS_CONF_CHAT_FILE_TRANSFER_REQ,
            body: {
              fileID,
              fileSize: oldFileSize,
              fileName: oldFileName,
              fileType: CHAT_FILE_TYPE.LOCAL,
              fileObj,
              type: file.type,
              destNodeID: destNode.destNodeID,
              attendeeNodeID: destNode.attendeeNodeID,
              receiverType: 0,
              xmppMsgData,
              msgID: localXmppMsgId,
            },
          }),
        );
        dispatch(removeUploadCancelToken(`${requestUUID}-full`));
      })
      .catch((error) => {
        logger.error(
          `New chat file transfer error when success: ${error.message}, file: ${error.fileName}, line: ${error.lineNumber}:${error.columnNumber}, stack: ${error.stack}`,
        );
      });
  };
}
/*
 * ENCRYPT_ALG_NONE = 0,
 * ENCRYPT_ALG_AES256 = 1,
 * ENCRYPT_ALG_AES256_GCM = 2,
 * ENCRYPT_ALG_AES256_GCM_3rd = 3,
 * webclient only support GCM now
 */
const uploadFullFile = (file, destNode, requestUUID, reply) => {
  return (dispatch, getState) => {
    const {
      meeting: {
        svcUrl,
        conID,
        zmk,
        currentUser,
        // fileServerDomain,
        // currentUser,
        // meetingTopic,
      },
      security: { reportDomain },
    } = getState();
    if (!file) {
      dispatch(
        onUploadFail(
          null,
          requestUUID,
          CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
        ),
      );
    }
    let uploadUrl = `https://${reportDomain}/wc/fileupload?filename=${encodeURIComponent(
      file.name,
    )}&filesize=${
      file.size
    }&rwg=${svcUrl}&cid=${conID}&attr=${encodeURIComponent('aesAlg:2')}`;

    if (isEnablePMC() && !currentUser.bid) {
      uploadUrl += `&channel=${FILE_UPLOAD_CHANNEL.CMC}`;
    } else {
      uploadUrl += `&channel=${FILE_UPLOAD_CHANNEL.NORMAL}`;
    }
    dispatch(
      updateNewChatFilePropertys(
        {
          uploadStatus: CHAT_FILE_UPLOAD_STATUS.PENDING,
          uploadPercent: 0,
        },
        file.xmppMsgId,
      ),
    );

    Promise.resolve().then(() => {
      const encodeBlockNum = Math.ceil(file.size / CHAT_FILE_ENCODE_BLOCK_SIZE);
      const encodePromiseCollection = [];
      for (let i = 0; i <= encodeBlockNum - 1; i++) {
        const start = i * CHAT_FILE_ENCODE_BLOCK_SIZE;
        // encode the last 2 blocks together
        const end =
          i === encodeBlockNum - 2
            ? (i + 2) * CHAT_FILE_ENCODE_BLOCK_SIZE
            : (i + 1) * CHAT_FILE_ENCODE_BLOCK_SIZE;
        encodePromiseCollection.push(
          new Promise((resolve, reject) => {
            readFileAsChunk(file, start, end)
              .then((e) => {
                beginEncrypt({
                  text: new Uint8Array(e.target.result),
                  type: ivType.CHAT_FILE,
                }).then((fileData) => {
                  resolve(fileData);
                });
              })
              .catch((e) => {
                reject(e);
              });
          }),
        );
        if (i === encodeBlockNum - 2) break;
      }

      Promise.all(encodePromiseCollection)
        .then((results) => {
          return combineArrayBuffer(results);
        })
        .then((arraybuffer) => {
          const formData = new FormData();
          formData.append('file', new File([arraybuffer], file.name));
          return axios.post(uploadUrl, formData, {
            onUploadProgress: (p) =>
              updateFullUploadProgress(p, file.xmppMsgId, dispatch),
            cancelToken: new CancelToken((cancel) => {
              dispatch(
                setUploadCancelTokens({ [`${requestUUID}-full`]: cancel }),
              );
            }),
            headers: {
              zmk,
              ['Zoom-File-Origin']: 'redirect=support_auth',
            },
          });
        })
        .then((res) => {
          return dispatch(
            sendFileTransferMessage(res, {
              file,
              destNode,
              requestUUID,
              reply,
            }),
          );
        })
        .catch((e) =>
          dispatch(
            onUploadFail(
              e,
              file.xmppMsgId,
              // CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
            ),
          ),
        );
    });
  };
};

const uploadFileComplete = (file, uploadBaseInfo, uploadCompleteInfo) => {
  return (dispatch, getState) => {
    const {
      meeting: { zmk, fileServerDomain },
    } = getState();
    const { uploadid, trackingId, destNode, requestUUID } = uploadBaseInfo;
    const uploadCompleteUrl = `https://${fileServerDomain}/upload/complete?uploadid=${uploadid}`;
    dispatch(
      updateNewChatFilePropertys(
        {
          uploadStatus: CHAT_FILE_UPLOAD_STATUS.COMPLETE,
          uploadPercent: 100,
        },
        requestUUID,
      ),
    );

    axios
      .post(uploadCompleteUrl, uploadCompleteInfo, {
        headers: {
          ['x-zm-trackingid']: trackingId,
          ['Zoom-File-Origin']: 'redirect=support_auth',
          zmk,
        },
        cancelToken: new CancelToken((cancel) => {
          dispatch(
            setUploadCancelTokens({ [`${requestUUID}-complete`]: cancel }),
          );
        }),
      })
      .then((res) => {
        return dispatch(
          sendFileTransferMessage(res, {
            destNode,
            file,
            requestUUID,
            reply: file.reply,
          }),
        );
      })
      .catch((e) =>
        dispatch(
          onUploadFail(
            e,
            requestUUID,
            CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
          ),
        ),
      );
  };
};

const updatePartUploadProgress = _.throttle(
  (p, fileMsgId, partNumber, fileSize, dispatch) => {
    const percent = _.parseInt(
      100 * (((partNumber - 1) * CHAT_FILE_CHUNK_SIZE + p.loaded) / fileSize),
    );
    dispatch(updateNewChatFilePropertys({ uploadPercent: percent }, fileMsgId));
  },
  1000,
);

const uploadFilePart = (
  file,
  partNumber,
  loaded,
  uploadBaseInfo,
  uploadCompleteInfo,
) => {
  return (dispatch, getState) => {
    const {
      meeting: { zmk, fileServerDomain },
    } = getState();
    const { uploadid, path, trackingId, requestUUID } = uploadBaseInfo;
    if (loaded >= file.size) {
      dispatch(uploadFileComplete(file, uploadBaseInfo, uploadCompleteInfo));
      return;
    }

    const fileChunkNums = Math.ceil(file.size / CHAT_FILE_CHUNK_SIZE);
    const isTheLastChunk = partNumber === fileChunkNums;
    const restSize = !isTheLastChunk
      ? CHAT_FILE_CHUNK_SIZE
      : file.size - loaded;
    const encodeBlockNum = Math.ceil(restSize / CHAT_FILE_ENCODE_BLOCK_SIZE);
    const encodePromiseCollection = [];
    let i = 0;
    while (i < encodeBlockNum) {
      const start = loaded + i * CHAT_FILE_ENCODE_BLOCK_SIZE;
      // encode the last 2 blocks together
      const isTheLast2EncodeBlock = isTheLastChunk && i == encodeBlockNum - 2;
      const end = isTheLast2EncodeBlock
        ? loaded + (i + 2) * CHAT_FILE_ENCODE_BLOCK_SIZE
        : loaded + (i + 1) * CHAT_FILE_ENCODE_BLOCK_SIZE;
      encodePromiseCollection.push(
        new Promise((resolve, reject) => {
          readFileAsChunk(file, start, end)
            .then((e) => {
              beginEncrypt({
                text: new Uint8Array(e.target.result),
                type: ivType.CHAT_FILE,
              }).then((fileData) => {
                resolve(fileData);
              });
            })
            .catch((e) => {
              reject(e);
            });
        }),
      );
      i = isTheLast2EncodeBlock ? i + 2 : i + 1;
    }
    Promise.all(encodePromiseCollection)
      .then((results) => {
        return combineArrayBuffer(results);
      })
      .then((arraybuffer) => {
        const uploadPartUrl = `https://${fileServerDomain}/upload/parts?uploadid=${uploadid}&partNumber=${partNumber}`;
        const formData = new FormData();
        const chunkFile = new File([arraybuffer], file.name);
        formData.append('file', new File([arraybuffer], file.name));
        axios
          .post(uploadPartUrl, formData, {
            headers: {
              ['Zoom-File-Size']: chunkFile.size,
              ['Zoom-File-Path']: path,
              ['x-zm-trackingid']: trackingId,
              ['Zoom-File-Origin']: 'redirect=support_auth',
              zmk,
            },
            onUploadProgress: (p) =>
              updatePartUploadProgress(
                p,
                requestUUID,
                partNumber,
                file.size,
                dispatch,
              ),
            cancelToken: new CancelToken((cancel) => {
              dispatch(
                setUploadCancelTokens({
                  [`${requestUUID}-part-${partNumber}`]: cancel,
                }),
              );
            }),
          })
          .then((res) => {
            const etag = res.headers['x-zm-etag'];
            const newCompleteInfo = _.cloneDeep(uploadCompleteInfo);
            newCompleteInfo['etags'][partNumber] = etag;
            dispatch(
              updateNewChatFilePropertys(
                {
                  uploadChunkInfo: {
                    partNumber,
                    uploadCompleteInfo: newCompleteInfo,
                  },
                },
                requestUUID,
              ),
            );
            dispatch(
              uploadFilePart(
                file,
                partNumber + 1,
                loaded + CHAT_FILE_CHUNK_SIZE,
                uploadBaseInfo,
                newCompleteInfo,
              ),
            );
            dispatch(
              removeUploadCancelToken(`${requestUUID}-part-${partNumber}`),
            );
          })
          .catch((e) => {
            dispatch(onUploadFail(e, requestUUID));
          });
      })
      .catch((e) =>
        dispatch(
          onUploadFail(
            e,
            requestUUID,
            CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
          ),
        ),
      );
  };
};

const uploadFileInit = (file, destNode, /*  fileMsgIndex, */ requestUUID) => {
  return (dispatch, getState) => {
    const {
      meeting: { svcUrl, conID, zmk, meetingId, currentUser },
      security: { reportDomain },
    } = getState();
    if (!file) {
      dispatch(
        onUploadFail(
          null,
          requestUUID,
          CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
        ),
      );
    }
    let uploadInitUrl = `https://${reportDomain}/wc/multiupload/init?filename=${encodeURIComponent(
      file.name,
    )}&filesize=${file.size}&rwg=${svcUrl}&cid=${conID}`;

    if (isEnablePMC() && !currentUser.bid) {
      uploadInitUrl += `&channel=${FILE_UPLOAD_CHANNEL.CMC}`;
    } else {
      uploadInitUrl += `&channel=${FILE_UPLOAD_CHANNEL.NORMAL}`;
    }
    dispatch(
      updateNewChatFilePropertys(
        {
          uploadStatus: CHAT_FILE_UPLOAD_STATUS.INIT,
          uploadPercent: 0,
        },
        requestUUID,
      ),
    );
    calcFileHashHex(file)
      .then((digest) => {
        axios
          .post(
            uploadInitUrl,
            {
              fileName: file.name,
              length: file.size,
              channelType: 4,
              digest,
              shareJid: meetingId,
              attribute: {
                aesAlg: 2,
              },
            },
            {
              headers: {
                zmk,
                ['Zoom-File-Origin']: 'redirect=support_auth',
              },
              cancelToken: new CancelToken((cancel) => {
                dispatch(
                  setUploadCancelTokens({ [`${requestUUID}-init`]: cancel }),
                );
              }),
            },
          )
          .then((res) => {
            const trackingId = res.headers['x-zm-trackingid'];
            const { uploadid, path, metadata } = res.data;
            if (!uploadid) {
              uploadFileInit(file, destNode, /* fileMsgIndex, */ requestUUID);
              return;
            }
            const uploadCompleteInfo = {
              metadata,
              etags: {},
            };
            const uploadBaseInfo = {
              uploadid,
              path,
              trackingId,
              // fileMsgIndex,
              destNode,
              requestUUID,
            };
            dispatch(
              updateNewChatFilePropertys(
                {
                  uploadBaseInfo,
                  uploadChunkInfo: {
                    partNumber: 0,
                    uploadCompleteInfo,
                  },
                  uploadStatus: CHAT_FILE_UPLOAD_STATUS.PENDING,
                },
                requestUUID,
              ),
            );
            dispatch(
              uploadFilePart(file, 1, 0, uploadBaseInfo, uploadCompleteInfo),
            );
            dispatch(removeUploadCancelToken(`${requestUUID}-init`));
          })
          .catch((e) => {
            dispatch(onUploadFail(e, requestUUID));
          });
      })
      .catch((e) =>
        dispatch(
          onUploadFail(
            e,
            requestUUID,
            CHAT_FILE_UPLOAD_ERROR_TYPE.FILE_REMOVED,
          ),
        ),
      );
  };
};

export function resendFile(fileBlob, uploadFileInfo) {
  return (dispatch) => {
    if (!fileBlob) {
      return;
    }

    const { uploadChunkInfo, uploadBaseInfo, uploadStatus, reply } =
      uploadFileInfo;
    const { destNode, requestUUID } = uploadBaseInfo;
    const { partNumber, uploadCompleteInfo } = uploadChunkInfo;
    if (fileBlob.size <= CHAT_FILE_CHUNK_SIZE) {
      dispatch(uploadFullFile(fileBlob, destNode, requestUUID, reply));
    } else {
      fileBlob.reply = reply;
      if (uploadStatus === CHAT_FILE_UPLOAD_STATUS.INIT) {
        dispatch(uploadFileInit(fileBlob, destNode, requestUUID));
      } else if (uploadStatus === CHAT_FILE_UPLOAD_STATUS.COMPLETE) {
        dispatch(
          uploadFileComplete(fileBlob, uploadBaseInfo, uploadCompleteInfo),
        );
      } else {
        dispatch(
          uploadFilePart(
            fileBlob,
            partNumber + 1,
            partNumber * CHAT_FILE_CHUNK_SIZE,
            uploadBaseInfo,
            uploadCompleteInfo,
          ),
        );
        dispatch(
          updateNewChatFilePropertys(
            {
              uploadStatus: CHAT_FILE_UPLOAD_STATUS.PENDING,
            },
            fileBlob.xmppMsgId,
          ),
        );
      }
    }
    dispatch(
      updateNewChatFilePropertys(
        {
          uploadError: false,
          uploadErrorType: undefined,
          uploadCanceled: false,
        },
        fileBlob.xmppMsgId,
      ),
    );
  };
}

export const clearNewChatAllFileMessage = () => {
  return (dispatch, getState) => {
    const {
      newChat: { meetingChat },
      chat: { uploadCancelTokens },
    } = getState();

    // clear all request
    Object.keys(uploadCancelTokens).forEach((key) => {
      const cancel = uploadCancelTokens[key];
      if (cancel) {
        cancel();
        removeUploadCancelToken(key);
      }
    });

    const toDeletedFileMsgs = [];
    meetingChat.forEach((item) => {
      if (item.isFile) {
        if (item.content?.file?.downloadCancelToken) {
          // clear download request
          item.content.file.downloadCancelToken();
        }
        toDeletedFileMsgs.push(item);
      }
    });

    if (toDeletedFileMsgs.length === 0) return;

    const newMeetingChat = produce(meetingChat, (draft) => {
      let fianalDraft = draft;
      toDeletedFileMsgs.forEach((item) => {
        const result = deleteOneMessageById(fianalDraft, item.msgId);
        if (result) {
          fianalDraft = result;
        }
      });
      return fianalDraft;
    });

    dispatch(updateNewMeetingChat({ newMeetingChat }));
  };
};

export const stopUploadNewChatFileRequest =
  (uploadFile) => (dispatch, getState) => {
    const {
      chat: { uploadCancelTokens },
    } = getState();
    const { uploadBaseInfo, uploadChunkInfo, uploadStatus, fileSize } =
      uploadFile;
    const { requestUUID } = uploadBaseInfo;
    let requestId;
    if (fileSize <= CHAT_FILE_CHUNK_SIZE) {
      requestId = `${requestUUID}-full`;
    } else {
      if (uploadStatus === CHAT_FILE_UPLOAD_STATUS.INIT) {
        requestId = `${requestUUID}-init`;
      } else if (uploadStatus === CHAT_FILE_UPLOAD_STATUS.PENDING) {
        const { partNumber } = uploadChunkInfo;
        requestId = `${requestUUID}-part-${partNumber + 1}`;
      } else {
        requestId = `${requestUUID}-complete`;
      }
    }
    const cancelFunc = uploadCancelTokens[requestId];
    if (cancelFunc) {
      cancelFunc();
      dispatch(removeUploadCancelToken(requestId));
    }
  };

export function setChatImgPreviewURL(file) {
  return (dispatch) => {
    dispatch(
      updateNewChatFilePropertys(
        {
          previewURL: '', // downloading status
        },
        file.xmppMsgId,
      ),
    );
    dispatch(downloadChatFile(file, true, file.fr !== CHAT_FROM.IM));
  };
}

export function setSelectedFilesThunk(newFiles, isGiphy = false) {
  return (dispatch, getState) => {
    const {
      newChat: { selectedFiles: currentFiles },
    } = getState();
    let files;
    if (isGiphy) {
      /**
       * @typedef {import('../../../global/ChatSDK/@types/type.ts').GiphyItem} GiphyItem
       * @type {GiphyItem} item
       */
      const item = newFiles;
      files = [
        {
          id: item.id,
          sizeStr: item.images.pcPicInfo.size,
          extension: 'gif',
          type: 'giphy',
          status: 0,
          imgUrl: item.images.pcPicInfo.objectUrl,
          file: {
            name: `${item.id}.gif`,
            size: item.images.pcPicInfo.size,
          },
          giphy: item,
        },
      ];
    } else {
      files = newFiles.map((file) => {
        const suffix = getFileSuffix(file.name);
        const isImage = ImageExtentions.includes(suffix);
        return {
          id: file.uid,
          file,
          sizeStr: file.size + '',
          extension: suffix,
          type: isImage ? 'image' : 'file',
          status: 0,
          imgUrl: isImage ? URL.createObjectURL(file) : '',
        };
      });
    }

    const allFiles = [...currentFiles, ...files];

    if (!meetingConfig.meetingOptions.enableMixMessage && allFiles.length > 1) {
      dispatch(setChatWarning([SEND_CHAT_WARNING.FILE_COUNT_FULL, 1]));
      return [allFiles[0]];
    }
    if (allFiles.length > MAX_FILES_COUNT) {
      return dispatch(setChatWarning([SEND_CHAT_WARNING.FILE_COUNT_FULL]));
    }

    dispatch(setSelectedFiles(allFiles));
  };
}
