export const UnmuteByHostType = {
  UNMUTE: Symbol('unmute'),
  UNMUTE_ALLOW_TALK: Symbol('unmuteAllowTalk'),
  SPOTLIGHT: Symbol('spotlight'),
};

export const DisallowTipType = Object.freeze({
  MICROPHONE: 'microphone',
  INTERPRETER_MICROPHONE: 'interpreter_microphone',
  INTERPRETER_MICROPHONE_ALLOWED: 'interpreter_microphone_allowed',
  SHARE: 'share',
});

export const claimStatusType = {
  SUCCESS: Symbol('success'),
  DEFAULT_ERROR: Symbol('defaultError'),
  NETWORK_ERROR: Symbol('networkError'),
  INIT: Symbol('init'),
};

export const forYourGiftTypes = {
  UPGRADE_30_MIN: Symbol('UPGRADE_30_MIN'),
  UNUPGRADE_30_MIN: Symbol('UNUPGRADE_30_MIN'),
  FIRST_30_MIN: Symbol('FIRST_30_MIN'),
  FIRST_END: Symbol('FIRST_END'),
  SECOND_END: Symbol('SECOND_END'),
  THIRD_30_MIN: Symbol('THIRD_30_MIN'),
  THIRD_30_MIN_FOR_COHOST: Symbol('THIRD_30_MIN_FOR_COHOST'),
  THIRD_END_FOR_HOST: Symbol('THIRD_END_FOR_HOST'),
  THIRD_END_FOR_OTHERS: Symbol('THIRD_END_FOR_OTHERS'),
};
