import { messageType } from '../enums';
import { MessageResExt, MessageResponse } from '../@types/type';
import { shape } from './reducer';

type savedList = Pick<shape, 'list'>;
export function search2(draft: savedList, msgId: string) {
  // return [-1];
  for (let index = 0; index < draft.list.length; index++) {
    const element = draft.list[index];
    if (element.id === msgId) return [index];
    if (element.comments?.length) {
      for (let j = 0; j < element.comments.length; j++) {
        const commEle = element.comments[j];
        if (commEle.id === msgId) return [index, j];
      }
    }
  }
}

export const getTargetChatItem = (
  searchRes: number[],
  list: MessageResExt[],
): MessageResExt | undefined => {
  if (searchRes.length === 1) {
    return list[searchRes[0]];
  } else if (searchRes.length === 2) {
    return list[searchRes[0]].comments![searchRes[1]] as MessageResExt;
  }
};

export function searchChatItem(draft: savedList, msgId: string) {
  const searchRes = search2(draft, msgId);

  if (searchRes) {
    const target = getTargetChatItem(searchRes, draft.list);
    return {
      target,
      searchRes,
    };
  }
  return {};
}

export function searchChatFileItemByFileID(draft: savedList, fileId: string) {
  let result: number[] = [];
  let mixedIndex = -1;
  for (let index = 0; index < draft.list.length; index++) {
    const element = draft.list[index];
    if (element.comments?.length) {
      for (let j = 0; j < element.comments.length; j++) {
        const commEle = element.comments[j];
        if (commEle.messageType === messageType.text) continue;
        if (commEle.messageType === messageType.mix && commEle.allFiles) {
          const fIndex = commEle.allFiles.findIndex((f) => f.fileId === fileId);
          if (fIndex !== -1) {
            result = [index, j];
            mixedIndex = fIndex;
            break;
          }
        } else if (commEle.file?.fileId === fileId) {
          result = [index, j];
          break;
        }
      }
    }
    if (element.messageType === messageType.text) continue;
    if (element.messageType === messageType.mix && element.allFiles) {
      const fIndex = element.allFiles.findIndex((f) => f.fileId === fileId);
      if (fIndex !== -1) {
        result = [index];
        mixedIndex = fIndex;
        break;
      }
    } else if (element.file?.fileId === fileId) {
      result = [index];
      break;
    }
  }
  const target = getTargetChatItem(result, draft.list);
  if (!target) return {};
  return {
    target,
    searchRes: result,
    mixedIndex,
  };
}

export function searchMessageByTime(
  arr: MessageResponse[],
  targetTime: number,
): number[] {
  // let left = 0;
  // let right = arr.length - 1;

  // while (left <= right) {
  //   const mid = Math.floor((left + right) / 2);

  //   if (arr[mid].time === targetTime) {
  //     return [mid, left, right];
  //   } else if (arr[mid].time < targetTime) {
  //     left = mid + 1;
  //   } else {
  //     right = mid - 1;
  //   }
  // }
  // return [-1, left, right];
  const foundIndex = arr.findIndex(
    (item) => item.time === targetTime || item.timeInBackend === targetTime,
  );
  return [foundIndex];
}
