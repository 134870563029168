import {
  setPinVideoList,
  clearPinVideoList,
  setShouldRebackPreviousView,
} from '../video-action';
import {
  VIDEO_LAYOUT_GALLERY_VIEW,
  VIDEO_LAYOUT_SPEAK_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW,
} from '../../enum';
import { setVideoLayoutThunk } from './video-layout-change-thunk';

export function updatePinList(message) {
  return (dispatch, getState) => {
    const {
      video: { pinVideoList },
      attendeesList: { attendeesList },
    } = getState();

    if (pinVideoList.length === 0) {
      return;
    }

    if (!_.isEmpty(message.body.update)) {
      const userList = message.body.update || [];
      userList.forEach((item) => {
        if (item.bHold && pinVideoList.includes(item.id)) {
          dispatch(removePinVideoListThunk(item.id));
        }
      });
    }

    if (!_.isEmpty(message.body.remove)) {
      const userList = message.body.remove || [];
      userList.forEach((item) => {
        if (pinVideoList.includes(item.id)) {
          dispatch(removePinVideoListThunk(item.id));
        }
      });
    }

    pinVideoList.forEach((id) => {
      if (!attendeesList.find((attendee) => attendee.userId === id)) {
        dispatch(removePinVideoListThunk(id));
      }
    });
  };
}

export function clearPinVideoListThunk(checkLayout) {
  return (dispatch, getState) => {
    const {
      video: {
        shouldRebackPreviousView,
        UI: { videoLayout },
      },
    } = getState();

    dispatch(clearPinVideoList());
    if (checkLayout && shouldRebackPreviousView) {
      setShouldRebackPreviousView('');
      if (videoLayout === VIDEO_LAYOUT_SPEAK_VIEW) {
        dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_GALLERY_VIEW));
      }

      if (videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW) {
        dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW));
      }
    }
  };
}

export function removePinVideoListThunk(userId) {
  return (dispatch, getState) => {
    const {
      video: {
        shouldRebackPreviousView,
        pinVideoList,
        UI: { videoLayout },
      },
    } = getState();

    dispatch(
      setPinVideoList({
        type: 'remove',
        userId,
      }),
    );

    if (shouldRebackPreviousView && pinVideoList.length === 1) {
      setShouldRebackPreviousView('');
      if (videoLayout === VIDEO_LAYOUT_SPEAK_VIEW) {
        dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_GALLERY_VIEW));
      }

      if (videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW) {
        dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW));
      }
    }
  };
}
