function matchFirst(regexp, ua) {
  const match = ua.match(regexp);
  return (match && match.length > 0 && match[1]) || '';
}

function matchSecond(regexp, ua) {
  const match = ua.match(regexp);
  return (match && match.length > 1 && match[2]) || '';
}
const commonVersionIdentifier = /version\/(\d+(\.?_?\d+)+)/i;

const browserList = [
  /* Opera < 13.0 */
  {
    test: [/opera/i],
    describe(ua) {
      return [
        'Opera(Legacy)',
        matchFirst(commonVersionIdentifier, ua) ||
          matchFirst(/(?:opera)[\s/](\d+(\.?_?\d+)+)/i, ua),
      ];
    },
  },
  /* Opera > 13.0 */
  {
    test: [/opr\/|opios/i],
    describe(ua) {
      return [
        'Opera',
        matchFirst(/(?:opr|opios)[\s/](\S+)/i, ua) ||
          matchFirst(commonVersionIdentifier, ua),
      ];
    },
  },
  {
    test: [/SamsungBrowser/i],
    describe(ua) {
      return [
        'Samsung Internet for Android',
        matchFirst(commonVersionIdentifier, ua) ||
          matchFirst(/(?:SamsungBrowser)[\s/](\d+(\.?_?\d+)+)/i, ua),
      ];
    },
  },
  {
    test: [/micromessenger/i],
    describe(ua) {
      return [
        'WeChat',
        matchFirst(/(?:micromessenger)[\s/](\d+(\.?_?\d+)+)/i, ua) ||
          matchFirst(commonVersionIdentifier, ua),
      ];
    },
  },
  {
    test: [/qqbrowser/i],
    describe(ua) {
      return [
        /qqbrowserlite/i.test(ua) ? 'QQ Browser Lite' : 'QQ Browser',
        matchFirst(/(?:qqbrowserlite|qqbrowser)[/](\d+(\.?_?\d+)+)/i, ua) ||
          matchFirst(commonVersionIdentifier, ua),
      ];
    },
  },
  {
    test: [/msie|trident/i],
    describe(ua) {
      return [
        'Internet Explorer',
        matchFirst(/(?:msie |rv:)(\d+(\.?_?\d+)+)/i, ua),
      ];
    },
  },
  {
    test: [/\sedg\//i],
    describe(ua) {
      return ['Microsoft Edge', matchFirst(/\sedg\/(\d+(\.?_?\d+)+)/i, ua)];
    },
  },
  {
    test: [/edg([ea]|ios)/i],
    describe(ua) {
      return [
        'Microsoft Edge(Legacy)',
        matchSecond(/edg([ea]|ios)\/(\d+(\.?_?\d+)+)/i, ua),
      ];
    },
  },
  {
    test: [/firefox|iceweasel|fxios/i],
    describe(ua) {
      return [
        'Firefox',
        matchFirst(/(?:firefox|iceweasel|fxios)[\s/](\d+(\.?_?\d+)+)/i, ua),
      ];
    },
  },
  {
    test: [/electron/i],
    describe(ua) {
      return ['Electron', matchFirst(/(?:electron)\/(\d+(\.?_?\d+)+)/i, ua)];
    },
  },
  {
    test: [/chromium/i],
    describe(ua) {
      return [
        'Chromium',
        matchFirst(/(?:chromium)[\s/](\d+(\.?_?\d+)+)/i, ua) ||
          matchFirst(commonVersionIdentifier, ua),
      ];
    },
  },
  {
    test: [/chrome|crios|crmo/i],
    describe(ua) {
      return [
        'Chrome',
        matchFirst(/(?:chrome|crios|crmo)\/(\d+(\.?_?\d+)+)/i, ua),
      ];
    },
  },
  {
    test: (ua) => {
      return !/like android/i.test(ua) && /android/i.test(ua);
    },
    describe(ua) {
      return ['Android Browser', matchFirst(commonVersionIdentifier, ua)];
    },
  },
  {
    test: [/safari|applewebkit/i],
    describe(ua) {
      return ['Safari', matchFirst(commonVersionIdentifier, ua)];
    },
  },
];

const OSList = [
  {
    test: [/windows /i],
    describe(ua) {
      return ['Windows', matchFirst(/Windows ((NT|XP)( \d\d?.\d)?)/i, ua)];
    },
  },
  /* Firefox on iPad */
  {
    test: [/Macintosh(.*?) FxiOS(.*?)\//],
    describe(ua) {
      return ['iOS', matchSecond(/(Version\/)(\d[\d.]+)/, ua)];
    },
  },
  {
    test: [/macintosh/i],
    describe(ua) {
      return [
        'macOS',
        matchFirst(/mac os x (\d+(\.?_?\d+)+)/i, ua).replace(/[_\s]/g, '.'),
      ];
    },
  },
  /**
   * iOS
   */
  {
    test: [/(ipod|iphone|ipad)/i],
    describe(ua) {
      return [
        'iOS',
        matchFirst(/os (\d+([_\s]\d+)*) like mac os x/i, ua).replace(
          /[_\s]/g,
          '.',
        ),
      ];
    },
  },
  /**
   * Android
   */
  {
    test: (ua) => {
      return !/like android/i.test(ua) && /android/i.test(ua);
    },
    describe(ua) {
      return ['Android', matchFirst(/android[\s/-](\d+(\.\d+)*)/i, ua)];
    },
  },
  {
    test: [/linux/i],
    describe() {
      return ['Linux'];
    },
  },
  {
    test: [/CrOS/],
    describe() {
      return ['Chrome OS'];
    },
  },
];

class OffscreenCanvasCapability {
  value;
  gpu;
  get isSupported() {
    if (this.value === undefined) {
      const isOffscreenCanvas = typeof window.OffscreenCanvas === 'function';
      if (isOffscreenCanvas) {
        const canvas = new OffscreenCanvas(1, 1);
        canvas.addEventListener('webglcontextlost', (event) => {
          event.preventDefault();
        });
        const gl = canvas.getContext('webgl');
        this.value = !!gl;
        if (gl) {
          const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
          if (debugInfo) {
            this.gpu = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
          }
        }
      } else {
        this.value = false;
      }
    }
    return this.value;
  }
}

export class PlatformDescriptor {
  _browser;
  _os;
  _browserVersion;
  _osVersion;
  _offscreenCanvasCapality;
  _clientHintsData;

  constructor() {
    this._offscreenCanvasCapality = new OffscreenCanvasCapability();
    if (
      'userAgentData' in navigator &&
      navigator.userAgentData?.getHighEntropyValues
    ) {
      navigator.userAgentData
        .getHighEntropyValues([
          'architecture',
          'bitness',
          'formFactor',
          'model',
          'platformVersion',
          'wow64',
        ])
        .then((values) => {
          this._clientHintsData = values;
          // update the correct OS version if possible
          if (
            this._os &&
            this._osVersion &&
            this._clientHintsData?.platform &&
            this._clientHintsData?.platformVersion
          ) {
            this._os = this._clientHintsData?.platform;
            this._osVersion = this._clientHintsData?.platformVersion;
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    }
  }

  _getBrowserVersion() {
    if (this._clientHintsData?.brands?.length) {
      const brand = this._clientHintsData.brands.filter(
        (b) => !/(Chromium|Not.*Brand)/i.test(b?.brand),
      )[0];
      if (brand) {
        this._browser = brand.brand;
      }
      const chromiumBrand = this._clientHintsData.brands.find(
        (b) => b?.brand?.toLowerCase?.() === 'chromium',
      );
      if (chromiumBrand) {
        this._browserVersion = chromiumBrand.version;
        if (!this._browser) {
          this._browser = chromiumBrand.brand;
        }
      } else {
        this._browserVersion = brand?.version;
      }
    } else {
      const ua = navigator.userAgent;
      const browserDescriptor = browserList.find((_browser) => {
        if (typeof _browser.test === 'function') {
          return _browser.test(ua);
        } else {
          return _browser.test.some((condition) => condition.test(ua));
        }
      });
      if (browserDescriptor) {
        const browserResult = browserDescriptor.describe(ua);
        this._browser = browserResult[0];
        this._browserVersion = browserResult[1];
        if (this.isIOSMobile) {
          this._browser = 'ios-safari';
        }
      } else {
        this._browser = 'unknown';
        this._browserVersion = '0';
      }
    }
  }

  _getOSVersion() {
    if (
      this._clientHintsData?.platform &&
      this._clientHintsData?.platformVersion
    ) {
      this._os = this._clientHintsData.platform;
      this._osVersion = this._clientHintsData?.platformVersion;
    } else {
      const ua = navigator.userAgent;
      const osDescriptor = OSList.find((_os) => {
        if (typeof _os.test === 'function') {
          return _os.test(ua);
        } else {
          return _os.test.some((condition) => condition.test(ua));
        }
      });
      if (osDescriptor) {
        const osResult = osDescriptor.describe(ua);
        this._os = osResult[0];
        this._osVersion = osResult[1];
      } else {
        this._os = 'unknown';
        this._osVersion = '0';
      }
    }
  }

  get browser() {
    if (!this._browser) {
      this._getBrowserVersion();
    }
    return this._browser;
  }

  get browserVersion() {
    if (!this._browserVersion) {
      this._getBrowserVersion();
    }
    return this._browserVersion;
  }

  get os() {
    if (!this._os) {
      this._getOSVersion();
    }
    return this._os;
  }

  get osVersion() {
    if (!this._osVersion) {
      this._getOSVersion();
    }
    return this._osVersion;
  }

  get isIE() {
    return this.browser === 'Internet Explorer';
  }

  get isLegacyEdge() {
    return this.browser === 'Microsoft Edge(Legacy)';
  }

  get isLegacyOpera() {
    return this.browser === 'Opera(Legacy)';
  }

  get isFirefox() {
    return this.browser === 'Firefox';
  }

  get isSafari() {
    return this.browser === 'Safari';
  }

  get isChrome() {
    if (this._clientHintsData?.brands?.length) {
      return this._clientHintsData?.brands.some(
        (b) => b.brand?.toLocaleLowerCase() === 'chromium',
      );
    }
    const { userAgent } = navigator;
    return (
      !this.isLegacyOpera &&
      !this.isLegacyEdge &&
      /chrome/i.test(userAgent) &&
      /webkit/i.test(userAgent)
    );
  }

  get isLegacyChrome() {
    return (
      this.isChrome &&
      typeof navigator.mediaDevices?.getDisplayMedia !== 'function'
    );
  }

  get isMacOS() {
    return this.os === 'macOS';
  }

  get isIPad() {
    return (
      /iPad/i.test(navigator.userAgent) ||
      (this.isMacOS && navigator?.maxTouchPoints > 2)
    );
  }

  get isIOSMobile() {
    return this.os === 'iOS' || this.isIPad;
  }

  get architecture() {
    return this._clientHintsData?.architecture ?? '';
  }

  get isArm() {
    return this.architecture
      ? this.architecture === 'arm'
      : /arm/i.test(navigator.userAgent);
  }

  get isChromeOS() {
    return this.os === 'Chrome OS';
  }

  get isAndroidBrowser() {
    return this.os?.toLowerCase() === 'android';
  }

  get isArmChromeOS() {
    return this.isArm && this.isChromeOS;
  }

  get isAndroidOrIOSBrowser() {
    return this.isAndroidBrowser || this.isIOSMobile;
  }

  get isSupportEssentialAudioAndVideoAbility() {
    return typeof WebAssembly === 'object' && typeof Worker === 'function';
  }

  get isSupportAudioWorklet() {
    return typeof AudioWorklet === 'function';
  }

  get isSupportImageCapture() {
    return 'ImageCapture' in window;
  }

  get isSupportMediaStreamTrackProcessor() {
    return 'MediaStreamTrackProcessor' in window;
  }

  get isSupportOffscreenCanvas() {
    return this._offscreenCanvasCapality.isSupported;
  }

  get gpu() {
    return this._offscreenCanvasCapality.isSupported
      ? this._offscreenCanvasCapality.gpu
      : '';
  }

  get bitness() {
    return this._clientHintsData?.bitness ?? '';
  }

  get model() {
    return this._clientHintsData?.model ?? '';
  }

  get wow64() {
    if (this.os.toLowerCase() === 'windows') {
      return this._clientHintsData?.wow64;
    }
    return undefined;
  }

  get isSupportSendScreenSharing() {
    return (
      'getDisplayMedia' in navigator.mediaDevices &&
      typeof navigator.mediaDevices.getDisplayMedia === 'function'
    );
  }
}

export const platformDescriptor = new PlatformDescriptor();
