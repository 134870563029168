import { getWebMediaBlockConfig } from '../service';
import { isSupportWebGLOffscreenCanvas, getDeviceType } from '../util';

export const logBrowserCapabilities = async () => {
  return {
    hardwareConcurrency: navigator.hardwareConcurrency,
    audioWorklet: typeof AudioWorklet === 'function',
    sharedArrayBuffer: typeof SharedArrayBuffer === 'function',
    webGLOffscreenCanvas: isSupportWebGLOffscreenCanvas,
    simd: await JsMediaSDK_Instance.util.isSDKSupportSIMD(),
    webcodecsVideoEncode:
      // eslint-disable-next-line @babel/new-cap
      await JsMediaSDK_Instance.util.IsSupportVideoEncodeHardwareAcceleration(),
    webcodecsVideoDecode:
      // eslint-disable-next-line @babel/new-cap
      await JsMediaSDK_Instance.util.IsSupportVideoDecodeHardwareAcceleration(),
    gpuInfo: JsMediaSDK_Instance.util?.getGpuInfo?.(),
    isSupportWebGPURender:
      await JsMediaSDK_Instance.util?.isSupportWebGPURender?.(
        getWebMediaBlockConfig(),
      ),
    deviceType: getDeviceType(),
  };
};
