/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import NotificationMessage from './notification-message';
import { promptA11yInfo } from '../../util';

const DEFAULT_ALIVE_TIME = 5000;

const queues = {};

const AliveToast = ({
  messageTip,
  messageComponent,
  aliveTime = DEFAULT_ALIVE_TIME,
  showClose = false,
  iconClass = '',
  beforeComponent = null,
  afterComponent = null,
  onAfterClose,
  btnComponent,
  name,
  className,
}) => {
  const [visible, setVisiable] = useState(true);
  const ref = useRef(null);
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const onClose = () => {
    removeToastNode(ref);
    setVisiable(false);
    if (onAfterClose) {
      onAfterClose();
    }
  };

  useEffect(() => {
    if (visible && name) {
      queues[name] = onClose;
    }
    return () => {
      if (name && queues[name]) {
        delete queues[name];
      }
    };
  }, [onClose, visible, name]);

  useEffect(() => {
    if (aliveTime > 0) {
      setTimeout(() => {
        onClose();
      }, aliveTime);
    }
    let content = '';
    if (typeof messageTip === 'string') {
      content = messageTip;
    } else if (typeof messageTip === 'object') {
      content = messageTip.props?.message || '';
    }
    promptA11yInfo(content);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const getBtnComponent = (onClose) => {
    if (btnComponent && typeof btnComponent === 'function') {
      return btnComponent(onClose);
    }
    return btnComponent;
  };
  const messageC =
    typeof messageComponent === 'function'
      ? messageComponent({ close: onClose })
      : null;
  return (
    <NotificationMessage
      wrapRef={ref}
      isOpen={visible}
      onClose={onClose}
      showClose={showClose}
      btnComponent={getBtnComponent(onClose)}
      className={className}
    >
      {iconClass && <span className={iconClass} />}
      {beforeComponent}
      {messageTip}
      {messageC}
      {afterComponent}
    </NotificationMessage>
  );
};

function removeToastNode(ref) {
  if (ref && ref.current) {
    const aliveToastContainer = document.getElementById('notificationManager');
    const parentNode = ref.current.parentNode;
    if (parentNode && parentNode.parentNode === aliveToastContainer) {
      aliveToastContainer.removeChild(parentNode);
    }
  }
}
const convertKey = (key) => {
  if (key === 'message') {
    return 'messageTip';
  }
  return key;
};
/**
 * params:
 * 1.message:string
 * 2.or {message:'',aliveTime: 0}:object
 */
function genarateToast(arg) {
  const props = {
    messageTip: '',
    aliveTime: DEFAULT_ALIVE_TIME,
    showClose: false,
    iconClass: '',
    onAfterClose: null,
    beforeComponent: null,
    afterComponent: null,
    notificationType: null,
    messageComponent: null,
    btnComponent: null,
    name: null,
  };
  if (typeof arg === 'string') {
    props.messageTip = arg;
  } else if (typeof arg === 'object') {
    Object.entries(arg).forEach(([key, v]) => {
      if (key && v !== undefined && v !== null) {
        props[convertKey(key)] = v;
      }
    });
  } else {
    throw new TypeError('argument type error of AliveToast');
  }

  const aliveToastContainer = document.getElementById(
    props.notificationType ?? 'notificationManager',
  );
  if (aliveToastContainer) {
    const div = document.createElement('div');
    if (!arg.notificationType) {
      div.setAttribute('class', 'notification-message-wrap');
    }
    const root = createRoot(div);
    aliveToastContainer.appendChild(div);
    return root.render(<AliveToast {...props} />);
  }

  return <></>;
}

function generateUniqueToast(arg) {
  if (typeof arg.name === 'undefined') {
    throw new Error('lack of argument name of AliveToast.uniqueToast');
  }
  if (arg.usePrevious && queues[arg.name]) {
    return;
  }
  // the queues array will not be clear when failover
  // so use this workaround to keep a single instance mode
  if (queues[arg.name]) {
    AliveToast.close(arg.name);
  }
  genarateToast(arg);
}

AliveToast.toast = genarateToast;
AliveToast.uniqueToast = generateUniqueToast;

AliveToast.close = (name) => {
  Object.keys(queues).some((toastName) => {
    if (toastName === name && queues[toastName]) {
      queues[toastName]();
      return true;
    }
    return false;
  });
};

export default AliveToast;
