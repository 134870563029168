import meetingConfig from 'meetingConfig';
import {
  LOCALSTORAGE_KEYS,
  SESSIONSTORAGE_KEYS,
} from '../../../global/constant';
import { storeType, easyStore } from '../../../global/easy-store';

import {
  updateReceivedReactions,
  updateExpiredReactions,
  setAllEmojiSetResourceStatus,
  setIsAllEmojiSetRequired,
  setAllEmojiSet,
  setAllEmojiMeta,
  setAllEmojiCategories,
  setFrequentlyUsedEmojis,
  setAllEmojiDownloadProgress,
  setEmojiPickerType,
  setReactionPickerVisible,
  setEmojiPickerVisible,
  addBullets,
  clearBullets,
  setIsWebinarReactionAllowed,
  setHasSentReactionInHiddenMode,
  setReactionsRestriction,
} from './reaction-action';
import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';

import {
  convertEmojiToUnicode,
  checkIsSelectedEmoji,
  convertUnicodeToEmoji,
  checkIsSkinToneValidForEmoji,
  getResourcePath,
  addSprite2xStyle,
} from '../utils';
import {
  REACTION_EXPIRE_TIME,
  ALL_EMOJI_SET_RESOURCE_STATUS,
  NONVERBAL_TYPE,
  REACTION_PICKER_TYPE,
  GUEST_USER_KEY,
  REACTION_SEND_OPTION,
  WEBINAR_REACTION_DISPLAY_MODE,
  categoryDiversity,
} from '../constant';
import {
  isReactionsEnableSelector,
  isNonverbalFeedbackEnableSelector,
  isRestrictWebinarReactionSelector,
} from './reaction-selector';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { globalVariable } from '../../../global/global-variable';
import axios from '../../../../node_modules/axios/index';
import { loadSpritesStyle } from '@zoom/zoom-react-ui';
import { fetchEmojiResource } from '@zoom/rtf-editor-lite';
import { DIALOG_OK } from '../../dialog/resource';
import Modal from '../../../global/components/widget/modal';
import {
  GO_TO_WEB_SEETING,
  REACTION_ENABLES_CONTENT,
  REACTION_ENABLES_TITLE,
} from '../resource';

const setReactionsTimeoutHelper = (reactionResults, dispatch, getState) =>
  setTimeout(() => {
    const state = getState();
    const {
      reaction: { receivedReactions: currentReceivedReactions },
    } = state;
    const expiredReactions = reactionResults.filter(
      (result) =>
        result.timeoutHandler ===
        (currentReceivedReactions[result.userId] &&
          currentReceivedReactions[result.userId].timeoutHandler),
    );
    if (expiredReactions.length) {
      dispatch(updateExpiredReactions(expiredReactions));
    }
  }, REACTION_EXPIRE_TIME);

export const onReceiveReactionResult = (payload) => (dispatch, getState) => {
  let shouldSetIsAllEmojiSetRequired = false;
  let receivedReactionResults = payload;
  if (!Array.isArray(receivedReactionResults)) {
    receivedReactionResults = [payload];
  }

  const state = getState();
  if (!isReactionsEnableSelector(state)) return;
  const {
    reaction: { isAllEmojiSetRequired: prevIsAllEmojiSetRequired },
  } = state;

  const reactionResults = receivedReactionResults.map((result) => {
    const isSelectedEmoji = checkIsSelectedEmoji(result?.strEmojiContent);
    if (!isSelectedEmoji && prevIsAllEmojiSetRequired === false) {
      shouldSetIsAllEmojiSetRequired = true;
    }
    return {
      userId: result?.userID,
      content: result?.strEmojiContent,
      isSelectedEmoji,
      unicode: convertEmojiToUnicode(result?.strEmojiContent),
    };
  });
  const timeoutHandler = setReactionsTimeoutHelper(
    reactionResults,
    dispatch,
    getState,
  );
  for (let i = 0; i < reactionResults.length; i++) {
    reactionResults[i].timeoutHandler = timeoutHandler;
  }

  dispatch(updateReceivedReactions(reactionResults));
  if (shouldSetIsAllEmojiSetRequired) {
    dispatch(setIsAllEmojiSetRequired(true));
  }
};

export const expireWaitingRoomUserReaction = (payload) => (dispatch) => {
  const waitingRoomToUpdateUsers = payload
    .filter((updateItem) => updateItem.bHold && updateItem.id)
    .map((userItem) => ({ userId: userItem.id }));
  dispatch(updateExpiredReactions(waitingRoomToUpdateUsers));
};

export const onReceiveWebinarReaction = (payload) => (dispatch, getState) => {
  const state = getState();
  const {
    reaction: { webinarReactionDisplayMode, isWebinarReactionAllowed },
  } = state;
  if (isRestrictWebinarReactionSelector(state)) return;
  if (webinarReactionDisplayMode === WEBINAR_REACTION_DISPLAY_MODE.HIDDEN) {
    return;
  }
  const { emojiReaction } = payload;

  if (!Array.isArray(emojiReaction) || !emojiReaction.length) {
    return;
  }
  if (!isWebinarReactionAllowed) {
    return;
  }
  const bullets = emojiReaction.reduce((prevSum, cur) => {
    const nextSum = { ...prevSum };
    const { key, value } = cur;

    // const parsedKey = removeWebinarReactionFlag(key);

    nextSum[key] = prevSum[key] ? prevSum[key] + value : value;

    return nextSum;
  }, {});

  dispatch(addBullets(bullets));
};

export const sendWebinarReactionRequest =
  (emojiMeta) => (dispatch, getState) => {
    const state = getState();
    const {
      settings: { currentUserSkinTone },
      reaction: { webinarReactionDisplayMode },
    } = state;
    if (!emojiMeta) {
      return;
    }
    let strEmojiCode = `${emojiMeta?.code_points?.output}`;
    if (checkIsSkinToneValidForEmoji(currentUserSkinTone, emojiMeta)) {
      strEmojiCode += `-${currentUserSkinTone.code}`;
    }

    const body = {
      strEmojiContent: convertUnicodeToEmoji(strEmojiCode),
      nSendOption: REACTION_SEND_OPTION.CAN_MERGE_AND_DROP,
    };
    const data = {
      evt: socketEventTypes.WS_CONF_SEND_WEBINAR_EMOJI_REACTION_REQ,
      body,
    };
    dispatch(sendSocketMessage(data));
    if (webinarReactionDisplayMode === WEBINAR_REACTION_DISPLAY_MODE.HIDDEN) {
      dispatch(setHasSentReactionInHiddenMode(true));
    }
  };

export const sendReactionRequest = (emojiMeta) => (dispatch, getState) => {
  const state = getState();
  const {
    meeting: {
      currentUser: { userId },
    },
    settings: { currentUserSkinTone },
  } = state;
  if (!emojiMeta) {
    return;
  }
  if (!isReactionsEnableSelector(state)) return null;
  let strEmojiCode = `${emojiMeta?.code_points?.output}`;
  if (checkIsSkinToneValidForEmoji(currentUserSkinTone, emojiMeta)) {
    strEmojiCode += `-${currentUserSkinTone.code}`;
  }
  const body = {
    uNodeID: userId,
    strEmojiContent: convertUnicodeToEmoji(strEmojiCode),
  };
  const data = {
    evt: socketEventTypes.WS_CONF_SEND_REACTION_REQ,
    body,
  };
  dispatch(sendSocketMessage(data));
};

export const switchNonVerbalFeedbackRequest =
  (nonVerbalFeedbackType) => (dispatch, getState) => {
    const state = getState();
    const {
      meeting: {
        currentUser: { feedback: prevFeedback },
      },
    } = state;
    if (!isNonverbalFeedbackEnableSelector(state)) return null;
    const body = {
      feedback:
        prevFeedback === nonVerbalFeedbackType
          ? NONVERBAL_TYPE.NONVERBAL_NONE
          : nonVerbalFeedbackType,
    };
    const data = {
      evt: socketEventTypes.WS_CONF_FEEDBACK_REQ,
      body,
    };
    dispatch(sendSocketMessage(data));
  };

export const removeNonVerbalFeedback = () => (dispatch) => {
  const body = {
    feedback: NONVERBAL_TYPE.NONVERBAL_NONE,
  };
  const data = {
    evt: socketEventTypes.WS_CONF_FEEDBACK_REQ,
    body,
  };
  dispatch(sendSocketMessage(data));
};

export const sendRaiseLowerHandRequest = () => (dispatch, getState) => {
  const state = getState();
  const {
    meeting: {
      currentUser: { bRaiseHand, userId },
    },
  } = state;
  const body = {
    bOn: !bRaiseHand,
    id: userId,
  };
  const data = {
    evt: socketEventTypes.WS_CONF_RAISE_LOWER_HAND_REQ,
    body,
  };
  dispatch(sendSocketMessage(data));
};

export const initAllEmojiResource = () => (dispatch) => {
  // const state = getState();
  // const {
  //   meeting: { baseUrl },
  // } = state;
  // if (!baseUrl || !resourceManager) {
  //   return;
  // }
  const resourcePath = getResourcePath();
  if (!resourcePath) return;
  dispatch(
    setAllEmojiSetResourceStatus(ALL_EMOJI_SET_RESOURCE_STATUS.INITIATING),
  );

  const getEmojiCategories = axios.get(
    `${resourcePath}/dataset/emoji-categories.json`,
  );
  // const getEmojiMeta = axios.get(
  //   `${resourcePath}/dataset/emoji-meta.json`,
  // );
  const getEmojiSet = axios.get(`${resourcePath}/dataset/emoji-set.json`);
  const getReactionsRestriction = axios.get(
    `${resourcePath}/dataset/reactions-restriction.json`,
  );
  Promise.all([
    getEmojiCategories,
    // getEmojiMeta,
    fetchEmojiResource(resourcePath),
    getEmojiSet,
    getReactionsRestriction,
  ])
    .then(
      ([
        allEmojiCategories,
        { emojiMetaSet: allEmojiMeta },
        allEmojiSet,
        reactionsRestriction,
      ]) => {
        dispatch(setAllEmojiSet(allEmojiSet.data));
        dispatch(setAllEmojiMeta(allEmojiMeta));
        dispatch(setAllEmojiCategories(allEmojiCategories.data));
        dispatch(setReactionsRestriction(reactionsRestriction.data));
        dispatch(
          setAllEmojiSetResourceStatus(ALL_EMOJI_SET_RESOURCE_STATUS.DONE),
        );
        loadSpritesStyle(getResourcePath(), 'anonymous');
        addSprite2xStyle([
          ...allEmojiCategories.data,
          { category: categoryDiversity },
        ]);
      },
    )
    .catch(() => {
      dispatch(
        setAllEmojiSetResourceStatus(ALL_EMOJI_SET_RESOURCE_STATUS.FAILED),
      );
    });
};

export const abortAllEmojiDownload = () => (dispatch, getState) => {
  const state = getState();
  const {
    reaction: { allEmojiSetResourceStatus },
  } = state;
  if (allEmojiSetResourceStatus !== ALL_EMOJI_SET_RESOURCE_STATUS.DOWNLOADING) {
    return;
  }
  if (globalVariable.downloadTask) {
    globalVariable.downloadTask.abort();
  }
  dispatch(setAllEmojiSetResourceStatus(ALL_EMOJI_SET_RESOURCE_STATUS.FAILED));
  dispatch(setAllEmojiDownloadProgress(100));
};

export const initFrequentlyUsedEmojis = () => (dispatch) => {
  const storedFrequentlyUsedEmojis = easyStore.easyGet(
    LOCALSTORAGE_KEYS.webClient_frequentlyUsedEmojis,
  );
  dispatch(setFrequentlyUsedEmojis(storedFrequentlyUsedEmojis ?? {}));
};

export const updateFrequentlyUsedEmojis = (unicode) => (dispatch, getState) => {
  if (!unicode || typeof unicode !== 'string') return;
  const state = getState();
  const {
    reaction: { frequentlyUsedEmojis: prevFrequentlyUsedEmojis },
  } = state;
  const storedUserKey = meetingConfig?.uid ?? GUEST_USER_KEY;

  let nextCurrentUserEmojis =
    prevFrequentlyUsedEmojis[storedUserKey]?.filter((x) => x !== unicode) ?? [];
  nextCurrentUserEmojis.unshift(unicode);
  nextCurrentUserEmojis = nextCurrentUserEmojis.slice(0, 16);
  const nextStoredEmojis = {
    ...prevFrequentlyUsedEmojis,
    [storedUserKey]: nextCurrentUserEmojis,
  };

  dispatch(setFrequentlyUsedEmojis(nextStoredEmojis));
  easyStore.easySet(
    LOCALSTORAGE_KEYS.webClient_frequentlyUsedEmojis,
    nextStoredEmojis,
    storeType.localStorage,
  );
};

export const retryDownloadAllEmojiResource = () => (dispatch) => {
  dispatch(setAllEmojiSetResourceStatus(ALL_EMOJI_SET_RESOURCE_STATUS.UNKNOWN));
};

export const closeReactionPicker = () => (dispatch) => {
  dispatch(setReactionPickerVisible(false));
  dispatch(setEmojiPickerType(REACTION_PICKER_TYPE.SIMPLE));
};

export const closeEmojiPicker = () => (dispatch) => {
  dispatch(setEmojiPickerVisible(false));
};

const defaultOkButtonProps = {
  type: 'primary',
  size: 'default',
  className: 'zm-btn-legacy',
};
const defaultCancelButtonProps = {
  type: 'default',
  size: 'default',
  className: 'zm-btn-legacy',
};

export const requestAllowWebinarReaction = (shouldAllow) => (dispatch) => {
  const body = {
    bOn: shouldAllow,
  };
  const data = {
    evt: socketEventTypes.WS_CONF_SET_ALLOW_WEBINAR_EMOJI_REACTION_REQ,
    body,
  };
  const reactionEnablesDialogShowed = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.reactionEnablesDialogDisplayChanged,
  );
  dispatch(sendSocketMessage(data));
  easyStore.easySet(
    SESSIONSTORAGE_KEYS.reactionEnablesDialogDisplayChanged,
    true,
    storeType.sessionStorage,
  );
  if (!reactionEnablesDialogShowed && shouldAllow) {
    Modal.confirm({
      className: 'zm-modal-legacy',
      okText: DIALOG_OK,
      okButtonProps: defaultOkButtonProps,
      okCancel: false,
      cancelButtonProps: defaultCancelButtonProps,
      cancelText: GO_TO_WEB_SEETING,
      contentLabel: REACTION_ENABLES_CONTENT,
      showClose: false,
      title: REACTION_ENABLES_TITLE,
      content: REACTION_ENABLES_CONTENT,
      onCancel: () => {
        window.open(`${meetingConfig.baseUrl}/profile/setting`, '_blank');
      },
    });
  }
};

export const allowWebinarReaction = (shouldAllow) => (dispatch) => {
  dispatch(setIsWebinarReactionAllowed(shouldAllow));
  if (!shouldAllow) {
    dispatch(clearBullets());
  }
};
