import React from 'react';
import { LoadingSVG } from '../svg/icons';

function Loading() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        animation: '1.5s linear 0s normal none infinite rotate360',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoadingSVG
        style={{
          width: '48px',
          height: '48px',
        }}
      />
    </div>
  );
}

export default Loading;
