import { CONTROL_MODE_XMPP_MSG_TYPE, JOIN_FLOW_ERROR_CODE } from '../../enum';
import { isMTRAndroid } from '../../../global';

export function xmppMsgHandler(evt, payload, store, adaptor) {
  switch (evt) {
    case CONTROL_MODE_XMPP_MSG_TYPE.PROMOTE:
    case CONTROL_MODE_XMPP_MSG_TYPE.DEPROMOTE: {
      if (!isMTRAndroid()) {
        adaptor.notifyControllerMeetingHoldOn();
      }
      break;
    }
    case CONTROL_MODE_XMPP_MSG_TYPE.EXPEL: {
      adaptor.notifyControllerMeetingEnd(
        JOIN_FLOW_ERROR_CODE.EXPEL_BY_HOST,
        true,
      );
      break;
    }
    default:
      break;
  }
}
