import { iText } from '../../global/util';

export default {
  VideoPreview: iText('Video Preview', 'apac.video_preview'),
  Scheduled: iText('Scheduled', 'apac.scheduled'),
  RecurringMeeting: iText(
    'This is a recurring meeting',
    'apac.recurring_meeting',
  ),
  RecurringWebinar: iText(
    'This is a recurring webinar',
    'apac.recurring_webianr',
  ),
  PractiseSessionTip: iText(
    'Please wait. The webinar will begin soon',
    'apac.practise_session_tip',
  ),
  HostSignIn: iText('Host Sign in', 'apac.host_sign_in'),
  MeetingID: iText('Meeting ID', 'apac.preview.meeting_id'),
  MeetingPasscode: iText('Meeting Passcode', 'apac.preview.meeting_passcode'),
  MeetingEmail: iText('Email', 'apac.preview.meeting_email'),
  YourName: iText('Your Name', 'apac.preview.your_name'),
  TeslaHint: iText(
    'Distracted driving can cause accidents resulting in serious injury or death. Please pay attention to your surroundings and traffic conditions, and comply with local laws. If you are hosting a meeting, consider designating a co-host. ',
    'apac.preview.tesla_hint',
  ),
  PrivacyHint: iText(
    'By clicking "Join", you agree to our <a>Terms of Services</a> <b>and</b> <c>Privacy Statement</c>',
    'apac.preview.privacy_hint',
  ),
  IncorrectPassword: iText(
    'Incorrect Password',
    'apac.preview.incorrect_password',
  ),
  PasswordTooLong: iText(
    'Your password is too long',
    'apac.preview.password_too_long',
  ),
  PasswordRequired: iText(
    'Your password cannot be empty',
    'apac.preview.passwrod_required',
  ),
  NameRequired: iText(
    'Your name cannot be empty',
    'apac.preview.name_required',
  ),
  NameTooLong: iText('Your name is too long', 'apac.preview.name_too_long'),
  EmailRequired: iText(
    'Your email cannot be empty',
    'apac.preview.email_required',
  ),
  EmailInvalid: iText(
    'Please enter a valid email address.',
    'apac.preview.email_invalid',
  ),
  RememberName: iText(
    'Remember my name for future meetings',
    'apac.preview.remember_name',
  ),
  HostMutedTip: iText(
    'Host has muted participants when meeting starts',
    'apac.preview.host_muted_tip',
  ),
  EnterMeetingInfo: iText(
    'Enter Meeting Info',
    'apac.preview.enter_meeting_info',
  ),
  agreementFn: (terms, privacy) =>
    iText(
      `By clicking "Join", you agree to our ${terms} and ${privacy}.`,
      'apac.preview.agreement',
      [terms, privacy],
    ),
  DisabledEditNameTip: iText(
    'To edit your name, please contact your account admin.',
    'apac.preview.disable_edit_name_tip',
  ),
  MutedNotifier: iText('You are muted', 'apac.preview.muted_notifier'),
  UnmutedNotifier: iText('You are unmuted', 'apac.preview.unmuted_notifier'),
  VideoStartedNotifier: iText(
    'Video now started',
    'apac.preview.video_started_notifier',
  ),
  VideoStoppedNotifier: iText(
    'Video now stopped',
    'apac.preview.video_stopped_notifier',
  ),
  UnableToJoinMeeting: iText(
    'Unable to join meeting',
    'apac.preview.unable_to_join_meeting',
  ),
  ReportToZOOM: iText('Report to ZOOM', 'apac.preview.report_to_zoom'),
  EmailNoticeTitle: iText(
    'Who can see your name and email address?',
    'apac.preview.email_notice_title',
  ),
  emailNoticeContentFn: (accountOwner) =>
    iText(
      `The name and email address you provide will be shared with the host and ${accountOwner}  and can be used and shared by them in accordance with their Terms and Privacy Policy.`,
      'apac.preview.email_notice_content',
      accountOwner,
    ),
  EnterAccountEmailTip: iText(
    'Please enter the email address associated with your Zoom account',
    'apac.wc_meeting_incorrect_email',
  ),
  ReCaptchaVerifyFailed: iText(
    'You failed the reCAPTCHA verification.',
    'apac.preview.reCaptcha_verify_failed',
  ),
  ReCaptchErrorTryAgain: iText(
    'Please try again.',
    'apac.preview.reCaptcha_error_try_again',
  ),
  AutomatedBotsBlocked: iText(
    'Automated bots aren’t allowed to join this meeting.',
    'apac.preview.automated_bots_blocked',
  ),
};
