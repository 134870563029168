import { promptA11yInfo, iText } from './util';
import { LOWER_HAND_TITLE, RAISE_HAND_TITLE } from './resource';

const tryA11ylocalRecord = (bLocalRecord) => {
  promptA11yInfo(`Local Recording is ${bLocalRecord ? 'started' : 'stopped'}`);
};

const tryA11yRaisehand = (displayName, bRaiseHand, isHost) => {
  if (isHost) {
    const hasBeenLoweredteHandxt = iText(
      `${displayName}'s hand has been lowered`,
      'apac.wc_hand_has_been_lowered',
      [displayName],
    );
    const hasBeenRaisedHandxt = iText(
      `${displayName}'s hand has been raised`,
      'apac.wc_hand_has_been_raised',
      [displayName],
    );
    promptA11yInfo(bRaiseHand ? hasBeenRaisedHandxt : hasBeenLoweredteHandxt);
  } else {
    promptA11yInfo(bRaiseHand ? RAISE_HAND_TITLE : LOWER_HAND_TITLE);
  }
};

const checkOnAttendees = (updateData, isHost, attendeesList) => {
  let attendee = null;
  for (let i = 0; i < updateData.length; i++) {
    if (
      /* eslint-disable-next-line no-prototype-builtins */
      updateData[i].hasOwnProperty('bRaiseHand') ||
      /* eslint-disable-next-line no-prototype-builtins */
      updateData[i].hasOwnProperty('bLocalRecordStatus')
    ) {
      attendee = _.find(
        attendeesList.attendeesList,
        (oldAttendee) => oldAttendee.userId === updateData[i].id,
      );
      /* eslint-disable-next-line no-prototype-builtins */
      if (attendee && updateData[i].hasOwnProperty('bRaiseHand')) {
        tryA11yRaisehand(
          attendee.displayName,
          updateData[i].bRaiseHand,
          isHost,
        );
      } else if (
        attendee &&
        /* eslint-disable-next-line no-prototype-builtins */
        updateData[i].hasOwnProperty('bLocalRecordStatus')
      ) {
        tryA11ylocalRecord(updateData[i].bLocalRecordStatus);
      }
    }
  }
  return null;
};

export { tryA11yRaisehand, tryA11ylocalRecord, checkOnAttendees };
