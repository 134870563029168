import _ from 'lodash';

export function createCollectorThunk(action, timeout, option) {
  let collection = [];
  const { type, collectType, ...rest } = option || {};
  let createTypeFun = _.debounce;
  if (type === 'throttle') {
    createTypeFun = _.throttle;
  }
  const debounceThunk = createTypeFun(
    (dispatch) => {
      let data = collection;
      if (collectType === 'last') {
        data = collection[collection.length - 1];
      }
      dispatch(action(data));
      collection = [];
    },
    timeout,
    rest,
  );
  return (v) => (dispatch) => {
    collection.push(v);
    debounceThunk(dispatch);
  };
}
