import { iText } from '../../global/util';

export const ENABLE_AUTO_TRANS_BUTTON = iText(
  'Enable Auto-Transcription',
  'apac.wc_lt_enable_transcription',
);
export const DISABLE_AUTO_TRANS_BUTTON = iText(
  'Disable Auto-Transcription',
  'apac.wc_lt_disable_transcription',
);
export const TRANS_TOOLS_TITLE = iText(
  'Live Transcript',
  'apac.wc_lt_live_transcript',
);

export const LIVE_TRANSCRIPTION_MENU_ITEM1 = iText(
  'Show Subtitle',
  'apac.wc_lt_menu_item1',
);
export const LIVE_TRANSCRIPTION_MENU_ITEM2 = iText(
  'View Full Transcript',
  'apac.wc_lt_menu_item2',
);
export const LIVE_TRANSCRIPTION_MENU_ITEM3 = iText(
  'Hide Subtitle',
  'apac.wc_lt_menu_item3',
);
export const LIVE_TRANSCRIPTION_MENU_ITEM4 = iText(
  'Close Full Transcript',
  'apac.wc_lt_menu_item4',
);

export const LIVE_TRANSCRIPTION_SECTION_DROPDOWN_CLOSE = iText(
  'Close',
  'apac.wc_lt_close',
);
export const LIVE_TRANSCRIPTION_SECTION_DROPDOWN_POP_OUT = iText(
  'Pop Out',
  'apac.wc_lt_popout',
);
export const LIVE_TRANSCRIPTION_SECTION_HEADER = iText(
  'Transcript',
  'apac.wc_lt_header',
);
export const LIVE_TRANSCRIPTION_ITEM_CAPTIONER = iText(
  '(Captioner)',
  'apac.wc_lt_item_captioner',
);

export const LIVE_TRANSCRIPTION_SEARCH_TEXT = iText(
  'Search',
  'apac.wc_lt_search_text',
);

export const LIVE_TRANSCRIPTION_POWERED_TEXT = iText(
  'Powered by Otter.ai',
  'apac.wc_lt_powered',
);

export const LIVE_TRANSCRIPTION_AVAILABLE_TITLE = iText(
  'Live Transcription is available',
  'apac.wc_lt_available',
);
export const LIVE_TRANSCRIPTION_TURN_ON_TITLE = iText(
  'You have turned on live transcription',
  'apac.you_have_turned_on',
);

export const LIVE_TRANSLATE_TURN_ON_TITLE = iText(
  'Live Translate is turned on',
  'apac.wc_ltl_truned_on',
);
export const CC_TURN_ON_TITLE = iText(
  'Closed Caption is turned on',
  'apac.wc_cc_truned_on',
);
export const LIVE_TRANSCRIPTION_TURN_OFF_TITLE = iText(
  'Live Transcription is turned off',
  'apac.wc_lt_truned_of',
);

export const LIVE_TRANSLATE_TURN_OFF_TITLE = iText(
  'Live Translate is turned off',
  'apac.wc_ltl_truned_of',
);

export const LIVE_TRANSCRIPTION_ASSIGN_TO_TYPE = iText(
  'Assign someone to type',
  'apac.wc_cc_assign_to_type',
);

export const LIVE_TRANSCRIPTION_ASSIGN_PARTICIPANT_TO_TYPE = iText(
  'Assign a participant to type',
  'apac.wc_cc_assign_participant_to_type',
);

export const LIVE_TRANSCRIPTION_TYPE_MYSELF = iText(
  'I will type',
  'apac.wc_cc_type_myself',
);

export const LIVE_TRANSCRIPTION_3RD_CC_SERVICE = iText(
  'Use a 3rd-party CC service',
  'apac.wc_3rd_cc_service2',
);

export const LIVE_TRANSCRIPTION_3RD_CC_COPY_API_TOKEN = iText(
  'Copy the API token',
  'apac.wc_3rd_cc_copy_api_token',
);

export const LIVE_TRANSCRIPTION_3RD_CC_URL_COPIED = iText(
  'API token copied to clipboard',
  'apac.wc_3rd_cc_url_copied2',
);

export const LIVE_TRANSCRIPTION_3RD_CC_URL_HELP_TIPS = iText(
  'Copy this token and paste it in a 3rd-party Closed Captioning tool',
  'apac.wc_3rd_cc_url_help_tips2',
);

export const LIVE_TRANSCRIPTION_MENU_REQ = iText(
  'Request Live Transcript',
  'apac.wc_lt_menu_req',
);

// #region request lt apac
export const LIVE_TRANSCRIPTION_REQUEST_DIALOG = iText(
  // ue in review
  'Request that the host enable Live Transcript for the meeting?',
  'apac.wc_lt_request_text',
);

export const LIVE_TRANSCRIPTION_ANONYMOUS_REQUEST = iText(
  'Ask anonymously',
  'apac.wc_lt_request_anonymous',
);

export const LIVE_TRANSCRIPTION_SECTION_DISABLED = iText(
  'Live Transcript disabled.',
  'apac.wc_lt_section_bottom_state',
);

export const hostAnsLtRequestTxt = (participantName) => {
  return iText(
    `${participantName}  requests that Live Transcription be enabled for the meeting`,
    'apac.wc_lt_request_ans',
    participantName,
  );
};

export const LIVE_TRANSCRIPTION_SILENCE_REQ = iText(
  "Decline and don't ask again",
  'apac.wc_lt_request_silence',
);

export const LIVE_TRANSCRIPTION_REQ_HOST_CTRL = iText(
  'Allow participants to request Live Transcript',
  'apac.wc_lt_request_host_ctrl',
);

export const LIVE_TRANSCRIPTION_DISABLE_TIP = iText(
  'The host has disabled Live Transcription for the meeting',
  'apac.wc_lt_request_disable_tip',
);
