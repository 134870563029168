import { CONTROL_MODE_ZOOM_UI_ACTION_TYPE } from '../../enum';
import { localReadyStatesObserver } from './local-ready-states-observer';

export const localEvtHandler = (evt, payload, adaptor) => {
  switch (evt) {
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.START_VIDEO:
      adaptor.notifyControllerVideoUnmuted();
      break;
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.STOP_VIDEO:
      adaptor.notifyControllerVideoMuted();
      break;
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.UNMUTE_AUDIO:
      adaptor.notifyControllerAudioUnmuted();
      break;
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.MUTE_AUDIO:
      adaptor.notifyControllerAudioMuted();
      break;
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.CONNECTING: {
      adaptor.notifyControllerStartJoining();
      break;
    }
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.FAILOVER: {
      localReadyStatesObserver.reset();
      adaptor.notifyControllerStartJoining();
      break;
    }
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.LEAVE_MEETING: {
      adaptor.notifyControllerMeetingHasEnded();
      break;
    }
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.STOP_PARTICIPANT_SHARING:
      adaptor.notifyControllerPresentationStopped();
      break;
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.PRESENTATION_AUDIO_MUTED:
      adaptor.notifyControllerPresentationAudioMuted();
      break;
    case CONTROL_MODE_ZOOM_UI_ACTION_TYPE.PRESENTATION_AUDIO_UNMUTED:
      adaptor.notifycontrollerPresentationAudioUnmuted();
      break;
    default:
      break;
  }
};
