import { isSupportMultiView } from '../../../../global/util';
import allThunks from '../../../../global/redux/all-thunks';

import { setIsSwapVideoShared } from '../video-action';
import { VIDEO_LAYOUT_STANDARD_VIEW, ATTENDEE_SEND_FLAG } from '../../enum';
import { attendeeViewFlagThunk } from './follow-host-base-message-thunk';
import { WHITEBOARD_STATUS } from '../../../whiteboard/enum';
import { isHost } from '../../../../global/service/user-types';

export function setVideoLayoutThunk(layout) {
  return allThunks.setVideoLayoutThunk(layout);
}

export function setIsSwapVideoSharedThunk(flag) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userRole },
      },
      video: {
        UI: { videoLayout },
      },
      whiteboard: { whiteboardStatus },
      sharing: { isReceiveSharing },
    } = getState();

    if (whiteboardStatus !== WHITEBOARD_STATUS.OFF && !isReceiveSharing) {
      return;
    }

    const isHostUser = isHost(userRole);

    dispatch(setIsSwapVideoShared(flag));

    if (!isSupportMultiView()) {
      return;
    }

    if (isHostUser) {
      if (flag) {
        dispatch(
          attendeeViewFlagThunk(ATTENDEE_SEND_FLAG.FOLLOW_STANDARD_SWAP),
        );
      } else if (videoLayout === VIDEO_LAYOUT_STANDARD_VIEW) {
        dispatch(attendeeViewFlagThunk(ATTENDEE_SEND_FLAG.FOLLOW_STANDARD));
      } else {
        dispatch(
          attendeeViewFlagThunk(ATTENDEE_SEND_FLAG.CANCEL_STANDARD_SWAP),
        );
      }
    }
  };
}
