export const isAnsweredQuestion = (questionEntity) =>
  (questionEntity.liveStatus || questionEntity.isAnswered) &&
  !questionEntity.isDismissed &&
  (questionEntity.answer.length === 0 ||
    questionEntity.answer.some((item) => !item.isOfflineAnswer));

export function findXMPPUser(xmppUserList, jid) {
  const { attendees, host, panelists } = xmppUserList;
  return host
    .concat(panelists)
    .concat(attendees)
    .find((v) => v.jid === jid);
}

export function generateCurrentDateString() {
  return `${Math.floor(+new Date() / 1000)}`;
}
