import { createActions } from 'redux-actions';

const SET_PROVIDE_FEEDBACK_BY_MYSELF = 'SET_PROVIDE_FEEDBACK_BY_MYSELF';
const SET_KICKED_BY_HOST = 'SET_KICKED_BY_HOST';
const SET_IS_LEAVE = 'SET_IS_LEAVE';
const SET_LEAVE_FROM_WAITING_ROOM = 'SET_LEAVE_FROM_WAITING_ROOM';
const SET_LEAVE_FOR_LOCK_MEETING = 'SET_LEAVE_FOR_LOCK_MEETING';
const SET_LEAVE_IN_DIALOG = 'SET_LEAVE_IN_DIALOG';
const SET_SURVEY_URL = 'SET_SURVEY_URL';

export const {
  setProvideFeedbackByMyself,
  setKickedByHost,
  setIsLeave,
  setLeaveFromWaitingRoom,
  setLeaveForLockMeeting,
  setLeaveInDialog,
  setSurveyUrl,
} = createActions({
  [SET_PROVIDE_FEEDBACK_BY_MYSELF]: (payload) => payload,
  [SET_KICKED_BY_HOST]: (payload) => payload,
  [SET_IS_LEAVE]: (payload) => payload,
  [SET_LEAVE_FROM_WAITING_ROOM]: (payload) => payload,
  [SET_LEAVE_FOR_LOCK_MEETING]: (payload) => payload,
  [SET_LEAVE_IN_DIALOG]: (payload) => payload,
  [SET_SURVEY_URL]: (payload) => payload,
});
