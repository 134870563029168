import { iText } from '../../global/util';

export const DeleteChatTitleTxt = iText(
  'Delete Message',
  'apac.wc_chat.delete-title',
);

export const DeleteChatContent = iText(
  "Are you sure you want to delete this message? This action cannot be undone. Other participants may still see your message on devices where the software hasn't been updated.",
  'apac.wc_chat.delete_content',
);

export const EditedText = iText('Edited', 'apac.wc_chat.edited');

// confirmed
export const PERSISTENT_TIP_TITLE = iText(
  'One place for all your meeting chats',
  'apac.chat.persistent_tip_title',
);

// updated 5.15.5
export const PERSISTENT_TIP_CONTENT = iText(
  'This meeting has continuous meeting chat enabled. You can find messages sent during the meeting and keep the conversation going afterwards in Team Chat.',
  'apac.chat.persistent_tip_content2',
  // 'Scheduled meetings now have a dedicated group chat where you can find messages sent during the meeting and keep the conversation going afterwards.',
  // 'apac.chat.persistent_tip_content',
);

export const StartChat = iText('Start Chatting', 'apac.chat.start_chat');
export const ViewInTeamChat = iText(
  'View in Team Chat',
  'apac.chat.view_in_team_chat',
);

export const UnSupportChat = iText(
  "This content can't be viewed here",
  'apac.chat.nonsupport_chat',
);

export const JoinAsGuest = (userName) =>
  iText(`${userName} joined as a guest`, 'apac.chat.join_as_guest', userName);

export const JoinTxt = iText(`join`, 'apac.chat.join');
export const LeaveTxt = iText(`left`, 'apac.chat.left');

export const MeetingStartAt = (time) =>
  iText(`Meeting started ${time}`, 'apac.chat.meeting_start', time);

// export const PMC_EveryOne = iText(
//   'Members & Guests',
//   'apac.chat.members_guests',
// );
export const PMC_EveryOne = iText(
  'Meeting Group Chat',
  'apac.chat.meeting_group',
);

// export const PMC_EveryOneAnyone = iText(
//   'Members & Guests, and anyone directly',
//   'apac.wc_chat.members_guests_directly',
// );
export const PMC_EveryOneAnyone = iText(
  'Meeting Group Chat & anyone directly',
  'apac.wc_chat.meeting_group_directly',
);

// updated 5.15.5
export const PMCSyncTip = iText(
  // 'Messages addressed to "Meeting Group Chat" will also appear in the dedicated group chat in Team Chat',
  // 'apac.wc_chat_sync_tip2',
  'Messages addressed to “Meeting Group Chat” will also appear in the meeting group chat in Team Chat',
  'apac.wc_chat_sync_tip3',
);

export const ChatDeleted = iText(
  'This message has been deleted',
  'apac.wc_chat_message_delete',
);

export const ChatNotSeen = iText(
  'Message sent before you joined the meeting',
  'apac.wc_chat_message_before_join',
);

export const ChatReply = iText('Reply...', 'apac.wc_chat_message_reply');

export const UnsupportedReplyTxt = iText(
  'This message was sent from a client that does not support threaded replies',
  'apac.wc_chat_unsupported_reply',
);
// Reacted to "xxx" with
// TODO https://zoomvideo.atlassian.net/wiki/spaces/IM/pages/2390332631/New+In-Meeting+UI+internal+beta+dev-plan#2.5-%E5%8A%9F%E8%83%BD%E5%BC%80%E5%8F%91%E8%A1%A5%E5%85%85%E8%AF%B4%E6%98%8E
export const ChatReplyToOldTxt = iText('Replying to', 'apac.wc_chat_replying');
export function getAddEmojiTo(text, emojiStr) {
  return iText(
    `Reacted to ${text} with ${emojiStr}`,
    'apac.wc_chat.add_emoji',
    [text, emojiStr],
  );
}

export function getRemoveEmoji(emojiStr, cutTxt) {
  return iText(
    `Removed a ${emojiStr} from ${cutTxt}`,
    'apac.chat.remove_emoji',
    [emojiStr, cutTxt],
  );
}
// Removed a 👍 reaction from

export const ReplyNotSupportedInWr = iText(
  'Waiting room messages do not support replies and reactions',
  'apac.wc_chat_unsupported_replies_in_wr',
);

export const YouDeleteMsgTxt = iText(
  'You deleted a message',
  'apac.wc_chat_delete_message',
);

export const receiverBeOnHoldTxt = (name) => {
  return iText(
    `Unable to send message because ${name} is no longer in this meeting.`,
    'apac.wc_chat_receiver_on_hold',
    [name],
  );
};

export const cannotInteractWarningTxt = iText(
  'You cannot interact with these messages from the current chat',
  'apac.wc_chat.interact_warn',
);

export const ChatPlaceHolderTxt = iText(
  'Type message here...',
  'apac.wc_chat.new_placeholder',
);

export const AllTxt = iText('all', 'apac.wc_chat.all');
export const LinkToTeamChatText = iText(
  'Link to Team Chat Message',
  'apac.wc_chat.link_to_team_chat',
);

export const fileCountFullText = (count) =>
  iText(
    `You can only send up ${count} files at a time`,
    'apac.wc_chat_file_full',
    [count],
  );

export const waitingRoomUnsupportedFiles = iText(
  'Waiting Room chat does not support files',
  'apac.wc_chat_unsupported_files',
);
