/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import clsx from 'classnames';
import meetingConfig from 'meetingConfig';
import { VIDEO_LOAD_ERROR, RELOAD_VIDEO_TEXT } from '../../global/resource';
import { DIALOG_EXIT } from '../../features/dialog/resource';
import previewI18n from '../../features/preview/resource';
import { LeaveSVG } from '../../svg/icons';
import ZoomButton from '../../global/components/widget/button/zoom-button';
import _ from 'lodash';
import PageFooter from '../../features/preview/component/PageFooter';
import { isWebinar } from '../../global/service/meeting-types';

const LogoAndDescContent = ({ logoUrl, title, desc }) => {
  const hasDesc = Boolean(desc);
  const hasTitle = Boolean(title);
  return (
    <>
      {!_.isEmpty(logoUrl) && (
        <img
          className={clsx('wr-logo', { 'has-desc': hasDesc })}
          alt="waiting room logo"
          src={logoUrl}
        />
      )}
      {hasTitle && <div className="wr-title">{title}</div>}
      <div className="wr-desc">{desc}</div>
    </>
  );
};

const LoadingStatus = {
  Loading: 'loading',
  Loaded: 'loaded',
  Error: 'error',
};

const VideoContent = ({ title, videoUrl }) => {
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.Loading);
  const isLoading = loadingStatus === LoadingStatus.Loading;
  const isLoadError = loadingStatus === LoadingStatus.Error;
  const videoRef = useRef(null);

  const onLoadedData = () => setLoadingStatus(LoadingStatus.Loaded);
  const onLoadError = () => setLoadingStatus(LoadingStatus.Error);
  const handleRetry = () => {
    videoRef.current?.load();
    setLoadingStatus(LoadingStatus.Loading);
  };

  const hasTitle = Boolean(title);
  return (
    <>
      {hasTitle && <div className="wr-default-title">{title}</div>}
      {isLoadError && (
        <div className="wr-loading-mask">
          {VIDEO_LOAD_ERROR}
          <ZoomButton
            className="wr-retry-btn"
            type="primary"
            tabIndex="0"
            onClick={handleRetry}
          >
            <span>{RELOAD_VIDEO_TEXT}</span>
          </ZoomButton>
        </div>
      )}
      {isLoading && (
        <div className="wr-loading-mask">
          <i className="wr-loading-icon" />
        </div>
      )}
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        controls
        className="wr-video"
        onLoadedData={onLoadedData}
        onError={onLoadError}
      >
        <source src={videoUrl} type="audio/mp4" />
      </video>
    </>
  );
};

const DefaultContent = ({ imageUrl, title }) => {
  const hasTitle = Boolean(title);
  const fullScreen = imageUrl?.endsWith('waiting_room_join_flow_default.png');
  return (
    <>
      {hasTitle && <div className="wr-default-title">{title}</div>}
      <img
        className={clsx('wr-default-bg', { 'wr-bg-full-screen': fullScreen })}
        alt="waiting room background "
        src={imageUrl}
      />
    </>
  );
};

const getDateStr = (time) => {
  const { lang } = meetingConfig;
  if (!time) {
    return '';
  }
  const scheduleDate = new Date(time);
  const today = new Date();
  const isSameDay = scheduleDate.toDateString() === today.toDateString();
  return new Intl.DateTimeFormat(lang, {
    dateStyle: isSameDay ? undefined : 'medium',
    timeStyle: 'short',
    hour12: true,
  }).format(scheduleDate);
};

const ScheduleTimeContent = () => {
  const scheduleTime = _.get(
    meetingConfig,
    'meetingInfo.scheduleTimeMilliseconds',
  );
  const isRecurring = _.get(meetingConfig, 'meetingInfo.isRecurring');
  const isPMI = _.get(meetingConfig, 'meetingInfo.isPmi');
  const hideStartTime = _.get(meetingConfig, 'meetingInfo.hideStartTime');
  let content = '';

  if (isRecurring) {
    content = isWebinar()
      ? previewI18n.RecurringWebinar
      : previewI18n.RecurringMeeting;
  } else if (!isPMI && Boolean(scheduleTime) && !hideStartTime) {
    const dateStr = getDateStr(scheduleTime);
    if (dateStr) {
      content = `${previewI18n.Scheduled}: ${dateStr}`;
    }
  }
  return <div className="wr-schedule-date">{content}</div>;
};

const WaitingRoom = (props) => {
  const { wrInfo, meetingTopic } = meetingConfig;
  const {
    waitingTip,
    onExit,
    isShowReport,
    onShowReport, // report abuse
    className,
    signInButton,
    setOpenReport, // send feedback
  } = props;
  const {
    title,
    fullLogoUrl: logoUrl,
    desc,
    videoUrl,
    defaultScreen,
  } = JSON.parse(wrInfo || '{}');

  const hasLogo = Boolean(logoUrl);
  const hasVideo = Boolean(videoUrl);
  const hasDesc = Boolean(desc);

  return (
    <div className={clsx('waiting-room-container', className)}>
      <div className="wr-information">
        <div className="wr-topic">{meetingTopic}</div>
        <ScheduleTimeContent />
        <div className="wr-tip">
          <span>{waitingTip}</span>
          <div className="wr-loading-icon-wrapper">
            <i className="wr-loading-icon" />
          </div>
        </div>
        <div className="btn-container">
          {signInButton}
          <button className="leave-btn" onClick={onExit}>
            <span>{DIALOG_EXIT}</span>
            <i>
              <LeaveSVG />
            </i>
          </button>
        </div>
      </div>
      <div
        className={clsx('wr-main-content', {
          'wr-customized': hasLogo || hasVideo || hasDesc,
          'has-logo': hasLogo,
          'has-video': hasVideo,
          'wr-content-default': !hasLogo && !hasVideo && !hasDesc,
        })}
      >
        {(hasLogo || hasDesc) && (
          <LogoAndDescContent logoUrl={logoUrl} title={title} desc={desc} />
        )}
        {hasVideo && <VideoContent title={title} videoUrl={videoUrl} />}
        {!hasLogo && !hasVideo && !hasDesc && (
          <DefaultContent imageUrl={defaultScreen} title={title} />
        )}
      </div>
      <PageFooter
        isShowReport={isShowReport}
        onShowReport={onShowReport}
        onOpenFeedback={setOpenReport}
      />
    </div>
  );
};

export default WaitingRoom;

WaitingRoom.defaultProps = {
  signInButton: null,
};

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/components/waiting-room/WaitingRoom.scss';
