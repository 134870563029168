/* eslint-disable no-undef */

import PropTypes from 'prop-types';
import React, { Suspense, memo } from 'react';
import classNames from 'classnames';
import LazySVGIconErrorBoundary from './lazy-svg-icon-error-boundary';
import { getBaseUrl } from '../../../../tasks/global/task-utils/common-utils';

const importSVG = (name) => {
  return import(
    // https://webpack.js.org/api/module-methods/#magic-comments
    /* webpackMode: "lazy-once" */
    /* webpackChunkName: "icons" */
    `../../../../svg/${name}.svg`
  );
};

const makeSVGIcon = (name) =>
  React.lazy(() => {
    // https://webpack.js.org/guides/public-path/#on-the-fly
    __webpack_public_path__ = `${getBaseUrl()}/`;
    return importSVG(name);
  });

// eslint-disable-next-line react/display-name
const LazySVGIcon = memo((props) => {
  const { name, svgProps, className = '' } = props;
  const defaultClassName = `lazy-icon-${name}`;
  if (!name) return null;

  const SVGIcon = makeSVGIcon(name);

  return (
    <LazySVGIconErrorBoundary>
      <Suspense fallback="">
        <SVGIcon
          className={classNames(
            'lazy-svg-icon__icon',
            defaultClassName,
            className,
          )}
          {...svgProps}
        />
      </Suspense>
    </LazySVGIconErrorBoundary>
  );
});

LazySVGIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  svgProps: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export { LazySVGIcon };

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/components/widget/icon/index.scss';
