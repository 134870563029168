import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { slotify } from '../hoc';

Modal.setAppElement('#root');

function GlobalPopUpBox({
  isOpen,
  lowerLeftWidgets,
  lowerRightWidgets,
  title,
  mainText,
  ariaDialogText,
  ariaCloseBtnText,
  showCloseBtn,
  onClose,
}) {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={ariaDialogText}
      portalClassName="global-pop-up-box"
      overlayClassName="global-pop-up-box__overlay"
      className={{
        base: 'global-pop-up-box__main-area',
        afterOpen: '',
        beforeClose: '',
      }}
      shouldCloseOnEsc
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      shouldFocusAfterRender
    >
      <div className="global-pop-up-box__header clearfix">
        {showCloseBtn && (
          <button
            type="button"
            className="global-pop-up-box__close"
            onClick={onClose}
            aria-label={ariaCloseBtnText}
          >
            &times;
          </button>
        )}
        <h3 className="global-pop-up-box__title">{title}</h3>
      </div>
      <div className="global-pop-up-box__body">{mainText}</div>
      <div className="global-pop-up-box__footer clearfix">
        <div className="global-pop-up-box__left-widget-block">
          {lowerLeftWidgets &&
            lowerLeftWidgets.map((widget) => (
              <div key={widget.key} className="global-pop-up-box__widget-cell">
                {widget}
              </div>
            ))}
        </div>
        <div className="global-pop-up-box__right-widget-block">
          {lowerRightWidgets &&
            lowerRightWidgets.map((widget) => (
              <div key={widget.key} className="global-pop-up-box__widget-cell">
                {widget}
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
}

GlobalPopUpBox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  lowerLeftWidgets: PropTypes.arrayOf(PropTypes.element),
  lowerRightWidgets: PropTypes.arrayOf(PropTypes.element),
  title: PropTypes.string,
  mainText: PropTypes.string,
  ariaDialogText: PropTypes.string.isRequired,
  ariaCloseBtnText: PropTypes.string.isRequired,
  showCloseBtn: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

GlobalPopUpBox.defaultProps = {
  showCloseBtn: true,
};

/* eslint-disable-next-line no-func-assign */
GlobalPopUpBox = slotify(GlobalPopUpBox);
export { GlobalPopUpBox };

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/components/widget/global-pop-up-box.scss';
