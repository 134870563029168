export function createFeedbackDataToPWA(feedbackAndSurvey, mode) {
  const {
    feedback,
    feedbackCustMessage,
    meetingNumber,
    postAttendeeUrl,
    meetingId,
    nodeId,
    meetingDetails,
    deviceType,
  } = feedbackAndSurvey;
  if (mode === 0) {
    // url redirect way
    let urlParams = `meetingNumber=${meetingNumber}&meetingId=${encodeURIComponent(
      meetingId,
    )}&feedback=${feedback}&nodeId=${nodeId}&meetingDetails=${meetingDetails}&deviceType=${encodeURIComponent(
      deviceType,
    )}`;
    if (postAttendeeUrl) {
      urlParams = `${urlParams}&paurl=${encodeURIComponent(postAttendeeUrl)}`;
    }
    return urlParams;
  } else {
    //PWA portal way
    return {
      feedback,
      feedbackCustMessage,
      meetingNumber,
      postAttendeeUrl,
      meetingId,
      nodeId,
      meetingDetails,
      deviceType,
    };
  }
}
