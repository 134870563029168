import { createSelector } from 'reselect';
import { LIVE_STREAM_STATUS } from '../constant';
import { isMeInRoomSelector } from '../../breakout-room/redux/bo-room-selector';
import { isExternalControlledMode } from '../../../global/util';
import { isSimulive } from '../../../global/service';
import {
  attendeesListSelector,
  currentRoleSelector,
} from '../../../global/redux/selector';

const liveStreamStatusSelector = ({ liveStream: { status } }) => status;
const isEnabledLiveStreamSelector = ({ liveStream: { isEnabledLiveStream } }) =>
  isEnabledLiveStream;

const roleCanOperateLiveStreamSelector = createSelector(
  [currentRoleSelector],
  (currentRole) => {
    const { isHost } = currentRole;
    return isHost;
  },
);

export const isLiveStreamFeatureAvailableSelector = createSelector(
  [isEnabledLiveStreamSelector, isMeInRoomSelector],
  (isEnabledLiveStream, isMeInRoom) => {
    return isEnabledLiveStream && !isMeInRoom && !isSimulive();
  },
);

export const isLiveStreamPreparingSelector = createSelector(
  [liveStreamStatusSelector, roleCanOperateLiveStreamSelector],
  (status, roleCanOperate) => {
    return status === LIVE_STREAM_STATUS.PREPARING && roleCanOperate;
  },
);

export const canStartLiveStreamSelector = createSelector(
  [
    liveStreamStatusSelector,
    roleCanOperateLiveStreamSelector,
    isLiveStreamFeatureAvailableSelector,
  ],
  (status, roleCanOperate, isLiveStreamFeatureAvailable) => {
    return (
      isLiveStreamFeatureAvailable &&
      roleCanOperate &&
      status === LIVE_STREAM_STATUS.OFF &&
      !isExternalControlledMode()
    );
  },
);

export const canStopLiveStreamSelector = createSelector(
  [
    liveStreamStatusSelector,
    roleCanOperateLiveStreamSelector,
    isLiveStreamFeatureAvailableSelector,
  ],
  (status, roleCanOperate, isLiveStreamFeatureAvailable) => {
    return (
      isLiveStreamFeatureAvailable &&
      roleCanOperate &&
      status !== LIVE_STREAM_STATUS.OFF
    );
  },
);

export const localLiveStreamMapSelector = createSelector(
  [attendeesListSelector],
  (attendeesList) => {
    return attendeesList
      .filter((attendee) => attendee?.llsInfo?.living)
      .map((attendee) => {
        return {
          ...attendee.llsInfo,
          userID: attendee.userId,
          displayName: attendee.displayName,
        };
      });
  },
);

export const isLocalLiveStreamOnSelector = createSelector(
  [localLiveStreamMapSelector],
  (localLiveStreamMap) => {
    return localLiveStreamMap.length > 0;
  },
);
