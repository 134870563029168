import { uniq } from 'lodash';
import meetingConfig from 'meetingConfig';
import { LANGUAGE_STR_MAP, NEW_LT_LANGUAGE_CODE_MAP } from './constant';
import { findMeetingConfigOption } from '../../global/service/find-meeing-config-option';
import { isWebinar } from '../../global/service/meeting-types';

export const isManualCCWebSettingEnabled = () =>
  findMeetingConfigOption('isEnableManualCaptions') ||
  (findMeetingConfigOption('isEnableManualCaptions') === undefined &&
    findMeetingConfigOption('isEnableClosedCaption'));

export const onlyWebSettingExistManualCC = () =>
  isManualCCWebSettingEnabled() &&
  !findMeetingConfigOption('isEnableLiveTranscription');

export const isAssignCCWebSettingEnabled = () =>
  findMeetingConfigOption('isEnableAllowHostAssignTypeCC') ||
  (findMeetingConfigOption('isEnableAllowHostAssignTypeCC') === undefined &&
    findMeetingConfigOption('isEnableClosedCaption'));

export const codesToDropdownOptions = (codes) => {
  return codes.map((v) => ({ label: LANGUAGE_STR_MAP[v], value: v }));
};
export const getTranscriptionLanguagesCode = () => {
  const transcriptionLang = meetingConfig?.liveTranscription?.transcriptionLang;
  if (transcriptionLang) {
    return transcriptionLang
      .split(';')
      ?.map((lang) => lang && NEW_LT_LANGUAGE_CODE_MAP[lang])
      .filter((v) => v !== '' && typeof v !== 'undefined')
      .sort((a, b) =>
        LANGUAGE_STR_MAP[a].localeCompare(LANGUAGE_STR_MAP[b], undefined, {
          numeric: true,
        }),
      );
  }
  return [];
};

export const getTranslateToLanguagesCode = () => {
  const translatedSetting = meetingConfig?.liveTranscription?.translatedSetting;
  let result = [];
  for (const speakingLanguage in translatedSetting) {
    if (Object.hasOwnProperty.call(translatedSetting, speakingLanguage)) {
      const translateToLang = translatedSetting[speakingLanguage];
      if (translateToLang) {
        const array = translateToLang
          .split(';')
          ?.map((lang) => lang && NEW_LT_LANGUAGE_CODE_MAP[lang])
          .filter((v) => v !== '' && typeof v !== 'undefined');
        result = result.concat(array);
      }
    }
  }

  return uniq(result).sort((a, b) =>
    LANGUAGE_STR_MAP[a].localeCompare(LANGUAGE_STR_MAP[b], undefined, {
      numeric: true,
    }),
  );
};
export const isHostLockSpeakLanguage = () =>
  meetingConfig.liveTranscription?.isHostLockSpeakingLanguage;
const isWebinarEnableTranslation = () =>
  meetingConfig.meetingOptions?.enableWebinarTranslation;
export const isEnableTranslation = () =>
  meetingConfig.liveTranscription?.isEnableTranslatedCaptions &&
  (!isWebinar() || isWebinarEnableTranslation());
export const isEnableFullTranscription = () =>
  meetingConfig.meetingOptions?.isEnableViewFullTranscript;

export const getTranscriptionDefaultCode = () => {
  const languages = getTranscriptionLanguagesCode();
  const englishCode = NEW_LT_LANGUAGE_CODE_MAP['en'];

  if (languages.includes(englishCode)) {
    return englishCode;
  }

  return languages.length > 0 ? languages[0] : null;
};
export const getTranslateDefaultCode = () => {
  const languages = getTranslateToLanguagesCode();
  const englishCode = NEW_LT_LANGUAGE_CODE_MAP['en'];

  if (languages.includes(englishCode)) {
    return englishCode;
  }

  return languages.length > 0 ? languages[0] : null;
};
