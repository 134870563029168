import { useState, useEffect } from 'react';
import {
  reportProductOptions,
  reportProblemOptions,
  CANNOT_JOIN_TITLE,
} from '../resource';
import { isWebinar } from '../../../global/service/meeting-types';

export const useReportProblem = (connectError = false, propsOverwrite = {}) => {
  const [product, setProduct] = useState(() => {
    if (isWebinar()) {
      return reportProductOptions.find((item) => item.value === 'Webinar');
    } else {
      return reportProductOptions.find((item) => item.value === 'Meeting');
    }
  });
  const now = new Date();
  const [problem, setProblem] = useState(() => {
    if (connectError) {
      return reportProblemOptions.find((item) => item.value === 'Join Meeting');
    } else return null;
  });
  const [day, setDay] = useState(now);
  const [time, setTime] = useState([now.getHours(), now.getMinutes()]);
  const [description, setDescription] = useState(() => {
    if (connectError) {
      return CANNOT_JOIN_TITLE;
    } else return '';
  });
  const [logCheckbox, setLogCheckbox] = useState(true);
  const [ticketCheckbox, setTicketCheckbox] = useState(false);
  const [ticket, setTicket] = useState('');

  const onChange = (changeVal, changeType) => {
    switch (changeType) {
      case 'Product':
        setProduct(changeVal);
        break;
      case 'Problem':
        setProblem(changeVal);
        break;
      case 'Description':
        setDescription(changeVal);
        break;
      case 'LogCheckbox':
        setLogCheckbox(changeVal);
        break;
      case 'TicketCheckbox':
        setTicketCheckbox(changeVal);
        break;
      case 'Ticket':
        setTicket(changeVal);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if ('description' in propsOverwrite) {
      setDescription(propsOverwrite.description);
    }
    if ('problem' in propsOverwrite) {
      setProblem(propsOverwrite.problem);
    }
  }, [propsOverwrite]);

  return {
    product,
    problem,
    day,
    setDay,
    time,
    setTime,
    description,
    logCheckbox,
    ticketCheckbox,
    ticket,
    onChange,
  };
};
