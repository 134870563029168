import meetingConfig from 'meetingConfig';
import _ from 'lodash';
import { getBaseUrl } from '../../tasks/global/task-utils/common-utils';

export const isTestMeeting = () => {
  return _.get(meetingConfig, 'meetingInfo.isTest');
};

export const fetchAudioDataFactory = () => {
  const audioUrl = `${getBaseUrl()}/audio/ring.mp3`;
  let arrayBuffer = null;
  return () => {
    if (arrayBuffer) {
      return Promise.resolve(arrayBuffer);
    } else {
      return fetch(audioUrl)
        .then((res) => res.arrayBuffer())
        .then((data) => (arrayBuffer = data));
    }
  };
};

export const isSupportUploadTestResult = () => {
  const dtResultUrl = _.get(meetingConfig, 'meetingInfo.dtResultUrl');
  return !_.isEmpty(dtResultUrl);
};

export const getHelpUrl = () => {
  return _.get(meetingConfig, 'meetingInfo.dtHelpUrl');
};
