export const enum operaType {
  new = 'new',
  edit = 'edit',
  revoke = 'revoke',
  forbidden = 'forbidden',
}
// tt
export const enum thirdPartyFileTypeEnum {
  None = '0',
  Word = '1',
  Excel = '2',
  PPT = '3',
  OneNote = '4',
  BoxNode = '5',
  BoxNodeFromTemplate = '6',
  GoogleDocs = '7',
  GoogleSlides = '8',
  GoogleSheet = '9',
}

// original value is 101 for share file, it increased by 10
export const enum thirdStorageMessageType {
  DropBox = 111,
  MicrosoftOneDrive = 112,
  GoogleDrive = 113,
  Box = 114,
  MicrosoftSharePoint = 115,
}
// t
export const enum integrationTypeEnum {
  None = 0,
  DropBox = 1,
  MicrosoftOneDrive = 2,
  GoogleDrive = 3,
  Box = 4,
  MicrosoftSharePoint = 5,
}
export enum CloudStorageEnum {
  MicrosoftOneDrive = 'onedrive',
  GoogleDrive = 'gdrive',
  Dropbox = 'dropbox',
  MicrosoftSharePoint = 'sharepoint',
  Box = 'box',
  Unknown = 'unknown',
}
export const cloudTypeMap: Record<
  Exclude<CloudStorageEnum, CloudStorageEnum.Unknown>,
  integrationTypeEnum
> = {
  [CloudStorageEnum.Dropbox]: integrationTypeEnum.DropBox,
  [CloudStorageEnum.GoogleDrive]: integrationTypeEnum.GoogleDrive,
  [CloudStorageEnum.MicrosoftOneDrive]: integrationTypeEnum.MicrosoftOneDrive,
  [CloudStorageEnum.Box]: integrationTypeEnum.Box,
  [CloudStorageEnum.MicrosoftSharePoint]:
    integrationTypeEnum.MicrosoftSharePoint,
};

export const OriginCloudType: Record<integrationTypeEnum, CloudStorageEnum> = {
  [integrationTypeEnum.DropBox]: CloudStorageEnum.Dropbox,
  [integrationTypeEnum.GoogleDrive]: CloudStorageEnum.GoogleDrive,
  [integrationTypeEnum.MicrosoftOneDrive]: CloudStorageEnum.MicrosoftOneDrive,
  [integrationTypeEnum.Box]: CloudStorageEnum.Box,
  [integrationTypeEnum.MicrosoftSharePoint]:
    CloudStorageEnum.MicrosoftSharePoint,
  [integrationTypeEnum.None]: CloudStorageEnum.Unknown,
};

export enum mentionType {
  user = 1,
  all = 2,
  channel = 3,
}

export enum StyleItem {
  'bold' = 1,
  'italic' = 2,
  'boldAndItalic' = 3,
  'strikethrough' = 4,
  'bulletedList' = 8,
  'numberedList' = 16,
  'underline' = 32,
  'fontSize' = 64,
  'fontColor' = 128,
  'backgroundColor' = 256,
  'indent' = 512,
  'paragraph' = 1024,
  'quote' = 2048,
  'insertLink' = 4096,
}

export enum FileStatus {
  unsent,
  sending,
  sended,
  sendError,
  notDownload,
  downloading,
  downloaded,
  downloadError,
  downloadErrorNotReady,
  paused,
}

export enum messageType {
  file = 'file',
  text = 'text',
  image = 'image',
  giphy = 'giphy',
  mixGiphy = 'mixGiphy',
  info = 'info',
  composing = 'composing',
  inactive = 'inactive',
  gone = 'gone',
  active = 'active',
  mix = 'mix',
  call = 'call',
  unknown = 'unknown',
  pmc = 'pmc',
  screenshot = 'screenshot',
  reminder = 'reminder',
  error = 'error',
  e2e = 'e2e',
  codeSnippet = 'codeSnippet',
  bot = 'bot',
  dropbox = 'dropbox',
  microsoftOneDrive = 'microsoftOneDrive',
  googleDrive = 'googleDrive',
  box = 'box',
  microsoftSharePoint = 'microsoftSharePoint',
  // forbidden = 'forbidden',
}

export enum EditObjType {
  image = 'imageEdit',
  file = 'fileEdit',
}

export enum fileChannelType {
  None = 0,
  imCommonFile = 1,
  imImageFile = 2,
  imVoice = 3,
  imMeeting = 4,
  imSticker = 5,
  imE2E = 6,
  imCode = 7,
  imIntegration = 11,
  imMail = 12,
  imPBX = 17,
  imWhiteboard = 20,
  imVideo = 26,
  imMeetChatFile = 27,
  imScreenShot = 32,
  imSharePoint = 64,
  imBox = 66,
  imWhiteboardPreview = 68,
  imHuddleView = 81,
  imBot = 301,
}

export enum MentionMeAction {
  add = 'add',
  remove = 'remove',
  update = 'update',
}
export enum emojiVoteAction {
  add = 'add',
  remove = 'remove',
}

export enum MixMsgFileType {
  JPG = 'jpg',
  Audio = 'audio',
  Video = 'video',
  PNG = 'png',
  GIF = 'gif',
  OtherFile = 'otherfile',
  Giphy = 'giphy',
  CodeSnippet = 'codesnippet',
  FileIntegration = 'fileintegration',
  Screenshot = 'screenshot',
  WhiteboardPreview = 'whiteboardpreview',
  Email = 'email',
}

export enum MixMsgFileTypeCode {
  JPG = 1048576,
  Audio = 2097152,
  Video = 4194304,
  PNG = 8388608,
  GIF = 16777216,
  OtherFile = 33554432,
  Giphy = 67108864,
  CodeSnippet = 134217728,
  FileIntegration = 268435456,
  Screenshot = 536870912,
  WhiteboardPreview = 1073741824,
  Email = 2147483648,
}

export const MixMsgFileTypeList = [
  [MixMsgFileType.JPG, MixMsgFileTypeCode.JPG],
  [MixMsgFileType.Audio, MixMsgFileTypeCode.Audio],
  [MixMsgFileType.Video, MixMsgFileTypeCode.Video],
  [MixMsgFileType.PNG, MixMsgFileTypeCode.PNG],
  [MixMsgFileType.GIF, MixMsgFileTypeCode.GIF],
  [MixMsgFileType.OtherFile, MixMsgFileTypeCode.OtherFile],
  [MixMsgFileType.Giphy, MixMsgFileTypeCode.Giphy],
  [MixMsgFileType.CodeSnippet, MixMsgFileTypeCode.CodeSnippet],
  [MixMsgFileType.FileIntegration, MixMsgFileTypeCode.FileIntegration],
  [MixMsgFileType.Screenshot, MixMsgFileTypeCode.Screenshot],
  [MixMsgFileType.WhiteboardPreview, MixMsgFileTypeCode.WhiteboardPreview],
  [MixMsgFileType.Email, MixMsgFileTypeCode.Email],
];

export enum UserRole {
  OWNER = 10,
  ADMIN = 22,
  ANNOUNCER = 25,
  MODERATOR = 27,
  MEMBER = 30,
  OUTCAST = 40,
  ADDON = 60,
  NOTAMEMBER = -1,
}

export enum PresenceType {
  dnd = 'dnd',
  presenting = 'presenting',
  zoommeeting = 'zoommeeting',
  pbx = 'pbx',
  calendar = 'calendar',
  available = 'available',
  away = 'away',
  offline = 'offline',
  mobile = 'mobile',
  busy = 'busy',
}

export const thirdPartyTypeNumToStrMap = {
  [integrationTypeEnum.DropBox]: messageType.dropbox,
  [integrationTypeEnum.MicrosoftOneDrive]: messageType.microsoftOneDrive,
  [integrationTypeEnum.GoogleDrive]: messageType.googleDrive,
  [integrationTypeEnum.Box]: messageType.box,
  [integrationTypeEnum.MicrosoftSharePoint]: messageType.microsoftSharePoint,
};

export const enum GiphyRatingLvs {
  G,
  PG,
  'PG-13',
  R,
}

export enum SystemActionType {
  subject = 'subject',
  create = 'create',
  edit = 'edit',
  quit = 'quit',
  invite = 'invite',
  kick = 'kick',
  remove_invitation = 'remove_invitation',
  transfer = 'transfer',
  assign = 'assign',
  pin = 'pin',
  unpin = 'unpin',
  remove_pin = 'remove_pin',
  delete = 'delete',
  add_admin = 'add_admin',
  del_admin = 'del_admin',
  desc = 'desc',
  pmc_edit = 'pmc_edit',
  error = 'error',
  mention_group = 'mention_group',
}

/* if need to support e2ee, update key_gen, aesAlg */
export enum KEY_GEN_ENUM {
  INVALID, // gcm key generate = 0
}

export enum ENC_ENUM {
  GCM_AES = 2,
}

export const GIPHY_DEFAULT_SIZE = {
  big: {
    width: 250,
    height: 200,
  },
  pc: {
    width: 150,
    height: 100,
  },
  mobile: {
    width: 100,
    height: 50,
  },
} as const;

export enum EtiquetteAction {
  NoAction = 1,
  AskUserToConfirm = 2,
  BlockTheMessage = 3,
}

export const FILE_UPLOAD_CHANNEL = {
  NORMAL: 4,
  CMC: 311,
} as const;
