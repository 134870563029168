import { iText } from '../../global/util';

const QA_COMP_FULL_TITLE = iText(
  'Question and Answer',
  'apac.wc_qa.qa_comp_ally_title',
);
const QA_PENDING_QUESTIONS_TIPS = iText(
  'Open questions',
  'apac.wc_qa.pending_questions_tips',
);
const IS_ANONYMOUS_TXT = iText('Send anonymously', 'apac.wc_qa.is_anonymous');
const IS_PRIVATELY_TXT = iText('Send privately', 'apac.wc_qa.is_privately');
const CANCEL_TXT = iText('Cancel', 'apac.wc_qa.cancel');
const SEND_TXT = iText('Send', 'apac.wc_qa.send');
const INPUT_TIPS = iText('Type your question here...', 'apac.wc_qa.input_tips');
const INPUT_TIPS_2 = iText(
  'Type your answer here...',
  'apac.wc_qa.input_tips_2',
);
const DISPLAY_ALL_ANSWER_TXT = iText(
  'Show more',
  'apac.wc_qa.display_all_answer2',
);
const COLLAPSE_ALL_ANSWER_TXT = iText(
  'Show less',
  'apac.wc_qa.collapse_all_answer2',
);
const BUTTON_DISPLAY_ANSWER_TXT = iText(
  'Type Answer',
  'apac.wc_qa.button_display_answer2',
);
const BUTTON_COMMENT_TXT = iText('Comment', 'apac.wc_qa.button_comment');
const BUTTON_LIVE_TXT = iText('Answer Live', 'apac.wc_qa.button_live2');
const BUTTON_LIVE_DONE_TXT = iText('Done', 'apac.wc_qa.button_live_done');
const BUTTON_REOPEN_TXT = iText('Reopen', 'apac.wc_qa.button_reopen');
const LIVE_TIPS_DONE_TXT = iText(
  'This question has been answered live',
  'apac.wc_qa.live_tips_done',
);
const LIVE_TIPS_START_TXT = iText(
  'would like to answer this question live',
  'apac.wc_qa.live_tips_start',
);
const DISMISS_TIPS_TXT = iText('Dismiss', 'apac.wc_qa.dissmis_tips');
const allQ = iText('All questions', 'apac.wc_qa.allQ');
const myQ = iText('My questions', 'apac.wc_qa.myQ');
const openedQ = iText('Open', 'apac.wc_qa.openedQ');
const answeredQ = iText('Answered', 'apac.wc_qa.answeredQ');
const dismissedQ = iText('Dismissed', 'apac.wc_qa.dismissedQ');
const ANSWER_PRIVATE_TYPE_TXT = iText(
  'Private answer',
  'apac.wc_qa.answer_private_type',
);
const QA_SETTING_BTN_NAME_TEXT = iText(
  'Settings',
  'apac.wc_qa.setting_btn_name',
);
const QA_SETTING_OPTION_TXT_ALLOW_ANONY_QUESTION = iText(
  'Allow anonymous questions',
  'apac.wc_qa.setting_option_allow_anony_question',
);

const webinarAttendeeCanSubmitQuestionsText = iText(
  'Attendee can submit questions',
  'apac.wc_qa.setting_option_attendee_can_submit_questions',
);

const QA_SETTING_OPTION_TXT_ENABLE_AUTO_REPLY = iText(
  'Enable Auto Reply',
  'apac.wc_qa.setting_option_enable_auto_reply',
);
const QA_SETTING_OPTION_TXT_CHANGE_AUTO_REPLY_MSG = iText(
  'You can customize the Auto-Response',
  'apac.wc_qa.setting_option_change_auto_reply_msg',
);
const QA_SETTING_OPTION_TXT_SAVE_AUTO_REPLY_MSG = iText(
  'Save',
  'apac.wc_qa.setting_option_save_auto_reply_msg',
);

const QA_SETTING_OPTION_TXT_AUTO_REPLY_CHANGE_SUCCESS = iText(
  'Saved your auto-reply changes.',
  'apac.wc_qa.setting_option_auto_reply_change_success',
);
const QA_SETTING_OPTION_TXT_AUTO_REPLY_CHANGE_FAILED = iText(
  'Unable to save your auto-reply changes. Try again.',
  'apac.wc_qa.setting_option_auto_reply_change_failed',
);
const qaSettingAllowViewWebinar = iText(
  'Allow attendees to view',
  'apac.wc_qa.setting_allow_view',
);
const QA_SETTING_OPTION_TXT_ALLOW_VIEW_ANSWERED = iText(
  'Answered questions only',
  'apac.wc_qa.setting_option_allow_view_answered',
);
const QA_SETTING_OPTION_TXT_ALLOW_VIEW_ALL = iText(
  'All questions',
  'apac.wc_qa.setting_option_allow_view_all',
);
const qaSettingAllowVoteWebinar = iText(
  'Attendees can upvote',
  'apac.wc_qa.setting_option_allow_vote',
);
const qaSettingAllowCommentWebinar = iText(
  'Attendees can comment',
  'apac.wc_qa.setting_option_allow_comment',
);
const QA_WELCOME_BANNER_TXT = iText(
  'Feel free to ask the host and panelists questions',
  'apac.wc_qa.welcome_banner',
);
const QA_WELCOME_BANNER_TITLE_TXT = iText(
  'Welcome',
  'apac.wc_qa.welcome_banner_title',
);
const anonymousAttendeeWebinarText = iText(
  'Anonymous Attendee',
  'apac.wc_qa.anonymous_attendee',
);
const QA_TOGGLE_BUTTON_TXT = iText('Q&A', 'apac.wc_qa.qa_comp_title');

const DISMISSED_INDICATOR = iText(
  'Dismissed by host',
  'apac.wc_qa.dismissed_indicator',
);
const DELETED_INDICATOR = iText(
  'Deleted by host',
  'apac.wc_qa.deleted_indicator',
);

export const NO_OPEN_QUESTIONS_TEXT = iText(
  'No open questions',
  'apac.wc_qa.no_open_questions',
);
export const NO_DISMISSED_QUESTIONS_TEXT = iText(
  'No dismissed questions',
  'apac.wc_qa.no_dismissed_questions',
);
export const NO_ANSWERED_QUESTIONS_TEXT = iText(
  'No answered questions',
  'apac.wc_qa.no_answered_questions',
);
export const NO_QUESTIONS_TEXT = iText(
  'No questions',
  'apac.wc_qa.no_questions',
);
export const QA_webinar_TIP1 = iText(
  'Everyone in this webinar can see open questions',
  'apac.wc_qa.qa_tip1',
);
export const QA_webinar_TIP2 = iText(
  'Only hosts and panelists can see the questions',
  'apac.wc_qa.qa_tip2',
);
export const QA_WELCOME_BANNER_TITLE_TXT_NEW = iText(
  'Welcome to Q&A',
  'apac.wc_qa.welcome_banner_title_new',
);
export const qaWelcomeBannerWebinarText = iText(
  'Questions you ask the host and panelists will show up here',
  'apac.wc_qa.welcome_banner_new',
);
export const QA_SORT_RECENT = iText('Most Recent', 'apac.qa.sort_most_recent');
export const QA_SORT_UPVOTE = iText(
  'Most Upvotes',
  'apac.qa.sort_most_upvotes',
);

export const MERGE_TO_MEETING_WINDOW = iText(
  'Merge to Meeting Window',
  'apac.qa.merge_to_meeting_window',
);

export const HOST_HAS_TURNED_OFF_QA = iText(
  'Host has turned off Q&A',
  'apac.qa.host_has_turned_off_qa',
);

export const QUESTIONS_ARE_CLOSED = iText(
  'Questions are closed',
  'apac.qa.questions_are_closed',
);

export {
  QA_COMP_FULL_TITLE,
  QA_PENDING_QUESTIONS_TIPS,
  IS_ANONYMOUS_TXT,
  IS_PRIVATELY_TXT,
  CANCEL_TXT,
  SEND_TXT,
  INPUT_TIPS,
  INPUT_TIPS_2,
  DISPLAY_ALL_ANSWER_TXT,
  COLLAPSE_ALL_ANSWER_TXT,
  BUTTON_DISPLAY_ANSWER_TXT,
  BUTTON_COMMENT_TXT,
  BUTTON_LIVE_TXT,
  BUTTON_LIVE_DONE_TXT,
  BUTTON_REOPEN_TXT,
  LIVE_TIPS_DONE_TXT,
  LIVE_TIPS_START_TXT,
  DISMISS_TIPS_TXT,
  allQ,
  myQ,
  openedQ,
  answeredQ,
  dismissedQ,
  ANSWER_PRIVATE_TYPE_TXT,
  QA_SETTING_BTN_NAME_TEXT,
  QA_SETTING_OPTION_TXT_ALLOW_ANONY_QUESTION,
  webinarAttendeeCanSubmitQuestionsText,
  qaSettingAllowViewWebinar,
  QA_SETTING_OPTION_TXT_ALLOW_VIEW_ANSWERED,
  QA_SETTING_OPTION_TXT_ALLOW_VIEW_ALL,
  qaSettingAllowVoteWebinar,
  qaSettingAllowCommentWebinar,
  QA_WELCOME_BANNER_TXT,
  QA_WELCOME_BANNER_TITLE_TXT,
  QA_TOGGLE_BUTTON_TXT,
  anonymousAttendeeWebinarText,
  DISMISSED_INDICATOR,
  DELETED_INDICATOR,
  QA_SETTING_OPTION_TXT_ENABLE_AUTO_REPLY,
  QA_SETTING_OPTION_TXT_CHANGE_AUTO_REPLY_MSG,
  QA_SETTING_OPTION_TXT_SAVE_AUTO_REPLY_MSG,
  QA_SETTING_OPTION_TXT_AUTO_REPLY_CHANGE_SUCCESS,
  QA_SETTING_OPTION_TXT_AUTO_REPLY_CHANGE_FAILED,
};
