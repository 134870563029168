import { QuestionTypeCode } from './constants';
import { POLLING_APAC } from './resource';

export const parseClock = (secondCount) => {
  const seconds = secondCount % 60;
  const s = seconds < 10 ? `0${seconds}` : seconds;
  const minutes = parseInt(`${secondCount / 60}`, 10) % 60;
  const m = minutes < 10 ? `0${minutes}` : minutes;
  const hours = parseInt(`${secondCount / 3600}`, 10);
  const h = hours < 10 ? `0${hours}` : hours;
  return {
    clockString: `${h}:${m}:${s}`,
    hours: Number(h),
    minutes: Number(m),
    seconds: Number(s),
  };
};
export const percent = (a, b) => `${b && Math.round((a / b) * 100)}%`;

const getAnswers = (question) => {
  if (!Array.isArray(question?.Answers) || question.Answers.length === 0) {
    return undefined;
  }

  const Answers =
    question?.Answers?.filter((v) => v.bChecked).map((v) => ({
      AnswerId: v.AnswerId,
    })) ?? undefined;

  return Answers;
};

const getAnswerText = (question) => {
  const AnswerText = question?.AnswerText ?? undefined;
  return AnswerText;
};

const getSubQuestions = (question) => {
  if (
    !Array.isArray(question?.SubQuestions) ||
    question.SubQuestions.length === 0
  ) {
    return undefined;
  }

  const SubQuestions =
    question?.SubQuestions?.map((subQuestion) => ({
      QuestionId: subQuestion?.QuestionId ?? '',
      Answers: getAnswers(subQuestion),
      AnswerText: getAnswerText(subQuestion),
    })) ?? undefined;

  return SubQuestions;
};

export const mapSubmitDataFromPollDataItem = (question) => {
  const { QuestionId = '' } = question;

  return {
    QuestionId,
    Answers: getAnswers(question),
    SubQuestions: getSubQuestions(question),
    AnswerText: getAnswerText(question),
  };
};

export const isAnsweredValidator = (question) => {
  const hasAnswerText = !!question?.AnswerText;
  const isAnswerChecked = question?.Answers?.some(
    (answerItem) => answerItem?.bChecked ?? false,
  );
  return hasAnswerText || isAnswerChecked;
};

export const maxCharacterValidator = (question) => {
  const shouldValidate = !!question?.MaxCharacter;
  if (!shouldValidate) return true;
  return (question?.AnswerText?.length ?? 0) <= question.MaxCharacter;
};

export const minCharacterValidator = (question, allValidators) => {
  const shouldAnswered = allValidators.find(
    (validator) => validator === isAnsweredValidator,
  );
  const isJustSkipTheQuestion =
    !shouldAnswered && !question?.AnswerText?.length;
  const shouldValidate = !!question?.MinCharacter && !isJustSkipTheQuestion;
  if (!shouldValidate) return true;
  return (question?.AnswerText?.length ?? 0) >= question.MinCharacter;
};

export const mergeValidator = (
  question,
  parentFailedValidators,
  ...validators
) => {
  return validators.reduce((prevResult, nextValidator) => {
    const nextValid = nextValidator(question, validators);
    if (!nextValid) {
      parentFailedValidators.push(nextValidator);
    }
    return prevResult && nextValid;
  }, true);
};

const ANSWER_IN_SUB_QUESTION_TYPES = [
  QuestionTypeCode.matching,
  QuestionTypeCode.rankOrder,
  QuestionTypeCode.fillInTheBlank,
];

export const validateQuestion = (question, forceSpecifiedValidators = []) => {
  const {
    nType,
    Required,
    // Answers,
    // AnswerText,
    SubQuestions,
    MaxCharacter,
    MinCharacter,
  } = question;
  const validators = [...forceSpecifiedValidators];
  const failedValidators = [];
  if (!forceSpecifiedValidators?.length) {
    if (Required) validators.push(isAnsweredValidator);
    if (MaxCharacter) validators.push(maxCharacterValidator);
    if (MinCharacter) validators.push(minCharacterValidator);
  }

  const shouldValidateSubQuestion = ANSWER_IN_SUB_QUESTION_TYPES.some(
    (questionType) => questionType === nType,
  );
  const shouldValidateMainQuestion = !shouldValidateSubQuestion;

  const isMainQuestionValid = shouldValidateMainQuestion
    ? mergeValidator(question, failedValidators, ...validators)
    : true;
  const isSubQuestionValid = shouldValidateSubQuestion
    ? SubQuestions.reduce(
        (prevResult, nextQuestion) =>
          mergeValidator(nextQuestion, failedValidators, ...validators) &&
          prevResult,
        true,
      )
    : true;

  return {
    isValid: isMainQuestionValid && isSubQuestionValid,
    failedValidators,
  };
};

export const checkCanSubmit = (pollingData) => {
  const canSubmit =
    !!pollingData?.Questions?.every((question) => {
      return validateQuestion(question)?.isValid ?? false;
    }) &&
    !!pollingData?.Questions?.some((question) => {
      return (
        validateQuestion(question, [isAnsweredValidator])?.isValid ?? false
      );
    });
  return canSubmit;
};

export const checkHasRightAnswers = (question) => {
  if (question?.RightAnswers?.length) {
    return true;
  }
  if (question?.SubQuestions) {
    return (
      question?.SubQuestions?.some((subQuestion) =>
        checkHasRightAnswers(subQuestion),
      ) ?? false
    );
  }
  return false;
};

// Refactoring this function in the future.
export const checkQuestionAnswersReleatedProperties = (
  question,
  flattenWrongAnswers = [],
  flattenRightAnswers = [],
) => {
  const { Answers, RightAnswers, SubQuestions, AnswerText, CaseSensitive } =
    question;
  const isSingle = !SubQuestions;
  const thisFlattenWrongAnswers = flattenWrongAnswers;
  const thisFlattenRightAnswers = flattenRightAnswers;
  if (Array.isArray(RightAnswers)) {
    const combinedRightAnswer = RightAnswers.map((rightAnswer) => ({
      question,
      rightAnswer,
    }));
    thisFlattenRightAnswers.push(...combinedRightAnswer);
  }

  const isThisQuestionAllCorrect =
    RightAnswers?.reduce((prevResult, rightAnswer) => {
      let nextAnswerCorrect = false;
      const isChoiceAnswer = !!rightAnswer?.AnswerId && !rightAnswer?.Answer;
      const isTextAnswer = !!rightAnswer?.Answer && !isChoiceAnswer;
      const targetAnswer =
        Answers?.find((answer) => answer?.AnswerId === rightAnswer?.AnswerId) ??
        null;

      if (isChoiceAnswer && targetAnswer?.bChecked) {
        // for multiple choice
        if (
          RightAnswers?.length === Answers?.filter((a) => a?.bChecked).length
        ) {
          nextAnswerCorrect = true;
        }
      }
      if (
        isTextAnswer &&
        CaseSensitive &&
        (rightAnswer?.Answer === targetAnswer?.AnswerText ||
          rightAnswer?.Answer === AnswerText)
      ) {
        nextAnswerCorrect = true;
      }
      if (
        isTextAnswer &&
        CaseSensitive === false &&
        (rightAnswer?.Answer?.localeCompare?.(
          targetAnswer?.AnswerText,
          undefined,
          { sensitivity: 'accent' },
        ) === 0 ||
          rightAnswer?.Answer?.localeCompare?.(AnswerText, undefined, {
            sensitivity: 'accent',
          }) === 0)
      ) {
        nextAnswerCorrect = true;
      }
      if (!nextAnswerCorrect) {
        const combinedAnswer = { question, rightAnswer };
        thisFlattenWrongAnswers.push(combinedAnswer);
      }
      return nextAnswerCorrect && prevResult;
    }, true) ?? true;

  const isSubQuestionsAllCorrect =
    SubQuestions?.reduce(
      (prevResult, subQuestion) =>
        checkQuestionAnswersReleatedProperties(
          { ...subQuestion, CaseSensitive },
          thisFlattenWrongAnswers,
          thisFlattenRightAnswers,
        )?.isAllCorrect && prevResult,
      true,
    ) ?? true;
  const isAllCorrect = isThisQuestionAllCorrect && isSubQuestionsAllCorrect;
  const shouldReturnWrongAnswersForSingle = isSingle && !isAllCorrect;
  const finalWrongAnswers = shouldReturnWrongAnswersForSingle
    ? thisFlattenRightAnswers
    : thisFlattenWrongAnswers;
  return {
    isAllCorrect,
    flattenWrongAnswers: finalWrongAnswers,
    flattenRightAnswers: thisFlattenRightAnswers,
    isSingle,
    isMultiple: !isSingle,
  };
};

export const recursiveTraversalQuestion = (
  question,
  callback,
  recursivePropertyNames = ['SubQuestions'],
) => {
  callback(question);
  if (Array.isArray(recursivePropertyNames)) {
    recursivePropertyNames.forEach((recursivePropertyName) => {
      if (Array.isArray(question[recursivePropertyName])) {
        question[recursivePropertyName].forEach((subQuestion) => {
          callback(subQuestion, question);
        });
      }
    });
  }
};

// currently, questions max depth: 2
// if depth add, questionMapKey will collision
export const flattenQuestions = (
  questions,
  recursivePropertyNames = ['SubQuestions'],
) => {
  if (!Array.isArray(questions)) return null;

  const questionMap = new Map();

  questions.forEach((question) => {
    recursiveTraversalQuestion(
      question,
      (question, parentQuestion) => {
        const questionMapKey = parentQuestion
          ? `${parentQuestion?.QuestionId};${question?.QuestionId}`
          : `${question?.QuestionId}`;
        questionMap.set(questionMapKey, question);
      },
      recursivePropertyNames,
    );
  });

  return questionMap;
};

export const returnPreviousOrder = (questions, previousQuestions) => {
  return previousQuestions
    .map((previousQuestion) =>
      questions.find(
        (question) => question?.QuestionId === previousQuestion?.QuestionId,
      ),
    )
    .filter((q) => !!q);
};

export const sameQuestions = (questions1, questions2) => {
  return (
    Array.isArray(questions1) &&
    Array.isArray(questions2) &&
    questions1.length === questions2.length &&
    questions1.every(
      (question1) =>
        !!questions2.find(
          (question2) => question1?.QuestionId === question2?.QuestionId,
        ),
    )
  );
};

export const composeIdFromAnswerAndDataAL = (answer, dataAL) =>
  `${dataAL};${answer?.AnswerId ?? 0}`;

export const getAnswerIdFromComposedId = (composeId) => composeId.split(';')[1];

export const getQuestionType = (nType) => {
  switch (nType) {
    case QuestionTypeCode.single:
      return POLLING_APAC.SingleChoice;
    case QuestionTypeCode.multiple:
      return POLLING_APAC.MultipleChoice;
    case QuestionTypeCode.matching:
      return POLLING_APAC.Matching;
    case QuestionTypeCode.rankOrder:
      return POLLING_APAC.RankOrder;
    case QuestionTypeCode.shortAnswer:
      return POLLING_APAC.ShortAnswer;
    case QuestionTypeCode.longAnswer:
      return POLLING_APAC.LongAnswer;
    case QuestionTypeCode.fillInTheBlank:
      return POLLING_APAC.SingleChoice;
    case QuestionTypeCode.ratingScale:
      return POLLING_APAC.SingleChoice;
  }
};

export const combineAnswersWithBCorrect = (anwsers, rightAnswers, nType) => {
  if (!rightAnswers) {
    return anwsers;
  }

  return anwsers.map((answer) => {
    let bCorrect;
    switch (nType) {
      case QuestionTypeCode.single:
        bCorrect = rightAnswers[0].AnswerId === answer.AnswerId;
        break;
      case QuestionTypeCode.multiple:
        bCorrect =
          rightAnswers.findIndex(
            ({ AnswerId }) => AnswerId === answer.AnswerId,
          ) > -1;
        break;
    }
    return {
      ...answer,
      bCorrect,
    };
  });
};
