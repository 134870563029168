import React from 'react';
import { connect } from 'react-redux';
import NotificationMessage from './notification-message';
import { CONNECTING_TO_RECORD_SERVER_TEXT } from '../../../features/recording/resource';
import { LazySVGIcon } from '../../components/widget/icon';

export const LOADING_TEXT_NOTIFICATION_TYPES = {
  START_RECORDING: {
    value: 0,
    text: CONNECTING_TO_RECORD_SERVER_TEXT,
  },
  STOP_RECORDING: {
    value: 1,
    text: '',
  },
};

const getText = (value) => {
  const target = Object.values(LOADING_TEXT_NOTIFICATION_TYPES).find(
    (item) => item.value === value,
  );
  return target?.text;
};

const LoadingTextNotification = ({ loadingNotificationValues }) => {
  if (loadingNotificationValues.length === 0) {
    return null;
  }
  return loadingNotificationValues.map((value) => (
    <NotificationMessage isOpen showClose={false} key={value}>
      <div className="loading-notification-container">
        <div className="loading-notification-container__loading">
          <LazySVGIcon
            name="icons/loading"
            svgProps={{ width: 16, height: 16 }}
          />
        </div>
        <span>{getText(value)}</span>
      </div>
    </NotificationMessage>
  ));
};

const mapStateToProps = (state) => {
  const {
    meetingUI: { loadingNotificationValues },
  } = state;
  return {
    loadingNotificationValues,
  };
};

export default connect(mapStateToProps)(LoadingTextNotification);

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/containers/notification-manager/loading-text-notification.scss';
