import React, { Component } from 'react';
import mitt from 'mitt';
import { WC_LOCAL_EVT_ENUM } from '../../enum';

function blurGuard(Comp) {
  return class blurGuardComponent extends Component {
    constructor(props, context) {
      super(props, context);
      this.eventBus = mitt();
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    getChildRootRef = (ele) => {
      this.rootEle = ele;
    };

    componentDidMount() {
      if (!this.rootEle) {
        throw new Error(
          'blur-guard requires consumer component must be either a composite component or a functional component providing the root DOM element by calling provideRootRef props from blur-guard',
        );
      }
      document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
      if (this.rootEle && !this.rootEle.contains(event.target)) {
        this.eventBus.emit(WC_LOCAL_EVT_ENUM.BLUR);
      }
    }

    render() {
      return (
        <Comp
          {...this.props}
          provideRootRef={this.getChildRootRef}
          blurNotifier={this.eventBus}
        />
      );
    }
  };
}

export { blurGuard };
