import { produce } from 'immer';
import { emptyUser } from '../constants/DefaultModules';
import * as types from '../constants/MeetingActionTypes';
import {
  isZRMultiStreamVideoUser,
  getLastWord,
  getFirstWord,
  checkIsHostSupportGallerySortType,
  isSupportGallerySortMode,
  isVirtualUser,
} from '../global/service';
import {
  sortVideoOrderByDragList,
  sortNewBasicAttendeeList,
  sortBasicAttendeeList,
  sortOrderByAlphabetical,
  sortUserToSpeaclOrder,
} from '../global/service/sortAttendeeList';

import { decodeBase64, isRwgPhoneUser } from '../global/util';
import { INTERPRETATION_LANG_TYPES } from '../features/interpretation/constant';
import { isAllowOnlyCanTalk, isHost } from '../global/service/user-types';

export const defaultAttendeesList = {
  attendeesList: [],
  virtualAttendeesList: [],
  hasAsnSet: {},
  xmppAllowTalkList: [],
  raiseHandUniq: '',
  holdOnUniq: '',
  addNewUserUniq: '',
  removeUserUniq: '',
  sdkUsers: { normalParticipants: [], webinarAttendees: [] },
  waitingRoomUserUniq: '',
  failoverUsersInWaitingRoom: [],
  failoverUsersInMeeting: [],
  dragList: [],
  notJoinedList: [],
  zrUserMap: {},
  assistants: [],
  videoQualityList: [],
  attendeeInPracticeCount: 0,
};

const replacements = {
  id: 'userId',
  type: 'userType',
  role: 'userRole',
  dn2: 'displayName',
  bShareOn: 'sharerOn',
  bSharePause: 'sharerPause',
  bLocalRecordStatus: 'bLocalRecord',
  bGuest: 'isGuest',
};

export const removeDuplicates = (data) =>
  Object.values(
    data.reduce((attendees, item) => {
      const { id } = item;
      const replacedItems = Object.keys(item).map((key) => {
        if (item[key] == null) {
          return null;
        }
        const newKey = replacements[key] || key;
        switch (key) {
          case 'role':
            return { [newKey]: item[key], isHost: isHost(item[key]) };
          case 'bShareOn':
            return { [newKey]: !!item[key] };
          case 'bSharePause':
            return { [newKey]: !!item[key] };
          case 'bLocalRecordStatus':
            return { [newKey]: item[key] === 1 };
          case 'dn2':
            return { [newKey]: decodeBase64(item[key], true) };
          default:
            return { [newKey]: item[key] };
        }
      });
      const newValue = Object.assign({}, ...replacedItems);
      if (attendees[id]) {
        attendees[id] = Object.assign(attendees[id], newValue);
      } else {
        attendees[id] = newValue;
      }
      return attendees;
    }, {}),
  );

const findUserIndex = (draft, userId) => {
  return draft.attendeesList.findIndex((user) => user.userId === userId);
};

const mountParentAndChild = (draft, parentId, childId, index) => {
  const parentIndex = findUserIndex(draft, parentId);
  if (parentIndex > -1) {
    const childIndex = findUserIndex(draft, childId);
    if (childIndex > -1) {
      draft.attendeesList[childIndex].zrChildUserUnLinked = false;
      draft.attendeesList.splice(
        parentIndex + index + 1,
        0,
        draft.attendeesList.splice(childIndex, 1)[0],
      );
    }
  }
};

const addZRMultiStreamVideoUser = (draft, dataArr) => {
  dataArr.forEach((item) => {
    if (isZRMultiStreamVideoUser(item)) {
      if (item.bMultiStreamVideoUser) {
        if (draft.zrUserMap[item.parentUserID] === undefined) {
          draft.zrUserMap[item.parentUserID] = [];
        }
        draft.zrUserMap[item.parentUserID].push(item);
        mountParentAndChild(
          draft,
          item.parentUserID,
          item.userId,
          draft.zrUserMap[item.parentUserID].length - 1,
        );
      } else if (draft.zrUserMap[item.userId]) {
        draft.zrUserMap[item.userId].forEach((child, index) => {
          mountParentAndChild(draft, item.userId, child.userId, index);
        });
      }
    }
  });
};

const removeZRMultiStreamVideoUser = (draft, dataArr) => {
  dataArr.forEach((item) => {
    const user = draft.attendeesList.find(
      (user) => user.userId === item.userId,
    );
    if (user && isZRMultiStreamVideoUser(user)) {
      if (user.bMultiStreamVideoUser) {
        const index = draft.zrUserMap[user.parentUserID].findIndex(
          (childItem) => childItem.userId === user.userId,
        );
        if (index > -1) {
          draft.zrUserMap[user.parentUserID].splice(index, 1);
          if (draft.zrUserMap[user.parentUserID].length === 0) {
            draft.zrUserMap[user.parentUserID] = undefined;
          }
        }
      } else if (draft.zrUserMap[user.userId]) {
        draft.zrUserMap[user.userId].forEach((child) => {
          const childIndex = findUserIndex(draft, child.userId);
          if (childIndex > -1) {
            draft.attendeesList[childIndex].zrChildUserUnLinked = true;
          }
        });
      }
    }
  });
};

const mergeNamePropertyToAttendee = (item) => {
  return Object.assign(item, {
    firstName: getFirstWord(item.displayName),
    lastName: getLastWord(item.displayName),
  });
};

const addNewUser = (draft, dataArr) => {
  /**
   * if participant is a phone-call-in user, him will be assigned a default avatar
   *  which background is one of eight color
   */
  dataArr
    .map((addItem) => ({
      ...emptyUser,
      ...addItem,
    }))
    .map((addItem) =>
      isRwgPhoneUser(addItem)
        ? {
            ...addItem,
            avatarBackgroundType: Math.floor(Math.random() * 8) + 1,
          }
        : addItem,
    )
    .map((addItem) => {
      if (isZRMultiStreamVideoUser(addItem)) {
        return addItem.bMultiStreamVideoUser
          ? {
              ...addItem,
              zrChildUserUnLinked: true,
            }
          : {
              ...addItem,
              zrCollapse: false,
            };
      }
      return addItem;
    })
    .forEach((item) => {
      const existAttendee = draft.attendeesList.find(
        (attendee) => attendee.userId === item.userId,
      );
      if (existAttendee) {
        Object.assign(existAttendee, item);
        // generate firstName lastName
        mergeNamePropertyToAttendee(existAttendee);
      } else {
        const existXmppUser = draft.xmppAllowTalkList.find(
          (user) => user.userId === item.userId,
        );
        if (isAllowOnlyCanTalk(item.userRole) && !existXmppUser) {
          const newItem = mergeNamePropertyToAttendee(item);
          draft.xmppAllowTalkList.push(newItem);
        } else if (existXmppUser) {
          Object.assign(existXmppUser, item);
          // generate firstName lastName
          mergeNamePropertyToAttendee(existXmppUser);
        } else {
          const existVirtualAttendee = draft.virtualAttendeesList.find(
            (user) => user.userId === item.userId,
          );
          if (existVirtualAttendee) {
            Object.assign(existVirtualAttendee, item);
            // generate firstName lastName
            mergeNamePropertyToAttendee(existVirtualAttendee);
          } else {
            const newItem = mergeNamePropertyToAttendee(item);
            if (isVirtualUser(newItem)) {
              draft.virtualAttendeesList.push(newItem);
            } else {
              draft.attendeesList.push(newItem);
            }
          }
        }
      }
    });
};

const updateUser = (draft, dataArr) => {
  dataArr.forEach((item) => {
    const existAttendee = draft.attendeesList.find(
      (attendee) => attendee.userId === item.userId,
    );
    if (existAttendee !== undefined) {
      Object.assign(existAttendee, item);
      // generate firstName lastName
      mergeNamePropertyToAttendee(existAttendee);
    } else {
      const existXmppUser = draft.xmppAllowTalkList.find(
        (user) => user.userId === item.userId,
      );
      if (existXmppUser !== undefined) {
        Object.assign(existXmppUser, item);
        // generate firstName lastName
        mergeNamePropertyToAttendee(existXmppUser);
      } else {
        const existVirtualAttendee = draft.virtualAttendeesList.find(
          (user) => user.userId === item.userId,
        );
        if (existVirtualAttendee) {
          Object.assign(existVirtualAttendee, item);
          // generate firstName lastName
          mergeNamePropertyToAttendee(existVirtualAttendee);
        }
      }
    }
  });
};

const removeUser = (draft, dataArr) => {
  dataArr.forEach((item) => {
    const existAttendeeIndex = draft.attendeesList.findIndex(
      (attendee) => attendee.userId === item.userId,
    );
    if (existAttendeeIndex > -1) {
      draft.attendeesList.splice(existAttendeeIndex, 1);
    } else {
      const existXmppUserIndex = draft.xmppAllowTalkList.findIndex(
        (user) => user.userId === item.userId,
      );
      if (existXmppUserIndex > -1) {
        draft.xmppAllowTalkList.splice(existXmppUserIndex, 1);
      } else {
        const existVirtualAttendeeIndex = draft.virtualAttendeesList.findIndex(
          (user) => user.userId === item.userId,
        );
        if (existVirtualAttendeeIndex > -1) {
          draft.virtualAttendeesList.splice(existVirtualAttendeeIndex, 1);
        }
      }
    }
  });
};

const ASSISTANT_TYPE = 51;
const splitAssistantUser = (draft, data) => {
  const assistant = {
    exist: [],
    remove: [],
  };
  data.add =
    data.add &&
    data.add.filter((item) => {
      if (item.type === ASSISTANT_TYPE) {
        assistant.exist.push(item);
        return false;
      }
      return true;
    });

  data.update =
    data.update &&
    data.update.filter((item) => {
      if (item.type === ASSISTANT_TYPE) {
        assistant.exist.push(item);
        return false;
      }
      return true;
    });

  removeDuplicates(assistant.exist).forEach((item) => {
    const existUser = draft.assistants.find((v) => v.userId === item.userId);
    if (existUser) {
      Object.assign(existUser, item);
    } else {
      draft.assistants.push({ ...item, isAdmin: true });
    }
  });

  if (data.remove) {
    data.remove.forEach((item) => {
      draft.assistants = draft.assistants.filter((v) => v.userId !== item.id);
    });
  }

  return data;
};

const attendeeList = produce((draft, action) => {
  switch (action.type) {
    case types.WS_CONF_ROSTER_INDICATION: {
      let data = action.message.body;
      data = splitAssistantUser(draft, data);
      if (data.add && data.add.length > 0) {
        const newUser = removeDuplicates(data.add);
        addNewUser(draft, newUser);
        addZRMultiStreamVideoUser(draft, newUser);
        const hostUser = draft.attendeesList?.find((item) => item.isHost);
        if (data.isFollowHost && hostUser?.userId !== data.currentUserId) {
          if (
            isSupportGallerySortMode() &&
            data.galleryOrderType &&
            !data.isWebinarAttendee &&
            (!hostUser || checkIsHostSupportGallerySortType(hostUser?.caps))
          ) {
            sortOrderByAlphabetical(draft, data.galleryOrderType);
            sortUserToSpeaclOrder(draft, [
              {
                userId: hostUser?.userId,
                position: 1,
              },
              {
                userId: data.currentUserId,
                position: 2,
              },
            ]);
          } else {
            sortVideoOrderByDragList(draft, newUser);
          }
        } else if (isSupportGallerySortMode() && data.galleryOrderType) {
          sortOrderByAlphabetical(draft, data.galleryOrderType);
          sortUserToSpeaclOrder(draft, [
            {
              userId: data.currentUserId,
              position: 1,
            },
          ]);
        } else if (!data.hasChangedVideoOrder) {
          sortBasicAttendeeList(draft, data.currentUserId);
        } else {
          sortNewBasicAttendeeList(draft, newUser);
        }
      }
      if (data.update && data.update.length > 0) {
        updateUser(draft, removeDuplicates(data.update));
        const hostUser = draft.attendeesList?.find((item) => item.isHost);
        if (data.isFollowHost && hostUser?.userId !== data.currentUserId) {
          // sort by order case
          if (
            isSupportGallerySortMode() &&
            data.galleryOrderType &&
            !data.isWebinarAttendee &&
            (!hostUser || checkIsHostSupportGallerySortType(hostUser?.caps))
          ) {
            sortOrderByAlphabetical(draft, data.galleryOrderType);
            sortUserToSpeaclOrder(draft, [
              {
                userId: hostUser?.userId,
                position: 1,
              },
              {
                userId: data.currentUserId,
                position: 2,
              },
            ]);
          }
        } else if (isSupportGallerySortMode() && data.galleryOrderType) {
          sortOrderByAlphabetical(draft, data.galleryOrderType);
          sortUserToSpeaclOrder(draft, [
            {
              userId: data.currentUserId,
              position: 1,
            },
          ]);
        } else if (!data.hasChangedVideoOrder) {
          sortBasicAttendeeList(draft, data.currentUserId);
        }
      }

      if (data.remove && data.remove.length > 0) {
        const rmUser = removeDuplicates(data.remove);
        removeZRMultiStreamVideoUser(draft, rmUser);
        removeUser(draft, rmUser);
      }
      return draft;
    }

    case types.SET_ATTENDEE_ASN: {
      const existAttendee = draft.attendeesList.find(
        (attendee) => attendee.userId === action.data.id,
      );
      if (existAttendee !== undefined) {
        Object.assign(draft.hasAsnSet, {
          [action.data.id]: action.data.hasAsn,
        });
      } else {
        const existXmppUser = draft.xmppAllowTalkList.find(
          (attendee) => attendee.userId === action.data.id,
        );
        if (existXmppUser) {
          Object.assign(draft.hasAsnSet, {
            [action.data.id]: action.data.hasAsn,
          });
        }
      }
      return draft;
    }
    case types.CLEAR_ATTENDEES_LIST: {
      draft.attendeesList = [];
      draft.zrUserMap = {};
      return draft;
    }
    case types.SET_ATTENDEE_RAISEHAND: {
      draft.attendeesList.forEach((attendee) => {
        if (attendee.bRaiseHand) {
          attendee.bRaiseHand = false;
        }
      });
      return draft;
    }
    case types.SET_IS_ADDED_TO_CAMERA_CONTROL_GROUP: {
      draft.attendeesList.forEach((attendee) => {
        if (attendee.userId === action.data) {
          attendee.isAddedToCameraControlGroup =
            !attendee.isAddedToCameraControlGroup;
        }
      });
      return draft;
    }

    case types.CLEAR_ATTENDEE_FEEDBACK: {
      draft.attendeesList.forEach((attendee) => {
        if (attendee.feedback > 0) {
          attendee.feedback = 0;
        }
      });
      return draft;
    }

    case types.SET_ATTENDEE_HOLD_ON_UNIQ: {
      draft.holdOnUniq = action.data;
      return draft;
    }
    case types.SET_ATTENDEE_NEW_UNIQ: {
      draft.addNewUserUniq = action.data;
      return draft;
    }
    case types.SAVE_WEB_SDK_USER: {
      draft.sdkUsers = action.data;
      return draft;
    }
    case types.SET_ATTENDEE_REMOVE_UNIQ: {
      draft.removeUserUniq = action.data;
      return draft;
    }

    case types.ADD_FAILOVER_USERS_IN_WR: {
      draft.failoverUsersInWaitingRoom.push(action.data);
      return draft;
    }
    case types.REMOVE_FAILOVER_USERS_IN_WR: {
      const targetIndex = draft.failoverUsersInWaitingRoom.findIndex(
        (zoomID) => zoomID === action.data,
      );
      if (targetIndex > -1) {
        draft.failoverUsersInWaitingRoom.splice(targetIndex, 1);
      }
      return draft;
    }
    case types.ADD_FAILOVER_USERS_IN_MEETING: {
      draft.failoverUsersInMeeting.push(action.data);
      return draft;
    }
    case types.REMOVE_FAILOVER_USERS_IN_MEETING: {
      const targetIndex = draft.failoverUsersInMeeting.findIndex(
        (zoomID) => zoomID === action.data,
      );
      if (targetIndex > -1) {
        draft.failoverUsersInMeeting.splice(targetIndex, 1);
      }
      return draft;
    }
    case types.SET_ATTENDEE_WAITING_ROOM_USER_UNIQ: {
      draft.waitingRoomUserUniq = action.data;
      return draft;
    }
    case types.SET_ATTENDEE_RAISE_HAND_UNIQ: {
      draft.raiseHandUniq = action.data;
      return draft;
    }

    case types.SWAP_VIDEO_ORDER: {
      const list = [...draft.attendeesList, ...draft.xmppAllowTalkList];
      const dragUser = list.find(
        (item) => item.userId === action.data.dragSourceUserId,
      );
      const dropUser = list.find(
        (item) => item.userId === action.data.dragTargetUserId,
      );
      const swapTemp = dragUser.videoOrder;
      dragUser.videoOrder = dropUser.videoOrder;
      dropUser.videoOrder = swapTemp;
      return draft;
    }

    case types.RESET_VIDEO_ORDER: {
      sortBasicAttendeeList(draft, action.data);
      return draft;
    }

    case types.SET_DRAG_LAYOUT: {
      if (action.data) {
        draft.dragList = action.data;
      }
      return draft;
    }
    case types.RESET_INTERPRETERS: {
      draft.attendeesList.forEach((attendee) => {
        if (attendee.isInterpreter) {
          attendee.isInterpreter = false;
          attendee.activeInterpretationLanguage =
            INTERPRETATION_LANG_TYPES.ORIGINAL;
        }
      });
      return draft;
    }
    case types.SET_NOT_JOINED_LIST: {
      draft.notJoinedList = action.payload;
      return draft;
    }
    case types.CHANGE_NOT_JOINED_LIST: {
      const { toHide, toShow } = action.payload;
      draft.notJoinedList = [...toShow, ...toHide];
      return draft;
    }
    case types.SET_MERGE_QUALITY: {
      const { ssrc, quality, width, height, fps } = action.data;
      const realSsrc = ssrc >> 10;
      let targetUser = draft.videoQualityList.find(
        (item) => item.ssrc === realSsrc,
      );
      if (!targetUser) {
        targetUser = { ssrc: realSsrc };
        draft.videoQualityList.push(targetUser);
      }
      if (quality !== undefined) {
        targetUser.currentQuality = quality;
      }
      if (width && height) {
        targetUser.currentWidth = width;
        targetUser.currentHeight = height;
      }
      if (fps !== undefined) {
        targetUser.currentFps = fps;
      }
      return draft;
    }

    case types.TOGGLE_ZR_MULTI_STREAM_VIDEO_USER: {
      const parentIndex = findUserIndex(draft, action.data);
      if (parentIndex > -1) {
        draft.attendeesList[parentIndex].zrCollapse =
          !draft.attendeesList[parentIndex].zrCollapse;
      }
      return draft;
    }
    case types.UPDATE_ALL_ATTENDEES_ORDER: {
      const { list } = action.payload;
      draft.attendeesList = list;
      return draft;
    }
    case types.UPDATE_ALL_XMPP_ALLOW_TALK_LIST: {
      const { list } = action.payload;
      draft.xmppAllowTalkList = list;
      return draft;
    }
    case types.RENAME_ZR_CHILD_USER_DISPLAYNAME: {
      const { participant, displayName } = action.data;
      if (draft.zrUserMap[participant.parentUserID]) {
        draft.zrUserMap[participant.parentUserID].forEach((item) => {
          if (item.userId === participant.userId) {
            item.displayName = displayName;
          }
        });
      }
      return draft;
    }
    case types.UPDATE_ATTENDEE_IN_PRACTICE_SESSION: {
      draft.attendeeInPracticeCount = action.payload;
      return draft;
    }
    default:
      return draft;
  }
}, defaultAttendeesList);

export const updateHoldOnUniq = (data) => ({
  type: types.SET_ATTENDEE_HOLD_ON_UNIQ,
  data,
});

export const updateaddNewUserUniq = (data) => ({
  type: types.SET_ATTENDEE_NEW_UNIQ,
  data,
});

export const saveWebSDKUser = (data) => ({
  type: types.SAVE_WEB_SDK_USER,
  data,
});

export const updateRemoveUserUniq = (data) => ({
  type: types.SET_ATTENDEE_REMOVE_UNIQ,
  data,
});

export const addFailoverUsersInWR = (data) => ({
  type: types.ADD_FAILOVER_USERS_IN_WR,
  data,
});

export const removeFailoverUsersInWR = (data) => ({
  type: types.REMOVE_FAILOVER_USERS_IN_WR,
  data,
});

export const addFailoverUsersInMeeting = (data) => ({
  type: types.ADD_FAILOVER_USERS_IN_MEETING,
  data,
});

export const removeFailoverUsersInMeeting = (data) => ({
  type: types.REMOVE_FAILOVER_USERS_IN_MEETING,
  data,
});

export const updateWaitingRoomUserUniq = (data) => ({
  type: types.SET_ATTENDEE_WAITING_ROOM_USER_UNIQ,
  data,
});

export const updateRainseHandUniq = (data) => ({
  type: types.SET_ATTENDEE_RAISE_HAND_UNIQ,
  data,
});

export const swapVideoOrder = (data) => ({
  type: types.SWAP_VIDEO_ORDER,
  data,
});

export const resetVideoOrder = (data) => ({
  type: types.RESET_VIDEO_ORDER,
  data,
});

export const resetInterpreters = (data) => ({
  type: types.RESET_INTERPRETERS,
  data,
});

export const setMergeQuality = (data) => ({
  type: types.SET_MERGE_QUALITY,
  data,
});

export const setDragLayout = (data) => ({
  type: types.SET_DRAG_LAYOUT,
  data,
});

export const toggleZRMultiStreamVideoUser = (data) => ({
  type: types.TOGGLE_ZR_MULTI_STREAM_VIDEO_USER,
  data,
});

export const updateAllAttendees = (payload) => ({
  type: types.UPDATE_ALL_ATTENDEES_ORDER,
  payload,
});

export const updateAllXmppAllowTalkList = (payload) => ({
  type: types.UPDATE_ALL_XMPP_ALLOW_TALK_LIST,
  payload,
});

export const renameZRChildUserDisplayName = (data) => ({
  type: types.RENAME_ZR_CHILD_USER_DISPLAYNAME,
  data,
});

// setDragLayoutThunk use for update draglist
// after update draglist, sort attendeelist by condition
export function setDragLayoutThunk({ dragList, forceFollowDrag }) {
  return (dispatch, getState) => {
    const {
      meeting: {
        // bFollowHostVideo,
        //  currentUser: { userId },
        isHost,
      },
      video: { galleryOrderType },
      attendeesList,
    } = getState();

    // not set undefined/null
    if (dragList) {
      dispatch(setDragLayout(dragList));
    }

    // two case 1 webinar attendee 2 leave follow host view
    // use draglist
    if (forceFollowDrag || !galleryOrderType) {
      const newSortedAttendees = produce(attendeesList, (draft) => {
        return sortVideoOrderByDragList(draft, null, dragList);
      });

      dispatch(updateAllAttendees({ list: newSortedAttendees.attendeesList }));
      dispatch(
        updateAllXmppAllowTalkList({
          list: newSortedAttendees.xmppAllowTalkList,
        }),
      );
      return;
    } /* else if (dragList?.length === 0) {
      const newSortedAttendees = produce(attendeesList, (draft) => {
        return sortBasicAttendeeList(draft, userId);
      });

      dispatch(updateAllAttendees({ list: newSortedAttendees.attendeesList }));
      dispatch(
        updateAllXmppAllowTalkList({
          list: newSortedAttendees.xmppAllowTalkList,
        }),
      );

      return;
    }*/

    // skip when order type not none
    if (galleryOrderType && !isHost) {
      return;
    }
  };
}

export const updateAttendeeInPracticeCount = (count) => ({
  type: types.UPDATE_ATTENDEE_IN_PRACTICE_SESSION,
  payload: count,
});

export default attendeeList;
