export const localReadyStatesObserver = (() => {
  let targetCap = 0x0;
  let flags = 0x0;

  return {
    setTargetCap(val) {
      targetCap = val;
    },
    complete(newFlag) {
      flags |= newFlag;
      if (flags === targetCap) {
        /* eslint-disable-next-line no-console */
        console.error('init done!!');

        if (typeof this.readyHandler === 'function') this.readyHandler();
      }
    },
    onReady(handler) {
      this.readyHandler = handler;
    },
    reset() {
      targetCap = 0x0;
      flags = 0x0;
    },
    isTargetCapSet() {
      return targetCap !== 0x0;
    },
  };
})();
