import * as types from '../constants/MeetingActionTypes';
import * as ms from '../constants/MeetingUIComponentSettings';
import { isShowNewFeatureTips } from '../global/service';
import { PanelName, PanelViewType } from '../global/redux/set-panel-action';
import {
  SET_MINIMIZED_PANEL,
  SET_CHAT_WARNING,
} from '../actions/MeetingUIPureAction';

const theme = localStorage.getItem('userSettingTheme');

export const defaultMeetingUI = {
  isOnHold: easyStore.easyGet('isOnHold'),
  isOnHoldDefaultTrue: true,
  fullScreen: false,
  showMeetingLockedTip: false,
  windowSize: {
    width: window.innerWidth || 0,
    height: window.innerHeight || 0,
  },
  sizeScale: {
    widthScale: 1,
    heightScale: 1,
  },
  muteAll: null,
  showAttendeeList: false,
  showJoinDialog: false,
  rememberPNChecked: false,
  rememberLockChecked: false,
  showInviteDialog: false,
  showMenu: false,
  showAudioMenu: false,
  showMicDisallowMenu: false, // @deprecated
  showShareDisallowMenu: false, // @deprecated
  showNoSupportInfo: true,
  perViewNum: 6,
  joinConfType: 1,
  rightContainerWidth: 400,
  callOut: {
    phoneNumber: '',
    showStatus: false,
    seq: 0,
  },
  isCalling: false,
  invite: {
    phoneNumber: '',
    showIndication: false,
    seq: 0,
  },
  inviteSeq: [],
  copied: '',
  copiedURL: '',
  windowZIndex: 999,
  isResizeWindow: false,
  meetingHeadType: ms.MEETING_HEAD_TYPE_NORMAL,
  mergeAudioVisible: false,
  mergeAudioType: null,
  mergedParticipant: null,
  showWaitingRoomNotification: false,
  avDisabledTipVisible: false,
  isWebClientFocused: true,
  showNewFeatureNotification: isShowNewFeatureTips(),
  showSharePronounNotification: false,
  loadingNotificationValues: [],
  inviteeListVisible: true,
  isWebglLostMenuDisable: easyStore.easyGet('webglLostSwitch') ?? false,
  showReclaimHostNotification: false,
  isViewButtonFocused: false,
  isMinimizeMode: false,
  panelViewState: {
    currentGroupType: '',
    currentGroupList: [],
    currentWindowList: [],
  },
  panelViewResult: {},
  minimizedPanel: null,
  chatWarning: [],
  isPIPMode: false,
  consents: {
    position: 'top',
  },
  showMeetingHeader: true,
  theme: theme ?? 'classic',
  newWaitingRoomFlowLoading: false,
  isUILayoutCleaned: false,
};

const defaultAction = {
  type: types.INIT_SCREEN,
};

const checkPanelType = ({ currentGroupList, currentWindowList }, panelName) => {
  if (currentGroupList.includes(panelName)) {
    if (currentGroupList.length > 1) {
      return PanelViewType.partView;
    } else {
      return PanelViewType.fullView;
    }
  } else if (currentWindowList.includes(panelName)) {
    return PanelViewType.windowView;
  }
  if (
    panelName === PanelName.rightPanelContainer &&
    currentGroupList.length > 0
  ) {
    return PanelViewType.fullView;
  }

  return false;
};

export default function meetingUI(
  state = defaultMeetingUI,
  action = defaultAction,
) {
  switch (action.type) {
    case SET_CHAT_WARNING: {
      return { ...state, chatWarning: action.payload };
    }
    case SET_MINIMIZED_PANEL: {
      return {
        ...state,
        minimizedPanel: action.payload,
      };
    }
    case types.MEETING_UI_RESET:
      return { ...defaultMeetingUI, ...action.data };
    case types.RESIZE_WINDOW:
      return {
        ...state,
        windowSize: {
          width: action.data.width,
          height: action.data.height,
        },
      };
    case types.UPDATE_JOINCONFTYPE:
      return { ...state, joinConfType: action.data };
    case types.SHOW_JOINDIALOG:
      return { ...state, showJoinDialog: action.showJoinDialog };
    case types.SHOW_INVITEDIALOG:
      return { ...state, showInviteDialog: action.showInviteDialog };
    case types.SHOW_MENU:
      return { ...state, showMenu: action.showMenu };
    case types.SHOW_AUDIOMENU:
      return { ...state, showAudioMenu: action.showAudioMenu };
    case types.SHOW_MIC_DISALLOW_MENU:
      return { ...state, showMicDisallowMenu: action.showMicDisallowMenu };
    case types.SHOW_SHARE_DISALLOW_MENU:
      return { ...state, showShareDisallowMenu: action.showShareDisallowMenu };
    case types.SHOW_MORE_MENU:
      return { ...state, showMoreMenu: action.showMoreMenu };
    case types.SHOW_HIDE_MEETING_SHARING:
      return { ...state, sharing: action.sharing };
    case types.ENTER_EXIT_FULL_SCREEN:
      return { ...state, fullScreen: action.data };
    case types.SHOW_CALLOUT_STATUS:
      return {
        ...state,
        callOut: {
          phoneNumber: action.callOut.phoneNumber,
          showStatus: action.callOut.showStatus,
          seq: action.callOut.seq,
        },
      };

    case types.UPDATE_CALLING_STATUS:
      return { ...state, isCalling: action.isCalling };

    case types.SHOW_INVITE_STATUS:
      return {
        ...state,
        invite: {
          phoneNumber: action.invite.phoneNumber,
          showIndication: action.invite.showIndication,
          seq: action.invite.seq,
        },
      };

    case types.SET_PANEL_VIEW_STATE: {
      const newPanelViewState = {
        ...state.panelViewState,
        ...action.payload,
      };
      const { currentGroupList, currentWindowList } = newPanelViewState;
      const diff = _.union(currentGroupList, currentWindowList);
      // maybe need to optimize to only check the diffs panel
      const newPanelViewResult = diff
        .concat(PanelName.rightPanelContainer)
        .reduce((s, v) => {
          const ret = checkPanelType(newPanelViewState, v);
          Object.assign(s, { [v]: ret });
          return s;
        }, {});
      return {
        ...state,
        panelViewState: newPanelViewState,
        panelViewResult: newPanelViewResult,
      };
    }

    case types.ADD_INVITE_SEQ: {
      const states = { ...state };
      states.inviteSeq.push(action.data);
      return states;
    }
    case types.DELETE_INVITE_SEQ: {
      const states2 = { ...state };
      for (let i = 0; i < states2.inviteSeq.length; i++) {
        if (states2.inviteSeq[i].seq === action.data.seq) {
          states2.inviteSeq.splice(i, 1);
        }
      }
      return states2;
    }
    case types.UPDATE_INVITE_SEQ: {
      const states3 = { ...state };
      states3.inviteSeq.forEach((item) => {
        if (item.seq === action.data.seq) {
          item.result = action.data.result;
        }
      });
      return states3;
    }
    case types.INVITE_OTHERS:
      return {
        ...state,
        invite: {
          phoneNumber: action.data.phoneNumber,
          showIndication: action.data.showIndication,
          seq: action.data.seq,
        },
      };

    case types.WS_AUDIO_CANCEL_DIALOUT_REQ:
      return {
        ...state,
        invite: {
          showIndication: false,
          seq: action.message.seq,
        },
      };

    case types.SHOW_MEETING_LOCK_TIP:
      return { ...state, showMeetingLockedTip: action.showMeetingLockedTip };

    case types.COPY_EMAIL:
      return { ...state, copied: action.copied };

    case types.COPY_URL:
      return { ...state, copiedURL: action.copied };

    case types.REMEMBER_PN_CHECK:
      return { ...state, rememberPNChecked: action.rememberPNChecked };

    case types.REMEMBER_LOCK_CHECK:
      return { ...state, rememberLockChecked: action.rememberLockChecked };

    case types.IS_ON_HOLD: {
      const meetingHeadType = action.bHold
        ? ms.MEETING_HEAD_TYPE_PUT_ON_HOLD
        : ms.MEETING_HEAD_TYPE_NORMAL;
      return {
        ...state,
        isOnHold: action.bHold,
        isOnHoldDefaultTrue: action.bHold,
        meetingHeadType, // waiting room only exist in normal meeting
      };
    }

    case types.SHOW_SUSPENSION_WINDOW: {
      const newState = { ...state };
      newState[`${action.data.name}Sus`] = action.data.isOpen;
      return newState;
    }

    case types.CHANGE_PERVIEW_NUM:
      return { ...state, perViewNum: action.data };

    case types.WEB_CLIENT_IS_FOCUSED:
      return { ...state, isWebClientFocused: action.data };

    case types.SET_WINDOW_ZINDEX:
      return { ...state, windowZIndex: action.data };

    case types.SHOW_NO_SUPPORT_INFO:
      return { ...state, showNoSupportInfo: action.data };

    case types.SET_RIGHT_CONTAINER_WIDTH:
      return { ...state, rightContainerWidth: action.data };

    case types.IS_RESIZE_WINDOW:
      return { ...state, isResizeWindow: action.data };
    case types.SET_MERGE_AUDIO_VISIBLE: {
      const { visible, type, participant, position } = action.data;
      if (visible) {
        return {
          ...state,
          mergeAudioVisible: visible,
          mergeAudioType: type,
          mergedParticipant: participant,
          mergedWindownPosition: position,
        };
      }
      return {
        ...state,
        mergeAudioVisible: visible,
        mergeAudioType: null,
        mergedParticipant: null,
        mergedWindownPosition: null,
      };
    }

    case types.SET_SHOW_WAITING_ROOM_NOTIFICATION:
      return { ...state, showWaitingRoomNotification: action.data };
    case types.SET_AV_DISABLED_TIP_VISIBLE:
      return { ...state, avDisabledTipVisible: action.data };

    case types.SET_SHOW_NEW_FEATURE_NOTIFICATION:
      return { ...state, showNewFeatureNotification: action.data };

    case types.SET_SIZE_SCALE:
      return { ...state, sizeScale: action.data };
    case types.SET_SHOW_SHARE_PRONOUN_NOTIFICATION:
      return { ...state, showSharePronounNotification: action.data };
    case types.ADD_LOADING_NOTIFICATION: {
      const { loadingNotificationValues, ...rest } = state;
      return {
        ...rest,
        loadingNotificationValues: [...loadingNotificationValues, action.data],
      };
    }
    case types.REMOVE_LOADING_NOTIFICATION: {
      const { loadingNotificationValues, ...rest } = state;
      return {
        ...rest,
        loadingNotificationValues: loadingNotificationValues.filter(
          (value) => value !== action.data,
        ),
      };
    }
    case types.TOGGLE_NOT_JOINED_LIST: {
      return { ...state, inviteeListVisible: !state.inviteeListVisible };
    }
    case types.TOGGLE_WEBGL_LOST_MENU: {
      return { ...state, isWebglLostMenuDisable: action.data };
    }
    case types.SHOW_RECLAIM_HOST_NOTIFICATION: {
      return { ...state, showReclaimHostNotification: action.data };
    }
    case types.TOGGLE_VIEW_BTN_FOCUSED: {
      return { ...state, isViewButtonFocused: action.data };
    }
    case types.SET_IS_MINIMIZE_MODE: {
      return { ...state, isMinimizeMode: action.data };
    }
    case types.SET_IS_PIP_MODE: {
      return { ...state, isPIPMode: action.data };
    }

    case types.SET_CONSENT_POSITION: {
      return {
        ...state,
        consents: { ...state.consents, position: action.data },
      };
    }
    case types.SET_NEW_WAITING_ROOM_FLOW_LOADING: {
      return {
        ...state,
        newWaitingRoomFlowLoading: action.data,
      };
    }
    case types.TOGGLE_IS_UI_LAYOUT_CLEANED: {
      return { ...state, isUILayoutCleaned: action.data };
    }
    default:
      return state;
  }
}

export const setConsentPosition = (position) => {
  return (dispatch) => {
    dispatch(
      meetingUI({
        type: types.SET_CONSENT_POSITION,
        data: position,
      }),
    );
  };
};

export const setShowWaitingRoomNotificationVisible = (visible) => {
  return (dispatch) => {
    dispatch(
      meetingUI({
        type: types.SET_SHOW_WAITING_ROOM_NOTIFICATION,
        data: visible,
      }),
    );
  };
};

export const setShowNewFeatureNotification = (visible) => {
  return (dispatch) => {
    dispatch(
      meetingUI({
        type: types.SET_SHOW_NEW_FEATURE_NOTIFICATION,
        data: visible,
      }),
    );
  };
};

export const setShowReactionAnimationNewFeatureNotification = (visible) => {
  return (dispatch) => {
    dispatch(
      meetingUI({
        type: types.SET_SHOW_REACTION_ANIMATION_NEW_FEATURE_NOTIFICATION,
        data: visible,
      }),
    );
  };
};

export const setSizeScale = (data) => {
  return (dispatch) => {
    dispatch(
      meetingUI({
        type: types.SET_SIZE_SCALE,
        data,
      }),
    );
  };
};

export const setSharePronounNotificationVisible = (visible) => {
  return (dispatch) => {
    dispatch(
      meetingUI({
        type: types.SET_SHOW_SHARE_PRONOUN_NOTIFICATION,
        data: visible,
      }),
    );
  };
};

export const setWebglLostMenuDisable = (disable) => {
  return (dispatch) => {
    dispatch(
      meetingUI({
        type: types.TOGGLE_WEBGL_LOST_MENU,
        data: disable,
      }),
    );
  };
};
