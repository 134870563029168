import {
  VIDEO_LAYOUT_GALLERY_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW,
  VIDEO_LAYOUT_SPEAK_VIEW,
  VIDEO_LAYOUT_STANDARD_VIEW,
} from '../../features/video/enum';

export const VideoLayout = {
  [VIDEO_LAYOUT_SPEAK_VIEW]: 'speaker',
  [VIDEO_LAYOUT_GALLERY_VIEW]: 'gallery',
  [VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW]: 'speaker',
  [VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW]: 'gallery',
  [VIDEO_LAYOUT_STANDARD_VIEW]: 'immersive',
};
