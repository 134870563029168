import React from 'react';
import LazyLoader from '../../../components/LazyLoader.js';

function lazyLoadComponent(setComponent) {
  return import(
    /* webpackChunkName: "ReportProblemContent" */
    './report-problem-dialog-content.js'
  ).then(setComponent);
}
function ReportProblemContentLazyLoad(props) {
  return <LazyLoader load={lazyLoadComponent} {...props}></LazyLoader>;
}

export default ReportProblemContentLazyLoad;
