import { setNetworkQualityMap } from '../video-action';
import { sendSocketMessage } from '../../../../actions/SocketActions';
import * as socketEventTypes from '../../../../constants/ZoomSocketEventTypes';

export const updateOtherNetworkQuality = (data) => (dispatch) => {
  const { nLevel, nUserID } = data || {};
  if (nUserID) {
    dispatch(
      setNetworkQualityMap({
        userId: nUserID,
        data: {
          uplink: {
            level: nLevel,
          },
        },
      }),
    );
  }
};

export const sendSelfNetworkQualityToRwg = (data) => (dispatch, getState) => {
  const {
    meeting: {
      currentUser: { userId },
    },
    video: { networkQualityMap },
  } = getState();
  if (!data || !userId) return;
  const { bwLevel, isUplink, networkLevel } = data;
  const prevQuality = networkQualityMap[userId];
  dispatch(
    setNetworkQualityMap({
      userId,
      data: {
        [isUplink ? 'uplink' : 'downlink']: {
          level: networkLevel,
          bwLevel,
          isMe: true,
        },
      },
    }),
  );
  const shouldSendQuality =
    isUplink &&
    (prevQuality && prevQuality.uplink
      ? prevQuality.uplink.level !== networkLevel ||
        prevQuality.uplink.bwLevel !== bwLevel
      : true);
  if (shouldSendQuality) {
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_BROADCAST_USER_NETWORK_QUALITY_REQ,
        body: {
          type: isUplink,
          level: networkLevel,
          bwLevel,
        },
      }),
    );
  }
};
