import meetingConfig from 'meetingConfig';
import { SHARER_PERMISSION_ENUM, SHARER_PERMISSION_CODE } from './enum';
import { decodeBase64ToBuffer, isMSFTMode } from '../../global/util';
import { easyStore, storeType } from '../../global/easy-store';
import {
  SHARING_ADD_REV_CHANNEL_TYPE,
  SHARING_REMOVE_REV_CHANNEL_TYPE,
} from '../../constants/AVNotifyMediaSDKTypes';
import { LOCALSTORAGE_KEYS, SESSIONSTORAGE_KEYS } from '../../global/constant';
import * as AVNotifyMediaSDKTypes from '../../constants/AVNotifyMediaSDKTypes';
import AliveToast from '../../global/containers/notification-manager/alive-toast';
import { UNABLE_VIEW_SHARE_FROM_MAINSESSION_TEXT } from './resource';
import { globalVariable } from '../../global/global-variable';

export const isHideMutedWhileSharingTips = () => {
  return easyStore.easyGet(
    LOCALSTORAGE_KEYS.webClient_is_hide_muted_while_sharing_tips,
  );
};

export const isHideMutedWhileSharingTips2 = () => {
  return easyStore.easyGet(
    LOCALSTORAGE_KEYS.webClient_is_hide_muted_while_sharing_tips2,
  );
};

const getResolvedOutboudingSharingsharerPermissionCode = (
  permissionArgs,
  id,
  curChecked,
) => {
  const resultCodeMap = {
    [`${SHARER_PERMISSION_ENUM.HOST_SHARE}_${SHARER_PERMISSION_ENUM.HOST_GRAB}`]:
      SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_LOCK_SHARE,
    [`${SHARER_PERMISSION_ENUM.HOST_SHARE}_${SHARER_PERMISSION_ENUM.ALL_GRAB}`]:
      SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_LOCK_SHARE,
    [`${SHARER_PERMISSION_ENUM.ALL_SHARE}_${SHARER_PERMISSION_ENUM.HOST_GRAB}`]:
      SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_HOST_GRAB,
    [`${SHARER_PERMISSION_ENUM.ALL_SHARE}_${SHARER_PERMISSION_ENUM.ALL_GRAB}`]:
      SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_ANYONE_GRAB,
  };

  let key1;
  let key2;

  if (_.find(permissionArgs[0].cb, { id })) {
    key1 = permissionArgs[0].cb.reduce((key, cur) => {
      if (curChecked) {
        cur.checked = cur.id === id;
      }

      return cur.checked ? cur.id : key;
    }, _.find(permissionArgs[0].cb, { checked: true }).id || SHARER_PERMISSION_ENUM.HOST_SHARE);
  } else {
    key1 = _.find(permissionArgs[0].cb, { checked: true }).id;
  }

  if (_.find(permissionArgs[1].cb, { id })) {
    key2 = permissionArgs[1].cb.reduce((key, cur) => {
      if (curChecked) {
        cur.checked = cur.id === id;
      }
      return cur.checked ? cur.id : key;
    }, _.find(permissionArgs[1].cb, { checked: true }).id || SHARER_PERMISSION_ENUM.HOST_GRAB);
  } else {
    key2 = _.find(permissionArgs[1].cb, { checked: true }).id;
  }
  const result = resultCodeMap[`${key1}_${key2}`];
  return result;
};

function getShareCaptureMode() {
  /**
   * MediaSdk will capture the share raw data in the new way using image capture
   */
  let mode = 1;
  if (isMSFTMode()) {
    /**
     * MediaSdk captures the share data in the old way
     */
    mode = 0;
  }
  return mode;
}

export const hasShareToBOConfirmDialogAlert = () => {
  return easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_hasAlertSharingToBOConfirm,
  );
};
export const setHasShareToBOConfirmDialogAlert = () => {
  easyStore.easySet(
    SESSIONSTORAGE_KEYS.webClient_hasAlertSharingToBOConfirm,
    true,
    storeType.sessionStorage,
  );
};
export const clearHasShareToBOConfirmDialogAlert = () => {
  easyStore.easyRemove(
    SESSIONSTORAGE_KEYS.webClient_hasAlertSharingToBOConfirm,
  );
};

export const addReceiveSharingChannelForSDK = ({
  ssrc,
  streamIndex,
  videoMode,
  isFromMainSession,
}) => {
  globalVariable.avSocket.sendSocket(SHARING_ADD_REV_CHANNEL_TYPE, {
    isFromMainSession,
    ssrc,
    streamIndex,
    videoMode,
  });
};

export const removeReceiveSharingChannelForSDK = (ssrc, isFromMainSession) => {
  globalVariable.avSocket.sendSocket(SHARING_REMOVE_REV_CHANNEL_TYPE, {
    isFromMainSession: +isFromMainSession,
    ssrc,
  });
};

export const updateUserRosterInfoToSDK = ({
  addUsers = [],
  removeUserIds = [],
  mediaActionType,
  isFromMainSession,
}) => {
  globalVariable.avSocket.sendSocket(
    AVNotifyMediaSDKTypes.USER_NODE_LIST_IN_MAIN_SESSION,
    {
      isFromMainSession: +isFromMainSession,
      mediaActionType,
      body: {
        add: addUsers.map((user) => {
          const sn = user.zoomID;
          const userid = user.id || user.userId;
          return {
            userid,
            sn: sn && decodeBase64ToBuffer(sn),
          };
        }),
        remove: removeUserIds,
      },
    },
  );
};

export const enableOptimizeVideoClipSDK = (enable) => {
  // mode
  // export const SHARING_NULL = 0; //sharing undefined
  // export const SHARING_NORMAL = 1;
  // export const SHARING_VIDEO_MODE = 2;
  globalVariable.avSocket.sendSocket(
    AVNotifyMediaSDKTypes.SWITCH_SHARING_TYPE,
    {
      mode: enable ? 2 : 1,
    },
  );
};

export {
  getResolvedOutboudingSharingsharerPermissionCode,
  getShareCaptureMode,
};

export const alertUnableToViewShareFromMainSessionTip = () => {
  AliveToast.uniqueToast({
    message: UNABLE_VIEW_SHARE_FROM_MAINSESSION_TEXT,
    aliveTime: 0,
    showClose: true,
    name: 'unable_to_view_share',
  });
};

export const closeUnableToViewShareFromMainSessionTip = () => {
  AliveToast.close('unable_to_view_share');
};

export const isSupportMultiShare = () => {
  if (meetingConfig.meetingOptions.isEnableRemoteSupport) {
    return false;
  }
  return true;
};
