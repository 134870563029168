import { SHOW_WINDOW_TYPE_LIMIT } from '../constant';
import * as types from '../../constants/MeetingActionTypes';

export const setPanelViewState = (payload) => {
  return {
    type: types.SET_PANEL_VIEW_STATE,
    payload,
  };
};

export const PanelName = {
  chat: 'chat',
  participant: 'participant',
  fullTranscription: 'fullTranscription',
  zoomAppLauncher: 'zoomAppLauncher',
  sidecar: 'sidecar',
  QA: 'QA',
  QA2: 'QA2',
  manualCaption: 'manualCaption',
  rightPanelContainer: 'rightPanelContainer',
  meetingQueriesChat: 'meetingQueriesChat',
};

export const PanelViewType = {
  notExist: 'notExist',
  fullView: 'fullView',
  partView: 'partView',
  windowView: 'windowView',
  miniView: 'miniView',
  rightPanelView: 'rightPanelView',
};
export const DefaultViewType = {
  [PanelName.QA]: PanelViewType.windowView,
  [PanelName.QA2]: PanelViewType.windowView,
};
export const panelOnlyList = [PanelName.sidecar, PanelName.zoomAppLauncher];
export const windowOnlyList = [PanelName.manualCaption];
// don't care what group name is, group name just a index
const rightPanelGroup = {
  groupA: [PanelName.participant, PanelName.chat],
  groupB: [PanelName.fullTranscription],
  groupC: [PanelName.sidecar],
  groupD: [PanelName.QA2],
  groupE: [PanelName.QA],
  group5: [PanelName.zoomAppLauncher],
  group6: [PanelName.meetingQueriesChat],
};

export const convertAllPanelToWindowIfSizeLimited =
  () => (dispatch, getState) => {
    const {
      meetingUI: {
        windowSize,
        panelViewState: { currentGroupList, currentWindowList },
      },
    } = getState();
    const data = {};
    if (windowSize.width < SHOW_WINDOW_TYPE_LIMIT) {
      data.currentGroupType = '';
      data.currentGroupList = currentGroupList.filter((v) =>
        panelOnlyList.includes(v),
      );
      data.currentWindowList = currentWindowList.concat(
        currentGroupList.filter((v) => !panelOnlyList.includes(v)),
      );
      dispatch(setPanelViewState(data));
    }
  };

export const convertToWindowOrPanelByUser =
  (panelName, forceBePanelOrWindow) => (dispatch, getState) => {
    const {
      meetingUI: {
        windowSize,
        panelViewState: {
          currentWindowList,
          currentGroupList,
          currentGroupType,
        },
      },
    } = getState();
    const isWindowNow = currentWindowList.includes(panelName);
    const isPanel = currentGroupList.includes(panelName);

    const shouldBePanel =
      isWindowNow &&
      !windowOnlyList.includes(panelName) &&
      forceBePanelOrWindow !== false;

    const shouldBeWindow =
      isPanel &&
      !panelOnlyList.includes(panelName) &&
      forceBePanelOrWindow !== true;
    const switchPanelOrWindow = (panelViewType) => {
      if (panelViewType === PanelViewType.rightPanelView) {
        if (
          currentGroupType &&
          rightPanelGroup[currentGroupType].includes(panelName)
        ) {
          dispatch(
            setPanelViewState({
              currentWindowList: currentWindowList.filter(
                (v) => v !== panelName,
              ),
              currentGroupList: currentGroupList.concat(panelName),
            }),
          );
        } else {
          const groupName = findGroupIncludes(panelName);
          dispatch(
            setPanelViewState({
              currentGroupType: groupName,
              currentGroupList: [panelName],
              currentWindowList: currentWindowList.filter(
                (v) => v !== panelName,
              ),
            }),
          );
        }
      } else {
        const rest = currentGroupList.filter((v) => v !== panelName);
        dispatch(
          setPanelViewState({
            currentGroupType: rest.length === 0 ? '' : currentGroupType,
            currentGroupList: rest,
            currentWindowList: currentWindowList.concat(panelName),
          }),
        );
      }
    };
    if (windowSize.width > SHOW_WINDOW_TYPE_LIMIT) {
      if (shouldBePanel) {
        switchPanelOrWindow(PanelViewType.rightPanelView);
      } else if (shouldBeWindow) {
        switchPanelOrWindow(PanelViewType.windowView);
      }
    } else if (shouldBeWindow) {
      switchPanelOrWindow(PanelViewType.windowView);
    }
  };

export const clearRightPanel = () =>
  setPanelViewState({
    currentGroupType: '',
    currentGroupList: [],
  });

export const clearAllPanel = () =>
  setPanelViewState({
    currentWindowList: [],
    currentGroupType: '',
    currentGroupList: [],
  });
const changeExistPanelState =
  (panelName, hopeState) => (dispatch, getState) => {
    const {
      meetingUI: {
        panelViewState: {
          currentGroupType,
          currentGroupList,
          currentWindowList = [],
        },
      },
    } = getState();
    // check if it is the existed window
    if (currentWindowList.includes(panelName)) {
      if (hopeState === true) {
        return;
      }
      dispatch(
        setPanelViewState({
          currentWindowList: currentWindowList.filter((v) => v !== panelName),
        }),
      );
      return;
    }

    // check if it is the window only panel
    if (windowOnlyList.includes(panelName)) {
      if (hopeState === false) {
        return;
      }
      dispatch(
        setPanelViewState({
          currentWindowList: currentWindowList.concat(panelName),
        }),
      );
      return;
    }

    // check if it is existed right panel
    if (currentGroupList.includes(panelName)) {
      if (hopeState === true) {
        return;
      }
      const newGroupList = currentGroupList.filter((v) => v !== panelName);
      const payload = {};
      payload.currentGroupList = newGroupList;
      if (newGroupList.length === 0) {
        payload.currentGroupType = '';
      }
      dispatch(setPanelViewState(payload));
      return;
    }

    // check if its' type is current group type
    if (
      currentGroupType &&
      rightPanelGroup[currentGroupType].includes(panelName)
    ) {
      if (hopeState === false) {
        return;
      }
      dispatch(
        setPanelViewState({
          currentGroupList: currentGroupList.concat(panelName),
        }),
      );
      return;
    }

    return true;
  };
const findGroupIncludes = (panelName) => {
  const item = Object.entries(rightPanelGroup).find(([, groupList]) => {
    return groupList.includes(panelName);
  });
  const [groupName] = item || [];
  return groupName;
};
export const changePanelViewState = (panelName, hopeState) => {
  return (dispatch, getState) => {
    const {
      meetingUI: {
        windowSize,
        panelViewState: {
          // currentGroupType,
          // currentGroupList,
          currentWindowList = [],
        },
      },
    } = getState();

    const setNewGroupPanel = () => {
      const groupName = findGroupIncludes(panelName);
      if (!groupName || hopeState === false) {
        return;
      }
      // it should be show as panel
      if (
        (panelOnlyList.includes(panelName) ||
          windowSize.width > SHOW_WINDOW_TYPE_LIMIT) &&
        DefaultViewType[panelName] !== PanelViewType.windowView
      ) {
        dispatch(
          setPanelViewState({
            currentGroupType: groupName,
            currentGroupList: [panelName],
          }),
        );
      } else {
        // it should show as window
        dispatch(
          setPanelViewState({
            currentWindowList: currentWindowList.concat(panelName),
          }),
        );
      }
    };

    const notExist = dispatch(changeExistPanelState(panelName, hopeState));
    // check the groups and find the new group
    if (notExist) {
      setNewGroupPanel();
    }
  };
};

export const getPanelViewState = (state, name) => {
  return state.meetingUI.panelViewResult[name];
};

export const showParticipantWindow = (state) => {
  return (
    getPanelViewState(state, PanelName.participant) === PanelViewType.windowView
  );
};

export const showParticipantPanel = (state) => {
  const ret = getPanelViewState(state, PanelName.participant);
  return ret && ret !== PanelViewType.windowView;
};

export const isShowSidecar = (state) => {
  return getPanelViewState(state, PanelName.sidecar);
};

export const chatViewType = (state) => {
  const ret = getPanelViewState(state, PanelName.chat);
  if (ret && ret !== PanelViewType.windowView) {
    if (state.meetingUI.minimizedPanel === PanelName.chat) {
      return PanelViewType.miniView;
    }
  }
  return ret;
};
