import { createActions } from 'redux-actions';

export const {
  changeTab,
  setExpandedStatus,
  setQaList,
  setTextBoxState,
  updateQa,
  updateQaOfflineAnswer,
  clearQaData,
  toggleQaWidget,
  setBAllowAnonymousQuestion,
  setBAllowAttendeeViewAllQuestion,
  setBAllowAttendeeUpvoteQuestion,
  setBAllowAttendeeCommentQuestion,
  setSortType,
  qaReset,
  setEnableQuestion,
  updateWebinarQAState,
} = createActions(
  'UPDATE_WEBINAR_Q_A_STATE',
  'CHANGE_TAB',
  'SET_EXPANDED_STATUS',
  'SET_QA_LIST',
  'SET_TEXT_BOX_STATE',
  'UPDATE_QA',
  'UPDATE_QA_OFFLINE_ANSWER',
  'CLEAR_QA_DATA',
  'TOGGLE_QA_WIDGET',
  'SET_B_ALLOW_ANONYMOUS_QUESTION',
  'SET_B_ALLOW_ATTENDEE_VIEW_ALL_QUESTION',
  'SET_B_ALLOW_ATTENDEE_UPVOTE_QUESTION',
  'SET_B_ALLOW_ATTENDEE_COMMENT_QUESTION',
  'SET_SORT_TYPE',
  'QA_RESET',
  'SET_ENABLE_QUESTION',
);
