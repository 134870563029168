export const xmppControl = {
  strategy: [0, 10000, 30000, 50000, 70000],
  delay: [0, 600, 1200, 2400, 5000],
};

export function calcXmppUpdateDelay(userCount) {
  let xmppUpdateDelay = 0;

  if (userCount > xmppControl.strategy[xmppControl.strategy.length - 1]) {
    xmppUpdateDelay = xmppControl.delay[xmppControl.strategy.length - 1];
  } else {
    for (let index = 0; index < xmppControl.strategy.length - 1; index++) {
      if (
        xmppControl.strategy[index] < userCount &&
        userCount < xmppControl.strategy[index + 1]
      ) {
        xmppUpdateDelay = xmppControl.delay[index];
        break;
      }
    }
  }
  return xmppUpdateDelay;
}

export class Throttler {
  constructor(callback, initialWait) {
    this.callback = callback;
    this.wait = initialWait;
    this.lastCallTime = 0;
    this.timer = null;
  }

  setTick(newWait) {
    this.wait = newWait;
  }

  throttle() {
    const now = Date.now();

    if (now - this.lastCallTime >= this.wait) {
      this.callback();
      this.lastCallTime = now;

      // clear pending trailing call
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    } else {
      // if in throttled, set trailing callback
      if (!this.timer) {
        const remainingTime = this.wait - (now - this.lastCallTime);
        this.timer = setTimeout(() => {
          this.callback();
          this.lastCallTime = Date.now();
          this.timer = null;
        }, remainingTime);
      }
    }
  }

  clear() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.lastCallTime = 0;
  }
}
