import { handleActions } from 'redux-actions';
import { produce } from 'immer';
// import { findLast, last } from 'lodash';
import {
  updateNewMeetingChat,
  changeReadNewChatState,
  cleanUnreadNewChat,
  hideChatPersistentTip,
  deleteNewChat,
  // setSelfNewChatId,
  resetNewChat,
  setValidChannels,
  pushSysInfo,
  setChatPMCTipShow,
  toggleThread,
  updateNewMeetingChatFile,
  updateNewChatIndexes,
  updateThreadDraft,
  removeThreadInput,
  clearNewChat,
  setLockTime,
  setIsPinBottom,
  addVoteEmoji,
  removeVoteEmoji,
  updateEditFlag,
  addThreadInput,
  saveLatestMessage,
  saveRtfDraft,
  toggleBottomFormat,
  updateOneChatMessage,
  setQuoteText,
  addPmcHeader,
  setSelectedFiles,
} from './new-chat-action';
import {
  CHAT_CONSTANTS,
  CHAT_THREAD,
  LOCALSTORAGE_KEYS,
} from '../../../global/constant';
// import { CHAT_FILE_TYPE } from '../../chat/constants';
import { ChatDeleted } from '../resource';

const defaultShape = {
  meetingChat: [],
  newIndexes: [],
  isHideChatPersistentTip: easyStore.easyGet(
    LOCALSTORAGE_KEYS.isHideChatPersistentTip,
  ),
  validChatChannels: 'initial',
  editChatFlag: 1,
  isChatPMCTipCouldShow:
    (easyStore.easyGet('chatPersistentButtonCount') || 0) < 3 &&
    !easyStore.easyGet('chatPersistentButtonClicked'),
  lockTime: 0,
  isPinBottom: false,
  latestMessage: null,
  bottomBoxFormatVisible: false,
  rtfDraft: {
    // text: '',
    // mention: [{ start, end, jid, type }],
    // styleItems: [],
  },
  selectedFiles: [],
};
/* chatMsgs: message[] */

const newChat = handleActions(
  {
    [setSelectedFiles]: produce((draft, { payload }) => {
      draft.selectedFiles = payload;
    }),
    [setQuoteText]: produce((draft, { payload }) => {
      const { isBottom, quoteText, index } = payload;
      if (isBottom) {
        draft.rtfDraft.quoteText = quoteText;
      } else {
        draft.meetingChat[index].messageDraft.quoteText = quoteText;
      }
    }),
    [updateOneChatMessage]: produce((draft, { payload }) => {
      const { item, index } = payload;
      draft.meetingChat[index] = item;
    }),
    [toggleBottomFormat]: produce((draft, { payload }) => {
      draft.bottomBoxFormatVisible = payload;
    }),
    [saveRtfDraft]: produce((draft, { payload }) => {
      const { text, mention, styleItems } = payload;
      draft.rtfDraft.text = text;
      draft.rtfDraft.mention = mention;
      draft.rtfDraft.styleItems = styleItems;
    }),
    [saveLatestMessage]: produce((draft, { payload }) => {
      draft.latestMessage = payload;
    }),
    [updateEditFlag]: produce((draft) => {
      draft.editChatFlag += 1;
    }),
    [addVoteEmoji]: produce((draft, action) => {
      const { emojiStr, targetMsgIndex, data, selfSent } = action.payload;
      const voteMap = draft.meetingChat[targetMsgIndex].voteMap || new Map();
      let voteCount = draft.meetingChat[targetMsgIndex].voteCount || 0;
      if (!voteMap.get(emojiStr)) {
        voteMap.set(emojiStr, { list: [data], selfSent });
      } else {
        const oldValues = voteMap.get(emojiStr);
        const isDuplicatedVoteEmoji =
          oldValues.list.findIndex(
            ({ senderJid }) => senderJid === data.senderJid,
          ) >= 0;
        if (isDuplicatedVoteEmoji) {
          return;
        }
        oldValues.list.push(data);
        if (selfSent) {
          oldValues.selfSent = selfSent;
        }
        voteMap.set(emojiStr, oldValues);
        // voteMap.get(emojiStr).list.push(data);
        // voteMap.get(emojiStr).selfSent = selfSent;
      }
      voteCount += 1;
      draft.meetingChat[targetMsgIndex].voteMap = voteMap;
      draft.meetingChat[targetMsgIndex].voteCount = voteCount;
      return draft;
    }),
    [removeVoteEmoji]: produce((draft, action) => {
      const { emojiStr, targetMsgIndex, data, selfRemoved } = action.payload;
      const voteMap = draft.meetingChat[targetMsgIndex].voteMap;
      const oldData = voteMap.get(emojiStr);
      if (oldData) {
        let { selfSent, list: oldList } = voteMap.get(emojiStr);
        const foundedIndex = oldList.findIndex(
          (i) =>
            i.senderJid.toLowerCase() === data.senderJid.toLowerCase() || // jid mixed case
            i.senderSN === data.senderSN,
        );
        if (foundedIndex !== -1) {
          oldList.splice(foundedIndex, 1);
          if (selfRemoved) {
            selfSent = false;
          }
          if (oldList.length === 0) {
            voteMap.delete(emojiStr);
          } else {
            voteMap.set(emojiStr, { selfSent, list: oldList });
          }
          draft.meetingChat[targetMsgIndex].voteMap = voteMap;
          draft.meetingChat[targetMsgIndex].voteCount -= 1;
          return draft;
        }
      }
    }),
    [setIsPinBottom]: produce((draft, { payload }) => {
      draft.isPinBottom = payload;
    }),
    [setLockTime]: produce((draft, { payload = 0 }) => {
      draft.lockTime = payload;
    }),
    [clearNewChat]: produce((draft) => {
      draft.meetingChat = [];
      draft.newIndexes = [];
      return draft;
    }),

    [toggleThread]: produce((draft, { payload }) => {
      const { index, val } = payload;
      draft.meetingChat[index].fold = val;
      draft.meetingChat[index].truncateCount = 0;
    }),
    [addThreadInput]: produce((draft, { payload }) => {
      const { item, mainMessageIndex, insertInputIndex } = payload;
      draft.meetingChat[mainMessageIndex].hasInput = true;
      draft.meetingChat.splice(insertInputIndex, 0, item);
    }),
    [removeThreadInput]: produce((draft, { payload }) => {
      const { removeIndex, mainMessageIndex } = payload;
      if (!draft.meetingChat[removeIndex]) return;
      if (draft.meetingChat[removeIndex].cat !== CHAT_CONSTANTS.INPUT) return;
      draft.meetingChat[mainMessageIndex].hasInput = false;
      draft.meetingChat.splice(removeIndex, 1);
    }),
    [updateThreadDraft]: produce((draft, { payload }) => {
      const { index, val } = payload;
      draft.meetingChat[index].messageDraft.text = val.text;
      draft.meetingChat[index].messageDraft.mention = val.mention;
      draft.meetingChat[index].messageDraft.styleItems = val.styleItems;
    }),
    [resetNewChat]: produce((draft) => {
      draft.meetingChat = [];
    }),
    [setValidChannels]: produce((draft, { payload }) => {
      draft.validChatChannels = payload;
    }),
    [setChatPMCTipShow]: produce((draft, { payload }) => {
      draft.isChatPMCTipCouldShow = payload;
    }),
    [updateNewChatIndexes]: produce((draft, { payload }) => {
      draft.newIndexes.push(...(Array.isArray(payload) ? payload : [payload]));
    }),
    [updateNewMeetingChat]: produce((draft, { payload }) => {
      const { newMeetingChat } = payload;
      draft.meetingChat = newMeetingChat;
    }),
    [updateNewMeetingChatFile]: produce((draft, { payload }) => {
      const { index, file } = payload;
      draft.meetingChat[index].xmppMsgId = file.xmppMsgId;
      draft.meetingChat[index].content.file = file;
    }),
    [addPmcHeader]: produce((draft, { payload }) => {
      const infos = Array.isArray(payload) ? payload : [payload];
      draft.meetingChat.unshift(...infos);
    }),
    [pushSysInfo]: produce((draft, { payload }) => {
      const infos = Array.isArray(payload) ? payload : [payload];
      draft.meetingChat.push(...infos);
    }),
    [cleanUnreadNewChat]: produce((draft) => {
      // const { meetingChat } = draft;
      // meetingChat.forEach((item) => (item.read = true));
      draft.newIndexes = [];
    }),
    [changeReadNewChatState]: produce((draft, { payload }) => {
      // const { meetingChat } = draft;
      // payload.forEach((item) => {
      //   const index = item.key;
      //   meetingChat[index].read = item.read;
      // });
      let { newIndexes, meetingChat } = draft;
      payload.forEach((item) => {
        meetingChat[item.key].read = item.read;
        const updateItemMsgId = meetingChat[item.key].msgId;
        const pIndex = newIndexes.findIndex((i) => {
          return i.msgId === updateItemMsgId;
        });
        if (pIndex !== -1) {
          // delete newIndexes[pIndex];
          newIndexes[pIndex].deleted = true;
        }
      });
      draft.newIndexes = newIndexes.filter((item) => !item.deleted);
    }),
    [hideChatPersistentTip]: produce((draft, { payload }) => {
      draft.isHideChatPersistentTip = payload;
    }),
    // [setSelfNewChatId]: produce((draft, { payload }) => {
    // new chat no need setSelfNewChatId
    // const { msgID, userId, fileID } = payload;
    // if (!fileID) {
    //   const chatGroup = _.findLast(
    //     draft.meetingChat,
    //     (v) => v.senderId === userId,
    //   );
    //   if (chatGroup) {
    //     const chatItem = _.findLast(chatGroup.chatMsgs, (v) => !v.msgId);
    //     if (chatItem) {
    //       chatItem.msgId = msgID;
    //     }
    //   }
    // } else {
    //   for (let i = 0; i < draft.meetingChat.length; i++) {
    //     const chatGroup = draft.meetingChat[i];
    //     const groupFirstMsg = chatGroup?.chatMsgs?.[0];
    //     if (
    //       groupFirstMsg &&
    //       groupFirstMsg.file &&
    //       !groupFirstMsg.msgId &&
    //       groupFirstMsg.file.fileID === fileID
    //     ) {
    //       groupFirstMsg.msgId = msgID;
    //       break;
    //     }
    //   }
    // }
    // }),
    [deleteNewChat]: produce((draft, { payload }) => {
      const result = deleteOneMessageById(draft.meetingChat, payload);
      if (result) {
        draft.meetingChat = result;
      }
      const pIndex = draft.newIndexes.findIndex((i) => {
        return i.msgId === payload;
      });
      if (pIndex !== -1) {
        // delete newIndexes[pIndex];
        // newIndexes[pIndex].deleted = true;
        draft.newIndexes.splice(pIndex, 1);
      }
      return draft;
    }),
  },
  defaultShape,
);

export function deleteOneMessageById(meetingChatList, msgId) {
  const index = meetingChatList.findIndex(
    (item) => item.msgId === msgId || item.xmppMsgId === msgId,
  );
  if (index === -1) return false;
  const chatItem = meetingChatList[index];
  if (!chatItem) {
    return false;
  }

  return produce(meetingChatList, (draft) => {
    if (chatItem.isThread || !chatItem.threadCount) {
      //! if a thread has input-box , should also remove input-box
      draft.splice(index, chatItem.hasInput ? 2 : 1);
      if (chatItem.isThread) {
        const mainIndex = draft.findIndex(
          (item) => item.msgId === chatItem.mainMsgId,
        );
        const mainChatItem = draft[mainIndex];
        // remove deleted header and input item together
        if (
          mainChatItem.cat === CHAT_CONSTANTS.DELETED &&
          mainChatItem.threadCount <= 1
        ) {
          draft.splice(mainIndex, mainChatItem.hasInput ? 2 : 1);
        } else {
          draft[mainIndex].threadCount -= 1;
        }

        // ! should update truncateCount
        if (mainChatItem.truncateCount && mainChatItem.truncateCount > 0) {
          const offsetFromMainChatItem = index - mainIndex;
          if (
            offsetFromMainChatItem &&
            offsetFromMainChatItem <= mainChatItem.truncateCount
          ) {
            draft[mainIndex].truncateCount -= 1;

            if (
              draft[mainIndex].truncateCount === 0 &&
              draft[mainIndex].fold === CHAT_THREAD.MORE
            ) {
              draft[mainIndex].fold = CHAT_THREAD.EXPAND;
            }
          }
        }
      }
    } else {
      draft[index].cat = CHAT_CONSTANTS.DELETED;
      draft[index].content.text = ChatDeleted;
      draft[index].voteMap?.clear();
    }
  });
}

export default newChat;
