import {
  FAR_END_CAMERA_BUTTON_ID,
  FAR_END_CAMERA_PTZ,
  PTZCameraRange,
} from './enum';
import _ from 'lodash';
import { setBitFlagAt } from '../../global/big-int';
const CONF_ID_SPLIT = ',';
const SUPPORT_USER_INFO_TOKEN = 1;
/**
 * @param {string} strList e.g. `confUserId1,confUserId2,` end with `CONF_ID_SPLIT`
 * @returns {Array<strConfUserID>} `Array<strConfUserID>`
 */
export const processGalleryOrderList = (strList) => {
  return strList.split(CONF_ID_SPLIT).filter(Boolean);
};

/**
 *
 * @param {string} mmrFeatureEx
 * @returns {boolean} bool server support or not
 */
export const isSupportSaveGalleryOrder = (mmrFeatureEx) => {
  return (
    mmrFeatureEx &&
    !!(BigInt(mmrFeatureEx) & setBitFlagAt(SUPPORT_USER_INFO_TOKEN))
  );
};

export function transformPtzConstraint(cmd, range) {
  let cKey = '',
    value = range;
  switch (cmd) {
    case FAR_END_CAMERA_BUTTON_ID.DOWN:
      value = -range;
      cKey = 'tilt';
      break;
    case FAR_END_CAMERA_BUTTON_ID.UP:
      cKey = 'tilt';
      break;
    case FAR_END_CAMERA_BUTTON_ID.LEFT:
      value = -range;
      cKey = 'pan';
      break;
    case FAR_END_CAMERA_BUTTON_ID.RIGHT:
      cKey = 'pan';
      break;
    case FAR_END_CAMERA_BUTTON_ID.ZOOM_IN:
      cKey = 'zoom';
      break;
    case FAR_END_CAMERA_BUTTON_ID.ZOOM_OUT:
      value = -range;
      cKey = 'zoom';
      break;
    default:
      break;
  }
  return {
    key: cKey,
    range: value,
  };
}

export function transformPtzRange(capability, value, diff) {
  const { max, min, step } = capability;
  const range = max - min;
  let tDiff = (diff / PTZCameraRange) * range;
  if (step) {
    tDiff = Math.min(Math.floor(tDiff / step) * step, Math.floor(tDiff));
  }
  let tValue = value + tDiff;
  tValue = Math.min(max, tValue);
  tValue = Math.max(min, tValue);
  return tValue;
}

export function getCurrentPtzConstraint(cameraPzt) {
  const constraints = ['pan', 'tilt', 'zoom'].reduce((payload, key) => {
    if (cameraPzt[key] && cameraPzt[key].value !== 0) {
      return {
        ...payload,
        [key]: cameraPzt[key].value,
      };
    }
    return payload;
  }, {});
  return _.isEmpty(constraints) ? null : constraints;
}

export function transformFarEndCameraPtzCapability({ nFECC }) {
  return {
    pan: (nFECC & FAR_END_CAMERA_PTZ.PAN) === FAR_END_CAMERA_PTZ.PAN,
    tilt: (nFECC & FAR_END_CAMERA_PTZ.TILT) === FAR_END_CAMERA_PTZ.TILT,
    zoom: (nFECC & FAR_END_CAMERA_PTZ.ZOOM) === FAR_END_CAMERA_PTZ.ZOOM,
  };
}

export function farEndCameraPtzCapability(info) {
  if (!info) return false;
  const { pan, tilt, zoom } = transformFarEndCameraPtzCapability(info);
  return pan || tilt || zoom;
}
