import { createAction } from 'redux-actions';
import {
  WS_CONF_ROSTER_INDICATION,
  SET_NOT_JOINED_LIST,
  TOGGLE_NOT_JOINED_LIST,
  CHANGE_NOT_JOINED_LIST,
} from '../../../constants/MeetingActionTypes';
import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { setShowWaitingRoomNotificationVisible } from '../../../reducers/MeetingUIReducer';

import { isInRoom } from '../../breakout-room/utils';
import { removeDuplicates } from '../../../reducers/AttendeesListReducer';
import { updateMainSessionAttendeeList } from '../../breakout-room/redux/bo-room-action';
import {
  decodeBase64,
  isTeslaMode,
  promptA11yInfo,
} from '../../../global/util';
import { isMeInRoomSelector } from '../../breakout-room/redux/bo-room-selector';
import { xmppServer } from '../../../components/XMPPSocket';
import {
  attendeesListSelector,
  waitingParticipantsSelector,
} from '../../../global/redux';
import {
  changePanelViewState,
  clearRightPanel,
  PanelName,
} from '../../../global/redux/set-panel-action';
import { checkRole } from '../../../global/service/user-types';
import _ from 'lodash';
import {
  userEnterWRTipFn,
  userJoinTipFn,
  userLeaveTipFn,
  userLeaveWRTipFn,
} from '../resource';

export function toggleShowParticipantsList(isVisible = undefined) {
  return changePanelViewState(PanelName.participant, isVisible);
}

// participantsNeedToUpdate: [{id: number, isBeingAdmitted: bool}]
export const updateBeingAdmitted =
  (participantsNeedToUpdate) => (dispatch, getState) => {
    const {
      breakoutRoom: {
        attendee: { status: boAttendeeStatus },
      },
      meeting: {
        currentUser: { userId: currentUserId },
      },
      video: { hasChangedVideoOrder },
    } = getState();

    if (isInRoom(boAttendeeStatus)) {
      const updateData = removeDuplicates(participantsNeedToUpdate);
      dispatch(updateMainSessionAttendeeList(updateData));
    } else {
      dispatch({
        type: WS_CONF_ROSTER_INDICATION,
        message: {
          body: {
            update: participantsNeedToUpdate,
            currentUserId,
            hasChangedVideoOrder,
          },
        },
      });
    }
  };

export function admit(userId) {
  return (dispatch) => {
    const body = {
      bHold: false,
      id: userId,
    };

    dispatch(updateBeingAdmitted([{ id: userId, isBeingAdmitted: true }]));
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_PUT_ON_HOLD_REQ,
        body,
      }),
    );
    if (!isTeslaMode()) {
      dispatch(setShowWaitingRoomNotificationVisible(false));
    }
  };
}

export function admitAll() {
  return (dispatch, getState) => {
    const waitingParticipants = waitingParticipantsSelector(getState());
    if (waitingParticipants.length > 0) {
      const data = {
        evt: socketEventTypes.WS_CONF_ADMIT_ALL_SILENT_USERS_REQ,
      };
      const participantsNeedToUpdate = [];
      waitingParticipants.forEach((item) => {
        participantsNeedToUpdate.push({
          isBeingAdmitted: true,
          id: item.userId,
        });
      });
      dispatch(updateBeingAdmitted(participantsNeedToUpdate));
      dispatch(sendSocketMessage(data));
      dispatch(setShowWaitingRoomNotificationVisible(false));
      if (isTeslaMode()) {
        // todo
        dispatch(clearRightPanel());
      }
    }
  };
}

export const allowToTalkAction = (isAllowTalk, userId) => {
  return (dispatch) => {
    const data = {
      evt: socketEventTypes.WS_AUDIO_ALLOW_TALK_REQ,
      body: {
        id: userId,
        bAllowTalk: !isAllowTalk,
      },
    };
    dispatch(sendSocketMessage(data));
  };
};

export const changeToAttendeeAction = (userId) => {
  return (dispatch, getState) => {
    const {
      meeting: { xmppUserList },
    } = getState();
    const xmppUser = [...xmppUserList.host, ...xmppUserList.panelists].find(
      (item) => item.node === userId,
    );
    if (xmppUser) {
      const { jid, node, clientCap } = xmppUser;
      dispatch(
        sendSocketMessage({
          evt: socketEventTypes.WS_CONF_ROLE_CHANGE_REQ,
          body: {
            jid,
            userID: node,
            clientCap,
            bPromote: false,
          },
        }),
      );
    }
  };
};

export const changeToPanelistAction = (jid, node, clientCap) => {
  return (dispatch) => {
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_ROLE_CHANGE_REQ,
        body: {
          jid,
          userID: node,
          clientCap,
          bPromote: true,
        },
      }),
    );
  };
};

export const setNotJoinedList = createAction(SET_NOT_JOINED_LIST);
export const toggleNotJoinedList = createAction(TOGGLE_NOT_JOINED_LIST);
export const onRecvNotJoinedList = (list) => {
  return (dispatch) => {
    if (list.length) {
      const formatList = list.map((item) => {
        return {
          ...item,
          visible: true,
          key: item.userId || item.email,
        };
      });
      dispatch(setNotJoinedList(formatList));
    }
  };
};

export const changeNotJoinedList = createAction(CHANGE_NOT_JOINED_LIST);

export const reclaimHostAction = () => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: { currentUser, zoomId },
      breakoutRoom: { mainSessionAttendeeList },
    } = state;
    const isMeInRoom = isMeInRoomSelector(state);
    let userId = currentUser.userId;
    if (isMeInRoom) {
      const meInMeeting = mainSessionAttendeeList.find(
        (attendee) => attendee.zoomID === zoomId,
      );
      if (meInMeeting) {
        userId = meInMeeting.userId;
      }
    }
    const body = {
      bCoHost: false,
      id: userId,
    };
    const data = {
      evt: socketEventTypes.WS_CONF_RECLAIM_HOST_REQ,
      body,
    };
    dispatch(sendSocketMessage(data));
  };
};

export const hostLowerHand = (participant) => (dispatch, getState) => {
  const { isWebinarAttendee } = checkRole(participant);
  if (isWebinarAttendee) {
    const {
      meeting: {
        xmppUserList: { attendees },
      },
    } = getState();
    xmppServer.lowerHand([
      participant?.jid ??
        attendees?.find((attendee) => attendee.node === participant.userId)
          ?.jid ??
        '',
    ]);
  } else {
    const body = {
      bOn: !participant.bRaiseHand,
      id: participant.userId,
    };
    const data = {
      evt: socketEventTypes.WS_CONF_RAISE_LOWER_HAND_REQ,
      body,
    };
    dispatch(sendSocketMessage(data));
  }
};

export const promptParticipantA11yInfo = (msg) => (_dispatch, getState) => {
  const { add, remove } = msg.body;
  const state = getState();
  const attendeeList = attendeesListSelector(state);
  const currentUserId = state.meeting?.currentUser?.userId;

  if (!_.isEmpty(add)) {
    const user = add[add.length - 1];
    const userName = decodeBase64(user.dn2);
    if (user.id !== currentUserId) {
      const tip = user.bHold
        ? userEnterWRTipFn(userName)
        : userJoinTipFn(userName);
      promptA11yInfo(tip);
    }
  }
  if (!_.isEmpty(remove)) {
    const user = remove[remove.length - 1];
    if (user.id !== currentUserId) {
      const attendee = attendeeList.find(
        (attendee) => attendee.userId === user.id,
      );
      if (attendee) {
        const tip = attendee.bHold
          ? userLeaveWRTipFn(attendee.displayName)
          : userLeaveTipFn(attendee.displayName);
        promptA11yInfo(tip);
      }
    }
  }
};
