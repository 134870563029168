import {
  CONTROL_MODE_READY_FACTOR,
  CONTROL_MODE_CAP_FULL,
  CONTROL_MODE_CAP_LEVEL_VIEWONLY,
  RWG_CONF_END_ERROR_CODE,
  RWG_JOIN_RESPONSE_ERROR_CODE,
  CONTROL_MODE_CAP_LEVEL_VIEWONLY_AUDIO_BRIDGE,
  CONTROL_MODE_CAP_FULL_AUDIO_BRIDGE,
} from '../../enum';
import {
  WS_CONF_JOIN_RES,
  WS_CONF_HOLD_CHANGE_INDICATION,
  WS_CONF_END_INDICATION,
  WS_CONF_ROSTER_INDICATION,
  WS_CONF_ATTRIBUTE_INDICATION,
  WS_CONF_MEETING_TOPIC_CHANGE,
  WS_AUDIO_ALLOW_TALK_INDICATION,
} from '../../../constants/ZoomSocketEventTypes';
import { CONF_SUCCESS } from '../../../constants/ErrorTypes';
import { localReadyStatesObserver } from './local-ready-states-observer';
import { isJoinAudio } from '../../../global/service';
import { RECOONECT_RWG_WHEN_CLOSE } from '../../../constants/ExeternalControllerErrorTypes';
import * as leaveReasonType from '../../../constants/LeaveReasons';
import { setRightContainerWidth } from '../../../actions/MeetingUIActions';
import { setMuteUnmuteActionInProgress } from '../../../features/audio/redux/audio-action';
import { decodeBase64 } from '../../../global/util';
import {
  participantsMap,
  updateParticipantsListByWs,
} from './participants-manager';
import { isAudioBridge } from '../../../global/op-feature-option';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';
import { TESLA_RIGHT_CONTAINER_WIDTH } from './constants';

const DEPENDENCE_ROSTER = {
  CAN_AUDIO_UNMUTE: 0x1,
  CAN_VIDEO_UNMUTE: 0x2,
  HIDE_AVATAR: 0x4,
};

export const ALL_DEPENDENCE_ROSTER_RECEIVED =
  DEPENDENCE_ROSTER.CAN_AUDIO_UNMUTE |
  DEPENDENCE_ROSTER.CAN_VIDEO_UNMUTE |
  DEPENDENCE_ROSTER.HIDE_AVATAR;

export const rosterAllInited = {
  callback: null,
  roster: 0x0,
  setAllRosterReceivedCallback(callback) {
    this.callback = callback;
  },
  setRoster(roster) {
    this.roster |= roster;
    if (this.roster === ALL_DEPENDENCE_ROSTER_RECEIVED) {
      if (typeof this.callback === 'function') {
        setTimeout(() => {
          if (this.callback) {
            this.callback();
            this.callback = null;
          }
        }, 0);
      }
    }
  },
};

export function rwgMsgHandler(evt, payload, store, adaptor) {
  const { getState, dispatch } = store;
  const {
    meeting: { currentUser },
  } = getState();
  const isWb = isWebinar();
  switch (evt) {
    case WS_CONF_JOIN_RES: {
      switch (payload.body.res) {
        case CONF_SUCCESS:
          adaptor.notifyControllerRwgConnected();
          dispatch(setRightContainerWidth(TESLA_RIGHT_CONTAINER_WIDTH));
          break;
        default:
          if (
            !Object.entries(RWG_JOIN_RESPONSE_ERROR_CODE).some(([, value]) => {
              if (value.code === Number(payload.body.res)) {
                adaptor.notifyControllerJoinFailure(value);
                return true;
              }
              return false;
            })
          ) {
            adaptor.notifyControllerJoinFailure({
              type: 'rwg',
              code: payload.body.res,
              message: 'Join rwg failed',
            });
          }
          break;
      }
      break;
    }
    case WS_CONF_ROSTER_INDICATION: {
      updateParticipantsListByWs(payload.body, store, adaptor);
      if (payload.body.update) {
        payload.body.update.forEach((user) => {
          if (user.id === currentUser.userId) {
            if (user.muted !== undefined) {
              if (user.muted) {
                adaptor.notifyControllerAudioMuted();
              } else {
                adaptor.notifyControllerAudioUnmuted();
              }
            }
            if (user.bVideoOn === false) {
              adaptor.notifyControllerVideoMuted();
            } else if (user.bVideoOn === true) {
              adaptor.notifyControllerVideoUnmuted();
            }
            if (user.audio !== undefined) {
              const isAudioConnected = isJoinAudio(user);
              adaptor.notifyControllerAudioConnected(isAudioConnected);
              adaptor.notifyControllerAudioCanUnMuted({
                audioConnectedChangeTo: isAudioConnected,
              });
              // rwg will notiry a audio='' and audio='computer' after receive muted status
              if (isAudioConnected) {
                if (
                  user.muted === undefined &&
                  currentUser.muted !== undefined
                ) {
                  if (currentUser.muted) {
                    adaptor.notifyControllerAudioMuted();
                  } else {
                    adaptor.notifyControllerAudioUnmuted();
                  }
                }
              } else {
                adaptor.notifyControllerAudioMuted();
              }
            }
            if (user.audio !== currentUser.audio || user.muted !== undefined) {
              dispatch(setMuteUnmuteActionInProgress(false));
            }
          }
        });
      }
      if (!localReadyStatesObserver.isTargetCapSet()) {
        if (!payload.body.add) return;
        const me = payload.body.add.find(
          (user) => user.id === currentUser.userId,
        );
        if (!me) return;

        if (me.bHold) {
          participantsMap.clearOthers();
          adaptor.notifyControllerMeetingHoldOn();
        }

        const isLimitedSupport = isViewOnly(me.role) && isWb;
        const targetVal = isLimitedSupport
          ? isAudioBridge()
            ? CONTROL_MODE_CAP_LEVEL_VIEWONLY_AUDIO_BRIDGE
            : CONTROL_MODE_CAP_LEVEL_VIEWONLY
          : isAudioBridge()
          ? CONTROL_MODE_CAP_FULL_AUDIO_BRIDGE
          : CONTROL_MODE_CAP_FULL;
        localReadyStatesObserver.setTargetCap(targetVal);
        localReadyStatesObserver.complete(
          CONTROL_MODE_READY_FACTOR.RWG_CONNECTED,
        );
        /* eslint-disable-next-line no-console */
        console.error(
          `Ready State Check: rwg mapp result : ${CONTROL_MODE_READY_FACTOR.RWG_CONNECTED}`,
        );
      }
      break;
    }

    case WS_CONF_HOLD_CHANGE_INDICATION: {
      if (payload.body.bHold) {
        participantsMap.clearOthers();
        adaptor.notifyControllerMeetingHoldOn();
      } else {
        adaptor.notifyControllerVideoCanUnMuted({
          bHoldChangeTo: false,
        });
        adaptor.notifyControllerAudioCanUnMuted({
          bHoldChangeTo: false,
        });
      }
      break;
    }
    case WS_CONF_END_INDICATION: {
      if (leaveReasonType.LEAVEREASON_RECONNECT === payload.body.reason) {
        // no-need to inform tesla
      } else if (
        !Object.entries(RWG_CONF_END_ERROR_CODE).some(([, value]) => {
          if (value.code === Number(payload.body.reason)) {
            adaptor.notifyControllerMeetingEnd(value, true);
            return true;
          }
          return false;
        }) &&
        leaveReasonType.LEAVEREASON_USERREQUEST !== payload.body.reason
      ) {
        adaptor.notifyControllerMeetingEnd(
          {
            type: 'rwg-leave-reason',
            code: payload.body.reason,
            message: 'Leave meeting for some reason',
          },
          true,
        );
      }
      break;
    }
    case RECOONECT_RWG_WHEN_CLOSE: {
      adaptor.notifyControllerJoinFailure(
        RWG_JOIN_RESPONSE_ERROR_CODE.RECOONECT_RWG_WHEN_CLOSE_ERROR,
      );
      break;
    }
    case WS_CONF_ATTRIBUTE_INDICATION: {
      /* eslint-disable-next-line no-prototype-builtins */
      if (payload.body && payload.body.hasOwnProperty('bCanUnmute')) {
        adaptor.notifyControllerAudioCanUnMuted({
          bCanUnmuteAudioChangeTo: !!payload.body.bCanUnmute,
        });
        rosterAllInited.setRoster(DEPENDENCE_ROSTER.CAN_AUDIO_UNMUTE);
      }
      /* eslint-disable-next-line no-prototype-builtins */
      if (payload.body && payload.body.hasOwnProperty('bCanUnmuteVideo')) {
        adaptor.notifyControllerVideoCanUnMuted({
          bCanUnmuteVideoChangeTo: !!payload.body.bCanUnmuteVideo,
        });
        rosterAllInited.setRoster(DEPENDENCE_ROSTER.CAN_VIDEO_UNMUTE);
      }
      /* eslint-disable-next-line no-prototype-builtins */
      if (payload.body && payload.body.hasOwnProperty('bAllowedAvatar')) {
        adaptor.notifyControllerHideAvatar(!payload.body.bAllowedAvatar);
        rosterAllInited.setRoster(DEPENDENCE_ROSTER.HIDE_AVATAR);
      }
      break;
    }
    case WS_CONF_MEETING_TOPIC_CHANGE: {
      adaptor.notifyControllerTopicChange(
        decodeBase64(payload.body.meetingTopic),
      );
      break;
    }
    case WS_AUDIO_ALLOW_TALK_INDICATION: {
      const {
        body: { bAllowTalk, promoterID },
      } = payload;
      if (promoterID === currentUser.userId) {
        adaptor.notifyControllerAudioCanUnMuted({
          bAllowTalkChangeTo: !!bAllowTalk,
        });
      }
      break;
    }
    default:
      break;
  }
}
