import { IQ_FROM_IM } from '../../../global/constant';
import { infoLog } from '../../../global/web-client-logger';
import { CHAT_FILE_TYPE } from '../../chat/constants';
import { convertXml2Json } from '../../chat/utils';
import { rtbStrToFontStyle } from './rtb/index';
import {
  trackMessageType,
  messageFeatureType,
  ZoomTaskFeature,
  fileIntegrationType,
} from './enum';
import { getAllFiles } from '../../../global/ChatSDK/util';
import meetingConfig from 'meetingConfig';
import { GIPHY_DEFAULT_SIZE } from '../../../global/ChatSDK/enums';
import { getMeetingGiphyRating } from '../../../global/service';

export const chatType = {
  chat: 'chat',
  groupchat: 'groupchat',
};

export const xmlns = {
  client: 'jabber:client',
  imcmd: 'zoom:imcmd',
  emoji: 'zoom:iq:emoji',
};

export const fileType = {
  file: 'file',
  image: 'image',
};

// TODO identify 3rd file type
export const thirdPartyFileType = {
  None: 0,
  Word: 1,
  Excel: 2,
  PPT: 3,
  OneNote: 4,
  BoxNode: 5,
  BoxNodeFromTemplate: 6,
  GoogleDocs: 7,
  GoogleSlides: 8,
  GoogleSheet: 9,
};

export const fileSuffixMap = {
  [fileType.image]: ['png', 'jpg', 'jpeg', 'gif', 'svg'],
};

export const RESID = 'ZoomChat_pc';
export const fileTransferScope = {
  FileTransferScope_Anyone: 0,
  FileTransferScope_SameOrg: 1,
  FileTransferScope_SameAccount: 2,
  FileTransferScope_Invalid: 3,
};

export function getFileIntegrationType(shareType) {
  switch (shareType) {
    case 'gdrive':
    case 'googleDrive':
      return fileIntegrationType.GoogleDrive;
    case 'dropbox':
      return fileIntegrationType.dropbox;
    case 'onedrive':
    case 'microsoftOneDrive':
      return fileIntegrationType.MicrosoftOneDrive;
    case 'box':
      return fileIntegrationType.Box;
    case 'sharepoint':
    case 'microsoftSharePoint':
      return fileIntegrationType.MicrosoftSharePoint;
    default:
      return fileIntegrationType.none;
  }
}
export function getMentions({ mention }) {
  if (!Array.isArray(mention)) return null;
  if (!mention.length) return null;
  return mention.map((item) => ({
    _jid: item.jid,
    _t: item.type,
    _s: item.start,
    _e: item.end,
  }));
}

function extractEmoji(json) {
  const body = json.iq;
  const {
    _from: sender,
    _to: receiverJid,
    _id: xmppMsgId,
    query: {
      emoji: {
        _action: action,
        _msgId: parentMsgId,
        _id: id,
        _cid: cid,
        _msg_t: t,
        _session: session,
      },
    },
    meetchat: { _ct: voteTime },
  } = body;
  const senderJid = sender?.split('@')[0];
  return {
    sender,
    receiverJid,
    xmppMsgId,
    action,
    parentMsgId,
    senderJid,
    id,
    cid,
    session,
    t,
    isVote: true,
    voteTime,
  };
}

function getRtbItems(rt) {
  let rtbItems = {};
  if (rt && rt._b) {
    rtbItems = rtbStrToFontStyle(rt._b) || {};
  }
  return rtbItems;
}

function getAtUsers(at) {
  const rawAt = _.get(at, 'user');
  return (rawAt && Array.isArray(rawAt) ? rawAt : [rawAt]).filter(Boolean);
}

// https://git.zoom.us/zms/pwa-webim/-/blob/release/packages/pwa-x2js/xml2json.js?ref_type=heads#L300
// https://zoomvideo.atlassian.net/issues/ZOOM-698442
function getSafeString(text) {
  if (typeof text === 'string') return text;
  return text.toString();
}

export function extractMsgFromXml(xmlStrOrJson) {
  return new Promise((resolve, reject) => {
    try {
      let message, json /* , messageXmlStr */;
      if (typeof xmlStrOrJson === 'string') {
        infoLog('xmlStr: ' + xmlStrOrJson);
        json = convertXml2Json(xmlStrOrJson);
        message = json.message;
        // messageXmlStr = xmlStrOrJson;
        infoLog('jsonObject');
      } else {
        infoLog('received json');
        json = xmlStrOrJson;
        message = xmlStrOrJson.message;
        // messageXmlStr = json.messageXmlStr;
      }
      if (!message) {
        if (_.get(json, 'iq.query.emoji')) {
          infoLog('received reactions');
          // resolve({ isReactionFromNewClient: true });
          const data = extractEmoji(json);
          resolve(data);
        } else {
          reject('received unsupported msg type');
          infoLog(`unsupported msg type: ${message}`);
        }
      }
      if (
        message.zmedit &&
        message.zmedit.message &&
        Number(message.zmext.msg_type) === trackMessageType.editEvent
      ) {
        const {
          _id: targetXmppMsgId,
          _t: timeStamp,
          message: {
            type,
            body: text,
            zmext: {
              _fb: fb = 0,
              msg_feature: msgFeature,
              rt,
              at,
              msg_type: msgType,
              visible,
            },
          },
        } = message.zmedit;

        const rtbItems = getRtbItems(rt);
        const allFiles =
          msgType == trackMessageType.fileAndText
            ? getAllFiles(rtbItems)
            : null;
        const atUsers = getAtUsers(at);
        infoLog(`received edited msg: ${targetXmppMsgId}, ${text},\r
        at users: ${JSON.stringify(atUsers)}
        `);
        return resolve({
          targetXmppMsgId,
          timeStamp,
          type,
          text: fb == 1 ? '' : getSafeString(text),
          snsBody: getSafeString(text),
          msgFeature,
          isEdit: true,
          rtbItems,
          atUsers,
          allFiles,
          failureBody: fb ? Number(fb) : undefined,
          visible: visible === 'false' ? false : true,
          // messageXmlStr,
        });
      }
      // fallback as text message, extract body
      if (!message.meetchat) message.meetchat = {};
      if (message.giphyv2) {
        return resolve(extractGiphyV2(message));
      }
      if (
        message.revoke &&
        message.zmext.msg_type == trackMessageType.revokeMessage
      ) {
        // handle historical revoke message
        return resolve({ visible: false });
      }

      const {
        body: text,
        _id: xmppMsgId,
        _from: ownerRes /* jid@xmppdomain/resId */,
        _type: chatType,
        meetchat: { _ct: timeStamp, _from: fr, ...others }, // message from meeting, timeStamp is in meetchat
        body: snsBody,
        zmext: {
          _fb: fb,
          msg_type: msgType,
          msg_feature: msgFeature,
          visible,
          rt,
          at,
          // obj,
          _t: timeStampOnExt, // message from im, timeStamp is in zmext
          from: { _n: senderName },
          obj,
          deleted,
        },
        it,
        // zmat,
        // zmtask,
        _to: toChannel,
      } = message;

      let fileInfo;
      if (obj) {
        const {
          _k: fileObj,
          _nm: fileName,
          _s: fileSize,
          _id: fileID,
          // _st: fileType,
          _fs: fileType,
        } = obj;
        fileInfo = {
          fileObj,
          fileName,
          fileSize,
          fileID,
          fileType,
        };
      }
      if (it) {
        const {
          _id: fileID,
          _t,
          _tt,
          _pl: previewUrl,
          _n: fileName,
          _s: fileSize,
          _pp: previewPath,
        } = it;
        fileInfo = {
          fileID,
          fileSize,
          fileName,
          previewPath,
          previewUrl,
          _t,
          _tt,
        };
      }

      if (msgType == trackMessageType.atEvent) {
        // TODO
        // resolve invisible atEvent
        return resolve(extractAtEvent({ message }));
      }

      const rtbItems = getRtbItems(rt);

      const allFiles =
        msgType == trackMessageType.fileAndText ? getAllFiles(rtbItems) : null;

      const atUsers = getAtUsers(at);

      const storedMsg = {
        text: fb == 1 ? '' : getSafeString(text),
        xmppMsgId,
        timeStamp: timeStamp || timeStampOnExt,
        chatType, // new
        snsBody: getSafeString(snsBody),
        msgType,
        msgFeature,
        fr,
        ownerRes,
        rtbItems,
        atUsers,
        toChannel,
        allFiles,
        failureBody: fb ? Number(fb) : undefined,
        senderName,
        visible: visible === 'false' ? false : true,
        ...(fileInfo ? fileInfo : {}),
        ...others,
        // messageXmlStr,
      };

      if (deleted) {
        storedMsg.deleted = true;
      }
      storedMsg.reply = extractReply(message.zmext.reply);
      resolve(storedMsg);
    } catch (error) {
      reject(error);
    }
  });
}

function extractGiphyV2(message) {
  const {
    body: text,
    _id: xmppMsgId,
    _from: ownerRes /* jid@xmppdomain/resId */,
    _type: chatType,
    meetchat: { _ct: t, _from: fr, ...others }, // message from meeting, timeStamp is in meetchat
    body: snsBody,
    zmext: {
      msg_type: msgType,
      msg_feature: msgFeature,
      _t: timeStampOnExt,
      from: { _n: senderName },
    },
    _to: toChannel,
    giphyv2: {
      _id: id,
      _url: url,
      _tags: tags,
      pcInfo,
      mobileInfo,
      bigPicInfo,
    },
  } = message;
  const reply = extractReply(message.zmext.reply);

  const giphyv2Info = {
    text,
    xmppMsgId,
    timeStamp: t || timeStampOnExt,
    fr,
    snsBody,
    msgType,
    msgFeature,
    giphyInfo: {
      id,
      url,
      tags,
      type: 'gif',
      rating: getMeetingGiphyRating(),
      title: `${id}.gif`,
      images: {
        bigPicInfo: {
          url: bigPicInfo._url,
          size: Number(bigPicInfo._size),
          ...GIPHY_DEFAULT_SIZE.big,
        },
        pcPicInfo: {
          url: pcInfo._url,
          size: Number(pcInfo._size),
          ...GIPHY_DEFAULT_SIZE.pc,
        },
        mobilePicInfo: {
          url: mobileInfo._url,
          size: Number(mobileInfo._size),
          ...GIPHY_DEFAULT_SIZE.mobile,
        },
      },
    },
    toChannel,
    ownerRes,
    chatType,
    reply,
    senderName,
    giphyRating:
      meetingConfig.meetingOptions.newMeetingChatExperience
        .giphyRatingInMeetingChat,
    ...others,
  };
  return Promise.resolve(giphyv2Info);
}

/**
 * @typedef { import("../../../global/ChatSDK/@types/meetingTypes").Reply } Reply
 * @param {any} - replyData
 * @returns {Reply}
 */
function extractReply(replyData) {
  const reply = {
    isEmpty: true,
  };
  if (_.isObject(replyData)) {
    const {
      _msg_id: replyMsgId,
      _thread_t: threadT,
      _owner: owner,
    } = replyData;
    reply.mainMsgId = replyMsgId;
    reply.mainMsgTime = threadT;
    reply.owner = owner;
    reply.isEmpty = false;
  }
  return reply;
}

export function extractFileInfoFromXml(xmlStrOrJson, is3rdFile = false) {
  return new Promise((resolve, reject) => {
    try {
      let message /* messageXmlStr */;
      if (typeof xmlStrOrJson === 'string') {
        infoLog('xmlStr: ' + xmlStrOrJson);
        message = convertXml2Json(xmlStrOrJson).message;
        // messageXmlStr = xmlStrOrJson;
        infoLog('jsonObject:' + message);
      } else {
        infoLog('received json');
        message = xmlStrOrJson.message;
        // messageXmlStr = xmlStrOrJson.messageXmlStr;
      }
      const reply = extractReply(message.zmext.reply);
      const isFileShare = is3rdFile || _.has(message, 'it');

      if (isFileShare) {
        const {
          _from: ownerRes /* jid@xmppdomain/resId */,
          body: snsBody,
          _id: xmppMsgId,
          _type: chatType,
          meetchat: { _ct: timeStamp, _from: fr, ...others },
          zmext: {
            msg_type: msgType,
            msg_feature: msgFeature,
            _t: timeStampOnExt, // message from im, timeStamp is in zmext
          },
          it: {
            _id: fileID,
            _t,
            _tt,
            _pl: previewUrl,
            _n: fileName,
            _s: fileSize,
            _pp: previewPath,
          },
          _to: toChannel,
        } = message;
        resolve({
          snsBody,
          xmppMsgId,
          timeStamp: timeStamp || timeStampOnExt,
          chatType,
          msgType,
          msgFeature,
          previewUrl,
          previewPath,
          // fileObj,
          fileName,
          fileSize,
          // fileID,
          fileType: CHAT_FILE_TYPE.THIRD_PARTY, // wcl need this
          shareType: fileIntegrationType[_t],
          fileID,
          _t,
          _tt,
          reply,
          fr,
          ownerRes,
          toChannel,
          ...others,
          // messageXmlStr,
        });
      } else {
        //  f="5" zoomXmppMsgType st="0" fileSubType fs="0"
        const {
          _from: ownerRes /* jid@xmppdomain/resId */,
          body: snsBody,
          _id: xmppMsgId,
          _type: chatType,
          meetchat: { _ct: timeStamp, _from: fr, ...others },
          zmext: {
            msg_type: msgType,
            msg_feature: msgFeature,
            _t: timeStampOnExt,
            obj: {
              _k: fileObj,
              _nm: fileName,
              _s: fileSize,
              _id: fileID,
              // _st: fileType,
              _fs: fileType,
            },
          },
          to: toChannel,
        } = message;
        resolve({
          snsBody,
          xmppMsgId,
          timeStamp: timeStamp || timeStampOnExt,
          chatType,
          msgType,
          msgFeature,
          fileObj,
          fileName,
          fileSize,
          fileID,
          fileType,
          reply,
          fr,
          ownerRes,
          toChannel,
          ...others,
          // messageXmlStr,
        });
      }
    } catch (error) {
      reject(error);
    }
  });
}

/*
 * not used now
 * delete msg by msgId
 */
export function extractDeleteInfoFromXml(xmlStr) {
  return new Promise((resolve, reject) => {
    try {
      const { message } = convertXml2Json(xmlStr);
      const {
        revoke: { _id: id, _thread: thread },
      } = message;
      resolve({ id, thread });
    } catch (error) {
      reject(error);
    }
  });
}

export const extractMsgFromIM = (xmlStr) => {
  return new Promise((resolve, reject) => {
    try {
      infoLog('received from IM xmlStr:' + xmlStr);
      const json = convertXml2Json(xmlStr);
      const { message } = json;
      if (!message) {
        infoLog(`unsupported msg type: ${message}`);
        reject('received unsupported msg type');
      }
      // json.messageXmlStr = xmlStr;
      if (_.get(json, 'message.zmext.obj._id')) {
        // return extractFileInfoFromXml(json, false);
        if (_.get(json, 'message.it._id')) {
          resolve(extractFileInfoFromXml(json, true));
        }
        resolve(extractFileInfoFromXml(json, false));
      } else if (
        _.get(json, 'message.zmext.msg_feature') == messageFeatureType.text
      ) {
        resolve(extractMsgFromXml(json));
      } else if (
        _.get(json, 'message.zmext.msg_feature') == messageFeatureType.comment
      ) {
        resolve(extractMsgFromXml(json));
      } else if (
        _.get(json, 'message.notify.payload.emoji') ||
        _.get(json, 'message.zmtask._feature') == ZoomTaskFeature.IM_emoji
      ) {
        // reject('unsupport msg type');
        // resolve(storedMsg);
        resolve(extractIMEmojiFromXML(json));
      } else if (
        _.get(json, 'message.zmat') ||
        _.get(json, 'message.zmtask._feature') == ZoomTaskFeature.AtEvent
      ) {
        resolve(extractAtEvent(json));
      } else if (_.get(json, 'message.giphyv2')) {
        resolve(extractGiphyV2(json.message));
        // resolve(returnUnsupportedMsg(json));
      } else if (
        _.get(json, 'message.zmext.msg_type') == trackMessageType.fileAndText
      ) {
        resolve(extractMsgFromXml(json));
      } else {
        resolve(returnUnsupportedMsg(json));
      }
    } catch (error) {
      reject(error);
    }
  });
};

function returnUnsupportedMsg(json) {
  const { message } = json;
  const { _id: xmppMsgId, body: text, _to: toChannel } = message;

  const reply = extractReply(message.zmext.reply);

  return {
    xmppMsgId,
    text,
    isUnsupportedFeature: true,
    rtbItems: {},
    reply,
    toChannel,
  };
}

function extractIMEmojiFromXML(json) {
  const {
    notify: {
      payload: {
        emoji: {
          _action: action,
          _msgId: parentMsgId,
          _id: id,
          _cid: cid,
          _msg_t: t,
        },
      },
    },
    zmext: {
      from: { _n: senderName },
    },
    _from: ownerRes,
    _id: xmppMsgId,
  } = json.message;

  // message from IM must have jid
  const senderJid = ownerRes.split('@')[0];
  return {
    action,
    parentMsgId,
    id,
    cid,
    t,
    senderName,
    senderJid,
    ownerRes,
    xmppMsgId,
    type: IQ_FROM_IM,
    isVote: true,
  };
}

function extractAtEvent(json) {
  const { zmat } = json.message;

  if (!zmat) return {};
  const atJid = _.get(zmat, 'at.user');

  return {
    isAtEvent: 1,
    atId: zmat._id,
    events: (Array.isArray(atJid) ? atJid : [atJid]).map(({ _jid: jid }) => {
      return {
        atJid: jid,
      };
    }),
  };
}
