// promise that handlers must be the pure function
const handlersCollector = (handlers) => {
  return handlers.reduce(
    (s, v) => {
      const { add, update, remove } = v;
      Object.entries({ add, update, remove }).forEach(([k, v]) => {
        if (v) {
          s[k].push(v);
        }
      });
      return s;
    },
    { add: [], update: [], remove: [] },
  );
};

const collectConfigs = () => {
  if (!require?.context) {
    return []; // For fixing npm run test jest issue
  }
  const req = require.context('./', true, /roster\.[^/]+?\.js$/);
  return req.keys().map((filename) => req(filename).default());
};
const handlers = handlersCollector(collectConfigs());

export const happyRoster = (message) => (dispatch, getState) => {
  if (!message.body) {
    return;
  }
  const { add, update, remove } = message.body;
  // keep sort as { add, update, remove }
  Object.entries({ add, update, remove }).forEach(([kye, items]) => {
    if (items && items.length > 0) {
      items.forEach((item) => {
        if (item !== undefined) {
          handlers[kye].forEach((handler) => {
            handler(item, dispatch, getState);
          });
        }
      });
    }
  });
};
