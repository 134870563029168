import { createActions } from 'redux-actions';

const SET_IS_OPEN = 'SET_IS_OPEN';
const TOGGLE_IS_HOVER_ANNO_BTN = 'TOGGLE_IS_HOVER_ANNO_BTN';
const UPDATE_USED_ANNOTATION_SHARING_LIST =
  'UPDATE_USED_ANNOTATION_SHARING_LIST';
const SET_REDO_STATUS = 'SET_REDO_STATUS';
const SET_UNDO_STATUS = 'SET_UNDO_STATUS';
const SET_SHARE_USER_ANNOTATION_INFO = 'SET_SHARE_USER_ANNOTATION_INFO';

export const {
  setIsOpen,
  toggleIsHoverAnnoBtn,
  updateUsedAnnotationSharingList,
  setRedoStatus,
  setUndoStatus,
  setShareUserAnnotationInfo,
} = createActions({
  [SET_IS_OPEN]: (payload) => payload,
  [TOGGLE_IS_HOVER_ANNO_BTN]: (payload) => payload,
  [UPDATE_USED_ANNOTATION_SHARING_LIST]: (payload) => payload,
  [SET_REDO_STATUS]: (payload) => payload,
  [SET_UNDO_STATUS]: (payload) => payload,
  [SET_SHARE_USER_ANNOTATION_INFO]: (payload) => payload,
});
