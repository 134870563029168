import React from 'react';
import { Link2, privacyNotificationUrl } from '../../components/utils/link2';
import { iText } from '../../global/util';
import { accountOwnerText } from '../../global/resource';
import { transformStringToElement } from '../../components/utils/transform-string-to-element';
import reactStringReplace from '../../global/resct-string-replace';
import { SETTINGS_TITLE } from '../footer/resource';
import { srSettingUrl } from './service/sr-web-config';

export const LEAVE_MEETING = iText('Leave Meeting', 'apac.wc_leave_meeting');
export const LEAVE_WEBINAR = iText('Leave Webinar', 'apac.wc_leave_webinar');
export const CONTINUE = iText('Continue', 'apac.wc_continue');

export const RECORD_REMINDER_TITLE = iText(
  'This meeting is being recorded.',
  'apac.wc_record_reminder_title',
);

// RECORDING
export const RECORDING_TITLE = iText(
  'This meeting is being recorded.',
  'apac.wc_record_reminder_title',
);
export const RECORDING_TOOLTIP = iText(
  'Anyone with access to the recording may view or share it with others.',
  'apac.wc_record_consent_tooltip',
);

export const ZRA_RECORDING_TOOLTIP = iText(
  'The host is using Zoom Revenue Accelerator to analyze the meeting, including the audio to distinguish between speakers for transcription purposes. The audio analysis will be deleted after the transcript is generated. Anyone with access to the recording may view or share it with others.',
  'apac.wc_ZRA_record_consent_tooltip',
);

// LIVESTREAMING
export const LIVESTREAMING_TITLE = iText(
  'This meeting is being livestreamed.',
  'apac.wc_livestream_consent_title',
);
export const LIVESTREAMING_TOOLTIP = iText(
  'Anyone with access to the livestream may view or share it with others.',
  'apac.wc_livestream_consent_tooltip',
);

// CC
export const CC_TITLE = iText(
  'This meeting is being transcribed.',
  'apac.wc_cc_consent_title2',
);
export const CC_TOOLTIP = iText(
  'Anyone with access to the transcript may view or share it with others.',
  'apac.wc_cc_consent_tooltip',
);

// AI
export const AI_TITLE = iText(
  'This meeting is being transcribed for AI Companion.',
  'apac.wc_ai_consent_title2',
);

// RECORDING + LIVESTREAMING
export const RECORDING_LIVESTREAMING_TITLE = iText(
  'This meeting is being recorded and livestreamed.',
  'apac.wc_recording_livestreaming_consent_title',
);
export const RECORDING_LIVESTREAMING_TOOLTIP = iText(
  'Anyone with access to the recording or livestream may view or share it with others.',
  'apac.wc_recording_livestreaming_consent_tooltip2',
);
export const ZRA_RECORDING_LIVESTREAMING_TOOLTIP = iText(
  'The host is using Zoom Revenue Accelerator to analyze the meeting, including the audio to distinguish between speakers for transcription purposes. The audio analysis will be deleted after the transcript is generated. Anyone with access to the recording or livestream may view or share it with others.',
  'apac.wc_ZRA_recording_livestreaming_consent_tooltip',
);

// RECORDING + LIVESTREAMING + AI
export const RECORDING_LIVESTREAMING_AI_TITLE = iText(
  "This meeting is being recorded and livestreamed. It's also being transcribed for AI Companion.",
  'apac.wc_recording_livestreaming_ai_consent_title2',
);
export const RECORDING_LIVESTREAMING_AI_TOOLTIP = iText(
  'Anyone with access to the recording or livestream may view or share them with others.',
  'apac.wc_recording_livestreaming_ai_consent_tooltip',
);

export const ZRA_RECORDING_LIVESTREAMING_AI_TOOLTIP = iText(
  'The host is using Zoom Revenue Accelerator to analyze the meeting, including the audio to distinguish between speakers for transcription purposes. The audio analysis will be deleted after the transcript is generated. Anyone with access to the recording or livestream may view or share it with others.',
  'apac.wc_ZRA_recording_livestreaming_ai_consent_tooltip',
);

// RECORDING + AI
export const RECORDING_AI_TITLE = iText(
  "This meeting is being recorded. It's also being transcribed for AI Companion.",
  'apac.wc_recording_ai_consent_title2',
);
export const RECORDING_AI_TOOLTIP = iText(
  'Anyone with access to the recording may view or share it with others.',
  'apac.wc_recording_ai_consent_tooltip',
);

export const ZRA_RECORDING_AI_TOOLTIP = iText(
  'The host is using Zoom Revenue Accelerator to analyze the meeting, including the audio to distinguish between speakers for transcription purposes. The audio analysis will be deleted after the transcript is generated. Anyone with access to the recording may view or share it with others.',
  'apac.wc_ZRA_recording_ai_consent_tooltip',
);

// RECORDING + LIVESTREAMING + CC
export const RECORDING_LIVESTREAMING_CC_TITLE = iText(
  'This meeting is being recorded and livestreamed.',
  'apac.wc_recording_livestreaming_cc_title2',
);

export const RECORDING_LIVESTREAMING_CC_TOOLTIP = iText(
  'Anyone with access to the recording, livestream, or transcript may view or share them with others.',
  'apac.wc_recording_livestreaming_cc_tooltip',
);

export const ZRA_RECORDING_LIVESTREAMING_CC_TOOLTIP = iText(
  'The host is using Zoom Revenue Accelerator to analyze the meeting, including the audio to distinguish between speakers for transcription purposes. The audio analysis will be deleted after the transcript is generated. Anyone with access to the recording, livestream, or transcript may view or share it with others.',
  'apac.wc_ZRA_recording_livestreaming_cc_tooltip',
);

// LIVESTREAMING + AI
export const LIVESTREAMING_AI_TITLE = iText(
  "This meeting is being livestreamed. It's also being transcribed for AI Companion.",
  'apac.wc_livestreaming_ai_consent_title2',
);
export const LIVESTREAMING_AI_TOOLTIP = iText(
  'Anyone with access to the livestream may view or share it with others.',
  'apac.wc_livestreaming_ai_consent_tooltip',
);

// RECORDING + AI + CC + LIVESTREAMING
export const RECORDING_AI_CC_LIVESTREAMING_TITLE = iText(
  "This meeting is being recorded and livestreamed. It's also being transcribed for AI Companion.",
  'apac.wc_recording_cc_livestreaming_ai_consent_title2',
);
export const RECORDING_AI_CC_LIVESTREAMING_TOOLTIP = iText(
  'Anyone with access to the recording, livestream, or transcript may view or share them with others.',
  'apac.wc_recording_cc_livestreaming_ai_consent_tooltip',
);

export const ZRA_RECORDING_AI_CC_LIVESTREAMING_TOOLTIP = iText(
  'The host is using Zoom Revenue Accelerator to analyze the meeting, including the audio to distinguish between speakers for transcription purposes. The audio analysis will be deleted after the transcript is generated. Anyone with access to the recording, livestream, or transcript may view or share it with others.',
  'apac.wc_ZRA_recording_cc_livestreaming_ai_consent_tooltip',
);

// RECORDING + AI + CC
export const RECORDING_AI_CC_TITLE = iText(
  "This meeting is being recorded. It's also being transcribed for AI Companion.",
  'apac.wc_recording_cc_ai_consent_title2',
);
export const RECORDING_AI_CC_TOOLTIP = iText(
  'Anyone with access to the recording or transcript may view or share them with others.',
  'apac.wc_recording_cc_ai_consent_tooltip',
);

export const ZRA_RECORDING_AI_CC_TOOLTIP = iText(
  'Anyone with access to the recording or transcript may view or share them with others.',
  'apac.wc_ZRA_recording_cc_ai_consent_tooltip',
);

// LIVESTREAMING + AI + CC
export const LIVESTREAMING_AI_CC_TITLE = iText(
  "This meeting is being livestreamed. It's also being transcribed for AI Companion.",
  'apac.wc_livestreaming_cc_ai_consent_title2',
);
export const LIVESTREAMING_AI_CC_TOOLTIP = iText(
  'Anyone with access to the livestream or transcript may view or share them with others.',
  'apac.wc_livestreaming_cc_ai_consent_tooltip',
);

// RECORDING + CC
export const RECORDING_CC_TITLE = iText(
  'This meeting is being recorded.',
  'apac.wc_recording_cc_consent_title2',
);
export const RECORDING_CC_TOOLTIP = iText(
  'Anyone with access to the recording or transcript may view or share them with others.',
  'apac.wc_recording_cc_consent_tooltip',
);

export const ZRA_RECORDING_CC_TOOLTIP = iText(
  'The host is using Zoom Revenue Accelerator to analyze the meeting, including the audio to distinguish between speakers for transcription purposes. The audio analysis will be deleted after the transcript is generated. Anyone with access to the recording or transcript may view or share it with others.',
  'apac.wc_ZRA_recording_cc_consent_tooltip',
);

// LIVESTREAMING + CC
export const LIVESTREAMING_CC_TITLE = iText(
  "This meeting is being livestreamed. It's also being transcribed.",
  'apac.wc_livestreaming_cc_consent_title2',
);
export const LIVESTREAMING_CC_TOOLTIP = iText(
  'Anyone with access to the livestream or transcript may view or share them with others.',
  'apac.wc_livestreaming_cc_consent_tooltip',
);

// AI + CC
export const AI_CC_TITLE = iText(
  'This meeting is being transcribed for AI Companion.',
  'apac.wc_ai_cc_consent_title2',
);
export const AI_CC_TOOLTIP = iText(
  'Anyone with access to the transcript may view or share it with others.',
  'apac.wc_ai_cc_consent_tooltip',
);

export const RECORD_REMINDER_DESC = iText(
  'By continuing to be in the meeting, you are consenting to be recorded.',
  'apac.wc_record_reminder_desc',
);

export const RECORD_REMINDER_WEBINAR_DESC = iText(
  'By continuing to be in the webinar, you are consenting to be recorded.',
  'apac.wc_record_webinar_reminder_desc',
);
export const RECORD_REMINDER_FOR_HOST_DESC = iText(
  'By continuing, all faces, conversations, and screen sharing will be recorded to the cloud.',
  'apac.wc_record_host_reminder_desc',
);
export const RECORD_REMINDER_TITLE_FOR_HOST = iText(
  'Record this meeting?',
  'apac.wc_record_reminder_title_for_host',
);

export const RECORD_REMINDER_DESC_1 = iText(
  'By staying in this meeting, you consent to being recorded.',
  'apac.wc_record_reminder_desc_1',
);
export const RECORD_REMINDER_DESC_2 = transformStringToElement(
  iText(
    'The {0} can also watch this recording if it’s stored in the cloud. Any participant granted permission can (1) record to their local device or (2) invite an app to record for them. These individuals can share these recordings with apps and others.',
    'apac.wc_record_reminder_desc_2_2',
    '#1*',
  ),
  '#1*',
  <Link2
    link={privacyNotificationUrl()}
    className="archiving__account-owner"
    text={accountOwnerText}
  />,
);
export const GOT_IT = iText('Got it', 'apac.GOT_IT');

export const CONNECTING_TO_RECORD_SERVER_TEXT = iText(
  'Connecting to cloud server...',
  'apac.wc_record_connecting',
);
export const START_RECORDING_ERROR_TEXT = iText(
  'Unable to connect to cloud server',
  'apac.wc_record_start_error',
);
export const PLS_TRY_AGAIN_TEXT = iText(
  'Please try again later',
  'apac.wc_record_pls_try_again',
);
export const WITH_ZOOM_IQ_TEXT = iText(
  'With Zoom IQ',
  'apac.wc_record_with_zoom_iq',
);

export const RA_TEXT = iText(
  'Record to the cloud with Revenue Accelerator',
  'apac.record.RA_TEXT',
);
export const RECORD_TO_CLOUD = iText(
  'Record to the cloud',
  'apac.record.to_cloud',
);
export const EnableSmartRecordingText = iText(
  'Enable Smart Recording with AI Companion',
  'apac.record.enable_smart_recording',
);

export const startSRFailedText = iText(
  'Unable to enable Smart Recording with AI Companion and start recording. Please try again later.',
  'apac.sr.start_SR_failed',
);

export const EnableSRForAllText = iText(
  'Smart Recording with AI Companion enabled',
  'apac.sr.enable_sr_for_all',
);

export const requestSRtoAdmin = iText(
  'Request enable Smart Recording with AI Companion',
  'apac.sr.request_to_admin',
);

export const goToSRSettingContent = iText(
  'Go to setting page to enable Smart Recording with AI Companion. This feature will only be effective for the next meetings.',
  'apac.sr.go_to_setting_content',
);

export const goToSettingButtonText = iText(
  'Go to settings',
  'apac.common.go_to_setting',
);

export const sendRequestText = iText(
  'Send Request',
  'apac.common.send_request',
);

export const requestSRContent = iText(
  'You can’t enable Smart Recording with AI Companion in this meeting because your account admin has turned off and locked this feature. Send Request to your admin to enable Smart Recording with AI Companion.',
  'apac.sr.request_sr_content',
);

export const requestSRTitle = iText(
  'Ask admin to enable Smart Recording with AI Companion',
  'apac.sr.request_sr_title',
);

export const receiveRequestSRTitle = (name) =>
  iText(
    `${name} is requesting you to enable Smart Recording with AI Companion `,
    'apac.sr.receive_request_sr_title',
    name,
  );

export const receiveRequestSRContent1 = iText(
  'Smart Recording with AI Companion uses AI technology, which may include third-party models. Zoom does not use any audio, video, chat, screen sharing, attachments or other communications-like content (such as poll results, whiteboard and reactions) to train Zoom’s or third-party AI models.',
  'apac.sr.receive_request_content_1',
);
export const receiveRequestSRContent = [
  receiveRequestSRContent1,
  reactStringReplace(
    iText(
      'Once Smart Recording with AI Companion is enabled, the web setting for this feature will be enabled for your account, your cloud recording will generate recording highlights, summary and smart chapters, and next steps. You can configure this feature at any time in {0}.',
      'apac.sr.receive_request_content_2',
    ),
    '{0}',
    // eslint-disable-next-line @babel/new-cap
    () => <Link2 link={srSettingUrl()} text={SETTINGS_TITLE} />,
  ),
];

export const requestCloudRecording = iText(
  'Request host to record to the cloud',
  'apac.recording.request_cloud_recording',
);

export const requestCloudRecordingTitle = iText(
  'Request the host to record to the cloud',
  'apac.recording.request_cloud_recording_title',
);

export const donotRemindMeAgainTip = iText(
  "Don't remind me again",
  'apac.recording.do_not_remind_me_again',
);

export const requestCloudRecordingContent = (name) => {
  return iText(
    `Request ${name} to record this meeting to the cloud.`,
    'apac.recording.request_cloud_recording_content',
    [name],
  );
};

export const receiveCloudRecordingRequestTitle = iText(
  'You received a request to record to the cloud',
  'apac.recording.receive_request_title',
);

export const receiveCloudRecordingRequestContent = (name) =>
  iText(
    `${name} is requesting you to record this meeting to the cloud.`,
    'apac.recording.receive_request_content',
    name,
  );

export const startRecordingButtonText = iText(
  'Start recording',
  'apac.recording.start_button',
);

export const denyText = iText('Deny', 'apac.common.deny');

export const denyAllText = iText('Deny All', 'apac.common.deny_all');

export const receiveLocalRecordingRequestTitle = (name) =>
  iText(
    `${name} is requesting to record this meeting locally`,
    'apac.recording.receive_request_local_content',
    name,
  );

export const allowRecordingText = iText(
  'Allow recording',
  'apac.recording.allow_recording',
);

export const receiveLocalRecordingDialogContent = iText(
  'Apply these permissions to all future requests',
  'apac.recording.local_request_content',
);

export const denyRequestRecordingText = iText(
  'Host denied your request to record to the cloud.',
  'apac.recording.deny_request',
);

export const timeoutRequestRecordingText = iText(
  'The cloud recording request timeout.',
  'apac.recording.timeout_request',
);

export const failedRequestRecordingText = iText(
  'An error occurred.Unable to send cloud recording request.',
  'apac.recording.failed_request',
);

export const unknowFailedRequestRecordingText = iText(
  `Unable to send request because the host has an outdated Zoom client version or isn't in the meeting, or for other reasons.`,
  'apac.recording.unknow_failed_request',
);

export const srContentOfOptionalSR = iText(
  'Once Smart Recording with AI Companion is enabled, your cloud recording will generate the recording highlights, summary and smart chapters, and next steps.',
  'apac.sr.sr_description_optional',
);
export const enableForThisMeetingText = iText(
  'Enable for this meeting',
  'apac.sr.enable_for_this_meeting',
);
export const enableForAllMeetingText = iText(
  'Enable for this and all future meetings',
  'apac.sr.enable_for_all_meeting',
);

export const hostStartSRDialogContentDescription = (insertText) =>
  iText(
    `Once Smart Recording with AI Companion is enabled, your cloud recording will generate recording highlights, summary and smart chapters, and next steps. You can configure this feature at any time in ${insertText}.`,
    'apac.sr.consent_content2',
    insertText,
  );
export const enableAndStartSRText = iText(
  'Enable and start recording',
  'apac.sr.enable_and_start_recording',
);
export const tooltipsText = () => {
  const data = {
    canOptionalEnable: iText(
      'Smart Recording with AI Companion uses AI technology, which may include third-party models, to create recording highlights, summary and smart chapters, and next steps.',
      'apac.sr.optional_enable_tips',
    ),
    allEnabled: reactStringReplace(
      iText(
        'Smart Recording with AI Companion is enabled. Your cloud recording will include recording highlights, summary and smart chapters, and next steps. You can configure this feature at any time in {0}.',
        'apac.sr.already_enable_tips',
      ),
      '{0}',
      // eslint-disable-next-line @babel/new-cap
      () => <Link2 link={srSettingUrl()} text={SETTINGS_TITLE} />,
    ),
    transcriptOff: reactStringReplace(
      iText(
        'To use this feature, you need to enable audio transcript and then this feature in {0}. This feature will only be effective for the next meetings.',
        'apac.sr.transcript_off',
      ),
      '{0}',
      // eslint-disable-next-line @babel/new-cap
      () => <Link2 link={srSettingUrl()} text={SETTINGS_TITLE} />,
    ),
    transcriptOffForNonAdmin: iText(
      'To use this feature, you need to enable audio transcript and then this feature. Your account admin has turned off and locked audio transcript. Contact your account admin for more information.',
      'apac.sr.transcript_off_for_non_admin',
    ),
  };

  return data;
};

export const CANNOT_CHANGE_SETTTINGS_TIPS = iText(
  'You can’t change this setting because of the admin’s settings.',
  'apac.common.can_not_change_setttings_tip',
);

export const ALLOW_REQUEST_START_CMR = iText(
  'Request Host to Start Cloud Recording',
  'apac.security.allow_request_start_cmr',
);

export const CANNOT_REQUEST_HOST_CMR_TIP1 = iText(
  'You can’t send cloud recording requests because of host’s settings.',
  'apac.recording.disable_request_host_cmr_reason1',
);
export const CANNOT_REQUEST_HOST_CMR_TIP2 = iText(
  'Unable to send the request because the host is recording the cloud recording.',
  'apac.recording.disable_request_host_cmr_reason2',
);
export const CANNOT_REQUEST_HOST_CMR_TIP3 = iText(
  'Unable to send the request because the host is recording the local recording.',
  'apac.recording.disable_request_host_cmr_reason3',
);
export const CANNOT_REQUEST_HOST_CMR_TIP4 = iText(
  'Unable to send the request when the host and you are in different scenarios (e.g., different breakout rooms).',
  'apac.recording.disable_request_host_cmr_reason4',
);

export const CANNOT_DENY_ALL_ADMIN_NOT_ALLOW_TIP = iText(
  'Unable to deny all requests because of the admin’s settings.',
  'apac.recording.cannot_deny_all_admit_not_allow_tip',
);

export const DENY_ALL_TIP = iText(
  'Deny all cloud recording requests for this meeting.',
  'apac.recording.deny_all_tip',
);

export const REQUEST_HAS_SENT_TIP = iText(
  'Request has been sent',
  'apac.recording.request_has_sent',
);
