import { createSelector } from 'reselect';
import meetingConfig from 'meetingConfig';
import {
  FILTER_POLL_TYPES,
  PollingTypes,
  PollItemState,
  POLL_URL_TYPE,
  POLL_LIST_TABS,
} from '../constants';
import { isAnsweredValidator, validateQuestion } from '../utils';
import { coOrHostSelector } from '../../../global/redux/selector';
import { restrictFeaturesSelector } from '../../../global/redux/restrict-features-selector';
import { isPollEnabled } from '../../../global/service';
import { isExternalControlledMode } from '../../../global/util';
import { isMeInRoomSelector } from '../../breakout-room/redux/bo-room-selector';
import { JOIN_MEETING_POLICY } from '../../../global/constant';
import _ from 'lodash';
import { isWebinar } from '../../../global/service/meeting-types';
import { isPanelist } from '../../../global/service/user-types';

const pollDataSelector = ({ poll: { pollData } }) => pollData;

const pollingIndexSelector = ({ poll: { pollingIndex } }) => pollingIndex;

const pollingIdSelector = ({ poll: { pollingId } }) => pollingId;

const deletedPollDataSelector = ({ poll: { deletedPollData } }) =>
  deletedPollData;

const launchedPollIdSelector = ({ poll: { launchedPollId } }) => launchedPollId;

const bAllowPanelistVoteSelector = ({ meeting: { bAllowPanelistVote } }) =>
  bAllowPanelistVote;

const filterPollTypeSelector = ({ poll: { filterPollType } }) => filterPollType;

const listActiveTabSelector = ({ poll: { listActiveTab } }) => listActiveTab;

const meetingInfoSelector = ({
  meeting: { baseUrl, isWebinar, meetingNumber, zak },
}) => ({ baseUrl, isWebinar, meetingNumber, zak });

const currentUserRoleSelector = ({
  meeting: {
    currentUser: { userRole },
  },
}) => userRole;

const pollingWebLinksSelector = ({ poll: { pollingWebLinks } }) =>
  pollingWebLinks;

export const currentPollDataSelector = createSelector(
  [
    pollDataSelector,
    pollingIndexSelector,
    pollingIdSelector,
    deletedPollDataSelector,
  ],
  (pollData, pollingIndex, pollingId, deletedPollData) => {
    // no pollingId or no pollData means no current poll data;
    if (
      !pollingId ||
      (!Array.isArray(pollData) && !Array.isArray(deletedPollData)) ||
      (pollData.length === 0 && deletedPollData.length === 0)
    ) {
      return null;
    }

    // try to get result data by index;
    let currentPollData = pollData[pollingIndex];
    if (currentPollData?.PollingId === pollingId) {
      return currentPollData;
    }

    // if failed, search by id;
    currentPollData = pollData.find((data) => data?.PollingId === pollingId);
    if (currentPollData) {
      return currentPollData;
    }

    // search data in deleted pollings
    currentPollData = deletedPollData?.find(
      (polling) => polling.PollingId === pollingId,
    );
    if (currentPollData) {
      return currentPollData;
    }

    // if failed, return null;
    currentPollData = null;
    return currentPollData;
  },
);

export const currentPollNStateSelector = createSelector(
  [currentPollDataSelector],
  (currentPollData) => {
    return (
      currentPollData?.nState ?? PollItemState.ZoomPollState_NotStart_unLaunch
    );
  },
);

export const canCurrentUserAccessPollsOnWebSelector = createSelector(
  [pollingWebLinksSelector],
  () => {
    const {
      isOriginhost,
      isAlternativeHost,
      isAssistant,
      meetingOptions: { isAlternativeHostEnableEditPoll },
    } = meetingConfig;

    const isCurrentRoleHasCaps =
      isOriginhost ||
      isAssistant ||
      (isAlternativeHost && isAlternativeHostEnableEditPoll);

    return isCurrentRoleHasCaps && !isExternalControlledMode();
  },
);

export const canCurrentUserVoteSelector = createSelector(
  [
    // currentPollNStateSelector,
    bAllowPanelistVoteSelector,
    currentUserRoleSelector,
    coOrHostSelector,
  ],
  (bAllowPanelistVote, currentUserRole, coOrHost) => {
    // const isLaunching =
    //   currentPollNState === PollItemState.ZoomPollState_Open_launching;
    const panelist = isPanelist(currentUserRole);
    const notAllowVotePanelist = panelist && !bAllowPanelistVote;

    return !coOrHost && !notAllowVotePanelist;
  },
);

export const answeredQuestionsCountSelector = createSelector(
  [currentPollDataSelector, canCurrentUserVoteSelector],
  (currentPollData, shouldCountAnsweredQuestions) => {
    if (!shouldCountAnsweredQuestions) {
      return 0;
    }
    const questions = currentPollData?.Questions ?? [];

    return (
      questions?.filter(
        (question) =>
          validateQuestion(question, [isAnsweredValidator])?.isValid ?? false,
      )?.length ?? 0
    );
  },
);
const isRwgEnablePollingSelector = (state) => state.poll.isRwgEnablePolling;
const hasPollingInMeetingSelector = (state) => state.poll.hasPollingInMeeting;

export const restrictPollingSelector = createSelector(
  [restrictFeaturesSelector],
  (restrictFeatures) => {
    return !!restrictFeatures[
      isWebinar()
        ? JOIN_MEETING_POLICY.WEBINAR_POLL
        : JOIN_MEETING_POLICY.MEETING_POLL
    ];
  },
);

export const isPollingEnabledSelector = createSelector(
  [
    restrictPollingSelector,
    coOrHostSelector,
    isMeInRoomSelector,
    currentPollNStateSelector,
    canCurrentUserAccessPollsOnWebSelector,
    isRwgEnablePollingSelector,
    hasPollingInMeetingSelector,
  ],
  (
    isRestrictPolling,
    coOrHost,
    isMeInRoom,
    currentPollNState,
    canCurrentUserAccessPollsOnWeb,
    isRwgEnablePolling,
    hasPollingInMeeting,
  ) => {
    if (isRestrictPolling) {
      return false;
    }
    return isPollEnabled({
      coOrHost,
      isMeInRoom,
      currentPollNState,
      canCurrentUserAccessPollsOnWeb,
      isRwgEnablePolling,
      hasPollingInMeeting,
    });
  },
);

export const pollUrlCreatorSelector = createSelector(
  [meetingInfoSelector],
  (meetingInfo) => {
    const { baseUrl, isWebinar, meetingNumber, zak } = meetingInfo;
    const {
      isAlternativeHost,
      isAssistant,
      meetingOptions: { isInstantMeeting },
    } = meetingConfig;

    let urlRoute = isWebinar ? 'webinar' : 'meeting';
    if (isAlternativeHost || isAssistant || isInstantMeeting) {
      urlRoute = 'poll';
    }

    const createPollLink = `${baseUrl}/${urlRoute}/${meetingNumber}?zak=${zak}`;

    const pollUrlCreator = (urlType, targetPollId) => {
      let editPollQuery = '';
      switch (urlType) {
        case POLL_URL_TYPE.LIST:
          editPollQuery = '&editPoll=false';
          break;
        case POLL_URL_TYPE.CREATE:
          editPollQuery = '&editPoll=false';
          break;
        case POLL_URL_TYPE.EDIT:
          editPollQuery = '&editPoll=true';
          break;
        default:
          break;
      }
      return `${createPollLink}${editPollQuery}${
        targetPollId ? `&pollingId=${targetPollId}` : ''
      }#managePoll`;
    };

    return pollUrlCreator;
  },
);

export const pollListSelector = createSelector(
  [pollDataSelector, filterPollTypeSelector, listActiveTabSelector],
  (pollData, filterPollType, listActiveTab) => {
    let filteredList = pollData || [];
    if (listActiveTab === POLL_LIST_TABS.STARRED) {
      filteredList = pollData.filter((poll) => poll.bStarred);
    }
    switch (filterPollType) {
      case FILTER_POLL_TYPES.POLLS:
        filteredList = _.filter(
          filteredList,
          (poll) =>
            poll.nPollType === PollingTypes.ZoomPollType_Basic ||
            poll.nPollType === PollingTypes.ZoomPollType_Advanced,
        );
        break;
      case FILTER_POLL_TYPES.QUIZZES:
        filteredList = _.filter(
          filteredList,
          (poll) => poll.nPollType === PollingTypes.ZoomPollType_Quiz,
        );
        break;
    }

    return filteredList;
  },
);

export const currentPollDeletedSelector = createSelector(
  [pollingIdSelector, deletedPollDataSelector],
  (pollingId, deletedPollData) => {
    if (!deletedPollData) {
      return false;
    }
    return deletedPollData.some(({ PollingId }) => PollingId === pollingId);
  },
);

export const launchingPollDataSelector = createSelector(
  [launchedPollIdSelector, pollDataSelector],
  (launchedPollId, pollData) => {
    if (!pollData) {
      return null;
    }
    return pollData.find(({ PollingId }) => PollingId === launchedPollId);
  },
);
