import React from 'react';

export const transformStringToElement = (text, tag, replace) => {
  const ret = text.split(tag).map((v) => <span key={v}>{v}</span>);
  return (
    <>
      {ret[0]}
      {replace}
      {ret[1]}
    </>
  );
};
