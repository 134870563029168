import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPipInstance, EVENT_NAME_MAP } from '../picture-in-picture';
import { isEnablePictureInPictureFeature } from '../op-feature-option';
import { togglePIP } from '../redux';

const TARGET_ELEMENT_ID = 'video-share-layout';
export const pipInstance = isEnablePictureInPictureFeature()
  ? getPipInstance(TARGET_ELEMENT_ID)
  : null;

export const useExitPip = ({ isPIPMode, loading, isOnHold }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEnablePictureInPictureFeature()) {
      return;
    }
    if (isPIPMode) {
      pipInstance?.on(EVENT_NAME_MAP.AFTER_EXIT, () => {
        if (isPIPMode) {
          dispatch(togglePIP(isPIPMode));
        }
      });
    }

    return () => {
      pipInstance?.off(EVENT_NAME_MAP.AFTER_EXIT);
    };
  }, [isPIPMode, dispatch]);

  useEffect(() => {
    if (!isEnablePictureInPictureFeature()) {
      return;
    }
    if (loading || isOnHold) {
      pipInstance?.closePipWin();
    }
  }, [loading, isOnHold]);
};
