import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const a11yText = (voteCount, voted) => {
  if (voteCount > 0) {
    return voted
      ? `undo upvote, ${voteCount} people upvote, button, checked`
      : `upvote, ${voteCount} people upvote, button, unchecked`;
  }
  return voted ? 'undo upvote, button, checked' : 'upvote, button,unchecked';
};

function VoteWidget({
  onVotedStatusChanged,
  voteCount,
  voted,
  a11yLevelBase,
  a11yWalkerPolicy,
  isDismissed,
}) {
  const clickHandler = () => {
    if (isDismissed) {
      return;
    }
    onVotedStatusChanged(!voted);
  };

  const className = classNames('vote-widget', {
    'vote-widget--voted': voted,
    'vote-widget--dismissed': isDismissed,
  });

  return (
    <button
      type="button"
      className={className}
      onClick={clickHandler}
      data-a-l={a11yLevelBase}
      data-a-walk-policy={a11yWalkerPolicy}
      aria-label={a11yText(voteCount, voted)}
      tabIndex={isDismissed ? -1 : 0}
    >
      <i className="vote-widget__toggle-icon" />
      {voteCount > 0 && (
        <span className="vote-widget__count-indicator">
          {voteCount > 999 ? '999+' : voteCount}
        </span>
      )}
    </button>
  );
}

VoteWidget.propTypes = {
  voted: PropTypes.bool.isRequired,
  voteCount: PropTypes.number.isRequired,
  onVotedStatusChanged: PropTypes.func.isRequired,
  a11yLevelBase: PropTypes.string.isRequired,
  a11yWalkerPolicy: PropTypes.number.isRequired,
  isDismissed: PropTypes.bool.isRequired,
};
export { VoteWidget };

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/global/components/widget/vote-widget.scss';
