import meetingConfig from 'meetingConfig';
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import _ from 'lodash';
import { JOIN_MEETING_POLICY } from '../../../global/constant';
import { isSimulivePhase1 } from '../../../global/service';
import { restrictFeaturesSelector } from '../../../global/redux/restrict-features-selector';
import { isMeetingAnimatedEnabledSelector } from '../../settings/redux/settings-selector';
import {
  ALL_EMOJI_SET_RESOURCE_STATUS,
  REACTION_SET_TYPE,
  FREQUENTLY_USED_EMOJIS_CATEGORY,
  GUEST_USER_KEY,
  NONVERBAL_TYPE,
  TAIWAN_FLAG_CODE_POINT,
  ANIMATED_REACTION_EMOJI_PLACEHOLDER,
} from '../constant';
import {
  getEmojiCodePointBase,
  splitAllEmojiListByLine,
  getEmojiWithSkinTone,
  isSupportedTimeZone,
  checkIsValidNonVerbalFeedback,
  isSupportTWFlagEmoji,
} from '../utils';

const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  _.isEqual,
);
const attendeesListSelector = ({ attendeesList: { attendeesList } }) =>
  attendeesList;

const allEmojiSetResourceStatusSelector = ({
  reaction: { allEmojiSetResourceStatus },
}) => allEmojiSetResourceStatus;
const reactionsRestrictionSelector = ({ reaction: { reactionsRestriction } }) =>
  reactionsRestriction;
export const allEmojiSetSelector = ({ reaction: { allEmojiSet } }) =>
  allEmojiSet;
export const allEmojiMetaSelector = ({ reaction: { allEmojiMeta } }) =>
  allEmojiMeta;
const allEmojiCategoriesSelector = ({ reaction: { allEmojiCategories } }) =>
  allEmojiCategories;
const frequentlyUsedEmojisSelector = ({ reaction: { frequentlyUsedEmojis } }) =>
  frequentlyUsedEmojis;
const currentUserSkinToneSelector = ({ settings: { currentUserSkinTone } }) =>
  currentUserSkinTone;

const receivedReactionsSelector = (
  { reaction: { receivedReactions } },
  userId,
) => receivedReactions[userId] ?? null;

const reactionCountSelector = ({ reaction: { reactionCount } }) =>
  reactionCount;
const NonVerbalFeedbackSelector = (state) => {
  const {
    meeting: {
      currentUser: { bRaiseHand, feedback },
    },
  } = state;

  // const receivedReaction = receivedReactionsSelector(state, userId);
  // const emojiDataSrc = emojiDataSetSelector(state, receivedReaction?.unicode);
  // const emojiDataMeta = emojiDataMetaSelector(state, receivedReaction?.unicode);
  // const isReactionExpired = receivedReaction?.isExpired ?? true;
  // const isSelectedEmoji = receivedReaction?.isSelectedEmoji;
  const shouldRenderRaiseHand =
    bRaiseHand || feedback === NONVERBAL_TYPE.NONVERBAL_HAND;
  const shouldRenderNVF = checkIsValidNonVerbalFeedback(feedback);
  // const shouldRenderReaction =
  //   !isReactionExpired &&
  //   (isSelectedEmoji || { src: emojiDataSrc, meta: emojiDataMeta });

  return shouldRenderNVF || shouldRenderRaiseHand; //|| shouldRenderReaction;
};

const showNonVerbalFeedbackEmojiSelector = createSelector(
  [
    allEmojiMetaSelector,
    allEmojiSetSelector,
    (state) => state.meeting.currentUser.userId,
    (state) => state.reaction.receivedReactions,
  ],
  (allEmojiMeta, allEmojiSet, userId, receivedReactions) => {
    const receivedReaction = receivedReactions[userId];
    const emojiCodePointBase = getEmojiCodePointBase(receivedReaction?.unicode);
    const meta = allEmojiMeta[emojiCodePointBase];
    const src = allEmojiSet[receivedReaction?.unicode];
    const isReactionExpired = receivedReaction?.isExpired ?? true;
    const isSelectedEmoji = receivedReaction?.isSelectedEmoji;
    return !isReactionExpired && (isSelectedEmoji || (src && meta));
  },
);

export const showNonVerbalFeedbackSelector = createSelector(
  [NonVerbalFeedbackSelector, showNonVerbalFeedbackEmojiSelector],
  (v, v2) => v || v2,
);

const nonVerbalFeedbackCountBaseSelector = createSelector(
  [attendeesListSelector],
  (attendees) => {
    const data = {
      handshake: 0,
      yes: 0,
      no: 0,
      faster: 0,
      slower: 0,
      coffee: 0,
    };
    attendees
      .filter((attendee) => !attendee.bHold)
      .forEach((attendee) => {
        if (attendee.bRaiseHand) {
          data.handshake += 1;
        }
        switch (attendee.feedback) {
          case NONVERBAL_TYPE.NONVERBAL_YES:
            data.yes += 1;
            break;
          case NONVERBAL_TYPE.NONVERBAL_NO:
            data.no += 1;
            break;
          case NONVERBAL_TYPE.NONVERBAL_FAST:
            data.faster += 1;
            break;
          case NONVERBAL_TYPE.NONVERBAL_SLOW:
            data.slower += 1;
            break;
          case NONVERBAL_TYPE.NONVERBAL_COFFEE:
            data.coffee += 1;
            break;
          default:
            break;
        }
      });

    return data;
  },
);

const nonVerbalFeedbackCountSelector = createDeepEqualSelector(
  [nonVerbalFeedbackCountBaseSelector],
  (nonVerbalFeedbackCount) => {
    return nonVerbalFeedbackCount;
  },
);

export const hasNonVerbalFeedbackSelector = createSelector(
  [nonVerbalFeedbackCountSelector],
  (nonVerbalFeedbackCount) => {
    return !!Object.values(nonVerbalFeedbackCount).filter(
      (count) => count !== 0,
    ).length;
  },
);

export const emojiDataMetaSelector = ({ reaction: { allEmojiMeta } }) => {
  // const emojiCodePointBase = getEmojiCodePointBase(unicode);
  // const meta = allEmojiMeta[emojiCodePointBase];
  // if (meta) {
  // return meta;
  // }
  // return null;
  return allEmojiMeta;
};
export const emojiDataSetSelector = ({ reaction: { allEmojiSet } }) => {
  // const src = allEmojiSet[unicode];
  // if (src) {
  //   return src;
  // }
  // return null;
  return allEmojiSet;
};

const emojiUniCodeSelector = (_, unicode) => unicode;

const isChatPropsSelector = (state, props) => {
  return Boolean(props?.isChatEmoji);
};

export const makeEmojiDataSelectors = () =>
  createSelector(
    [emojiDataSetSelector, emojiDataMetaSelector, emojiUniCodeSelector],
    (allEmojiSet, allEmojiMeta, unicode) => {
      const emojiCodePointBase = getEmojiCodePointBase(unicode);
      const meta = allEmojiMeta[emojiCodePointBase];
      const src = allEmojiSet[unicode];
      if (meta && src) {
        return { meta, src };
      } else {
        return null;
      }
    },
  );

export const currentUserFrequentlyUsedEmojisSelector = createSelector(
  [frequentlyUsedEmojisSelector],
  (frequentlyUsedEmojis) => {
    const userKey = meetingConfig?.uid ?? GUEST_USER_KEY;
    return frequentlyUsedEmojis[userKey] ?? [];
  },
);

export const isResourceManagerReadySelector = ({
  resourceManager: { initResourceManagerStatus },
}) => initResourceManagerStatus === 'ready';

export const makeReceivedReactionSelectors = () =>
  createSelector(
    [receivedReactionsSelector],
    (receivedReaction) => receivedReaction,
  );

const sortedReactionCountListSelector = createSelector(
  [reactionCountSelector],
  (reactionCount) => {
    return Object.entries(reactionCount)
      .filter((kvReactionCount) => kvReactionCount[0] && kvReactionCount[1])
      .sort((prev, next) => next[1] - prev[1])
      .map((kvReactionCount) => ({
        type: REACTION_SET_TYPE.REACTION,
        code: kvReactionCount[0],
        count: kvReactionCount[1],
      }));
  },
);

const raiseHandCountSelector = createSelector(
  [attendeesListSelector],
  (attendeesList) => {
    const count = attendeesList.filter(
      (attendee) => attendee.bRaiseHand && !attendee.bHold,
    ).length;
    return count;
  },
);

const sortedNonVerbalFeedbackCountListSelector = createSelector(
  [nonVerbalFeedbackCountSelector, raiseHandCountSelector],
  (nonVerbalFeedbackCount, raiseHandCount) => {
    return Object.entries(nonVerbalFeedbackCount)
      .filter((kvNonVerbalFeedbackCount) => {
        if (kvNonVerbalFeedbackCount[0] === 'handshake') {
          return raiseHandCount;
        }

        return kvNonVerbalFeedbackCount[1];
      })
      .map((kvNonVerbalFeedbackCount) => ({
        type: REACTION_SET_TYPE.NONVERBAL,
        code: kvNonVerbalFeedbackCount[0],
        count:
          kvNonVerbalFeedbackCount[0] === 'handshake'
            ? raiseHandCount
            : kvNonVerbalFeedbackCount[1],
      }))
      .sort((prev, next) => {
        if (prev.code === 'handshake') {
          return -1;
        }
        if (next.code === 'handshake') {
          return 1;
        }
        return next.count - prev.count;
      });
  },
);

export const combinedSortedCountListSelector = createSelector(
  [sortedReactionCountListSelector, sortedNonVerbalFeedbackCountListSelector],
  (sortedReactionCountList, sortedNonVerbalFeedbackCountList) => [
    ...sortedNonVerbalFeedbackCountList,
    ...sortedReactionCountList,
  ],
);

export const shouldStartInitiateAllEmojiSetSelector = ({
  reaction: { allEmojiSetResourceStatus },
}) => allEmojiSetResourceStatus === ALL_EMOJI_SET_RESOURCE_STATUS.UNKNOWN;

export const allEmojiListWithoutFrequentlyUsedSelector = createSelector(
  [
    allEmojiSetResourceStatusSelector,
    allEmojiSetSelector,
    allEmojiMetaSelector,
    allEmojiCategoriesSelector,
    currentUserSkinToneSelector,
    reactionsRestrictionSelector,
    isChatPropsSelector,
  ],
  (
    allEmojiSetResourceStatus,
    allEmojiSet,
    allEmojiMeta,
    allEmojiCategories,
    currentUserSkinTone,
    reactionsRestriction,
    isChat,
  ) => {
    if (!(allEmojiSetResourceStatus === ALL_EMOJI_SET_RESOURCE_STATUS.DONE)) {
      return null;
    }

    const AllEmojiListCategoriesMap = {};

    allEmojiCategories.forEach((category) => {
      AllEmojiListCategoriesMap[category.category] = category;
    });

    let metaValues = [];
    if (isChat) {
      if (
        meetingConfig?.meetingOptions?.enableTWEmojiLibrary &&
        isSupportedTimeZone()
      ) {
        // if enableTWEmojiLibrary & supportTimeZone, display all emoji
        metaValues = Object.values(allEmojiMeta);
      }
      if (
        meetingConfig?.meetingOptions?.enableFilterTWEmoji &&
        !isSupportedTimeZone()
      ) {
        metaValues = Object.values(allEmojiMeta).filter(
          (emojiMeta) =>
            emojiMeta?.code_points?.base !== TAIWAN_FLAG_CODE_POINT,
        ); // if is chat and the user is in china, remove the TW Flag
      } else {
        if (isSupportedTimeZone()) {
          metaValues = Object.values(allEmojiMeta);
        } else {
          metaValues = Object.values(allEmojiMeta).filter(
            (emojiMeta) =>
              emojiMeta?.code_points?.base !== TAIWAN_FLAG_CODE_POINT,
          );
        }
      }
    } else {
      metaValues = Object.values(allEmojiMeta).filter(
        (emojiMeta) => reactionsRestriction[emojiMeta?.code_points?.base], // If is not chat, the restriction will ensure that the tw flag is not in.
      );
    }

    const allEmojiList = metaValues
      .map((meta) => {
        const src = getEmojiWithSkinTone(
          currentUserSkinTone,
          meta,
          allEmojiSet,
        );
        return {
          meta,
          src,
          category: AllEmojiListCategoriesMap[meta.category],
        };
      })
      .sort((a, b) => a.meta.order - b.meta.order);

    return allEmojiList;
  },
);

const allEmojiListSelector = createSelector(
  [
    allEmojiListWithoutFrequentlyUsedSelector,
    allEmojiSetSelector,
    allEmojiMetaSelector,
    allEmojiCategoriesSelector,
    currentUserFrequentlyUsedEmojisSelector,
    currentUserSkinToneSelector,
    reactionsRestrictionSelector,
    isChatPropsSelector,
    isMeetingAnimatedEnabledSelector,
  ],
  (
    allEmojiListWithoutFrequentlyUsed,
    allEmojiSet,
    allEmojiMeta,
    allEmojiCategories,
    currentUserFrequentlyUsedEmojis,
    currentUserSkinTone,
    reactionsRestriction,
    isChatProps,
    isMeetingAnimatedEnabled,
  ) => {
    if (!allEmojiListWithoutFrequentlyUsed) return null;

    const AllEmojiListCategoriesMap = {};

    allEmojiCategories.forEach((category) => {
      AllEmojiListCategoriesMap[category.category] = category;
    });

    const frequentlyUsedEmojis = currentUserFrequentlyUsedEmojis.map(
      (unicode) => {
        const meta = allEmojiMeta[unicode];
        const src = getEmojiWithSkinTone(
          currentUserSkinTone,
          meta,
          allEmojiSet,
        );

        return {
          meta,
          src,
          category: FREQUENTLY_USED_EMOJIS_CATEGORY,
        };
      },
    );

    if (isChatProps) {
      return [...frequentlyUsedEmojis, ...allEmojiListWithoutFrequentlyUsed];
    }

    if (isMeetingAnimatedEnabled) {
      return [
        ...ANIMATED_REACTION_EMOJI_PLACEHOLDER,
        ...frequentlyUsedEmojis.filter(
          (emoji) => reactionsRestriction[emoji.meta?.code_points?.base],
        ),
        ...allEmojiListWithoutFrequentlyUsed,
      ];
    }

    return [
      ...frequentlyUsedEmojis.filter(
        (emoji) => reactionsRestriction[emoji.meta?.code_points?.base],
      ),
      ...allEmojiListWithoutFrequentlyUsed,
    ];
  },
);

export const allEmojiMetaWithFrequentlyUsedSelector = createSelector(
  [
    allEmojiMetaSelector,
    currentUserFrequentlyUsedEmojisSelector,
    reactionsRestrictionSelector,
    isChatPropsSelector,
  ],
  (
    allEmojiMeta,
    currentUserFrequentlyUsedEmojis,
    reactionsRestriction,
    isChat,
  ) => {
    if (
      currentUserFrequentlyUsedEmojis?.length === 0 &&
      _.isEmpty(reactionsRestriction)
    ) {
      return allEmojiMeta;
    }
    const frequentlyUsedEmojisMeta = {};
    currentUserFrequentlyUsedEmojis.forEach((unicode, index) => {
      frequentlyUsedEmojisMeta[`frequent-${index}`] = {
        ...allEmojiMeta[unicode],
        category: 'frequently',
        order: index,
      };
    });
    let emojiMeta = Object.assign({}, allEmojiMeta, frequentlyUsedEmojisMeta);
    if (isChat && !isSupportTWFlagEmoji()) {
      emojiMeta = _.omitBy(
        emojiMeta,
        (meta) => meta?.code_points?.base === TAIWAN_FLAG_CODE_POINT,
      );
    } else if (!_.isEmpty(reactionsRestriction) && !isChat) {
      emojiMeta = _.pickBy(
        emojiMeta,
        (meta) => reactionsRestriction[meta?.code_points?.base],
      );
    }

    return emojiMeta;
  },
);

export const lineSplitedAllEmojiListSelector = createSelector(
  [allEmojiListSelector],
  (allEmojiList) => {
    const [lines, lineCategories] = splitAllEmojiListByLine(allEmojiList);
    return [lines, lineCategories];
  },
);

const isRestrictReactionsSelector = createSelector(
  [restrictFeaturesSelector],
  (restrictFeatures) => {
    return restrictFeatures[JOIN_MEETING_POLICY.REACTIONS];
  },
);

export const isRestrictNonverbalSelector = createSelector(
  [restrictFeaturesSelector],
  (restrictFeatures) => {
    return restrictFeatures[JOIN_MEETING_POLICY.NON_VERBAL_FEEDBACK];
  },
);

export const isReactionsEnableSelector = createSelector(
  [isRestrictReactionsSelector],
  (isRestrictReactions) => {
    if (isRestrictReactions) return false;
    return meetingConfig.meetingOptions.isEnableMeetingReactions;
  },
);

export const isNonverbalFeedbackEnableSelector = createSelector(
  [isRestrictNonverbalSelector],
  (isRestrictNonverbal) => {
    if (isRestrictNonverbal) return false;
    return meetingConfig.meetingOptions.nonverbalfeedback;
  },
);

export const isOnlyEnableSelectedEmojisSelector = createSelector(
  [isRestrictReactionsSelector],
  (isRestrictReactions) => {
    if (isRestrictReactions) return false;
    return meetingConfig.meetingOptions.enableSelectedEmojis;
  },
);

export const isRestrictWebinarReactionSelector = (state) => {
  return state.meeting.restrictFeatures[
    JOIN_MEETING_POLICY.DISABLE_WEBINAR_REACTIONS
  ];
};

export const webinarReactionSettingEnabledSelector = ({
  reaction: { isWebinarReactionAllowed, isWebinarLockOffReaction },
}) =>
  !isWebinarLockOffReaction && isWebinarReactionAllowed && !isSimulivePhase1();

export const shouldRenderReactionEmojiLauncherSelector = createSelector(
  [webinarReactionSettingEnabledSelector, isRestrictWebinarReactionSelector],
  (isWebinarReactionSettingEnabled, isRestrictWebinarReaction) => {
    if (isRestrictWebinarReaction) return false;
    return isWebinarReactionSettingEnabled;
  },
);
