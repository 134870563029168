import { handleAddRoomsIndication } from './redux/bo-room-thunk-aciton';

let draftRooms = [];
let cacheRooms = [];
let mergeIndicationTimer = null;
let messages = [];

export const updateDraftRooms = (seq) => {
  draftRooms.forEach((room) => {
    room.requestId += seq;
  });
};

export const clearDraftRooms = () => {
  draftRooms = [];
};

export const addDraftRoom = (room) => {
  draftRooms.push(room);
};

export const removeDraftRoom = (requestId) => {
  const index = draftRooms.findIndex((room) => room.requestId === requestId);
  if (index > -1) {
    draftRooms.splice(index, 1);
  }
};

export const getDraftRoom = (requestId) => {
  return draftRooms.find((room) => room.requestId === requestId);
};

export const getDraftRoomSize = () => {
  return draftRooms.length;
};

export const clearCacheRooms = () => {
  cacheRooms = [];
};
export const addCacheRoom = (room) => {
  cacheRooms.push(room);
};
export const getCacheRooms = () => cacheRooms;

export const mergeAddRoomIndications = (dispatch, message) => {
  const {
    body: { bid },
    seq,
  } = message;
  messages.push({
    bid,
    seq,
  });
  if (mergeIndicationTimer) {
    clearTimeout(mergeIndicationTimer);
  }
  mergeIndicationTimer = setTimeout(() => {
    dispatch(handleAddRoomsIndication(messages));
    messages = [];
    clearTimeout(mergeIndicationTimer);
  }, 200);
};
