import { createSelector } from 'reselect';
import { NEW_LT_LANGUAGE, NEW_LT_MESSAGE_SOURCE } from '../constant';
import meetingConfig from 'meetingConfig';
import { restrictFeaturesSelector } from '../../../global/redux/restrict-features-selector';
import { JOIN_MEETING_POLICY } from '../../../global/constant';
import { isSimulivePhase1 } from '../../../global/service';
import {
  isInBOSelector,
  isMeInRoomSelector,
} from '../../breakout-room/redux/bo-room-selector';
import { isManualCCWebSettingEnabled } from '../service';

export const isShowCCButton = (state) => {
  const restrictCC = restrictFeaturesSelector(state)[JOIN_MEETING_POLICY.CC];
  const newLTFeatureEnabled = state.newLiveTranscription.newLTFeatureEnabled;
  const isLiveTranscriptionInMeetingEnabled =
    isLiveTranscriptionInMeetingEnabledSelector(state);
  const isManualCCInMeetingEnabled = isManualCCInMeetingEnabledSelector(state);
  const isHost = state.meeting.isHost;
  const isCaptionDisabled = state.newLiveTranscription.isCaptionDisabled;
  const isInBO = isInBOSelector(state);
  return (
    !restrictCC &&
    newLTFeatureEnabled &&
    !isSimulivePhase1() &&
    (isLiveTranscriptionInMeetingEnabled || isManualCCInMeetingEnabled) &&
    (!isCaptionDisabled || (isHost && !isInBO))
  );
};
const ltMessage = ({ newLiveTranscription: { newLTMessage } }) => {
  return newLTMessage;
};

const selectedTranslateToLanguageSelector = ({
  newLiveTranscription: { selectedTranslateToLanguage },
}) => selectedTranslateToLanguage;

const captionTypeSelector = (state) => {
  const {
    newLiveTranscription: { isNewLTManualCCOn, isTranslateOff },
  } = state;
  if (!isTranslateOff) {
    return 'translation';
  } else if (isNewLTManualCCOn) {
    return 'manual';
  } else {
    return 'transcription';
  }
};

const filterTranslation = (newLTMessage, selectedTranslateToLanguage) => {
  const values = Object.values(newLTMessage);
  return values.filter(
    (v) =>
      v.text &&
      v.source === NEW_LT_MESSAGE_SOURCE.LTTSource_ASR &&
      v.language === selectedTranslateToLanguage,
  );
};

const filterTranscription = (newLTMessage) => {
  const values = Object.values(newLTMessage);
  return values.filter(
    (v) =>
      v.text &&
      v.source === NEW_LT_MESSAGE_SOURCE.LTTSource_ASR &&
      (v.language === NEW_LT_LANGUAGE.LTTLanguage_ID_NoTranslation ||
        v.language === NEW_LT_LANGUAGE.LTTLanguage_ID_DefaultManualInput),
  );
};

const filterManualCaption = (newLTMessage) => {
  const values = Object.values(newLTMessage);
  return values
    .filter(
      (v) =>
        v.text &&
        (v.source === NEW_LT_MESSAGE_SOURCE.LTTSource_InMeetingManual ||
          v.source === NEW_LT_MESSAGE_SOURCE.LTTSource_ExternalCaptioner),
    )
    .slice(-1);
};
const getDurationFrom = (targetTimeString) => {
  const now = new Date();
  const [h, m, s] = targetTimeString.split(':') ?? [];
  // Define the target time '10:23:24'
  const targetTime = new Date();
  targetTime.setHours(h);
  targetTime.setMinutes(m);
  targetTime.setSeconds(s);

  // Calculate the difference in milliseconds
  const durationMs = now - targetTime;

  //   var hours = Math.floor(durationMs / (1000 * 60 * 60));
  //   var minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);
  return seconds;
};
const lastTwoParagraph = (
  newLTMessage,
  captionType,
  selectedTranslateToLanguage,
) => {
  let filter = null;
  switch (captionType) {
    case 'transcription':
      filter = filterTranscription;
      break;
    case 'translation':
      filter = filterTranslation;
      break;
    case 'manual':
      return filterManualCaption(newLTMessage);
    default:
      break;
  }
  if (!filter) {
    return [];
  }
  const messages = filter(newLTMessage, selectedTranslateToLanguage);
  const concatText = (arr) => {
    return arr.reverse().reduce((s, v) => {
      return s.concat(v.text);
    }, '');
  };
  let dismiss = false;
  const collection = messages
    .reverse()
    .filter((v) => {
      if (!dismiss && getDurationFrom(v.messageTime) < 20) {
        return true;
      } else {
        dismiss = true;
        return false;
      }
    })
    .reduce((s, v) => {
      const sender = v.user?.userId;
      if (!s[sender]) {
        if (Object.keys(s).length === 2) {
          return s;
        }
        s[sender] = [];
      }
      if (s[sender].length > 5) {
        return s;
      }
      if (s[sender].length > 0) {
        if (
          s[sender][0].audioLanguage !== v.audioLanguage ||
          s[sender][0].language !== v.language
        ) {
          return s;
        }
      }
      s[sender].push(v);
      return s;
    }, {});
  return Object.values(collection).map((items) => {
    return {
      ...items[items.length - 1],
      text: concatText(items),
    };
  });
};
export const subtitleSelector = createSelector(
  [ltMessage, captionTypeSelector, selectedTranslateToLanguageSelector],
  lastTwoParagraph,
);

export const isCurrentCCEditor = ({
  meeting: {
    currentUser: { userId },
    closedCaption: { ccEditor },
    zoomId,
  },
  breakoutRoom: { mainSessionAttendeeList },
}) => {
  const userIdInMain = mainSessionAttendeeList.find((v) => v.zoomID === zoomId);

  return ccEditor && (ccEditor === userId || ccEditor === userIdInMain?.userId);
};

const isCCPermission = (state) => {
  const {
    meeting: {
      currentUser: { isHost },
      closedCaption: { ccEditor },
    },
  } = state;
  return isCurrentCCEditor(state) || (!ccEditor && isHost);
};

export const isCCPermissionSelector = createSelector(
  [isCCPermission],
  (v) => v,
);

export const isCurrentCCEditorSelector = createSelector(
  [isCurrentCCEditor],
  (v) => v,
);

export const isManualCCInMeetingEnabledSelector = createSelector(
  [restrictFeaturesSelector],
  (restrictFeature) => {
    if (restrictFeature[JOIN_MEETING_POLICY.CC]) return false;
    return isManualCCWebSettingEnabled();
  },
);

export const isLiveTranscriptionInMeetingEnabledSelector = createSelector(
  [restrictFeaturesSelector],
  (restrictFeature) => {
    if (restrictFeature[JOIN_MEETING_POLICY.DISABLE_NEW_LT]) return false;
    return meetingConfig.meetingOptions?.isEnableLiveTranscription;
  },
);

export const onlyExistManualCCSelector = createSelector(
  [
    isManualCCInMeetingEnabledSelector,
    isLiveTranscriptionInMeetingEnabledSelector,
  ],
  (enableManualCC, enableLiveTranscription) => {
    return enableManualCC && !enableLiveTranscription;
  },
);
export const selectedTranslationLanguageSelector = (state) =>
  state.newLiveTranscription.selectedTranslateToLanguage;

export const selectedTranscriptionLanguageSelector = (state) =>
  state.newLiveTranscription.selectedTranscriptionLanguage;
export const hasAnyoneEnabledTranscription = (state) => {
  return state.newLiveTranscription.hasTranscriptionEnabled;
};

export const hasAnyoneEnabledTranslation = (state) => {
  return state.newLiveTranscription.hasTranslationEnabled;
};

export const thirdCCURL = (state) => {
  if (!meetingConfig.meetingOptions?.isEnableAllowThirdPartyCCToken) {
    return null;
  }
  const isMeInRoom = isMeInRoomSelector(state);
  const confId = state.meeting.confId;
  let newCCUrl = state.newLiveTranscription.ccUrl || meetingConfig.ccUrl;
  if (isMeInRoom) {
    newCCUrl += `&subconfid=${confId}`;
  }
  return newCCUrl;
};
