const EVT_TYPE_BASE = 0x1000;
const EVT_TYPE_VIDEO_BASE = 0x3000;
export const EVT_TYPE_WS_CONF_LEAVE_REQ = 7 | EVT_TYPE_BASE;

export const MP4Codec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
export const SimuliveStatus = {
  NOT_STARTED: 0,
  STARTED: 1,
  ENDED: 2,
};

// jsMediaSDK notify event type
export const ADD_RENDER_VIDEO = 1;
export const STOP_RENDER_VIDEO = 2;
export const START_CAPTURE_VIDEO = 3;
export const STOP_CAPTURE_VIDEO = 4;
export const CHANGE_VIDEO_CAPTURE_DEVICE = 12;
export const UPDATE_CANVAS_SIZE = 46;
export const ZOOM_RENDER_VIDEO = 48;
export const MIRROR_MY_VIDEO = 75;
export const SWITCH_CANVAS_FOR_VIDEO_CAPTURE = 25;

// rwg event type
export const EVT_TYPE_WS_VIDEO_SUBSCRIBE_REQ = 1 | EVT_TYPE_VIDEO_BASE; // 12289
export const EVT_TYPE_WS_VIDEO_UNSUBSCRIBE_REQ = 3 | EVT_TYPE_VIDEO_BASE; // 12291
export const EVT_TYPE_WS_VIDEO_MUTE_VIDEO_REQ = 9 | EVT_TYPE_VIDEO_BASE;

// visibility change event
const visibilityChangeEventMap = {
  hidden: 'visibilitychange',
  webkitHidden: 'webkitvisibilitychange',
  mozHidden: 'mozvisibilitychange',
  msHidden: 'msvisibilitychange',
};
export const visibilityChangeEvent = [
  'hidden',
  'webkitHidden',
  'mozHidden',
  'msHidden',
].reduce((preValue, currentValue) => {
  if (!preValue && document[currentValue] !== undefined) {
    return {
      event: visibilityChangeEventMap[currentValue],
      value: currentValue,
    };
  }
  return preValue;
}, undefined);

export const VIDEO_CANVAS = {
  MAIN: 'main-video',
  SPEAKVIEW: 'speak-view-video',
  MULTI_VIEW_VIDEO: 'multi-view-video',
  SUSPENSION_VIDEO: 'suspension-video',
  MY_VIDEO: 'my-video',
  MAIN_SELF: 'main-video-self',
  SUSPENSION_VIDEO_SELF: 'suspension-video-self',
  SUSPENSION_VIDEO_SELF_SMALL: 'suspension-video-self-small',
  MINIMIZE_VIDEO: 'minimize-video',
  INTERPRETER_VIDEO: 'interpreter-video',
  SIMULIVE_STREAM_VIDEO: 'simu-live-stream-video-canvas',
};

export const VIDEO_LAYOUT_ZONE = {
  GALLERY_MAIN_VIDEO: 11,
  SPEAKER_BAR_VIDEO: 21,
  SPEAKER_ACTIVE_VIDEO: 22,
  SUSPENSION_VIDEO: 31,
  MY_VIDEO: 61,
  MINIMIZE_VIDEO: 81,
  INTERPRETER_VIDEO: 91,
  SIMULIVE_STREAM_VIDEO: 101,
  MULTI_SPEAKER_ACTIVE_VIDEO: 111,
  MULTI_SPEAKER_MAIN_VIDEO: 112,
};

// export const VIDEO_TYPE_NO_VIDEO = -1;
export const VIDEO_TYPE_90P = 0;
export const VIDEO_TYPE_180P = 1;
export const VIDEO_TYPE_360P = 2;
export const VIDEO_TYPE_720P = 3;
export const VIDEO_TYPE_1080P = 4;

export const MUTE_VIDEO_MODAL_START = 1;
export const MUTE_VIDEO_MODAL_STOP = 2;

// for normal mode
export const VIDEO_LAYOUT_SPEAK_VIEW = 'speak-view';
export const VIDEO_LAYOUT_GALLERY_VIEW = 'gallery-view';
export const VIDEO_LAYOUT_MULTI_SPEAKER_VIEW = 'multi-speaker-view';
// for receive share mode
export const VIDEO_LAYOUT_STANDARD_VIEW = 'standard';
export const VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW =
  'side-by-side-speaker-view';
export const VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW =
  'side-by-side-gallery-view';
export const VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW =
  'side-by-side-multi-speaker-view';

export const MINIMIZED_VIDEO = 0;
export const ACTIVE_VIDEO = 1;
export const MULTI_VIDEO = 2;
export const LARGE_VIDEO = 3;

export const WS_CONF_SET_DRAG_LAYOUT = 4218;
export const WS_CONF_DRAG_LAYOUT_INDICATION = 7957;

export const ALL_COLOR_AFTER_VIDEO_STOP = {
  R: 17 / 255.0,
  G: 17 / 255.0,
  B: 17 / 255.0,
  A: 1,
};

export const VIDEO_LAYOUT = {
  'speak-view': 0,
  'gallery-view': 1,
  'side-by-side-speaker-view': 0,
  'side-by-side-gallery-view': 1,
  standard: 0,
};

export const VIDEO_LAYOUT_NO_SHARE = {
  0: 'speak-view',
  1: 'gallery-view',
};

export const VIDEO_LAYOUT_SIDE_BY_SIDE = {
  0: 'side-by-side-speaker-view',
  1: 'side-by-side-gallery-view',
};

export const VIDEO_LAYOUT_SHARE = {
  0: 'standard',
  1: 'standard',
};

export const SIDE_BY_SIDE_MODE = {
  CLOSE: 1,
  OPEN: 3,
};

export const ATTENDEE_VIDEO_MODE = {
  ACTIVE: 0,
  GALLERY: 1,
  FOLLOW: 2,
};

export const ATTENDEE_FLAG = {
  FOLLOW_STANDARD: 101 & 0x0f, // 165 37 181 117 63
  FOLLOW_SIDE_BY_SIDE: 103 & 0x0f,
  FOLLOW_STANDARD_SWAP: 109 & 0x0f, // 173 45 189 125 61
  CANCEL_STANDARD_SWAP: 111 & 0x0f,
};

export const ATTENDEE_SEND_FLAG = {
  FOLLOW_STANDARD: 101,
  FOLLOW_SIDE_BY_SIDE: 103,
  FOLLOW_STANDARD_SWAP: 109,
  CANCEL_STANDARD_SWAP: 111,
};

export const VIDEO_LAYOUT_PARAMS = {
  'speak-view': {
    AttendeeVideoControlMode: 0,
    AttendeeVideoControlModeEx: 1,
  },
  'gallery-view': {
    AttendeeVideoControlMode: 1,
    AttendeeVideoControlModeEx: 1,
  },
  standard: {
    AttendeeVideoControlMode: 0,
    AttendeeVideoControlModeEx: 1,
  },
  'side-by-side-speaker-view': {
    AttendeeVideoControlMode: 0,
    AttendeeVideoControlModeEx: 3,
  },
  'side-by-side-gallery-view': {
    AttendeeVideoControlMode: 1,
    AttendeeVideoControlModeEx: 3,
  },
  'no-share-follow': {
    AttendeeVideoControlMode: 2,
    AttendeeVideoControlModeEx: 1,
  },
  'share-follow': {
    AttendeeVideoControlMode: 2,
    AttendeeVideoControlModeEx: 3,
  },
};

export const VERTICAL_RIGHT_MIN_WIDTH = 274;
export const VERTICAL_LEFT_MAX_WIDTH = 430;
export const VERTICAL_LEFT_MIN_WIDTH = 254;
export const VERTICAL_LEFT_MINI_WIDTH = 126;
export const FOOTER_HEIGHT = 52;

// https://zoomvideo.atlassian.net/wiki/spaces/ZW/pages/2229999947/Walker+ZOOM-317791+Watermark+Admin+Controls+and+A+G+U+settings+-+Web+Client+meeting+backend
export const WATERMARK_COVER_TYPE = {
  DEFAULT: 0,
  SINGLE_INSTANCE: 1,
  REPEATED: 2,
};

export const WATERMARK_VISIBLE_ON = {
  DEFAULT: 0,
  SHARED_CONTENT: 1,
  VIDEO_FEEDS: 2,
  BOTH: 3,
};

export const ConnectionType = {
  AUDIO: 1,
  SHARING: 2,
  VIDEO: 3,
};

export const WATERMARK_POSITION = {
  DEFAULT: 0,
  TOP: 1,
  BOTTOM: 2,
  LEFT: 4,
  MIDDLE: 16,
  RIGHT: 8,
};

/**
 * some styles of nametag change with the video size
 * based on the following two size design style,
 * if given the video size 1000, we can calcaute the current heigth
 * by data (180, 25.67) and (2560, 277.73).
 */
export const DESIGN_SMALLEST_STYLE = {
  video_size: 320,
  height: 24,
  margin: 2,
  padding: 2,
  logo_width: 1, //logoHeight
  logo_left: 2,
  logo_right: 2,
  font: 9,
  radius: 3,
};

export const DESIGN_BIGGEST_STYLE = {
  video_size: 1920,
  height: 170,
  margin: 20,
  padding: 24,
  logo_width: 14, //logoHeight
  logo_left: 36,
  logo_right: 27,
  font: 46,
  radius: 27,
};

// originHeight - HEADER_HEIGHT(48)
export const QUALITY_RANGE_MAP = {
  VIDEO_TYPE_90P: {
    MIN: 0,
    MAX: 300,
  },
  VIDEO_TYPE_180P: {
    MIN: 300,
    MAX: 500,
    MIN_9: 180,
  },
  VIDEO_TYPE_360P: {
    MIN: 452,
    MAX: 5000,
    MIN_4: 270,
  },
  VIDEO_TYPE_720P: {
    // unit DPR
    MIN_1_WINDOWS: 652, //700 broswer bar  firfox 80  egde/chrome 70
    MIN_1_MAC: 652,
    MIN_1_CHROMEBOOK: 472,
  },
  VIDEO_TYPE_1080P: {
    // unit DPR
    MIN: 802,
  },
};

export const VIDEO_ACTIVE_SSRC_TYPE = {
  ACTIVE_VIDEO_TALKING_ID: 'activeVideoTalkingId',
  ACTIVE_VIDEO_ID: 'activeVideoId',
};

export const HORIZONTAL_TOP_HEIGHT = 120;
export const TESLA_HORIZONTAL_TOP_HEIGHT = 227;

export const STRIC_WIDTH = {
  SUSPENSION_VIDEO: 254,
  INTERPRETER_VIDEO: 396,
  GALLERY_MAIN_VIDEO: 234,
  SINGLE_VIDEO: 254,
  TESLA_SUSPENSION_VIDEO: 340,
};

export const DEFAULT_SPEAKER_BAR_ASPECT_RATIO = [16, 9];
export const DEFAULT_SPEAKER_ACTIVE_ASPECT_RATIO = [16, 9];
export const DEFAULT_TESLA_BAR_ASPECT_RATIO = [340, 227];
export const DEFAULT_TESLA_SUSPENSION_WIDTH_HEIGHT = [340, 227];

export const VIDEO_FILL_MODE = {
  ON: 1,
  OFF: 0,
};

export const GALLERY_ORDER_TYPE = {
  DEFAULT: 0,
  FIRST_NAME_ORDER_ASC: 1,
  FIRST_NAME_ORDER_DES: 2,
  LAST_NAME_ORDER_ASC: 3,
  LAST_NAME_ORDER_DES: 4,
  MEETING_ENTRY_TIME_ORDER_ASC: 5,
  MEETING_ENTRY_TIME_ORDER_DES: 6,
};

export const SELF_VIEW_DEFAULT_ORDER = {
  FIRST: 1,
  SECOND: 2,
};

export const FAR_END_CAMERA_CONTROL_CMD = {
  REQUEST: 11,
  DECLINE: 12,
  APPROVE: 13,
  GIVE_UP: 14,
  START_CONTROL: 15,
  CONTINUE_CONTROL: 16,
  STOP_CONTROL: 17,
  SWITCH_CAMERA: 20,
};

export const FAR_END_CAMERA_BUTTON_ID = {
  BK: 0,
  CENTER: 1,
  ZOOM_IN: 2,
  ZOOM_OUT: 3,
  LEFT: 4,
  RIGHT: 5,
  UP: 6,
  DOWN: 7,
  SWITCH_CAMERA: 8,
};

export const FAR_END_CAMERA_PTZ = {
  PAN: 0xc0,
  TILT: 0x30,
  ZOOM: 0x0c,
  FOCUS: 0x03,
};

export const FAR_END_CAMERA_CONTROL_DECLINED_REASON = {
  NONE: 0,
  APPROVE_ANOTHER: 3,
  STOP: 5,
};

export const PTZCameraStep = 5;
export const PTZCameraRange = 100;

export const CAMERA_CONTROL_GROUP_ACTION_TYPE = {
  EXISITED: 'exisited',
  NEW: 'new',
  REMOVE: 'remove',
};

export const MULTI_SPEAKER_VIEW_ACTIVE_AREA_DEFAULT_SCALE = 0.7;
export const MULTI_SPEAKER_MIN_VIDEO_HEIGHT_BUFFER_SIZE = 10;
export const MULTI_SPEAKER_VIEW_RESIZER_HEIGHT = 12;
