import { CHAT_FILE_READER_CHUNK_SIZE } from '../../chat/constants';
import { readFileAsChunk } from '../../settings/service';
import { bufferToHex } from '../../chat/redux/chat-thunk-action';

export function calcFileHashHex(file) {
  // resolve large size file
  const chunkNum = Math.ceil(file.size / CHAT_FILE_READER_CHUNK_SIZE);
  const promiseCollection = [];
  for (let i = 0; i < chunkNum; i++) {
    promiseCollection.push(
      new Promise((resolve, reject) => {
        readFileAsChunk(
          file,
          i * CHAT_FILE_READER_CHUNK_SIZE,
          (i + 1) * CHAT_FILE_READER_CHUNK_SIZE,
        )
          .then((e) => {
            window.crypto.subtle
              .digest('SHA-256', e.target.result)
              .then((hashArray) => {
                resolve(bufferToHex(hashArray));
              });
          })
          .catch((e) => reject(e));
      }),
    );
  }
  return new Promise((resolve, reject) => {
    Promise.all(promiseCollection)
      .then((results) => {
        resolve(results.join(''));
      })
      .catch((e) => reject(e));
  });
}
