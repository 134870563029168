import {
  CONTROL_MODE_COMMON_ERROR_TYPE,
  JOIN_FLOW_ERROR_CODE,
} from '../../enum';
import {
  startCaptureVideo,
  stopCaptureVideo,
} from '../../../features/video/redux/thunks/start-stop-capture-video-thunk';
import { setIsNeedNotifyStartVideoFail } from '../../../features/video/redux/video-action';
import {
  unmuteAudio,
  muteAudio,
} from '../../../features/audio/redux/audio-thunk-action';
import { setSelfMuteOrUnmute } from '../../../features/audio/redux/audio-action';
import { coOrHostSelector } from '../../../global/redux';
import { setUnmuteByHostDialogVisible } from '../../../features/dialog/redux/dialog-action';
import { isVideoEncodeReadySelector } from '../../../features/video/redux/selectors/video-status-selector';
import { externalStatus } from '../../../global/util';
import { showNotAllowUnmuteDialog } from '../../../features/dialog/redux/dialog-thunk-action';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';
import { leaveMeetingThunkAction } from '../../../global/redux/thunk-action/end-meeting-request';
import { reportToGlobalTracing } from '../../../global/logger';
import { noAvailableCameraSelector } from '../../../features/video/redux/selectors/camera-control-selector';

export function externalEvtHandler(store, controller, adaptor) {
  const { getState, dispatch } = store;

  controller.addEventListener('action', ({ detail: { type } }) => {
    const state = getState();
    const {
      meeting: { res, currentUser, bCanUnmute },
      video: {
        UI: { loading },
        isCameraCaptureLoading,
        isCameraForbidden,
        isNeedNotifyStartVideoFail,
      },
      audio: { isMicrophoneForbidden },
      meeting: {
        loginUserUid,
        bCanUnmuteVideo,
        currentUser: {
          bVideoMute: isCurrentUserVideoMuted,
          isAllowTalk: isCurrentUserAllowTalk,
        },
      },
      settings: {
        vbSettings: { enableVB, enableForceUseVB },
      },
      dialog: {
        unmuteByHost: { visible: isUnmuteByHostVisible },
      },
    } = state;

    const isVideoEncodeReady = isVideoEncodeReadySelector(state);
    const coOrHost = coOrHostSelector(state);
    const isWb = isWebinar();
    const isViewOnlyUser = isViewOnly(currentUser.userRole);
    const noAvailableCamera = noAvailableCameraSelector(state);
    switch (type) {
      case 'muteAudio':
        if (isMicrophoneForbidden) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.MUTE_AUDIO,
          });

          return false; // report error to MSFT
        }
        if (isWb && isViewOnlyUser && !isCurrentUserAllowTalk) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
          });
          return false; // report error to MSFT
        }
        dispatch(setSelfMuteOrUnmute(true));
        dispatch(muteAudio(currentUser.userId));
        break;
      case 'unmuteAudio': {
        if (isMicrophoneForbidden) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
          });
          return false;
        }
        if (isWb && isViewOnlyUser && !isCurrentUserAllowTalk) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
          });
          dispatch(showNotAllowUnmuteDialog());
          return false;
        }
        if (!bCanUnmute && currentUser.muted && !coOrHost) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
          });
          dispatch(showNotAllowUnmuteDialog());
          return false;
        }

        if (isUnmuteByHostVisible) {
          dispatch(setUnmuteByHostDialogVisible(false));
        }
        dispatch(setSelfMuteOrUnmute(true));
        dispatch(unmuteAudio(currentUser.userId));
        break;
      }
      case 'muteVideo': {
        if (isWb && isViewOnlyUser) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
          });
          return false;
        }
        if (isCameraCaptureLoading || isCameraForbidden) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.STOP_VIDEO,
          });
          return false; // report error to MSFT
        }
        dispatch(stopCaptureVideo());
        break;
      }
      case 'unmuteVideo': {
        if (isWb && isViewOnlyUser) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
          });
        }

        if (!isVideoEncodeReady || loading) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
          });
        }
        if (noAvailableCamera) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
          });
        }
        if (
          (isCurrentUserVideoMuted && !coOrHost) ||
          (!bCanUnmuteVideo && !coOrHost)
        ) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
          });
        }

        if (loginUserUid && enableForceUseVB && !enableVB) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
          });
        }

        if (isNeedNotifyStartVideoFail) {
          dispatch(setIsNeedNotifyStartVideoFail(false));
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
          });
        }

        dispatch(startCaptureVideo());
        break;
      }

      case 'userInitiatedLogUpload': {
        const config = {
          userId: currentUser?.userId,
          filter: () => true,
          confId: currentUser?.confId,
          conID: currentUser?.conId,
          svcUrl: currentUser?.svcUrl,
          res,
        };

        reportToGlobalTracing(config);
        adaptor.notifyController({ userInitiatedLogUploaded: true });
        break;
      }

      case 'hangup': {
        /* eslint-disable-next-line no-console */
        console.log(
          'received leave request from controller, zoom is leaving now..',
        );
        adaptor.notifyControllerWarningInfo(
          JOIN_FLOW_ERROR_CODE.CONTROLLER_QUIT,
        );
        dispatch(leaveMeetingThunkAction());
        externalStatus.isHangup = true;
        break;
      }
      default:
        break;
    }
  });
}
