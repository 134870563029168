import { storeType, easyStore } from '../../../global/easy-store';
import { globalVariable } from '../../../global/global-variable';
import * as AVNotifyMediaSDKTypes from '../../../constants/AVNotifyMediaSDKTypes';
import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { xmppServer } from '../../../components/XMPPSocket';
import {
  SETTINGS_CANVAS,
  MARCH_KEY,
  GET_START_REG,
  REASON_TO_THIRD_PART,
} from '../enum';
import Modal from '../../../global/components/widget/modal';

import {
  externalController,
  CONTROL_MODE_ZOOM_MSG_TYPE,
  CONTROL_MODE_ZOOM_UI_ACTION_TYPE,
} from '../../../controller';
import {
  isExternalControlledMode,
  getVideoDPI,
  isMTRAndroid,
} from '../../../global/util';
import {
  setCurrentUserSkinTone,
  setShowReactionToolBar,
  setShowUserProfileIcon,
  setBackgroundImage,
  setVbSettingVideoInitSuccess,
  setShowMeetingAnimatedReaction,
  setCurrentSettingsTab,
  showSettingsDialog,
  setBackgroundList,
} from './settings-action';
import { toggleIsAlwaysShowFooter } from '../../footer/redux/footer-action';
import {
  setVideoRendererList,
  setVideoRendererType,
} from '../../video/redux/video-action';
import { currentSettingsTabSelector } from '../redux/settings-selector';

import { FileStoreEnum } from '@zoom/resource-manager';
import {
  initResourceManager,
  decodeBase64,
  clipImage,
  typeArrToImageDataToBlob,
  getAllLocalImage,
} from '../service';
import { isVideoEncodeReadySelector } from '../../video/redux/selectors/video-status-selector.js';
import md5 from '../../../global/crypto/md5';
import VirtualBackgroundInit from '../container/background-pane/virtual-background-init';
import {
  ALLOW,
  DONOTALLOW,
  RESET_NONE_TITLE,
  RESET_NONE_TEXT,
  RESET_BLUR_TEXT,
  RESET_BLUR_TITLE,
} from '../resource';
import { isViewOnly } from '../../../global/service/user-types';
import {
  AVS_TAGS,
  avsLogReport,
} from '../../../global/logger/log-service/avs-laplace-telemetry';
import { LOCALSTORAGE_KEYS } from '../../../global';

export const toggleReactionToolBar = (payload) => (dispatch) => {
  dispatch(setShowReactionToolBar(payload));
  easyStore.easySet('showReactionToolBar', payload, storeType.localStorage);
};

export const changeCurrentUserSkinTone = (skinTone) => (dispatch, getState) => {
  // also set it when received roster indication.
  const {
    meeting: {
      currentUser: { userRole },
    },
    settings: { currentUserSkinTone },
  } = getState();
  const isAttendee = isViewOnly(userRole);
  const prevSkinTone = currentUserSkinTone?.type;
  const nextSkinTone = skinTone?.type;

  // set currentUserSkinTone in advance.
  // It will be set again when rwg response roster indication.
  dispatch(setCurrentUserSkinTone(skinTone));

  // change attendee skintone by xmpp socket, otherwise by command socket
  if (isAttendee && xmppServer) {
    xmppServer.sendSkinTone(nextSkinTone);
  } else {
    const data = {
      evt: socketEventTypes.WS_CONF_SET_SKIN_TONE_REQ,
      body: {
        nNewSkinTone: nextSkinTone,
        nOldSkinTone: prevSkinTone,
      },
    };
    dispatch(sendSocketMessage(data));
  }
};

export const setShowUserProfileStorage = (payload) => (dispatch) => {
  dispatch(setShowUserProfileIcon(payload));
  easyStore.easySet('showUserProfileIcon', payload, storeType.localStorage);
};

export const setIsAlwaysShowFooter = (payload) => (dispatch, getState) => {
  dispatch(toggleIsAlwaysShowFooter(payload));
  const {
    footer: {
      visibility: { isAlwaysShowFooter },
    },
  } = getState();
  easyStore.easySet(
    'isAlwaysShowFooter',
    isAlwaysShowFooter,
    storeType.localStorage,
  );
};

export const enableGalleryThunk = (enableGallery) => () => {
  easyStore.easySet('enableGallery', !enableGallery, storeType.localStorage);
  setTimeout(() => {
    window.location.reload();
  }, 0);
};

export const updateMeetingAnimatedReactionSetting = (payload) => (dispatch) => {
  const isEnable = Boolean(payload);
  dispatch(setShowMeetingAnimatedReaction(isEnable));
  easyStore.easySet(
    'showMeetingAnimatedReaction',
    isEnable,
    storeType.localStorage,
  );
};

// update common background image
export function setBackgroundImageThunk(data) {
  return (dispatch) => {
    dispatch(setBackgroundImage(data));
  };
}

// save common background
export function saveBackgroundStatusThunk({
  loginUserUid,
  backgroundIdx,
  backgroundName,
}) {
  return () => {
    const obj = {
      backgroundIdx,
      backgroundName,
    };

    if (loginUserUid) {
      easyStore.easySet(
        `background${loginUserUid}`,
        obj,
        storeType.localStorage,
      );
    } else {
      easyStore.easySet('backgroundLocalDefault', obj, storeType.localStorage);
    }
  };
}

// init background store info from browser storage
export function initBackgroundStatusThunk() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: { loginUserUid },
    } = state;

    let backgroundRecord = {};
    if (loginUserUid) {
      backgroundRecord = easyStore.easyGet(`background${loginUserUid}`);
    } else {
      backgroundRecord = easyStore.easyGet('backgroundLocalDefault');
    }

    const { backgroundIdx, backgroundName } = backgroundRecord || {
      backgroundIdx: -1,
    };

    dispatch(
      setBackgroundImageThunk({
        id: backgroundIdx,
        name: backgroundName,
      }),
    );
  };
}

export function videoVbSettingThunk({ canvas, bgdom, isOpenCamera }) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      settings: {
        backgroundImage: { backgroundList, backgroundIdx },
      },
      video: { activeCameraDevice, currentVideoStatus },
      meeting: {
        currentUser: { videoSsrc },
      },
    } = state;
    const currentSettingsTab = currentSettingsTabSelector(state);
    const dpiObject = getVideoDPI();
    const isOpenCamera1 = isOpenCamera ?? currentVideoStatus !== 'opened';

    let canvasDefault = '';
    if (currentSettingsTab === 'background' || currentSettingsTab === 'video') {
      canvasDefault = SETTINGS_CANVAS.BACKGROUND_VB_CANVAS_ID;
    }

    const bgdomDefault =
      backgroundList.find((item) => item.id === backgroundIdx)?.id ?? null;

    if (globalVariable.avSocket?.sendSocket) {
      globalVariable.avSocket.sendSocket(
        AVNotifyMediaSDKTypes.START_VIDEO_VB_SETTING,
        {
          canvas: canvas ?? canvasDefault,
          bgdom: bgdom ?? bgdomDefault,
          width: dpiObject.width,
          height: dpiObject.height,
          ssid: videoSsrc,
          VideoSelectValue:
            activeCameraDevice && activeCameraDevice !== 'default'
              ? activeCameraDevice
              : null,
          ...(isMTRAndroid() ? { timeout: 15 * 1000 } : {}),
        },
      );
    }
    if (isExternalControlledMode() && isOpenCamera1) {
      externalController.notifyConnectorZoomMsg(
        CONTROL_MODE_ZOOM_MSG_TYPE.UI,
        CONTROL_MODE_ZOOM_UI_ACTION_TYPE.START_VIDEO,
      );
    }
  };
}

export function updateVbBgImageThunk(bgdom) {
  //[3.5.0][user_action]update VB background img{bgdom}
  avsLogReport(`update VB background img{${bgdom}}`, [
    AVS_TAGS.user_action,
    AVS_TAGS.video_telemetry,
  ]);
  return () => {
    if (globalVariable.avSocket?.sendSocket) {
      globalVariable.avSocket.sendSocket(
        AVNotifyMediaSDKTypes.UPDATE_VIDEO_VB_BG_IMAGE,
        {
          bgdom,
        },
      );
    }
  };
}

export function updateBgImageThunk(bgdom) {
  return (dispatch) => {
    dispatch(updateVbBgImageThunk(bgdom));
  };
}

export function setSettingVideoInitSuccessThunk(data) {
  return (dispatch) => {
    dispatch(setVbSettingVideoInitSuccess(data));
  };
}

// finish bg
export function finishVbSettingThunk({ isSwitch }) {
  return (dispatch) => {
    if (isSwitch) {
      dispatch(setSettingVideoInitSuccessThunk(true));
    }

    globalVariable.avSocket.sendSocket(
      AVNotifyMediaSDKTypes.STOP_VIDEO_VB_SETTING,
      {
        isSwitch,
      },
    );
    if (isExternalControlledMode() && !isSwitch) {
      externalController.notifyConnectorZoomMsg(
        CONTROL_MODE_ZOOM_MSG_TYPE.UI,
        CONTROL_MODE_ZOOM_UI_ACTION_TYPE.STOP_VIDEO,
      );
    }
  };
}

export function openSettingsAndToTabThunk(tabs) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      settings: {
        settingsDialog: { currentSettingsTab, isShowSettingsDialog },
      },
    } = state;
    if (!isShowSettingsDialog) {
      dispatch(showSettingsDialog(true));
    }

    if (currentSettingsTab !== tabs) {
      dispatch(setCurrentSettingsTab(tabs));
    }
  };
}

export function isBlurMyBackgroundThunk(flag) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: { loginUserUid },
    } = state;

    if (flag) {
      //[3.5.0][user_action]blur background
      avsLogReport('blur background', [
        AVS_TAGS.user_action,
        AVS_TAGS.video_telemetry,
      ]);

      dispatch(updateBgImageThunk('blur'));
      dispatch(setBackgroundImageThunk({ id: 'blur' }));
      dispatch(
        saveBackgroundStatusThunk({
          loginUserUid,
          backgroundIdx: 'blur',
          backgroundName: 'blur',
        }),
      );
    } else {
      //[3.5.0][user_action]cancel blur background
      avsLogReport('cancel blur background', [
        AVS_TAGS.user_action,
        AVS_TAGS.video_telemetry,
      ]);

      dispatch(updateBgImageThunk(null));
      dispatch(setBackgroundImageThunk({ id: -1 }));
      dispatch(
        saveBackgroundStatusThunk({
          loginUserUid,
          backgroundIdx: -1,
          backgroundName: '',
        }),
      );
    }

    // dispatch(setIsBlurMyBackground(flag));
  };
}

function updateThirdPartImageThunk({
  id,
  backgroundName,
  smallImagePath,
  bigImagePath,
  objectURLBig,
  objectURLSmall,
  isExist,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      settings: {
        //vbBackground: { settingVideoInitSuccess },
        settingsDialog: { currentSettingsTab, isShowSettingsDialog },
        backgroundImage: { backgroundList },
        vbSettings: { vbList },
      },
      meeting: { zak, loginUserUid, fileReadZak },
    } = state;

    return new Promise((resolve) => {
      if (
        (isShowSettingsDialog && currentSettingsTab === 'background') ||
        currentSettingsTab === 'video'
      ) {
        let imageObject = {};
        let list = [];
        if (isExist) {
          list = backgroundList.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                displayURL: objectURLBig,
                previewBigStatus: 'success',
                previewBig: bigImagePath,
                previewBigObjectURL: objectURLBig,
                previewSmallStatus: 'success',
                previewSmall: smallImagePath,
                previewSmallObjectURL: objectURLSmall,
              };
            }
            return item;
          });
          imageObject = list.find((item) => item.id === id);
        } else {
          imageObject = {
            id,
            name: id,
            displayName: backgroundName,
            displayURL: objectURLBig,
            previewSmall: smallImagePath,
            previewSmallStatus: 'success',
            previewBig: bigImagePath,
            previewBigStatus: 'success',
            previewBigObjectURL: objectURLBig,
            previewSmallObjectURL: objectURLSmall,
            isLocal: true,
            isLocalThird: true,
          };
          list = (backgroundList || []).concat([imageObject]);
        }

        dispatch(setBackgroundList([...list]));

        // settimeout for waiting insert new background ready
        setTimeout(() => {
          const imageDom = document.getElementById(`${backgroundName}li`);
          const image = imageDom?.querySelector('img');
          if (!imageDom || !image) {
            resolve(REASON_TO_THIRD_PART.FAIL_IMAGE_DOM);
            return;
          }

          image.src = imageObject.displayURL;
          image.onload = () => {
            dispatch(
              setBackgroundImageThunk({
                id: imageObject.id,
                name: imageObject.displayName,
              }),
            );
            dispatch(updateBgImageThunk(imageObject.id));
            dispatch(
              saveBackgroundStatusThunk({
                loginUserUid,
                backgroundIdx: imageObject.id,
                backgroundName: imageObject.displayName,
              }),
            );
            resolve(REASON_TO_THIRD_PART.SUCCESS);
            return;
          };
        }, 0);
      } else {
        VirtualBackgroundInit.start({
          id: 'mask-vb-init-dom',
          backgroundIdx: id,
          backgroundName: backgroundName,
          zak,
          fileReadZak,
          loginUserUid,
          vbList,
          dispatch,
          onAfterFail: () => {
            resolve(REASON_TO_THIRD_PART.FAIL_VB_RECOVER);
          },
          onAfterSuccess: () => {
            resolve(REASON_TO_THIRD_PART.SUCCESS);
          },
        });
      }
    });
  };
}

function uploadThirdPartImageThunk({ data, backgroundName }) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      meeting: { loginUserUid },
    } = state;

    return new Promise((resolve) => {
      const string = data.bitmap.substring(data.bitmap.indexOf(',') + 1);
      const typeArray = decodeBase64(string);
      typeArrToImageDataToBlob(typeArray, data.width)
        .then((bigImageBlob) => {
          return clipImage(bigImageBlob, 1, 1080);
        })
        .then(({ smallImageBlob }) => {
          return clipImage(smallImageBlob, 0.5, 224);
        })
        .then(({ smallImageBlob, bigImageBlob }) => {
          return initResourceManager(loginUserUid).then((resourceManager) => {
            return Promise.resolve({
              resourceManager,
              params: { smallImageBlob, bigImageBlob },
            });
          });
        })
        .then(({ resourceManager, params }) => {
          const { smallImageBlob, bigImageBlob } = params;
          const currentDate = new Date().valueOf();
          const taskBigImage = resourceManager.updateFile(
            {
              updateTime: currentDate,
              createTime: currentDate,
              fileFormat: '',
              path: `${backgroundName}${MARCH_KEY}small`,
              version: 1,
              sourceURL: loginUserUid,
              data: smallImageBlob,
            },
            { forceSpecifyStore: FileStoreEnum.INDEX_DB },
          );

          const taskSmallImage = resourceManager.updateFile(
            {
              updateTime: currentDate,
              createTime: currentDate,
              fileFormat: '',
              path: `${backgroundName}${MARCH_KEY}big`,
              version: 1,
              sourceURL: loginUserUid,
              data: bigImageBlob,
            },
            { forceSpecifyStore: FileStoreEnum.INDEX_DB },
          );

          return Promise.all([taskBigImage, taskSmallImage]);
        })
        .then((res) => {
          resolve(res);
        });
    });
  };
}

export function setThirdPartVBThunk(data) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      settings: {
        vbSettings: { enableVB },
        vbBackground: { settingVideoInitSuccess },
        settingsDialog: { isShowSettingsDialog },
      },
      video: { currentVideoStatus },
      meeting: { loginUserUid },
    } = state;

    return new Promise((resolve) => {
      const isVideoEncodeReady = isVideoEncodeReadySelector(state);
      const backgroundKey = md5(data.bitmap);
      const backgroundName = `zoomapp-${backgroundKey}`;

      if (!enableVB) {
        resolve(REASON_TO_THIRD_PART.FAIL_ENABLE_VB_OFF);
        return;
      }

      if (!backgroundKey) {
        resolve(REASON_TO_THIRD_PART.FAIL_GENERATE_IMAGE_KEY);
        return;
      }

      if (!isVideoEncodeReady) {
        resolve(REASON_TO_THIRD_PART.FAIL_VIDEO_ENCODE_NOT_READY);
        return;
      }

      if (
        currentVideoStatus !== 'opened' &&
        (!isShowSettingsDialog ||
          (isShowSettingsDialog && !settingVideoInitSuccess))
      ) {
        resolve(REASON_TO_THIRD_PART.FAIL_VIDEO_NOT_OPEN);
        return;
      }

      if (!loginUserUid) {
        resolve(REASON_TO_THIRD_PART.FAIL_NOT_LOGIN);
        return;
      }

      getAllLocalImage(loginUserUid).then((res) => {
        const IMAGE_ID_REG = new RegExp(`${backgroundKey}`);
        const list = (res || []).filter((item) => IMAGE_ID_REG.test(item.path));
        if (list.length == 2) {
          const smallImage = list.find((item) => /small/i.test(item.path));
          const bigImage = list.find((item) => /big/i.test(item.path));

          const id = smallImage.path.match(GET_START_REG)[1];
          const objectURLSmall = URL.createObjectURL(smallImage.data);
          const objectURLBig = URL.createObjectURL(bigImage.data);
          const smallImagePath = smallImage.path;
          const bigImagePath = bigImage.path;
          dispatch(
            updateThirdPartImageThunk({
              id,
              backgroundName,
              smallImagePath,
              bigImagePath,
              objectURLBig,
              objectURLSmall,
              isExist: true,
            }),
          ).then((reason) => {
            resolve(reason);
          });
        } else {
          dispatch(
            uploadThirdPartImageThunk({
              data,
              backgroundName,
            }),
          ).then((res) => {
            const id = res[0]?.path.match(GET_START_REG)[1];
            const objectURLSmall = URL.createObjectURL(res[0]?.data);
            const objectURLBig = URL.createObjectURL(res[1]?.data);
            const smallImagePath = res[0]?.path;
            const bigImagePath = res[1]?.path;
            dispatch(
              updateThirdPartImageThunk({
                id,
                backgroundName,
                smallImagePath,
                bigImagePath,
                objectURLBig,
                objectURLSmall,
                isExist: false,
              }),
            ).then((reason) => {
              resolve(reason);
            });
          });
        }
      });
    });
  };
}

export function setBackToBlurBytThirdPartThunk() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      settings: {
        vbSettings: { enableVB },
        backgroundImage: { backgroundIdx },
        vbBackground: { settingVideoInitSuccess },
        settingsDialog: { isShowSettingsDialog },
      },
      video: { currentVideoStatus },
      meeting: { loginUserUid },
    } = state;

    return new Promise((resolve) => {
      const isVideoEncodeReady = isVideoEncodeReadySelector(state);
      if (!enableVB) {
        resolve(REASON_TO_THIRD_PART.FAIL_ENABLE_VB_OFF);
      }

      if (!isVideoEncodeReady) {
        resolve(REASON_TO_THIRD_PART.FAIL_VIDEO_ENCODE_NOT_READY);
        return;
      }

      if (
        currentVideoStatus !== 'opened' &&
        (!isShowSettingsDialog ||
          (isShowSettingsDialog && !settingVideoInitSuccess))
      ) {
        resolve(REASON_TO_THIRD_PART.FAIL_VIDEO_NOT_OPEN);
        return;
      }

      if (!loginUserUid) {
        resolve(REASON_TO_THIRD_PART.FAIL_NOT_LOGIN);
        return;
      }

      Modal.confirm({
        className: 'zm-modal-legacy',
        okButtonProps: {
          type: 'primary',
          size: 'default',
          className: 'zm-btn-legacy',
        },
        okText: ALLOW,
        cancelText: DONOTALLOW,
        cancelButtonProps: {
          type: 'default',
          size: 'default',
          className: 'zm-btn-legacy',
        },
        title: RESET_BLUR_TITLE,
        content: RESET_BLUR_TEXT,
        okCancel: true,
        isModal: true,
        onAfterClose: () => {
          resolve(REASON_TO_THIRD_PART.FAIL_BLUR_CANCEL);
        },
        onOk: () => {
          if (backgroundIdx !== 'blur') {
            dispatch(
              setBackgroundImageThunk({
                id: 'blur',
              }),
            );
            dispatch(updateBgImageThunk('blur'));
            dispatch(
              saveBackgroundStatusThunk({
                loginUserUid,
                backgroundIdx: 'blur',
              }),
            );
          }

          resolve(REASON_TO_THIRD_PART.SUCCESS);
        },
      });
    });
  };
}

export function removeThirdPartVBThunk() {
  return (dispatch, getState) => {
    const state = getState();
    const {
      settings: {
        vbSettings: { enableVB, enableForceUseVB },
        backgroundImage: { backgroundIdx },
        vbBackground: { settingVideoInitSuccess },
        settingsDialog: { isShowSettingsDialog },
      },
      video: { currentVideoStatus },
      meeting: { loginUserUid },
    } = state;

    return new Promise((resolve) => {
      const isVideoEncodeReady = isVideoEncodeReadySelector(state);

      if (enableForceUseVB) {
        resolve(REASON_TO_THIRD_PART.FAIL_FORCE_VB_ON);
        return;
      }

      if (!enableVB) {
        resolve(REASON_TO_THIRD_PART.FAIL_ENABLE_VB_OFF);
        return;
      }

      if (!isVideoEncodeReady) {
        resolve(REASON_TO_THIRD_PART.FAIL_VIDEO_ENCODE_NOT_READY);
        return;
      }

      if (
        currentVideoStatus !== 'opened' &&
        (!isShowSettingsDialog ||
          (isShowSettingsDialog && !settingVideoInitSuccess))
      ) {
        resolve(REASON_TO_THIRD_PART.FAIL_VIDEO_NOT_OPEN);
        return;
      }

      Modal.confirm({
        className: 'zm-modal-legacy',
        okButtonProps: {
          type: 'primary',
          size: 'default',
          className: 'zm-btn-legacy',
        },
        okText: ALLOW,
        cancelText: DONOTALLOW,
        cancelButtonProps: {
          type: 'default',
          size: 'default',
          className: 'zm-btn-legacy',
        },
        title: RESET_NONE_TITLE,
        content: RESET_NONE_TEXT,
        okCancel: true,
        isModal: true,
        onAfterClose: () => {
          resolve(REASON_TO_THIRD_PART.FAIL_NONE_CANCEL);
        },
        onOk: () => {
          if (backgroundIdx !== -1) {
            dispatch(
              setBackgroundImageThunk({
                id: -1,
              }),
            );
            dispatch(updateBgImageThunk(null));
            dispatch(
              saveBackgroundStatusThunk({
                loginUserUid,
                backgroundIdx: -1,
              }),
            );
          }

          resolve(REASON_TO_THIRD_PART.SUCCESS);
        },
      });
    });
  };
}

export function setRendererTypeThunk(rendererType, shouldRemember = true) {
  return (dispatch) => {
    dispatch(setVideoRendererType(rendererType));
    if (shouldRemember) {
      easyStore.easySet(
        LOCALSTORAGE_KEYS.video_render_method_type,
        rendererType,
        storeType.localStorage,
      );
    }
    return;
  };
}

export function setRendererListThunk(data) {
  return (dispatch) => {
    dispatch(setVideoRendererList(data));
  };
}
