import * as socketEventTypes from '../../constants/ZoomSocketEventTypes';
import { warningLog } from '../../global/web-client-logger';

const collector = new Map();

export const makeKey = (...args) => {
  if (args.length === 0) {
    throw new Error(`key ${args} is not valid`);
  }
  return args.join('__');
};

export const injectRWGHandlers = (handlerObject) => {
  Object.entries(handlerObject).forEach(([key, handler]) => {
    if (typeof handler !== 'function') {
      throw new Error('handler must be a thunk like function');
    }
    const currentHandlers = collector.get(key);
    if (currentHandlers) {
      collector.set(key, [handler].concat(currentHandlers));
    } else {
      collector.set(key, [handler]);
    }
  });
};

export const doRWGAction = (message, evt) => (dispatch) => {
  const handlers = collector.get(String(evt));
  if (handlers) {
    handlers.forEach((h) => {
      const action = h(message);
      if (action) {
        dispatch(action);
      }
    });
  } else if (IS_LOCAL && Object.values(socketEventTypes).includes(evt)) {
    warningLog('no handler for: ', evt);
  }
};
