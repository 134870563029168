export const STEP_TYPES = {
  START_CAMERA: 'start-camera',
  TEST_CAMERA: 'test-camera',
  TEST_SPEAKER: 'test-speaker',
  TEST_MICROPHONE: 'test-microphone',
  END_TEST: 'end-test',
};
export const STEPS = [
  STEP_TYPES.START_CAMERA,
  STEP_TYPES.TEST_CAMERA,
  STEP_TYPES.TEST_SPEAKER,
  STEP_TYPES.TEST_MICROPHONE,
  STEP_TYPES.END_TEST,
];

export const DEVICE_STATUS = {
  GOOD: 'good',
  BAD: 'bad',
};

export const DEVICE_STATUS_PARAM = {
  NOT_TESTED: '0',
  NOT_WORKING: '1',
  WORKING: '2',
};

// when test microphone, if pause more than this long, replay
export const TEST_MIC_STOP_LENGTH = 1000;
// when test microphone, if last more than this long, replay
export const TEST_MIC_LAST_LENGTH = 5000;

export const TEST_MIC_STATUS = {
  NORMAL: 'normal',
  RECORDING: 'recording',
  PLAYING: 'playing',
  LOADING: 'loading',
};
