import {
  isMSFTMode,
  isTeslaMode,
  is2K,
  is4K,
  isExternalControlledMode,
} from '../../../global/util';
import { isZoomDomain } from '../../../global/service';
import { isOpOpen1080PVideoDecodeFlag } from '../../../global/op-feature-option';
import {
  DESIGN_SMALLEST_STYLE,
  DESIGN_BIGGEST_STYLE,
  HORIZONTAL_TOP_HEIGHT,
  TESLA_HORIZONTAL_TOP_HEIGHT,
  DEFAULT_SPEAKER_BAR_ASPECT_RATIO,
  DEFAULT_SPEAKER_ACTIVE_ASPECT_RATIO,
  DEFAULT_TESLA_BAR_ASPECT_RATIO,
  VIDEO_FILL_MODE,
} from '../enum';
import meetingConfig from 'meetingConfig';

export function getVideoCaptureMode() {
  /**
   * MediaSdk will capture the video raw data in the new way using image capture
   * but it will cause intermittent video-freeze bugs
   */
  let mode = 1;
  if (isMSFTMode()) {
    /**
     * MediaSdk will capture the video raw data in the old way so that there's not video freeze bug
     * But it will bring audio glitch issue back which is fixed in chromimum > 83
     */
    mode = 0;
  }
  return mode;
}

export const isVideoAvatarShow = (avatar, bAllowedAvatar) => {
  if (!bAllowedAvatar) {
    return false;
  }
  if (!avatar) {
    return false;
  }
  if (!isZoomDomain(avatar)) {
    return false;
  }
  return true;
};

function overlappingArea(
  draggingItemBottomLeft,
  draggingItemTopRight,
  targetItemBottomLeft,
  targetItemTopRight,
  frameArea,
) {
  if (
    draggingItemBottomLeft.x >= targetItemTopRight.x ||
    targetItemBottomLeft.x >= draggingItemTopRight.x ||
    draggingItemTopRight.y <= targetItemBottomLeft.y ||
    targetItemTopRight.y <= draggingItemBottomLeft.y
  ) {
    return false;
  }

  const intersectingArea =
    (Math.min(draggingItemTopRight.x, targetItemTopRight.x) -
      Math.max(draggingItemBottomLeft.x, targetItemBottomLeft.x)) *
    (Math.min(draggingItemTopRight.y, targetItemTopRight.y) -
      Math.max(draggingItemBottomLeft.y, targetItemBottomLeft.y));
  return (frameArea * 2) / 3 < intersectingArea;
}

export function calculateFrameOverlapping(
  userId,
  videoFrame,
  position,
  rootHeight,
) {
  let result = null;
  const frameWidth = videoFrame[0].width;
  const frameHeight = videoFrame[0].height;
  const frameArea = frameWidth * frameHeight;
  const draggingItemBottomLeft = {
    x: position.x,
    y: rootHeight - Math.abs(position.y) - frameHeight,
  };
  const draggingItemTopRight = {
    x: position.x + frameWidth,
    y: rootHeight - Math.abs(position.y),
  };
  videoFrame
    .filter((item) => item.user.userId !== userId)
    .forEach((item) => {
      const targetItemBottomLeft = {
        x: item.x,
        y: item.y,
      };
      const targetItemTopRight = {
        x: item.x + frameWidth,
        y: item.y + frameHeight,
      };
      if (
        overlappingArea(
          draggingItemBottomLeft,
          draggingItemTopRight,
          targetItemBottomLeft,
          targetItemTopRight,
          frameArea,
        )
      ) {
        result = item;
      }
    });
  return result;
}

function cal(
  width,
  x1,
  x2,
  y1 = DESIGN_SMALLEST_STYLE.video_size,
  y2 = DESIGN_BIGGEST_STYLE.video_size,
) {
  return Math.round(x1 + ((x2 - x1) * (width - y1)) / (y2 - y1));
}

export function calNameTag(width) {
  return {
    height: cal(
      width,
      DESIGN_SMALLEST_STYLE.height,
      DESIGN_BIGGEST_STYLE.height,
    ),
    margin: cal(
      width,
      DESIGN_SMALLEST_STYLE.margin,
      DESIGN_BIGGEST_STYLE.margin,
    ),
    padding: cal(
      width,
      DESIGN_SMALLEST_STYLE.padding,
      DESIGN_BIGGEST_STYLE.padding,
    ),
    logoWidth: cal(
      width,
      DESIGN_SMALLEST_STYLE.logo_width,
      DESIGN_BIGGEST_STYLE.logo_width,
    ),
    logoLeft: cal(
      width,
      DESIGN_SMALLEST_STYLE.logo_left,
      DESIGN_BIGGEST_STYLE.logo_left,
    ),
    logoRight: cal(
      width,
      DESIGN_SMALLEST_STYLE.logo_right,
      DESIGN_BIGGEST_STYLE.logo_right,
    ),
    font: cal(width, DESIGN_SMALLEST_STYLE.font, DESIGN_BIGGEST_STYLE.font),
    radius: cal(
      width,
      DESIGN_SMALLEST_STYLE.radius,
      DESIGN_BIGGEST_STYLE.radius,
    ),
  };
}

export function getVideoHorizontalTopHeight() {
  if (isTeslaMode()) {
    return TESLA_HORIZONTAL_TOP_HEIGHT;
  }

  if (isExternalControlledMode()) {
    return HORIZONTAL_TOP_HEIGHT;
  }

  const pixelRatio = window.devicePixelRatio;

  if (is2K && pixelRatio < 1.25) {
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;

    if (innerWidth > 2244 && innerHeight > 1196) {
      return HORIZONTAL_TOP_HEIGHT * 1.5;
    }

    if (innerWidth > 1626 && innerHeight > 984) {
      return HORIZONTAL_TOP_HEIGHT * 1.25;
    }

    return HORIZONTAL_TOP_HEIGHT;
  }

  if (is4K && pixelRatio < 1.25) {
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;

    if (innerWidth > 2644 && innerHeight > 1496) {
      return HORIZONTAL_TOP_HEIGHT * 2;
    }

    if (innerWidth > 1826 && innerHeight > 1184) {
      return HORIZONTAL_TOP_HEIGHT * 1.5;
    }

    return HORIZONTAL_TOP_HEIGHT;
  }

  return HORIZONTAL_TOP_HEIGHT;
}

//bar area get basic width
export function getBarVideoStricWidth() {
  const aspectRatio = isTeslaMode()
    ? DEFAULT_TESLA_BAR_ASPECT_RATIO
    : DEFAULT_SPEAKER_BAR_ASPECT_RATIO;

  const [widthRatio, heightRatio] = aspectRatio;
  return (getVideoHorizontalTopHeight() / heightRatio) * widthRatio - 6;
}

//active area get basic width
export function getActiveVideoStricWidth() {
  if (isTeslaMode()) {
    return window.innerWidth;
  }

  const [widthRatio, heightRatio] = DEFAULT_SPEAKER_ACTIVE_ASPECT_RATIO;
  return (getVideoHorizontalTopHeight() / heightRatio) * widthRatio - 6;
}

//bar area get video box ratio
export function getBarAspectRatio() {
  if (isTeslaMode()) {
    return DEFAULT_TESLA_BAR_ASPECT_RATIO;
  }

  return DEFAULT_SPEAKER_BAR_ASPECT_RATIO;
}

//active area get video box ratio
export function getActiveAspectRatio() {
  return DEFAULT_SPEAKER_ACTIVE_ASPECT_RATIO;
}

export function getTeslaActiveAspectRatio(teslaBarListCount) {
  if (teslaBarListCount > 0) {
    return [
      window.innerWidth,
      window.innerHeight - getVideoHorizontalTopHeight(),
    ];
  }

  return [window.innerWidth, window.innerHeight];
}

export function getVideoFillMode() {
  if (isTeslaMode()) {
    return VIDEO_FILL_MODE.ON;
  }

  return VIDEO_FILL_MODE.OFF;
}

export const isSupportVideoDecode1080P = () => {
  // op condition
  if (!isOpOpen1080PVideoDecodeFlag()) {
    return false;
  }

  /* if (!isSupportMultiView()) {
    return false;
  }

  if (
    JsMediaSDK_Instance?.util?.getsub1080pcapacity &&
    JsMediaSDK_Instance.util.getsub1080pcapacity()
  ) {
    return true;
  }*/

  if (navigator.hardwareConcurrency && navigator.hardwareConcurrency >= 4) {
    return true;
  }

  return false;
};

export const isWebinarOnlyShowContentConfig = () => {
  const { webinarContentOnlyView } = meetingConfig.meetingOptions || {};
  return !!webinarContentOnlyView;
};
