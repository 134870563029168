import { CHAT_RESPONSE } from '../../constants/Constants';
import {
  WS_CONF_CHAT_CMD_INDICATION,
  WS_CONF_CHAT_CMD_RES,
  WS_CONF_CHAT_FILE_INDICATION,
  // WS_CONF_ATTRIBUTE_INDICATION,
  // WS_CONF_HOST_CHANGE_INDICATION,
  WS_CONF_CHAT_INDICATION,
  WS_CONF_CHAT_RES,
} from '../../constants/ZoomSocketEventTypes';
import { SOCKET_NAME_ENUM } from '../../global/constant';
import {
  CHAT_CMD_DELETE,
  CHAT_CMD_MODIFY,
  CHAT_DELETED_OR_MODIFIED_BY_DLP,
  DLPActionType,
} from '../chat/constants';
import { setChatDeletedDlpDialogVisible } from '../dialog/redux/dialog-action';
import { showDLPPopupThunk } from '../dialog/redux/dialog-thunk-action';
import { setSelfNewChatId } from './redux/new-chat-action';
import { onReceiveNewChatFileMessage } from './redux/new-chat-file-thunk';
// import _ from 'lodash';
// import { convertXml2Json } from './utils';
import { onReceiveNewChatMessage } from './redux/new-chat-thunk';
import {
  onReceiveEditChat,
  onDeleteNewChatMsg,
} from './redux/new-chat-cmd-thunk';
import { onApplySelfChanges } from './redux/new-chat-core-thunk';
import { isEnableOneChatUI } from '../../global/service';
import {
  onEditSavedChatMessage,
  onRevokeSavedChatMessage,
  onReceiveChatMessageSaveOne,
  onSelfRevokeMessageResponse,
} from '../../global/ChatSDK/redux/thunk';
import { isOnHoldSelector } from '../../global/redux/selector';
import { onReceiveOneChatFileMessage } from '../../global/ChatSDK/redux/file-thunk';

/**
 * meetingOptions.channelId
 * meetingOption.newMeetingChatExperience
    allowNewMeetingChatExperience - New meeting chat experience
    deleteMsgInMeetingChat - Allow participants to delete message in meeting chat
    editMsgInMeetingChat - Allow participants to edit message in meeting chat
    screenshotInMeetingChat - Enable the Screenshot feature in meeting chat
    ReactionInMeetingChat - allow reaction
 */
export default {
  [SOCKET_NAME_ENUM.COMMAND]: {
    [WS_CONF_CHAT_INDICATION]: (store, message) => {
      const inOnHold = isOnHoldSelector(store.getState());
      if (isEnableOneChatUI() && !inOnHold) {
        store.dispatch(onReceiveChatMessageSaveOne(message));
      } else {
        store.dispatch(onReceiveNewChatMessage(message));
      }
    },
    [WS_CONF_CHAT_FILE_INDICATION]: (store, message) => {
      const inOnHold = isOnHoldSelector(store.getState());
      if (isEnableOneChatUI() && !inOnHold) {
        store.dispatch(onReceiveOneChatFileMessage(message));
      } else {
        store.dispatch(onReceiveNewChatFileMessage(message));
      }
    },
    [WS_CONF_CHAT_CMD_INDICATION]: (store, message) => {
      if (!message.body || _.isUndefined(message.body.cmd)) {
        return;
      }

      const { dispatch, getState } = store;
      const {
        newChat: { meetingChat },
        meetingUI: { isOnHold },
      } = getState();
      if (
        message.body.deletedOrModifiedBy === CHAT_DELETED_OR_MODIFIED_BY_DLP // &&
        // isMessageSentByUser(currentUserId, message.body.msgID, meetingChatList)
      ) {
        const beDeletedMsg = meetingChat.find(
          (item) => item.msgId === message.body.msgID,
        );
        if (beDeletedMsg && beDeletedMsg.isMyMessage) {
          dispatch(setChatDeletedDlpDialogVisible(true));
        }
      }
      if (message.body.cmd === CHAT_CMD_DELETE) {
        dispatch(onDeleteNewChatMsg(message.body.msgID, message.body));
        if (isEnableOneChatUI() && !isOnHold) {
          dispatch(onRevokeSavedChatMessage(message));
        }
      } else if (message.body.cmd === CHAT_CMD_MODIFY) {
        dispatch(onReceiveEditChat(message.body));
        if (isEnableOneChatUI() && !isOnHold) {
          dispatch(onEditSavedChatMessage(message));
        }
      }
    },
    [WS_CONF_CHAT_RES]: ({ getState, dispatch }, message) => {
      const {
        meeting: {
          currentUser: { userId },
        },
        meetingUI: { isOnHold },
      } = getState();
      const { destNodeID, msgID, result, fileID } = message.body;
      if (result === CHAT_RESPONSE.BLOCK_BY_DLP) {
        dispatch(onDeleteNewChatMsg(msgID));
        if (isEnableOneChatUI() && !isOnHold) {
          dispatch(onRevokeSavedChatMessage(message));
        }
        dispatch(showDLPPopupThunk(DLPActionType.block));
      } else if (result === CHAT_RESPONSE.DELETE_MESSAGE) {
        dispatch(onDeleteNewChatMsg(msgID));
        if (isEnableOneChatUI() && !isOnHold) {
          dispatch(onRevokeSavedChatMessage(message));
        }
      } else if (result === CHAT_RESPONSE.CHAT_MESSAGE_OK) {
        dispatch(setSelfNewChatId({ destNodeID, msgID, userId, fileID }));
      }
    },
    [WS_CONF_CHAT_CMD_RES]: (store, message) => {
      const inOnHold = isOnHoldSelector(store.getState());
      if (message.body && message.body.bSuccess) {
        switch (message.body.cmd) {
          case CHAT_CMD_DELETE:
            store.dispatch(onDeleteNewChatMsg(message.body.msgID));
            if (isEnableOneChatUI() && !inOnHold) {
              store.dispatch(onSelfRevokeMessageResponse(message));
            }
            break;
          case CHAT_CMD_MODIFY:
            // edit
            store.dispatch(onApplySelfChanges(message.body.msgID));
            break;
          default:
            break;
        }
      } else {
        //revert
        store.dispatch(onApplySelfChanges(message.body.msgID), true);
      }
    },
  },
};
