import { LOADING_TEXT_NOTIFICATION_TYPES } from '../../global/containers/notification-manager/loading-text-notification';
import meetingConfig from 'meetingConfig';

export const isUIConnectingCMR = (loadingValues = []) => {
  return (
    loadingValues.findIndex(
      (value) =>
        value === LOADING_TEXT_NOTIFICATION_TYPES.START_RECORDING.value,
    ) > -1
  );
};

export const isNewZoomIQDesign = () => {
  return !meetingConfig.meetingOptions.enableOldZraRecording;
};
