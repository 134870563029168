import { SEND_CHAT_WARNING } from '../../../global/constant';
import {
  cutString,
  decodeBase64,
  encodeUnsafeBase64,
} from '../../../global/util';
import {
  CHAT_MSG_TYPE_TO_SILENT_MODE_USERS,
  // CHAT_MSG_TYPE_TO_INDIVIDUAL_CC_PANELIST,
  CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS,
} from '../../chat/constants';
import { beginEncrypt, ivType } from '../../../global/crypto';
import { addVoteEmoji, removeVoteEmoji } from './new-chat-action';
import { WS_CONF_CHAT_REQ } from '../../../constants/ZoomSocketEventTypes';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { buildSendVoteEmoji } from '../transform/buildSendXmppMsgData';
import { getAddEmojiTo, getRemoveEmoji } from '../resource';
import { isBuildInChatReceiver } from '../../chat/utils';
import {
  checkBeforeSentAndGetReceiverId,
  generateLocalMsgId,
  getXmppMsgFromToSenderNameMid,
} from '../help';
import { updateFrequentlyUsedEmojis } from '../../reaction/redux/reaction-thunk-action';
import { generateUUID } from '../../../global/service/generate-uuid';
import { setChatWarning as showNewChatWarning } from '../../../actions/MeetingUIPureAction';

// emoji is attached to message
const VOTE_ACTION = {
  ADD: 'add',
  REMOVE: 'remove',
};

export function updateReceiverEmojis(data) {
  // {
  //     "sender":"WltKS1yg3qIvsI8yGS7hGQ@xmppdev.zoom.us/ZoomChat_pc",
  //     "receiverJid":"N6IwRQL_RfK6aRbgcbNMnQ@conference.xmppdev.zoom.us",
  //     "xmppMsgId":"{787FE323-9138-4B49-9FFB-F9D5F7D2A26E}",
  //     "action":"add",
  //     "parentMsgId":"1-{9F39B2A3-77E4-49A1-B687-EEAB7D3B4880}",
  //     "id":"8J+RjQ==",
  //     "cid":"{13823EE4-E23F-480B-983F-177C94A71832}",
  //     "session":"N6IwRQL_RfK6aRbgcbNMnQ",
  //     "t":"1669022071309",
  //     "isVote":true
  //      senderSN
  //      senderObj, senderId
  // }
  return (dispatch, getState) => {
    const state = getState();
    const {
      newChat: { meetingChat },
      meeting: { currentUser },
    } = state;
    const { id, parentMsgId, action } = data;
    const targetMsgIndex = meetingChat.findIndex(
      (item) =>
        (item.xmppMsgId && item.xmppMsgId === parentMsgId) ||
        item.msgId === parentMsgId, // compatible with message from old client
    );
    if (targetMsgIndex !== -1) {
      const emojiStr = decodeBase64(id).replace(/[\u200B-\u200D\uFEFF]/g, '');
      const isSelf =
        currentUser.strConfUserID.toLowerCase() ===
        data.senderJid.toLowerCase();
      if (action === VOTE_ACTION.ADD) {
        dispatch(
          addVoteEmoji({ emojiStr, targetMsgIndex, data, selfSent: isSelf }),
        );
      } else if (action === VOTE_ACTION.REMOVE) {
        dispatch(
          removeVoteEmoji({
            emojiStr,
            targetMsgIndex,
            data,
            selfRemoved: isSelf,
          }),
        );
      }
    }
  };
}
export function sendVoteEmoji(emojiMeta, mainMsgId, needUpdateFrequent) {
  return (dispatch, getState) => {
    let emojiStr = emojiMeta.content;
    if (!emojiStr) return;

    emojiStr = emojiStr.replace(/[\u200B-\u200D\uFEFF]/g, '');

    const state = getState();

    const {
      meeting: { currentUser, chatPriviledge, zoomId },
      attendeesList: { attendeesList },
      newChat: { meetingChat },
    } = state;

    const targetMsgIndex = meetingChat.findIndex(
      (item) => item.xmppMsgId === mainMsgId || item.msgId === mainMsgId,
    );
    if (targetMsgIndex === -1) return;
    const mainMessage = meetingChat[targetMsgIndex];
    let { receiverId } = mainMessage;

    const isBuiltInChatType = isBuildInChatReceiver(receiverId);

    let realReceiverId, receiverSN, realReceiver;

    if (isBuiltInChatType) {
      realReceiverId = mainMessage.receiverId;
      realReceiver = mainMessage.receiver;
    } else if (mainMessage.isShowPrivately) {
      if (mainMessage.isMyMessage) {
        realReceiverId = mainMessage.receiverId;
        receiverSN = mainMessage.chatReceiver?.zoomID;
        realReceiver = mainMessage.receiver;
      } else if (mainMessage.isToMyMessage) {
        realReceiverId = mainMessage.senderId;
        receiverSN = mainMessage.senderSN;
        realReceiver = mainMessage.sender;
      }
    }
    if (
      mainMessage.receiverId === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS ||
      mainMessage.receiverId === CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS ||
      mainMessage.isSilentModeUser
    ) {
      return dispatch(showNewChatWarning([SEND_CHAT_WARNING.EXCEPTION_WR]));
    }

    const beforeCheckVals = checkBeforeSentAndGetReceiverId(
      {
        // receiver: mainMessage.receiver,
        receiverId: realReceiverId,
        receiverSN,
        attendeesList,
        isMeInRoom: !!currentUser.bid,
        chatPriviledge,
        currentUserPrivateChatMsgDisabled: false,
        currentIsCoOrHost: currentUser.isHost || currentUser.bCoHost,
        isSilentModeUser: mainMessage.isSilentModeUser,
        isSameMeetingMessage: mainMessage.bid === currentUser.bid,
      },
      true,
    );

    if (beforeCheckVals[0] !== SEND_CHAT_WARNING.NONE) {
      return dispatch(showNewChatWarning([beforeCheckVals[0], realReceiver]));
    }

    const destNodeID = beforeCheckVals[1];

    const { voteMap } = mainMessage;
    let action;
    if (!voteMap) {
      action = VOTE_ACTION.ADD;
    } else {
      action = voteMap.get(emojiStr)?.selfSent
        ? VOTE_ACTION.REMOVE
        : VOTE_ACTION.ADD;
    }
    const attendeeNodeID = 0; //! TODO if support webinar need update this
    const oldText = mainMessage.isFile
      ? mainMessage.content.file.fileName
      : mainMessage.content.text;
    // const destNodeID = currentUser.userId;
    const text =
      oldText &&
      (action === VOTE_ACTION.ADD
        ? getAddEmojiTo(`"${cutString(oldText, 20)}"`, emojiStr)
        : getRemoveEmoji(emojiStr, `"${cutString(oldText, 20)}"`));
    const localXmppMsgId = generateLocalMsgId(currentUser);
    const cid = `{${generateUUID()}}`;
    const ct = Date.now();
    const base64Str = encodeUnsafeBase64(emojiMeta.content);
    const otherInfos = getXmppMsgFromToSenderNameMid(
      currentUser.strConfUserID,
      currentUser.displayName,
    );
    return Promise.all([
      buildSendVoteEmoji({
        id: localXmppMsgId,
        cid,
        ct,
        action,
        mainMsgId: mainMessage.msgId,
        mainMsgTime: mainMessage.t,
        encodedEmoji: base64Str,
        ...otherInfos,
      }).then((xmlStr) => {
        return beginEncrypt({ text: xmlStr, type: ivType.RWG_CHAT });
      }),
      beginEncrypt({
        text: text,
        type: ivType.RWG_CHAT,
      }),
    ])
      .then(([encryptedXml, oldText]) => {
        dispatch(
          sendSocketMessage({
            evt: WS_CONF_CHAT_REQ,
            body: {
              text: text ? oldText : '',
              xmppMsgData: encryptedXml,
              destNodeID,
              attendeeNodeID,
              sn: zoomId,
              msgID: localXmppMsgId,
            },
          }),
        );
      })
      .then(() => {
        const data = {
          cid,
          id: base64Str,
          isVote: true,
          parentMsgId: mainMessage.xmppMsgId || mainMessage.msgId,
          receiverJid: mainMessage.ownerRes,
          sender: otherInfos.from,
          senderId: currentUser.userId,
          senderObj: currentUser,
          senderSN: zoomId,
          // sender: currentUser.displayName,
          // session: '',
          senderJid: currentUser.strConfUserID,
          t: ct,
          xmppMsgId: localXmppMsgId,
        };
        if (action === VOTE_ACTION.ADD) {
          dispatch(
            addVoteEmoji({ emojiStr, targetMsgIndex, data, selfSent: true }),
          );
          if (needUpdateFrequent) {
            dispatch(updateFrequentlyUsedEmojis(emojiMeta?.code_points?.base));
          }
        } else {
          dispatch(
            removeVoteEmoji({
              emojiStr,
              targetMsgIndex,
              data,
              selfRemoved: true,
            }),
          );
        }
      });

    // return Promise.all([]).then();
  };
}
