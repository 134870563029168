import { fetchRWCToken, getMeetingTokens } from '../../service/meetingToken';
import * as types from '../../../constants/MeetingActionTypes';
import { infoLog, errorLog } from '../../web-client-logger';
import * as socketEventTypes from '../../../constants/ZoomSocketEventTypes';
import { sendSocketMessage } from '../../../actions/SocketActions';
import { setWhiteboardToken } from '../../../features/whiteboard/redux/whiteboard-action';
import axios from 'axios';
import meetingConfig from 'meetingConfig';
import { makeLogger } from '../../logger';
import { getJoinMeetingLog } from '../../util';
import { postMessageToWBDashboard } from '../../../features/whiteboard/service';
import { globalVariable } from '../../global-variable';
import { isEnableUseZMKFromConfJ } from '../../op-feature-option';

const logger = makeLogger(['Join Meeting Flow']);

export function refreshRWCTokenThunkAction() {
  return (dispatch) => {
    return fetchRWCToken()
      .then((result) => {
        infoLog('-- Refresh Authentication Token --');
        dispatch({
          type: types.REFRESH_TOKEN,
          data: getMeetingTokens(),
        });
        dispatch(
          sendSocketMessage({
            evt: socketEventTypes.WS_CONF_UPDATE_ZAK_REQ,
            body: {
              zak: result.zak,
            },
          }),
        );
        dispatch(exchangeWhiteboardToken());
        logger.log(getJoinMeetingLog('refresh rwc token'), [
          'REFRESH_RWC_TOKEN_SUCCESS',
        ]);
      })
      .catch((error) => {
        logger.log(getJoinMeetingLog(`refresh rwc token: ${error}`), [
          'REFRESH_RWC_TOKEN_FAILED',
        ]);
        errorLog('-- refresh token failed --\n' + error);
        throw new Error('refresh token failed');
      });
  };
}

export function exchangeWhiteboardToken() {
  return (dispatch, getState) => {
    const tokens = getMeetingTokens();
    const { meeting } = getState();
    const zmk = isEnableUseZMKFromConfJ() ? meeting.zmk : tokens.zmk;
    axios({
      method: 'get',
      url: `${meetingConfig.meetingOptions.whiteboardDasPath}/api/v1/tokens/inmeeting`,
      headers: {
        zmk,
      },
    }).then((res) => {
      const whiteboardToken = res.data?.result?.token;
      dispatch(setWhiteboardToken(whiteboardToken));
      postMessageToWBDashboard({
        messageName: 'RESPONSE_TOKEN',
        whiteboardToken,
      });
      globalVariable.whiteboardSdk?.conn?.updateTokenFromChannel?.({
        wbk: whiteboardToken,
      });
    });
  };
}
