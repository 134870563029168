/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import clsx from 'classnames';
import {
  PREVIEW_AUDIO_STATUS,
  PREVIEW_VIDEO_STATUS,
  PREVIEW_CANVAS,
  PREVIEW_SIZE,
  PREVIEW_CANVAS_SIZE,
} from '../consts';
import {
  VideoOffDisallowedSVG,
  VideoOnSVG,
  VideoOffSVG,
  VideoOffDisabledSVG,
  AudioJoinSVG,
  VoipUnmuteSVG,
  VoipUnmuteDisabledSVG,
  VoipUnmuteDisallowedSVG,
} from '../../../svg/icons/footer';
import { PreviewDefaultAvatar, Spinner } from '../../../svg/icons';
import { MUTE, UNMUTE_TEXT, JOIN_AUDIO } from '../../audio/resource';
import previewI18n from '../resource';
import { START_VIDEO, STOP_VIDEO } from '../../video/resource';
import PreviewError from './PreviewError';
import VideoMenu from './VideoMenu';
import AudioMenu from './AudioMenu';
import { promptA11yInfo } from '../../../global/util';
import ResizeObserver from 'rc-resize-observer';
import deviceManager from '../../../device-manager';
import { getPreviewAVSocket } from '../service';
import VoipActiveIcon from '../../audio/components/voip-active-icon/';
import _, { has } from 'lodash';
import NameTag from './name-tag/NameTag';

const getAudioIcon = (status) => {
  switch (status) {
    case PREVIEW_AUDIO_STATUS.DISABLED: {
      return <VoipUnmuteDisabledSVG />;
    }
    case PREVIEW_AUDIO_STATUS.FORBIDDEN: {
      return <VoipUnmuteDisallowedSVG />;
    }
    case PREVIEW_AUDIO_STATUS.NOT_CONNECTED: {
      return <AudioJoinSVG />;
    }
    case PREVIEW_AUDIO_STATUS.MUTED: {
      return <VoipUnmuteSVG />;
    }
    case PREVIEW_AUDIO_STATUS.UNMUTED: {
      return <VoipActiveIcon />;
    }
    case PREVIEW_AUDIO_STATUS.LOADING:
    case PREVIEW_AUDIO_STATUS.CAPTURING: {
      return <Spinner className="spinner" />;
    }
    default:
      return null;
  }
};
const getAudioText = (state) => {
  switch (state) {
    case PREVIEW_AUDIO_STATUS.DISABLED: {
      return MUTE;
    }
    case PREVIEW_AUDIO_STATUS.FORBIDDEN: {
      return MUTE;
    }
    case PREVIEW_AUDIO_STATUS.NOT_CONNECTED: {
      return JOIN_AUDIO;
    }
    case PREVIEW_AUDIO_STATUS.MUTED: {
      return UNMUTE_TEXT;
    }
    case PREVIEW_AUDIO_STATUS.UNMUTED: {
      return MUTE;
    }
  }
};

const getVideoIcon = (status, canNotDetectCamera) => {
  if (canNotDetectCamera) {
    return <VideoOffDisallowedSVG />;
  }
  switch (status) {
    case PREVIEW_VIDEO_STATUS.DISABLED: {
      return <VideoOffDisabledSVG />;
    }
    case PREVIEW_VIDEO_STATUS.FORBIDDEN: {
      return <VideoOffDisallowedSVG />;
    }
    case PREVIEW_VIDEO_STATUS.OPEN: {
      return <VideoOnSVG />;
    }
    case PREVIEW_VIDEO_STATUS.CLOSED: {
      return <VideoOffSVG />;
    }
    case PREVIEW_VIDEO_STATUS.LOADING:
    case PREVIEW_VIDEO_STATUS.CAPTURING: {
      return <Spinner className="spinner" />;
    }
    default:
      return null;
  }
};

const getVideoText = (status) => {
  switch (status) {
    case PREVIEW_VIDEO_STATUS.FORBIDDEN: {
      return STOP_VIDEO;
    }
    case PREVIEW_VIDEO_STATUS.OPEN: {
      return STOP_VIDEO;
    }
    case PREVIEW_VIDEO_STATUS.CLOSED: {
      return START_VIDEO;
    }
  }
};

const PreviewVideo = (props) => {
  const {
    audioStatus,
    nextAudioStatus,
    videoStatus,
    nextVideoStatus,
    onAudioClick,
    onVideoClick,
    error,
    willRenderVideo,
    size,
    className,
    displayName,
    needDefaultAvatar = true,
    isAudioEnabled,
    avatar,
    resizable,
    sessionBranding,
  } = props;

  const canvasSize = PREVIEW_CANVAS_SIZE[size];

  const [videoSize, setVideoSize] = useState({
    width: canvasSize.WIDTH,
    height: canvasSize.HEIGHT,
  });
  const [cameras, setCameras] = useState([]);
  const [activeCamera, setActiveCamera] = useState(null);
  const avSocket = getPreviewAVSocket();

  const isSmall = size === PREVIEW_SIZE.SMALL;
  const audioConnected =
    audioStatus === PREVIEW_AUDIO_STATUS.MUTED ||
    audioStatus === PREVIEW_AUDIO_STATUS.UNMUTED;
  const isVideoOpen = videoStatus === PREVIEW_VIDEO_STATUS.OPEN;
  const isAudioLoading =
    audioStatus === PREVIEW_AUDIO_STATUS.LOADING ||
    audioStatus === PREVIEW_AUDIO_STATUS.CAPTURING;
  const isVideoLoading =
    videoStatus === PREVIEW_VIDEO_STATUS.LOADING ||
    videoStatus === PREVIEW_VIDEO_STATUS.CAPTURING;
  const audioText = isAudioLoading
    ? getAudioText(nextAudioStatus)
    : getAudioText(audioStatus);
  const videoText = isVideoLoading
    ? getVideoText(nextVideoStatus)
    : getVideoText(videoStatus);
  const avatarCls = clsx('preview-video__default-avatar', {
    'preview-video__default-avatar--small': isSmall,
  });

  const hideAudio = !isAudioEnabled;
  const canNotDetectCamera = cameras.length <= 1;

  useEffect(() => {
    const deviceChangeHandler = (deviceState) => {
      if (has(deviceState, 'cameras')) {
        setCameras(deviceState.cameras);
      }
      if (has(deviceState, 'activeCamera')) {
        setActiveCamera(deviceState.activeCamera);
        if (deviceState.activeCamera !== activeCamera && isVideoOpen) {
          avSocket.changeCamera(deviceState.activeCamera);
        }
      }
    };
    const deviceSelectedHandler = (deviceState) => {
      if (has(deviceState, 'activeCamera')) {
        setActiveCamera(deviceSelectedHandler.activeCamera);
        if (isVideoOpen) {
          avSocket.changeCamera(deviceState.activeCamera);
        }
      }
    };
    deviceManager.watchInitComplete().then((deviceState) => {
      setCameras(deviceState.cameras);
      setActiveCamera(deviceState.activeCamera);
    });
    deviceManager.on('DEVICE_CHANGED', deviceChangeHandler);
    deviceManager.on('DEVICE_SELECTED', deviceSelectedHandler);

    return () => {
      deviceManager.off('DEVICE_CHANGED', deviceChangeHandler);
      deviceManager.off('DEVICE_SELECTED', deviceSelectedHandler);
    };
  }, [isVideoOpen, avSocket, activeCamera]);

  useEffect(() => {
    if (audioStatus === PREVIEW_AUDIO_STATUS.MUTED) {
      promptA11yInfo(previewI18n.MutedNotifier);
    }
    if (audioStatus === PREVIEW_AUDIO_STATUS.UNMUTED) {
      promptA11yInfo(previewI18n.UnmutedNotifier);
    }
  }, [audioStatus]);

  useEffect(() => {
    if (videoStatus === PREVIEW_VIDEO_STATUS.OPEN) {
      promptA11yInfo(previewI18n.VideoStartedNotifier);
    }
    if (videoStatus === PREVIEW_VIDEO_STATUS.CLOSED) {
      promptA11yInfo(previewI18n.VideoStoppedNotifier);
    }
  }, [videoStatus]);

  const renderPreviewVideoContent = () => {
    return (
      <>
        {!avatar && <div className="preview-username">{displayName}</div>}
        {avatar && (
          <div className="preview-avatar">
            <img src={avatar} alt="avatar" />
          </div>
        )}
        {willRenderVideo && (
          <canvas
            width={resizable ? videoSize.width : canvasSize.WIDTH}
            height={resizable ? videoSize.height : canvasSize.HEIGHT}
            id={PREVIEW_CANVAS}
          />
        )}
        {!willRenderVideo && !displayName && !avatar && needDefaultAvatar && (
          <div className={avatarCls}>
            <PreviewDefaultAvatar />
          </div>
        )}
        {!isSmall && (
          <PreviewError
            className="preview-video__error"
            error={error}
            canNotDetectCamera={canNotDetectCamera}
          />
        )}
        {!_.isEmpty(sessionBranding) && isVideoOpen && !isSmall && (
          <NameTag sessionBranding={sessionBranding} />
        )}
        <div className={clsx('preview-video__control', { simple: isSmall })}>
          {!hideAudio && (
            <div
              className={clsx('preview-video__control-button-container', {
                simple: isSmall,
              })}
            >
              <button
                className="preview-video__control-button"
                onClick={onAudioClick}
                aria-label={audioText}
                id="preview-audio-control-button"
                title={
                  audioStatus === PREVIEW_AUDIO_STATUS.DISABLED
                    ? previewI18n.HostMutedTip
                    : ''
                }
              >
                {getAudioIcon(audioStatus)}
                <span className="preview-video__control-text">
                  {!isSmall && audioText}
                </span>
              </button>
              {!isSmall && <AudioMenu showToggle={audioConnected} />}
            </div>
          )}
          <div
            className={clsx('preview-video__control-button-container', {
              simple: isSmall,
            })}
          >
            <button
              className="preview-video__control-button"
              onClick={onVideoClick}
              aria-label={videoText}
              id="preview-video-control-button"
            >
              {getVideoIcon(videoStatus, canNotDetectCamera)}
              <span className="preview-video__control-text">
                {!isSmall && videoText}
              </span>
            </button>
            {!isSmall && (
              <VideoMenu
                isVideoOpen={isVideoOpen}
                showToggle={!isVideoLoading}
                cameras={cameras}
                activeCamera={activeCamera}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const renderPreviewVideo = () => {
    return (
      <div
        className={clsx('preview-video', className, {
          'preview-video-resizable': resizable,
        })}
        style={
          resizable
            ? {}
            : { width: canvasSize.WIDTH, height: canvasSize.HEIGHT }
        }
      >
        {resizable ? (
          <div className="preview-video-content">
            {renderPreviewVideoContent()}
          </div>
        ) : (
          renderPreviewVideoContent()
        )}
      </div>
    );
  };

  return resizable ? (
    <ResizeObserver
      onResize={({ width, height }) => {
        setVideoSize({ width, height });
      }}
    >
      {renderPreviewVideo()}
    </ResizeObserver>
  ) : (
    renderPreviewVideo()
  );
};

export default PreviewVideo;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/features/preview/component/PreviewVideo.scss';
