import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CAMERA_SELECT } from '../../video/resource';
import Menu from './Menu';
import MenuItem from './MenuItem';
import { ChevronSVG } from '../../../svg/icons/footer';
import { SelectedSVG } from '../../../svg/icons';
import deviceManager from '../../../device-manager';

const MenuContent = React.forwardRef((props, ref) => {
  const { cameras, activeCamera, onKeyDown, onMenuClick } = props;

  const onChangeCamera = (deviceId) => {
    deviceManager.manuallySelectCamera(deviceId);
    if (onMenuClick) {
      onMenuClick();
    }
  };

  return (
    <Menu className="preview__dropdown-menu" ref={ref} onKeyDown={onKeyDown}>
      <div className="preview__menu-title">{CAMERA_SELECT}</div>
      {cameras.map((camera) => (
        <MenuItem
          className="preview__dropdown-menuitem"
          key={camera.deviceId}
          onClick={() => onChangeCamera(camera.deviceId)}
          role="menuitemradio"
          aria-checked={activeCamera === camera.deviceId}
          aria-label={`${CAMERA_SELECT} ${camera.label}`}
        >
          {activeCamera === camera.deviceId && (
            <SelectedSVG width="24" height="24" />
          )}
          <span>{camera.label}</span>
        </MenuItem>
      ))}
    </Menu>
  );
});
MenuContent.displayName = 'MenuContent';
MenuContent.propTypes = {
  cameras: PropTypes.array,
  activeCamera: PropTypes.string,
  onKeyDown: PropTypes.func,
  onMenuClick: PropTypes.func,
  isVideoOpen: PropTypes.bool,
};

const VideoMenu = (props) => {
  const { isVideoOpen, cameras, activeCamera } = props;
  const [show, setShow] = useState(false);
  const btnRef = useRef(null);
  const menuRef = useRef(null);

  const handleToggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    const handler = (e) => {
      const btnContains = btnRef.current && btnRef.current.contains(e.target);
      const menuContains =
        menuRef.current && menuRef.current.contains(e.target);
      if (!btnContains && !menuContains) {
        setShow(false);
      }
    };
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const handleMenuKeydown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setShow(false);
      btnRef.current.focus();
    } else if (event.key === 'Escape') {
      setShow(false);
      btnRef.current.focus();
    }
  };

  const onMenuClick = () => {
    setShow(false);
  };

  if (!props.showToggle) {
    return null;
  }

  return (
    <>
      <button
        className="preview__toggle"
        onClick={handleToggle}
        ref={btnRef}
        aria-label="More video controls"
      >
        <ChevronSVG />
      </button>
      {show && (
        <MenuContent
          ref={menuRef}
          cameras={cameras}
          activeCamera={activeCamera}
          onKeyDown={handleMenuKeydown}
          onMenuClick={onMenuClick}
          isVideoOpen={isVideoOpen}
        />
      )}
    </>
  );
};

export default VideoMenu;

VideoMenu.propTypes = {
  isVideoOpen: PropTypes.bool,
  showToggle: PropTypes.bool,
  setError: PropTypes.func,
  cameras: PropTypes.array,
  activeCamera: PropTypes.object,
};
