import { isAllowOnlyCanTalk, isViewOnly } from '../user-types';
import AliveToast from '../../containers/notification-manager/alive-toast';
import {
  ACRToastTest,
  archivingDialogThunk,
  archivingSubjectsText,
} from '../../../features/dialog/components/archiving';
import { easyStore } from '../../easy-store';
import { SESSIONSTORAGE_KEYS } from '../../constant';
import { AUDIO } from '../../../features/audio/resource';
import { playArchivingAudio } from '../../audio-play';
import { setArchivingInfo } from '../../../actions/MeetingActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { confReadySelector } from '../../selectors/meeting-selector';
import { ConsentDialogType } from '../../../features/dialog/service/consent-dialog-constant';
import { closeRefDialog } from '../../../features/dialog/service/command-dialog';

const checkACR = () => {
  const check = (item, dispatch, getState) => {
    const {
      meeting: {
        archiveInfo: { subjects, acrCount },
      },
    } = getState();
    if (item.AcrStatus === 1) {
      if (!acrCount) {
        const newSubjects = _.union(subjects, [AUDIO]);
        dispatch(
          setArchivingInfo({
            acrCount: 1,
            subjects: newSubjects,
            text: archivingSubjectsText(newSubjects),
          }),
        );
      } else {
        dispatch(
          setArchivingInfo({
            acrCount: acrCount + 1,
          }),
        );
      }
    } else if (item.AcrStatus === 0) {
      dispatch(
        setArchivingInfo({
          acrCount: acrCount - 1,
        }),
      );
    }
  };

  return {
    add: check,
    update: check,
  };
};
export const useACRConfig = () => {
  const {
    isOnHold,
    confReady,
    acrCount,
    disclaimerPrivacy,
    userRole,
    promptArchivingVoice,
  } = useSelector((state) => {
    const {
      meeting: {
        archiveInfo: { acrCount, disclaimerPrivacy, promptArchivingVoice },
        currentUser: { userRole },
      },
    } = state;
    return {
      isOnHold: state.meetingUI.isOnHold,
      confReady: confReadySelector(state),
      acrCount,
      disclaimerPrivacy,
      userRole,
      promptArchivingVoice,
    };
  }, shallowEqual);
  const dispatch = useDispatch();
  return {
    isOn: acrCount > 0,
    enable: !isOnHold && confReady,
    group: ConsentDialogType.ARCHIVING,
    startDialog: () => {
      dispatch(
        archivingDialogThunk({
          type: 'startArchiving',
          text: AUDIO,
          isSingle: true,
          disclaimerPrivacy,
          disableCustomerDescription: true,
          dialogType: 'archiveDialog2',
        }),
      );
    },
    startTip: () => {
      AliveToast.toast(ACRToastTest);
    },
    onStart: () => {
      if (promptArchivingVoice) {
        playArchivingAudio();
      }
    },
    onStop: () => {
      closeRefDialog('archiveDialog2');
    },
    showType: () => {
      if (isViewOnly(userRole) && !isAllowOnlyCanTalk(userRole)) {
        return null;
      } else if (
        isAllowOnlyCanTalk(userRole) ||
        easyStore.easyGet(SESSIONSTORAGE_KEYS.archiveConfirmed) ||
        easyStore.easyGet(SESSIONSTORAGE_KEYS.promoteConfirmed)
      ) {
        return 'tip';
      } else {
        return 'dialog-consent';
      }
    },
  };
};

export default checkACR;
