import { createActions } from 'redux-actions';
import { combineMessage } from '../service';

const SET_TURN_ON_OR_OFF_LIVE_TRANSCRIPTION_RESPONSE =
  'SET_TURN_ON_OR_OFF_LIVE_TRANSCRIPTION_RESPONSE';
const SET_IS_SUBTITLE_SHOWING = 'SET_IS_SUBTITLE_SHOWING';
const SET_LIVE_TRANSCRIPTION_STATUS = 'SET_LIVE_TRANSCRIPTION_STATUS';
const LIVE_TRANSCRIPTION_RESET = 'LIVE_TRANSCRIPTION_RESET';
const DELETE_MESSAGE = 'DELETE_MESSAGE';
const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
const SHOW_LIVE_TRANSCRIPTION_TOAST = 'SHOW_LIVE_TRANSCRIPTION_TOAST';
const PUSH_REQUEST_LT_ARR = 'PUSH_REQUEST_LT_ARR';
const SHIFT_REQUEST_LT_ARR = 'SHIFT_REQUEST_LT_ARR';
const CLEAR_REQUEST_LT_ARR = 'CLEAR_REQUEST_LT_ARR';
const CHANGE_REQUEST_LT_PERMISSION = 'CHANGE_REQUEST_LT_PERMISSION';
const CHANGE_REQUEST_FOOTER_VIS = 'CHANGE_REQUEST_FOOTER_VIS';
const TOGGLE_REQUEST_DIALOG = 'TOGGLE_REQUEST_DIALOG';
const SET_HAS_REQUEST_LT = 'SET_HAS_REQUEST_LT';

export const {
  setTurnOnOrOffLiveTranscriptionResponse,
  setIsSubtitleShowing,
  setLiveTranscriptionStatus,
  liveTranscriptionReset,
  deleteMessage,
  updateMessage,
  pushRequestLtArr,
  shiftRequestLtArr,
  clearRequestLtArr,
  changeRequestLtPermission, // Allow participants to request Live Transcription
  changeRequestFooterVis,
  toggleRequestDialog,
  setHasRequestLt,
} = createActions({
  [SET_TURN_ON_OR_OFF_LIVE_TRANSCRIPTION_RESPONSE]: (payload) => payload,
  [SET_IS_SUBTITLE_SHOWING]: (payload) => payload,
  [SET_LIVE_TRANSCRIPTION_STATUS]: (payload) => payload,
  [LIVE_TRANSCRIPTION_RESET]: (payload) => payload,
  [DELETE_MESSAGE]: (payload) => payload,
  [UPDATE_MESSAGE]: (payload) => combineMessage(payload),
  [SHOW_LIVE_TRANSCRIPTION_TOAST]: (payload) => payload,
  [SHIFT_REQUEST_LT_ARR]: (payload) => payload,
  [PUSH_REQUEST_LT_ARR]: (payload) => payload,
  [CLEAR_REQUEST_LT_ARR]: () => {},
  [CHANGE_REQUEST_LT_PERMISSION]: (payload) => payload,
  [CHANGE_REQUEST_FOOTER_VIS]: (payload) => payload,
  [TOGGLE_REQUEST_DIALOG]: (payload) => payload,
  [SET_HAS_REQUEST_LT]: (p) => p,
});
